// noinspection AllyPlainJsInspection

import Colors from '../../Theme/colors.module.scss'

export const getCustomTheme = (columnWidth: number, numberOfVisibleColumns: number): Record<string, string> => {
  const generateColumnWidth = (): string => Array(numberOfVisibleColumns).fill(`${columnWidth}px`).join(' ')

  return {
    Table: `
        --data-table-library_grid-template-columns: ${generateColumnWidth()} minmax(${columnWidth}px, 1fr);
      `,
    HeaderCell: `
         color: ${Colors.gray650};
         padding: 1em;
         background-color: #fcfcfc;
         font-variant: small-caps;
         &:nth-of-type(1) {
          background-color: ${Colors.white};
          
         }
      `,
    BaseCell: `
        padding: 1em;
        &:nth-of-type(1) {
          left: 0px;
          border-right: 1px solid ${Colors.gray200};
        }
      `,
    Row: `
        &:nth-of-type(odd) {
          background-color: #fcfcfc;
        }

        &:nth-of-type(even) {
          background-color: #f7f8f9;
        }
      `,
    Cell: `
        padding: 1em;
      `,
  }
}
