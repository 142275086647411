import { useEffect, useState } from 'react'

import { TItem } from '../interfaces'

const useInView = (initialActive: number, items: TItem[]): { active: number } => {
  const [active, setActive] = useState(initialActive)

  const getVisiblePercentage = (element: TItem): number => {
    const rect = element.ref?.current?.getBoundingClientRect()
    if (!rect) return 0

    const windowHeight = window.innerHeight || document.documentElement.clientHeight
    const windowWidth = window.innerWidth || document.documentElement.clientWidth

    const elementHeight = rect.bottom - rect.top
    const elementWidth = rect.right - rect.left

    // Calculate the visible portions of the element
    const visibleHeight = Math.max(0, Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0))
    const visibleWidth = Math.max(0, Math.min(rect.right, windowWidth) - Math.max(rect.left, 0))

    // Calculate the visible area in percentage
    return ((visibleHeight * visibleWidth) / (elementHeight * elementWidth)) * 100
  }

  // Throttling the scroll event to improve performance
  const throttle = (callback: () => void, delay: number): (() => void) => {
    let wait = false

    return (...args: []) => {
      if (wait) return

      callback(...args)
      wait = true
      setTimeout(() => {
        wait = false
      }, delay)
    }
  }

  useEffect(() => {
    const handleScroll = throttle(() => {
      let mostInviewSection = { visiblePercentage: 0, index: 0 }

      items.forEach((item, index) => {
        const visiblePercentage = getVisiblePercentage(item)

        if (visiblePercentage > mostInviewSection.visiblePercentage) mostInviewSection = { visiblePercentage, index }
      })

      setActive(mostInviewSection.index)
    }, 50)

    window.addEventListener('scroll', handleScroll, true)

    return () => {
      window.removeEventListener('scroll', handleScroll, true)
    }
  }, [items])

  return { active }
}

export default useInView
