import React from 'react'
import { useTranslation } from 'react-i18next'

import Styles from './styles.module.scss'

export const MaintenanceNotice = ({ error }: { error?: Error }): JSX.Element => {
  const { t } = useTranslation()
  let hint = ''

  if ((error && import.meta.env.MODE === 'development') || import.meta.env.MODE === 'test')
    hint = `Please make sure you have 'dev proxy' running in the background...<br>Error: ${error?.message}`

  return (
    <div className={Styles.container}>
      <div className={Styles.imgContainer} />
      <h1>{t('Maintenance')}</h1>
      <p className={Styles.errorMessage}>
        {t('We are currently performing maintenance on the website. Please try again later.')}
      </p>
      <p dangerouslySetInnerHTML={{ __html: hint }} className={Styles.errorMessage} />
    </div>
  )
}
