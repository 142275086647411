import React from 'react'
import { useTranslation } from 'react-i18next'

import { Panel } from '../../../../../Components/Panel'
import { List } from '../../../../../Components/Panel/Components/Content/List'
import { KeyValueListItem } from '../../../../../Components/Panel/Components/Content/List/Components/KeyValueListItem'
import { Edit } from './Components/Edit'

export const Contact = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Panel
      header={{
        title: t('Contact details'),
        actions: <Edit />,
      }}
    >
      <List>
        <KeyValueListItem key={1} label={t('Email')} labelWidth="202px">
          klantenservice@reisbalans.nl
        </KeyValueListItem>
        <KeyValueListItem key={2} label={t('Phone number')} labelWidth="202px">
          012-1234567
        </KeyValueListItem>
      </List>
    </Panel>
  )
}
