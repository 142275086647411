import React from 'react'
import { components, MenuListProps } from 'react-select'

import { IOption } from '../../interfaces'
import Styles from './styles.module.scss'
import { ToggleAll } from './ToggleAll'

export const MenuList = (props: MenuListProps<IOption, true>): JSX.Element => {
  const { children, ...rest } = props

  return (
    <components.MenuList {...rest}>
      <div data-testid="multi-selector-menu" className={Styles.menuHeaderStyle}>
        <ToggleAll {...rest}>{children}</ToggleAll>
        <div data-testid="menu-options">{children}</div>
      </div>
    </components.MenuList>
  )
}
