import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../Components'
import { ConfirmModal } from '../../../Components/ConfirmModal'
import { PageContainer } from '../../../Components/PageContainer'
import { Panel } from '../../../Components/Panel'
import { List } from '../../../Components/Panel/Components/Content/List'
import { KeyValueListItem } from '../../../Components/Panel/Components/Content/List/Components/KeyValueListItem'
import { Edit } from './Components/Edit'
import Styles from './styles.module.scss'

const Details = (): JSX.Element => {
  const name = '[Application_Name]'
  const { t } = useTranslation()
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)

  return (
    <PageContainer
      header={{
        title: name,
        headerCustomComponent: (
          <Button variant="secondary" onClick={(): void => setShowConfirm(true)}>
            {t('Remove')}
          </Button>
        ),
      }}
    >
      <Panel
        header={{
          title: 'OAUTH',
          actions: (
            <Button
              size="small"
              variant="secondaryBold"
              className={Styles.button}
              onClick={(): void => setShowEditModal(true)}
            >
              {t('Edit', { context: 'short' })}
            </Button>
          ),
        }}
      >
        <List rows>
          <KeyValueListItem key={0} labelAlign="middle" label={t('Scope')} variant="fat">
            hr api_v2 api_v3
          </KeyValueListItem>
          <KeyValueListItem key={1} labelAlign="middle" label={t('Application ID')} variant="fat">
            596f89e96bc9b26b82a688bdc31f3a396168c8b7c4861469134137a12c61213a
          </KeyValueListItem>
          <KeyValueListItem key={2} labelAlign="middle" label={t('Secret')} variant="fat">
            77da52d3334b6e70b85af8de0ed636c8472c04a49324cef9189cbe90dc31e553
          </KeyValueListItem>
          <KeyValueListItem key={3} labelAlign="middle" label={t('Redirect URI')} variant="fat">
            urn:ietf:wg:oauth:2.0:oob
          </KeyValueListItem>
        </List>
      </Panel>

      {showConfirm && (
        <ConfirmModal
          content={t('Are you sure you want to remove Get Connector {{ target }}?', { target: name })}
          description={t('This action is irreversible.')}
          onCancel={(): void => setShowConfirm(false)}
          confirmButtonProps={{ onClick: (): void => {}, content: t('Yes, remove') }}
        />
      )}

      {showEditModal && <Edit onClose={(): void => setShowEditModal(false)} />}
    </PageContainer>
  )
}

export default Details
