import React from 'react'

import { Pagination } from '../Pagination'
import { Table } from '../Table'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const RegularTable: React.FC<IProps> = ({ tableProps, paginationProps }): JSX.Element => {
  return (
    <div className={Styles.wrapper}>
      <Table {...tableProps} />

      <div className={Styles.pagination}>
        <Pagination {...paginationProps} />
      </div>
    </div>
  )
}
