import React from 'react'
import { components, ValueContainerProps } from 'react-select'

import { Typography } from '../../../Typography'
import { IOption } from '../../interfaces'
import Styles from './styles.module.scss'

export const ValueContainer = (props: ValueContainerProps<IOption>): JSX.Element => {
  const { children, ...rest } = props
  const {
    selectProps: { inputValue, placeholder },
  } = rest

  return (
    <components.ValueContainer {...rest}>
      {!inputValue && (
        <components.Placeholder {...rest} isFocused={false} innerProps={{ ...rest.innerProps }}>
          <Typography className={Styles.placeholder} variant="body-2">
            {placeholder}
          </Typography>
        </components.Placeholder>
      )}

      {children}
    </components.ValueContainer>
  )
}
