import i18n from 'i18next'

export const publishedTo = [
  'Appelo-3jan',
  'Appelo-4',
  'Appelo B.V',
  'Appelo-90',
  'jantje',
  'Appelo-3jan1',
  'Appelo-42',
  'Appelo B.V3',
  'Appelo-904',
]

const { t } = i18n
export const tableConfig = {
  title: {
    name: t('Title'),
    width: '255px',
  },
  publishedTo: {
    name: t('Published to'),
    width: '328px',
  },
  status: {
    name: t('Status'),
    width: '441px',
  },
}
