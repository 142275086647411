import i18n from 'i18next'
import { FC } from 'react'

import { DamageWidget } from './DamageWidget'
import { EmployeesWidget } from './EmployeesWidget'
import { ExceededContractsWidget } from './ExceededContractsWidget'
import { ExpiringContractsWidget } from './ExpiringContractsWidget'
import { FinesWidget } from './FinesWidget'
import { FuelCostWidget } from './FuelCostWidget'
import { IBaseWidgetProps } from './interfaces'
import { PersonalMobilityCardStatusWidget } from './PersonalMobilityCardStatusWidget'
import { RidesWidget } from './RidesWidget'
import { SharedMobilityCardStatusWidget } from './SharedMobilityCardStatusWidget'

export type TWidgetName = keyof typeof availableWidgets
export const availableWidgets: {
  [key: string]: FC<IBaseWidgetProps>
} = {
  EmployeesWidget,
  FuelCostWidget,
  DamageWidget,
  PersonalMobilityCardStatusWidget,
  SharedMobilityCardStatusWidget,
  ExceededContractsWidget,
  ExpiringContractsWidget,
  RidesWidget,
  FinesWidget,
}

// eslint-disable-next-line @getify/proper-arrows/params
export const getHumanizedName = (name: TWidgetName): string => {
  const { t } = i18n
  switch (name) {
    case 'EmployeesWidget':
      return t('Employees')
    case 'FuelCostWidget':
      return t('Total tank & charging costs')
    case 'DamageWidget':
      return t('Total number of damage cases')
    case 'PersonalMobilityCardStatusWidget':
      return t('Mobility cards')
    case 'SharedMobilityCardStatusWidget':
      return t('Shared Mobility Cards')
    case 'ExceededContractsWidget':
      return t('Lease contract(s) with over- and underspendings')
    case 'ExpiringContractsWidget':
      return t('Lease contract(s) expiring within')
    case 'RidesWidget':
      return t('Journeys')
    case 'FinesWidget':
      return t('Total fines & penalties')
    default:
      return 'unknown'
  }
}

export const widgetNames = Object.values(availableWidgets).map((widget) => widget.displayName || widget.name)
