import { ROUTE_NAMES, TRoutes } from './interfaces'

export const routes: TRoutes = {
  [ROUTE_NAMES.DASHBOARD]: '/',
  [ROUTE_NAMES.DRIVEN_LEASE_CONTRACTS]: '/driven/lease_contracts',
  [ROUTE_NAMES.DRIVEN_LEASE_CAR_FLEET]: '/driven/lease-car-fleet',
  [ROUTE_NAMES.CUSTOMER_LABELS]: '/customer_labels',
  [ROUTE_NAMES.CUSTOMER_LABELS_CREATE]: '/customer_labels/new',
  [ROUTE_NAMES.CUSTOMER_LABELS_DETAILS]: '/customer_labels/:id',
  [ROUTE_NAMES.FEATURE_FLAGS]: 'feature_flags',
  [ROUTE_NAMES.MESSAGES]: '/messages',
  [ROUTE_NAMES.MESSAGES_DETAILS]: '/messages/:id',
  [ROUTE_NAMES.MESSAGES_NEW]: '/messages/new',
  [ROUTE_NAMES.GET_CONNECTORS]: '/get_connectors',
  [ROUTE_NAMES.GET_CONNECTORS_DETAILS]: '/get_connectors/:id',
  [ROUTE_NAMES.GET_CONNECTORS_NEW]: '/get_connectors/new',
  [ROUTE_NAMES.OAUTH_APPLICATIONS]: '/oauth_applications',
  [ROUTE_NAMES.OAUTH_APPLICATIONS_NEW]: '/oauth_applications/new',
  [ROUTE_NAMES.OAUTH_APPLICATIONS_DETAILS]: '/oauth_applications/:id',
  [ROUTE_NAMES.TERMS_CONDITIONS]: '/terms_conditions',
  [ROUTE_NAMES.TERMS_CONDITIONS_PREVIOUS]: '/terms_conditions/previous',
  [ROUTE_NAMES.POOL_CARS]: '/pool_cars',
  [ROUTE_NAMES.WRPM_OVERVIEW]: '/wrpm_overview',
}
