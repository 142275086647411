import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Icon } from '../Icon'
import { Typography } from '../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Breadcrumbs: React.FC<IProps> = ({ breadcrumbs = [] }): JSX.Element | null => {
  const navigate = useNavigate()
  const previousPage = breadcrumbs.at(-2)

  if (!previousPage) return null

  return (
    <div className={Styles.breadcrumbs} data-testid="breadcrumbs" onClick={(): void => navigate(previousPage.url)}>
      <Icon className={Styles.icon} name="chevron-left-bold" width={8} height={8} />
      <Typography className={Styles.title} variant="body-3">
        {previousPage.title}
      </Typography>
    </div>
  )
}
