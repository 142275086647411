export enum ESortDirections {
  ASC = 'ASC',
  DESC = 'DESC',
  NONE = 'NONE',
}

export interface IProps {
  sortable?: boolean
  name: string
  onChange: (field: string, direction: ESortDirections) => void
  activeSort?: ESortDirections
  width?: string
  column: string
}
