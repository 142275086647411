import React from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '../Icon'
import { IProps as IIconProps } from '../Icon/interface'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ActiveBadge: React.FC<IProps> = ({ active }): JSX.Element => {
  const { t } = useTranslation()

  const iconProps = ((): IIconProps => {
    if (active) return { name: 'check', className: Styles.active }
    return { name: 'close-circle', className: Styles.inActive }
  })()

  return (
    <div className={Styles.activeBadge}>
      <Icon {...iconProps} />
      <span className={Styles.state}>{active ? t('Yes') : t('No')}</span>
    </div>
  )
}
