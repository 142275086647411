import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useOnClickOutside } from '../../../../../Hooks/useClickedOutside/useOnClickOutside'
import { getLeadingZeroTimeUnit } from '../../../../../Lib/Formatters/DateFormatter'
import { ITime } from '../../interfaces'
import OptionsList from '../OptionsList'
import { TOption } from '../OptionsList/interfaces'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

const TimeSelectPanel: React.FC<IProps> = ({
  hours = 0,
  minutes = 0,
  onChange,
  onClickOutside,
  disabledTimes,
}): JSX.Element => {
  const panelRef = useRef<HTMLDivElement>(null)
  const [selectedTime, setSelectedTime] = useState<ITime>()

  const hourOptions: Array<TOption> = [...Array(24).keys()].map((number) => ({
    label: getLeadingZeroTimeUnit(number),
    value: number,
    disabled: disabledTimes && disabledTimes.disabledHours.includes(number),
  }))

  const minuteOptions: Array<TOption> = [...Array(60).keys()].map((number) => ({
    label: getLeadingZeroTimeUnit(number),
    value: number,
    disabled: disabledTimes && disabledTimes.disabledMinutes.includes(number),
  }))

  const setTimeAsSelected = useCallback(
    (time: ITime): void => {
      setSelectedTime(time)

      if (onChange) onChange(time)
    },
    [onChange]
  )

  const onHoursSelect = (option: TOption): void => {
    setTimeAsSelected({
      hours: Number(option.value),
      minutes: selectedTime?.minutes ?? 0,
    })
  }

  const onMinutesSelect = (option: TOption): void => {
    setTimeAsSelected({
      hours: selectedTime?.hours ?? 0,
      minutes: Number(option.value),
    })
  }

  const onPanelOutsideClick = (event: MouseEvent | TouchEvent): void => {
    if (onClickOutside) onClickOutside(event)
  }

  useEffect((): void => {
    setTimeAsSelected({
      hours,
      minutes,
    })
  }, [setTimeAsSelected, hours, minutes])

  useOnClickOutside({ handler: onPanelOutsideClick, ref: panelRef })

  return (
    <div className={Styles.timePickerPanel} ref={panelRef}>
      <OptionsList
        id="hours"
        options={hourOptions}
        height="100px"
        selected={selectedTime?.hours}
        onSelect={onHoursSelect}
      />
      <OptionsList
        id="minutes"
        options={minuteOptions}
        height="100px"
        selected={selectedTime?.minutes}
        onSelect={onMinutesSelect}
      />
    </div>
  )
}

export default TimeSelectPanel
