import React from 'react'

import { LoadingSkeleton as ListItemSkeleton } from '../../../../../../Components/Panel/Components/Content/List/Components/ListItem/LoadingSkeleton'
import { LoadingSkeleton as ProgressBarSkeleton } from '../../../../../../Components/ProgressBar/LoadingSkeleton'
import Styles from './styles.module.scss'

export const LoadingSkeleton = (): JSX.Element => {
  return (
    <div>
      <div className={Styles.container}>
        <div className={Styles.progressBars}>
          <ProgressBarSkeleton />
          <ProgressBarSkeleton />
          <ProgressBarSkeleton />
        </div>
      </div>
      <div className={Styles.listItem}>
        <ListItemSkeleton bottomBorder />
      </div>
    </div>
  )
}
