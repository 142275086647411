import 'react-day-picker/dist/style.css'

import { isSameDay, isToday, isValid, parse, startOfDay } from 'date-fns'
import dateFnsFormat from 'date-fns/format'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useOnClickOutside } from '../../../Hooks/useClickedOutside/useOnClickOutside'
import { useTranslationDecorator } from '../../../Hooks/useTranslationDecorator'
import { localeForDateFn } from '../../../Lib/Formatters/DateFormatter'
import { MaybeRenderWithLabelHOC } from '../../../Lib/MaybeRenderWithLabelHOC'
import { DayPicker } from '../DayPicker'
import { Input } from '../Input'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

const DatePickerComponent: React.FC<IProps> = ({
  id,
  name,
  placeholder,
  value,
  disabled,
  inline,
  onChange,
  className,
  format = 'dd-MM-yyyy',
  required,
  minDate,
  maxDate,
  showOutsideDays = true,
  showWeekNumber = true,
  width = '240px',
}): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)
  const dayPickerRef = useRef<HTMLDivElement>(null)

  const [selected, setSelected] = useState<Date>()
  const [dateInputValue, setDateInputValue] = useState<string>('')
  const [showCalendar, setShowCalendar] = useState<boolean>(false)

  const { t } = useTranslation()
  const { locale } = useTranslationDecorator()
  const dateFnsLocale = localeForDateFn(locale)

  const datePickerClasses = ((): string => {
    const classes: string[] = [Styles.datePickerWrapper]

    if (className) classes.push(className)

    return classes.join(' ')
  })()

  const getLocaleDateStringOfDate = useCallback(
    (date: Date): string => {
      const options: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
      }

      if (!isToday(date)) options.year = 'numeric'

      let dateString = date.toLocaleDateString(locale, options)

      if (isToday(date)) dateString = t('Today ({{ date }})', { date: dateString })

      return dateString
    },
    [locale, t]
  )

  const getPlaceholder = (): string => {
    return placeholder || getLocaleDateStringOfDate(value || new Date())
  }

  const updateDateInputValueToDate = useCallback(
    (date: Date): void => {
      setDateInputValue(getLocaleDateStringOfDate(date))
    },
    [getLocaleDateStringOfDate]
  )

  const updateDateInputValueToCurrentSelectedDate = useCallback((): void => {
    if (selected) updateDateInputValueToDate(selected)
  }, [updateDateInputValueToDate, selected])

  const setSelectedCalendarDate = (selectedDate: Date | undefined): void => {
    if (selectedDate === undefined || (selected && selectedDate && isSameDay(selected, selectedDate))) return

    let newDate = startOfDay(selectedDate)

    if (minDate && selectedDate < minDate) newDate = startOfDay(minDate)
    if (maxDate && selectedDate > maxDate) newDate = startOfDay(maxDate)

    if (newDate && isValid(newDate)) {
      if (selected && onChange) onChange(newDate)
      setSelected(newDate)
    }
  }

  const getCurrentSelectedDateAsFormattedString = (): string => {
    return selected ? dateFnsFormat(selected, format, { locale: dateFnsLocale }) : ''
  }

  const openCalendarModal = (): void => {
    setShowCalendar(true)
  }

  const closeCalendarModal = (): void => {
    if (showCalendar) updateDateInputValueToCurrentSelectedDate()

    setShowCalendar(false)
  }

  const onCalendarDateSelect = (selectedDate: Date | undefined): void => {
    setSelectedCalendarDate(selectedDate)
    closeCalendarModal()
  }

  const getDateInputAsFormattedString = (dateInput: string): string => {
    let newDateValue = dateInput.replaceAll('/', '-')
    const matches = newDateValue.match(/^(\d{1,2}-\d{1,2}-)(\d{2})$/)

    if (matches) newDateValue = `${matches[1]}19${matches[2]}`

    return newDateValue
  }

  const getDayPickerExtraProps = (): object => {
    const props: { disabled: Array<Date | object> } = {
      disabled: [],
    }

    if (minDate) props.disabled.push({ before: minDate })
    if (maxDate) props.disabled.push({ after: maxDate })

    return props
  }

  const onDateInputBlur = (inputValue: string): void => {
    setSelectedCalendarDate(parse(getDateInputAsFormattedString(inputValue), format, new Date()))
  }

  const onDateInputFocus = ({ target }: React.FocusEvent): void => {
    const currentDateValue = getCurrentSelectedDateAsFormattedString()

    setDateInputValue(currentDateValue)

    if (target) {
      // we need a little time for the DOM to update the input value before selecting it.
      setTimeout(() => {
        ;(target as HTMLInputElement).select()
      }, 5)
    }
  }

  const onDateInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault()
      ;(event.target as HTMLInputElement).blur()
      closeCalendarModal()
    }
  }

  const onMonthChange = (): void => {
    if (dayPickerRef.current) {
      dayPickerRef.current.querySelectorAll('.rdp-nav button').forEach((button: Element): void => {
        ;(button as HTMLButtonElement).blur()
      })
    }
  }

  useOnClickOutside({ handler: closeCalendarModal, ref })

  useEffect(() => {
    setSelectedCalendarDate(value || new Date())
    // Ignoring the "react-hooks/exhaustive-deps" here because it causes a reset to the intial value..
    // eslint-disable-next-line
  }, [value, format])

  useEffect(() => {
    updateDateInputValueToCurrentSelectedDate()
  }, [updateDateInputValueToCurrentSelectedDate, selected])

  return (
    <div
      // @ts-ignore
      style={{ '--width': width }}
      className={`${Styles.datePicker} ${inline ? Styles.inline : ''}`}
      data-testid="datePickerWrapper"
      ref={ref}
    >
      <Input
        {...{ id, name, disabled, inline }}
        wrapperClassName={Styles.inputWrapper}
        type="text"
        label=""
        placeholder={getPlaceholder()}
        value={dateInputValue}
        iconName="calendar"
        autocomplete="off"
        clearable={false}
        onClick={openCalendarModal}
        onChange={setDateInputValue}
        onBlur={onDateInputBlur}
        onFocus={onDateInputFocus}
        onKeyDown={onDateInputKeyDown}
        required={required}
      />

      {showCalendar && (
        <div data-testid="datePicker" className={datePickerClasses} ref={dayPickerRef}>
          <DayPicker
            locale={dateFnsLocale}
            mode="single"
            defaultMonth={selected}
            selected={selected}
            showOutsideDays={showOutsideDays}
            showWeekNumber={showWeekNumber}
            onMonthChange={onMonthChange}
            onSelect={(date, _t, _y, event): void => {
              event.preventDefault()
              onCalendarDateSelect(date)
            }}
            {...getDayPickerExtraProps()}
          />
        </div>
      )}
    </div>
  )
}

export const DatePicker = MaybeRenderWithLabelHOC(DatePickerComponent)
