import { useEffect, useState } from 'react'

export const useMountStatus = (): boolean => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500)

    return (): void => clearTimeout(timeout)
  }, [])

  return isMounted
}
