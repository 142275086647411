import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../../Components'
import { Checkbox } from '../../../../Components/FormComponents/Checkbox'
import { Modal } from '../../../../Components/Modal'
import { ContentSection } from '../../../../Components/Modal/Components'
import { Typography } from '../../../../Components/Typography'
import { defaultWidgets } from '../../helpers'
import { getHumanizedName, widgetNames } from '../Widgets/helpers'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const EditModal: React.FC<IProps> = ({ items, onClose, onSave }): JSX.Element => {
  const { t } = useTranslation()

  const defaultWidgetOptions = Object.values(defaultWidgets).flat()

  const widgetOptions = widgetNames
    .map((name) => ({ label: getHumanizedName(name), value: name }))
    .sort((first, second) => first.label.localeCompare(second.label))

  const defaultOptionsAsString = ((): string => {
    const defaultOptionLabels = widgetOptions
      .filter((option) => defaultWidgetOptions.includes(option.value))
      .map((option) => option.label)

    const joinedElements = defaultOptionLabels.slice(0, -1).join(', ')
    return `${joinedElements} ${t('and')} ${defaultOptionLabels[defaultOptionLabels.length - 1]}`
  })()

  const visibleOptions = Object.values(items).flat()
  const [checkedItems, setCheckedItems] = useState<string[]>(visibleOptions)

  const changeHandler = (checked: boolean, option: string): void => {
    const includesOption = checkedItems.includes(option)
    if (checked && !includesOption) setCheckedItems((prev) => [...prev, option])
    if (!checked && includesOption) setCheckedItems((prev) => prev.filter((item) => option !== item))
  }

  const saveHandler = (customization = false): void => {
    if (!customization) onSave(defaultWidgets)
    else {
      // We can save it straight but to improve user experience its good to keep the current order as much as possible the same
      const { LEFT, RIGHT } = items

      const existInLeftColumn = checkedItems.filter((item) => LEFT.includes(item))
      const existInRightColumn = checkedItems.filter((item) => RIGHT.includes(item))
      const newItems = checkedItems.filter((item) => !LEFT.includes(item) && !RIGHT.includes(item))

      onSave({ LEFT: [...existInLeftColumn, ...newItems], RIGHT: [...existInRightColumn] })
    }

    onClose()
  }

  return (
    <Modal title={t('Add/remove widgets')} subTitle={t('Manage your dashboard')} onClose={onClose} show>
      <ContentSection title={t('Choose a view')}>
        <Typography className={Styles.label} variant="body-2">
          {t('Choose a view')}
        </Typography>

        <div className={Styles.default}>
          <div>
            <Typography className={Styles.standard} variant="h1" element="p">
              {t('Standard')}
            </Typography>
            <Typography variant="body-2" className={Styles.defaultOptions}>
              {defaultOptionsAsString}
            </Typography>
          </div>

          <Button id="default-button" onClick={(): void => saveHandler()}>
            {t('Apply')}
          </Button>
        </div>
      </ContentSection>

      <div className={Styles.divider}>
        <div className={Styles.line} />
        <Typography variant="h1" element="p" className={Styles.text}>
          {t('or')}
        </Typography>
        <div className={Styles.line} />
      </div>

      <ContentSection title={t('Widgets')}>
        <div className={Styles.checkBoxes}>
          {widgetOptions.map((item) => (
            <Checkbox
              key={item.value}
              label={item.label}
              id={item.value}
              checked={checkedItems.includes(item.value)}
              onChange={(checked): void => changeHandler(checked, item.value)}
            />
          ))}
        </div>

        <Button
          id="custom-button"
          className={Styles.customButton}
          disabled={checkedItems.length === 0}
          onClick={(): void => saveHandler(true)}
        >
          {t('Apply')}
        </Button>
      </ContentSection>
    </Modal>
  )
}
