import React, { useContext } from 'react'

import { ThemeContext } from '../../Providers/ThemeProvider'
import { Select } from '../FormComponents/Select'
import Styles from './styles.module.scss'

export const ThemeSwitcher: React.FC = (): JSX.Element | null => {
  const { overrideHostname, setOverrideHostname } = useContext(ThemeContext)

  const options = [
    { label: 'Reisbalans', value: 'staging.interface.reisbalans.nl' },
    { label: 'Driven', value: 'staging-driven.interface.reisbalans.nl' },
  ]
  const handleThemeSwitch = (event: { label: string; value: string }): void => {
    setOverrideHostname(event.value)
  }

  if (import.meta.env.MODE === 'production') return null

  return (
    <div className={Styles.themeSwitcher}>
      <Select
        id="hostnameOverride"
        data-testid="hostnameOverride"
        placeholder="Hostname override"
        ariaLabel="Hostname override"
        options={options}
        value={overrideHostname || 'staging.interface.reisbalans.nl'}
        name="hostnameOverride"
        onChange={handleThemeSwitch}
      />
    </div>
  )
}
