import { add, format } from 'date-fns'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ISelect } from '../../../../../Components/LeasefleetTable/interfaces'
import { Modal } from '../../../../../Components/Modal'
import { List } from '../../../../../Components/Panel/Components/Content/List'
// @ts-ignore
// import { useGetExpiringContractsQuery } from '../../../../../Lib/graphql'
import { BicycleContractsTable } from '../../../../DrivenLeaseContracts/Tabs/BicycleContractsTable'
import { CarContractsTable } from '../../../../DrivenLeaseContracts/Tabs/CarContractsTable'
import { Widget } from '../../Widget'
import { TOption } from '../../Widget/interfaces'
import Styles from '../../Widget/styles.module.scss'
import { WidgetListItem } from '../../WidgetListItem'
import { IBaseWidgetProps } from '../interfaces'
import { LoadingSkeleton } from './LoadingSkeleton'

export const ExpiringContractsWidget: React.FC<IBaseWidgetProps> = ({ draggable }): JSX.Element => {
  const { t } = useTranslation()

  const selectMonthOptions = (): { label: string; value: number }[] => {
    const months = [1, 3, 6, 9, 12]
    const monthRecords: { label: string; value: number }[] = []

    months.forEach((month) => {
      monthRecords.push({ label: t('{{ count }} months', { count: month }), value: month })
    })

    return monthRecords
  }

  const [selectedMonth, setSelectedMonth] = useState<TOption>(selectMonthOptions()[4])
  const [range, setRange] = useState<{ from: Date; till: Date }>({
    from: new Date(),
    till: add(new Date(), { months: Number(selectedMonth.value) }),
  })
  const [modalType, setModalType] = useState<'bike' | 'car'>()
  const modalTitle = modalType === 'car' ? t('Expiring lease car contract(s)') : t('Expiring lease bicycle contract(s)')

  const formatDate = (date: Date): string => format(date, 'yyyy-MM-dd')
  const formattedDateRange = { from: formatDate(range.from), till: formatDate(range.till) }
  // @ts-ignore
  const { data, loading, refetch } = useGetExpiringContractsQuery({
    variables: {
      from: formattedDateRange.from,
      till: formattedDateRange.till,
    },
    fetchPolicy: 'no-cache',
  })

  const handleOnChange = (selected: TOption): void => {
    const from = new Date()
    const till = add(from, { months: Number(selected.value) })

    setRange({ from, till })
    setSelectedMonth(selected)
    refetch({ from: formatDate(from), till: formatDate(till) })
  }

  const selectOptions: ISelect<TOption> = {
    name: 'ExpiringContractsWidget',
    options: selectMonthOptions(),
    onChange: handleOnChange,
    value: selectedMonth,
    menuAlignment: 'right',
    small: true,
  }

  return (
    <Widget
      id="ExpiringContractsWidget"
      title={t('Lease contract(s) expiring within')}
      icon="contract-break"
      select={selectOptions}
      draggable={draggable}
    >
      <Modal
        id="ExpiringContractsWidgetModal"
        title={modalTitle}
        onClose={(): void => setModalType(undefined)}
        show={!!modalType}
        className={Styles.modal}
      >
        {modalType === 'car' && <CarContractsTable dateRange={range} select={selectOptions} />}
        {modalType === 'bike' && <BicycleContractsTable dateRange={range} select={selectOptions} />}
      </Modal>

      {((): JSX.Element => {
        const carCount = data?.lease?.carContractsAggregate?.aggregate?.count || 0
        const bikeCount = data?.lease?.bicycleContractsAggregate?.aggregate?.count || 0

        if (loading) return <LoadingSkeleton />

        return (
          <List rows>
            <WidgetListItem onClick={(): void => setModalType('car')} count={carCount} disabled={draggable}>
              {t('Car')}
            </WidgetListItem>
            <WidgetListItem onClick={(): void => setModalType('bike')} count={bikeCount} disabled={draggable}>
              {t('Bicycle')}
            </WidgetListItem>
          </List>
        )
      })()}
    </Widget>
  )
}

ExpiringContractsWidget.displayName = 'ExpiringContractsWidget'
