import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../Button'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ConfirmFooter: React.FC<IProps> = ({
  cancelProps: { onClick: onCancel, label: cancelLabel },
  confirmProps: { onClick, label, disabled = false, loading = false, size = 'big' },
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <footer className={Styles.confirmFooter}>
      <Button id="cancel-button" variant="noBorder" onClick={onCancel} textOnly>
        {cancelLabel || t('Cancel')}
      </Button>
      <Button id="confirm-button" onClick={onClick} disabled={disabled} loading={loading} size={size}>
        {label || t('Confirm')}
      </Button>
    </footer>
  )
}
