import React from 'react'

import { Status } from '../../../../../../../Components/Status'
import { Typography } from '../../../../../../../Components/Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Total: React.FC<IProps> = ({ label, total }): JSX.Element => {
  return (
    <div className={Styles.total}>
      <Typography variant="body-2" className={Styles.label}>
        {label}
      </Typography>
      <Status label={total} />
    </div>
  )
}
