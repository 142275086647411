import React, { useContext } from 'react'

import { LayoutContext } from '../../Providers/LayoutProvider'
import { Header } from './Components/Header'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const PageContainer: React.FC<IProps> = ({ header, children, footerContent }): JSX.Element => {
  const { integrated } = useContext(LayoutContext)
  return (
    <div className={Styles.pageContainer}>
      <Header header={header} />

      <div className={`${Styles.content} ${!!footerContent && Styles.withFooter}`} data-testid="content">
        {children}
      </div>

      {footerContent && <div className={`${Styles.footer} ${integrated && Styles.integrated}`}>{footerContent}</div>}
    </div>
  )
}
