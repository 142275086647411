import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../../Components'
import { ColorPreview } from '../../../Components/ColorPreview'
import { PageContainer } from '../../../Components/PageContainer'
import { ButtonFooter } from '../../../Components/PageContainer/Components/Footers/ButtonFooter'
import { Pagination } from '../../../Components/Pagination'
import { Table } from '../../../Components/Table'
import { ROUTE_NAMES } from '../../../Layouts/Authorized/interfaces'
import { routes } from '../../../Layouts/Authorized/routes'
import { LayoutContext } from '../../../Providers/LayoutProvider'
import Styles from './styles.module.scss'

export const CustomerLabels: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { integrated } = useContext(LayoutContext)
  const [currentPage, setCurrentPage] = useState<number>(1)

  const tableConfig = {
    labelName: {
      name: t('Label name'),
      width: '267px',
    },
    host: {
      name: t('Host'),
      width: '300px',
    },
    primary: {
      name: t('Primary'),
      width: '458px',
    },
  }

  const navigateTo = (id: number): void => {
    if (!integrated) navigate(`${id}`)
  }

  const tableData = [
    {
      id: 'fallback',
      labelName: 'Fallback',
      host: 'fallback',
      primary: <ColorPreview color="green" />,
      onClick: (): void => {
        navigateTo(55)
      },
    },
    {
      id: '2',
      labelName: 'test',
      host: 'staging.reisbalans.nl',
      primary: <ColorPreview color="#e2e2e2" />,
      onClick: (): void => {
        navigateTo(51)
      },
    },
  ]

  const paginationChangeHandler = (page: number): void => {
    setCurrentPage(page)
  }

  return (
    <PageContainer
      header={{
        title: t('Customer labels'),
        showLine: true,
      }}
      footerContent={
        <ButtonFooter>
          <Button
            id="add-customer-label"
            icon="add"
            size="big"
            onClick={(): void => {
              navigate(routes[ROUTE_NAMES.CUSTOMER_LABELS_CREATE])
            }}
          >
            {t('Add customer label')}
          </Button>
        </ButtonFooter>
      }
    >
      <div className={Styles.wrapper}>
        <Table tableConfig={tableConfig} tableData={tableData} />

        <div className={Styles.pagination}>
          <Pagination
            totalItemsPerPage={10}
            totalItems={13}
            selected={currentPage}
            onChange={paginationChangeHandler}
            navArrows
            visiblePages={10}
          />
        </div>
      </div>
    </PageContainer>
  )
}
