import React from 'react'

import { MaybeRenderWithLabelHOC } from '../../../Lib/MaybeRenderWithLabelHOC'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

const ColorInputComponent: React.FC<IProps> = ({ value, onChange, width = '100%' }): JSX.Element => {
  const isValidHex = (hex: string): boolean => {
    const hexRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
    return hexRegex.test(hex)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value
    const hexRegex = /^#[A-Fa-f0-9]{0,6}$/
    if (hexRegex.test(newValue) || newValue === '') onChange(newValue)
  }

  const inputValue = ((): string => {
    if (value.startsWith('#')) return value
    return `#${value}`
  })()

  return (
    <div
      className={Styles.colorInputWrapper}
      style={{
        width,
      }}
    >
      <span
        data-testid="color-preview"
        {...(isValidHex(inputValue) && { style: { backgroundColor: inputValue } })}
        className={Styles.color}
      />

      <input
        data-testid="color-input"
        name="color-input"
        className={Styles.input}
        value={inputValue}
        onChange={handleChange}
      />
    </div>
  )
}

export const ColorInput = MaybeRenderWithLabelHOC(ColorInputComponent)
