import React, { useEffect } from 'react'

import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Item = React.memo(
  // eslint-disable-next-line sonarjs/cognitive-complexity
  React.forwardRef<HTMLLIElement, IProps>((props, ref) => {
    const {
      dragOverlay,
      dragging,
      disabled,
      fadeIn,
      handleProps,
      index,
      listeners,
      style,
      transition,
      transform,
      children,
      ...restProps
    } = props

    useEffect(() => {
      if (!dragOverlay) return

      document.body.style.cursor = 'grabbing'

      return (): void => {
        document.body.style.cursor = ''
      }
    }, [dragOverlay])

    const listItemStyles = ((): string => {
      const classes: string[] = [Styles.wrapper]
      if (fadeIn) classes.push(Styles.fadeIn)
      if (dragOverlay) classes.push(Styles.dragOverlay)
      if (!disabled) classes.push(Styles.dragCursor)

      return classes.join(' ')
    })()

    const itemStyles = ((): string => {
      const classes: string[] = [Styles.item]
      if (fadeIn) classes.push(Styles.fadeIn)
      if (dragging) classes.push(Styles.dragging)
      if (dragOverlay) classes.push(Styles.dragOverlay)

      return classes.join(' ')
    })()

    const dragableProps = {
      ...handleProps,
      ...listeners,
    }

    return (
      <div
        {...(!disabled && { ...dragableProps })}
        className={listItemStyles}
        style={
          {
            transition: [transition].filter(Boolean).join(', '),
            '--translate-x': transform ? `${Math.round(transform.x)}px` : undefined,
            '--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
            '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
            '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
            '--index': index,
          } as React.CSSProperties
        }
        ref={ref}
      >
        <div className={itemStyles} style={style} {...restProps}>
          {children}
        </div>
      </div>
    )
  })
)
