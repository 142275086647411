import React, { RefObject } from 'react'
import { useTranslation } from 'react-i18next'

import { Link } from '../Link'
import { Typography } from '../Typography'
import useInView from './Hooks/useInView'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const QuickNav: React.FC<IProps> = ({ scrollContainerId, items, offset = 0 }): JSX.Element => {
  const { t } = useTranslation()
  const { active } = useInView(0, items)

  const getLinkClasses = (index: number): string => {
    return active === index ? [Styles.link, Styles.active].join(' ') : Styles.link
  }

  const onClick = (itemRef: RefObject<HTMLDivElement>, index: number): void => {
    const element = itemRef.current
    if (!element) return

    const offsetTop = index > 0 ? element.offsetTop - offset : 0

    const scrollContainer = scrollContainerId ? document.getElementById(scrollContainerId) : null
    if (scrollContainer) {
      scrollContainer.scroll({ behavior: 'smooth', top: offsetTop })
      return
    }

    itemRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <nav className={Styles.quickNav}>
      <div className={Styles.inner}>
        <Typography variant="h2" element="h3" className={Styles.title}>
          {t('Quick to')}:
        </Typography>
        <ul className={Styles.list}>
          {items.map((item, index) => (
            <li key={index}>
              <Link
                className={getLinkClasses(index)}
                ariaLabel={item.title}
                withUnderline={false}
                onClick={(): void => onClick(item.ref, index)}
              >
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  )
}
