import React, { useCallback, useEffect, useRef, useState } from 'react'

import { IProps, TOption } from './interfaces'
import Styles from './styles.module.scss'

const OptionsList: React.FC<IProps> = ({ id, options, selected, height, onSelect }): JSX.Element => {
  const timePickerRef = useRef<HTMLUListElement>(null)
  const [selectedOption, setSelectedOption] = useState<string | number>()

  const getOptionElementWithValue = (value: string | number): HTMLDivElement | null => {
    if (timePickerRef?.current === null) return null

    return timePickerRef.current.querySelector(`div[data-value="${value}"]`)
  }

  const scrollOptionWithValueIntoView = useCallback((value: string | number): void => {
    const selectedOptionElement = getOptionElementWithValue(value)

    if (selectedOptionElement?.scrollIntoView) {
      selectedOptionElement.scrollIntoView({
        block: 'center',
        inline: 'nearest',
        behavior: 'auto',
      })
    }
  }, [])

  const getStyle = (): React.CSSProperties => {
    const styles: React.CSSProperties = {}

    if (height && typeof height === 'number') styles.height = `${height}px`
    else if (height) styles.height = height.toString()

    return styles
  }

  const getOptionClasses = (option: TOption): string => {
    const classes: Array<string> = [Styles.option]

    if (selectedOption === option.value) classes.push(Styles.selected)
    if (option.disabled) classes.push(Styles.disabled)

    return classes.join(' ')
  }

  const onOptionClick = (option: TOption): void => {
    setSelectedOption(option.value)

    if (onSelect) onSelect(option)
  }

  useEffect(() => {
    setSelectedOption(selected)
  }, [selected])

  useEffect(() => {
    if (selectedOption) scrollOptionWithValueIntoView(selectedOption)
  }, [selectedOption, scrollOptionWithValueIntoView])

  return (
    <ul id={id} className={Styles.optionsList} style={getStyle()} ref={timePickerRef}>
      {options.map((option: TOption) => {
        return (
          <li key={option.value}>
            <div
              className={getOptionClasses(option)}
              data-value={option.value}
              onClick={(): void => {
                if (option.disabled) return
                onOptionClick(option)
              }}
            >
              {option.label}
            </div>
          </li>
        )
      })}
    </ul>
  )
}

export default OptionsList
