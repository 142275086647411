import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../../../Components'
import { DatePicker } from '../../../../../Components/FormComponents/DatePicker'
import { Modal } from '../../../../../Components/Modal'
import { ConfirmFooter, ContentSection, Footer } from '../../../../../Components/Modal/Components'
import { IProps } from './interfaces'

export const TerminatePoolCar: React.FC<IProps> = ({ id }): JSX.Element => {
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)
  const [terminationDate, setTerminationDate] = useState<Date>(new Date())

  const handleClose = (): void => setShow(false)

  return (
    <React.Fragment>
      <Button id={`terminate-button-${id}`} size="xxs" variant="secondary" onClick={(): void => setShow(true)}>
        {t('Terminate')}
      </Button>

      <Modal id={`terminate-modal-${id}`} show={show} title={t('Terminate pool car')} onClose={handleClose}>
        <ContentSection title={`${t('Terminate by')}:`}>
          <DatePicker id="terminate-date" value={terminationDate} onChange={setTerminationDate} width="196px" />
        </ContentSection>

        <Footer>
          <ConfirmFooter
            cancelProps={{ onClick: handleClose }}
            confirmProps={{ label: t('Terminate', { context: 'long' }), onClick: (): void => {} }}
          />
        </Footer>
      </Modal>
    </React.Fragment>
  )
}
