import React from 'react'

import { Icon } from '../Icon'
import { Typography } from '../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Panel: React.FC<IProps> = ({ id, children, header, footer }): JSX.Element => {
  return (
    <div {...(id && { id })} className={Styles.panel}>
      {header && (
        <div className={`${Styles.header} ${header.className || ''}`}>
          {header.icon && <Icon name={header.icon} className={Styles.icon} />}
          <div className={Styles.titleContainer}>
            <Typography variant="h2" className={Styles.title}>
              {header.title}
            </Typography>
            {header.customComponent}
          </div>
          {header.actions && <div className={Styles.actions}>{header.actions}</div>}
        </div>
      )}
      <div>{children}</div>
      {footer && <div className={Styles.footer}>{footer}</div>}
    </div>
  )
}
