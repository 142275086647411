import React from 'react'

import { Typography } from '../../../../../../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const KeyValueListItem: React.FC<IProps> = ({
  label,
  children,
  labelWidth = '30%',
  labelAlign = 'top',
  variant = 'default',
}): JSX.Element => {
  const classNames = ((): string => {
    const classes: string[] = [Styles.listItem, Styles[variant]]

    if (labelAlign === 'middle') classes.push(Styles.labelAlignMiddle)
    if (labelAlign === 'bottom') classes.push(Styles.labelAlignBottom)

    return classes.join(' ')
  })()

  return (
    <li className={classNames}>
      <Typography variant="h2" element="div" className={Styles.label} style={{ minWidth: labelWidth }}>
        {label}
      </Typography>
      <div className={Styles.value}>{children || '-'}</div>
    </li>
  )
}
