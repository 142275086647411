import { IOption } from './interfaces'

export const options: IOption[] = [
  { value: 'blue', label: 'Blue' },
  { value: 'ocean', label: 'Ocean' },
  { value: 'purple', label: 'Purple' },
  { value: 'red', label: 'Red' },
  { value: 'orange', label: 'Orange' },
  { value: 'yellow', label: 'Yellow' },
  { value: 'green', label: 'Green' },
  { value: 'forest', label: 'Forest' },
  { value: 'slate', label: 'Slate' },
  { value: 'silver', label: 'Silver' },
]

export const preSelectedOptions = options.slice(0, 7)
