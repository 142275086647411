import React from 'react'
import { useTranslation } from 'react-i18next'

import { FileUpload } from '../../../FormComponents/FileUpload'
import { ContentSection } from '../ContentSection'
import { IProps } from './interfaces'

export const FileUploadSection: React.FC<IProps> = ({ file, onChange }): JSX.Element => {
  const { t } = useTranslation()

  return (
    <ContentSection title={t('Upload PDF file')}>
      <FileUpload
        type="file"
        value={file}
        onChange={onChange}
        accept="application/pdf"
        label={t('Upload the PDF file containing the conditions.')}
      />
    </ContentSection>
  )
}
