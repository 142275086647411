import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MenuListProps, OptionProps } from 'react-select'

import { Typography } from '../../../../Typography'
import { IOption } from '../../../interfaces'
import Styles from './styles.module.scss'

export const ToggleAll = (props: MenuListProps<IOption, true>): JSX.Element | null => {
  const { children, selectProps } = props
  const elements = children as ReactElement<OptionProps<IOption, true>>[]

  const { t } = useTranslation()
  const [state, setState] = useState<'select' | 'deselect'>('select')

  const visibleOptions = ((): IOption[] => {
    if (!Array.isArray(elements)) return []
    return elements.map((element) => element.props.data)
  })()

  const containsOptions = useMemo(() => visibleOptions.length > 0, [visibleOptions])
  const selectedOptions = selectProps.value as IOption[]

  const handleSelectAll = (): void => {
    const uniqueIds = new Set()
    const list = [...selectedOptions, ...visibleOptions]
    const selectableOptions = list.filter((obj) => !uniqueIds.has(obj.value) && uniqueIds.add(obj.value))

    selectProps.onChange(selectableOptions, { option: undefined, action: 'select-option' })
    setState('deselect')
  }

  const handleDeSelectAll = (): void => {
    // Options that are visible and selected
    const removableOptions = visibleOptions.filter((visibleOption) =>
      selectedOptions.some((selectedOption) => selectedOption.value === visibleOption.value)
    )

    // Options that are not visible (by filter) and should not be removed
    const optionsToKeep = selectedOptions.filter(
      (selectedOption) => !removableOptions.some((removableOption) => selectedOption.value === removableOption.value)
    )

    selectProps.onChange(optionsToKeep, { removedValues: removableOptions, action: 'clear' })

    setState('select')
  }

  const handleClick = (): void => {
    if (state === 'select') handleSelectAll()
    else handleDeSelectAll()
  }

  useEffect(() => {
    if (!containsOptions) return

    const allItemsSelected = visibleOptions.every((visibleOption) =>
      selectedOptions.some((selectedOption) => visibleOption.value === selectedOption.value)
    )

    if (state === 'select' && allItemsSelected) setState('deselect')
    else if (state === 'deselect' && !allItemsSelected) setState('select')
  }, [containsOptions, selectedOptions, state, visibleOptions])

  if (!containsOptions) return null

  return (
    <Typography data-testid={`toggle-${state}`} onClick={handleClick} className={Styles.selectAll} variant="body-2">
      {state === 'select' ? t('Select all') : t('Deselect all')}
    </Typography>
  )
}
