import {
  CollisionDetection,
  defaultDropAnimationSideEffects,
  DndContext,
  DragOverlay,
  DropAnimation,
  getFirstCollision,
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  pointerWithin,
  rectIntersection,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { DragEndEvent, DragOverEvent } from '@dnd-kit/core/dist/types'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import { availableWidgets } from '../Widgets/helpers'
import { IBaseWidgetProps } from '../Widgets/interfaces'
import { Item } from './Components'
import { DraggableItem } from './Components/DraggableItem'
import { DroppableContainer } from './Components/DroppableContainer'
import { IProps, Items } from './interfaces'
import { coordinateGetter as multipleContainersCoordinateGetter } from './multipleContainersKeyboardCoordinates'
import Styles from './styles.module.scss'

const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
}

export const DraggableContainer: React.FC<IProps> = ({ items, onChange, disabled }): JSX.Element => {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)
  const lastOverId = useRef<UniqueIdentifier | null>(null)
  const recentlyMovedToNewContainer = useRef(false)

  const findElementById = (id: UniqueIdentifier): JSX.Element | null => {
    return React.createElement<IBaseWidgetProps>(availableWidgets[id], { draggable: !disabled })
  }

  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args)
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args)
      const overId = getFirstCollision(intersections, 'id')

      if (overId != null) {
        lastOverId.current = overId

        return [{ id: overId }]
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) lastOverId.current = activeId

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : []
    },
    [activeId]
  )
  const [clonedItems, setClonedItems] = useState<Items | null>(null)
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: multipleContainersCoordinateGetter,
    })
  )
  const findContainer = (id: UniqueIdentifier): string | number | undefined => {
    if (id in items) return id

    return Object.keys(items).find((key) => items[key].some((child) => child === id))
  }

  const getIndex = (id: UniqueIdentifier): number => {
    const container = findContainer(id)

    if (!container) return -1

    return items[container].findIndex((child) => child === id)
  }

  const onDragCancel = (): void => {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      onChange(clonedItems)
    }

    setActiveId(null)
    setClonedItems(null)
  }

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false
    })
  }, [items])

  const renderDraggableItemDragOverlay = (id: UniqueIdentifier): JSX.Element | null => {
    const element = findElementById(id)

    if (!element) return null

    return <Item dragOverlay>{element}</Item>
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const dragOverHandler = ({ active, over }: DragOverEvent): void => {
    const overId = over?.id

    if (overId == null) return

    const overContainer = findContainer(overId)
    const activeContainer = findContainer(active.id)

    if (!overContainer || !activeContainer) return

    if (activeContainer !== overContainer) {
      onChange((prevItems) => {
        const activeItems = prevItems[activeContainer]
        const overItems = prevItems[overContainer]

        const overIndex = overItems.findIndex((child) => child === overId)
        const activeIndex = activeItems.findIndex((child) => child === active.id)

        let newIndex: number

        if (overId in prevItems) newIndex = overItems.length + 1
        else {
          const isBelowOverItem =
            over &&
            active.rect.current.translated &&
            active.rect.current.translated.top > over.rect.top + over.rect.height

          const modifier = isBelowOverItem ? 1 : 0

          newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1
        }

        recentlyMovedToNewContainer.current = true

        return {
          ...prevItems,
          [activeContainer]: prevItems[activeContainer].filter((item) => item !== active.id),
          [overContainer]: [
            ...prevItems[overContainer].slice(0, newIndex),
            prevItems[activeContainer][activeIndex],
            ...prevItems[overContainer].slice(newIndex, prevItems[overContainer].length),
          ],
        }
      })
    }
  }

  const dragEndHandler = ({ active, over }: DragEndEvent): void => {
    const activeContainer = findContainer(active.id)

    if (!activeContainer) {
      setActiveId(null)
      return
    }

    const overId = over?.id

    if (overId == null) {
      setActiveId(null)
      return
    }

    const overContainer = findContainer(overId)

    if (overContainer) {
      const activeIndex = items[activeContainer].findIndex((child) => child === active.id)
      const overIndex = items[overContainer].findIndex((child) => child === overId)

      if (activeIndex !== overIndex) {
        onChange((prevItems) => ({
          ...prevItems,
          [overContainer]: arrayMove(prevItems[overContainer], activeIndex, overIndex),
        }))
      }
    }

    setActiveId(null)
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={({ active }): void => {
        setActiveId(active.id)
        setClonedItems(items)
      }}
      onDragOver={dragOverHandler}
      onDragEnd={dragEndHandler}
      onDragCancel={onDragCancel}
    >
      <div className={Styles.container}>
        {Object.keys(items).map((containerId) => (
          <DroppableContainer
            disabled={disabled}
            key={containerId}
            id={containerId}
            items={items[containerId].map((child) => child)}
          >
            <SortableContext items={items[containerId].map((item) => item)} strategy={verticalListSortingStrategy}>
              {items[containerId].map((value, index) => {
                return (
                  <DraggableItem
                    key={`item-${value}`}
                    id={value}
                    disabled={disabled}
                    index={index}
                    containerId={containerId}
                    getIndex={getIndex}
                  >
                    {React.createElement<IBaseWidgetProps>(availableWidgets[value], { draggable: !disabled })}
                  </DraggableItem>
                )
              })}
            </SortableContext>
          </DroppableContainer>
        ))}
      </div>
      {createPortal(
        <DragOverlay adjustScale={false} dropAnimation={dropAnimation}>
          {((): JSX.Element | null => {
            if (!activeId) return null

            return renderDraggableItemDragOverlay(activeId)
          })()}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  )
}
