import React, { useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'

import { Typography } from '../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ProgressBar: React.FC<IProps> = ({ label, value, percentage }): JSX.Element => {
  const nodeRef = useRef(null)

  const [animateBar, setAnimateBar] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => setAnimateBar(true), 300)
  }, [])

  return (
    <div className={Styles.progressBar} data-testid="progressBar">
      <div className={Styles.barContainer} style={{ width: `${percentage}%` }}>
        <CSSTransition nodeRef={nodeRef} in={animateBar} timeout={500} classNames="progressBar">
          <div ref={nodeRef} className={Styles.bar} />
        </CSSTransition>
      </div>
      <div className={Styles.overlay}>
        <Typography variant="h3" element="p" className={Styles.label}>
          {label}
        </Typography>
        <Typography data-testid="progress-bar-value" variant="h2" element="p" className={Styles.value}>
          {value}
        </Typography>
      </div>
    </div>
  )
}
