import React from 'react'

import { Typography } from '../../../../Components/Typography'
import { getValidValue } from '../../helpers'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Cell: React.FC<IProps> = ({ value, subValue }) => {
  return (
    <div className={Styles.cell}>
      <Typography variant="body-2" element="span" className={Styles.value}>
        {getValidValue(value)}
      </Typography>
      {subValue && (
        <Typography variant="body-3" element="span" className={Styles.subValue}>
          {subValue}
        </Typography>
      )}
    </div>
  )
}
