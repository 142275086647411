import { isSameDay, isSameHour, setHours, setMinutes } from 'date-fns'

import { TDisableTimes } from '../DateTimePicker/interfaces'

export const getDisabledTimes = (minDate: Date, selectedDate: Date): TDisableTimes => {
  // Create arrays to hold the disabled hours and minutes
  const disabledHours: number[] = []
  let disabledMinutes: number[] = []

  if (isSameDay(minDate, selectedDate)) {
    const minHours = minDate.getHours()
    const minMinutes = minDate.getMinutes()

    for (let i = 0; i < minHours; i += 1) disabledHours.push(i)

    if (isSameHour(minDate, selectedDate)) for (let i = 0; i < minMinutes; i += 1) disabledMinutes.push(i)
    if (selectedDate.getHours() < minDate.getHours()) disabledMinutes = Array.from(Array(60).keys())
  }

  // Return an object with the disabled hours and minutes
  return {
    disabledHours,
    disabledMinutes,
  }
}

/*
  Function is responsible for selecting a non-disabled date
 */
export const maybeGetFallbackForDate = (minDate: Date, newDate: Date): Date => {
  // Return if minDate is not set
  if (!minDate) return newDate

  const { disabledHours, disabledMinutes } = getDisabledTimes(minDate, newDate)
  // Return if empty
  if (disabledMinutes.length === 0 && disabledHours.length === 0) return newDate

  let finalDate = new Date(newDate.getTime())

  // Start by setting the hours
  if (disabledHours.includes(newDate.getHours())) finalDate = setHours(finalDate, disabledHours.length)

  // Get a new list of disabled minutes as the date has been changed
  const disabledTimesForFinalDate = getDisabledTimes(minDate, finalDate)
  if (disabledTimesForFinalDate.disabledMinutes.includes(finalDate.getMinutes()))
    finalDate = setMinutes(finalDate, disabledTimesForFinalDate.disabledMinutes.length)

  return finalDate
}
