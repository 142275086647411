import React, { ReactElement, useContext } from 'react'

import { LayoutContext } from '../../Providers/LayoutProvider'
import { IProps as IButton } from '../Button/interfaces'
import { TitleContainer } from '../PageHeader/Components/TitleContainer'
import { QuickNav } from '../QuickNav'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const SmartPageHeader: React.FC<IProps> = ({
  buttons,
  title,
  headerCustomComponent,
  quickNavProps,
}): JSX.Element => {
  const { integrated } = useContext(LayoutContext)

  const getButtons = (): Array<ReactElement<IButton>> => {
    if (!buttons) return []
    if (Array.isArray(buttons)) return buttons

    return [buttons]
  }

  return (
    <div className={`${Styles.smartHeader} ${integrated && Styles.integrated}`}>
      <TitleContainer title={title} headerCustomComponent={headerCustomComponent} isHeaderFixed />

      {(!!buttons || !!quickNavProps) && (
        <div className={Styles.content}>
          {!!quickNavProps?.items && (
            <QuickNav
              items={quickNavProps.items}
              scrollContainerId={quickNavProps.scrollContainerId}
              offset={quickNavProps.offset}
            />
          )}

          {buttons && (
            <div className={Styles.buttonsContainer}>
              {getButtons().map((button, index) => React.cloneElement(button, { key: index }))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
