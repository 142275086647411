import { TSize } from './interfaces'

export const getImageSize = (source: string): Promise<TSize> => {
  return new Promise((resolve, reject) => {
    const img = new Image()

    img.onload = (): void => {
      const { width, height } = img

      resolve({ width, height })
    }

    img.onerror = (): void => {
      reject(new Error('Failed to load the image'))
    }

    img.src = source
  })
}
