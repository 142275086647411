import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../Components'
import { ConfirmModal } from '../../../Components/ConfirmModal'
import { IOption } from '../../../Components/MultiSelect/interfaces'
import { PageContainer } from '../../../Components/PageContainer'
import { FieldPanel } from './Components/FieldPanel'

const GetConnectorDetails = (): JSX.Element => {
  const labelName = '[TITLE]'
  const { t } = useTranslation()
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  const deleteGetConnector = (): void => {
    setShowDeleteModal(false)
  }

  const options: IOption[] = [
    { label: 'AfasUpdateConnector', value: '1' },
    { label: 'Demo Louwman', value: '2' },
  ]

  return (
    <PageContainer
      header={{
        title: '[labelName]',
        headerCustomComponent: (
          <Button variant="secondary" onClick={(): void => setShowDeleteModal(true)}>
            {t('Delete', { context: 'short' })}
          </Button>
        ),
      }}
    >
      <FieldPanel id="booleanField" type="textArea" title={t('Boolean fields')} value={undefined} />

      <FieldPanel id="conditionalFields" type="textArea" title={t('Conditional fields')} value={undefined} />

      <FieldPanel
        id="renamedFields"
        title={t('Renamed fields')}
        type="textArea"
        value={'[{:to=>"organization_id", :from=>"organization_ID"}]'}
      />

      <FieldPanel
        id="accountingPackages"
        type="textArea"
        title={t('Accounting packages')}
        value="AfasUpdateConnector - OLVG Lab"
      />

      <FieldPanel id="organisations" type="multiSelect" title={t('Organisations')} values={options} options={options} />

      {showDeleteModal && (
        <ConfirmModal
          content={t('Are you sure you want to remove the {{ labelName }} Get Connector?', { labelName })}
          description={t('This action is irreversible.')}
          confirmButtonProps={{ onClick: deleteGetConnector, content: t('Yes, remove') }}
          onCancel={(): void => setShowDeleteModal(false)}
        />
      )}
    </PageContainer>
  )
}

export default GetConnectorDetails
