import { TableNode } from '@table-library/react-table-library'

import { IProps as IFormSelect } from '../FormComponents/Select/interfaces'

export interface ITableFieldsDisplaySettings {
  __default: boolean
  order?: number
  showFields: string[]
}

export type TTableDisplaySettings = { [key: string]: ITableFieldsDisplaySettings }

export type TOption = { label: string; value: string | number }
export interface ISelect<ObjectValue>
  extends Pick<
    IFormSelect<ObjectValue>,
    'options' | 'disabled' | 'small' | 'onChange' | 'value' | 'menuPlacement' | 'menuAlignment'
  > {
  name: string
}

export interface ITableProps {
  cellGenerator?: (config: ITableConfig, rowData: TableNode) => JSX.Element[]
  tableConfig: ITableConfig
  tableData: TableNode[]
  columnWidth?: number
  loading?: boolean
  tableWidth?: number | string
  tableName: string
  showCustomization?: boolean
  totalResults?: number
  perPage?: number
  refetch?: (variables: any) => void
  defaultOrder?: { [key: string]: string }
  select?: ISelect<TOption>
}

export interface ITableConfig {
  [key: string]: IColumnData
}

export enum ESortDirection {
  ASC,
  DESC,
  NONE,
}

export type TConvertedObject = { [key: string]: TConvertedObject } | string

export interface IColumnData {
  required?: boolean
  pinLeft?: boolean
  align?: 'left' | 'center' | 'right'
  hide?: boolean
  name: string
  sortable?: boolean
  defaultSort?: ESortDirection
  filter?: { [key: string]: string }
  orderByKey?: string
}
