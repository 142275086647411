import { FC, useState } from 'react'

import { Icon } from '../../../Icon'
import { ESortDirection } from '../../interfaces'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

const iconForSortDirection = (direction: string): JSX.Element => {
  switch (direction) {
    case 'ASC':
      return <Icon className={Styles.flip} name="sort-down" />
    case 'DESC':
      return <Icon name="sort-down" />
    default:
      return <Icon name="sort-up-down">↕</Icon>
  }
}

export const SortIndicator: FC<IProps> = ({ direction = ESortDirection.NONE, name, onChange }): JSX.Element => {
  const [currentClickIndex, setCurrentClickIndex] = useState<number>(direction)

  const handleDirectionChange = (): void => {
    let clickIndex = currentClickIndex + 1
    if (clickIndex > 2) clickIndex = 0

    setCurrentClickIndex(clickIndex)
    onChange(name, clickIndex)
  }

  return (
    <span data-testid={`sort-${name}`} className={Styles.wrapper} onClick={handleDirectionChange}>
      {iconForSortDirection(ESortDirection[currentClickIndex])}
    </span>
  )
}
