import { TLocale } from '../sharedInterfaces'
import { applyPrecision, getSettings } from './helpers'

export type TDistanceUnit = 'Meter' | 'Kilometer'
export type TDistanceAbbreviation = 'm' | 'km'

export interface IOptions {
  asHTML?: boolean
  abbreviation?: boolean
  precision?: number
  zeros?: boolean
}

interface IDistanceResult {
  value: number
  abbreviation: TDistanceAbbreviation
  unit: TDistanceUnit
}

const getDistanceResult = (valueInMeters: number): IDistanceResult => {
  if ((valueInMeters >= 1 && valueInMeters <= 999) || (valueInMeters <= 0 && valueInMeters >= -999))
    return { value: valueInMeters, unit: 'Meter', abbreviation: 'm' }

  return { value: valueInMeters / 1000, unit: 'Kilometer', abbreviation: 'km' }
}

const formatAsText = (result: IDistanceResult, locale: TLocale, options: IOptions): string => {
  const { abbreviation = true, precision = 2, zeros = false } = options
  const calculatePrecision = applyPrecision(result.value, precision, zeros)
  const intlOptions = getSettings({
    precision,
    zeros,
    value: calculatePrecision,
  })
  const distanceFormattedBasedOnLocale = new Intl.NumberFormat(locale, intlOptions).format(Number(calculatePrecision))

  return `${distanceFormattedBasedOnLocale} ${abbreviation ? result.abbreviation : result.unit}`
}

const formatAsHTML = (result: IDistanceResult, locale: TLocale, options: IOptions): string => {
  const parts = formatAsText(result, locale, options).split(' ')
  return `<span data-distance data-distance-value>${parts[0]}</span><span data-distance data-distance-unit>${parts[1]}</span>`
}

export const formatDistance = (distanceInMeters: number, locale: TLocale, options: IOptions = {}): string => {
  const { asHTML = false } = options

  const result = getDistanceResult(distanceInMeters)
  return asHTML ? formatAsHTML(result, locale, options) : formatAsText(result, locale, options)
}
