import React from 'react'
import { useTranslation } from 'react-i18next'

import { Status } from '../../../../../Components/Status'
import { Typography } from '../../../../../Components/Typography'
import { formatDateString } from '../../../../../Lib/Formatters/DateFormatter'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const StatusColumnValue: React.FC<IProps> = ({ status, dateRange }): JSX.Element => {
  const { t } = useTranslation()

  const humanizedDate = ((): string | null => {
    if (!dateRange) return null
    const { startsAt, endsAt } = dateRange
    const format = 'dd-MM-yy'

    const lst: string[] = [formatDateString(startsAt, format, 'nl')]
    const end = endsAt ? formatDateString(endsAt, format, 'nl') : t('to be determined')
    lst.push(end)

    return lst.join(' / ')
  })()

  // TODO: When data available make the right color / status mapping
  const statusColor = '#00C851'

  return (
    <React.Fragment>
      <Status label={status} color={statusColor} />

      {humanizedDate && (
        <Typography className={Styles.date} variant="body-2">
          {humanizedDate}
        </Typography>
      )}
    </React.Fragment>
  )
}
