import React from 'react'

import { Icon } from '../../../Icon'
import { Typography } from '../../../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Badge: React.FC<IProps> = ({ disabled, removeable, label, onClick }): JSX.Element => {
  const handleClick = (): void => {
    if (!disabled) onClick()
  }

  return (
    <div
      data-testid={`badge-${label}`}
      onClick={handleClick}
      className={`${Styles.badge} ${disabled ? Styles.disabled : ''}`}
    >
      <Typography variant="h3" element="span">
        {label}
      </Typography>

      {removeable && <Icon name="close" width={16} height={16} />}
    </div>
  )
}
