import React from 'react'
import { OptionProps } from 'react-select'

import { Checkbox } from '../../../FormComponents/Checkbox'
import { IOption } from '../../interfaces'
import Styles from './styles.module.scss'

export const Option = (props: OptionProps<IOption, true>): JSX.Element => {
  const { data, selectOption, isSelected } = props
  const { value, label } = data

  const handleClick = (): void => selectOption(data)

  return (
    <div onClick={handleClick} className={Styles.option}>
      <Checkbox id={value} className={Styles.checkbox} label={label} checked={isSelected} onChange={handleClick} />
    </div>
  )
}
