import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

import Styles from './styles.module.scss'

export const LoadingSkeleton: FC<{ icon?: boolean; bottomBorder?: boolean }> = ({ icon, bottomBorder }) => {
  const cssClasses: string = ((): string => {
    const classes: string[] = [Styles.item]
    if (bottomBorder) classes.push(Styles.bottomBorder)
    return classes.join(' ')
  })()

  return (
    <div className={cssClasses}>
      <div className={Styles.wrapper}>
        {icon && <Skeleton className={Styles.icon} width={28} height={28} />}
        <Skeleton className={Styles.name} width={90} />
      </div>

      <div className={Styles.end}>
        <Skeleton width={29} height={29} />
      </div>
    </div>
  )
}
