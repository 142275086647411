import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { ActiveBadge } from '../../../../../Components/ActiveBadge'
import { ColorPreview } from '../../../../../Components/ColorPreview'
import { ImagePreview } from '../../../../../Components/ImagePreview'
import LogoOnBlack from '../../../../../Components/ImagePreview/Images/logo_on_black.png'
import LogoOnWhite from '../../../../../Components/ImagePreview/Images/logo_on_white.png'
import { Panel } from '../../../../../Components/Panel'
import { List } from '../../../../../Components/Panel/Components/Content/List'
import { Typography } from '../../../../../Components/Typography'
import { ThemeContext } from '../../../../../Providers/ThemeProvider'
import { Item } from '../Item'
import { Edit } from './Components/Edit'
import { rgbToHex } from './helpers'

export const Settings = (): JSX.Element => {
  const { t } = useTranslation()
  const { currentTheme } = useContext(ThemeContext)

  const primaryColorAsHex = ((): string | null => {
    const rgba = currentTheme.primaryColor?.rgba
    if (!rgba) return null
    const { red, blue, green } = rgba

    const rgbaString = `rgba(${red}, ${green}, ${blue})`
    return rgbToHex(rgbaString)
  })()

  return (
    <Panel
      header={{
        title: t('Settings'),
        actions: <Edit />,
      }}
    >
      <List rows>
        <Item label={t('Admin')}>
          <ActiveBadge active={false} />
        </Item>

        <Item label={t('Primary color')}>
          <ColorPreview color="var(--primary-color)" label={primaryColorAsHex || '-'} />
        </Item>

        <Item label={t('Side bar color')}>
          <ColorPreview color="#fff" label="Licht" />
        </Item>

        <Item label={t('CSS overrides')}>
          <Typography variant="body-2">-</Typography>
        </Item>

        <Item label={t('Icon black background')}>
          <ImagePreview src={LogoOnBlack} backgroundColor="#000" />
        </Item>

        <Item label={t('Icon white background')}>
          <ImagePreview src={LogoOnWhite} backgroundColor="#fff" />
        </Item>

        <Item label={t('Logo black background')}>
          <ImagePreview src={LogoOnBlack} backgroundColor="#000" />
        </Item>

        <Item label={t('Logo white background')}>
          <ImagePreview src={LogoOnWhite} backgroundColor="#fff" />
        </Item>

        <Item label={t('Icon black background')}>
          <Typography variant="body-2">-</Typography>
        </Item>

        <Item label={t('Favicon')}>
          <Typography variant="body-2">-</Typography>
        </Item>

        <Item label={t('Login cover')}>
          <Typography variant="body-2">-</Typography>
        </Item>
      </List>
    </Panel>
  )
}
