import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { createNetworkStatusNotifier } from 'react-apollo-network-status'

import { authorizedClient, unauthorizedClient } from '../../Lib/apolloClient'
import { AdminLoginDocument, GetJwtDocument, LogoutDocument } from '../../Lib/graphql'
import { LocaleContext } from '../LocaleProvider'
import { IAuthContext } from './interfaces'

export const AuthContext = React.createContext<IAuthContext>({
  login: (_email, _password, _otp, _remember): Promise<boolean> => Promise.resolve(false),
  logout: (): void => {},
  refreshToken: (): void => {},
  jwtToken: '',
  loggedIn: false,
  initializing: true,
})

export const AuthProvider: React.FC<{ children: React.ReactNode }> = (props): JSX.Element => {
  const [jwtToken, setJwtToken] = useState<string | null>(localStorage.getItem('jwtToken'))
  const [loggedIn, setLoggedIn] = useState<boolean>(!!localStorage.getItem('jwtToken'))
  const [initializing, setInitializing] = useState<boolean>(true)

  const { children } = props
  const { locale } = useContext(LocaleContext)

  const logout = useCallback((): void => {
    const { link } = createNetworkStatusNotifier()
    const client = authorizedClient(null, link, async () => {}, locale)
    client
      .mutate({ mutation: LogoutDocument, variables: { input: {} } })
      .then(() => {
        setJwtToken(null)
        setLoggedIn(false)
      })
      .catch(() => {
        // FIXME: what to do now? inform the user? Send to New Relic?
      })

    localStorage.removeItem('jwtToken')
  }, [locale])

  const handleLoginSuccess = useCallback(
    (jwt: string, remember?: boolean): void => {
      if (jwtToken !== '' && remember) localStorage.setItem('jwtToken', jwt)

      setJwtToken(jwt)
      setLoggedIn(true)
      setInitializing(false)
    },
    [jwtToken]
  )

  const login = useCallback(
    async (
      userEmail: string | undefined,
      password: string | undefined,
      otp: string | undefined,
      remember?: boolean
    ): Promise<boolean> => {
      return new Promise((resolve) => {
        const client = unauthorizedClient(locale)
        client
          .mutate({
            mutation: AdminLoginDocument,
            variables: { email: userEmail || '', password: password || '', otp: otp || '' },
          })
          .then(({ data }) => {
            const { jwt, result } = data.reisbalans.adminLogin
            if (result.error) return resolve(false)
            handleLoginSuccess(jwt, remember)
            return resolve(true)
          })
          .catch(() => {
            // FIXME: what to do now? inform the user? Send to New Relic?
          })
      })
    },
    [handleLoginSuccess, locale]
  )

  const refreshToken = useCallback(
    (token: string): void => {
      const client = unauthorizedClient(locale)

      client
        .query({ query: GetJwtDocument, variables: { token } })
        .then((result) => {
          const { jwt } = result.data.reisbalans
          if (!jwt) {
            // this might happen if we reuse a refresh token, which is not allowed, in that case just see if we have an
            // old token and use that... otherwise just logout
            const oldToken = localStorage.getItem('jwtToken')
            if (oldToken) handleLoginSuccess(oldToken, true)
            else {
              setLoggedIn(false)
              setInitializing(false)
            }
            return
          }
          handleLoginSuccess(jwt, false)
        })
        .catch(() => {
          // FIXME: what to do now? inform the user? Send to New Relic?
        })
    },
    [handleLoginSuccess, locale]
  )

  useEffect((): void => {
    if (!initializing) return

    const token = new URLSearchParams(window.location.search).get('jwt')

    if (!token) {
      setInitializing(false)
      return
    }

    handleLoginSuccess(token, false)

    // refreshToken(token) // FIXME: will be done with ticket RBW-977
  }, [refreshToken, initializing, handleLoginSuccess])

  const options = useMemo(() => {
    return { jwtToken, login, logout, loggedIn, refreshToken, initializing }
  }, [jwtToken, login, logout, loggedIn, refreshToken, initializing])

  return <AuthContext.Provider value={options}>{children}</AuthContext.Provider>
}
