import React from 'react'

import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const LimitedListBadge: React.FC<IProps> = ({ limit, list }): JSX.Element => {
  const listLength = list.length
  const displayBadge = listLength > limit
  const itemsToDisplay = displayBadge ? [...list.slice(0, limit), '...'] : list

  return (
    <div>
      <span>{itemsToDisplay.join(', ')}</span>
      {displayBadge && <span className={Styles.badge}>{`+${listLength - limit}`}</span>}
    </div>
  )
}
