import i18n from 'i18next'
import React, { useEffect, useMemo, useState } from 'react'

import { TLocale } from '../../Lib/sharedInterfaces'

interface ILocaleContext {
  locale: TLocale
}

export const LocaleContext = React.createContext<ILocaleContext>({
  locale: 'en' as TLocale,
})

export const LocaleProvider = ({ children }: { children: JSX.Element }): JSX.Element | null => {
  const [initializing, setInitializing] = useState<boolean>(true)
  const requestedLocale = new URLSearchParams(window.location.search).get('locale') || 'nl'

  useEffect(() => {
    i18n
      .changeLanguage(requestedLocale)
      .then(() => setInitializing(false))
      .catch((err) => console.error('Error changing language:', err))
  }, [requestedLocale])

  const options = useMemo(() => {
    return {
      locale: requestedLocale as TLocale,
    }
  }, [requestedLocale])

  if (initializing) return null
  return <LocaleContext.Provider value={options}>{children}</LocaleContext.Provider>
}
