import { StylesConfig } from 'react-select'

import colors from '../../../Theme/colors.module.scss'

export const selectStyle: StylesConfig = {
  container: (provided) => {
    return {
      ...provided,
      width: '100%',
    }
  },
  valueContainer: (provided) => ({
    ...provided,
    padding: '0',
  }),
  control: (provided, state) => {
    return {
      ...provided,
      color: colors.gray900,
      border: `0.5px solid ${state.isFocused ? colors.gray600 : colors.gray200}`,
      padding: '10px 12px',
      fontSize: '14px',
      fontWeight: '400',
      outline: state.isFocused ? `0.5px solid ${colors.gray450}` : 'none',
    }
  },
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  // @ts-ignore
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    color: colors.gray650,
    padding: '0',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: colors.gray650,
    fontWeight: '400',
    fontSize: '14px',
    margin: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    borderRadius: '8px;',
    paddingTop: '0',
    paddingBottom: '0',
    padding: '24px 20px',
  }),
  option: (provided, { isSelected }) => {
    return {
      ...provided,
      color: colors.gray900,
      fontWeight: '500',
      fontSize: '14px',
      backgroundColor: colors.white,
      height: '48px',
      padding: '14px 12px',
      borderRadius: '2px',
      borderTop: `0.5px solid ${colors.gray200}`,
      '&:first-of-type': {
        borderTopColor: 'transparent',
      },
      '&:hover': {
        cursor: 'pointer',
        borderTopColor: 'transparent',
        ...(!isSelected
          ? { backgroundColor: 'var(--primary-color)', color: 'var(--primary-color-contrast)' }
          : { backgroundColor: 'var(--primary-color-opacity-80)' }),
      },
      '&:hover + div': {
        borderTopColor: 'transparent',
      },
      '&[class*="__option--is-selected"]': {
        borderTop: '0.5px solid transparent',
        color: 'var(--primary-color-contrast)',
        backgroundColor: 'var(--primary-color)',
      },
      '&[class*="__option--is-selected"] + div': {
        borderTopColor: 'transparent',
      },
    }
  },
  singleValue: (provided) => ({
    ...provided,
    fontSize: '14px',
    fontWeight: '400',
    color: colors.gray900,
  }),
}
