import React from 'react'

import { defaulticonsize } from '../../Theme/sizes.module.scss'
import { IProps } from './interface'

export const Icon: React.FC<IProps> = ({
  name,
  color = '#000',
  height = defaulticonsize,
  width = defaulticonsize,
  linked = false,
  ...svgProps
}): JSX.Element | null => {
  const symbolId = `#icon-${name}`

  return (
    <svg
      {...svgProps}
      width={width}
      height={height}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ cursor: linked ? 'pointer' : 'inherit' }}
    >
      <use xlinkHref={symbolId} fill={color} />
    </svg>
  )
}
