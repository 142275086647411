import { TOptions, TSettings } from '../sharedInterfaces'

export const applyPrecision = (value: number, precision?: number, zeros?: boolean): string => {
  if (!precision || precision === 0) return `${Math.round(value)}`
  const float = parseFloat(`${value}`).toFixed(precision)

  if (zeros === undefined || zeros) return float
  const parts = float.split('.')
  return parts[1].match(/^[0]+$/) ? parts[0] : float
}

const determineMinimum = ({ precision, zeros, value }: TOptions): number => {
  const isFractional = Number(value) % 1
  if (!isFractional && !zeros) return 0
  return precision
}

const determineMaximum = ({ precision, zeros, value }: TOptions): number => {
  const isFractional = Number(value) % 1
  if (!isFractional && !zeros) return 0
  if (isFractional && !zeros) {
    // First we get the number after the ".";
    // then we reduce that to the maximum length of precision
    // then we check for any trailing zeros and remove those.
    // now we count the length of the string and that's the maximum
    return value.split('.')[1].slice(0, precision).replace(/0+$/, '').length
  }
  return precision
}

export const getSettings = ({ precision, zeros, value }: TOptions): TSettings => {
  const maximumFractionDigits = determineMaximum({ precision, zeros, value })
  const min = determineMinimum({ precision, zeros, value })
  const minimumFractionDigits = min > maximumFractionDigits ? maximumFractionDigits : min
  return {
    minimumFractionDigits,
    maximumFractionDigits,
  }
}
