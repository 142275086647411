import React, { ReactNode, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { PageContainer } from '../../Components/PageContainer'
import { Panel } from '../../Components/Panel'
import { ListItem } from '../../Components/Panel/Components/Content/List/Components/ListItem'
import { TItem } from '../../Components/QuickNav/interfaces'
import { Section } from '../../Components/Section'
import { ROUTE_NAMES } from '../../Layouts/Authorized/interfaces'
import { routes } from '../../Layouts/Authorized/routes'
import { ListRow } from './Components/ListRow'
import { CreateTermsButton } from './CreateTermsButton'
import Styles from './styles.module.scss'

export const TermsConditions: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const PLATFORM = t('Platform')
  const LABEL = t('Label')
  const CONTRACT = t('Contract')
  const SERVICES = t('Services')
  const privacyConditions = t('Privacy conditions')
  const userTerms = t('User terms')
  const privacyConditionsEmployer = t('Privacy terms for the employer')

  const sections: TItem[] = [
    { title: PLATFORM, ref: useRef<HTMLDivElement>(null) },
    { title: LABEL, ref: useRef<HTMLDivElement>(null) },
    { title: CONTRACT, ref: useRef<HTMLDivElement>(null) },
    { title: SERVICES, ref: useRef<HTMLDivElement>(null) },
  ]

  const footer = (product: string, navigateTo: string): ReactNode => {
    return (
      <ul className={Styles.footer}>
        <ListItem onClick={(): void => navigate(`${navigateTo}`)}>
          {t('View previous {{ product }}', { product })}
        </ListItem>
      </ul>
    )
  }

  const getFooterTextForProduct = (product: string): string => t('{{ product }} terms and conditions', { product })

  return (
    <PageContainer
      header={{
        title: t('Terms and Conditions'),
        showLine: true,
        subTitle: 'Reisbalans',
        buttons: <CreateTermsButton />,
        quickNavItems: sections,
      }}
    >
      <Section title={PLATFORM} ref={sections[0].ref}>
        <Panel
          header={{ title: privacyConditions }}
          footer={footer(privacyConditions.toLowerCase(), routes[ROUTE_NAMES.TERMS_CONDITIONS_PREVIOUS])}
        >
          <ListRow title="Februari 2023" date="03-10-2023" time="06:14" onClick={(): void => {}} />
        </Panel>

        <Panel
          header={{ title: userTerms }}
          footer={footer(userTerms.toLowerCase(), routes[ROUTE_NAMES.TERMS_CONDITIONS_PREVIOUS])}
        >
          <ListRow title="Februari 2023" date="03-10-2023" time="06:14" onClick={(): void => {}} />
        </Panel>
      </Section>

      <Section title={LABEL} ref={sections[1].ref}>
        <Panel
          header={{ title: userTerms }}
          footer={footer(userTerms.toLowerCase(), routes[ROUTE_NAMES.TERMS_CONDITIONS_PREVIOUS])}
        >
          <ListRow title="Freddi" date="03-10-2023" time="06:14" onClick={(): void => {}} />
          <ListRow title="Reisbalans" date="03-10-2023" time="06:14" onClick={(): void => {}} />
          <ListRow title="Reizigers" date="03-10-2023" time="06:14" onClick={(): void => {}} />
        </Panel>

        <Panel
          header={{ title: t('User terms for the employer') }}
          footer={footer(userTerms.toLowerCase(), routes[ROUTE_NAMES.TERMS_CONDITIONS_PREVIOUS])}
        >
          <ListRow title="Freddi" date="03-10-2023" time="06:14" onClick={(): void => {}} />
          <ListRow title="Reisbalans" date="03-10-2023" time="06:14" onClick={(): void => {}} />
          <ListRow title="Reizigers" date="03-10-2023" time="06:14" onClick={(): void => {}} />
        </Panel>

        <Panel
          header={{ title: privacyConditions }}
          footer={footer(privacyConditions.toLowerCase(), routes[ROUTE_NAMES.TERMS_CONDITIONS_PREVIOUS])}
        >
          <ListRow title="Freddi" date="03-10-2023" time="06:14" onClick={(): void => {}} />
          <ListRow title="Reisbalans" date="03-10-2023" time="06:14" onClick={(): void => {}} />
          <ListRow title="Reizigers" date="03-10-2023" time="06:14" onClick={(): void => {}} />
        </Panel>

        <Panel
          header={{ title: privacyConditionsEmployer }}
          footer={footer(privacyConditionsEmployer.toLowerCase(), routes[ROUTE_NAMES.TERMS_CONDITIONS_PREVIOUS])}
        >
          <ListRow title="Freddi" date="03-10-2023" time="06:14" onClick={(): void => {}} />
          <ListRow title="Reisbalans" date="03-10-2023" time="06:14" onClick={(): void => {}} />
          <ListRow title="Reizigers" date="03-10-2023" time="06:14" onClick={(): void => {}} />
        </Panel>
      </Section>

      <Section title={CONTRACT} ref={sections[2].ref}>
        <Panel
          header={{ title: t('Terms employer') }}
          footer={footer(t('employer conditions'), routes[ROUTE_NAMES.TERMS_CONDITIONS_PREVIOUS])}
        >
          <ListRow title="Naam X" date="03-10-2023" time="06:14" onClick={(): void => {}} />
          <ListRow title="Naam Y" date="03-10-2023" time="06:14" onClick={(): void => {}} />
          <ListRow title="Naam Z" date="03-10-2023" time="06:14" onClick={(): void => {}} />
          <ListRow title="Naam Z" date="03-10-2023" time="06:14" onClick={(): void => {}} />
        </Panel>

        <Panel
          header={{ title: t('Greenwheels') }}
          footer={footer(getFooterTextForProduct('Greenwheels'), routes[ROUTE_NAMES.TERMS_CONDITIONS_PREVIOUS])}
        >
          <ListRow title="Freddi" date="03-10-2023" time="06:14" onClick={(): void => {}} />
        </Panel>
      </Section>

      <Section title={SERVICES} ref={sections[3].ref}>
        <Panel
          header={{ title: t('Taxi') }}
          footer={footer(getFooterTextForProduct('Taxi'), routes[ROUTE_NAMES.TERMS_CONDITIONS_PREVIOUS])}
        >
          <ListRow title="Kentest" date="03-10-2023" time="06:14" onClick={(): void => {}} />
        </Panel>

        <Panel
          header={{ title: 'OV-fiets' }}
          footer={footer(getFooterTextForProduct('OV-fiets'), routes[ROUTE_NAMES.TERMS_CONDITIONS_PREVIOUS])}
        >
          <ListRow title="Reizigers" date="03-10-2023" time="06:14" onClick={(): void => {}} />
        </Panel>

        <Panel
          header={{ title: 'Huurauto' }}
          footer={footer(getFooterTextForProduct('Huurauto'), routes[ROUTE_NAMES.TERMS_CONDITIONS_PREVIOUS])}
        >
          <ListRow title="Reizigers" date="03-10-2023" time="06:14" onClick={(): void => {}} />
        </Panel>

        <Panel
          header={{ title: t('Amber') }}
          footer={footer(getFooterTextForProduct('Amber'), routes[ROUTE_NAMES.TERMS_CONDITIONS_PREVIOUS])}
        >
          <ListRow title="Reizigers" date="03-10-2023" time="06:14" onClick={(): void => {}} />
        </Panel>

        <Panel
          header={{ title: 'Deelvervoer' }}
          footer={footer(getFooterTextForProduct('Deelvervoer'), routes[ROUTE_NAMES.TERMS_CONDITIONS_PREVIOUS])}
        >
          <ListRow title="Reizigers" date="03-10-2023" time="06:14" onClick={(): void => {}} />
        </Panel>
      </Section>
    </PageContainer>
  )
}
