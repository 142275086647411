import React from 'react'
import { components, InputProps } from 'react-select'

import { Icon } from '../../../Icon'
import { IOption } from '../../interfaces'
import Styles from './styles.module.scss'

export const Input = (props: InputProps<IOption, true>): JSX.Element => {
  return (
    <div className={Styles.inputWrapper}>
      <Icon className={Styles.icon} name="search" width={16} height={16} />
      <components.Input data-testid="multi-select-input" {...props} />
    </div>
  )
}
