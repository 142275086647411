import React, { useRef, useState } from 'react'

import { TIconName } from '../../Icon/iconNames'
import { Typography } from '../../Typography'
import { FileContent } from './Components/FileContent'
import { FileHeader } from './Components/FileHeader'
import { ImageContent } from './Components/ImageContent'
import { ImageHeader } from './Components/ImageHeader'
import { TProps } from './interfaces'
import Styles from './styles.module.scss'

export const FileUpload: React.FC<TProps> = ({
  type,
  accept,
  icon,
  label,
  errors,
  size,
  value,
  onChange,
  className,
}): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null)
  const [internErrors, setInternErrors] = useState<string[]>([])
  const getIcon = (): TIconName => icon || (type === 'file' ? 'file' : 'image')

  const allErrors = ((): string[] => {
    const list: string[] = []

    if (internErrors) list.push(...internErrors)
    if (errors) list.push(...errors)

    return list
  })()

  return (
    <div data-style-id="file-upload" className={className || ''}>
      {label && (
        <Typography className={`${Styles.label} ${allErrors.length > 0 ? Styles.error : ''}`} variant="body-2">
          {label}
        </Typography>
      )}
      <div className={Styles.container}>
        {type === 'image' && (
          <React.Fragment>
            <ImageHeader src={value?.data} icon={getIcon()} />

            <ImageContent
              innerRef={ref}
              accept={accept}
              value={value}
              size={size}
              onChange={onChange}
              setErrors={setInternErrors}
              hasError={allErrors.length > 0}
            />
          </React.Fragment>
        )}

        {type === 'file' && (
          <React.Fragment>
            <FileHeader fileName={value?.filename} icon={getIcon()} />
            <FileContent value={value} accept={accept} innerRef={ref} onChange={onChange} />
          </React.Fragment>
        )}

        {allErrors.map((error, index) => (
          <Typography key={`error-${index}`} variant="body-3" className={Styles.error}>
            {error}
          </Typography>
        ))}
      </div>
    </div>
  )
}
