import React from 'react'
import { useTranslation } from 'react-i18next'

import { ActiveBadge } from '../../../../../Components/ActiveBadge'
import { ImagePreview } from '../../../../../Components/ImagePreview'
import LogoOnBlack from '../../../../../Components/ImagePreview/Images/logo_on_black.png'
import LogoOnWhite from '../../../../../Components/ImagePreview/Images/logo_on_white.png'
import { Panel } from '../../../../../Components/Panel'
import { List } from '../../../../../Components/Panel/Components/Content/List'
import { Typography } from '../../../../../Components/Typography'
import { Item } from '../Item'
import { Edit } from './Components/Edit'

export const Email = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Panel
      header={{
        title: t('Email'),
        actions: <Edit />,
      }}
    >
      <List rows>
        <Item label={t('Use brand template')}>
          <ActiveBadge active={false} />
        </Item>

        <Item label={t('Template')}>
          <Typography variant="body-2">-</Typography>
        </Item>

        <Item label={t('Sender name')}>
          <Typography variant="body-2">-</Typography>
        </Item>

        <Item label={t('Address')}>
          <Typography variant="body-2">-</Typography>
        </Item>

        <Item label={t('Sent from')}>
          <Typography variant="h2">noreply@mail.com</Typography>
        </Item>

        <Item label={t('Salutation')}>
          <Typography variant="body-2">Geachte gebruiker,</Typography>
        </Item>

        <Item label={t('Closing')}>
          <Typography variant="body-2">
            Wilt u meer informatie? Neem dan gerust contact op met onze klantenservice of kijk op de servicepagina. U
            kunt ons 24/7 bereiken via --customer_service_telephone_linked-- of stuur een e-mail naar{' '}
            --customer_service_email_address_linked-- .Wij wensen u veel reisgemak! Met vriendelijke groet, Het
            Reisbalans team
          </Typography>
        </Item>

        <Item label={t('Header image')}>
          <ImagePreview src={LogoOnBlack} backgroundColor="#000" />
        </Item>

        <Item label={t('Header image style')}>
          <ImagePreview src={LogoOnWhite} backgroundColor="#fff" />
        </Item>
      </List>
    </Panel>
  )
}
