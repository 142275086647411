import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../Button'
import { LazyImage } from '../LazyImage'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ImagePreview: React.FC<IProps> = ({ src, backgroundColor }): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div className={Styles.imagePreview}>
      <div {...(backgroundColor && { style: { backgroundColor } })} className={Styles.imageWrapper}>
        <LazyImage src={src} alt="image" />
      </div>

      <Button
        className={Styles.button}
        icon="eye-show"
        size="small"
        variant="secondary"
        onClick={(): void => {
          window.open(src, '_blank')
        }}
      >
        {t('Show')}
      </Button>
    </div>
  )
}
