import { TableNode } from '@table-library/react-table-library'
import { add, differenceInCalendarMonths, format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LeasefleetTable } from '../../../../Components/LeasefleetTable'
import { ITableConfig } from '../../../../Components/LeasefleetTable/interfaces'
import { MaintenanceNotice } from '../../../../Components/MaintenanceNotice'
import { formatCurrency } from '../../../../Lib/Formatters/currencyFormatter'
// @ts-ignore
// import { useGetLeaseBicycleContractDataQuery } from '../../../../Lib/graphql'
import { TLocale } from '../../../../Lib/sharedInterfaces'
import { Cell } from '../../Components/Cell'
import { Contract } from '../../Components/Contract'
import { formatDate, getStatusTranslation } from '../../helpers'
import { IBikeContractProps } from '../../interfaces'
import { tableConfig } from './helpers'

export const BicycleContractsTable: React.FC<IBikeContractProps> = ({ dateRange, select }): JSX.Element => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const locale = language as TLocale

  const [rowData, setRowData] = useState<TableNode[]>([])

  const perPage = 7
  const isRangeProvided = !!dateRange
  const endDateRange = isRangeProvided
    ? { gte: format(dateRange.from, 'yyyy-MM-dd'), lte: format(dateRange.till, 'yyyy-MM-dd') }
    : undefined

  // @ts-ignore
  const { error, loading, data, refetch } = useGetLeaseBicycleContractDataQuery({
    variables: {
      limit: perPage,
      whereStatus: { eq: 'running' },
      expectedEndDate: endDateRange,
    },
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    const contracts = data?.lease?.contracts
    if (!contracts) return
    setRowData(contracts)
  }, [data])

  const cellDataGenerator = (config: ITableConfig, item: TableNode): JSX.Element[] => {
    return Object.keys(config).map((column) => {
      switch (column) {
        case 'contractNumber':
          return <Contract contractNumber={item.contractNumber} />

        case 'driver':
          return <Cell value={item.driverName} subValue={t('driver since {{ date }}', { date: item.driverSince })} />
        case 'kind':
          return (
            <Cell
              value={[item.vehicle?.brand, item.vehicle?.model, item.vehicle?.year]
                .filter((kind) => !!kind)
                .join(' - ')}
              subValue={item.vehicle?.modelSubtype}
            />
          )
        case 'contractPeriod':
          return (
            <Cell
              value={t('Start: {{ date }}', { date: formatDate(item.startDate, locale) })}
              subValue={t('End: {{ date }}', { date: formatDate(item.expectedEndDate, locale) })}
            />
          )

        case 'contractDuration':
          // eslint-disable-next-line no-case-declarations
          const diff = differenceInCalendarMonths(
            add(Date.parse(item.startDate), { months: item.durationInMonths }),
            Date.now()
          )

          return (
            <Cell
              value={t('Duration: {{ months }} months', { months: item.durationInMonths })}
              subValue={diff <= 0 ? t('Expired') : t('Remaining: {{ months }} months', { months: diff || '-' })}
            />
          )
        case 'insurance':
          return <Cell value={item.liabilityInsuranceSince} />

        case 'financial':
          return (
            <Cell
              value={t('Operating costs: {{ cost }}', {
                cost: formatCurrency(item.operatingCostsPerMonth || 0, locale),
              })}
              subValue={t('Catalog value: {{ value }}', { value: formatCurrency(item.cataloguePrice || 0, locale) })}
            />
          )
        case 'cost':
          return <Cell value={t('Lease rate: {{ rate }}', { rate: formatCurrency(item.ratePerMonth || 0, locale) })} />
        case 'status':
          return <Cell value={getStatusTranslation(item.status, locale)} />

        default:
          return <Cell value={item[column]} />
      }
    })
  }

  if (!loading && error) return <MaintenanceNotice error={error} />
  const totalRecords = data?.lease?.contractsAggregate?.aggregate?.count || 0

  return (
    <LeasefleetTable
      loading={loading}
      cellGenerator={cellDataGenerator}
      tableConfig={tableConfig(t)}
      tableData={rowData}
      tableName="LeaseBicycleContracts"
      showCustomization
      perPage={perPage}
      totalResults={totalRecords}
      refetch={refetch}
      select={select}
    />
  )
}
