import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DateTimePicker } from '../../../../../../Components/FormComponents/DateTimePicker'
import { IFile } from '../../../../../../Components/FormComponents/FileUpload/interfaces'
import { Form } from '../../../../../../Components/FormComponents/Form'
import { Select } from '../../../../../../Components/FormComponents/Select'
import { ConfirmFooter, ContentSection, Footer } from '../../../../../../Components/Modal/Components'
import { FileUploadSection } from '../../../../../../Components/Modal/Components/FileUploadSection'
import { IProps } from '../interfaces'
import Styles from './styles.module.scss'

const contracts = [
  { label: 'Reisbalans', value: '1' },
  { label: 'Shuttel', value: '2' },
]

export const Services: React.FC<IProps> = ({ onClose }): JSX.Element => {
  const { t } = useTranslation()
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [file, setFile] = useState<IFile | null>(null)
  const [kind, setKind] = useState<(typeof contracts)[0]>()

  return (
    <React.Fragment>
      <ContentSection title="Over de voorwaarden">
        <Form width="320px">
          <div className={Styles.wrapper}>
            <Select id="kinds" onChange={setKind} value={kind} options={contracts} ariaLabel="" label={t('Kind')} />
          </div>

          <DateTimePicker
            id="start-date"
            label={t('Starting date')}
            value={startDate}
            onChange={setStartDate}
            width={{ datePicker: '191px' }}
          />
        </Form>
      </ContentSection>

      <FileUploadSection file={file} onChange={setFile} />

      <Footer>
        <ConfirmFooter
          cancelProps={{ onClick: onClose }}
          confirmProps={{ label: t('Save'), onClick: (): void => {} }}
        />
      </Footer>
    </React.Fragment>
  )
}
