import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../Components'
import { ActiveBadge } from '../../../Components/ActiveBadge'
import { LimitedListBadge } from '../../../Components/LimitedListBadge'
import { Modal } from '../../../Components/Modal'
import { ConfirmFooter } from '../../../Components/Modal/Components'
import { PageContainer } from '../../../Components/PageContainer'
import { RegularTable } from '../../../Components/RegularTable'
import { Edit } from '../Components/Edit'
import { publishedTo, tableConfig } from './helpers'

export const FeatureFlags: React.FC = (): JSX.Element => {
  const { t } = useTranslation()

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [featureId, setFeatureId] = useState<string | undefined>()

  const paginationChangeHandler = (page: number): void => {
    setCurrentPage(page)
  }

  const limit = 6
  const tableData = [
    {
      id: '1',
      feature: 'Beta user interface',
      active: <ActiveBadge active={false} />,
      organisations: <LimitedListBadge list={publishedTo} limit={limit} />,
      button: (
        <Button
          onClick={(): void => {
            setFeatureId('1')
          }}
          variant="secondaryBold"
          size="xxs"
          id="edit-button"
        >
          {t('Edit', { context: 'short' })}
        </Button>
      ),
    },
    {
      id: '2',
      feature:
        'Forceer het zetten van de juiste adres locatie en nog een hele lange andere zin om te zien wat er gebeurt met hele lange zinnen',
      active: <ActiveBadge active />,
      organisations: <LimitedListBadge list={publishedTo} limit={limit} />,
    },
    {
      id: '3',
      feature: 'Gesplitst in rekening brengen',
      active: <ActiveBadge active />,
      organisations: <LimitedListBadge list={['Reisbalans B.V.']} limit={5} />,
      button: (
        <Button
          onClick={(): void => {
            setFeatureId('3')
          }}
          variant="secondaryBold"
          size="small"
          id="edit-button"
        >
          {t('Edit', { context: 'short' })}
        </Button>
      ),
    },
  ]

  const renderFooter = (): JSX.Element => {
    return (
      <ConfirmFooter
        confirmProps={{ label: t('Save'), onClick: (): void => {} }}
        cancelProps={{ onClick: () => setFeatureId(undefined) }}
      />
    )
  }

  return (
    <PageContainer header={{ title: t('Feature flags') }}>
      <RegularTable
        tableProps={{ tableConfig, tableData }}
        paginationProps={{
          totalItemsPerPage: 10,
          totalItems: 13,
          selected: currentPage,
          onChange: paginationChangeHandler,
          navArrows: true,
          visiblePages: 10,
        }}
      />

      <Modal
        onClose={(): void => setFeatureId(undefined)}
        show={!!featureId}
        title={t('Edit feature flag')}
        subTitle={t(`Make changes for {{ entity }}`, { entity: tableData[0].feature })}
        footer={renderFooter()}
      >
        <Edit id={featureId} />
      </Modal>
    </PageContainer>
  )
}
