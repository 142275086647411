import React from 'react'

import { useDisableBodyScroll } from '../../Hooks/useDisableBodyScroll'
import { ModalContainer } from '../ModalContainer'
import { Typography } from '../Typography'
import { Footer } from './Components'
import { ErrorsSection } from './Components/ErrorsSection'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Modal: React.FC<IProps> = ({
  id,
  className,
  show,
  title,
  subTitle,
  children,
  footer,
  onClose,
  errors = [],
}): JSX.Element => {
  useDisableBodyScroll()

  return (
    <ModalContainer
      id={id}
      aria-label={title}
      title={title}
      className={[Styles.modal, ...(className ? [className] : [])].join(' ')}
      onClose={onClose}
      withPadding={false}
      show={show}
    >
      <div className={Styles.header}>
        <Typography variant="h1-large" element="h2" className={Styles.title}>
          {title}
        </Typography>

        {subTitle && (
          <Typography variant="body-2" element="div" className={Styles.subTitle}>
            {subTitle}
          </Typography>
        )}

        <ErrorsSection errors={errors} />
      </div>

      {children && <div className={Styles.content}>{children}</div>}
      {footer && <Footer>{footer}</Footer>}
    </ModalContainer>
  )
}
