import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../../Components'
import { LimitedListBadge } from '../../../Components/LimitedListBadge'
import { PageContainer } from '../../../Components/PageContainer'
import { ButtonFooter } from '../../../Components/PageContainer/Components/Footers/ButtonFooter'
import { RegularTable } from '../../../Components/RegularTable'
import { ROUTE_NAMES } from '../../../Layouts/Authorized/interfaces'
import { routes } from '../../../Layouts/Authorized/routes'
import { StatusColumnValue } from './Components/StatusColumnValue'
import { publishedTo, tableConfig } from './helpers'

export const Messages = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState<number>(1)

  const tableData = [
    {
      id: '1',
      title: 'Nieuwe voorwaarden',
      publishedTo: <LimitedListBadge list={publishedTo} limit={5} />,
      status: <StatusColumnValue status="CONCEPT" />,
      onClick: (): void => navigate('69'),
    },
    {
      id: '2',
      title: 'Dit is een hele lange titel die gaat over meer dan 2 regels ...',
      publishedTo: <LimitedListBadge list={publishedTo} limit={5} />,
      status: <StatusColumnValue status="GEPLAND" dateRange={{ startsAt: new Date('2022-05-11'), endsAt: null }} />,
      onClick: (): void => navigate('69'),
    },
    {
      id: '3',
      title: 'Let op! ',
      publishedTo: <LimitedListBadge list={['Test']} limit={5} />,
      status: (
        <StatusColumnValue
          status="GEPUBLICEERD"
          dateRange={{ startsAt: new Date('2022-05-11'), endsAt: new Date('2023-05-11') }}
        />
      ),
      onClick: (): void => navigate('69'),
    },
  ]

  const paginationChangeHandler = (page: number): void => {
    setCurrentPage(page)
  }

  const PageFooter = (
    <ButtonFooter>
      <Button id="add-oauth" icon="add" size="big" onClick={(): void => navigate(routes[ROUTE_NAMES.MESSAGES_NEW])}>
        {t('Add message')}
      </Button>
    </ButtonFooter>
  )

  return (
    <PageContainer
      header={{
        title: t('Messages'),
        showLine: true,
      }}
      footerContent={PageFooter}
    >
      <RegularTable
        tableProps={{ tableConfig, tableData }}
        paginationProps={{
          totalItemsPerPage: 10,
          totalItems: 13,
          selected: currentPage,
          onChange: paginationChangeHandler,
          navArrows: true,
          visiblePages: 10,
        }}
      />
    </PageContainer>
  )
}
