import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Select } from '../../../../Components/FormComponents/Select'
import { Icon } from '../../../../Components/Icon'
import { Modal } from '../../../../Components/Modal'
import { Panel } from '../../../../Components/Panel'
import { ListItem } from '../../../../Components/Panel/Components/Content/List/Components/ListItem'
import { LayoutContext } from '../../../../Providers/LayoutProvider'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Widget: React.FC<IProps> = ({
  id,
  title,
  icon,
  select,
  goTo,
  children,
  draggable = false,
}): JSX.Element => {
  const navigate = useNavigate()
  const { integrated } = useContext(LayoutContext)
  const [showModal, setShowModal] = useState<boolean>(false)

  const onFooterButtonClick = (): void => {
    if (!goTo) return

    if (goTo.integratedUrl && integrated) window.parent.location.href = goTo.integratedUrl
    if (goTo.url) navigate(goTo.url)
    if (goTo.modalContent) setShowModal(true)
  }

  const footer = ((): JSX.Element | undefined => {
    if (!goTo) return undefined

    return (
      <ul className={Styles.list}>
        <ListItem onClick={onFooterButtonClick} count={goTo.count} disabled={goTo.disabled}>
          {goTo.label}
        </ListItem>
      </ul>
    )
  })()

  const toggleConfigModal = (): void => {
    setShowModal(!showModal)
  }

  const action = ((): JSX.Element | undefined => {
    if (draggable) return <Icon tabIndex={0} name="drag" />
    if (!select) return undefined

    const selectName = select.name.toLowerCase().replaceAll(' ', '-')

    return <Select {...select} name={selectName} ariaLabel={selectName} id={selectName} />
  })()

  return (
    <Panel
      {...(id && { id })}
      header={{ title, icon, actions: action, className: Styles.widgetHeader }}
      footer={footer}
    >
      {children}

      <Modal
        id={id ? `${id}Modal` : 'modal'}
        title={title}
        show={showModal}
        onClose={toggleConfigModal}
        className={Styles.modal}
      >
        {goTo?.modalContent}
      </Modal>
    </Panel>
  )
}
