import React from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '../../../../../Components/Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Value: React.FC<IProps> = ({ value }): JSX.Element => {
  const { t } = useTranslation()

  if (!value) return <span className={Styles.empty}>{t('Not filled in')}</span>
  return (
    <Typography className={Styles.value} variant="body-2">
      {value}
    </Typography>
  )
}
