import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { InlineFields } from '../../../../../../../Components/FormComponents/InlineFields'
import { Input } from '../../../../../../../Components/FormComponents/Input'
import Styles from './styles.module.scss'

export const AutoFillSection: React.FC = (): JSX.Element => {
  const { t } = useTranslation()

  const [brand, setBrand] = useState<string>('')
  const [model, setModel] = useState<string>('')
  const [co2, setCo2] = useState<string>('')
  const [energyLabel, setEnergyLabel] = useState<string>('')

  return (
    <div className={Styles.wrapper}>
      <InlineFields>
        <Input id="brand" label={t('Brand')} placeholder={t('Brand')} value={brand} onChange={setBrand} />
        <Input id="model" label={t('Model')} placeholder={t('Model')} value={model} onChange={setModel} />
      </InlineFields>

      <InlineFields className={Styles.innerWrapper}>
        <Input
          id="co2"
          label={t('Co2 per kilometer')}
          placeholder="0"
          value={co2}
          onChange={setCo2}
          unit="gram"
          width="180px"
          clearable={false}
        />
        <Input
          id="energy-label"
          label={t('Energy label')}
          placeholder="A"
          value={energyLabel}
          onChange={setEnergyLabel}
          width="58px"
          clearable={false}
        />
      </InlineFields>
    </div>
  )
}
