import i18n from 'i18next'
import React from 'react'

import { Typography } from '../../../Components/Typography'
import { formatCurrency } from '../../../Lib/Formatters/currencyFormatter'
import { Kind } from './Components/Kind'
import { Term } from './Components/Term'

const { t } = i18n

export const tableConfig = {
  kind: {
    name: t('Kind'),
    width: '256px',
  },
  costsPerMonth: {
    name: t('Costs per month'),
    width: '203px',
  },
  costsPerKm: {
    name: t('Costs per km'),
    width: '167px',
  },
  term: {
    name: t('Term'),
    width: '398px',
  },
}

export const tableData = [
  {
    id: '1',
    kind: <Kind kind="De rode auto, mercedes" licensePlate="123-XX-AB" />,
    costsPerMonth: <Typography variant="body-2">{formatCurrency(5, 'nl')}</Typography>,
    costsPerKm: <Typography variant="body-2">{formatCurrency(5, 'nl')}</Typography>,
    term: <Term id="1" startsAt={new Date('2022-07-04')} endsAt={new Date('2024-02-30')} />,
  },
  {
    id: '2',
    kind: <Kind kind="De rode auto, mercedes" licensePlate="123-XX-AB" />,
    costsPerMonth: <Typography variant="body-2">{formatCurrency(5, 'nl')}</Typography>,
    costsPerKm: <Typography variant="body-2">{formatCurrency(5, 'nl')}</Typography>,
    term: <Term id="2" startsAt={new Date('2022-07-04')} endsAt={new Date('2024-02-30')} />,
  },
]
