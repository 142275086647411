import React, { Fragment, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Select } from '../../../Components/FormComponents/Select'
import { Icon } from '../../../Components/Icon'
import { PageContainer } from '../../../Components/PageContainer'
import { Table } from '../../../Components/Table'
import { ITableConfig } from '../../../Components/Table/interfaces'
import { useGetBudgetGroupsQuery, useGetWrpmQuery } from '../../../Lib/graphql'
import { LayoutContext } from '../../../Providers/LayoutProvider'
import {
  defaultBudgetGroupOption,
  getBudgetGroupOptions,
  mapModalityEnumToIconName,
  transformWRPMData,
} from './helpers'
import { IBudgetGroupOption, ITransformedWRPMRow, IWRPMTableRow } from './interfaces'
import Styles from './styles.module.scss'

export const WRPMOverview: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const { organizationId } = useContext(LayoutContext) // FIXME: how to inform the user when there is no organization_id?

  const [selectedBudgetGroup, setSelectedBudgetGroup] = useState<IBudgetGroupOption>(defaultBudgetGroupOption)
  const [budgetGroupOptions, setBudgetGroupOptions] = useState<IBudgetGroupOption[]>([])
  const [tableData, setTableData] = useState<IWRPMTableRow[]>([])

  /* The polymorphicId is used in two different setups:
   1. when forBudgetGroup is false, the polymorphicId represents the id of an organization
   2. when forBudgetGroup is true, the polymorphicId represents the id of a budgetgroup
   */
  const [polymorphicId, setPolymorphicId] = useState<string>(`${organizationId}`)
  const [forBudgetGroup, setForBudgetGroup] = useState<boolean>(false)

  const getKindCellContent = (item: ITransformedWRPMRow): JSX.Element => {
    return (
      <div className={Styles.modalityKindWrapper}>
        {item.kind ? <Icon name={mapModalityEnumToIconName(item.kind)} /> : ''} {item.humanized}
      </div>
    )
  }

  const createTableData = (transformedWRPMData: ITransformedWRPMRow[]): IWRPMTableRow[] => {
    const tableRows: IWRPMTableRow[] = []

    transformedWRPMData.forEach((item) => {
      tableRows.push({
        id: item.id,
        kind: getKindCellContent(item),
        commute_kms: `${item.commute_kms} ${t('km')}`,
        commute_co2: `${item.commute_co2} ${t('gram')}`,
        business_kms: `${item.business_kms} ${t('km')}`,
        business_co2: `${item.business_co2} ${t('gram')}`,
      })
    })

    return tableRows
  }

  const { loading: loadingFirstQuery } = useGetBudgetGroupsQuery({
    variables: { organizationId: `${organizationId}` },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    onCompleted: (response) => {
      const { budgetGroups } = response?.reisbalansV2?.v2Organization || []

      if (budgetGroups && budgetGroups.length) setBudgetGroupOptions(getBudgetGroupOptions(budgetGroups))
    },
    skip: !organizationId,
  })

  const { loading: loadingSecondQuery } = useGetWrpmQuery({
    variables: { polymorphicId, forBudgetGroup },
    onCompleted: (response) => {
      const transformedWRPMData = transformWRPMData(response)
      const createdTableData = createTableData(transformedWRPMData)

      setTableData(createdTableData)
    },
    skip: !polymorphicId,
  })

  const handleBudgetGroupChange = (budgetGroup: IBudgetGroupOption): void => {
    const polyId = budgetGroup.value === null ? `${organizationId}` : budgetGroup.value

    setSelectedBudgetGroup(budgetGroup)
    setForBudgetGroup(budgetGroup.value !== null)
    setPolymorphicId(polyId)
  }

  const getTableConfig = (): ITableConfig => {
    return {
      kind: {
        name: t('Kind'),
        width: '300px',
      },
      commute_kms: {
        name: t('Commute'),
        width: '200px',
      },
      commute_co2: {
        name: t('Commute CO2'),
        width: '200px',
      },
      business_kms: {
        name: t('Business'),
        width: '200px',
      },
      business_co2: {
        name: t('Business CO2'),
        width: '200px',
      },
    }
  }

  return (
    <PageContainer
      header={{
        title: t('Passenger mobility legislation'),
        showLine: true,
      }}
    >
      <Fragment>
        {/* Added this div since the select component doesn't allow a decent override of the width, see RBW-1095 */}
        <div className={Styles.budgetGroupWrapper}>
          <Select
            id="budgetgroup"
            ariaLabel={t('Budgetgroup')}
            onChange={handleBudgetGroupChange}
            value={selectedBudgetGroup}
            options={budgetGroupOptions}
          />
        </div>

        <Table tableConfig={getTableConfig()} tableData={tableData} loading={loadingFirstQuery || loadingSecondQuery} />
      </Fragment>
    </PageContainer>
  )
}
