import { Body, Cell, Header, HeaderRow, Row, Table as ReactTable, TableNode } from '@table-library/react-table-library'
import { getTheme } from '@table-library/react-table-library/baseline'
import { useTheme } from '@table-library/react-table-library/theme'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '../Icon'
import { HeaderCellWrapper } from './Components/HeaderCellWrapper'
import { ESortDirections } from './Components/HeaderCellWrapper/interfaces'
import { LoadingSkeleton } from './Components/LoadingSkeleton'
import { ITableConfig, ITableProps } from './interfaces'
import Styles from './styles.module.scss'
import { getCustomTheme } from './theme'

export const Table: FC<ITableProps> = ({
  tableConfig,
  tableData,
  tableWidth = 'fit-content',
  columnWidth = 200,
  loading,
  onSortChange,
}): JSX.Element => {
  // tableConfig is the hardcoded config that contains the fields and default settings
  const { t } = useTranslation()

  const numberOfColumns = Object.keys(tableConfig).length

  const theme = useTheme([getTheme(), getCustomTheme(columnWidth, numberOfColumns)])

  const handleSortChange = (field: string, direction: ESortDirections): void => {
    if (!onSortChange) return
    onSortChange(field, direction)
  }

  const defaultCellDataGenerator = (config: ITableConfig, rowData: TableNode): JSX.Element[] => {
    return Object.keys(config).map((column) => (
      <div key={column} className={Styles.dataCell}>
        {rowData[column]}
      </div>
    ))
  }

  const generateHeaders = (): JSX.Element[] | null => {
    return Object.keys(tableConfig).map((column) => {
      return (
        <HeaderCellWrapper
          key={column}
          column={column}
          width={tableConfig[column].width || `${columnWidth}px`}
          name={tableConfig[column].name}
          activeSort={tableConfig[column].defaultSort}
          sortable={tableConfig[column].sortable}
          onChange={handleSortChange}
        />
      )
    })
  }

  const generateCells = (item: TableNode): JSX.Element[] => {
    const cellData = defaultCellDataGenerator(tableConfig, item)
    const columns = Object.keys(tableConfig)

    return cellData.map((content, index) => {
      const columnConfig = tableConfig[columns[index]]
      const modalButton = cellData.length - 1 === index && item.button
      const isLast = cellData.length - 1 === index && item.onClick

      return (
        <Cell
          key={columns[index]}
          style={{
            textAlign: columnConfig.align || 'left',
            width: tableConfig[columns[index]].width || `${columnWidth}px`,
          }}
        >
          {content}

          {(modalButton || isLast) && (
            <div className={Styles.buttonsWrapper}>
              {modalButton && item.button}
              {isLast && (
                <span className={Styles.chevron}>
                  <Icon name="chevron-right" />
                </span>
              )}
            </div>
          )}
        </Cell>
      )
    })
  }

  const getOnClickProps = (row: TableNode): { className: string; onClick: any } | object => {
    if (!row.onClick) return {}

    if (String(row.id).includes('fallback')) {
      return {
        className: `${Styles.onHover} ${Styles.fallback}`,
        onClick: row.onClick,
      }
    }

    return {
      className: Styles.onHover,
      onClick: row.onClick,
    }
  }

  if (loading) return <LoadingSkeleton />

  if (tableData.length === 0) return <div>{t('No data found...')}</div>

  return (
    <div className={Styles.container}>
      <div className={Styles.tableWrapper} style={{ width: `${tableWidth}` }}>
        <ReactTable data={{ nodes: tableData }} layout={{ custom: true }} theme={theme}>
          {(tableList: any): JSX.Element => (
            <React.Fragment>
              <Header>
                <HeaderRow>{generateHeaders()}</HeaderRow>
              </Header>
              <Body>
                {tableList.map((item: any) => (
                  <Row key={item.id || item.uuid} item={item} {...getOnClickProps(item)}>
                    {generateCells(item)}
                  </Row>
                ))}
              </Body>
            </React.Fragment>
          )}
        </ReactTable>
      </div>
    </div>
  )
}
