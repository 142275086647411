import React from 'react'

import { KeyValueListItem } from '../../../../../Components/Panel/Components/Content/List/Components/KeyValueListItem'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Item: React.FC<IProps> = ({ label, children }): JSX.Element => {
  return (
    <KeyValueListItem labelAlign="middle" label={label} labelWidth="268px">
      <div className={Styles.wrapper}>{children}</div>
    </KeyValueListItem>
  )
}
