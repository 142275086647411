import React from 'react'

import { LoadingSkeleton as ListItemSkeleton } from '../../../../../../Components/Panel/Components/Content/List/Components/ListItem/LoadingSkeleton'

export const LoadingSkeleton = (): JSX.Element => {
  return (
    <div>
      <ListItemSkeleton bottomBorder />
      <ListItemSkeleton />
    </div>
  )
}
