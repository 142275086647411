import React from 'react'

import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Status: React.FC<IProps> = ({ label, color, light, size = 'small' }): JSX.Element => {
  const getClassNames = (): string => {
    const classNames: Array<string> = [Styles.status]
    if (size !== 'small') classNames.push(Styles[size])
    if (color) classNames.push(Styles.withColor)
    if (light) classNames.push(Styles.light)

    return classNames.join(' ')
  }

  return (
    // @ts-ignore
    <div {...(color && { style: { '--color': color } })} className={getClassNames()}>
      {label}
    </div>
  )
}
