import React from 'react'

import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Form: React.FC<IProps> = ({ children, onSubmit, width = '100%' }): JSX.Element => {
  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault()

    if (onSubmit) onSubmit()
  }

  return (
    <form className={Styles.form} style={{ width }} onSubmit={handleSubmit}>
      {children}
    </form>
  )
}
