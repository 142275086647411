import React from 'react'

import { Typography } from '../../../../../../../Components/Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Statistic: React.FC<IProps> = ({ label, value }): JSX.Element => {
  const testId = label.replaceAll(' ', '-').toLowerCase()
  return (
    <div data-testid={testId} className={Styles.statistic}>
      <Typography className={Styles.value} variant="h1">
        {value}
      </Typography>
      <Typography className={Styles.label} variant="body-4">
        {label}
      </Typography>
    </div>
  )
}
