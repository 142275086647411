import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PageContainer } from '../../../Components/PageContainer'
import { ButtonFooter } from '../../../Components/PageContainer/Components/Footers/ButtonFooter'
import { RegularTable } from '../../../Components/RegularTable'
import { NewPoolCar } from './Components/NewPoolCar'
import { tableConfig, tableData } from './helpers'

const PoolCars = (): JSX.Element => {
  const { t } = useTranslation()
  const [currentPage, setCurrentPage] = useState<number>(1)

  const paginationChangeHandler = (page: number): void => {
    setCurrentPage(page)
  }

  const renderFooter = (): JSX.Element => {
    return (
      <ButtonFooter>
        <NewPoolCar />
      </ButtonFooter>
    )
  }

  return (
    <PageContainer header={{ title: t('Pool cars') }} footerContent={renderFooter()}>
      <RegularTable
        tableProps={{ tableConfig, tableData }}
        paginationProps={{
          totalItemsPerPage: 10,
          totalItems: 13,
          selected: currentPage,
          onChange: paginationChangeHandler,
          navArrows: true,
          visiblePages: 10,
        }}
      />
    </PageContainer>
  )
}

export default PoolCars
