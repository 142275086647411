import { useSortable } from '@dnd-kit/sortable'
import React from 'react'

import { useMountStatus } from '../../useMountStatus'
import { Item } from '../Item'
import { IProps } from './interfaces'

export const DraggableItem = ({ children, disabled, id, index }: IProps): JSX.Element => {
  const { setNodeRef, setActivatorNodeRef, listeners, isDragging, transform, transition } = useSortable({
    id,
  })
  const mounted = useMountStatus()
  const mountedWhileDragging = isDragging && !mounted

  return (
    <Item
      ref={disabled ? undefined : setNodeRef}
      dragging={isDragging}
      handleProps={{ ref: setActivatorNodeRef }}
      index={index}
      transition={transition}
      transform={transform}
      fadeIn={mountedWhileDragging}
      listeners={listeners}
      disabled={disabled}
    >
      {children}
    </Item>
  )
}
