import React, { ReactElement } from 'react'

import { IProps as IButton } from '../Button/interfaces'
import { Info } from '../Info'
import { Typography } from '../Typography'
import { TitleContainer } from './Components/TitleContainer'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const PageHeader: React.FC<IProps> = ({
  title,
  subTitle,
  info,
  headerCustomComponent,
  subTitleCustomComponent,
  buttons,
  showLine = true,
}) => {
  const containerClassNames = ((): string => {
    const classNames: Array<string> = [Styles.pageHeader]

    if (showLine) classNames.push(Styles.line)

    return classNames.join(' ')
  })()

  const getButtons = (): Array<ReactElement<IButton>> => {
    if (!buttons) return []
    if (Array.isArray(buttons)) return buttons

    return [buttons]
  }

  return (
    <div className={containerClassNames}>
      <TitleContainer title={title} isHeaderFixed={false} headerCustomComponent={headerCustomComponent} />

      {(subTitle || info || subTitleCustomComponent || buttons) && (
        <div className={Styles.subHeaderContainer}>
          {(subTitle || info || subTitleCustomComponent) && (
            <div>
              {(subTitle || subTitleCustomComponent) && (
                <div className={Styles.subTitleContainer}>
                  {subTitle && (
                    <Typography variant="h2" className={Styles.subTitle}>
                      {subTitle}
                    </Typography>
                  )}
                  {subTitleCustomComponent}
                </div>
              )}

              {info && <Info>{info}</Info>}
            </div>
          )}

          {buttons && (
            <div className={Styles.buttonsContainer}>
              {getButtons().map((button, index) => React.cloneElement(button, { key: index }))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
