import { TFunction } from 'i18next'

import { ITableConfig } from '../../../../Components/LeasefleetTable/interfaces'

export const tableConfig = (translate: TFunction): ITableConfig => ({
  contractNumber: {
    required: true,
    name: translate('Contract'),
    pinLeft: true,
    sortable: true,
  },
  driverName: {
    required: true,
    name: translate('Driver'),
    sortable: true,
  },
  kind: {
    name: translate('Kind'),
    sortable: true,
    orderByKey: 'vehicle.brand',
  },
  fuel: {
    name: translate('Fuel'),
    hide: true,
    sortable: true,
    orderByKey: 'vehicle.fuelType',
  },
  contractPeriod: {
    name: translate('Contract date'),
    hide: true,
    sortable: true,
    orderByKey: 'startDate',
  },
  contractDuration: {
    name: translate('Contract duration'),
    hide: true,
    sortable: true,
    orderByKey: 'durationInMonths',
  },
  budgetMileage: {
    name: translate('Budget mileage'),
    hide: true,
    align: 'right',
    sortable: true,
    orderByKey: 'yearlyContractMileage',
  },
  insurance: {
    name: translate('Insurance'),
    sortable: true,
    orderByKey: 'liabilityInsuranceSince',
  },
  financial: {
    name: translate('Fiscal worth'),
    align: 'left',
    sortable: true,
    orderByKey: 'operatingCostsPerMonth',
  },
  cost: {
    name: translate('Lease rate p/m'),
    align: 'left',
    sortable: true,
    orderByKey: 'ratePerMonth',
  },
  status: {
    name: translate('Status'),
    align: 'right',
    filter: {
      none: translate('none'),
      cancelled: translate('cancelled'),
      ordered: translate('ordered'),
      running: translate('running'),
      terminated: translate('terminated'),
    },
  },
  isShortLease: {
    name: translate('Short lease'),
    align: 'center',
    filter: {
      none: translate('none'),
      true: translate('Yes').toLowerCase(),
      false: translate('No').toLowerCase(),
    },
  },
  environment: {
    name: translate('Environment'),
    hide: true,
    sortable: true,
    orderByKey: 'vehicle.energyClassification',
  },
  costCenter: {
    name: translate('Cost center'),
    hide: true,
    sortable: true,
  },
})
