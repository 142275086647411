import React from 'react'

import { SelectButton } from './Components/SelectButton'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const SelectButtons = <T,>({ value, data, onClick }: IProps<T>): JSX.Element => {
  const getClassName = (radioValue: T): string | undefined => {
    const isSelected = !!value && radioValue !== value
    if (isSelected) return Styles.selected
  }

  return (
    <div className={Styles.selectButtons}>
      {data.map((buttonProps, index) => {
        const buttonValue = buttonProps.value

        return (
          <div key={index} className={getClassName(buttonValue)}>
            <SelectButton<T> {...buttonProps} onClick={onClick} active={value === buttonValue} />
          </div>
        )
      })}
    </div>
  )
}
