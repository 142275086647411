import React from 'react'

import { MaybeRenderWithLabelHOC } from '../../../Lib/MaybeRenderWithLabelHOC'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const RadioComponent: React.FC<IProps> = ({ children, className, onChange, value }): JSX.Element | null => {
  const handleOptionChange = (selectedValue: string): void => {
    if (selectedValue && onChange) onChange(selectedValue)
  }

  if (!children) return null

  return (
    <ul className={`${Styles.radio} ${className}`} data-testid="radioButton">
      {children.map((option, index) => {
        return (
          <li key={index} data-testid={`radio-option-${index}`}>
            {React.cloneElement(option, {
              selected: value === option.props.value,
              onChange: handleOptionChange,
            })}
          </li>
        )
      })}
    </ul>
  )
}

export const Radio = MaybeRenderWithLabelHOC(RadioComponent)
