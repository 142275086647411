import { SideNav } from '../../Components/SideNav'
import { BreadcrumbsProvider } from '../../Providers/BreadcrumbsProvider'
import { layoutId } from '../helpers'
import Styles from './styles.module.scss'

const Content = ({ children }: { children: JSX.Element }): JSX.Element => {
  return (
    <div className={Styles.layout}>
      <SideNav />
      <div id={layoutId} className={Styles.content}>
        <div>{children}</div>
      </div>
    </div>
  )
}

export const DrivenLayout = ({ children }: { children: JSX.Element }): JSX.Element => {
  return (
    <BreadcrumbsProvider>
      <Content>{children}</Content>
    </BreadcrumbsProvider>
  )
}
