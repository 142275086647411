import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useMatch } from 'react-router-dom'

import { Icon } from '../../../Icon'
import { Link } from '../../../Link'
import { INavigationItemProps } from './interfaces'
import Styles from './styles.module.scss'

export const NavigationItem: FC<INavigationItemProps> = ({
  iconName,
  label,
  location,
  isActiveOnChildRoutes,
  onClick,
}) => {
  const { t } = useTranslation()

  const isActive = Boolean(useMatch({ path: location, end: !isActiveOnChildRoutes }))
  const dataTestId = label.split(' ').join('-').toLocaleLowerCase()

  return (
    <div
      data-testid={dataTestId}
      className={`${Styles.navigationItem} ${isActive && Styles.active}`}
      data-active={isActive}
      aria-label={`${label} link`}
      role="link"
      tabIndex={0}
      {...(onClick && { onClick })}
    >
      <Link className={Styles.link} href={location} withUnderline={false} ariaLabel={t(`${label} navigation item.`)}>
        <div className={Styles.iconContainer}>
          <Icon name={iconName} />
        </div>
        <span>{label}</span>
      </Link>
    </div>
  )
}
