import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { Panel } from '../../../Panel'
import Styles from './Styles.module.scss'

const Section = (): JSX.Element => {
  return (
    <Panel>
      <Skeleton className={Styles.title} height={20} width={100} />
      <Skeleton height={15} width={220} />
    </Panel>
  )
}

export const LoadingSkeleton = (): JSX.Element => {
  return (
    <div className={Styles.container}>
      <Section />
    </div>
  )
}
