import { TAvailableAuthorizationRoles } from '../apolloClientInterfaces'
import { TLocale } from '../sharedInterfaces'

const contentType = 'application/json'

export const generateHeaders = (): Record<string, string> => {
  const conditionalHeaders: Record<string, string> = {
    Accept: contentType,
    'Content-Type': contentType,
  }
  if (import.meta.env.MODE !== 'production') conditionalHeaders['X-Requested-With'] = 'XMLHttpRequest'
  return conditionalHeaders
}

export const generateApolloClientHeaders = ({
  jwtToken,
  withAuthorization,
  role,
  locale,
}: {
  jwtToken?: string | null
  withAuthorization: boolean
  role?: TAvailableAuthorizationRoles
  locale: TLocale
}): Record<string, string> => {
  const sharedHeaders = {
    ...generateHeaders(),
    Accept: contentType,
    'Accept-Language': locale,
    'User-Selected-Language': locale,
  }

  if (!withAuthorization || !jwtToken) return sharedHeaders

  return {
    ...sharedHeaders,
    ...(jwtToken && { authorization: `Bearer ${jwtToken}` }),
    ...(role && { 'X-Hasura-Role': role }),
  }
}
