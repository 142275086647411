/* eslint-disable */
import { format, sub } from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ISelect } from '../../../../../Components/LeasefleetTable/interfaces'
import { HorizontalBarGraph } from '../../../../../Components/Stats/HorizontalBarGraph'
// @ts-ignore
// import { useGetDamagesByLiabilityQuery } from '../../../../../Lib/graphql'
import { DamagesTable } from '../../../../DrivenLeaseContracts/Tabs/DamagesTable'
import { Widget } from '../../Widget'
import { TOption } from '../../Widget/interfaces'
import { IBaseWidgetProps } from '../interfaces'
import { LoadingSkeleton } from './LoadingSkeleton'
import Styles from './styles.module.scss'

export const DamageWidget: React.FC<IBaseWidgetProps> = ({ draggable }): JSX.Element => {
  const { t } = useTranslation()

  const selectMonthOptions = (): { label: string; value: number }[] => {
    const months = [6, 12]
    const monthRecords: { label: string; value: number }[] = []

    monthRecords.push({ label: t('All'), value: 0 })

    months.forEach((month) => {
      monthRecords.push({ label: t('Previous {{ count }} months', { count: month }), value: month })
    })

    return monthRecords
  }

  const [stats, setStats] = useState<{ liable: number; nonLiable: number; glass: number }>({
    liable: 0,
    nonLiable: 0,
    glass: 0,
  })

  const [selectedOption, setSelectedOption] = useState<TOption>(selectMonthOptions()[0])

  const getVariables = useMemo((): Record<string, unknown> => {
    if (selectedOption.value === 0) return {}
    return { from: format(sub(new Date(), { months: Number(selectedOption.value) }), 'yyyy-MM-dd') }
  }, [selectedOption.value])

  // @ts-ignore
  const { data, loading } = useGetDamagesByLiabilityQuery({
    variables: { ...getVariables },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (!data) return
    const newStats = {
      liable: data?.lease?.liableOther.aggregate?.count || 0,
      nonLiable: data?.lease?.nonLiableOther.aggregate?.count || 0,
      glass: data?.lease?.glass.aggregate?.count || 0,
    }
    setStats(newStats)
  }, [data])

  const handleOnChange = (selected: TOption): void => {
    setSelectedOption(selected)
  }

  const selectOptions: ISelect<TOption> = {
    options: selectMonthOptions(),
    name: 'damageWidget',
    value: selectedOption,
    onChange: handleOnChange,
    small: true,
    menuAlignment: 'right',
  }

  return (
    <Widget
      id="DamageWidget"
      title={t('Total number of damage cases')}
      icon="calculator"
      draggable={draggable}
      select={selectOptions}
      goTo={{
        label: t('View all damage cases'),
        disabled: draggable,
        // modalContent: <DamagesTable contractType="car-lease" selectOptions={selectOptions} />,
      }}
    >
      {((): JSX.Element => {
        if (loading) return <LoadingSkeleton />

        return (
          <div className={Styles.wrapper}>
            <HorizontalBarGraph
              data={{
                [t('Recoverable')]: stats.liable,
                [t('Non-recoverable')]: stats.nonLiable,
                [t('Window damage')]: stats.glass,
              }}
            />
          </div>
        )
      })()}
    </Widget>
  )
}

DamageWidget.displayName = 'DamageWidget'
