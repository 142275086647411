import React, { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useOnClickOutside } from '../../../../Hooks/useClickedOutside/useOnClickOutside'
import { Icon } from '../../../Icon'
import { IFilterProps } from './interfaces'
import Styles from './styles.module.scss'

export const Filter: FC<IFilterProps> = ({ options, name, onFilterChange }): JSX.Element | null => {
  const { t } = useTranslation()
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const [selected, setSelected] = useState<string>()
  const ref = useRef<HTMLDivElement>(null)

  useOnClickOutside({ handler: (): void => setShowMenu(false), ref })

  const handleChange = (option: string): void => {
    setSelected(option)
    onFilterChange(name, option)
    setShowMenu(false)
  }

  if (!options) return null

  return (
    <div data-testid="filter-icon" ref={ref} className={Styles.filter}>
      <Icon name="filter" className={Styles.icon} onClick={(): void => setShowMenu(true)} />

      {showMenu && (
        <div className={Styles.menu}>
          {Object.keys(options).map((option) => {
            const isSelected = selected === option
            return (
              <span
                key={option}
                data-testid={`option-${option}`}
                onClick={(): void => handleChange(option)}
                className={`${Styles.option} ${isSelected ? Styles.selected : ''}`}
              >
                {t(option)}
              </span>
            )
          })}
        </div>
      )}
    </div>
  )
}
