import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const LazyImage = ({ width, height, src, alt, containerClassName, ...others }: IProps): JSX.Element => {
  const { t } = useTranslation()

  const [loadingDone, setLoadingDone] = useState<boolean>(false)
  const containerClasses: string[] = [Styles.imageContainer]
  if (containerClassName) containerClasses.push(containerClassName)
  const loadingTitle = t('Loading the image') || ''

  return (
    <div className={containerClasses.join(' ')} style={{ width: width || '100%', height: height || '100%' }}>
      <img
        {...others}
        loading="lazy"
        src={src}
        alt={alt}
        width={width || '100%'}
        height={height || '100%'}
        onLoad={(): void => setLoadingDone(true)}
        className={loadingDone ? Styles.imageLoaded : Styles.imageLoading}
        data-image-state={loadingDone ? 'loaded' : 'loading'}
        aria-label={loadingDone ? alt : loadingTitle}
      />
    </div>
  )
}
