import React, { createContext, useCallback, useMemo, useState } from 'react'

import { TPage } from '../../Components/Breadcrumbs/interfaces'
import { IContextProps, IProviderProps } from './interfaces'

export const BreadcrumbsContext = createContext<IContextProps>({
  path: [],
  setLabelOfCrumbAtIndex: (): void => {},
  setSubLabelOfCrumbAtIndex: (): void => {},
  setPath: (): void => {},
})

export const BreadcrumbsProvider: React.FC<IProviderProps> = ({ children, initPath }): JSX.Element => {
  const [currentPath, setCurrentPath] = useState<Array<TPage>>(initPath || [])

  const setPath = useCallback((path: TPage[]): void => {
    setCurrentPath(path)
  }, [])

  const setLabelOfCrumbAtIndex = useCallback(
    (index: number, label: string): void => {
      if (currentPath.length - 1 < index || currentPath[index].title === label) return

      setCurrentPath((old) => {
        const pages = [...old]

        pages[index] = {
          ...pages[index],
          title: label,
        }

        return pages
      })
    },
    [currentPath]
  )

  const setSubLabelOfCrumbAtIndex = useCallback(
    (index: number): void => {
      if (currentPath.length - 1 < index) return

      setCurrentPath((old) => {
        const pages = [...old]

        pages[index] = {
          ...pages[index],
        }

        return pages
      })
    },
    [currentPath]
  )

  const options = useMemo(
    () => ({
      path: currentPath,
      setPath,
      setLabelOfCrumbAtIndex,
      setSubLabelOfCrumbAtIndex,
    }),
    [currentPath, setLabelOfCrumbAtIndex, setSubLabelOfCrumbAtIndex, setPath]
  )

  return <BreadcrumbsContext.Provider value={options}>{children}</BreadcrumbsContext.Provider>
}
