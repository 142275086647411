export const rgbToHex = (rgbColor: string): string => {
  const values = rgbColor
    .substring(rgbColor.indexOf('(') + 1, rgbColor.lastIndexOf(')'))
    .split(',')
    .map((value) => parseInt(value, 10)) // Specify radix parameter as 10

  const hexValues = values.map((value) => {
    const hex = value.toString(16)
    return hex.length === 1 ? `0${hex}` : hex
  })

  return `#${hexValues.join('')}`
}
