import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { DateTimePicker } from '../../../Components/FormComponents/DateTimePicker'
import { Form } from '../../../Components/FormComponents/Form'
import { Input } from '../../../Components/FormComponents/Input'
import { Radio } from '../../../Components/FormComponents/Radio'
import { Option } from '../../../Components/FormComponents/Radio/Components/Option'
import { TextArea } from '../../../Components/FormComponents/TextArea'
import { MultiSelect } from '../../../Components/MultiSelect'
import { IOption } from '../../../Components/MultiSelect/interfaces'
import { PageContainer } from '../../../Components/PageContainer'
import { ConfirmFooter } from '../../../Components/PageContainer/Components/Footers/ConfirmFooter'
import { Panel } from '../../../Components/Panel'
import { Section } from '../../../Components/Panel/Components/Content/Section'
import { ROUTE_NAMES } from '../../../Layouts/Authorized/interfaces'
import { routes } from '../../../Layouts/Authorized/routes'
import { OptionEnum, PublishOnEnum, TEndDateOption, TPublishOnOption } from './interfaces'
import Styles from './styles.module.scss'

const NewMessage = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [title, setTitle] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [publicationDate, setPublicationDate] = useState<Date>(new Date())
  const [endDateOption, setEndDateOption] = useState<TEndDateOption>({
    value: OptionEnum.DATE,
    date: new Date(),
  })

  const [publishOnOption, setPublishOnOption] = useState<TPublishOnOption>({
    value: PublishOnEnum.ORGANISATION,
    organisations: [],
  })

  const organisations: IOption[] = [
    { value: '1', label: 'Reisbalans' },
    { value: '2', label: 'Shuttel' },
  ]

  const handleEndDateOptionChange = (value: OptionEnum, date?: Date): void => {
    setEndDateOption((prev) => ({ value, date: date || prev.date }))
  }

  const handlePublishOnChange = (value: PublishOnEnum, list?: IOption[]): void => {
    setPublishOnOption((prev) => ({ value, organisations: list || prev.organisations }))
  }

  const PageFooter = (
    <ConfirmFooter
      onConfirm={(): void => {}}
      onCancel={(): void => navigate(routes[ROUTE_NAMES.MESSAGES])}
      confirmButtonLabel={t('Save')}
    />
  )

  return (
    <PageContainer header={{ title: t('Add message') }} footerContent={PageFooter}>
      <Panel>
        <Section title={t('Message')}>
          <Form width="622px">
            <Input id="title" label={t('Title')} value={title} onChange={setTitle} />

            <TextArea
              id="message"
              placeholder={t('Type your message')}
              label={t('Message')}
              value={message}
              onChange={setMessage}
            />
          </Form>
        </Section>
      </Panel>

      <Panel>
        <Section title={t('Visibility')}>
          <Form width="476px">
            <DateTimePicker
              id="publicationDate"
              label={t('Publication date')}
              value={publicationDate}
              onChange={setPublicationDate}
              width={{
                datePicker: '307px',
                timePicker: '153px',
              }}
            />

            <Radio
              id="endDate"
              label={t('End date')}
              value={endDateOption.value}
              onChange={(value): void => handleEndDateOptionChange(value as OptionEnum)}
            >
              <Option value={OptionEnum.DATE} inline>
                <DateTimePicker
                  disabled={endDateOption.value !== OptionEnum.DATE}
                  id="publicationDate"
                  value={endDateOption.date}
                  onChange={(value): void => handleEndDateOptionChange(OptionEnum.DATE, value)}
                  width={{
                    datePicker: '277px',
                    timePicker: '153px',
                  }}
                />
              </Option>

              <Option value={OptionEnum.NO_DATE} label={t('No end date, to be determined')} />
            </Radio>

            <Radio
              id="publishOn"
              label={t('Publish on')}
              value={publishOnOption.value}
              onChange={(value): void => handlePublishOnChange(value as PublishOnEnum)}
            >
              <Option value={PublishOnEnum.CONTRACT} label={t('Contract level')} />
              <Option value={PublishOnEnum.ORGANISATION} label={t('Organisation level')}>
                <div className={Styles.multiSelectWrapper}>
                  <MultiSelect
                    id="organisations"
                    options={organisations}
                    placeholder={t('Search by organization name')}
                    selectedOptions={publishOnOption.organisations}
                    disabled={publishOnOption.value !== PublishOnEnum.ORGANISATION}
                    onChange={(list): void => handlePublishOnChange(PublishOnEnum.ORGANISATION, list)}
                  />
                </div>
              </Option>
            </Radio>
          </Form>
        </Section>
      </Panel>
    </PageContainer>
  )
}

export default NewMessage
