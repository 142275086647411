import React from 'react'

import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ButtonFooter: React.FC<IProps> = ({ children, justify = 'end' }): JSX.Element => {
  return (
    <div className={Styles.buttonFooter} style={{ justifyContent: justify }}>
      {children}
    </div>
  )
}
