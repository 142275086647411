import React from 'react'
import { useTranslation } from 'react-i18next'

import { Panel } from '../../../../../Components/Panel'
import { List } from '../../../../../Components/Panel/Components/Content/List'
import { KeyValueListItem } from '../../../../../Components/Panel/Components/Content/List/Components/KeyValueListItem'
import { Edit } from './Components/Edit'

export const General = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Panel
      header={{
        title: t('General'),
        actions: <Edit />,
      }}
    >
      <List>
        <KeyValueListItem key={1} label={t('Name label')} labelWidth="202px">
          Louwman
        </KeyValueListItem>
        <KeyValueListItem key={2} label={t('Domain')} labelWidth="202px">
          Louwman
        </KeyValueListItem>
      </List>
    </Panel>
  )
}
