import { IOption } from '../../../Components/MultiSelect/interfaces'

export enum OptionEnum {
  DATE = 'DATE',
  NO_DATE = 'NO_DATE',
}

export type TEndDateOption = { value: OptionEnum; date: Date }

export enum PublishOnEnum {
  CONTRACT = 'CONTRACT',
  ORGANISATION = 'ORGANISATION',
}

export type TPublishOnOption = { value: PublishOnEnum; organisations: IOption[] }
