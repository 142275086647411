import React from 'react'

import Colors from '../../Theme/colors.module.scss'
import { Icon } from '../Icon'
import { IProps as IconProps } from '../Icon/interface'
import { IProps, TReturnType } from './interfaces'
import Styles from './styles.module.scss'

export const Button: React.FC<IProps> = ({
  'aria-label': ariaLabel,
  children,
  id,
  disabled,
  fullWidth = false,
  loading,
  className,
  width,
  icon,
  iconWidth,
  iconOnly,
  alignLeft = false,
  variant = 'primary',
  size = 'medium',
  textOnly = false,
  selected = false,
  iconWithBackground = false,
  uppercase = false,
  ...otherProps
}): JSX.Element => {
  const renderWithoutIcon = (): TReturnType => children

  const renderChildren = (): JSX.Element => {
    if (!icon) return renderWithoutIcon() as JSX.Element

    const props: Partial<IconProps> = {}

    if (iconWithBackground) {
      props.className = `${Styles.iconWithBackground} ${selected ? Styles.selected : ''}`
      props.color = selected ? Colors.white : 'var(--primary-color)'
    }

    if (iconWidth !== undefined) props.width = iconWidth

    return (
      <React.Fragment>
        <Icon data-testid={`${icon}-icon`} name={icon} {...props} />
        {!iconOnly && children}
      </React.Fragment>
    )
  }

  // TODO: Remove old style after redesigning finished
  // ts-ignore temporary needed, will be removed when we undo old classNames
  const classNames = ((): string => {
    const classes: string[] = [Styles.button, Styles[size]]
    if (className) classes.push(className)
    if (variant === 'primary') classes.push(Styles.primary)
    if (variant === 'secondary') classes.push(Styles.secondary)
    if (variant === 'secondaryBold') classes.push(Styles.secondaryBold)
    if (variant === 'noBorder') classes.push(Styles.noBorder)
    if (loading) classes.push(Styles.loading)
    if (fullWidth) classes.push(Styles.fullWidth)
    if (iconOnly) classes.push(Styles.iconOnly)
    if (alignLeft) classes.push(Styles.alignLeft)
    if (selected) classes.push(Styles.selected)
    if (uppercase) classes.push(Styles.uppercase)
    return classes.join(' ')
  })()

  if (textOnly) {
    const classes: string[] = [Styles.textOnly]
    if (loading) classes.push(Styles.loading)
    if (variant === 'secondary') classes.push(Styles.secondary)
    if (alignLeft) classes.push(Styles.alignLeft)

    return (
      <span id={id} className={classes.join(' ')} tabIndex={0} role="button" {...otherProps}>
        {children}
      </span>
    )
  }

  const isDisabled = Boolean(disabled || loading)

  return (
    <button
      {...otherProps}
      id={id}
      className={classNames}
      disabled={isDisabled}
      type="submit"
      style={{ width }}
      tabIndex={0}
      aria-label={ariaLabel}
    >
      {renderChildren()}
    </button>
  )
}
