import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DateTimePicker } from '../../../../../../Components/FormComponents/DateTimePicker'
import { IFile } from '../../../../../../Components/FormComponents/FileUpload/interfaces'
import { Form } from '../../../../../../Components/FormComponents/Form'
import { Radio } from '../../../../../../Components/FormComponents/Radio'
import { Option } from '../../../../../../Components/FormComponents/Radio/Components/Option'
import { Select } from '../../../../../../Components/FormComponents/Select'
import { ConfirmFooter, ContentSection, Footer } from '../../../../../../Components/Modal/Components'
import { FileUploadSection } from '../../../../../../Components/Modal/Components/FileUploadSection'
import { IProps } from '../interfaces'

const contracts = [
  { label: 'Reisbalans', value: '1' },
  { label: 'Shuttel', value: '2' },
]

export const Contract: React.FC<IProps> = ({ onClose }): JSX.Element => {
  const { t } = useTranslation()
  const [kind, setKind] = useState<string>()
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [file, setFile] = useState<IFile | null>(null)
  const [contract, setContract] = useState<(typeof contracts)[0]>()

  return (
    <React.Fragment>
      <ContentSection title="Over de voorwaarden">
        <Form width="320px">
          <Select
            id="contracts"
            onChange={setContract}
            value={contract}
            options={contracts}
            ariaLabel=""
            label={t('Contract')}
          />

          <Radio id="1" label={t('Kind')} value={kind} onChange={setKind}>
            <Option label={t('Terms of use')} value="1" />
            <Option label={t('Terms of use for the employer')} value="2" />
          </Radio>

          <DateTimePicker
            id="start-date"
            label={t('Starting date')}
            value={startDate}
            onChange={setStartDate}
            width={{ datePicker: '196px' }}
          />
        </Form>
      </ContentSection>

      <FileUploadSection file={file} onChange={setFile} />

      <Footer>
        <ConfirmFooter
          cancelProps={{ onClick: onClose }}
          confirmProps={{ label: t('Save'), onClick: (): void => {} }}
        />
      </Footer>
    </React.Fragment>
  )
}
