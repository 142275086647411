import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DatePicker } from '../../../../../Components/FormComponents/DatePicker'
import { InlineFields } from '../../../../../Components/FormComponents/InlineFields'
import { Input } from '../../../../../Components/FormComponents/Input'
import { ConfirmFooter, ContentSection, Footer } from '../../../../../Components/Modal/Components'
import { AutoFillSection } from './Components/AutofillSection'
import { IProps } from './interfaces'

export const PoolCarForm: React.FC<IProps> = ({ onClose }): JSX.Element => {
  const { t } = useTranslation()

  const [licensePlate, setLicensePlate] = useState<string>('')
  const [characteristic, setCharacteristic] = useState<string>('')
  const [costPK, setCostPK] = useState<string>('')
  const [startsOn, setStartsOn] = useState<Date>()
  const [endsOn, setEndsOn] = useState<Date>()

  const hint = t(
    'Only the reference is mandatory to register a new vehicle. License plate, model and / or brand is NOT required.'
  )

  return (
    <React.Fragment>
      <ContentSection title={t('About the car')}>
        <InlineFields>
          <Input
            id="licenseplate"
            label={t('License plate')}
            placeholder={t('License plate')}
            onChange={setLicensePlate}
            value={licensePlate}
          />
          <Input
            id="characteristic"
            label={t('Characteristic')}
            placeholder={t('Characteristic')}
            hint={hint}
            required
            onChange={setCharacteristic}
            value={characteristic}
          />
          <Input
            id="cost-pk"
            label={t('Cost per kilometer')}
            placeholder="00,00"
            hint={t('Cost per kilometer')}
            onChange={setCostPK}
            value={costPK}
            type="number"
            iconName="euro"
          />
        </InlineFields>

        {licensePlate && <AutoFillSection />}
      </ContentSection>

      <ContentSection title={t('Duration')}>
        <InlineFields>
          <DatePicker
            id="start-date"
            label={t('Start date')}
            width="196px"
            onChange={setStartsOn}
            value={startsOn}
            hint={hint}
          />
          <DatePicker
            id="expiration-date"
            label={t('Expiration date')}
            hint="Placeholder"
            width="196px"
            onChange={setEndsOn}
            value={endsOn}
            isOptional
          />
        </InlineFields>
      </ContentSection>

      <Footer>
        <ConfirmFooter
          cancelProps={{ onClick: onClose }}
          confirmProps={{ label: t('Save'), onClick: (): void => {} }}
        />
      </Footer>
    </React.Fragment>
  )
}
