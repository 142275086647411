import { useEffect } from 'react'

import { IUseClickedOutsideHook } from './interfaces'

export const useOnClickOutside = ({ handler, ref, elementId }: IUseClickedOutsideHook): void => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent): void => {
      if (elementId) {
        const element = document.getElementById(elementId)

        if (element && !element.contains(event.target as Node)) {
          handler(event)
          return
        }
      }

      if (!ref?.current || ref.current.contains(event.target as Node)) return

      handler(event)
    }

    document.addEventListener('touchstart', listener)
    document.addEventListener('mousedown', listener)

    return () => {
      document.removeEventListener('touchstart', listener)
      document.removeEventListener('mousedown', listener)
    }
  }, [ref, handler, elementId])
}
