import React from 'react'
import { useTranslation } from 'react-i18next'

import { Widget } from '../../Widget'
import { IBaseWidgetProps } from '../interfaces'
import { Statistics } from './Components/Statistics'
import { Total } from './Components/Total'
import Styles from './styles.module.scss'

export const RidesWidget: React.FC<IBaseWidgetProps> = ({ draggable }): JSX.Element => {
  const { t } = useTranslation()

  const data = [
    { label: t('Car'), total: 80 },
    { label: t('OV'), total: 80 },
    { label: t('Bicycle'), total: 20 },
    { label: t('Walk'), total: 20 },
  ]

  return (
    <Widget id="RidesWidget" title={t('Journeys')} icon="shared-transport" draggable={draggable}>
      <div className={Styles.wrapper}>
        <Statistics header={t('Business')} data={data} />
        <Statistics header={t('Commute')} data={data} />
        <Statistics header={t('Private')} data={data} />
      </div>

      <div className={`${Styles.totals} ${draggable ? Styles.disabled : ''}`}>
        <Total label={t('Total km')} total="23.8471 km" />
        <Total label={t('Total costs')} total="€6.820" />
        <Total label={t('Total emissions')} total="19.238 CO₂ " />
      </div>

      {/* Enable if url is available */}
      {/* <ListItem name={t('View all journeys')} disabled={disabled} link="#" /> */}
    </Widget>
  )
}

RidesWidget.displayName = 'RidesWidget'
