import * as React from 'react'

import { Hint } from '../../Components/Hint'
import { IProps } from './interfaces'

export const MaybeRenderWithHintHOC =
  <receivedProps extends Record<string, any>>(Component: React.FC<receivedProps>): React.FC<receivedProps & IProps> =>
  (props: receivedProps & IProps): JSX.Element => {
    const { hint, ...remainingProps } = props

    if (!hint) return <Component {...(remainingProps as receivedProps)} />

    return (
      <Hint hint={hint}>
        <Component {...(remainingProps as receivedProps)} />
      </Hint>
    )
  }
