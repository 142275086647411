import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../../Button'
import { getImageSize } from '../../helpers'
import Styles from '../../styles.module.scss'
import { UploadInput } from '../UploadInput'
import { ImageCropperModal } from './Components/ImageCropperModal'
import { IProps } from './interfaces'

export const ImageContent: React.FC<IProps> = ({
  accept = 'image/png, image/jpeg, image/jpg, image/gif',
  innerRef,
  value,
  size,
  onChange,
  setErrors,
  hasError,
}): JSX.Element => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [originalSource, setOriginalSource] = useState<typeof value>(value)

  const file = innerRef.current?.files?.item(0)
  const fileSource = file ? URL.createObjectURL(file) : null
  const croppedSource = value?.data || null
  const source = originalSource?.data || fileSource || croppedSource

  const openModal = (): void => setShowModal(true)

  const saveImage = (base64: string): void => {
    const type = file?.type || value?.contentType
    const name = file?.name || value?.filename

    if (!type || !name) return

    const input = {
      data: base64,
      contentType: type,
      filename: name,
    }

    onChange(input)
  }

  const hasValidDimensions = useCallback(
    (width: number, height: number): boolean => {
      return width >= size.width && height >= size.height
    },
    [size.height, size.width]
  )

  const clearImage = (): void => {
    onChange(null)
    if (innerRef.current) innerRef.current.value = ''
    setOriginalSource(null)
  }

  const setError = useCallback((): void => {
    setErrors([
      t(
        'The selected image does not meet the required dimensions. The minimum required dimensions are {{ width }}px width and {{ height }}px height. Please select another image.',
        { width: size?.width, height: size?.height }
      ),
    ])
  }, [setErrors, size?.height, size?.width, t])

  const handleFileChange = async (incomingFile: File): Promise<void> => {
    setErrors([])

    const url = URL.createObjectURL(incomingFile)
    const result = await getImageSize(url)

    if (hasValidDimensions(result.width, result.height)) openModal()
    else {
      setError()
      clearImage()
    }
  }

  const validateImage = useCallback(async () => {
    if (!value) {
      setErrors([])
      return
    }
    const sizes = await getImageSize(value.data)
    if (!hasValidDimensions(sizes.width, sizes.height)) setError()
  }, [hasValidDimensions, setError, setErrors, value])

  useEffect(() => {
    validateImage()
  }, [validateImage])

  const openImagePicker = (): void => {
    clearImage()
    innerRef.current?.click()
  }

  const showImageCropperModal = showModal && !!source

  return (
    <div data-testid="buttons" className={`${Styles.buttons} ${hasError ? Styles.buttonError : ''}`}>
      <UploadInput accept={accept} innerRef={innerRef} onChange={handleFileChange} />

      <Button
        size="small"
        className={Styles.button}
        icon="upload"
        variant="secondary"
        onClick={openImagePicker}
        fullWidth
      >
        {value ? t('Again') : t('Upload')}
      </Button>

      {value && (
        <React.Fragment>
          <Button
            icon="pencil"
            size="small"
            className={Styles.button}
            variant="secondary"
            onClick={openModal}
            fullWidth
          >
            {t('Crop')}
          </Button>

          <Button
            icon="delete"
            size="small"
            className={Styles.button}
            variant="secondary"
            onClick={clearImage}
            fullWidth
          >
            {t('Remove')}
          </Button>
        </React.Fragment>
      )}

      {showImageCropperModal ? (
        <ImageCropperModal
          size={size}
          source={source}
          onReupload={openImagePicker}
          onClose={(): void => setShowModal(false)}
          onApply={saveImage}
        />
      ) : null}
    </div>
  )
}
