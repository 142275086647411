import {
  Body,
  Cell,
  Header,
  HeaderCell,
  HeaderRow,
  Row,
  Table as ReactTable,
  TableNode,
} from '@table-library/react-table-library'
import { getTheme } from '@table-library/react-table-library/baseline'
import { useTheme } from '@table-library/react-table-library/theme'
import React, { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'

import { UserPreferencesContext } from '../../Providers/UserPreferencesProvider'
import { IUserPreferences } from '../../Providers/UserPreferencesProvider/interfaces'
import { Button } from '../Button'
import { Select } from '../FormComponents/Select'
import { Icon } from '../Icon'
import { Modal } from '../Modal'
import { Pagination } from '../Pagination'
import { Typography } from '../Typography'
import { ConfigModal } from './Components/ConfigModal'
import { Filter } from './Components/Filter'
import { SortIndicator } from './Components/SortIndicator'
import {
  convertArrayToObject,
  generateDefaultTableDisplaySettings,
  mergeDeep,
  removeKeysWithNoneValues,
} from './helpers'
import { ITableConfig, ITableProps, TTableDisplaySettings } from './interfaces'
import Styles from './styles.module.scss'
import { getCustomTheme } from './theme'

export const LeasefleetTable: FC<ITableProps> = ({
  cellGenerator,
  tableConfig,
  tableData,
  tableWidth = 'fit-content',
  columnWidth = 200,
  tableName,
  showCustomization,
  loading,
  perPage = 10,
  totalResults = 0,
  refetch,
  defaultOrder = {},
  select,
}): JSX.Element => {
  // tableConfig is the hardcoded config that contains the fields and default settings
  // tableDisplayConfig is a user preference should only determine if a field is shown or not (required is always show)

  const { t } = useTranslation()
  const [hasOverflowRight, setHasOverflowRight] = useState<boolean>(false)
  const [hasOverflowLeft, setHasOverflowLeft] = useState<boolean>(false)

  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false)
  const tableRef = React.useRef<HTMLDivElement>(null)
  const [currentSortOptions, setCurrentSortOptions] = useState<{ [key: string]: string }>(defaultOrder)
  const [currentFilterOptions, setCurrentFilterOptions] = useState<{ [key: string]: string }>({})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalItems, setTotalItems] = useState<number>(totalResults)
  const { saveTableDisplaySettings, loadTableDisplaySettings } = useContext<IUserPreferences>(UserPreferencesContext)

  const initialTableDisplaySettings: TTableDisplaySettings = {
    Default: generateDefaultTableDisplaySettings(tableConfig),
    ...loadTableDisplaySettings(tableName),
  }

  let initialSelectedTableDisplaySetting = 'Default'
  Object.keys(initialTableDisplaySettings).forEach((key) => {
    if (initialTableDisplaySettings[key].__default) initialSelectedTableDisplaySetting = key
  })

  const [tableDisplaySettings, setTableDisplaySettings] = useState<TTableDisplaySettings>(initialTableDisplaySettings)

  const [selectedTableDisplaySetting, setSelectedTableDisplaySetting] = useState<string>(
    initialSelectedTableDisplaySetting
  )

  const scrollStep = 200

  const numberOfVisibleColumns = tableDisplaySettings[selectedTableDisplaySetting].showFields.length - 1

  const theme = useTheme([getTheme(), getCustomTheme(columnWidth || 200, numberOfVisibleColumns)])

  const defaultCellDataGenerator = (config: ITableConfig, item: TableNode): JSX.Element[] => {
    return Object.keys(config).map((column) => <div key={column}>{item[column]}</div>)
  }

  const determineOverflow = useDebouncedCallback((element: Element) => {
    if (!tableRef.current) return
    setHasOverflowRight(element.scrollLeft + element.clientWidth < element.scrollWidth)
    setHasOverflowLeft(element.scrollLeft > 0)
  }, 100)

  useEffect(() => {
    setTimeout(() => {
      if (!tableRef.current) return
      setHasOverflowRight(tableRef.current.children[1].scrollWidth > tableRef.current.clientWidth)
    }, 100)
  }, [tableRef, selectedTableDisplaySetting, tableData])

  const handleScrollLeft = (_event: React.UIEvent<HTMLDivElement, UIEvent>): void => {
    if (!tableRef.current) return

    const element = tableRef.current.children[1]
    const stopAt = element.scrollLeft - scrollStep

    const interval = setInterval(() => {
      if (!tableRef.current) return
      element.scrollLeft -= 10
      if (element.scrollLeft <= stopAt || element.scrollLeft <= 0) clearInterval(interval)
    }, 20)
  }

  const handleScrollRight = (_event: React.UIEvent<HTMLDivElement, UIEvent>): void => {
    if (!tableRef.current) return

    const element = tableRef.current.children[1]
    const stopAt = element.scrollLeft + scrollStep

    const interval = setInterval(() => {
      if (!tableRef.current) return

      element.scrollLeft += 10

      if (element.scrollLeft >= stopAt || element.scrollWidth - element.scrollLeft - tableRef.current.clientWidth <= 0)
        clearInterval(interval)
    }, 20)
  }

  const setDefaultConfig = (label: string | number): void => {
    const currentSettings = loadTableDisplaySettings(tableName) || initialTableDisplaySettings

    Object.keys(currentSettings).forEach((key) => {
      currentSettings[key].__default = key === label
    })

    setTableDisplaySettings(currentSettings)
    saveTableDisplaySettings(tableName, currentSettings)
  }

  const handleTableViewChange = (label: string): void => {
    if (!label) return

    setDefaultConfig(label)
    setSelectedTableDisplaySetting(label)
    setShowSettingsModal(false)
  }

  const toggleConfigModal = (): void => {
    setShowSettingsModal(!showSettingsModal)
  }

  const handleNewConfig = (showFields: string[], labelName?: string): void => {
    const label = labelName || 'custom'
    const newSettings = { ...tableDisplaySettings }

    Object.keys(newSettings).forEach((setting) => {
      newSettings[setting].__default = false
    })

    newSettings[label] = { showFields, __default: true }
    saveTableDisplaySettings(tableName, newSettings)

    setTableDisplaySettings(newSettings)
    setTimeout(() => setSelectedTableDisplaySetting(label), 100)

    setShowSettingsModal(false)
  }

  const handleDelete = (label: string): void => {
    if (tableDisplaySettings[label] === undefined) return

    const newSettings = { ...tableDisplaySettings }
    delete newSettings[label]

    saveTableDisplaySettings(tableName, newSettings)
    setTableDisplaySettings(newSettings)

    handleTableViewChange('Default')
  }

  const handleSortChange = (field: string, directionIndex: number): void => {
    const segments = field.split('.')
    const convertedObject = convertArrayToObject(segments, directionIndex) as object

    const newSortOptions: Record<string, any> = { ...currentSortOptions }
    mergeDeep(newSortOptions, convertedObject)

    if (!refetch) return
    setCurrentSortOptions(newSortOptions)
    const options = removeKeysWithNoneValues(newSortOptions)
    const variables = Object.entries(options).length ? { orderBy: options } : { orderBy: {} }
    setCurrentPage(1)
    refetch({ offset: 0, ...variables })
  }

  const handleFilterChange = (filterType: string, filterValue: string): void => {
    if (!refetch) return

    const newFilterOptions = { ...currentFilterOptions }
    newFilterOptions[filterType] = filterValue

    setCurrentFilterOptions(newFilterOptions)

    const options = Object.fromEntries(Object.entries(newFilterOptions).filter(([_key, value]) => value !== 'none'))
    const where: { [key: string]: { [key: string]: string | boolean } } = {}
    where.whereStatus = options.status ? { eq: options.status } : {}
    where.whereShortLease = options.isShortLease ? { eq: options.isShortLease === 'true' } : {}
    where.whereAmountType = options.amountType ? { eq: options.amountType } : {}

    const refetchVariables = { backward: null, forward: { after: null, first: perPage }, ...where }

    setCurrentPage(0)

    refetch({ offset: 0, ...refetchVariables })
  }

  /*
      When switching to another page the query resets the totalResults to 0
      The initial totalResult should be leading and 0 shouldn't be set to the state
     */
  useEffect(() => {
    if (totalResults !== 0) setTotalItems(totalResults)
  }, [totalResults])

  const generateHeaders = (): JSX.Element[] | null => {
    if (!tableDisplaySettings[selectedTableDisplaySetting]) return null

    return Object.keys(tableConfig).map((column) => {
      const visibleColumns = tableDisplaySettings[selectedTableDisplaySetting].showFields
      const hide = visibleColumns.indexOf(column) === -1
      const lastColumn = visibleColumns.at(-1)

      return (
        <HeaderCell
          className={Styles.headerCell}
          resize={lastColumn !== column}
          hide={hide}
          key={column}
          pinLeft={tableConfig[column].pinLeft}
        >
          <span className={Styles.columnName}>{tableConfig[column].name}</span>
          {tableConfig[column].sortable && (
            <SortIndicator
              name={tableConfig[column].orderByKey || column}
              direction={tableConfig[column].defaultSort}
              onChange={handleSortChange}
            />
          )}
          {tableConfig[column].filter && (
            <Filter name={column} options={tableConfig[column].filter} onFilterChange={handleFilterChange} />
          )}
        </HeaderCell>
      )
    })
  }

  const generateCells = (item: TableNode): JSX.Element[] => {
    if (!tableDisplaySettings[selectedTableDisplaySetting]) return []
    const cellData = cellGenerator ? cellGenerator(tableConfig, item) : defaultCellDataGenerator(tableConfig, item)
    const columns = Object.keys(tableConfig)

    return cellData.map((content, index) => {
      const columnConfig = tableConfig[columns[index]]
      const hide = tableDisplaySettings[selectedTableDisplaySetting].showFields.indexOf(columns[index]) === -1
      return (
        <Cell
          hide={hide}
          pinLeft={!!columnConfig?.pinLeft}
          key={columns[index]}
          style={{ textAlign: columnConfig.align || 'left' }}
        >
          {content}
        </Cell>
      )
    })
  }

  const loadingStyles: string[] = [Styles.loading]
  if (loading) loadingStyles.push(Styles.isLoading)

  const defaultTranslated = t('Default')

  const buttonName = ((): string => {
    if (selectedTableDisplaySetting === 'custom') return t('Manual view')
    return t((initialSelectedTableDisplaySetting || defaultTranslated).toString())
  })()

  const paginationChangeHandler = (page: number): void => {
    if (!refetch) return
    refetch({ offset: (page - 1) * perPage })
    setCurrentPage(page)
  }

  const selectName = select?.name.toLowerCase().replaceAll(' ', '-') || ''

  return (
    <div className={Styles.container}>
      <div className={Styles.selectDisplayWrapper}>
        {showCustomization && (
          <div className={Styles.displayWrapper}>
            <Typography variant="body-3" className={Styles.label}>
              {t('Display')}:
            </Typography>
            <Button variant="secondary" onClick={toggleConfigModal} icon="list" size="big">
              {buttonName}
            </Button>
          </div>
        )}

        {select && <Select {...select} name={selectName} ariaLabel={selectName} id={selectName} />}
      </div>

      <div className={Styles.wrapper}>
        <div className={Styles.tableWrapper} ref={tableRef} style={{ width: `${tableWidth}` }}>
          <div className={loadingStyles.join(' ')}>
            <div>{t('Loading...')}</div>
          </div>
          <div className={Styles.innerWrapper}>
            <ReactTable
              data={{ nodes: tableData }}
              layout={{ custom: true, horizontalScroll: true }}
              theme={theme}
              onScroll={(event: React.UIEvent): void => determineOverflow(event.currentTarget)}
            >
              {(tableList: any): JSX.Element => (
                <React.Fragment>
                  <Header>
                    <HeaderRow>{generateHeaders()}</HeaderRow>
                  </Header>
                  <Body>
                    {!loading && tableList.length === 0 && (
                      <tr className={Styles.empty}>
                        <td>{t('No data found...')}</td>
                      </tr>
                    )}

                    {tableList.map((item: any, index: number) => (
                      <Row key={`${item.id}-${index}` || `${item.uuid}-${index}`} item={item}>
                        {generateCells(item)}
                      </Row>
                    ))}
                  </Body>
                </React.Fragment>
              )}
            </ReactTable>
            <div
              className={[Styles.overflow, hasOverflowLeft ? Styles.show : Styles.hide].join(' ')}
              style={{ left: `${columnWidth}px` }}
              onClick={handleScrollLeft}
            >
              <div>
                <Icon width={16} height={16} name="chevron-left" />
              </div>
            </div>
            <div
              className={[Styles.overflow, Styles.right, hasOverflowRight ? Styles.show : Styles.hide].join(' ')}
              onClick={handleScrollRight}
            >
              <div>
                <Icon width={16} height={16} name="chevron-right" />
              </div>
            </div>
          </div>

          <div className={Styles.pagination}>
            <Pagination
              totalItemsPerPage={perPage}
              totalItems={totalItems}
              selected={currentPage}
              onChange={paginationChangeHandler}
              navArrows
              visiblePages={10}
            />
          </div>
        </div>
      </div>

      <Modal
        id="configurationModal"
        title={t('Display')}
        subTitle={t('Quick access to fields that are important to you.')}
        show={showSettingsModal}
        onClose={toggleConfigModal}
      >
        <ConfigModal
          tableConfig={tableConfig}
          tableDisplaySettings={tableDisplaySettings}
          selectedTableDisplaySetting={selectedTableDisplaySetting}
          onChangeCallback={handleNewConfig}
          onUseCallback={handleTableViewChange}
          onDeleteCallback={handleDelete}
        />
      </Modal>
    </div>
  )
}
