import React from 'react'

import { Typography } from '../../../../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Section: React.FC<IProps> = ({ title, children, size = 'medium' }): JSX.Element => {
  return (
    <div className={[Styles.section, Styles[size]].join(' ')}>
      {title && (
        <Typography variant="h1" element="h2" className={Styles.header}>
          {title}
        </Typography>
      )}
      {children}
    </div>
  )
}
