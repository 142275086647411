import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../../Components'
import { Form } from '../../../Components/FormComponents/Form'
import { Input } from '../../../Components/FormComponents/Input'
import { Select } from '../../../Components/FormComponents/Select'
import { MultiSelect } from '../../../Components/MultiSelect'
import { IOption } from '../../../Components/MultiSelect/interfaces'
import { options } from '../../../Components/MultiSelect/mock'
import { PageContainer } from '../../../Components/PageContainer'
import { ButtonFooter } from '../../../Components/PageContainer/Components/Footers/ButtonFooter'
import { Panel } from '../../../Components/Panel'
import { Section } from '../../../Components/Panel/Components/Content/Section'

const OAuthNew = (): JSX.Element => {
  const contractOptions = [
    { label: 'Reisbalans', value: '1' },
    { label: 'Shuttel', value: '2' },
    { label: 'Driven', value: '3' },
  ]

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [name, setName] = useState<string>('')
  const [contract, setContract] = useState<IOption>()
  const [selectedScopes, setSelectedScopes] = useState<IOption[]>([])
  const [redirectUri, setRedirectUri] = useState<string>('')

  const renderFooter = (): JSX.Element => {
    return (
      <ButtonFooter justify="space-between">
        <Button id="cancel-button" variant="noBorder" onClick={(): void => navigate(-1)} textOnly>
          {t('Cancel')}
        </Button>
        <Button id="create-button" size="big" onClick={(): void => {}}>
          {t('Add')}
        </Button>
      </ButtonFooter>
    )
  }

  return (
    <PageContainer header={{ title: t('Add OAuth ') }} footerContent={renderFooter()}>
      <Form>
        <Panel>
          <Section title={t('General')}>
            <Form width="304px">
              <Input id="name" label={t('Name')} value={name} onChange={setName} />

              <Select
                id="contract"
                label={t('Contract')}
                ariaLabel="Contract"
                onChange={setContract}
                value={contract}
                options={contractOptions}
              />
            </Form>
          </Section>
        </Panel>

        <Panel>
          <Section title={t('About the application')}>
            <Form width="304px">
              <MultiSelect
                id="scopes"
                label={t('Scopes')}
                placeholder={t('Select scopes')}
                options={options}
                selectedOptions={selectedScopes}
                onChange={setSelectedScopes}
              />

              <Input id="redirect-uri" label={t('Redirect URI')} value={redirectUri} onChange={setRedirectUri} />
            </Form>
          </Section>
        </Panel>
      </Form>
    </PageContainer>
  )
}

export default OAuthNew
