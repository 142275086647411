import React from 'react'
import Skeleton from 'react-loading-skeleton'

import Styles from './styles.module.scss'

export const LoadingSkeleton = (): JSX.Element => {
  return (
    <div className={Styles.progressBar}>
      <div className={Styles.overlay}>
        <Skeleton width={110} />
        <Skeleton width={20} />
      </div>
    </div>
  )
}
