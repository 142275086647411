import { FC } from 'react'

import { ProgressBar } from '../../ProgressBar'
import { IHorizontalBarGraphProps } from './interfaces'
import Styles from './styles.module.scss'

export const HorizontalBarGraph: FC<IHorizontalBarGraphProps> = ({ data }): JSX.Element => {
  const total = Object.keys(data)
    .map((key) => data[key])
    .reduce((left, right) => left + right, 0)

  const generateBar = (label: string, value: number): JSX.Element => {
    const percentage = value === 0 ? 0 : (value / total) * 100
    return <ProgressBar key={label} label={label} value={value} percentage={percentage} />
  }

  const generateBars = (): JSX.Element[] => {
    return Object.keys(data).map((key) => {
      return generateBar(key, data[key])
    })
  }

  return (
    <div className={Styles.horizontalBarGraph}>
      {data && generateBars()}
      {!data && <span>No data provided...</span>}
    </div>
  )
}
