import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { getPagesBasedOnPath } from '../../../../Layouts/Driven/helpers'
import { layoutId } from '../../../../Layouts/helpers'
import { BreadcrumbsContext } from '../../../../Providers/BreadcrumbsProvider'
import { Breadcrumbs } from '../../../Breadcrumbs'
import { PageHeader } from '../../../PageHeader'
import { QuickNav } from '../../../QuickNav'
import { SmartPageHeader } from '../../../SmartPageHeader'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Header: React.FC<IProps> = ({ header }): JSX.Element => {
  const headerId = 'header'
  const location = useLocation()
  const { setPath, path: breadcrumbs } = useContext(BreadcrumbsContext)

  const [showCrumbs, setShowCrumb] = useState<boolean>(false)

  useEffect((): void => {
    if (location.pathname !== '/') {
      setPath(getPagesBasedOnPath(location.pathname))
      setShowCrumb(true)
    } else setShowCrumb(false)
  }, [location, setPath])

  const ref = useRef<HTMLDivElement>(null)
  const [isHeaderFixed, setIsHeaderFixed] = useState<boolean>(false)

  const listenScrollEvent = useCallback((element: HTMLElement): void => {
    const pageHeaderHeight = ref.current?.offsetHeight || 60

    if (!element) return
    if (element.scrollTop < pageHeaderHeight) return setIsHeaderFixed(false)
    if (element.scrollTop > pageHeaderHeight) return setIsHeaderFixed(true)
  }, [])

  useEffect(() => {
    const element = document.getElementById(layoutId)
    if (!element) return

    element.addEventListener('scroll', () => listenScrollEvent(element))
    return () => element.removeEventListener('scroll', () => listenScrollEvent(element))
  }, [listenScrollEvent])

  const offset = ((): number => {
    const headerElement = document.getElementById(headerId)
    if (!headerElement) return 160
    return headerElement.offsetHeight - 20
  })()

  return (
    <React.Fragment>
      {isHeaderFixed && (
        <div id={headerId} className={Styles.smartPageHeader}>
          <SmartPageHeader
            title={header.title}
            headerCustomComponent={header.headerCustomComponent}
            buttons={header.buttons}
            quickNavProps={{ items: header.quickNavItems, offset, scrollContainerId: layoutId }}
          />
        </div>
      )}

      <div id={headerId} ref={ref} className={`${isHeaderFixed ? Styles.hidden : Styles.visible}`}>
        {showCrumbs && (
          <div className={Styles.breadcrumbsWrapper}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
        )}

        {!isHeaderFixed && <PageHeader {...header} />}
        {header.quickNavItems && <QuickNav items={header.quickNavItems} scrollContainerId={layoutId} offset={offset} />}
      </div>
    </React.Fragment>
  )
}
