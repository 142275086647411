import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Radio } from '../../../../Components/FormComponents/Radio'
import { Option } from '../../../../Components/FormComponents/Radio/Components/Option'
import { Label } from '../../../../Components/Label'
import { ContentSection } from '../../../../Components/Modal/Components'
import { MultiSelect } from '../../../../Components/MultiSelect'
import { IOption } from '../../../../Components/MultiSelect/interfaces'
import { IProps, TActiveRadioOption, TRadioOption } from './interface'
import Styles from './styles.module.scss'

export const Edit: React.FC<IProps> = ({ id }): JSX.Element | null => {
  const { t } = useTranslation()

  const options = [
    { label: 'APG', value: 'APG' },
    { label: 'Demo', value: 'Demo' },
    { label: 'Reisbalans', value: 'Reisbalans' },
  ]

  const [pickedOption, setPickedOption] = useState<TRadioOption>('all')
  const [pickedActiveOption, setPickedActiveOption] = useState<TActiveRadioOption>('active')
  const [validForSelectedOptions, setValidForSelectedOptions] = useState<IOption[]>([])
  const [organizationsSelectedOptions, setOrganizationsSelectedOptions] = useState<IOption[]>([])

  const handleRadioOptionChange = (value: TRadioOption): void => {
    setPickedOption(value)
  }

  const handleActiveRadioOptionChange = (value: TActiveRadioOption): void => {
    setPickedActiveOption(value)
  }

  if (!id) return null

  return (
    <React.Fragment>
      <ContentSection>
        <Label label={t('Valid for')} id="validFor-radioGroup" attribute="validFor">
          <Radio
            id="validFor-radio"
            value={pickedOption}
            onChange={(event): void => handleRadioOptionChange(event as TRadioOption)}
          >
            <Option label={t('All organizations')} value="all" />
            <Option label={t('All organizations except ...')} value="exceptions">
              {pickedOption === 'exceptions' && (
                <div className={Styles.multiWrapper}>
                  <MultiSelect
                    id="except-multiSelect"
                    onChange={setValidForSelectedOptions}
                    options={options}
                    selectedOptions={validForSelectedOptions}
                  />
                </div>
              )}
            </Option>
            <Option label={t('Selected organizations')} value="preferred">
              {pickedOption === 'preferred' && (
                <div className={Styles.multiWrapper}>
                  <MultiSelect
                    id="except-multiSelect"
                    onChange={(option): void => {
                      setOrganizationsSelectedOptions([...option])
                    }}
                    options={options}
                    selectedOptions={organizationsSelectedOptions}
                  />
                </div>
              )}
            </Option>
          </Radio>
        </Label>
      </ContentSection>

      <ContentSection>
        <Label label={t('Active')} id="active-radioGroup" attribute="active">
          <Radio
            id="active-radio"
            value={pickedActiveOption}
            onChange={(event): void => handleActiveRadioOptionChange(event as TActiveRadioOption)}
          >
            <Option label={t('Yes')} value="active" />
            <Option label={t('No')} value="not-active" />
          </Radio>
        </Label>
      </ContentSection>
    </React.Fragment>
  )
}
