import { HeaderCell } from '@table-library/react-table-library'
import React from 'react'

import { Icon } from '../../../Icon'
import { Typography } from '../../../Typography'
import Styles from '../../styles.module.scss'
import { ESortDirections, IProps } from './interfaces'

const iconForSortDirection = (direction: string): JSX.Element => {
  switch (direction) {
    case 'ASC':
      return <Icon className={Styles.flip} name="sort-down" />
    case 'DESC':
      return <Icon name="sort-down" />
    default:
      return <Icon name="sort-up-down">↕</Icon>
  }
}

export const HeaderCellWrapper: React.FC<IProps> = ({
  sortable = false,
  name,
  onChange,
  activeSort = ESortDirections.NONE,
  width,
  column,
}): JSX.Element => {
  const handleDirectionChange = (): void => {
    let newDirection = ESortDirections.NONE

    if (activeSort === ESortDirections.NONE) newDirection = ESortDirections.ASC
    if (activeSort === ESortDirections.ASC) newDirection = ESortDirections.DESC
    if (activeSort === ESortDirections.DESC) newDirection = ESortDirections.NONE

    onChange(name, newDirection)
  }

  return (
    <HeaderCell
      className={`${Styles.headerCell} ${sortable ? Styles.onClick : ''}`}
      key={column}
      style={{ '--custom-width': `${width}` }}
      {...(sortable && { onClick: handleDirectionChange })}
    >
      <Typography variant="admin-heading">{name}</Typography>
      {sortable && <span className={Styles.sortIconWrapper}>{iconForSortDirection(ESortDirections[activeSort])}</span>}
    </HeaderCell>
  )
}
