import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../Components'
import { ConfirmModal } from '../../../Components/ConfirmModal'
import { PageContainer } from '../../../Components/PageContainer'
import { Panel } from '../../../Components/Panel'
import { List } from '../../../Components/Panel/Components/Content/List'
import { KeyValueListItem } from '../../../Components/Panel/Components/Content/List/Components/KeyValueListItem'
import { Section } from '../../../Components/Panel/Components/Content/Section'
import { Status } from '../../../Components/Status'
import { Typography } from '../../../Components/Typography'
import { EditDetails } from '../components/EditDetails'
import { EditMessage } from '../components/EditMessage'
import { TModalType } from './interfaces'

export const MessagesDetails = (): JSX.Element => {
  const { t } = useTranslation()

  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [modalType, setModalType] = useState<TModalType>()

  const pageTitle = 'Gebruikersvoorwaarden aangepast per 01-03-2023'

  const handleClick = (type: TModalType): void => setModalType(type)

  const confirmHandler = (): void => {
    setShowConfirm(false)
    // ... other logic
  }

  const deleteHandler = (): void => setShowConfirm(true)

  const HeaderStatus = <Status label={t('Concept')} color="#fb3" size="large" light />

  const handleClose = (): void => setModalType(undefined)

  const HeaderButtons = (
    <Fragment>
      <Button size="big" variant="secondary" onClick={deleteHandler}>
        {t('Delete')}
      </Button>
      <Button size="big" icon="add" variant="primary" onClick={(): void => {}}>
        {t('Copy')}
      </Button>
    </Fragment>
  )

  return (
    <PageContainer
      header={{
        title: pageTitle,
        buttons: HeaderButtons,
        subTitleCustomComponent: HeaderStatus,
      }}
    >
      <Panel
        header={{
          title: t('Message'),
          actions: (
            <Button size="small" variant="secondaryBold" uppercase onClick={(): void => handleClick('message')}>
              {t('Edit', { context: 'short' })}
            </Button>
          ),
        }}
      >
        <Section size="small">
          <Typography element="p" variant="h2">
            Per 1 december 2022 gelden er nieuwe gebruiksvoorwaarden voor Reisbalans. Door het uitbreiden van ons aanbod
            hebben we onze gebruiksvoorwaarden op een paar punten moeten updaten. Wilt u zien welke onderdelen van de
            gebruiksvoorwaarden zijn aangepast? Dit kunt u lezen op onze voorwaardenpagina.
          </Typography>
        </Section>
      </Panel>

      <Panel
        header={{
          title: t('Details'),
          actions: (
            <Button size="small" variant="secondaryBold" uppercase onClick={(): void => handleClick('details')}>
              {t('Edit', { context: 'short' })}
            </Button>
          ),
        }}
      >
        <List rows>
          <KeyValueListItem variant="fat" label={t('Published on')}>
            9 maart 2020, 10:45
          </KeyValueListItem>
          <KeyValueListItem variant="fat" label={t('Ends on')}>
            26 mei 2023, 14:34
          </KeyValueListItem>
          <KeyValueListItem variant="fat" label={t('Published to')}>
            Appelo-3jan, Appelo-4, Appelo B.V., Appelo BV 1, asdfasdfasdfasdf, Appelo-3jan, Appelo-4, Appelo B.V.,
            Appelo BV 1, asdfasdfasdfasdf, Appelo-3jan, Kiwi, Appelo B.V., Appelo BV 1, asdfasdfasdfasdf, Banaan,
            Appelo-4, Appelo B.V., Appelo BV 1, asdfasdfasdfasdf, Appelo-3jan, Appelo-4, Appelo B.V., Sinaasappel,
            asdfasdfasdfasdf, Appelo-3jan, Appelo-4, Appelo B.V., Appelo BV 1, asdfasdfasdfasdf,
          </KeyValueListItem>
        </List>
      </Panel>

      {modalType === 'details' && <EditDetails onClose={handleClose} />}
      {modalType === 'message' && <EditMessage onClose={handleClose} />}

      {showConfirm && (
        <ConfirmModal
          content={t('Are you sure you want to remove the message {{ messageName }}?', { pageTitle })}
          description={t('This action is irreversible.')}
          confirmButtonProps={{ onClick: confirmHandler, content: t('Yes, delete') }}
          onCancel={(): void => setShowConfirm(false)}
        />
      )}
    </PageContainer>
  )
}
