import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ColorInput } from '../../../Components/FormComponents/ColorInput'
import { FileUpload } from '../../../Components/FormComponents/FileUpload'
import { Form } from '../../../Components/FormComponents/Form'
import { InlineFields } from '../../../Components/FormComponents/InlineFields'
import { Input } from '../../../Components/FormComponents/Input'
import { Radio } from '../../../Components/FormComponents/Radio'
import { Option } from '../../../Components/FormComponents/Radio/Components/Option'
import { TextArea } from '../../../Components/FormComponents/TextArea'
import { PageContainer } from '../../../Components/PageContainer'
import { ConfirmFooter } from '../../../Components/PageContainer/Components/Footers/ConfirmFooter'
import { Panel } from '../../../Components/Panel'
import { Section } from '../../../Components/Panel/Components/Content/Section'
import { ROUTE_NAMES } from '../../../Layouts/Authorized/interfaces'
import { routes } from '../../../Layouts/Authorized/routes'
import Styles from './styles.module.scss'

export const CreateCustomerLabel: React.FC = (): JSX.Element => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [name, setName] = useState<string>('')
  const [domain, setDomain] = useState<string>('')

  const [emailAddress, setEmailAddress] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')

  const [admin, setAdmin] = useState<boolean>(false)
  const [primaryColor, setPrimaryColor] = useState<string>('#45FDC7')

  const handleFormSubmit = (): void => {
    alert('CREATED')
  }

  const handleCancel = (): void => {
    navigate(routes[ROUTE_NAMES.CUSTOMER_LABELS])
  }

  const formWidth = '622px'

  return (
    <PageContainer
      header={{
        title: t('Add customer label'),
        showLine: true,
      }}
      footerContent={<ConfirmFooter onConfirm={handleFormSubmit} onCancel={handleCancel} />}
    >
      <Panel>
        <Section title={t('General')}>
          <InlineFields width={formWidth}>
            <Input id="client-label-name" label={t('Name client label')} value={name} onChange={setName} />
            <Input id="domain" label={t('Domain')} value={domain} onChange={setDomain} />
          </InlineFields>
        </Section>
      </Panel>

      <Panel>
        <Section title={`${t('Contact')} (${t('Optional').toLowerCase()})`}>
          <InlineFields width={formWidth}>
            <Input id="email" label={t('E-mail address')} value={emailAddress} onChange={setEmailAddress} />
            <Input id="phone" label={t('Phone number')} value={phoneNumber} onChange={setPhoneNumber} />
          </InlineFields>
        </Section>
      </Panel>

      <Panel>
        <Section title={`${t('Style')} (${t('Optional').toLowerCase()})`}>
          <Form width={formWidth}>
            <Radio
              id="isAdmin"
              label={t('Admin')}
              onChange={(value: string): void => {
                setAdmin(value === 'yes')
              }}
            >
              <Option label={t('Yes')} value="yes" selected={admin} />
              <Option label={t('No')} value="no" selected={!admin} />
            </Radio>

            <ColorInput
              id="dd"
              label={t('Primary color')}
              value={primaryColor}
              onChange={setPrimaryColor}
              width="303px"
            />

            <InlineFields>
              <FileUpload
                type="image"
                label={t('Icon black background')}
                value={null}
                onChange={(): void => {}}
                size={{ width: 100, height: 100 }}
                className={Styles.firstImageUploadField}
              />
              <FileUpload
                type="image"
                label={t('Icon white background')}
                value={null}
                onChange={(): void => {}}
                size={{ width: 100, height: 100 }}
              />
            </InlineFields>

            <InlineFields>
              <FileUpload
                type="image"
                label={t('Logo black background')}
                value={null}
                onChange={(): void => {}}
                size={{ width: 100, height: 100 }}
                className={Styles.firstImageUploadField}
              />
              <FileUpload
                type="image"
                label={t('Logo white background')}
                value={null}
                onChange={(): void => {}}
                size={{ width: 100, height: 100 }}
              />
            </InlineFields>

            <InlineFields>
              <FileUpload
                type="image"
                label={t('Favicon')}
                value={null}
                onChange={(): void => {}}
                size={{ width: 100, height: 100 }}
                className={Styles.firstImageUploadField}
              />
              <FileUpload
                type="image"
                label={t('Inlog cover')}
                value={null}
                onChange={(): void => {}}
                size={{ width: 100, height: 100 }}
              />
            </InlineFields>
          </Form>
        </Section>
      </Panel>

      <Panel>
        <Section title={`${t('E-mail')} (${t('Optional').toLowerCase()})`}>
          <Form width={formWidth}>
            <Radio id="useBrandTemplate" label={t('Use brand template')}>
              <Option label={t('Yes')} value="yes" />
              <Option label={t('No')} value="no" />
            </Radio>
            <Input id="template" label={t('Template')} />

            <InlineFields width="940px">
              <Input id="senderName" label={t('Sender name')} />
              <Input id="address" label={t('Address')} />
              <Input id="sentFrom" label={t('Sent from')} />
            </InlineFields>

            <TextArea id="salutation" label={t('Salutation')} />
            <TextArea id="closing" label={t('Closing')} />

            <InlineFields>
              <FileUpload
                type="image"
                label={t('Header image')}
                value={null}
                onChange={(): void => {}}
                size={{ width: 100, height: 100 }}
                className={Styles.firstImageUploadField}
              />
              <FileUpload
                type="image"
                label={t('Header image style')}
                value={null}
                onChange={(): void => {}}
                size={{ width: 100, height: 100 }}
              />
            </InlineFields>
          </Form>
        </Section>
      </Panel>
    </PageContainer>
  )
}
