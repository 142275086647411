import React from 'react'
import { useTranslation } from 'react-i18next'

// @ts-ignore
// import { useGetUserStatsQuery } from '../../../../../Lib/graphql'
import { Widget } from '../../Widget'
import { WidgetListItem } from '../../WidgetListItem'
import { IBaseWidgetProps } from '../interfaces'
import { TFilter } from './interfaces'

export const EmployeesWidget: React.FC<IBaseWidgetProps> = ({ draggable }): JSX.Element => {
  const { t } = useTranslation()
  // @ts-ignore
  // const { organizationId } = useContext(LayoutContext)
  // @ts-ignore
  // const { data, loading } = useGetUserStatsQuery({ variables: { organizationId } })

  const getIntegratedLink = (type: TFilter): string => {
    return `${document.referrer}admin/employees/search?search=&filter=${type}`
  }

  return (
    <Widget id="EmployeesWidget" title={t('Employees')} icon="user" draggable={draggable}>
      {((): JSX.Element => {
        // if (loading) return <LoadingSkeleton />

        return (
          <div>
            <WidgetListItem
              // count={data?.lease?.rbdb?.statsActiveEmployees[0].activeEmployees}
              integratedUrl={getIntegratedLink('confirmed')}
              disabled={draggable}
            >
              {t('Active')}
            </WidgetListItem>
            <WidgetListItem
              // count={data?.lease?.rbdb?.statsEmployeesPendingActivation[0].pendingActivation}
              integratedUrl={getIntegratedLink('invited')}
              disabled={draggable}
            >
              {t('Invited')}
            </WidgetListItem>
            <WidgetListItem
              // count={data?.lease?.rbdb?.statsPendingInvites[0].pendingInvites}
              integratedUrl={getIntegratedLink('uninvited')}
              disabled={draggable}
            >
              {t('Not invited yet')}
            </WidgetListItem>
            <WidgetListItem integratedUrl={getIntegratedLink('inactive')} disabled={draggable}>
              {t('Terminated')}
            </WidgetListItem>
          </div>
        )
      })()}
    </Widget>
  )
}

EmployeesWidget.displayName = 'EmployeesWidget'
