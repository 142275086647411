import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../../../Components'
import { Modal } from '../../../../../Components/Modal'
import { PoolCarForm } from '../PoolCarForm'
import { IProps } from './interfaces'

export const EditPoolCar: React.FC<IProps> = ({ id }): JSX.Element => {
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)

  const handleClose = (): void => setShow(false)

  return (
    <React.Fragment>
      <Button id={`edit-button-${id}`} size="xxs" variant="secondary" onClick={(): void => setShow(true)}>
        {t('Edit', { context: 'short' })}
      </Button>
      <Modal id={`edit-modal-${id}`} title={t('Edit pool car')} onClose={handleClose} show={show}>
        <PoolCarForm id={id} onClose={handleClose} />
      </Modal>
    </React.Fragment>
  )
}
