import { addMonths, endOfMonth, startOfMonth, subMonths, subYears } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { BarChart } from '../../../../../Components/BarChart'
import { ROUTE_NAMES } from '../../../../../Layouts/Authorized/interfaces'
import { routes } from '../../../../../Layouts/Authorized/routes'
import { formatCurrency } from '../../../../../Lib/Formatters/currencyFormatter'
import { formatDateString } from '../../../../../Lib/Formatters/DateFormatter'
// @ts-ignore
// import { useGetFinesWidgetDataQuery } from '../../../../../Lib/graphql'
import { TLocale } from '../../../../../Lib/sharedInterfaces'
import { Widget } from '../../Widget'
import { LoadingSkeleton } from '../FuelCostWidget/LoadingSkeleton'
import { IBaseWidgetProps } from '../interfaces'
import Styles from './styles.module.scss'

export const FinesWidget: React.FC<IBaseWidgetProps> = ({ draggable }): JSX.Element => {
  const { t, i18n } = useTranslation()
  const locale = i18n.language as TLocale

  const now = new Date()
  const dateFormat = 'yyyy-MM-dd'

  const oneYearAgo = subYears(startOfMonth(now), 1)
  const endOfLastMonth = endOfMonth(subMonths(now, 1))

  // @ts-ignore
  const { data, loading } = useGetFinesWidgetDataQuery({
    variables: {
      from: formatDateString(oneYearAgo, dateFormat, locale),
      to: formatDateString(endOfLastMonth, dateFormat, locale),
    },
  })

  const fines = data?.lease.fines || []

  const formatDate = (dateString: string): string => formatDateString(new Date(dateString), 'MMM', locale)

  const generateMonthlyEntries = (
    startDate: Date,
    endDate: Date
  ): {
    date: string
    barName: string
    label: string
    value: number
  }[] => {
    const entries = []
    let currentDate = startDate

    while (currentDate <= endDate) {
      entries.push({
        date: formatDate(currentDate.toDateString()),
        barName: formatDate(currentDate.toDateString()),
        value: 0,
        label: '€0,-',
      })

      currentDate = addMonths(currentDate, 1)
    }

    return entries
  }

  const allMonthlyEntries = generateMonthlyEntries(oneYearAgo, endOfLastMonth)
  // @ts-ignore
  fines.forEach((fine) => {
    const date = formatDate(fine.fineDate)

    const entry = allMonthlyEntries.find((ent) => ent.date === date)

    if (entry) {
      entry.value += fine.amount || 0
      entry.label = formatCurrency(entry.value, locale)
    }
  })

  const chartData = allMonthlyEntries

  return (
    <Widget
      title={t('Total fines & penalties')}
      icon="receipt"
      draggable={draggable}
      goTo={{
        label: t('View all fines and penalties'),
        disabled: draggable,
        url: `${routes[ROUTE_NAMES.DRIVEN_LEASE_CAR_FLEET]}#fines`,
      }}
    >
      {((): JSX.Element => {
        if (loading) return <LoadingSkeleton />

        return (
          <div className={Styles.barChartWrapper}>
            <BarChart data={chartData} />
          </div>
        )
      })()}
    </Widget>
  )
}

FinesWidget.displayName = 'FinesWidget'
