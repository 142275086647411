import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { ROUTE_NAMES } from '../../Layouts/Authorized/interfaces'
import { routes } from '../../Layouts/Authorized/routes'
import { ThemeContext } from '../../Providers/ThemeProvider'
import { Input } from '../FormComponents/Input'
import { LazyImage } from '../LazyImage'
import { ThemeSwitcher } from '../ThemeSwitcher'
import { NavigationItem } from './Components/NavigationItem'
import Styles from './styles.module.scss'

export const SideNav = (): JSX.Element => {
  const { t } = useTranslation()
  const currentTheme = useContext(ThemeContext)

  const themeLogo = currentTheme?.currentTheme.logo

  return (
    <nav id="nav-sidebar" className={Styles.navigation}>
      {themeLogo && (
        <div className={Styles.brandContainer}>
          <LazyImage
            src={themeLogo?.uri || 'Images/reisbalans.gif'}
            alt="reisbalans"
            height={themeLogo?.height || '40px'}
            width={themeLogo?.width || '200px'}
          />
        </div>
      )}

      <div className={Styles.content}>
        <div className={Styles.search}>
          <Input id="search" label="search" iconName="search" placeholder={t('Search...')} />
        </div>

        <ThemeSwitcher />

        <NavigationItem iconName="house" label={t('Home')} location="/" />

        <NavigationItem
          iconName="user"
          label={t('Customer labels')}
          location={routes[ROUTE_NAMES.CUSTOMER_LABELS]}
          isActiveOnChildRoutes
        />

        <NavigationItem
          iconName="receipt"
          label={t('Passenger mobility legislation')}
          location={routes[ROUTE_NAMES.WRPM_OVERVIEW]}
          isActiveOnChildRoutes
        />

        <NavigationItem
          iconName="user"
          label={t('OAuth Applications')}
          location={routes[ROUTE_NAMES.OAUTH_APPLICATIONS]}
          isActiveOnChildRoutes
        />

        <NavigationItem
          iconName="envelope"
          label={t('Messages')}
          location={routes[ROUTE_NAMES.MESSAGES]}
          isActiveOnChildRoutes
        />

        <NavigationItem
          iconName="user"
          label="Get connectors"
          location={routes[ROUTE_NAMES.GET_CONNECTORS]}
          isActiveOnChildRoutes
        />

        <NavigationItem
          iconName="user"
          label={t('Feature flags')}
          location={routes[ROUTE_NAMES.FEATURE_FLAGS]}
          isActiveOnChildRoutes
        />

        <NavigationItem
          iconName="shared-transport"
          label={t('Pool cars')}
          location={routes[ROUTE_NAMES.POOL_CARS]}
          isActiveOnChildRoutes
        />

        <NavigationItem
          iconName="user"
          label={t('Terms and Conditions')}
          location={routes[ROUTE_NAMES.TERMS_CONDITIONS]}
          isActiveOnChildRoutes
        />
      </div>
    </nav>
  )
}
