import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Form } from '../../../../../Components/FormComponents/Form'
import { Input } from '../../../../../Components/FormComponents/Input'
import { Select } from '../../../../../Components/FormComponents/Select'
import { Modal } from '../../../../../Components/Modal'
import { ConfirmFooter, ContentSection } from '../../../../../Components/Modal/Components'
import { MultiSelect } from '../../../../../Components/MultiSelect'
import { IOption } from '../../../../../Components/MultiSelect/interfaces'
import { options } from '../../../../../Components/MultiSelect/mock'
import { IProps } from './interfaces'

export const Edit: React.FC<IProps> = ({ onClose }): JSX.Element => {
  const { t } = useTranslation()
  const [name, setName] = useState<string>('')
  const [contract, setContract] = useState<IOption>()
  const [selectedScopes, setSelectedScopes] = useState<IOption[]>([])
  const [redirectUri, setRedirectUri] = useState<string>('')

  const contractOptions = [
    { label: 'Reisbalans', value: '1' },
    { label: 'Shuttel', value: '2' },
    { label: 'Driven', value: '3' },
  ]

  const ModalFooter = (
    <ConfirmFooter cancelProps={{ onClick: onClose }} confirmProps={{ onClick: (): void => {}, label: t('Save') }} />
  )

  return (
    <Modal title={t('Edit oAuth')} onClose={onClose} footer={ModalFooter} show>
      <ContentSection title={t('General')}>
        <Form width="304px">
          <Input id="name" label={t('Name')} value={name} onChange={setName} />

          <Select
            id="contract"
            label={t('Contract')}
            ariaLabel="Contract"
            onChange={setContract}
            value={contract}
            options={contractOptions}
          />
        </Form>
      </ContentSection>

      <ContentSection title={t('About the application')}>
        <Form width="304px">
          <MultiSelect
            id="scopes"
            label={t('Scopes')}
            placeholder={t('Select scopes')}
            options={options}
            selectedOptions={selectedScopes}
            onChange={setSelectedScopes}
          />

          <Input id="redirect-uri" label={t('Redirect URI')} value={redirectUri} onChange={setRedirectUri} />
        </Form>
      </ContentSection>
    </Modal>
  )
}
