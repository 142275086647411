import React from 'react'

import { Typography } from '../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ColorPreview: React.FC<IProps> = ({ color, label }): JSX.Element => {
  return (
    <div className={Styles.colorPreview}>
      <div style={{ backgroundColor: color }} className={Styles.color} />

      <Typography className={Styles.label} variant="h2" element="span">
        {label || color}
      </Typography>
    </div>
  )
}
