import React, { useCallback, useEffect, useState } from 'react'

import { MaybeRenderWithLabelHOC } from '../../../Lib/MaybeRenderWithLabelHOC'
import { DatePicker } from '../DatePicker'
import { TimePicker } from '../TimePicker'
import { maybeGetFallbackForDate } from '../TimePicker/helpers'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

const DateTimePickerComponent: React.FC<IProps> = ({
  id,
  name,
  onChange,
  value,
  className,
  required,
  disabled,
  inline,
  minDate,
  width,
}): JSX.Element => {
  const [selectedDate, setSelectedDate] = useState<Date>(value || new Date())

  const onDateChange = (input: Date): void => {
    const newDate = new Date(input.getTime())
    newDate.setHours(selectedDate.getHours(), selectedDate.getMinutes())

    const date = minDate ? maybeGetFallbackForDate(minDate, newDate) : newDate

    setSelectedDate(date)
    if (onChange) onChange(date)
  }

  const onTimeChange = useCallback(
    (date: Date): void => {
      if (onChange) onChange(date)
    },
    [onChange]
  )

  const getClassNames = (): string => {
    const classNames: Array<string> = [Styles.dateTimePicker]

    if (className) classNames.push(className)
    if (inline) classNames.push(Styles.inline)

    return classNames.join(' ')
  }

  useEffect(() => {
    if (value) {
      const newDate = new Date(value.getTime())

      newDate.setSeconds(0, 0)
      setSelectedDate(newDate)
    }
  }, [value])

  const datePickerWidth = width?.datePicker || '180px'

  return (
    <div className={getClassNames()}>
      <div style={{ width: datePickerWidth }} className={Styles.datePicker}>
        <DatePicker
          id={`${id}-date-picker`}
          required={required}
          disabled={disabled}
          name={`${name || id}-date-time-picker`}
          value={selectedDate}
          onChange={onDateChange}
          minDate={minDate}
          width={datePickerWidth}
        />
      </div>

      <TimePicker
        id={`${id}-time-picker`}
        required={required}
        disabled={disabled}
        name={`${name || id}-time-picker`}
        value={selectedDate}
        onChange={onTimeChange}
        minDate={minDate}
        width={width?.timePicker}
      />
    </div>
  )
}

export const DateTimePicker = MaybeRenderWithLabelHOC(DateTimePickerComponent)
