export enum ROUTE_NAMES {
  TERMS_CONDITIONS_PREVIOUS,
  DASHBOARD,
  DRIVEN_LEASE_CONTRACTS,
  DRIVEN_LEASE_CAR_FLEET,
  CUSTOMER_LABELS,
  CUSTOMER_LABELS_DETAILS,
  CUSTOMER_LABELS_CREATE,
  FEATURE_FLAGS,
  MESSAGES,
  MESSAGES_DETAILS,
  MESSAGES_NEW,
  GET_CONNECTORS,
  GET_CONNECTORS_DETAILS,
  GET_CONNECTORS_NEW,
  OAUTH_APPLICATIONS,
  OAUTH_APPLICATIONS_NEW,
  OAUTH_APPLICATIONS_DETAILS,
  TERMS_CONDITIONS,
  POOL_CARS,
  WRPM_OVERVIEW,
}

export type TRoutes = {
  [route in ROUTE_NAMES]: string
}
