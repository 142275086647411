import React from 'react'
import { useTranslation } from 'react-i18next'

import { List } from '../../../../../Components/Panel/Components/Content/List'
// @ts-ignore
// import { useGetSharedMobilityCardStatusQuery } from '../../../../../Lib/graphql'
import { Widget } from '../../Widget'
import { WidgetListItem } from '../../WidgetListItem'
import { IBaseWidgetProps } from '../interfaces'
import { LoadingSkeleton } from './LoadingSkeleton'

export const SharedMobilityCardStatusWidget: React.FC<IBaseWidgetProps> = ({ draggable }): JSX.Element => {
  const { t } = useTranslation()
  // @ts-ignore
  const { data, loading } = useGetSharedMobilityCardStatusQuery({ variables: { organizationId: 1 } })
  const statsSharedMobilityCards = data?.lease?.rbdb?.statsSharedMobilityCards || []

  return (
    <Widget id="SharedMobilityCardStatusWidget" title={t('Shared Mobility Cards')} icon="card" draggable={draggable}>
      {((): JSX.Element => {
        if (loading) return <LoadingSkeleton />

        if (!statsSharedMobilityCards.length) return <div>No items</div>

        return (
          <List rows>
            {/* @ts-ignore */}
            {statsSharedMobilityCards.map((item) => (
              <WidgetListItem key={item.name} count={item.total} disabled={draggable}>
                {t(item.name || 'unknown')}
              </WidgetListItem>
            ))}
          </List>
        )
      })()}
    </Widget>
  )
}

SharedMobilityCardStatusWidget.displayName = 'SharedMobilityCardStatusWidget'
