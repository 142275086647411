import React, { useMemo } from 'react'

interface ILayoutContext {
  integrated: boolean
  organizationId: string | null
  hostName: string
}

export const LayoutContext = React.createContext<ILayoutContext>({
  integrated: false,
  organizationId: null,
  hostName: '',
})

export const LayoutProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const searchParams = new URLSearchParams(window.location.search)
  const integrated = searchParams.get('integrated') === 'true'
  const hostName = searchParams.get('hostname') || document.referrer
  const organizationId = searchParams.get('organization_id')

  const options = useMemo(() => {
    return { integrated, organizationId, hostName }
  }, [integrated, organizationId, hostName])

  return <LayoutContext.Provider value={options}>{children}</LayoutContext.Provider>
}
