import { format } from 'date-fns'
import React from 'react'

import { Typography } from '../../../../../Components/Typography'
import { EditPoolCar } from '../EditPoolCar'
import { TerminatePoolCar } from '../TerminatePoolCar'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Term: React.FC<IProps> = ({ id, startsAt, endsAt }): JSX.Element => {
  const dateFormat = 'dd-MM-yyyy'
  const dateString = `${format(startsAt, dateFormat)} / ${format(endsAt, dateFormat)}`

  return (
    <div className={Styles.term}>
      <Typography variant="body-2">{dateString}</Typography>

      <div className={Styles.buttons}>
        <TerminatePoolCar id={id} />
        <EditPoolCar id={id} />
      </div>
    </div>
  )
}
