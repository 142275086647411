import React, { useEffect, useState } from 'react'

import { Icon } from '../../Icon'
import { Input } from '../Input'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Checkbox: React.FC<IProps> = ({
  'aria-label': ariaLabel,
  checked,
  errors,
  id,
  label,
  onChange,
  required,
  disabled,
  className,
  name,
  ...otherProps
}): JSX.Element => {
  const [isChecked, setIsChecked] = useState<boolean>(checked)

  // Somehow without this the state is not being set properly when you toggle it in storybook?
  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  const handleOnChange = (): void => {
    if (disabled) return

    const newValue = !isChecked
    setIsChecked(newValue)
    onChange && onChange(newValue)
  }

  const classNames: string[] = [Styles.checkbox, ...(className ? [className] : [])]

  return (
    <div
      data-testid={`wrapper-${id}`}
      className={[Styles.checkboxWrapper, ...(disabled ? [Styles.disabled] : [])].join(' ')}
      onClick={(event): void => {
        event.stopPropagation()
        handleOnChange()
      }}
      tabIndex={0}
      role="link"
      aria-label={ariaLabel}
    >
      <div
        id={`checkbox-${id}`}
        className={`${classNames.join(' ')} ${isChecked ? Styles.checked : ''} ${disabled ? Styles.disabled : ''}`}
      >
        <Icon name="check" />
      </div>

      <Input
        {...otherProps}
        name={name}
        aria-label={ariaLabel || label}
        labelCssClasses={[Styles.inputLabel]}
        wrapperClassName={Styles.inputWrapper}
        errors={errors}
        id={id}
        label={label}
        required={required}
        type="hidden"
        onChange={(): void => {}}
        value={isChecked.toString()}
        disabled={disabled}
        tabIndex={0}
        role="link"
      />
    </div>
  )
}
