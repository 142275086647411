import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Form } from '../../../Components/FormComponents/Form'
import { InlineFields } from '../../../Components/FormComponents/InlineFields'
import { Input } from '../../../Components/FormComponents/Input'
import { TextArea } from '../../../Components/FormComponents/TextArea'
import { Info } from '../../../Components/Info'
import { MultiSelect } from '../../../Components/MultiSelect'
import { IOption } from '../../../Components/MultiSelect/interfaces'
import { options } from '../../../Components/MultiSelect/mock'
import { PageContainer } from '../../../Components/PageContainer'
import { ConfirmFooter } from '../../../Components/PageContainer/Components/Footers/ConfirmFooter'
import { Panel } from '../../../Components/Panel'
import { Section } from '../../../Components/Panel/Components/Content/Section'

const GetConnectorsNew = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  //   General
  const [name, setName] = useState<string>('')
  const [organisations, setOrganisations] = useState<IOption[]>([])

  // Fields
  const [booleanFields, setBooleanFields] = useState<string>('')
  const [conditionalFields, setConditionalFields] = useState<string>('')
  const [renamedFields, setRenamedFields] = useState<string>('')

  const renderFooter = (): JSX.Element => {
    return (
      <ConfirmFooter confirmButtonLabel={t('Save')} onConfirm={(): void => {}} onCancel={(): void => navigate(-1)} />
    )
  }

  const getTextForForm = (form: string): string => {
    return t('Keep the following form: {{ form }}', {
      form,
    })
  }

  return (
    <PageContainer footerContent={renderFooter()} header={{ title: t('Add GetConnector') }}>
      <Form>
        <Panel>
          <Section title={t('General')}>
            <InlineFields width="623px">
              <Input id="name" label={t('Name')} value={name} onChange={setName} />

              <MultiSelect
                id="organisations"
                label={t('Organisations', { context: 'maybe_multiple' })}
                placeholder={t('Select organisation')}
                options={options}
                selectedOptions={organisations}
                onChange={setOrganisations}
              />
            </InlineFields>
          </Section>
        </Panel>

        <Panel>
          <Section title={t('Fields')}>
            <Form width="623px">
              <TextArea
                id="booleanFields"
                label={t('Boolean fields')}
                value={booleanFields}
                onChange={setBooleanFields}
              />

              <Info>{getTextForForm('[{ "field": "Field name in accounting package", "invert": true|false }]')}</Info>

              <TextArea
                id="conditionFields"
                label={t('Conditional fields')}
                value={conditionalFields}
                onChange={setConditionalFields}
              />

              <Info>
                {getTextForForm(
                  '[{ "if_field": "Field name in accounting package", "if_value": "Value to match on", "field": "Field to put the result in", "then_value": "Exact value if condition is true", "then_field": "Field if condition is true", "else_value": "Exact value if condition is false", "else_field": "Field if condition is false" }]'
                )}
              </Info>

              <TextArea
                id="renamedFields"
                label={t('Renamed fields')}
                value={renamedFields}
                onChange={setRenamedFields}
              />

              <Info>
                {getTextForForm('[{ "from": "Field name in accounting package", "to": "Field to put the result in" }]')}
              </Info>
            </Form>
          </Section>
        </Panel>
      </Form>
    </PageContainer>
  )
}

export default GetConnectorsNew
