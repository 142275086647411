import { format } from 'date-fns'

import { TLocale } from '../../sharedInterfaces'
import { localeForDateFn, localeForICU } from './helpers'
import { ICurrentDateProps } from './interfaces'

export const parseDate = (dateString: string): Date => new Date(dateString.replace(/-/g, '/'))

export const formatDate = (date: Date | string, options: ICurrentDateProps): string => {
  try {
    // Safari has a bug that it cannot handle date strings like `new Date("2021-09-10 13:35:07 +0200")`
    // replacing it with `new Date("2021/09/10 13:35:07 +0200")` works fine...

    let parsedDate: Date
    if (typeof date === 'string') {
      if (date.match(/T/)) parsedDate = new Date(date)
      else parsedDate = new Date(date.replace(/-/g, '/'))
    } else parsedDate = date

    // parsedDate = typeof date === 'string' ? new Date(date.replace(/-/g, '/')) : date

    const {
      locale,
      humanized = false,
      formatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
    } = options

    const convertedLocale = localeForICU(locale)

    if (!humanized) return new Intl.DateTimeFormat(convertedLocale).format(parsedDate)

    return parsedDate.toLocaleDateString(convertedLocale, formatOptions)
  } catch (e) {
    console.error('could not cover date', date, e)
    return date.toString()
  }
}

export const currentDate = (options: ICurrentDateProps): string => {
  return formatDate(new Date(), options)
}

export const formatDateToISOString = (date: Date): string => format(date, 'yyyy-MM-dd')

export const formatDateString = (date: string | Date, formatString: string, locale: TLocale): string => {
  if (!date) return ''
  const dateString = typeof date === 'object' ? date.toString() : date
  if (!dateString) return ''
  return format(new Date(dateString.replace(/-/g, '/')), formatString, { locale: localeForDateFn(locale) })
}

/**
 * @param number
 * @returns Returns the given number with a leading zero if needed.
 */
export const getLeadingZeroTimeUnit = (number: number): string => {
  return number < 10 ? `0${number}` : `${number}`
}
