import React from 'react'

import { Typography } from '../../../Typography'
import Styles from '../../styles.module.scss'
import { IProps } from './interfaces'

export const Bar: React.FC<IProps> = ({ barName, label, value }): JSX.Element => {
  return (
    <div key={barName} className={Styles.barWrapper}>
      <div className={Styles.bar}>
        <Typography className={Styles.label} variant="body-4">
          {label}
        </Typography>
        <div className={Styles.innerBar} style={{ height: `${value}%` }} />
      </div>

      <Typography variant="body-3" className={Styles.barName}>
        {barName}
      </Typography>
    </div>
  )
}
