import React, { forwardRef } from 'react'

import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Container = forwardRef<HTMLDivElement, IProps>(({ disabled, children, onClick, style }: IProps, ref) => {
  return (
    <div
      ref={ref}
      style={
        {
          ...style,
        } as React.CSSProperties
      }
      className={`${Styles.container} ${!disabled ? Styles.active : ''}`}
      onClick={onClick}
    >
      <div className={Styles.innerContainer}>{children}</div>
    </div>
  )
})
