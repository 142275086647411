import React, { useMemo } from 'react'

import { TTableDisplaySettings } from '../../Components/LeasefleetTable/interfaces'
import { IUserPreferences } from './interfaces'

export const UserPreferencesContext = React.createContext<IUserPreferences>({
  loadTableDisplaySettings(): TTableDisplaySettings {
    return {}
  },
  saveTableDisplaySettings: (_name, _settings): void => {},
})
export const UserPreferencesProvider = ({ children }: { children: JSX.Element }): JSX.Element | null => {
  const options = useMemo(() => {
    const loadTableDisplaySettings = (name: string): TTableDisplaySettings => {
      const saved = window.localStorage.getItem(`tableSettings-${name}`)
      return saved !== null ? (JSON.parse(saved) as TTableDisplaySettings) : {}
    }

    const saveTableDisplaySettings = (name: string, layout: TTableDisplaySettings): void => {
      window.localStorage.setItem(`tableSettings-${name}`, JSON.stringify(layout))
    }

    return { saveTableDisplaySettings, loadTableDisplaySettings }
  }, [])

  return <UserPreferencesContext.Provider value={options}>{children}</UserPreferencesContext.Provider>
}
