import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import React from 'react'

import { Container } from '../Container'
import { IProps } from './interfaces'

export const DroppableContainer: React.FC<IProps> = ({ children, disabled, id, items }) => {
  const { isDragging, setNodeRef, transition, transform } = useSortable({
    id,
    data: {
      type: 'container',
      children: items,
    },
  })

  return (
    <Container
      disabled={!!disabled}
      ref={disabled ? undefined : setNodeRef}
      style={{
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : undefined,
      }}
    >
      {children}
    </Container>
  )
}
