import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select, { ActionMeta, MultiValue } from 'react-select'

import { MaybeRenderWithLabelHOC } from '../../Lib/MaybeRenderWithLabelHOC'
import Colors from '../../Theme/colors.module.scss'
import { Badge } from './Components/Badge'
import { DownChevron } from './Components/DownChevron'
import { Input } from './Components/Input'
import { MenuList } from './Components/MenuList'
import { Null } from './Components/Null'
import { Option } from './Components/Option'
import { ValueContainer } from './Components/ValueContainer'
import { badgesLimit } from './helpers'
import { IOption, IProps } from './interfaces'
import Styles from './styles.module.scss'

export const MultiSelectComponent: React.FC<IProps> = ({
  selectedOptions,
  onChange,
  options,
  placeholder,
  disabled = false,
}): JSX.Element => {
  const { t } = useTranslation()

  const [showAllBadges, setShowAllBadges] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = (value: string): void => {
    setInputValue(value)
  }

  const handleChange = (newValue: MultiValue<IOption>, actionMeta: ActionMeta<IOption>): void => {
    onChange(newValue as IOption[])

    // Check if the user has selected an option (not clearing the input)
    if (actionMeta.action === 'select-option' || actionMeta.action === 'deselect-option') setInputValue(inputValue)
  }

  const badges = showAllBadges ? selectedOptions : selectedOptions?.slice(0, badgesLimit)

  const isSelected = (option: IOption): boolean => selectedOptions.some((selected) => selected.value === option.value)

  const unSelectOption = (option: IOption): void =>
    onChange(selectedOptions.filter((stateOption) => stateOption.value !== option.value))

  return (
    <div>
      <Select<IOption, true>
        id="multi-selector"
        options={options}
        backspaceRemovesValue={false}
        isDisabled={disabled}
        placeholder={placeholder || t('Select')}
        styles={{
          control: (provided) => ({
            ...provided,
            border: `0.5px solid ${Colors.gray200}`,
            marginTop: '10px',
          }),
          menuList: (provided) => ({
            ...provided,
            maxHeight: '201px',
          }),
          placeholder: (provided) => ({
            ...provided,
            order: 1,
          }),
          valueContainer: (provided) => ({
            ...provided,
            display: 'flex',
            paddingTop: 11,
            paddingBottom: 11,
          }),
        }}
        components={{
          Input,
          ValueContainer,
          MenuList,
          Option,
          DownChevron,
          Placeholder: Null,
          SingleValue: Null,
          MultiValue: Null,
          IndicatorSeparator: Null,
        }}
        noOptionsMessage={(_obj: { inputValue: string }): string | null => t('no results found...')}
        isOptionSelected={isSelected}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        value={selectedOptions}
        onChange={handleChange}
        isClearable={false}
        isMulti
      />

      {badges.length > 0 && (
        <div data-testid="badges" className={Styles.badges}>
          {badges.map((selectedOption) => (
            <Badge
              key={selectedOption.value}
              label={selectedOption.label}
              onClick={(): void => unSelectOption(selectedOption)}
              disabled={disabled}
              removeable
            />
          ))}

          {!showAllBadges && selectedOptions?.length > badgesLimit && (
            <Badge
              onClick={(): void => setShowAllBadges(true)}
              label={`+${selectedOptions.length - badgesLimit}`}
              disabled={disabled}
            />
          )}
        </div>
      )}
    </div>
  )
}

export const MultiSelect = MaybeRenderWithLabelHOC(MultiSelectComponent)
