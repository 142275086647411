import React from 'react'

import { Icon } from '../../../../../../../../Components/Icon'
import { Typography } from '../../../../../../../../Components/Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const SelectButton = <T,>({ icon, label, onClick, value, active }: IProps<T>): JSX.Element => {
  const handleClick = (): void => {
    if (!onClick) return

    onClick(active ? undefined : value)
  }

  const classNames = ((): string => {
    const classes: string[] = [Styles.selectButton]

    if (onClick) classes.push(Styles.clickable)
    if (active) classes.push(Styles.active)

    return classes.join(' ')
  })()

  return (
    <div className={classNames} onClick={handleClick}>
      <Icon className={Styles.icon} name={icon} />
      <Typography className={Styles.label} variant="h1" element="span">
        {label}
      </Typography>

      {active && <Icon width={12} height={12} className={Styles.closeIcon} name="cross-bold" />}
    </div>
  )
}
