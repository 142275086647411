// eslint-disable-next-line import/no-unresolved
import 'virtual:svg-icons-register'

import React from 'react'
import ReactDOM from 'react-dom/client'
import Modal from 'react-modal'

import { App } from './App'

Modal.setAppElement('#root')

const createRoot = (root: HTMLElement): void => {
  ReactDOM.createRoot(root).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )
}

const root = document.getElementById('root')
root ? createRoot(root) : console.error('could not find an element with id root in the DOM')
