import React, { useEffect, useRef, useState } from 'react'

import { Bar } from './Components/Bar'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const BarChart: React.FC<IProps> = ({ data }): JSX.Element => {
  const [scrollableWidth, setScrollableWidth] = useState(0)
  const scrollableDivRef = useRef<HTMLDivElement | null>(null)

  const values = data.map((option) => option.value)
  const sum = values.reduce((total, num) => total + num, 0)
  const average = Math.floor(sum / data.length)
  const averageInPercentage = Math.floor((average / Math.max(...values)) * 100)

  useEffect(() => {
    if (scrollableDivRef.current) {
      const scrollableDiv = scrollableDivRef.current
      setScrollableWidth(scrollableDiv.scrollWidth)
    }
  }, [])

  return (
    <div className={Styles.barsChart}>
      <div data-testid="bars-wrapper" className={Styles.bars} ref={scrollableDivRef}>
        {data.length > 1 && (
          <div
            id="average"
            // @ts-ignore
            style={{ bottom: `${averageInPercentage}%`, '--line-width': `${scrollableWidth}px` }}
            className={Styles.average}
          />
        )}

        {data.map((bar, index) => {
          const maxValue = Math.max(...values)

          return <Bar key={`${bar.barName}-${index}`} {...bar} value={(bar.value / maxValue) * 100} />
        })}
      </div>
    </div>
  )
}
