import './styles.module.scss'

import React from 'react'
import Modal, { Styles } from 'react-modal'

import { Icon } from '../Icon'
import { IProps } from './interfaces'

export const ModalContainer: React.FC<IProps> = ({
  children,
  title,
  show,
  onClose,
  fullWidth,
  fullHeight,
  styles,
  withPadding = true,
  className,
  id,
  ...props
}): JSX.Element => {
  const additionalStyling: Styles = {
    content: {
      ...styles?.content,
    },
    overlay: {
      ...styles?.overlay,
    },
  }

  const cssClasses = []
  if (fullWidth) cssClasses.push('fullWidth')
  if (fullHeight) cssClasses.push('fullHeight')
  if (withPadding) cssClasses.push('withPadding')
  if (className) cssClasses.push(className)

  return (
    /* if you change the closeTimeOutMS make sure to change it also in the CSS file */
    <Modal
      {...props}
      className={cssClasses.join(' ')}
      isOpen={show}
      onRequestClose={(event): void => {
        event.stopPropagation()
        onClose()
      }}
      style={additionalStyling}
      contentLabel={title}
      closeTimeoutMS={250}
      {...(id && { id })}
    >
      <React.Fragment>
        <div className="ReactModal__Overlay__CloseButton">
          <Icon
            data-testid="close-button"
            name="cross"
            onClick={(event): void => {
              event.stopPropagation()
              onClose()
            }}
          />
        </div>
        {children}
      </React.Fragment>
    </Modal>
  )
}
