import React from 'react'

import { Typography } from '../../../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ContentSection: React.FC<IProps> = ({ id, title, noPadding = false, children }): JSX.Element => {
  return (
    <section {...(id ? { id } : {})} className={`${Styles.contentSection} ${noPadding ? Styles.noPadding : ''}`}>
      {title && (
        <Typography variant="h1" element="h3" className={Styles.title}>
          {title}
        </Typography>
      )}
      {children}
    </section>
  )
}
