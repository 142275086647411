import React from 'react'

import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Badge: React.FC<IProps> = ({ children, className }): JSX.Element => {
  return (
    <div data-testid="badge" className={`${Styles.badge} ${className || ''}`}>
      {children}
    </div>
  )
}
