import * as React from 'react'

import { Label } from '../../Components/Label'
import { IProps } from './interfaces'

export const MaybeRenderWithLabelHOC =
  <receivedProps extends Record<string, any>>(Component: React.FC<receivedProps>): React.FC<receivedProps & IProps> =>
  (props: receivedProps & IProps): JSX.Element => {
    const { attribute, errors, id, label, inline, required, width } = props
    const { labelCssClasses, isOptional, hint, ...remainingProps } = props

    if (!label) return <Component {...(remainingProps as receivedProps)} required={required} />

    return (
      <Label
        attribute={attribute}
        errors={errors}
        id={id}
        label={label}
        labelCssClasses={labelCssClasses}
        required={required}
        inline={inline}
        width={width}
        isOptional={isOptional}
        hint={hint}
      >
        <Component {...(remainingProps as receivedProps)} required={required} />
      </Label>
    )
  }
