import React, { useCallback, useEffect, useRef, useState } from 'react'

import { getLeadingZeroTimeUnit } from '../../../Lib/Formatters/DateFormatter'
import { MaybeRenderWithLabelHOC } from '../../../Lib/MaybeRenderWithLabelHOC'
import { Input } from '../Input'
import TimeSelectPanel from './components/TimeSelectPanel'
import { getDisabledTimes, maybeGetFallbackForDate } from './helpers'
import { IProps, ITime } from './interfaces'
import Styles from './styles.module.scss'

const TimePickerComponent: React.FC<IProps> = ({
  id,
  className,
  disabled = false,
  onChange,
  value,
  inline,
  required,
  minDate,
  width = '104px',
}): JSX.Element => {
  const disabledTimes = minDate ? getDisabledTimes(minDate, value) : undefined
  const timePickerRef = useRef<HTMLDivElement>(null)
  const [showTimeSelect, setShowTimeSelect] = useState<boolean>(false)
  const [timeInputValue, setTimeInputValue] = useState<string>('')
  const [selectedTime, setSelectedTime] = useState<ITime>()

  const getHours = (): number => {
    if (!selectedTime) return 0

    return selectedTime.hours
  }

  const getMinutes = (): number => {
    if (!selectedTime) return 0

    return selectedTime.minutes
  }

  const getTimeObjectAsTimeString = useCallback((time: ITime): string => {
    return `${getLeadingZeroTimeUnit(time.hours)}:${getLeadingZeroTimeUnit(time.minutes)}`
  }, [])

  const isValidTimeString = (timeString: string): boolean => {
    const format = /^\d{2}:\d{2}$/

    return format.test(timeString)
  }

  const getTimeStringAsTimeObject = useCallback((time: string): ITime => {
    const [hours, minutes] = time.split(':')

    return {
      hours: Number(hours),
      minutes: Number(minutes),
    }
  }, [])

  const getTimeStringWithLeadingZeros = (time: string): string => {
    const timeObj = getTimeStringAsTimeObject(time)

    return getTimeObjectAsTimeString(timeObj)
  }

  const isTimeChanged = (newTime: ITime): boolean => {
    return newTime.hours !== selectedTime?.hours || newTime.minutes !== selectedTime?.minutes
  }

  const onTimeInputFocus = ({ target }: React.FocusEvent): void => {
    setShowTimeSelect(true)

    if (target) {
      // we need a little time for the DOM to update the input value before selecting it.
      setTimeout(() => {
        ;(target as HTMLInputElement).select()
      }, 5)
    }
  }

  /**
   * Will return the time string of the input value when the hours and minutes are
   * valid. Otherwise will return the fallback value.
   *
   * @param inputValue
   * @param fallback
   * @returns
   */
  const getValidTime = (inputValue: string, fallback: string): string => {
    const format = /^(?<hour>\d{1,2})(:(?<minutes>\d{1,2})?)?$/
    const matches = inputValue.match(format)

    // only return the input time when the hours and minutes are valid
    if (
      matches &&
      !(
        (matches?.groups?.hour && Number(matches.groups.hour) > 23) ||
        (matches?.groups?.minutes && Number(matches.groups.minutes) > 59)
      )
    )
      return inputValue

    return fallback
  }

  const onTimeInputChange = (inputValue: string): void => {
    if (inputValue.length === 0) setTimeInputValue(inputValue)
    else setTimeInputValue(getValidTime(inputValue, timeInputValue))
  }

  const onTimeChange = (time: ITime): void => {
    if (!isTimeChanged(time)) return

    setSelectedTime(time)
    setTimeInputValue(getTimeObjectAsTimeString(time))

    const date = new Date(value.getTime())
    date.setHours(time.hours, time.minutes)

    const fallback = minDate ? maybeGetFallbackForDate(minDate, date) : date
    onChange(fallback)
  }

  const onTimeInputBlur = (inputValue: string): void => {
    const newTime = getTimeStringWithLeadingZeros(inputValue)

    if (!selectedTime || (isValidTimeString(newTime) && getTimeObjectAsTimeString(selectedTime) !== newTime))
      onTimeChange(getTimeStringAsTimeObject(newTime))
    else if (!isValidTimeString(newTime)) setTimeInputValue(getTimeObjectAsTimeString(selectedTime))
  }

  const onTimeSelectPanelOutsideClick = (event: MouseEvent | TouchEvent): void => {
    const inputElement = timePickerRef.current?.querySelector(`input#${id}`)

    if (event.target !== inputElement) setShowTimeSelect(false)
  }

  const onTimeInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault()
      ;(event.target as HTMLInputElement).blur()
      setShowTimeSelect(false)
    }
  }

  useEffect((): void => {
    const fallbackDate = minDate ? maybeGetFallbackForDate(minDate, value) : value
    const newTime = { hours: fallbackDate.getHours(), minutes: fallbackDate.getMinutes() }

    setSelectedTime(newTime)
    setTimeInputValue(getTimeObjectAsTimeString(newTime))
  }, [value, getTimeObjectAsTimeString, minDate])

  return (
    <div
      // @ts-ignore
      style={{ '--width': width }}
      className={`${Styles.timePicker} ${inline ? Styles.inline : ''}`}
      ref={timePickerRef}
    >
      <Input
        id={id}
        wrapperClassName={Styles.inputWrapper}
        className={className || ''}
        disabled={disabled}
        required={required}
        value={timeInputValue}
        iconName="clock"
        clearable={false}
        onFocus={onTimeInputFocus}
        onChange={onTimeInputChange}
        onBlur={onTimeInputBlur}
        onKeyDown={onTimeInputKeyDown}
      />
      {showTimeSelect && (
        <TimeSelectPanel
          hours={getHours()}
          minutes={getMinutes()}
          onChange={onTimeChange}
          onClickOutside={onTimeSelectPanelOutsideClick}
          disabledTimes={disabledTimes}
        />
      )}
    </div>
  )
}

export const TimePicker = MaybeRenderWithLabelHOC(TimePickerComponent)
