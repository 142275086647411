import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DateTimePicker } from '../../../../Components/FormComponents/DateTimePicker'
import { Form } from '../../../../Components/FormComponents/Form'
import { Radio } from '../../../../Components/FormComponents/Radio'
import { Option } from '../../../../Components/FormComponents/Radio/Components/Option'
import { Modal } from '../../../../Components/Modal'
import { ConfirmFooter, ContentSection, Footer } from '../../../../Components/Modal/Components'
import { MultiSelect } from '../../../../Components/MultiSelect'
import { IOption } from '../../../../Components/MultiSelect/interfaces'
import { OptionEnum, PublishOnEnum, TEndDateOption, TPublishOnOption } from '../../New/interfaces'
import Styles from '../../New/styles.module.scss'
import { IProps } from '../interfaces'

export const EditDetails: React.FC<IProps> = ({ onClose }): JSX.Element => {
  const { t } = useTranslation()

  const [endDateOption, setEndDateOption] = useState<TEndDateOption>({
    value: OptionEnum.DATE,
    date: new Date(),
  })

  const [publishOnOption, setPublishOnOption] = useState<TPublishOnOption>({
    value: PublishOnEnum.ORGANISATION,
    organisations: [],
  })

  const organisations: IOption[] = [
    { value: '1', label: 'Reisbalans' },
    { value: '2', label: 'Shuttel' },
  ]
  const handlePublishOnChange = (value: PublishOnEnum, list?: IOption[]): void => {
    setPublishOnOption((prev) => ({ value, organisations: list || prev.organisations }))
  }

  const handleEndDateOptionChange = (value: OptionEnum, date?: Date): void => {
    setEndDateOption((prev) => ({ value, date: date || prev.date }))
  }

  return (
    <Modal title={t('Edit concept')} onClose={onClose} show>
      <ContentSection title={t('Visibility')}>
        <Form>
          <DateTimePicker
            id="publicationDate"
            label={t('Publication date')}
            width={{ datePicker: '307px', timePicker: '154px' }}
          />

          <Radio
            id="endDate"
            label={t('End date')}
            value={endDateOption.value}
            onChange={(value): void => handleEndDateOptionChange(value as OptionEnum)}
          >
            <Option value={OptionEnum.DATE} inline>
              <DateTimePicker
                disabled={endDateOption.value !== OptionEnum.DATE}
                id="endDate"
                value={endDateOption.date}
                onChange={(value): void => handleEndDateOptionChange(OptionEnum.DATE, value)}
                width={{
                  datePicker: '275px',
                  timePicker: '153px',
                }}
              />
            </Option>

            <Option value={OptionEnum.NO_DATE} label={t('No end date, to be determined')} />
          </Radio>

          <Radio
            id="publishOn"
            label={t('Publish on')}
            value={publishOnOption.value}
            onChange={(value): void => handlePublishOnChange(value as PublishOnEnum)}
          >
            <Option value={PublishOnEnum.CONTRACT} label={t('Contract level')} />
            <Option value={PublishOnEnum.ORGANISATION} label={t('Organisation level')}>
              <div className={Styles.multiSelectWrapper}>
                <MultiSelect
                  id="organisations"
                  options={organisations}
                  placeholder={t('Select organisations')}
                  selectedOptions={publishOnOption.organisations}
                  disabled={publishOnOption.value !== PublishOnEnum.ORGANISATION}
                  onChange={(list): void => handlePublishOnChange(PublishOnEnum.ORGANISATION, list)}
                />
              </div>
            </Option>
          </Radio>
        </Form>
      </ContentSection>

      <Footer>
        <ConfirmFooter
          cancelProps={{ onClick: onClose }}
          confirmProps={{ label: t('Save'), onClick: (): void => {} }}
        />
      </Footer>
    </Modal>
  )
}
