import React from 'react'

import { Typography } from '../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Info: React.FC<IProps> = ({ children }) => {
  return (
    <Typography variant="body-2" className={Styles.info}>
      {children}
    </Typography>
  )
}
