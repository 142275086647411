import { format } from 'date-fns'

import { capitalizeString, localeForDateFn, parseDate } from '../../Lib/Formatters/DateFormatter'
import { TLocale } from '../../Lib/sharedInterfaces'

export const formatDate = (date: string | null, locale: TLocale): string => {
  if (!date) return '-'
  return format(parseDate(date), 'dd-MM-yyyy', { locale: localeForDateFn(locale) })
}

export const getValidValue = (value: string | undefined): string => {
  if (!value || value === 'null') return '-'
  return value
}

export const getStatusTranslation = (status: string, locale: TLocale): string => {
  if (locale !== 'nl') return capitalizeString(status)

  const map: { [key: string]: string } = {
    cancelled: 'Geannuleerd',
    ordered: 'Besteld',
    running: 'Lopend',
    terminated: 'Beëindigd',
  }

  return map[status] || capitalizeString(status)
}

export const getFuelTypeTranslation = (fuelType: string | undefined, locale: TLocale): string => {
  if (!fuelType) return ''
  if (locale !== 'nl') return capitalizeString(fuelType)

  const map: { [key: string]: string } = {
    public: 'Openbaar',
    home: 'Thuis',
    quick: 'Snel',
    alcohol: 'Alcohol',
    petrol: 'Benzine',
    cryogenic: 'Cryogeen',
    diesel: 'Diesel',
    electric: 'Elektrisch',
    LPG: 'LPG',
    CNG: 'CNG',
    hydrogen: 'Waterstof',
    hybrid: 'Hybride',
  }

  return map[fuelType] || capitalizeString(fuelType)
}

export const getTranslatedCostCenter = (costCenter: string | undefined, locale: TLocale): string | undefined => {
  if (costCenter === 'Not assigned' && locale === 'nl') return 'Niet toegekend'
  return costCenter
}
