import React from 'react'

import { Icon } from '../../../../Icon'
import { LazyImage } from '../../../../LazyImage'
import { Divider } from '../Divider'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ImageHeader: React.FC<IProps> = ({ icon, src }): JSX.Element => {
  return (
    <React.Fragment>
      {src ? (
        <LazyImage data-testid="image-preview" src={src} alt="image" />
      ) : (
        <Icon data-testid="icon" className={Styles.icon} name={icon} />
      )}
      <Divider />
    </React.Fragment>
  )
}
