import { forwardRef } from 'react'

import { Typography } from '../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Section = forwardRef<HTMLDivElement, IProps>(({ title, children }, ref) => {
  return (
    <div className={Styles.section} ref={ref}>
      <Typography className={Styles.title} variant="h1" element="h3">
        {title}
      </Typography>
      {children}
    </div>
  )
})
