import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ContentSection } from '../../../Modal/Components'
import { Typography } from '../../../Typography'
import { ManualView } from './Components/ManualView'
import { TableViewOption } from './Components/TableViewOption'
import { IConfigModalProps } from './interfaces'
import Styles from './styles.module.scss'

export const ConfigModal: FC<IConfigModalProps> = ({
  tableConfig,
  tableDisplaySettings,
  selectedTableDisplaySetting,
  onChangeCallback,
  onDeleteCallback,
  onUseCallback,
}): JSX.Element => {
  const { t } = useTranslation()

  const getDescription = (key: string): string => {
    return tableDisplaySettings[key].showFields
      .map((item) => (tableConfig[item] ? tableConfig[item].name : `unknown ${item}`))
      .join(', ')
  }

  return (
    <React.Fragment>
      <ContentSection title={t('Choose a view')}>
        <Typography className={Styles.subTitle} variant="body-2">
          {t('Choose a view')}
        </Typography>

        <div>
          {Object.keys(tableDisplaySettings)
            .filter((option) => option !== 'custom')
            .map((config, index) => {
              const isActive = tableDisplaySettings[config].__default
              return (
                <TableViewOption
                  key={`${config}-${index}`}
                  name={t(config)}
                  variants={getDescription(config)}
                  onApply={(): void => onUseCallback(config)}
                  isActive={isActive}
                  {...(index > 0 && { onDelete: (): void => onDeleteCallback(config) })}
                />
              )
            })}
        </div>
      </ContentSection>

      <Typography className={Styles.divider} variant="h1" element="span">
        {t('or')}
      </Typography>

      <ManualView
        tableConfig={tableConfig}
        tableDisplaySettings={tableDisplaySettings}
        selectedTableDisplaySetting={selectedTableDisplaySetting}
        onChange={onChangeCallback}
      />
    </React.Fragment>
  )
}
