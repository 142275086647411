import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../../../../../Components'
import { InlineFields } from '../../../../../../../Components/FormComponents/InlineFields'
import { Input } from '../../../../../../../Components/FormComponents/Input'
import { Modal } from '../../../../../../../Components/Modal'
import { ConfirmFooter, ContentSection } from '../../../../../../../Components/Modal/Components'

export const Edit = (): JSX.Element => {
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')

  const clickHandler = (): void => setShow(true)
  const closeHandler = (): void => setShow(false)

  const canSubmit = !!email && !!phoneNumber
  const renderFooter = (): JSX.Element => {
    return (
      <ConfirmFooter
        cancelProps={{ onClick: closeHandler }}
        confirmProps={{ label: t('Save'), onClick: closeHandler, disabled: !canSubmit }}
      />
    )
  }

  const formType = t('General change')

  return (
    <React.Fragment>
      <Button variant="secondaryBold" size="small" onClick={clickHandler}>
        {t('Edit', { context: 'short' })}
      </Button>

      {show && (
        <Modal
          title={t('Customer label - {{ formType }}', { formType })}
          onClose={closeHandler}
          footer={renderFooter()}
          show
        >
          <ContentSection>
            <InlineFields>
              <Input id="email" label={t('E-mail address')} value={email} onChange={setEmail} required />
              <Input
                id="phoneNumber"
                label={t('Phone number')}
                value={phoneNumber}
                onChange={setPhoneNumber}
                required
              />
            </InlineFields>
          </ContentSection>
        </Modal>
      )}
    </React.Fragment>
  )
}
