import React from 'react'

import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const InlineFields: React.FC<IProps> = ({ children, className, width }): JSX.Element => {
  return (
    <div className={`${Styles.inlineFields} ${className}`} style={{ width: width || 'auto' }}>
      {children}
    </div>
  )
}
