import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DateTimePicker } from '../../../../../../Components/FormComponents/DateTimePicker'
import { IFile } from '../../../../../../Components/FormComponents/FileUpload/interfaces'
import { Form } from '../../../../../../Components/FormComponents/Form'
import { Radio } from '../../../../../../Components/FormComponents/Radio'
import { Option } from '../../../../../../Components/FormComponents/Radio/Components/Option'
import { ConfirmFooter, ContentSection, Footer } from '../../../../../../Components/Modal/Components'
import { FileUploadSection } from '../../../../../../Components/Modal/Components/FileUploadSection'
import { IProps } from '../interfaces'

export const Platform: React.FC<IProps> = ({ onClose }): JSX.Element => {
  const { t } = useTranslation()
  const [kind, setKind] = useState<string>()
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [file, setFile] = useState<IFile | null>(null)

  return (
    <React.Fragment>
      <ContentSection title="Over de voorwaarden">
        <Form>
          <Radio id="1" label={t('Kind')} value={kind} onChange={setKind}>
            <Option label="Gebruikersvoorwaarden" value="1" />
            <Option label="Privacy voorwaarden" value="2" />
          </Radio>

          <DateTimePicker
            id="start-date"
            label={t('Starting date')}
            value={startDate}
            onChange={setStartDate}
            width={{ datePicker: '196px' }}
          />
        </Form>
      </ContentSection>

      <FileUploadSection file={file} onChange={setFile} />

      <Footer>
        <ConfirmFooter
          cancelProps={{ onClick: onClose }}
          confirmProps={{ label: t('Save'), onClick: (): void => {} }}
        />
      </Footer>
    </React.Fragment>
  )
}
