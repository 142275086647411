import React from 'react'

import { Typography } from '../../../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const TitleContainer: React.FC<IProps> = ({ isHeaderFixed, title, headerCustomComponent }): JSX.Element => {
  const titleContainerClasses = ((): string => {
    const classes: string[] = [Styles.titleContainer]

    if (isHeaderFixed) classes.push(Styles.fixed)

    return classes.join(' ')
  })()

  return (
    <div className={titleContainerClasses}>
      <Typography variant={isHeaderFixed ? 'h1' : 'h1-xx-large'} className={Styles.title}>
        {title}
      </Typography>
      {headerCustomComponent}
    </div>
  )
}
