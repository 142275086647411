import React from 'react'
import { useTranslation } from 'react-i18next'

import { List } from '../../../../../Components/Panel/Components/Content/List'
// @ts-ignore
// import { useGetPersonalMobilityCardStatusQuery } from '../../../../../Lib/graphql'
import { Widget } from '../../Widget'
import { WidgetListItem } from '../../WidgetListItem'
import { IBaseWidgetProps } from '../interfaces'
import { TEntries, TTypes } from './interfaces'
import { LoadingSkeleton } from './LoadingSkeleton'

export const PersonalMobilityCardStatusWidget: React.FC<IBaseWidgetProps> = ({ draggable }): JSX.Element => {
  const { t } = useTranslation()
  // @ts-ignore
  const { data, loading } = useGetPersonalMobilityCardStatusQuery({ variables: { organizationId: 1 } })

  const getIntegratedLink = (type: TTypes): string => {
    return `${document.referrer}admin/mobility_cards?search=&kind=all&status=${type}`
  }

  const entries = ((): TEntries => {
    const statsPersonalMobilityCardStatusCount = data?.lease?.rbdb?.statsPersonalMobilityCardStatusCount
    const defaultEntries = { all: 0, active: 0, inActive: 0, terminated: 0 }
    if (!statsPersonalMobilityCardStatusCount) return defaultEntries

    const pendingStatuses = ['requested', 'initialized', 'manufactured', 'activation_requested']
    const terminatedStatuses = ['being_terminated', 'rejected', 'reported_stolen', 'terminated']
    // @ts-ignore
    return statsPersonalMobilityCardStatusCount.reduce((acc, { status, total }) => {
      if (!status) return acc

      if (status.toLowerCase() === 'in_use') acc.active += total
      if (pendingStatuses.includes(status.toLowerCase())) acc.inActive += total
      if (terminatedStatuses.includes(status.toLowerCase())) acc.terminated += total
      acc.all += total

      return acc
    }, defaultEntries)
  })()

  return (
    <Widget id="PersonalMobilityCardStatusWidget" title={t('Mobility cards')} icon="card" draggable={draggable}>
      {((): JSX.Element => {
        if (loading) return <LoadingSkeleton />

        return (
          <List rows>
            <WidgetListItem count={entries.active} integratedUrl={getIntegratedLink('in_use')} disabled={draggable}>
              {t('In use')}
            </WidgetListItem>
            <WidgetListItem
              count={entries.inActive}
              integratedUrl={getIntegratedLink('before_in_use')}
              disabled={draggable}
            >
              {t('Not yet in use')}
            </WidgetListItem>
            <WidgetListItem
              count={entries.terminated}
              integratedUrl={getIntegratedLink('in_termination')}
              disabled={draggable}
            >
              {t('Terminated')}
            </WidgetListItem>
          </List>
        )
      })()}
    </Widget>
  )
}

PersonalMobilityCardStatusWidget.displayName = 'PersonalMobilityCardStatusWidget'
