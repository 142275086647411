import { FC } from 'react'

import { formatLicensePlate } from '../../Lib/Formatters/licenseFormatter'
import { ILicensePlateProps } from './interfaces'
import Styles from './styles.module.scss'

export const LicensePlate: FC<ILicensePlateProps> = ({ licensePlate }): JSX.Element | null => {
  if (!licensePlate) return null
  return <div className={Styles.licensePlate}>{formatLicensePlate(licensePlate)}</div>
}
