import React, { ChangeEvent, useState } from 'react'

import { MaybeRenderWithLabelHOC } from '../../../Lib/MaybeRenderWithLabelHOC'
import { MaybeWithMaxLengthHintHOC } from '../../../Lib/MaybeWithMaxLengthHintHOC'
import { Typography } from '../../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const TextAreaComponent: React.FC<IProps> = (props): JSX.Element => {
  const {
    autoComplete = 'on',
    className,
    disabled,
    errors,
    inline,
    maxLength,
    onChange,
    required,
    rows = 4,
    label,
    value,
    ...otherProps
  } = props
  const [input, setInput] = useState<string>(value || '')

  const classes = ((): string => {
    const classNames: string[] = [Styles.textarea]

    if (className) classNames.push(className)
    if (disabled) classNames.push(Styles.disabled)
    if (errors?.length) classNames.push(Styles.hasError)
    if (inline) classNames.push(Styles.inline)
    if (maxLength) classNames.push(Styles.maxLength)

    return classNames.join(' ')
  })()

  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    const newValue = event.currentTarget.value
    setInput(newValue)
    if (onChange) onChange(newValue)
  }

  return (
    <MaybeWithMaxLengthHintHOC maxLength={maxLength} label={label}>
      <div className={Styles.wrapper}>
        <textarea
          {...otherProps}
          autoComplete={autoComplete}
          className={classes}
          disabled={disabled}
          maxLength={maxLength?.length}
          onChange={handleOnChange}
          required={required}
          rows={rows}
          aria-label={label}
          value={value || input}
        />

        {maxLength && (
          <Typography
            element="span"
            variant="body-3"
            data-testid="count"
            className={Styles.count}
          >{`${input.length}/${maxLength.length}`}</Typography>
        )}
      </div>
    </MaybeWithMaxLengthHintHOC>
  )
}

export const TextArea = MaybeRenderWithLabelHOC(TextAreaComponent)
