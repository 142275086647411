import React, { ChangeEvent } from 'react'

import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const UploadInput: React.FC<IProps> = ({ innerRef, onChange, accept }): JSX.Element => {
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const file = event.currentTarget.files?.item(0)
    if (file) onChange(file)
  }

  return (
    <input
      className={Styles.input}
      ref={innerRef}
      data-testid="upload-input"
      type="file"
      accept={accept}
      onChange={handleFileChange}
    />
  )
}
