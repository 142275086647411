import { endOfYear, startOfYear } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { BarChart } from '../../../../../Components/BarChart'
import { ROUTE_NAMES } from '../../../../../Layouts/Authorized/interfaces'
import { routes } from '../../../../../Layouts/Authorized/routes'
import { formatCurrency } from '../../../../../Lib/Formatters/currencyFormatter'
import { formatDateString } from '../../../../../Lib/Formatters/DateFormatter'
// @ts-ignore
// import { useGetTransactionWidgetDataQuery } from '../../../../../Lib/graphql'
import { TLocale } from '../../../../../Lib/sharedInterfaces'
import { Widget } from '../../Widget'
import { IBaseWidgetProps } from '../interfaces'
import { Statistic } from './Components/Statistic'
import { TBarChartData } from './interfaces'
import { LoadingSkeleton } from './LoadingSkeleton'
import Styles from './styles.module.scss'

export const FuelCostWidget: React.FC<IBaseWidgetProps> = ({ draggable }): JSX.Element => {
  const { t, i18n } = useTranslation()
  const locale = i18n.language as TLocale
  const now = new Date()
  const startOfYearDate = startOfYear(now)
  const endOfYearDate = endOfYear(now)
  const dateFormat = 'yyyy-MM-dd'

  // @ts-ignore
  const { data, loading } = useGetTransactionWidgetDataQuery({
    variables: {
      from: formatDateString(startOfYearDate, dateFormat, locale),
      to: formatDateString(endOfYearDate, dateFormat, locale),
    },
  })

  const transactions = data?.lease.transactions || []

  const formatDate = (dateString: string): string => formatDateString(new Date(dateString), 'MMM', locale)

  const chartData = ((): TBarChartData[] => {
    const groupedData: { [key: string]: TBarChartData } = {}
    // @ts-ignore
    transactions.forEach((transaction) => {
      const date = formatDate(transaction.transactionDate)

      if (!groupedData[date]) {
        groupedData[date] = {
          barName: date,
          value: 0,
          label: '€0,-',
        }
      }

      groupedData[date].value += transaction.priceExVat || 0
      groupedData[date].label = formatCurrency(groupedData[date].value, locale)
    })

    return Object.values(groupedData)
  })()

  // Find the highest and lowest values
  // @ts-ignore
  const values: number[] = transactions.map((transaction) => transaction.priceExVat || 0) || []
  const highestValue = Math.max(...values)
  const lowestValue = Math.min(...values)

  // Calculate the average transaction value
  const sum = values.reduce((total, value) => total + value, 0)
  const averageTransactionValue = sum / values.length

  return (
    <Widget
      id="FuelCostWidget"
      title={t('Total tank & charging costs')}
      icon="calculator"
      draggable={draggable}
      goTo={{
        label: t('View all refueling and charging costs'),
        disabled: draggable,
        url: `${routes[ROUTE_NAMES.DRIVEN_LEASE_CAR_FLEET]}#transactions`,
      }}
    >
      {((): JSX.Element => {
        if (loading) return <LoadingSkeleton />

        return (
          <React.Fragment>
            <div className={Styles.barChartWrapper}>
              <BarChart data={chartData} />
            </div>
            <div className={[Styles.statistics, ...(draggable ? [Styles.disabled] : [])].join(' ')}>
              <Statistic label="Minste kosten" value={formatCurrency(lowestValue, locale)} />
              <Statistic label="Gemiddelde kosten" value={formatCurrency(averageTransactionValue, locale)} />
              <Statistic label="Meeste kosten" value={formatCurrency(highestValue, locale)} />
            </div>
          </React.Fragment>
        )
      })()}
    </Widget>
  )
}

FuelCostWidget.displayName = 'FuelCostWidget'
