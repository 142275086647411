import React, { useEffect, useRef } from 'react'

import { Icon } from '../../../../Icon'
import { Typography } from '../../../../Typography'
import { Divider } from '../Divider'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const FileHeader: React.FC<IProps> = ({ icon, fileName }): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)
  const fileNameId = `${fileName}`

  useEffect(() => {
    const textElement = document.getElementById(fileNameId)

    if (!textElement || !fileName) return

    const { scrollWidth, clientWidth } = textElement
    const averageWidthOfCharacter = Math.round(scrollWidth / fileName.length)

    if (scrollWidth <= clientWidth) {
      textElement.innerHTML = fileName
      return
    }

    const maxWidth = Math.round(clientWidth / averageWidthOfCharacter)
    const parts = fileName.match(/(.+)(\..+)/)

    if (!parts) return

    const [name, extension] = parts.slice(1, 3)
    const ellipses = '...'
    const maxLength = maxWidth - extension.length - ellipses.length
    const truncatedName = name.slice(0, maxLength)
    textElement.innerHTML = `${truncatedName}${ellipses}${extension}`
  }, [fileName, fileNameId])

  return (
    <React.Fragment>
      <div ref={ref} className={Styles.wrapper}>
        <Icon data-testid="upload-icon" className={Styles.icon} name={icon} />
        {fileName && (
          <Typography id={fileNameId} data-testid="fileName" className={Styles.fileName} variant="body-2">
            {fileName}
          </Typography>
        )}
      </div>
      <Divider />
    </React.Fragment>
  )
}
