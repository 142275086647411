// @ts-nocheck
/* eslint-disable */
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { IGraphQLContext } from './apolloClientInterfaces';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Base64: { input: string; output: string; }
  BigDecimal: { input: number; output: number; }
  Coordinate: { input: number; output: number; }
  Date: { input: string; output: string; }
  DateTime: { input: string; output: string; }
  JSON: { input: { [key: string]: unknown }; output: { [key: string]: unknown }; }
  ObjMap: { input: any; output: any; }
  UUID: { input: any; output: any; }
}

/** Autogenerated input type of AcceptMobilityEvents */
export interface IAcceptMobilityEventsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityEventIds: Array<Scalars['ID']['input']>;
}

/** Autogenerated return type of AcceptMobilityEvents. */
export interface IAcceptMobilityEventsPayload {
  __typename?: 'AcceptMobilityEventsPayload';
  acceptedMobilityEvents: Array<IMobilityEvent>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  unacceptedMobilityEvents: Array<IMobilityEvent>;
}

export interface IActionRequired extends ITask {
  __typename?: 'ActionRequired';
  /** Humanized value for this task type. */
  humanized: Scalars['String']['output'];
  /** Contains actionable information about the task. */
  message: Scalars['String']['output'];
  /**
   * The path you can use for navigation
   * @deprecated Frontend uses hash params instead of url params, we dont want the backend to keep track of the frontends URLs. Use detailed fields on each task.
   */
  path?: Maybe<Scalars['String']['output']>;
  /** What kind of tasks type we are handling */
  type: ITaskEnum;
}

export interface IActions {
  __typename?: 'Actions';
  /** Whether this mobility event will be unconfirmable after confirming, or unacceptable after accepting */
  canRestoreToInitial: Scalars['Boolean']['output'];
  categoryAndVehicleEditable: Scalars['Boolean']['output'];
  changeCombinedRide: Scalars['Boolean']['output'];
  /** Whether the travel purpose of this mobility event can be changed. */
  changeTravelPurpose: Scalars['Boolean']['output'];
  /** Whether the mobility event can be confirmed */
  confirmable: Scalars['Boolean']['output'];
  copyable: Scalars['Boolean']['output'];
  deletable: Scalars['Boolean']['output'];
  distanceOverridable: Scalars['Boolean']['output'];
  editable: Scalars['Boolean']['output'];
  mergeable: Scalars['Boolean']['output'];
  missingCheckout: Scalars['Boolean']['output'];
  /** @deprecated Submit used to be a synonym for confirm. We now only use confirm, so use confirmable instead */
  submittable: Scalars['Boolean']['output'];
  unacceptable: Scalars['Boolean']['output'];
  unconfirmable: Scalars['Boolean']['output'];
  /** Whether this mobility event can be viewed in detail */
  viewDetails: Scalars['Boolean']['output'];
}

export interface IActivatableDongle extends ITask {
  __typename?: 'ActivatableDongle';
  /** Humanized value for this task type. */
  humanized: Scalars['String']['output'];
  /** Contains actionable information about the task. */
  message: Scalars['String']['output'];
  /**
   * The path you can use for navigation
   * @deprecated Frontend uses hash params instead of url params, we dont want the backend to keep track of the frontends URLs. Use detailed fields on each task.
   */
  path?: Maybe<Scalars['String']['output']>;
  /** What kind of tasks type we are handling */
  type: ITaskEnum;
}

/** Autogenerated input type of ActivateEnecoDongle */
export interface IActivateEnecoDongleInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobideskOwnershipToken: Scalars['Int']['input'];
}

/** Autogenerated return type of ActivateEnecoDongle. */
export interface IActivateEnecoDonglePayload {
  __typename?: 'ActivateEnecoDonglePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  enecoDongle?: Maybe<IEnecoDongle>;
  result: IResult;
}

/** Autogenerated input type of ActivatePersonalMeansOfRegistration */
export interface IActivatePersonalMeansOfRegistrationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
}

/** Autogenerated return type of ActivatePersonalMeansOfRegistration. */
export interface IActivatePersonalMeansOfRegistrationPayload {
  __typename?: 'ActivatePersonalMeansOfRegistrationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  personalMeansOfRegistration?: Maybe<IPersonalMeansOfRegistration>;
  result: IResult;
}

export interface IActiveAdditional extends IAvailableServiceInterface {
  __typename?: 'ActiveAdditional';
  /** When a service is requestable because of rules of the budget group, it can be a required or an optional service. When the service is not requestable by the budget group but on the card when requesting the card, the availability is `null`. */
  budgetGroupAvailability?: Maybe<IAvailableServiceAvailabilityEnum>;
  /** Description of this service */
  description?: Maybe<IDescription>;
  /** Date on which this service is due to be ended (if available) */
  endsOn?: Maybe<IPersonalMeansOfRegistrationServiceEndsOn>;
  /** Icon for the available service, based on the kind */
  icon?: Maybe<IIcon>;
  /** Identity for the service on this card. Can be a username or a unique number (if available) */
  identity?: Maybe<IPersonalMeansOfRegistrationServiceIdentity>;
  /** Kind of service that is available */
  kind: IPersonalMeansOfRegistrationServiceKind;
  /** Mobidesk ownership token for this service */
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  /** Personal Mean of Registration this service belongs to (or will belong to if service has not been requested yet) */
  personalMeansOfRegistration?: Maybe<IPersonalMeansOfRegistration>;
  /** Internal ID used for this service (if available) */
  recordId?: Maybe<Scalars['ID']['output']>;
  /** Whether this service needs a motiviation when requesting it */
  requiresMotivation: Scalars['Boolean']['output'];
  /** Current status of this service */
  status?: Maybe<IPersonalMeansOfRegistrationServiceStatus>;
  /** The terms and conditions document for this service */
  termsAndConditions?: Maybe<ITermsAndConditions>;
}

export interface IActiveGreenwheels extends IAvailableServiceInterface {
  __typename?: 'ActiveGreenwheels';
  /** Whether an employee can activate the greenwheels service */
  activatable: Scalars['Boolean']['output'];
  /** Activation link for Greenwheels */
  activationLink?: Maybe<Scalars['String']['output']>;
  /** When a service is requestable because of rules of the budget group, it can be a required or an optional service. When the service is not requestable by the budget group but on the card when requesting the card, the availability is `null`. */
  budgetGroupAvailability?: Maybe<IAvailableServiceAvailabilityEnum>;
  /** Description of this service */
  description?: Maybe<IDescription>;
  /** List of countries whose driving licenses can be used for Greenwheels */
  driversLicenseCountries: Array<IDriversLicenseCountry>;
  /** Date on which this service is due to be ended (if available) */
  endsOn?: Maybe<IPersonalMeansOfRegistrationServiceEndsOn>;
  /** URL to the Terms and Conditions document of Greenwheels. This is different then the terms_and_conditions document, which always comes from the organization the employee belongs to. */
  greenwheelsTermsAndConditionsUrl: Scalars['String']['output'];
  /** Icon for the available service, based on the kind */
  icon?: Maybe<IIcon>;
  /** Identity for the service on this card. Can be a username or a unique number (if available) */
  identity?: Maybe<IPersonalMeansOfRegistrationServiceIdentity>;
  /** Kind of service that is available */
  kind: IPersonalMeansOfRegistrationServiceKind;
  /** Mobidesk ownership token for this service */
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  /** Personal Mean of Registration this service belongs to (or will belong to if service has not been requested yet) */
  personalMeansOfRegistration?: Maybe<IPersonalMeansOfRegistration>;
  /** Internal ID used for this service (if available) */
  recordId?: Maybe<Scalars['ID']['output']>;
  /** Whether an employee can register for greenwheels */
  registerable: Scalars['Boolean']['output'];
  /** Whether this service needs a motiviation when requesting it */
  requiresMotivation: Scalars['Boolean']['output'];
  /** Current status of this service */
  status?: Maybe<IPersonalMeansOfRegistrationServiceStatus>;
  /** The terms and conditions document for this service */
  termsAndConditions?: Maybe<ITermsAndConditions>;
  urls?: Maybe<IAppUrls>;
}

export interface IActiveSubscription extends IAvailableServiceInterface {
  __typename?: 'ActiveSubscription';
  /** When a service is requestable because of rules of the budget group, it can be a required or an optional service. When the service is not requestable by the budget group but on the card when requesting the card, the availability is `null`. */
  budgetGroupAvailability?: Maybe<IAvailableServiceAvailabilityEnum>;
  /** Description of this service */
  description?: Maybe<IDescription>;
  /** Date on which this service is due to be ended (if available) */
  endsOn?: Maybe<IPersonalMeansOfRegistrationServiceEndsOn>;
  /** Icon for the available service, based on the kind */
  icon?: Maybe<IIcon>;
  /** Identity for the service on this card. Can be a username or a unique number (if available) */
  identity?: Maybe<IPersonalMeansOfRegistrationServiceIdentity>;
  /** Kind of service that is available */
  kind: IPersonalMeansOfRegistrationServiceKind;
  /** Mobidesk ownership token for this service */
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  /** Personal Mean of Registration this service belongs to (or will belong to if service has not been requested yet) */
  personalMeansOfRegistration?: Maybe<IPersonalMeansOfRegistration>;
  /** Internal ID used for this service (if available) */
  recordId?: Maybe<Scalars['ID']['output']>;
  /** Whether this service needs a motiviation when requesting it */
  requiresMotivation: Scalars['Boolean']['output'];
  /** Star value, relevant for bus transport. Only present for specific subscriptions. */
  starValue?: Maybe<Scalars['String']['output']>;
  /** Current status of this service */
  status?: Maybe<IPersonalMeansOfRegistrationServiceStatus>;
  /** The terms and conditions document for this service */
  termsAndConditions?: Maybe<ITermsAndConditions>;
  /** Train class for this subscription. Only present for subscriptions that have something to do with trains. */
  trainClass?: Maybe<ITrainClass>;
  /** Grouped fields that are about train class change */
  trainClassChange: ITrainClassChange;
  /** Specific traject you can travel on. Most of the times the names of two cities. Only present for specific subscriptions. */
  traject?: Maybe<ITraject>;
  /** Zone for this subscription, represented as four digits. Only present for specific subscriptions. */
  zone?: Maybe<Scalars['String']['output']>;
}

export interface IActiveYellowbrickSp extends IAvailableServiceInterface {
  __typename?: 'ActiveYellowbrickSp';
  /** When a service is requestable because of rules of the budget group, it can be a required or an optional service. When the service is not requestable by the budget group but on the card when requesting the card, the availability is `null`. */
  budgetGroupAvailability?: Maybe<IAvailableServiceAvailabilityEnum>;
  /** Description of this service */
  description?: Maybe<IDescription>;
  /** Date on which this service is due to be ended (if available) */
  endsOn?: Maybe<IPersonalMeansOfRegistrationServiceEndsOn>;
  /** Icon for the available service, based on the kind */
  icon?: Maybe<IIcon>;
  /** Identity for the service on this card. Can be a username or a unique number (if available) */
  identity?: Maybe<IPersonalMeansOfRegistrationServiceIdentity>;
  /** Kind of service that is available */
  kind: IPersonalMeansOfRegistrationServiceKind;
  /** Mobidesk ownership token for this service */
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  /** Personal Mean of Registration this service belongs to (or will belong to if service has not been requested yet) */
  personalMeansOfRegistration?: Maybe<IPersonalMeansOfRegistration>;
  /** Internal ID used for this service (if available) */
  recordId?: Maybe<Scalars['ID']['output']>;
  /** Whether this service needs a motiviation when requesting it */
  requiresMotivation: Scalars['Boolean']['output'];
  /** Current status of this service */
  status?: Maybe<IPersonalMeansOfRegistrationServiceStatus>;
  /** The terms and conditions document for this service */
  termsAndConditions?: Maybe<ITermsAndConditions>;
  /** Yellowbrick uesrname */
  username?: Maybe<Scalars['String']['output']>;
}

/** Autogenerated input type of AddPushNotificationToken */
export interface IAddPushNotificationTokenInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
}

/** Autogenerated return type of AddPushNotificationToken. */
export interface IAddPushNotificationTokenPayload {
  __typename?: 'AddPushNotificationTokenPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
}

/** Autogenerated input type of AddToWhitelist */
export interface IAddToWhitelistInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Reisbalans mobility card id */
  mobilityCardId: Scalars['ID']['input'];
}

/** Autogenerated return type of AddToWhitelist. */
export interface IAddToWhitelistPayload {
  __typename?: 'AddToWhitelistPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  studentTravelRequestDates: IRequestDates;
  validationErrors: Array<IValidationError>;
}

export interface IAddableVehiclePermission {
  __typename?: 'AddableVehiclePermission';
  /** Allowed fuel types for this vehicle permission */
  allowedFuelTypes: Array<IFuel>;
  /** Icon for this modality permission */
  icon: IIcon;
  /** Vehicle modality information for this permission */
  name: IVehicleModalityKind;
}

export interface IAddress {
  __typename?: 'Address';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  latitude: Scalars['Float']['output'];
  location: Scalars['String']['output'];
  longitude: Scalars['Float']['output'];
  type?: Maybe<ITypeEnum>;
}

/** Autogenerated input type of AdminLogin */
export interface IAdminLoginInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Email of the account */
  email: Scalars['String']['input'];
  /** One Time Password of the account (2fa code) */
  otp?: InputMaybe<Scalars['String']['input']>;
  /** Password of the account */
  password: Scalars['String']['input'];
}

/** Autogenerated return type of AdminLogin. */
export interface IAdminLoginPayload {
  __typename?: 'AdminLoginPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The JWT Token that can be used as authentication */
  jwt?: Maybe<Scalars['String']['output']>;
  result: IResult;
  tokens?: Maybe<IAuth>;
}

export interface IAmber extends IAvailableFeatureInterface {
  __typename?: 'Amber';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  /**
   * This is only available when the request for this feature has been approved and the employee is allowed to order this feature
   * @deprecated This URL is not valid as we want to redirect users to the mobile app, not the website. Dont use this field anymore.
   */
  url?: Maybe<Scalars['String']['output']>;
  urls?: Maybe<IFeatureUrls>;
}

export interface IAppUrls {
  __typename?: 'AppUrls';
  /** Android app URL */
  android: Scalars['String']['output'];
  /** iOs app URL */
  ios: Scalars['String']['output'];
}

export interface IAssetAvailability {
  __typename?: 'AssetAvailability';
  /** Number of available bikes at this Station */
  bikes: Scalars['Int']['output'];
  /** Number of available bike drop-off points at this Station */
  dropoff: Scalars['Int']['output'];
  /** Number of available e-bikes at this Station */
  ebikes: Scalars['Int']['output'];
}

export interface IAssetLocation {
  __typename?: 'AssetLocation';
  /** Country code for this City */
  countryCode: Scalars['String']['output'];
  geoLocation: ICoordinates;
  /** This City's identifier */
  identifier: Scalars['String']['output'];
  /** Human readable name for this City */
  name: Scalars['String']['output'];
  /** A list of Drop-off & pickup locations within this City */
  stations: Array<IAssetStation>;
}

export interface IAssetStation {
  __typename?: 'AssetStation';
  availability?: Maybe<IAssetAvailability>;
  geoLocation?: Maybe<ICoordinates>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
}

/** Autogenerated input type of AssignReplacementManager */
export interface IAssignReplacementManagerInput {
  /** The end of the approval period for this replacement manager */
  approvalPeriodEnd?: InputMaybe<Scalars['Date']['input']>;
  /** The start of the approval period for this replacement manager */
  approvalPeriodStart: Scalars['Date']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The email of the employee that is to be assigned as a replacement manager */
  email: Scalars['String']['input'];
  /** If this ID is given, update the replacement manager. Otherwise it is a create mutation. */
  replacementManagerId?: InputMaybe<Scalars['ID']['input']>;
}

/** Autogenerated return type of AssignReplacementManager. */
export interface IAssignReplacementManagerPayload {
  __typename?: 'AssignReplacementManagerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  replacementManagerId?: Maybe<Scalars['ID']['output']>;
  result: IResult;
}

export interface IAttachment {
  data: Scalars['Base64']['input'];
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
}

export interface IAuth {
  __typename?: 'Auth';
  /** Access token that can be used as authentication */
  accessToken?: Maybe<Scalars['String']['output']>;
  /** Refresh token that can be used to get new access token when expired */
  refreshToken?: Maybe<Scalars['String']['output']>;
}

export interface IAvailableFeatureInterface {
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export enum IAvailableServiceAvailabilityEnum {
  DISABLED = 'DISABLED',
  OPTIONAL = 'OPTIONAL',
  REQUIRED = 'REQUIRED'
}

export interface IAvailableServiceInterface {
  /** When a service is requestable because of rules of the budget group, it can be a required or an optional service. When the service is not requestable by the budget group but on the card when requesting the card, the availability is `null`. */
  budgetGroupAvailability?: Maybe<IAvailableServiceAvailabilityEnum>;
  /** Description of this service */
  description?: Maybe<IDescription>;
  /** Date on which this service is due to be ended (if available) */
  endsOn?: Maybe<IPersonalMeansOfRegistrationServiceEndsOn>;
  /** Icon for the available service, based on the kind */
  icon?: Maybe<IIcon>;
  /** Identity for the service on this card. Can be a username or a unique number (if available) */
  identity?: Maybe<IPersonalMeansOfRegistrationServiceIdentity>;
  /** Kind of service that is available */
  kind: IPersonalMeansOfRegistrationServiceKind;
  /** Mobidesk ownership token for this service */
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  /** Personal Mean of Registration this service belongs to (or will belong to if service has not been requested yet) */
  personalMeansOfRegistration?: Maybe<IPersonalMeansOfRegistration>;
  /** Internal ID used for this service (if available) */
  recordId?: Maybe<Scalars['ID']['output']>;
  /** Whether this service needs a motiviation when requesting it */
  requiresMotivation: Scalars['Boolean']['output'];
  /** Current status of this service */
  status?: Maybe<IPersonalMeansOfRegistrationServiceStatus>;
  /** The terms and conditions document for this service */
  termsAndConditions?: Maybe<ITermsAndConditions>;
}

export interface IAvailableSharedCar {
  __typename?: 'AvailableSharedCar';
  endsAt: Scalars['DateTime']['output'];
  fromLocation: IGeoLocation;
  id: Scalars['ID']['output'];
  startsAt: Scalars['DateTime']['output'];
  vehicleName?: Maybe<Scalars['String']['output']>;
}

export interface IAvailableSharedVehicle {
  __typename?: 'AvailableSharedVehicle';
  endsAt: Scalars['DateTime']['output'];
  fromLocation: IGeoLocation;
  icon: IIcon;
  id: Scalars['ID']['output'];
  modality: IModality;
  startsAt: Scalars['DateTime']['output'];
  vehicleName?: Maybe<Scalars['String']['output']>;
}

export interface IAvailableSubscription {
  __typename?: 'AvailableSubscription';
  hasTraject: Scalars['Boolean']['output'];
  humanized: Scalars['String']['output'];
  /** Humanized values for the zones */
  humanizedZones?: Maybe<Array<IZone>>;
  requiresMotivation?: Maybe<Scalars['Boolean']['output']>;
  starValues: Array<IStarValue>;
  trainClassOptions?: Maybe<ITrainClassOptions>;
  value: ISubscriptionEnum;
  /** @deprecated Deprecated due to introduction of humanized_zones */
  zones: Array<Scalars['String']['output']>;
}

export interface IBalance {
  __typename?: 'Balance';
  totalFiscalAmount?: Maybe<Scalars['Float']['output']>;
  totalGrossAmount?: Maybe<Scalars['Float']['output']>;
  totalNetAmount?: Maybe<Scalars['Float']['output']>;
}

export interface IBikeAccess {
  __typename?: 'BikeAccess';
  tokenData?: Maybe<IEkeyLock>;
  tokenType?: Maybe<Scalars['String']['output']>;
  validFrom?: Maybe<Scalars['DateTime']['output']>;
  validUntil?: Maybe<Scalars['DateTime']['output']>;
}

export interface IBranding {
  __typename?: 'Branding';
  humanized: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

export interface IBudget {
  __typename?: 'Budget';
  /** The humanized values reflecting the valueInCents */
  amount: IMoneyTransfer;
  /** A label to describe what kind of budget this is */
  label: Scalars['String']['output'];
  /**
   * The exact amount as float, can be either positive or negative
   * @deprecated Use valueInCents instead, its more reliable when doing things like calculations
   */
  value: Scalars['Float']['output'];
  /** The exact amount in cents, can be either positive or negative */
  valueInCents: Scalars['Int']['output'];
}

export interface IBudgetGroup {
  __typename?: 'BudgetGroup';
  id: Scalars['ID']['output'];
  reimbursementRules: Array<IReimbursementRule>;
}

export interface ICalculatedDistance {
  __typename?: 'CalculatedDistance';
  humanized: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

/** Autogenerated input type of CancelDonkeyRepublicReservation */
export interface ICancelDonkeyRepublicReservationInput {
  bookingId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of CancelDonkeyRepublicReservation. */
export interface ICancelDonkeyRepublicReservationPayload {
  __typename?: 'CancelDonkeyRepublicReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  donkeyRepublicReservation?: Maybe<IDonkeyRepublicReservation>;
  donkeyRepublicResult?: Maybe<IDonkeyRepublicResult>;
  result: IDonkeyRepublicResult;
}

/** Autogenerated input type of CancelSharedCarReservation */
export interface ICancelSharedCarReservationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
}

/** Autogenerated return type of CancelSharedCarReservation. */
export interface ICancelSharedCarReservationPayload {
  __typename?: 'CancelSharedCarReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  sharedCarReservation: ISharedCarReservation;
}

/** Autogenerated input type of CancelSharedVehicleReservation */
export interface ICancelSharedVehicleReservationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
}

/** Autogenerated return type of CancelSharedVehicleReservation. */
export interface ICancelSharedVehicleReservationPayload {
  __typename?: 'CancelSharedVehicleReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  sharedVehicleReservation: ISharedVehicleReservation;
}

/** Autogenerated input type of CancelTaxiReservation */
export interface ICancelTaxiReservationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  taxiReservationId: Scalars['ID']['input'];
}

/** Autogenerated return type of CancelTaxiReservation. */
export interface ICancelTaxiReservationPayload {
  __typename?: 'CancelTaxiReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  taxiReservation: ITaxiReservation;
}

export interface ICategory {
  __typename?: 'Category';
  id: Scalars['ID']['output'];
  main: Scalars['String']['output'];
  mainCategory: IMainCategory;
  /**
   * The reimbursable category method. Ride, Route or Both
   * @deprecated Use whatOptions reimbursable_category_method
   */
  reimbursableCategoryMethod?: Maybe<IReimbursableCategoryMethod>;
  requiresModality: Scalars['Boolean']['output'];
  sub?: Maybe<Scalars['String']['output']>;
  subCategory?: Maybe<ISubCategory>;
}

export interface ICo2Emission {
  __typename?: 'Co2Emission';
  humanized: Scalars['String']['output'];
  value: Scalars['Int']['output'];
}

export interface ICo2EmissionPerModality {
  __typename?: 'Co2EmissionPerModality';
  emission: ICo2Emission;
  modality?: Maybe<IModality>;
}

export interface ICo2EmissionSummary {
  __typename?: 'Co2EmissionSummary';
  mobilityEventsPerModality?: Maybe<Array<ICo2EmissionPerModality>>;
  totalEmission: ICo2Emission;
}

export interface IColor {
  __typename?: 'Color';
  /** The color in html HEX value */
  hex?: Maybe<Scalars['String']['output']>;
  /** The color in Hue, Saturation, Lightness and Alpha values */
  hsla?: Maybe<IHsla>;
  /** The color in Red, Green, Blue and Alpha values */
  rgba?: Maybe<IRgba>;
}

export interface ICompensationRideToBeCreated extends ITask {
  __typename?: 'CompensationRideToBeCreated';
  /** Humanized value for this task type. */
  humanized: Scalars['String']['output'];
  incompleteRideId?: Maybe<Scalars['ID']['output']>;
  /** Contains actionable information about the task. */
  message: Scalars['String']['output'];
  /**
   * The path you can use for navigation
   * @deprecated Frontend uses hash params instead of url params, we dont want the backend to keep track of the frontends URLs. Use detailed fields on each task.
   */
  path?: Maybe<Scalars['String']['output']>;
  refundId?: Maybe<Scalars['ID']['output']>;
  /** What kind of tasks type we are handling */
  type: ITaskEnum;
}

export interface IConfiguration {
  __typename?: 'Configuration';
  grossLimit?: Maybe<Scalars['Float']['output']>;
  grossPercentage?: Maybe<Scalars['Int']['output']>;
  limitFrom?: Maybe<Scalars['Float']['output']>;
  limitTo?: Maybe<Scalars['Float']['output']>;
  limitToDefaultCommute?: Maybe<Scalars['Boolean']['output']>;
  netLimit?: Maybe<Scalars['Float']['output']>;
  netPercentage?: Maybe<Scalars['Int']['output']>;
  offsets?: Maybe<Array<Array<Scalars['Float']['output']>>>;
  threshold?: Maybe<Scalars['Float']['output']>;
}

export interface IConfigurationProfileForHost {
  __typename?: 'ConfigurationProfileForHost';
  /** The customer_label settings for this host */
  customerLabelTheme?: Maybe<ICustomerLabelTheme>;
  /** Customer service details */
  customerService?: Maybe<ICustomerService>;
  /** Whether or not this host uses SSO to login */
  ssoUrl?: Maybe<Scalars['String']['output']>;
  /** The theme settings for this host */
  theme?: Maybe<ITheme>;
}

/** Autogenerated input type of ConfigureMfa */
export interface IConfigureMfaInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Email of the account */
  email: Scalars['String']['input'];
  /** Password of the account */
  password: Scalars['String']['input'];
  /** Validation of the otp secret */
  validateOtpSecret?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of ConfigureMfa. */
export interface IConfigureMfaPayload {
  __typename?: 'ConfigureMfaPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** True when the MFA is correctly configured */
  configurationSuccess?: Maybe<Scalars['Boolean']['output']>;
  /** Base64 encoded otp uri as input for a QR code */
  otpUri?: Maybe<Scalars['String']['output']>;
  result: IResult;
}

/** Autogenerated input type of ConfirmGreenwheels */
export interface IConfirmGreenwheelsInput {
  /** Whether organizational terms and conditions are accepted */
  acceptOrganizationTerms?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether Greenwheels terms and conditions are accepted */
  acceptTerms: Scalars['Boolean']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Country that issues the drivers license */
  driversLicenseCountry: IDriversLicenseCountryEnum;
  /** Number of drivers license */
  driversLicenseNumber: Scalars['String']['input'];
  /** This is only required when the Greenwheels service is confirmed, for the feature it is optional */
  mobilityCardId?: InputMaybe<Scalars['ID']['input']>;
  /** Phone number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of ConfirmGreenwheels. */
export interface IConfirmGreenwheelsPayload {
  __typename?: 'ConfirmGreenwheelsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  /** List of validation errors */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of Confirm */
export interface IConfirmInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityCardOrderId: Scalars['ID']['input'];
}

/** Autogenerated input type of ConfirmMobilityEvent */
export interface IConfirmMobilityEventInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
}

/** Autogenerated return type of ConfirmMobilityEvent. */
export interface IConfirmMobilityEventPayload {
  __typename?: 'ConfirmMobilityEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  day: IDayWithMobilityEvents;
  mobilityEvent?: Maybe<IMobilityEvent>;
  result: IResult;
}

/** Autogenerated input type of ConfirmMobilityEvents */
export interface IConfirmMobilityEventsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityEventIds: Array<Scalars['ID']['input']>;
}

/** Autogenerated return type of ConfirmMobilityEvents. */
export interface IConfirmMobilityEventsPayload {
  __typename?: 'ConfirmMobilityEventsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  confirmedMobilityEvents: Array<IMobilityEvent>;
  days: Array<IDayWithMobilityEvents>;
  result: IResult;
  unconfirmedMobilityEvents: Array<IMobilityEvent>;
}

/** Autogenerated return type of Confirm. */
export interface IConfirmPayload {
  __typename?: 'ConfirmPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The confirmed mobility card order */
  mobilityCardOrder?: Maybe<IMobilityCardOrder>;
  /** Result of confirming the mobility card order */
  result: IResult;
}

/** Autogenerated input type of ConfirmSharedCarReservation */
export interface IConfirmSharedCarReservationInput {
  bookingId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of ConfirmSharedCarReservation. */
export interface IConfirmSharedCarReservationPayload {
  __typename?: 'ConfirmSharedCarReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  /** @deprecated Use sharedVehicleReservation */
  sharedCarReservation?: Maybe<ISharedCarReservation>;
}

/** Autogenerated input type of ConfirmSharedVehicleReservation */
export interface IConfirmSharedVehicleReservationInput {
  bookingId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of ConfirmSharedVehicleReservation. */
export interface IConfirmSharedVehicleReservationPayload {
  __typename?: 'ConfirmSharedVehicleReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  sharedVehicleReservation?: Maybe<ISharedVehicleReservation>;
}

/** Autogenerated input type of ConfirmTaxiReservation */
export interface IConfirmTaxiReservationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  taxiReservationId: Scalars['ID']['input'];
}

/** Autogenerated return type of ConfirmTaxiReservation. */
export interface IConfirmTaxiReservationPayload {
  __typename?: 'ConfirmTaxiReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  taxiReservation: ITaxiReservation;
}

export interface IContractActions {
  __typename?: 'ContractActions';
  revocable: Scalars['Boolean']['output'];
}

export enum IContractPaymentServiceProviderEnum {
  TWIKEY = 'TWIKEY'
}

export interface IContractStatus {
  __typename?: 'ContractStatus';
  humanized: Scalars['String']['output'];
  value: IContractStatusEnum;
}

export enum IContractStatusEnum {
  ACTIVE = 'ACTIVE',
  ASSIGNED = 'ASSIGNED',
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR',
  INVITED = 'INVITED',
  NEW = 'NEW',
  SUSPENDED = 'SUSPENDED'
}

export interface ICoordinates {
  __typename?: 'Coordinates';
  latitude: Scalars['Coordinate']['output'];
  longitude: Scalars['Coordinate']['output'];
}

export interface ICoordinatesMutation {
  latitude: Scalars['Coordinate']['input'];
  longitude: Scalars['Coordinate']['input'];
}

/** Autogenerated input type of CopyMobilityEventsForDay */
export interface ICopyMobilityEventsForDayInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Which date to copy */
  from: Scalars['Date']['input'];
  /** Dates to copy the manual mobility events on the given from date to */
  to: Array<Scalars['Date']['input']>;
}

/** Autogenerated return type of CopyMobilityEventsForDay. */
export interface ICopyMobilityEventsForDayPayload {
  __typename?: 'CopyMobilityEventsForDayPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  days: Array<IDay>;
  result: IResult;
}

/** Autogenerated input type of CopyMobilityEvents */
export interface ICopyMobilityEventsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['DateTime']['input'];
  mobilityEventIds: Array<Scalars['ID']['input']>;
}

/** Autogenerated return type of CopyMobilityEvents. */
export interface ICopyMobilityEventsPayload {
  __typename?: 'CopyMobilityEventsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  days: Array<IDayWithMobilityEvents>;
  result: IResult;
}

export interface ICost extends IReimbursementRule {
  __typename?: 'Cost';
  budgetGroupId: Scalars['ID']['output'];
  category: ICategory;
  configuration: IConfiguration;
  fiscality: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  main: IMainCategory;
  modality?: Maybe<IModality>;
  reimbursableCategory: IReimbursableCategory;
  sub?: Maybe<ISubCategory>;
  travelPurpose: Scalars['String']['output'];
}

export interface ICountryIsoCode {
  __typename?: 'CountryIsoCode';
  humanized: Scalars['String']['output'];
  value: ICountryIsoCodeEnum;
}

export enum ICountryIsoCodeEnum {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW'
}

/** Autogenerated input type of CreateCommuteCompensation */
export interface ICreateCommuteCompensationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not to confirm right away */
  confirm?: InputMaybe<Scalars['Boolean']['input']>;
  /** Date to create the commute compensations for. */
  date?: InputMaybe<Scalars['Date']['input']>;
  /** Dates to create the commute compensations for. */
  dates?: InputMaybe<Array<Scalars['Date']['input']>>;
}

/** Autogenerated return type of CreateCommuteCompensation. */
export interface ICreateCommuteCompensationPayload {
  __typename?: 'CreateCommuteCompensationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** List of all confirmed commute compensations. */
  confirmedMobilityEvents?: Maybe<Array<IMobilityEvent>>;
  dayWithMobilityEvents: IDayWithMobilityEvents;
  /** returns mobility events for all dates if given mutiple dates */
  daysWithMobilityEvents: Array<IDayWithMobilityEvents>;
  mobilityEvents: Array<IMobilityEvent>;
  result: IResult;
  /** List of all unconfirmed commute compensations. */
  unconfirmedMobilityEvents?: Maybe<Array<IMobilityEvent>>;
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of CreateCommuteRides */
export interface ICreateCommuteRidesInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not to confirm right away */
  confirm?: InputMaybe<Scalars['Boolean']['input']>;
  /** Date to create the commute rides for. */
  date?: InputMaybe<Scalars['Date']['input']>;
  /** Dates to create the commute rides for. */
  dates?: InputMaybe<Array<Scalars['Date']['input']>>;
}

/** Autogenerated return type of CreateCommuteRides. */
export interface ICreateCommuteRidesPayload {
  __typename?: 'CreateCommuteRidesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** List of all confirmed commute rides. A Commute ride can be confirmed by passing confirm: true. */
  confirmedMobilityEvents?: Maybe<Array<IMobilityEvent>>;
  dayWithMobilityEvents: IDayWithMobilityEvents;
  /** returns mobility events for all dates if given mutiple dates */
  daysWithMobilityEvents: Array<IDayWithMobilityEvents>;
  /** Favorite with a fallback location that cause the mutation to fail */
  favoriteWithFallback?: Maybe<IFavorite>;
  mobilityEvents: Array<IMobilityEvent>;
  result: IResult;
  /** List of all unconfirmed commute rides. A Commute ride can be confirmed by passing confirm: true. */
  unconfirmedMobilityEvents?: Maybe<Array<IMobilityEvent>>;
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of CreateCompensationMobilityEvent */
export interface ICreateCompensationMobilityEventInput {
  /** DEPRECATED. The backend has taken over the responsibility and this field will be faced out in the future. */
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  explanation?: InputMaybe<Scalars['String']['input']>;
  incompleteRideId: Scalars['Int']['input'];
  /** DEPRECATED. The backend has taken over the responsibility and this field will be faced out in the future. */
  modalityId?: InputMaybe<Scalars['ID']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  refundId: Scalars['Int']['input'];
  /** DEPRECATED. The backend has taken over the responsibility and this field will be faced out in the future. */
  travelPurpose?: InputMaybe<ITravelPurposeEnum>;
  /** DEPRECATED. The backend has taken over the responsibility and this field will be faced out in the future. */
  vehicleId?: InputMaybe<Scalars['ID']['input']>;
}

/** Autogenerated return type of CreateCompensationMobilityEvent. */
export interface ICreateCompensationMobilityEventPayload {
  __typename?: 'CreateCompensationMobilityEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Created mobility event, if multiple mobility events are created it contains the first created mobility event */
  mobilityEvent?: Maybe<IMobilityEvent>;
  /** Result of creating a mobility event */
  result: IResult;
  /** List of validation errors while creating mobility events */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of CreateDirectDebitMandate */
export interface ICreateDirectDebitMandateInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of CreateDirectDebitMandate. */
export interface ICreateDirectDebitMandatePayload {
  __typename?: 'CreateDirectDebitMandatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** If everything was ok, this contains your new direct debit mandate */
  directDebitMandate?: Maybe<IDirectDebitMandate>;
  /** Whether the request was a success or not */
  result: IResult;
}

/** Autogenerated input type of CreateDonkeyRepublicReservation */
export interface ICreateDonkeyRepublicReservationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  quoteId: Scalars['ID']['input'];
}

/** Autogenerated return type of CreateDonkeyRepublicReservation. */
export interface ICreateDonkeyRepublicReservationPayload {
  __typename?: 'CreateDonkeyRepublicReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  donkeyRepublicReservation?: Maybe<IDonkeyRepublicReservation>;
  donkeyRepublicResult?: Maybe<IDonkeyRepublicResult>;
  result: IDonkeyRepublicResult;
}

/** Autogenerated input type of CreateFavorite */
export interface ICreateFavoriteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** A human friendly name to recognize this favorite, for example 'Reisbalans'. */
  label: Scalars['String']['input'];
  /** The latitude of the location. */
  latitude: Scalars['Coordinate']['input'];
  /** The fill address information as string, for example 'Utrechtseweg 9, 3811NA Amersfoort'. */
  location: Scalars['String']['input'];
  /** An enriched location object with complete address address information from Google Places. This being optional is for backwards compatibility, we really want to make it required. */
  locationId?: InputMaybe<Scalars['ID']['input']>;
  /** The longitude of the location. */
  longitude: Scalars['Coordinate']['input'];
  /** If this favorite should belong to an organization, then pass along its id here. (Only admins are allowed to set this) */
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  /** The type of favorite this is. */
  type?: InputMaybe<IFavoriteTypeEnum>;
}

/** Autogenerated return type of CreateFavorite. */
export interface ICreateFavoritePayload {
  __typename?: 'CreateFavoritePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** If everything was ok, this contains your new favorite record */
  favorite?: Maybe<IFavorite>;
  /** Whether the request was a success or not */
  result: IResult;
  /** If something was not ok, you can find the reason here */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of CreateLeaseBikeOrder */
export interface ICreateLeaseBikeOrderInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Duration of the lease contract in months */
  durationInMonths?: InputMaybe<Scalars['Int']['input']>;
  /** Key that identifies this order in Reisbalans */
  employeeKey: Scalars['String']['input'];
  /** Start date of the lease contract */
  endDate?: InputMaybe<Scalars['Date']['input']>;
  /** Lease price in cents */
  leasePriceInCents?: InputMaybe<Scalars['Int']['input']>;
  /** Order key that identifies this order */
  orderKey: Scalars['String']['input'];
  /** Description of the product in the lease contract */
  productDescription?: InputMaybe<Scalars['String']['input']>;
  /** Image of the product in the lease contract */
  productImage?: InputMaybe<IAttachment>;
  /** Name of the product in the lease contract */
  productName?: InputMaybe<Scalars['String']['input']>;
  /** Start date of the lease contract */
  startDate?: InputMaybe<Scalars['Date']['input']>;
  /** Status of the order */
  status?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of CreateLeaseBikeOrder. */
export interface ICreateLeaseBikeOrderPayload {
  __typename?: 'CreateLeaseBikeOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of CreateMileage */
export interface ICreateMileageInput {
  amountInKilometers: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  licensePlate: Scalars['String']['input'];
  recordedAt: Scalars['DateTime']['input'];
}

/** Autogenerated return type of CreateMileage. */
export interface ICreateMileagePayload {
  __typename?: 'CreateMileagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Added mileage */
  mileage?: Maybe<IMileage>;
  /** Result of adding a mileage */
  result: IResult;
  /** List of validation errors while adding a mileage */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of CreateMobilityCardOrder */
export interface ICreateMobilityCardOrderInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of CreateMobilityCardOrder. */
export interface ICreateMobilityCardOrderPayload {
  __typename?: 'CreateMobilityCardOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Created mobility card order */
  mobilityCardOrder?: Maybe<IMobilityCardOrder>;
  /** Result of creating a mobility card order */
  result: IResult;
}

/** Autogenerated input type of CreateMobilityEvent */
export interface ICreateMobilityEventInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not to confirm the mobility events right away */
  confirm?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether a retour ride should be created */
  createRetourRide?: InputMaybe<Scalars['Boolean']['input']>;
  /** The specific employment id you want to link this mobility event to */
  employmentId?: InputMaybe<Scalars['ID']['input']>;
  financial?: InputMaybe<IFinancialMutation>;
  fiscality?: InputMaybe<IFiscalityEnum>;
  happenedAt: Scalars['DateTime']['input'];
  /** Whether or not to recalculate the consumption */
  recalculate?: InputMaybe<Scalars['Boolean']['input']>;
  route?: InputMaybe<IRouteMutation>;
  routes?: InputMaybe<Array<IRouteMutation>>;
  /** DEPRECATED. Submit used to be a synonym for confirm, use confirm instead */
  submit?: InputMaybe<Scalars['Boolean']['input']>;
  what?: InputMaybe<ICreateWhatMutation>;
  why: IWhyMutation;
}

/** Autogenerated return type of CreateMobilityEvent. */
export interface ICreateMobilityEventPayload {
  __typename?: 'CreateMobilityEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** List of all confirmed mobility events. A mobility event can be confirmed by passing confirm: true when creating the mobility event. Confirming happens apart from creating the mobility event, meaning it can fail. All successfully confirmed mobility event is in this field. */
  confirmedMobilityEvents?: Maybe<Array<IMobilityEvent>>;
  /** Created mobility event, if multiple mobility events are created it contains the first created mobility event */
  mobilityEvent?: Maybe<IMobilityEvent>;
  /** List of all created mobility events */
  mobilityEvents?: Maybe<Array<IMobilityEvent>>;
  /** Result of creating a mobility event */
  result: IResult;
  /** List of all unconfirmed mobility events. A mobility event can be confirmed by passing confirm: true when creating the mobility event. Confirming happens apart from creating the mobility event, meaning it can fail. When a confirm should happen but didnt, the mobility event is in this field. */
  unconfirmedMobilityEvents?: Maybe<Array<IMobilityEvent>>;
  /** List of validation errors while creating mobility events */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of CreateParkingOrder */
export interface ICreateParkingOrderInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Motivation for your parking order */
  motivation?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of CreateParkingOrder. */
export interface ICreateParkingOrderPayload {
  __typename?: 'CreateParkingOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  parkingOrder?: Maybe<IParkingOrder>;
  result: IResult;
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of CreateTaxiReservation */
export interface ICreateTaxiReservationInput {
  /** The desired time at which the taxi should be waiting for you. */
  at: Scalars['DateTime']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The phone number that the driver can reach you on. */
  contactPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** The departure location. */
  departure: ILocationMutation;
  /** The destination location. */
  destination: ILocationMutation;
  /** The amount of passengers making use of the taxi. */
  passengers: Scalars['Int']['input'];
  /** Any remarks that you would like to pass on to the taxi driver. */
  remarks?: InputMaybe<Scalars['String']['input']>;
  /** The desired type of taxi you would like to order. */
  vehicleType?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of CreateTaxiReservation. */
export interface ICreateTaxiReservationPayload {
  __typename?: 'CreateTaxiReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  taxiReservation?: Maybe<ITaxiReservation>;
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of CreateTranzerReservation */
export interface ICreateTranzerReservationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  quoteId: Scalars['ID']['input'];
  /** List of travelers for this booking. Can either be a contactId which should have all the required traveler information, or a list of specific data. */
  travelers: Array<ITravelerMutation>;
}

/** Autogenerated return type of CreateTranzerReservation. */
export interface ICreateTranzerReservationPayload {
  __typename?: 'CreateTranzerReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  tranzerReservation?: Maybe<ITranzerReservation>;
}

/** Autogenerated input type of CreateUserProfile */
export interface ICreateUserProfileInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Date of birth of the user */
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  /** Preferred purpose of supplier transactions */
  defaultMobilityCardTransactionTravelPurpose?: InputMaybe<ITravelPurposeEnum>;
  /** Preferred purpose of supplier transactions on weekends */
  defaultMobilityCardWeekendTransactionTravelPurpose?: InputMaybe<ITravelPurposeEnum>;
  /** Email address of the user. Required for initial authentication together with inviteCode. If using the invite code, email is required */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Employee number of the user, that is mostly used internally. */
  employeeNumber?: InputMaybe<Scalars['String']['input']>;
  /** First name of the user */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Home address of the user */
  homeAddress?: InputMaybe<IUserProfileAddress>;
  /** Infix of the user */
  infix?: InputMaybe<Scalars['String']['input']>;
  /** Initials of the user */
  initials?: InputMaybe<Scalars['String']['input']>;
  /** Invite code of the user. Required for authentication for Freddi. Required for initial authentication together with email. */
  inviteCode?: InputMaybe<Scalars['String']['input']>;
  /** Invite token of the user. Required for authentication generated by Devise. Required for initial authentication no email required when using a token. */
  inviteToken?: InputMaybe<Scalars['String']['input']>;
  /** Last name of the user */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** New password of the user */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Confirmation of new password of the user. */
  passwordConfirmation?: InputMaybe<Scalars['String']['input']>;
  /** Phone number of the user. Accepted formats with fake phone numbers: +31612345678 (NL), +3214812345 (BE), +4912345678 (DE), 0612345678, 06-12345678, 0401234567, 040-1234567, 0455123456, 0455-123456, all other European numbers. See employee.rb for specific regex. */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** User-preferred purpose of GPS registered rides */
  preferredTravelPurpose?: InputMaybe<ITravelPurposeEnum>;
  /** Agreed Terms and conditions */
  termsAndConditionsDocumentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Work address of the user */
  workAddress?: InputMaybe<IUserProfileAddress>;
}

/** Autogenerated return type of CreateUserProfile. */
export interface ICreateUserProfilePayload {
  __typename?: 'CreateUserProfilePayload';
  /** Api-Token to authenticate the employee for successive requests */
  apiToken?: Maybe<Scalars['String']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** JWT to authenticate the employee for successive requests */
  jwt?: Maybe<Scalars['String']['output']>;
  /** Updated employee */
  me?: Maybe<IEmployee>;
  /** Returns whether the mutation was successful */
  result: IResult;
  /** Returns which provided attribute(s) didnt apply to the validation rules */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of CreateVehicle */
export interface ICreateVehicleInput {
  brand?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fuelType?: InputMaybe<IVehicleFuelTypeEnum>;
  licensePlate?: InputMaybe<Scalars['String']['input']>;
  modalityObjectType?: InputMaybe<IModalityKindEnum>;
  model?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** DEPRECATED: Use modality_object_type field */
  objectType?: InputMaybe<IModalityPrivateVehicleEnum>;
  startsOn: Scalars['Date']['input'];
}

/** Autogenerated return type of CreateVehicle. */
export interface ICreateVehiclePayload {
  __typename?: 'CreateVehiclePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  validationErrors: Array<IValidationError>;
  vehicle?: Maybe<IMeansOfTransport>;
}

export interface ICreateWhatMutation {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  modalityId?: InputMaybe<Scalars['ID']['input']>;
  /** Reimbursable category method. If given, properties for the other reimbursable category are nullified in the backend since theyre mutually exclusive. */
  reimbursableCategoryMethodValue?: InputMaybe<IReimbursableCategoryMethodEnum>;
  sharedRidePassengers?: InputMaybe<Array<Scalars['String']['input']>>;
  vehicleId?: InputMaybe<Scalars['ID']['input']>;
}

/** Autogenerated input type of CreateWorkFromHome */
export interface ICreateWorkFromHomeInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not to confirm right away */
  confirm?: InputMaybe<Scalars['Boolean']['input']>;
  /** Date to create the work from hom for. */
  date?: InputMaybe<Scalars['Date']['input']>;
  /** Dates to create the work from home for. */
  dates?: InputMaybe<Array<Scalars['Date']['input']>>;
}

/** Autogenerated return type of CreateWorkFromHome. */
export interface ICreateWorkFromHomePayload {
  __typename?: 'CreateWorkFromHomePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** List of all confirmed work from home events. */
  confirmedMobilityEvents?: Maybe<Array<IMobilityEvent>>;
  dayWithMobilityEvents: IDayWithMobilityEvents;
  /** returns mobility events for all dates if given mutiple dates */
  daysWithMobilityEvents: Array<IDayWithMobilityEvents>;
  mobilityEvents: Array<IMobilityEvent>;
  result: IResult;
  /** List of all unconfirmed work from home events. */
  unconfirmedMobilityEvents?: Maybe<Array<IMobilityEvent>>;
  validationErrors: Array<IValidationError>;
}

export enum ICrowdForecastEnum {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  UNKNOWN = 'UNKNOWN'
}

export interface ICustomerLabelTheme {
  __typename?: 'CustomerLabelTheme';
  /** The favicon for this organization */
  favicon?: Maybe<IImage>;
  /** The icon for this organization */
  icon?: Maybe<IImage>;
  /** The light theme logo for this organization */
  logo?: Maybe<IImage>;
  /** The dark theme logo for this organization */
  logoDark?: Maybe<IImage>;
  /** The login poster image that should be used for this organization */
  poster?: Maybe<IImage>;
  /** The primary color of the organization */
  primaryColor?: Maybe<IColor>;
  /** The secondary color of the organization */
  secondaryColor?: Maybe<IColor>;
  /** The sidebar color of the organization */
  sidebarColor?: Maybe<IColor>;
  /** The sidebar text color of the organization */
  sidebarTextColor?: Maybe<IColor>;
}


export interface ICustomerLabelThemeFaviconArgs {
  expires?: InputMaybe<Scalars['DateTime']['input']>;
}


export interface ICustomerLabelThemeIconArgs {
  expires?: InputMaybe<Scalars['DateTime']['input']>;
}


export interface ICustomerLabelThemeLogoArgs {
  expires?: InputMaybe<Scalars['DateTime']['input']>;
}


export interface ICustomerLabelThemeLogoDarkArgs {
  expires?: InputMaybe<Scalars['DateTime']['input']>;
}


export interface ICustomerLabelThemePosterArgs {
  expires?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ICustomerService {
  __typename?: 'CustomerService';
  /** Customer service email address */
  emailAddress: Scalars['String']['output'];
  /** URL of dedicated customer service website */
  helpUrl?: Maybe<Scalars['String']['output']>;
  /** The alias for the url service desk */
  helpUrlName: Scalars['String']['output'];
  /** URL for a privacy policy */
  privacyPolicyUrl: Scalars['String']['output'];
  /** Customer service telephone number */
  telephone: Scalars['String']['output'];
}

/** Range of dates */
export interface IDateRangeInput {
  /** Minimum value of the range */
  from: Scalars['Date']['input'];
  /** Maximum value of the range */
  to: Scalars['Date']['input'];
}

export enum IDateRangeStringEnum {
  LAST_MONTH = 'last_month',
  LAST_WEEK = 'last_week',
  LAST_YEAR = 'last_year',
  MONTH = 'month',
  TODAY = 'today',
  WEEK = 'week',
  YEAR = 'year',
  YESTERDAY = 'yesterday'
}

/** Range of dates */
export interface IDateRangeStringInput {
  /** Minimum value of the range */
  dateRange: IDateRangeStringEnum;
}

export type IDay = IDayWithMobilityEvents | IDayWithoutEmployment;

export interface IDayWithMobilityEvents {
  __typename?: 'DayWithMobilityEvents';
  commuteCompensationAddable?: Maybe<Scalars['Boolean']['output']>;
  commuteRideAddable: Scalars['Boolean']['output'];
  commuteRidesAddable: Scalars['Boolean']['output'];
  date: Scalars['Date']['output'];
  eventsAddable: Scalars['Boolean']['output'];
  /** Mobility events for this day are confirmable */
  eventsConfirmable: Scalars['Boolean']['output'];
  /** @deprecated Submit used to be a synonym for confirm. We now only use confirm, so use confirmable instead */
  eventsSubmittable: Scalars['Boolean']['output'];
  mobilityEvents: Array<IMobilityEvent>;
  permissions: IPermissions;
  status?: Maybe<Scalars['String']['output']>;
  workFromHomeCompensationAddable: Scalars['Boolean']['output'];
}

export interface IDayWithoutEmployment {
  __typename?: 'DayWithoutEmployment';
  date: Scalars['Date']['output'];
}

export interface IDescription {
  __typename?: 'Description';
  /** Translated description of this service */
  humanized: Scalars['String']['output'];
}

/** Autogenerated input type of DestroyFavorite */
export interface IDestroyFavoriteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the favorite you want to destroy */
  id: Scalars['ID']['input'];
}

/** Autogenerated return type of DestroyFavorite. */
export interface IDestroyFavoritePayload {
  __typename?: 'DestroyFavoritePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Whether the destruction was a success or not */
  result: IResult;
}

/** Autogenerated input type of DestroyMobilityEvent */
export interface IDestroyMobilityEventInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
}

/** Autogenerated return type of DestroyMobilityEvent. */
export interface IDestroyMobilityEventPayload {
  __typename?: 'DestroyMobilityEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  day: IDay;
  result: IResult;
}

/** Autogenerated input type of DestroyMobilityEvents */
export interface IDestroyMobilityEventsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityEventIds: Array<Scalars['ID']['input']>;
}

/** Autogenerated return type of DestroyMobilityEvents. */
export interface IDestroyMobilityEventsPayload {
  __typename?: 'DestroyMobilityEventsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  days: Array<IDayWithMobilityEvents>;
  destroyedMobilityEventIds: Array<Scalars['ID']['output']>;
  result: IResult;
  undestroyedMobilityEvents: Array<IMobilityEvent>;
}

export enum IDestroyProfileAddressEnum {
  HOME = 'HOME',
  WORK = 'WORK'
}

/** Autogenerated input type of DestroyProfileAddress */
export interface IDestroyProfileAddressInput {
  /** Which address should be removed from the profile */
  addressType: IDestroyProfileAddressEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of DestroyProfileAddress. */
export interface IDestroyProfileAddressPayload {
  __typename?: 'DestroyProfileAddressPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Returns whether the mutation was successful */
  result: IResult;
}

export interface IDirectDebitInvoice {
  __typename?: 'DirectDebitInvoice';
  /** Amount invoiced */
  amount?: Maybe<IMoney>;
  /** External reference of this invoice */
  externalReference: Scalars['String']['output'];
  /** The database id of the invoice */
  id: Scalars['ID']['output'];
  /** Date of the invoice */
  invoiceDate: Scalars['Date']['output'];
  /** URL of the PDF of this invoice */
  pdfUrl: Scalars['String']['output'];
  /** The settlement that belongs to this invoice */
  settlement?: Maybe<ISettlement>;
  /** Status of this invoice */
  status?: Maybe<IInvoiceStatus>;
}

export interface IDirectDebitMandate {
  __typename?: 'DirectDebitMandate';
  /** External URL for accepting mandate */
  acceptanceUrl?: Maybe<Scalars['String']['output']>;
  /** DateTime at which this mandate was accepted */
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  actions: IMandateActions;
  /** Ecurring customer token */
  ecurringCustomerToken?: Maybe<Scalars['String']['output']>;
  /** Ecurring subscription token */
  ecurringSubscriptionToken?: Maybe<Scalars['String']['output']>;
  /** Mandate ID */
  id: Scalars['ID']['output'];
  /** Whether this mandate was accepted */
  isAccepted: Scalars['Boolean']['output'];
  /** Whether this mandate was customer registered */
  isCustomerRegistered: Scalars['Boolean']['output'];
  /** Whether this mandate was revoked */
  isRevoked: Scalars['Boolean']['output'];
  /** Whether this is a valid mandate */
  isValid: Scalars['Boolean']['output'];
  /** DateTime at which this mandate was revoked */
  revokedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Status of mandate */
  status?: Maybe<IMandateStatus>;
  /** Mandate UUID */
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface IDirectDebitMandates {
  __typename?: 'DirectDebitMandates';
  create?: Maybe<ICreateDirectDebitMandatePayload>;
  revoke?: Maybe<IRevokeDirectDebitMandatePayload>;
}


export interface IDirectDebitMandatesCreateArgs {
  input: ICreateDirectDebitMandateInput;
}


export interface IDirectDebitMandatesRevokeArgs {
  input: IRevokeDirectDebitMandateInput;
}

export enum IDirectionEnum {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
  ZERO = 'ZERO'
}

export interface IDisplay {
  __typename?: 'Display';
  humanized: Scalars['String']['output'];
  value: IDisplayTypeEnum;
}

export enum IDisplayTypeEnum {
  EXPIRED = 'EXPIRED',
  VALID = 'VALID',
  VIRGIN = 'VIRGIN'
}

export interface IDistance extends IReimbursementRule {
  __typename?: 'Distance';
  budgetGroupId: Scalars['ID']['output'];
  category: ICategory;
  configuration: IConfiguration;
  fiscality: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  main: IMainCategory;
  modality?: Maybe<IModality>;
  reimbursableCategory: IReimbursableCategory;
  sub?: Maybe<ISubCategory>;
  travelPurpose: Scalars['String']['output'];
}

export interface IDistancePerModality {
  __typename?: 'DistancePerModality';
  distance: Scalars['Int']['output'];
  modality?: Maybe<IModality>;
}

export interface IDistanceSummary {
  __typename?: 'DistanceSummary';
  mobilityEventsPerModality?: Maybe<Array<IDistancePerModality>>;
  totalDistance: Scalars['Int']['output'];
}

export interface IDiverted {
  __typename?: 'Diverted';
  humanized: Scalars['String']['output'];
  label: Scalars['String']['output'];
  value: Scalars['Boolean']['output'];
}

export interface IDonkeyLeg {
  __typename?: 'DonkeyLeg';
  bookingId?: Maybe<Scalars['String']['output']>;
  /** A bike pickup/drop-off location. */
  from: IAssetStation;
  /** The identifier of the leg */
  id: Scalars['ID']['output'];
  mode?: Maybe<IDonkeyRepublicModality>;
  /** A list of prices per segment. Segments are following up each other, depending on the length of a ride. The longer the ride, the more segments will be included in the final price. */
  price: Array<ISegmentPrice>;
  quoteId?: Maybe<Scalars['String']['output']>;
  /** The name of the external service that was used to obtain information. */
  service: Scalars['String']['output'];
}

export interface IDonkeyQuote {
  __typename?: 'DonkeyQuote';
  id: Scalars['ID']['output'];
  legs: Array<IDonkeyLeg>;
  validUntil: Scalars['DateTime']['output'];
}

export interface IDonkeyRepublic extends IAvailableFeatureInterface {
  __typename?: 'DonkeyRepublic';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export interface IDonkeyRepublicBooking {
  __typename?: 'DonkeyRepublicBooking';
  cancel?: Maybe<ICancelDonkeyRepublicReservationPayload>;
  create?: Maybe<ICreateDonkeyRepublicReservationPayload>;
  finish?: Maybe<IFinishDonkeyRepublicReservationPayload>;
  lock?: Maybe<ILockDonkeyRepublicAssetPayload>;
  start?: Maybe<IStartDonkeyRepublicReservationPayload>;
  unlock?: Maybe<IUnlockDonkeyRepublicAssetPayload>;
}


export interface IDonkeyRepublicBookingCancelArgs {
  input: ICancelDonkeyRepublicReservationInput;
}


export interface IDonkeyRepublicBookingCreateArgs {
  input: ICreateDonkeyRepublicReservationInput;
}


export interface IDonkeyRepublicBookingFinishArgs {
  input: IFinishDonkeyRepublicReservationInput;
}


export interface IDonkeyRepublicBookingLockArgs {
  input: ILockDonkeyRepublicAssetInput;
}


export interface IDonkeyRepublicBookingStartArgs {
  input: IStartDonkeyRepublicReservationInput;
}


export interface IDonkeyRepublicBookingUnlockArgs {
  input: IUnlockDonkeyRepublicAssetInput;
}

export interface IDonkeyRepublicModality {
  __typename?: 'DonkeyRepublicModality';
  humanized: Scalars['String']['output'];
  value: IDonkeyRepublicModalityEnum;
}

export enum IDonkeyRepublicModalityEnum {
  BIKE = 'bike',
  EBIKE = 'ebike'
}

export interface IDonkeyRepublicReservation {
  __typename?: 'DonkeyRepublicReservation';
  /** Data to access the Donkey Republic bike */
  accessData?: Maybe<IBikeAccess>;
  /** ID of the asset */
  assetId?: Maybe<Scalars['String']['output']>;
  /** User readable name for the asset */
  assetName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** An external id for the Mobidesk platform. */
  externalId: Scalars['ID']['output'];
  /** Full price ex. vat in cents */
  fullPriceExVatInCents?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  legs: Array<IDonkeyLeg>;
  status: IDonkeyRepublicStatus;
  /** The reason why the booking was unexpectedly put in this status. */
  statusReason?: Maybe<Scalars['String']['output']>;
  /** The reference in Donkey Republic */
  supplierReference: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
}

export interface IDonkeyRepublicResult extends IResultInterface {
  __typename?: 'DonkeyRepublicResult';
  /** Error field containing the error(s) from the DonkeyRepublic API */
  donkeyRepublicError?: Maybe<IError>;
  /** Error message */
  error?: Maybe<Scalars['String']['output']>;
  /** Success message */
  success?: Maybe<Scalars['String']['output']>;
}

export interface IDonkeyRepublicStatus {
  __typename?: 'DonkeyRepublicStatus';
  humanized: Scalars['String']['output'];
  value: IDonkeyRepublicStatusEnum;
}

export enum IDonkeyRepublicStatusEnum {
  ACTIVE = 'active',
  ACTIVE_LOCKED = 'active_locked',
  ACTIVE_PENDING_LOCK = 'active_pending_lock',
  ACTIVE_PENDING_UNLOCK = 'active_pending_unlock',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  CONFIRMED = 'confirmed',
  CONFIRMED_PENDING_UNLOCK = 'confirmed_pending_unlock',
  FINISHED = 'finished',
  NEW = 'new'
}

export interface IDriver {
  __typename?: 'Driver';
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
}

export interface IDriversLicenseCountry {
  __typename?: 'DriversLicenseCountry';
  /** Humanized country name that can be used for displaying */
  humanized: Scalars['String']['output'];
  /** Internal country name */
  value: IDriversLicenseCountryEnum;
}

export enum IDriversLicenseCountryEnum {
  AT = 'AT',
  BE = 'BE',
  BG = 'BG',
  CH = 'CH',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DK = 'DK',
  EE = 'EE',
  EL = 'EL',
  ES = 'ES',
  FI = 'FI',
  FR = 'FR',
  GB = 'GB',
  HR = 'HR',
  HU = 'HU',
  IE = 'IE',
  IS = 'IS',
  IT = 'IT',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  MT = 'MT',
  NL = 'NL',
  NO = 'NO',
  PL = 'PL',
  PT = 'PT',
  RO = 'RO',
  SE = 'SE',
  SI = 'SI',
  SK = 'SK'
}

export interface IDuration extends IWhen {
  __typename?: 'Duration';
  editable: Scalars['Boolean']['output'];
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  startsAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface IEkey {
  __typename?: 'Ekey';
  key?: Maybe<Scalars['String']['output']>;
  passkey?: Maybe<Scalars['String']['output']>;
}

export interface IEkeyLock {
  __typename?: 'EkeyLock';
  ekey?: Maybe<IEkey>;
  lock?: Maybe<ILock>;
}

export interface IEmployee {
  __typename?: 'Employee';
  /** @deprecated Use favorites instead */
  addresses?: Maybe<Array<IAddress>>;
  /** Total number of mobility events that needs to be approved by manager */
  approvableMobilityEventsAggregate: Scalars['Int']['output'];
  availableFeature?: Maybe<IAvailableFeatureInterface>;
  availableFeatures?: Maybe<Array<IAvailableFeatureInterface>>;
  /** Return a single service that belong to this employee */
  availableService?: Maybe<IAvailableServiceInterface>;
  /** List of all services on products that belong to this employee */
  availableServices?: Maybe<Array<IAvailableServiceInterface>>;
  /** @deprecated Use availableSharedVehicles */
  availableSharedCars?: Maybe<Array<IAvailableSharedCar>>;
  /** Request a list of available shared vehicles. The location where the vehicles must be nearby can be configured via the from_* arguments, but falls back to the most recent Favorite location with type "shared_vehicles_location". For legacy purposes there is a hard-coded fallback after this as well. */
  availableSharedVehicles?: Maybe<Array<IAvailableSharedVehicle>>;
  /** List of all available subscriptions within the budget group */
  availableSubscriptions?: Maybe<Array<IAvailableSubscription>>;
  /** Returns if the current session is an admin logged in as an employee */
  becameEmployee: Scalars['Boolean']['output'];
  /** The branding of the organization that htis employee is currently active at */
  branding?: Maybe<IBranding>;
  /** Returns the total CO2 emission for a date range, followed by a breakdown of CO2 emission per modality */
  co2EmissionSummary?: Maybe<ICo2EmissionSummary>;
  /** A list of all mobility_events that are copyable for a specific day */
  copyableMobilityEvents: Array<IMobilityEvent>;
  day?: Maybe<IDay>;
  days: Array<IDay>;
  defaultTravelPurpose?: Maybe<ITravelPurposeEnum>;
  /** Direct debit invoices for this employee */
  directDebitInvoices: Array<IDirectDebitInvoice>;
  /** All given direct debit mandates for this employee */
  directDebitMandates: Array<IDirectDebitMandate>;
  /** Returns the total recorded distance in meters for a date range, followed by a breakdown of distance in meters per modality */
  distanceSummary?: Maybe<IDistanceSummary>;
  /** Asset availability for Donkey Republic bikes per city */
  donkeyAssetsAvailability?: Maybe<Array<IAssetLocation>>;
  donkeyQuotes?: Maybe<Array<IDonkeyQuote>>;
  donkeyRepublicReservation?: Maybe<IDonkeyRepublicReservation>;
  donkeyRepublicReservations?: Maybe<Array<IDonkeyRepublicReservation>>;
  /** Whether or not this user needs to give a mandate for split billing */
  eMandateRequired: Scalars['Boolean']['output'];
  /** @deprecated Deprecated due to the obsolete addition of employee as prefix. Use `financialConsequences` instead. */
  employeeFinancialConsequences: IEmployeeFinancialConsequences;
  /** Returns a list of employments for this employee */
  employments?: Maybe<Array<IEmployment>>;
  /** Lists all export files for various datasets for the current employee */
  exportFiles?: Maybe<IExportFiles>;
  /**
   * Lists all export URLs for various datasets for the current employee
   * @deprecated Use exportFiles endpoint to retrieve the exports
   */
  exportUrls: IExportUrls;
  /** endpoint to retrieve the favorite locations of the current employee */
  favorites?: Maybe<Array<IFavorite>>;
  /** @deprecated use availableFeatures instead */
  featureOptions: Array<IFeatureOption>;
  filterOptions?: Maybe<IFilterOptions>;
  financialConsequences: IEmployeeFinancialConsequences;
  /** Fixed commute distance used by default for commute rides if enabled in the budget group. */
  fixedCommuteDistance?: Maybe<Scalars['Float']['output']>;
  greenwheelsFleets?: Maybe<Array<IGreenwheelsFleet>>;
  hasAgreedToGeneralTerms: Scalars['Boolean']['output'];
  hasAgreedToPrivacyStatement: Scalars['Boolean']['output'];
  hasNotAgreedToUpdatedGeneralTerms: Scalars['Boolean']['output'];
  hasNotAgreedToUpdatedPrivacyStatement: Scalars['Boolean']['output'];
  hasSso: Scalars['Boolean']['output'];
  holidayOnDate?: Maybe<Scalars['String']['output']>;
  /** The database id of this employee */
  id: Scalars['ID']['output'];
  /**
   * Invoices for this employee
   * @deprecated Use directDebitInvoices instead
   */
  invoices: Array<IDirectDebitInvoice>;
  journey?: Maybe<IJourney>;
  journeys?: Maybe<Array<IJourney>>;
  /** @deprecated The url is requestable via available features */
  leaseBikeUrl?: Maybe<Scalars['String']['output']>;
  licensePlate?: Maybe<ILicensePlate>;
  /** license plates of the vehicles of this employee */
  licensePlates?: Maybe<Array<ILicensePlate>>;
  /** Preview of multiple mobility events merged into one */
  mergedMobilityEventsPreview: IMergedMobilityEventsPreview;
  messages: Array<IMessage>;
  mileage?: Maybe<IMileage>;
  /** Years of when the Mileages were recorded for the vehicles of this employee */
  mileagesYears?: Maybe<Array<Scalars['String']['output']>>;
  /** The token to use when connecting with Mobidot */
  mobidotUserToken?: Maybe<Scalars['String']['output']>;
  mobilityCardOrder?: Maybe<IMobilityCardOrder>;
  mobilityCardOrders: Array<IMobilityCardOrder>;
  mobilityEvent?: Maybe<IMobilityEvent>;
  mobilityEventFormValidations?: Maybe<IMobilityEventFormValidations>;
  /** Totals for mobility events within the selection. Amounts are based on the budget transaction. */
  mobilityEventTotals: IMobilityEventTotals;
  mobilityEvents: Array<IMobilityEvent>;
  mobilityEventsAggregate?: Maybe<IMobilityEventAggregate>;
  mobilityEventsByDate: Array<IMobilityEventByDate>;
  multiModalAvailability?: Maybe<Array<IMultiModalAvailability>>;
  organization?: Maybe<IOrganization>;
  organizations?: Maybe<Array<IOrganization>>;
  /** Parking orders for this employee */
  parkingOrders: Array<IParkingOrder>;
  permissions: IPermissions;
  /** Return a single personal means of registration */
  personalMeansOfRegistration?: Maybe<IPersonalMeansOfRegistration>;
  /** All personal means of registration for this user */
  personalMeansOfRegistrations?: Maybe<Array<IPersonalMeansOfRegistration>>;
  /** Return a single personal vehicle */
  personalVehicle?: Maybe<IMeansOfTransport>;
  /** List of all vehicles that are available for this employee */
  personalVehicles: Array<IMeansOfTransport>;
  /**
   * The phone number of this employee
   * @deprecated Use `profile.phone_number` instead. We did this to group profile-specific data about this employee.
   */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  possibleReplacementManagers?: Maybe<Array<IPossibleReplacementManager>>;
  /** The profile details of this employee, mostly containing his personal details */
  profile: IProfile;
  /** Returns a list of countries Iso Codes */
  profileCountries: Array<ICountryIsoCode>;
  /** QR code that can be used to retrieve an API token for this user */
  qrCodeForApiToken: Scalars['String']['output'];
  refundableIncompleteRides?: Maybe<Array<IMobilityEvent>>;
  replacementAssignments?: Maybe<Array<IReplacementManager>>;
  replacementManager?: Maybe<IReplacementManager>;
  replacementManagers?: Maybe<Array<IReplacementManager>>;
  /** @deprecated The requestable features are now accessible through the available_features endpoint. */
  requestableFeatureOptions?: Maybe<Array<IRequestableFeatureOption>>;
  requiredFields: IRequiredFields;
  /** Lists all reservations across different reservation types for the current employee */
  reservations?: Maybe<Array<IReservation>>;
  routeMetadata: IRouteMetadata;
  /** @deprecated Use sharedVehicleReservation */
  sharedCarReservation?: Maybe<ISharedCarReservation>;
  /** @deprecated Use sharedVehicleReservations */
  sharedCarReservations?: Maybe<Array<ISharedCarReservation>>;
  sharedVehicleReservation?: Maybe<ISharedVehicleReservation>;
  sharedVehicleReservations?: Maybe<Array<ISharedVehicleReservation>>;
  splitBillingContract?: Maybe<ISplitBillingContract>;
  splitBillingContracts?: Maybe<Array<ISplitBillingContract>>;
  /** Split billing invoices for this employee */
  splitBillingInvoices: Array<ISplitBillingInvoice>;
  statistics: IStatistics;
  subordinate?: Maybe<ISubordinate>;
  subordinates?: Maybe<Array<ISubordinate>>;
  tasks: Array<ITask>;
  taxiReservation?: Maybe<ITaxiReservation>;
  taxiReservations: Array<ITaxiReservation>;
  termsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  /** The token used for inviting users or when resetting their password */
  token: Scalars['String']['output'];
  tranzerQuotes?: Maybe<Array<IQuote>>;
  tranzerReservation?: Maybe<ITranzerReservation>;
  tranzerReservations?: Maybe<Array<ITranzerReservation>>;
  /** vehicle options for creating mileages */
  vehicleOptions?: Maybe<Array<IVehicleOptions>>;
  whatOptions: Array<IWhatOption>;
  /** all active yellowbrick services of this employee */
  yellowbrickServices?: Maybe<Array<IYellowbrickService>>;
}


export interface IEmployeeAvailableFeatureArgs {
  id: Scalars['ID']['input'];
}


export interface IEmployeeAvailableFeaturesArgs {
  filterBy?: InputMaybe<Array<IFeatureOptionEnum>>;
  status?: InputMaybe<IMobilityCardOrderStatusEnum>;
}


export interface IEmployeeAvailableServiceArgs {
  id: Scalars['ID']['input'];
}


export interface IEmployeeAvailableServicesArgs {
  filterBy?: InputMaybe<IPersonalMeansOfRegistrationServiceStatusFilterEnum>;
}


export interface IEmployeeAvailableSharedCarsArgs {
  endsAt: Scalars['DateTime']['input'];
  startsAt: Scalars['DateTime']['input'];
}


export interface IEmployeeAvailableSharedVehiclesArgs {
  endsAt: Scalars['DateTime']['input'];
  fromHumanized?: InputMaybe<Scalars['String']['input']>;
  fromLatitude?: InputMaybe<Scalars['Coordinate']['input']>;
  fromLongitude?: InputMaybe<Scalars['Coordinate']['input']>;
  startsAt: Scalars['DateTime']['input'];
}


export interface IEmployeeCo2EmissionSummaryArgs {
  dateRange?: InputMaybe<IDateRangeInput>;
  dateRangeString?: InputMaybe<IDateRangeStringInput>;
}


export interface IEmployeeCopyableMobilityEventsArgs {
  forDate: Scalars['Date']['input'];
}


export interface IEmployeeDayArgs {
  date: Scalars['Date']['input'];
}


export interface IEmployeeDaysArgs {
  from: Scalars['Date']['input'];
  to: Scalars['Date']['input'];
}


export interface IEmployeeDefaultTravelPurposeArgs {
  on?: InputMaybe<Scalars['Date']['input']>;
}


export interface IEmployeeDirectDebitMandatesArgs {
  filterByStatus?: InputMaybe<IMandateStatusFilterEnum>;
}


export interface IEmployeeDistanceSummaryArgs {
  dateRange?: InputMaybe<IDateRangeInput>;
  dateRangeString?: InputMaybe<IDateRangeStringInput>;
}


export interface IEmployeeDonkeyQuotesArgs {
  cityIdentifier: Scalars['String']['input'];
  stationId: Scalars['ID']['input'];
}


export interface IEmployeeDonkeyRepublicReservationArgs {
  id: Scalars['ID']['input'];
}


export interface IEmployeeEmploymentsArgs {
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
  withChildren?: InputMaybe<Scalars['Boolean']['input']>;
}


export interface IEmployeeExportFilesArgs {
  filterByStatus?: InputMaybe<IFilterStatusEnum>;
  filterByStatuses?: InputMaybe<Array<IFilterStatusEnum>>;
  happenedBetween?: InputMaybe<IDateRangeInput>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  mainCategories?: InputMaybe<Array<IMainCategoryEnum>>;
  modalities?: InputMaybe<Array<IModalityKindEnum>>;
  modalityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  modalityOwnerships?: InputMaybe<Array<IOwnershipEnum>>;
  subCategories?: InputMaybe<Array<ISubCategoryEnum>>;
  travelPurpose?: InputMaybe<ITravelPurposeEnum>;
  travelPurposes?: InputMaybe<Array<ITravelPurposeEnum>>;
  year?: InputMaybe<Scalars['Int']['input']>;
}


export interface IEmployeeFavoritesArgs {
  filterBy?: InputMaybe<IFavoriteFilterEnum>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
  withFallbackLocation?: InputMaybe<Scalars['Boolean']['input']>;
}


export interface IEmployeeFeatureOptionsArgs {
  on?: InputMaybe<Scalars['Date']['input']>;
}


export interface IEmployeeHolidayOnDateArgs {
  date: Scalars['Date']['input'];
}


export interface IEmployeeJourneyArgs {
  journeyId: Scalars['String']['input'];
}


export interface IEmployeeJourneysArgs {
  arrivalTime?: InputMaybe<Scalars['DateTime']['input']>;
  departureTime?: InputMaybe<Scalars['DateTime']['input']>;
  destination: ILocationInput;
  journeyModes?: InputMaybe<Array<IPreferredJourneyModeEnum>>;
  journeyOperators?: InputMaybe<Array<Scalars['String']['input']>>;
  order?: InputMaybe<IJourneySorter>;
  origin: ILocationInput;
}


export interface IEmployeeLicensePlateArgs {
  licensePlate: Scalars['String']['input'];
}


export interface IEmployeeLicensePlatesArgs {
  order?: InputMaybe<IVehicleStatusEnum>;
}


export interface IEmployeeMergedMobilityEventsPreviewArgs {
  mobilityEventIds: Array<Scalars['ID']['input']>;
}


export interface IEmployeeMessagesArgs {
  withKey?: InputMaybe<IMessageKeyEnum>;
}


export interface IEmployeeMileageArgs {
  id: Scalars['ID']['input'];
}


export interface IEmployeeMobilityCardOrderArgs {
  id: Scalars['ID']['input'];
}


export interface IEmployeeMobilityEventArgs {
  id?: InputMaybe<Scalars['Int']['input']>;
  mobilityEventId?: InputMaybe<Scalars['ID']['input']>;
}


export interface IEmployeeMobilityEventFormValidationsArgs {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  fromLocationId?: InputMaybe<Scalars['ID']['input']>;
  modalityId?: InputMaybe<Scalars['ID']['input']>;
  toLocationId?: InputMaybe<Scalars['ID']['input']>;
}


export interface IEmployeeMobilityEventTotalsArgs {
  happenedBetween?: InputMaybe<IDateRangeInput>;
}


export interface IEmployeeMobilityEventsArgs {
  filterByStatus?: InputMaybe<IFilterStatusEnum>;
  filterByStatuses?: InputMaybe<Array<IFilterStatusEnum>>;
  filterStatus?: InputMaybe<Scalars['String']['input']>;
  happenedBetween?: InputMaybe<IDateRangeInput>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mainCategories?: InputMaybe<Array<IMainCategoryEnum>>;
  modalities?: InputMaybe<Array<IModalityKindEnum>>;
  modalityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  modalityOwnerships?: InputMaybe<Array<IOwnershipEnum>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  subCategories?: InputMaybe<Array<ISubCategoryEnum>>;
  travelPurpose?: InputMaybe<ITravelPurposeEnum>;
  travelPurposes?: InputMaybe<Array<ITravelPurposeEnum>>;
}


export interface IEmployeeMobilityEventsAggregateArgs {
  filterByStatus?: InputMaybe<IFilterStatusEnum>;
  filterByStatuses?: InputMaybe<Array<IFilterStatusEnum>>;
  happenedBetween?: InputMaybe<IDateRangeInput>;
  includeAll?: InputMaybe<Scalars['Boolean']['input']>;
  mainCategories?: InputMaybe<Array<IMainCategoryEnum>>;
  modalities?: InputMaybe<Array<IModalityKindEnum>>;
  modalityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  modalityOwnerships?: InputMaybe<Array<IOwnershipEnum>>;
  subCategories?: InputMaybe<Array<ISubCategoryEnum>>;
  travelPurpose?: InputMaybe<ITravelPurposeEnum>;
  travelPurposes?: InputMaybe<Array<ITravelPurposeEnum>>;
}


export interface IEmployeeMobilityEventsByDateArgs {
  filterByStatus?: InputMaybe<IFilterStatusEnum>;
  filterByStatuses?: InputMaybe<Array<IFilterStatusEnum>>;
  filterStatus?: InputMaybe<Scalars['String']['input']>;
  happenedBetween?: InputMaybe<IDateRangeInput>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mainCategories?: InputMaybe<Array<IMainCategoryEnum>>;
  modalities?: InputMaybe<Array<IModalityKindEnum>>;
  modalityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  modalityOwnerships?: InputMaybe<Array<IOwnershipEnum>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  subCategories?: InputMaybe<Array<ISubCategoryEnum>>;
  travelPurpose?: InputMaybe<ITravelPurposeEnum>;
  travelPurposes?: InputMaybe<Array<ITravelPurposeEnum>>;
}


export interface IEmployeeMultiModalAvailabilityArgs {
  lat: Scalars['Coordinate']['input'];
  long: Scalars['Coordinate']['input'];
  modalities?: InputMaybe<Array<IJourneyModeEnum>>;
  operators?: InputMaybe<Array<Scalars['String']['input']>>;
  radius: Scalars['Int']['input'];
}


export interface IEmployeeOrganizationArgs {
  on?: InputMaybe<Scalars['Date']['input']>;
}


export interface IEmployeeOrganizationsArgs {
  from: Scalars['Date']['input'];
  to: Scalars['Date']['input'];
}


export interface IEmployeePermissionsArgs {
  on?: InputMaybe<Scalars['Date']['input']>;
}


export interface IEmployeePersonalMeansOfRegistrationArgs {
  id: Scalars['ID']['input'];
}


export interface IEmployeePersonalMeansOfRegistrationsArgs {
  filterByKindMain?: InputMaybe<IPersonalMeansOfRegistrationKindMainEnum>;
  filterByStatus?: InputMaybe<IPersonalMeansOfRegistrationStatusFilterEnum>;
}


export interface IEmployeePersonalVehicleArgs {
  id: Scalars['ID']['input'];
}


export interface IEmployeePersonalVehiclesArgs {
  filterBy?: InputMaybe<IVehicleStatusEnum>;
  order?: InputMaybe<IOrderEnum>;
  ownership?: InputMaybe<IOwnershipEnum>;
}


export interface IEmployeeReplacementManagerArgs {
  id: Scalars['ID']['input'];
}


export interface IEmployeeRequiredFieldsArgs {
  on?: InputMaybe<Scalars['Date']['input']>;
}


export interface IEmployeeReservationsArgs {
  filterBy?: InputMaybe<IReservationStatusEnum>;
  filterByState?: InputMaybe<IReservationStateEnum>;
  kinds?: InputMaybe<Array<IReservationKindEnum>>;
  order?: InputMaybe<IOrderEnum>;
}


export interface IEmployeeRouteMetadataArgs {
  from?: InputMaybe<ICoordinatesMutation>;
  modalityId?: InputMaybe<Scalars['ID']['input']>;
  on?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<ICoordinatesMutation>;
  travelPurpose?: InputMaybe<ITravelPurposeEnum>;
}


export interface IEmployeeSharedCarReservationArgs {
  id: Scalars['ID']['input'];
}


export interface IEmployeeSharedCarReservationsArgs {
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
}


export interface IEmployeeSharedVehicleReservationArgs {
  id: Scalars['ID']['input'];
}


export interface IEmployeeSharedVehicleReservationsArgs {
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
}


export interface IEmployeeSplitBillingContractArgs {
  contractId: Scalars['String']['input'];
}


export interface IEmployeeSplitBillingContractsArgs {
  filterByStatus?: InputMaybe<IContractStatusEnum>;
}


export interface IEmployeeSubordinateArgs {
  id: Scalars['ID']['input'];
}


export interface IEmployeeTaxiReservationArgs {
  id: Scalars['ID']['input'];
}


export interface IEmployeeTermsAndConditionsArgs {
  kind?: InputMaybe<ITermsAndConditionsDocumentKindEnum>;
  kinds?: InputMaybe<Array<ITermsAndConditionsDocumentKindEnum>>;
}


export interface IEmployeeTranzerQuotesArgs {
  classNumber?: InputMaybe<ITrainClassEnum>;
  endsAt?: InputMaybe<Scalars['DateTime']['input']>;
  fromLocationName: Scalars['String']['input'];
  passengerCount?: InputMaybe<Scalars['Int']['input']>;
  preferredModalities?: InputMaybe<Array<IPreferredModalityEnum>>;
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  toLocationName: Scalars['String']['input'];
}


export interface IEmployeeTranzerReservationArgs {
  id: Scalars['ID']['input'];
}


export interface IEmployeeWhatOptionsArgs {
  on?: InputMaybe<Scalars['Date']['input']>;
}

export interface IEmployeeAddress {
  __typename?: 'EmployeeAddress';
  city?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use employeeCountryIsoCode please */
  countryIsoCode?: Maybe<ICountryIsoCodeEnum>;
  employeeCountryIsoCode?: Maybe<ICountryIsoCode>;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  streetNumberSuffix?: Maybe<Scalars['String']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
}

export interface IEmployeeAddressMutation {
  city: Scalars['String']['input'];
  countryIsoCode: ICountryIsoCodeEnum;
  street: Scalars['String']['input'];
  streetNumber: Scalars['String']['input'];
  streetNumberSuffix?: InputMaybe<Scalars['String']['input']>;
  zipcode: Scalars['String']['input'];
}

export interface IEmployeeDetails {
  __typename?: 'EmployeeDetails';
  email?: Maybe<Scalars['String']['output']>;
  employeeUuid?: Maybe<Scalars['ID']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  infix?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  leaseBicycleCategory?: Maybe<Scalars['String']['output']>;
  organizationUuid?: Maybe<Scalars['ID']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
}

export interface IEmployeeDetailsResponse {
  __typename?: 'EmployeeDetailsResponse';
  details?: Maybe<IEmployeeDetails>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
}

export interface IEmployeeFinancialConsequences {
  __typename?: 'EmployeeFinancialConsequences';
  budgetTransactionRemainder: IBudget;
  /** cumulative gross amount of all settlements */
  cumulativeGrossAmount?: Maybe<Scalars['Float']['output']>;
  /** cumulative net amount of all settlements */
  cumulativeNetAmount?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use last_intermediate_settlement.gross_amount instead */
  grossAmount?: Maybe<IBudget>;
  /** All financial consequences coming from the last intermediate settlement. If no intermediate settlement is present yet, it returns the data of the tentative settlement instead. */
  lastIntermediateSettlement?: Maybe<ISettlement>;
  /** @deprecated Use last_intermediate_settlement.net_amount instead */
  netAmount?: Maybe<IBudget>;
  /** get a specific settlement */
  settlement?: Maybe<ISettlement>;
  settlements?: Maybe<Array<ISettlement>>;
  /** @deprecated Use last_intermediate_settlement.gross_amount instead */
  tentativeGrossAmount: IBudget;
  /** @deprecated Use tentative_settlement.net_amount instead */
  tentativeNetAmount: IBudget;
  /** All financial consequences coming from the tentative settlement. */
  tentativeSettlement: ISettlement;
}


export interface IEmployeeFinancialConsequencesSettlementArgs {
  id: Scalars['ID']['input'];
}

export interface IEmployment {
  __typename?: 'Employment';
  /** The budget group name that this employment belongs to */
  budgetGroupName: Scalars['String']['output'];
  /** The commute monthly km budget */
  commuteMonthlyKmBudget?: Maybe<Scalars['Int']['output']>;
  /** The commute monthly momney budget */
  commuteMonthlyMoneyBudget?: Maybe<Scalars['Int']['output']>;
  /** The cost center that this employment belongs to */
  costCenter?: Maybe<Scalars['String']['output']>;
  /** The department that this employment belongs to */
  department?: Maybe<Scalars['String']['output']>;
  /** The date that this employment ends on */
  endsOn?: Maybe<Scalars['DateTime']['output']>;
  /** The database id of this employment */
  id: Scalars['ID']['output'];
  /** Whether this employment is the main employment */
  main: Scalars['Boolean']['output'];
  /** The date that this employment starts on */
  startsOn: Scalars['DateTime']['output'];
}

export interface IEnecoDongle extends IAvailableFeatureInterface {
  __typename?: 'EnecoDongle';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export interface IError {
  __typename?: 'Error';
  /** Error details */
  details?: Maybe<Array<IError>>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
  /** Error code */
  messageCode?: Maybe<Scalars['String']['output']>;
}

export interface IExplanation {
  __typename?: 'Explanation';
  /** Whether to still show the explanation box even if it is not required */
  allowed: Scalars['Boolean']['output'];
  humanized?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  required: IExplanationRequired;
}

export interface IExplanationRequired {
  __typename?: 'ExplanationRequired';
  business: Scalars['Boolean']['output'];
  businessByAmount: Scalars['Boolean']['output'];
  businessByDistance: Scalars['Boolean']['output'];
  commute: Scalars['Boolean']['output'];
  commuteByAmount: Scalars['Boolean']['output'];
  commuteByDistance: Scalars['Boolean']['output'];
  personal: Scalars['Boolean']['output'];
  personalByAmount: Scalars['Boolean']['output'];
  personalByDistance: Scalars['Boolean']['output'];
}

export interface IExportFiles {
  __typename?: 'ExportFiles';
  mobilityEventsExport?: Maybe<Scalars['String']['output']>;
  ridesExport?: Maybe<Scalars['String']['output']>;
  transactionsExport?: Maybe<Scalars['String']['output']>;
}

export interface IExportUrls {
  __typename?: 'ExportUrls';
  mobilityEventsExportUrl: Scalars['String']['output'];
}

export interface IExternalUrl {
  __typename?: 'ExternalUrl';
  /** Title of the URL */
  title: Scalars['String']['output'];
  /** Actual URL */
  url: Scalars['String']['output'];
}

export interface IExternalUrls {
  __typename?: 'ExternalUrls';
  /** Static URL to the mobility app */
  appStoreUrls: IAppUrls;
  /** Static URL to the English version of the current Privacy Statement */
  englishPrivacyStatementUrl: IExternalUrl;
  /** Static URL to the English version of the current Terms and Conditions */
  englishTermsAndConditionsUrl: IExternalUrl;
  /** URL to request a NS refund */
  nsRefundUrl: IExternalUrl;
  /** URL to the Yellowbrick Forgot Password page */
  yellowbrickForgotPasswordUrl: IExternalUrl;
}

export interface IFavorite {
  __typename?: 'Favorite';
  /** Actions that can be executed on this favorite */
  actions: IFavoriteActions;
  /** Contains the longitude and latitude of the location */
  coordinates: ICoordinates;
  geoLocation?: Maybe<IFavoriteLocation>;
  /** Whether this favorite uses a fallback location */
  hasFallbackLocation: Scalars['Boolean']['output'];
  /** The database id */
  id: Scalars['ID']['output'];
  /** A user friendly/generated label */
  label: Scalars['String']['output'];
  /** The location string as it was provided by google places */
  location: Scalars['String']['output'];
  /** An enriched location object with complete address address information retrieved from google places */
  locationId?: Maybe<Scalars['ID']['output']>;
  /** If provided, this favorite has been supplied by the employee's organization */
  organization?: Maybe<IOrganization>;
  /** If null, this has been created by the employee or their organization, otherwise it is either their home or work location */
  type?: Maybe<IFavoriteTypeEnum>;
}

export interface IFavoriteActions {
  __typename?: 'FavoriteActions';
  deletable: Scalars['Boolean']['output'];
  editable: Scalars['Boolean']['output'];
}

export enum IFavoriteFilterEnum {
  ORGANIZATION = 'ORGANIZATION',
  PERSONAL = 'PERSONAL'
}

export interface IFavoriteLocation {
  __typename?: 'FavoriteLocation';
  city?: Maybe<Scalars['String']['output']>;
  coordinates: ICoordinates;
  countryIsoCode?: Maybe<Scalars['String']['output']>;
  formattedAddress?: Maybe<Scalars['String']['output']>;
  googlePlaceId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  initialSource: Scalars['String']['output'];
  name: Scalars['String']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  streetNumberSuffix?: Maybe<Scalars['String']['output']>;
  tomtomPlaceId?: Maybe<Scalars['String']['output']>;
}

export enum IFavoriteTypeEnum {
  HOME = 'HOME',
  SHARED_VEHICLES_LOCATION = 'SHARED_VEHICLES_LOCATION',
  WORK = 'WORK'
}

export interface IFavorites {
  __typename?: 'Favorites';
  create?: Maybe<ICreateFavoritePayload>;
  destroy?: Maybe<IDestroyFavoritePayload>;
  setManualCoordinates?: Maybe<ISetManualFavoriteCoordinatesPayload>;
  update?: Maybe<IUpdateFavoritePayload>;
}


export interface IFavoritesCreateArgs {
  input: ICreateFavoriteInput;
}


export interface IFavoritesDestroyArgs {
  input: IDestroyFavoriteInput;
}


export interface IFavoritesSetManualCoordinatesArgs {
  input: ISetManualFavoriteCoordinatesInput;
}


export interface IFavoritesUpdateArgs {
  input: IUpdateFavoriteInput;
}

export interface IFeatureOption {
  __typename?: 'FeatureOption';
  humanized: Scalars['String']['output'];
  value: IFeatureOptionEnum;
}

export enum IFeatureOptionEnum {
  AMBER = 'amber',
  DONKEY_REPUBLIC = 'donkey_republic',
  ENECO_DONGLE = 'eneco_dongle',
  GPS = 'gps',
  GREENWHEELS = 'greenwheels',
  LEASE_BIKE = 'lease_bike',
  RENTAL_CAR = 'rental_car',
  SHARED_VEHICLE = 'shared_vehicle',
  SHUTTEL_CHECK = 'shuttel_check',
  SHUTTEL_MY_WHEELS = 'shuttel_my_wheels',
  SHUTTEL_SIXT = 'shuttel_sixt',
  SHUTTEL_TAXI = 'shuttel_taxi',
  SHUTTEL_TRANZER_ETICKETS = 'shuttel_tranzer_etickets',
  SHUTTEL_VECORE = 'shuttel_vecore',
  TAXI = 'taxi',
  TRANZER_ETICKETS = 'tranzer_etickets',
  YELLOWBRICK_SP = 'yellowbrick_sp'
}

export interface IFeatureOrderStatus {
  __typename?: 'FeatureOrderStatus';
  /** Humanized and translated status */
  humanized: Scalars['String']['output'];
  /** Raw value of the status */
  value: IFeatureOrderStatusEnum;
}

export enum IFeatureOrderStatusEnum {
  APPROVED = 'APPROVED',
  DRAFT = 'DRAFT',
  NOT_REQUESTED = 'NOT_REQUESTED',
  ORDERED = 'ORDERED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  REJECTED = 'REJECTED',
  REQUEST_FAILED = 'REQUEST_FAILED'
}

export interface IFeatureUrls {
  __typename?: 'FeatureUrls';
  /** Url for Android App */
  android?: Maybe<Scalars['String']['output']>;
  /** Url for iOS App */
  ios?: Maybe<Scalars['String']['output']>;
  /** Url for web */
  web?: Maybe<Scalars['String']['output']>;
}

export interface IFilterOptionItem {
  __typename?: 'FilterOptionItem';
  /** Filter humanized value */
  humanized: Scalars['String']['output'];
  /** Filter value */
  value: Scalars['String']['output'];
}

export interface IFilterOptions {
  __typename?: 'FilterOptions';
  /** Donkey assets availability type filter options */
  donkeyAssetsAvailabilityType?: Maybe<Array<IFilterOptionItem>>;
  /** JourneyServiceProviders filter options */
  journeyServiceOperators?: Maybe<Array<IFilterOptionItem>>;
  /** JourneyModes filter options */
  journeysJourneyModes?: Maybe<Array<IFilterOptionItem>>;
  /** Main categories of MobilityEvents */
  mainCategories?: Maybe<Array<IFilterOptionItem>>;
  /** MobilityEvents Status filter options */
  mobilityEventsFilterByStatus?: Maybe<Array<IFilterOptionItem>>;
  /** MobilityEvents Modality filter options */
  mobilityEventsModality?: Maybe<Array<IFilterOptionItem>>;
  /** MobilityEvents TravelPurpose filter options */
  mobilityEventsTravelPurpose?: Maybe<Array<IFilterOptionItem>>;
  /** MultiModalAvailability modality filter options */
  multiModalAvailabilityModalities?: Maybe<Array<IFilterOptionItem>>;
  /** Reservation kinds */
  reservationsKinds?: Maybe<Array<IFilterOptionItem>>;
  /** Tranzer quotes preferred modalities filter options */
  tranzerQuotesPreferredModalities?: Maybe<Array<IFilterOptionItem>>;
}

export enum IFilterStatusEnum {
  ACCEPTED = 'accepted',
  CONFIRMABLE = 'confirmable',
  CONFIRMED = 'confirmed',
  DRAFT = 'draft',
  REJECTED = 'rejected',
  REQUIRES_ACTION = 'requires_action'
}

export interface IFinancial {
  __typename?: 'Financial';
  consequences: IFinancialConsequences;
  fact?: Maybe<IFinancialFact>;
}

export interface IFinancialComponent {
  __typename?: 'FinancialComponent';
  amount: IMoney;
  entries: Array<IJournalEntry>;
  label: Scalars['String']['output'];
  moneyTransfer: IMoneyTransfer;
}

export interface IFinancialConsequences {
  __typename?: 'FinancialConsequences';
  fiscal?: Maybe<IFinancialComponent>;
  gross?: Maybe<IFinancialComponent>;
  net?: Maybe<IFinancialComponent>;
}

export interface IFinancialFact {
  __typename?: 'FinancialFact';
  direction?: Maybe<IDirectionEnum>;
  exclVat?: Maybe<IMoney>;
  inclVat: IMoney;
  label: Scalars['String']['output'];
  receipt?: Maybe<IReceipt>;
  vatRate?: Maybe<IVatRate>;
}

export interface IFinancialFactMutation {
  inclVat?: InputMaybe<Scalars['BigDecimal']['input']>;
  receipt?: InputMaybe<IAttachment>;
}

export interface IFinancialMutation {
  fact: IFinancialFactMutation;
}

export interface IFinancialSummary {
  __typename?: 'FinancialSummary';
  fiscal?: Maybe<Scalars['Float']['output']>;
  gross?: Maybe<Scalars['Float']['output']>;
  mobilityEvent?: Maybe<IMobilityEvent>;
  net?: Maybe<Scalars['Float']['output']>;
}

/** Autogenerated input type of FinishDonkeyRepublicReservation */
export interface IFinishDonkeyRepublicReservationInput {
  bookingId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Coordinate']['input'];
  longitude: Scalars['Coordinate']['input'];
  userIsNearVehicle: Scalars['Boolean']['input'];
  vehicleIsLocked: Scalars['Boolean']['input'];
}

/** Autogenerated return type of FinishDonkeyRepublicReservation. */
export interface IFinishDonkeyRepublicReservationPayload {
  __typename?: 'FinishDonkeyRepublicReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  donkeyRepublicReservation?: Maybe<IDonkeyRepublicReservation>;
  donkeyRepublicResult?: Maybe<IDonkeyRepublicResult>;
  result: IDonkeyRepublicResult;
}

/** Autogenerated input type of FinishSharedCarReservation */
export interface IFinishSharedCarReservationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
}

/** Autogenerated return type of FinishSharedCarReservation. */
export interface IFinishSharedCarReservationPayload {
  __typename?: 'FinishSharedCarReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  /** @deprecated Use sharedVehicleReservation */
  sharedCarReservation: ISharedCarReservation;
}

/** Autogenerated input type of FinishSharedVehicleReservation */
export interface IFinishSharedVehicleReservationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
}

/** Autogenerated return type of FinishSharedVehicleReservation. */
export interface IFinishSharedVehicleReservationPayload {
  __typename?: 'FinishSharedVehicleReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  sharedVehicleReservation: ISharedVehicleReservation;
}

export interface IFirstClassReason {
  __typename?: 'FirstClassReason';
  humanized: Scalars['String']['output'];
  value: IFirstClassReasonEnum;
}

export enum IFirstClassReasonEnum {
  BUSINESS_ONLY = 'BUSINESS_ONLY',
  EMPLOYMENT_CONDITION = 'EMPLOYMENT_CONDITION',
  MEDICAL = 'MEDICAL',
  OWN_ACCOUNT = 'OWN_ACCOUNT'
}

export enum IFiscalityEnum {
  REIMBURSED = 'REIMBURSED',
  SUPPLIED = 'SUPPLIED'
}

export interface IFuel {
  __typename?: 'Fuel';
  humanized: Scalars['String']['output'];
  value: IVehicleFuelTypeEnum;
}

export interface IGeoLocation {
  __typename?: 'GeoLocation';
  coordinates: ICoordinates;
  humanized?: Maybe<Scalars['String']['output']>;
}

/** Autogenerated input type of GetAccessUrl */
export interface IGetAccessUrlInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of GetAccessUrl. */
export interface IGetAccessUrlPayload {
  __typename?: 'GetAccessUrlPayload';
  /** If everything was ok, this contains the url to redirect the user to */
  accessUrl?: Maybe<Scalars['String']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The id of the contract as known in the database */
  contract?: Maybe<ISplitBillingContract>;
  /** Whether the request was a success or not */
  result: IResult;
}

/** Suggest locations */
export interface IGooglePlaces {
  __typename?: 'GooglePlaces';
  /** returns the details given a specific place id. */
  details?: Maybe<IGooglePlacesDetailsResult>;
  /** returns a list places that starts with... */
  suggest?: Maybe<Array<IGooglePlacesResult>>;
}


/** Suggest locations */
export interface IGooglePlacesDetailsArgs {
  placeId: Scalars['String']['input'];
}


/** Suggest locations */
export interface IGooglePlacesSuggestArgs {
  startsWith: Scalars['String']['input'];
}

export interface IGooglePlacesDetailsResult {
  __typename?: 'GooglePlacesDetailsResult';
  /** A formatted address of the location. */
  address: Scalars['String']['output'];
  /** City address component */
  city?: Maybe<Scalars['String']['output']>;
  /** Contains the longitude and latitude of the location */
  coordinates: ICoordinates;
  /** Country ISO Code address component */
  countryIsoCode?: Maybe<Scalars['String']['output']>;
  /** Reference to stored location with a cached google details */
  locationId?: Maybe<Scalars['ID']['output']>;
  /** The name of the location */
  name: Scalars['String']['output'];
  /** The Google Place ID that you'd like to get the details from. */
  placeId: Scalars['String']['output'];
  /** Postal Code address component */
  postalCode?: Maybe<Scalars['String']['output']>;
  /** Street address component */
  street?: Maybe<Scalars['String']['output']>;
  /** Street number address component */
  streetNumber?: Maybe<Scalars['String']['output']>;
  /** When related location cannot be resolved or created because of missing data from google_places, an error message is provided. */
  validationErrors: Array<IValidationError>;
}

export interface IGooglePlacesResult {
  __typename?: 'GooglePlacesResult';
  /** A HTML enriched string containing the name of the location */
  html: Scalars['String']['output'];
  /** The Google Place ID which is needed if you want to retrieve the details if this place */
  placeId: Scalars['String']['output'];
  /** Structured formatting of the suggested text */
  structuredText: IGooglePlacesStructuredText;
  /** A plain text string containing the name of the location */
  text: Scalars['String']['output'];
  /** The location type and icon */
  type?: Maybe<IGooglePlacesTypeValue>;
}

export interface IGooglePlacesStructuredText {
  __typename?: 'GooglePlacesStructuredText';
  /** Main text of the suggestion, usually the name of the place */
  main?: Maybe<Scalars['String']['output']>;
  /** Secondary text of the suggestion, usually the location of the place */
  secondary?: Maybe<Scalars['String']['output']>;
}

/** Google places type */
export interface IGooglePlacesTypeValue {
  __typename?: 'GooglePlacesTypeValue';
  /** Icon name for type */
  icon?: Maybe<IIconEnum>;
  /** The location type */
  value: IGooglePlacesTypesEnum;
}

/** Google places types enum */
export enum IGooglePlacesTypesEnum {
  AIRPORT = 'airport',
  BUS_STATION = 'bus_station',
  GAS_STATION = 'gas_station',
  LIGHT_RAIL_STATION = 'light_rail_station',
  PARKING = 'parking',
  SUBWAY_STATION = 'subway_station',
  TRAIN_STATION = 'train_station',
  UNKNOWN = 'unknown'
}

export interface IGreenwheels extends IAvailableFeatureInterface {
  __typename?: 'Greenwheels';
  /** Whether an employee can activate the greenwheels feature */
  activatable: Scalars['Boolean']['output'];
  /** Activation link for Greenwheels */
  activationLink?: Maybe<Scalars['String']['output']>;
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** List of countries whose driving licenses can be used for Greenwheels */
  driversLicenseCountries: Array<IDriversLicenseCountry>;
  /** URL to the Terms and Conditions document of Greenwheels. This is different then the terms_and_conditions document, which always comes from the organization the employee belongs to. */
  greenwheelsTermsAndConditionsUrl: Scalars['String']['output'];
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Whether an employee can register for greenwheels */
  registerable: Scalars['Boolean']['output'];
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export interface IGreenwheelsFleet {
  __typename?: 'GreenwheelsFleet';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
}

export interface IHow {
  __typename?: 'How';
  humanized: Scalars['String']['output'];
  icon?: Maybe<IIcon>;
  identity?: Maybe<IIdentity>;
  label: Scalars['String']['output'];
}

export interface IHowMuch {
  __typename?: 'HowMuch';
  /** @deprecated Field renamed to co2_emission */
  co2?: Maybe<IQuantity>;
  co2Emission?: Maybe<IQuantity>;
  consumption: IQuantity;
}

export interface IHsla {
  __typename?: 'Hsla';
  alpha: Scalars['Int']['output'];
  hue: Scalars['Int']['output'];
  lightness: Scalars['Int']['output'];
  saturation: Scalars['Int']['output'];
}

export enum IHttpMethod {
  CONNECT = 'CONNECT',
  DELETE = 'DELETE',
  GET = 'GET',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  TRACE = 'TRACE'
}

export interface IHub extends IMultiModalAvailability {
  __typename?: 'Hub';
  /** The city identifier for this hub */
  cityIdentifier?: Maybe<Scalars['String']['output']>;
  deeplink?: Maybe<Scalars['String']['output']>;
  geoLocation: ICoordinates;
  icon: IIcon;
  modality: IJourneyMode;
  /** Human readable name for this asset */
  name?: Maybe<Scalars['String']['output']>;
  /** The name of the operator for this hub */
  operator: Scalars['String']['output'];
  /** The amount of parking spaces available at this hub */
  parkingAvailable?: Maybe<Scalars['Int']['output']>;
  serviceOperator?: Maybe<IServiceOperator>;
  /** The hubId for this hub */
  stationId?: Maybe<Scalars['String']['output']>;
  /** The amount of assets available at this hub */
  totalAvailable: Scalars['Int']['output'];
  type: Scalars['String']['output'];
}

export interface IIcon {
  __typename?: 'Icon';
  name: IIconEnum;
}

export enum IIconEnum {
  AIRPORT = 'airport',
  ALL_MODALITIES = 'all_modalities',
  AMBER = 'amber',
  BEHAVIOR = 'behavior',
  BICYCLE = 'bicycle',
  BIKE = 'bike',
  BUDGET = 'budget',
  BUS = 'bus',
  CAR = 'car',
  DONKEY_REPUBLIC = 'donkey_republic',
  ELECTRIC_CAR = 'electric_car',
  ELECTRICITY = 'electricity',
  ENECO_DONGLE = 'eneco_dongle',
  FERRY = 'ferry',
  FUEL = 'fuel',
  LEASE_BIKE = 'lease_bike',
  LEGS = 'legs',
  LIGHT_RAIL_STATION = 'light_rail_station',
  LOCATION_ARROW = 'location_arrow',
  METRO = 'metro',
  MOTORCYCLE = 'motorcycle',
  OTHER_OV = 'other_ov',
  OV_CHIP = 'ov_chip',
  PARKING = 'parking',
  PUBLIC_TRANSPORT = 'public_transport',
  RECEIPT = 'receipt',
  REFUND = 'refund',
  REISBALANS = 'reisbalans',
  RENTAL_CAR = 'rental_car',
  SCOOTER = 'scooter',
  SHARED_VEHICLE = 'shared_vehicle',
  SHED = 'shed',
  SHUTTEL_CHECK = 'shuttel_check',
  SHUTTEL_MY_WHEELS = 'shuttel_my_wheels',
  SHUTTEL_SIXT = 'shuttel_sixt',
  SHUTTEL_TAXI = 'shuttel_taxi',
  SHUTTEL_TRANZER_ETICKETS = 'shuttel_tranzer_etickets',
  SHUTTEL_VECORE = 'shuttel_vecore',
  SUBWAY_STATION = 'subway_station',
  SURCHARGE = 'surcharge',
  TAXI = 'taxi',
  TRAIN = 'train',
  TRAM = 'tram',
  TRANSFER = 'transfer',
  TRANZER_ETICKETS = 'tranzer_etickets',
  UNKNOWN = 'unknown',
  WALKING = 'walking',
  YELLOWBRICK_SP = 'yellowbrick_sp'
}

export interface IIdentity {
  __typename?: 'Identity';
  humanized: Scalars['String']['output'];
}

/** image type */
export interface IImage {
  __typename?: 'Image';
  /** The image in BASE64 encoding */
  base64: Scalars['String']['output'];
  /** The content type of the image */
  contentType: Scalars['String']['output'];
  /** The image height in px */
  height: Scalars['Int']['output'];
  /** The content size in bytes */
  size: Scalars['String']['output'];
  /** The URI of the image */
  uri: Scalars['String']['output'];
  /** The image width in px */
  width: Scalars['Int']['output'];
}

export interface IInvoiceStatus {
  __typename?: 'InvoiceStatus';
  humanized: Scalars['String']['output'];
  value: IInvoiceStatusEnum;
}

export enum IInvoiceStatusEnum {
  FAILED = 'FAILED',
  PAID = 'PAID',
  SCHEDULED = 'SCHEDULED'
}

export interface IJournalEntry {
  __typename?: 'JournalEntry';
  amount: IMoney;
  calculation: Scalars['String']['output'];
  label: Scalars['String']['output'];
  moneyTransfer: IMoneyTransfer;
}

export interface IJourney {
  __typename?: 'Journey';
  arrivalTime: Scalars['DateTime']['output'];
  caloriesCost: Scalars['Int']['output'];
  co2Emission: Scalars['Int']['output'];
  departureTime: Scalars['DateTime']['output'];
  destination: IGeoLocation;
  duration?: Maybe<Scalars['Int']['output']>;
  /** Money amount with humanized value */
  estimatedPrice?: Maybe<IMoneyWithCurrency>;
  id: Scalars['ID']['output'];
  inconvenienceScore: Scalars['Int']['output'];
  journeyServiceOperatorIds: Array<Scalars['String']['output']>;
  journeyServiceOperators: Array<IServiceOperator>;
  legs: Array<IJourneyLeg>;
  origin: IGeoLocation;
}

export interface IJourneyLeg {
  __typename?: 'JourneyLeg';
  /** The agency for this specific leg */
  agency?: Maybe<Scalars['String']['output']>;
  /** The logo URL for the agency */
  agencyLogo?: Maybe<Scalars['String']['output']>;
  bookingId?: Maybe<Scalars['ID']['output']>;
  /** The expected crowdiness of this leg */
  crowdForecast?: Maybe<ICrowdForecastEnum>;
  /** The distance for this leg in meters */
  distance?: Maybe<Scalars['Int']['output']>;
  /** The duration for this leg in seconds */
  duration?: Maybe<Scalars['Int']['output']>;
  /** The platform at which the means of transport will arrive on the destination */
  endPlatform?: Maybe<Scalars['String']['output']>;
  endTime: Scalars['DateTime']['output'];
  fromLocation: IGeoLocation;
  /** A humanized string for any non-public transport leg */
  humanized?: Maybe<Scalars['String']['output']>;
  icon: IIcon;
  /** The identifier of the leg */
  id: Scalars['ID']['output'];
  /** The destination for this line */
  lineDirection?: Maybe<Scalars['String']['output']>;
  /** The line identifier for this leg */
  lineNumber?: Maybe<Scalars['String']['output']>;
  mode: IJourneyMode;
  /** The polyline for this specific leg, which can be used to draw the route on a map */
  polyline?: Maybe<Scalars['String']['output']>;
  quoteId?: Maybe<Scalars['ID']['output']>;
  /** The departure platform for this leg */
  startPlatform?: Maybe<Scalars['String']['output']>;
  startTime: Scalars['DateTime']['output'];
  toLocation: IGeoLocation;
  /** Array of waypoints of the decoded polyline */
  waypoints?: Maybe<Array<ICoordinates>>;
}

export interface IJourneyMode {
  __typename?: 'JourneyMode';
  humanized: Scalars['String']['output'];
  value: IJourneyModeEnum;
}

export enum IJourneyModeEnum {
  BIKE = 'bike',
  BUS = 'bus',
  CAR = 'car',
  FERRY = 'ferry',
  METRO = 'metro',
  SCOOTER = 'scooter',
  TAXI = 'taxi',
  TRAIN = 'train',
  TRAM = 'tram',
  TRANSFER = 'transfer',
  UNKNOWN = 'unknown',
  WALKING = 'walking'
}

export enum IJourneySortTypeEnum {
  CAL = 'cal',
  CO2 = 'co2',
  DISTANCE = 'distance',
  DURATION = 'duration',
  PRICE = 'price',
  TRANSFER_COUNT = 'transfer_count'
}

export interface IJourneySorter {
  /** The sort order for the specified type (low-high or high-low) */
  direction: IOrderEnum;
  type: IJourneySortTypeEnum;
}

export interface ILabel {
  __typename?: 'Label';
  humanized?: Maybe<Scalars['String']['output']>;
  value?: Maybe<ILabelEnum>;
}

export enum ILabelEnum {
  ACTIVE = 'ACTIVE',
  FUTURE = 'FUTURE',
  PAST = 'PAST'
}

export interface ILeaseBike extends IAvailableFeatureInterface {
  __typename?: 'LeaseBike';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  /**
   * This is only available when the request for this feature has been approved and the employee is allowed to order a lease bike
   * @deprecated Use the urls endpoint instead
   */
  url?: Maybe<Scalars['String']['output']>;
  urls?: Maybe<IFeatureUrls>;
}

export interface ILeaseBikeOrder {
  __typename?: 'LeaseBikeOrder';
  create?: Maybe<ICreateLeaseBikeOrderPayload>;
}


export interface ILeaseBikeOrderCreateArgs {
  input: ICreateLeaseBikeOrderInput;
}

export interface ILeaseCar {
  __typename?: 'LeaseCar';
  /** The amount of kilometers you made for personal purposes during the current month */
  personalKilometers: Scalars['Float']['output'];
  /** The amount of kilometers made for unaccounted purposes */
  totalUnaccountedKilometers: Scalars['Float']['output'];
}

export interface ILeg {
  __typename?: 'Leg';
  quantity: IQuantity;
  travelPurpose: ITravelPurpose;
}

export interface ILicensePlate {
  __typename?: 'LicensePlate';
  budgetChargeTotal?: Maybe<Scalars['Float']['output']>;
  licensePlate: Scalars['String']['output'];
  mileages?: Maybe<Array<IMileage>>;
  remainingKilometersTotal?: Maybe<Scalars['Float']['output']>;
}

export interface ILocation {
  __typename?: 'Location';
  coordinates?: Maybe<ICoordinates>;
  favoriteId?: Maybe<Scalars['ID']['output']>;
  humanized: Scalars['String']['output'];
  label: Scalars['String']['output'];
  locationId?: Maybe<Scalars['ID']['output']>;
}

export interface ILocationInput {
  lat: Scalars['Float']['input'];
  long: Scalars['Float']['input'];
  name: Scalars['String']['input'];
}

export interface ILocationMutation {
  coordinates: ICoordinatesMutation;
  favoriteId?: InputMaybe<Scalars['ID']['input']>;
  humanized: Scalars['String']['input'];
  locationId?: InputMaybe<Scalars['ID']['input']>;
}

export interface ILock {
  __typename?: 'Lock';
  bdAddress?: Maybe<Scalars['String']['output']>;
  deviceName?: Maybe<Scalars['String']['output']>;
}

/** Autogenerated input type of LockDonkeyRepublicAsset */
export interface ILockDonkeyRepublicAssetInput {
  bookingId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Coordinate']['input'];
  locationName: Scalars['String']['input'];
  longitude: Scalars['Coordinate']['input'];
}

/** Autogenerated return type of LockDonkeyRepublicAsset. */
export interface ILockDonkeyRepublicAssetPayload {
  __typename?: 'LockDonkeyRepublicAssetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  donkeyRepublicReservation?: Maybe<IDonkeyRepublicReservation>;
  donkeyRepublicResult?: Maybe<IDonkeyRepublicResult>;
  result: IResult;
}

/** Autogenerated input type of Login */
export interface ILoginInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Email of the account */
  email: Scalars['String']['input'];
  /** OTP token */
  otpToken?: InputMaybe<Scalars['String']['input']>;
  /** Password of the account */
  password: Scalars['String']['input'];
}

/** Autogenerated return type of Login. */
export interface ILoginPayload {
  __typename?: 'LoginPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Does the user have OTP configured? Use the ConfigureMfa mutation to configure OTP */
  hasOtpConfigured?: Maybe<Scalars['Boolean']['output']>;
  /** The JWT Token that can be used as authentication */
  jwt?: Maybe<Scalars['String']['output']>;
  /** If true, this means that the password validates, but OTP is required and the user can only login with a valid OTP token */
  otpRequired?: Maybe<Scalars['Boolean']['output']>;
  result: IResult;
  /** Token that can be used as authentication */
  token?: Maybe<Scalars['String']['output']>;
  tokens?: Maybe<IAuth>;
}

/** Autogenerated input type of Logout */
export interface ILogoutInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of Logout. */
export interface ILogoutPayload {
  __typename?: 'LogoutPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
}

export interface IMsaAbstractProperty {
  __typename?: 'MSAAbstractProperty';
  /** Name of the property. */
  key: Scalars['String']['output'];
  /** Value of the property. */
  value: Scalars['String']['output'];
}

export enum IMsaAdminAction {
  FIATING = 'FIATING'
}

export interface IMsaCarrier {
  __typename?: 'MSACarrier';
  /** The available admin actions for the current status the carrier is in. */
  adminActions?: Maybe<Array<Maybe<IMsaAdminAction>>>;
  /** Product group identifier */
  carrierId: Scalars['UUID']['output'];
  /** Product group identifier */
  productGroupId: Scalars['UUID']['output'];
  /** Properties associated with the carrier. */
  properties?: Maybe<Array<Maybe<IMsaAbstractProperty>>>;
  provider: IMsaProviderType;
  status?: Maybe<IMsaExternalStatus>;
  statusCategory: IMsaStatusCategory;
  /** The available traveler actions for the current status the carrier is in. */
  travelerActions?: Maybe<Array<Maybe<IMsaTravelerAction>>>;
  /** The name of the type */
  type: Scalars['String']['output'];
}

/** Create carrier request body */
export interface IMsaCreateCarrierRequestBodyRequestInput {
  /** The type of the carrier */
  carrierType: Scalars['String']['input'];
  /** Product group identifier */
  productGroupId: Scalars['UUID']['input'];
  properties?: InputMaybe<IMsaPropertiesInput>;
}

/** Create product group request body */
export interface IMsaCreateProductGroupRequestBodyRequestInput {
  /** Product group identifier */
  organizationalUnitId: Scalars['UUID']['input'];
}

/** Create product request body */
export interface IMsaCreateProductRequestBodyRequestInput {
  /** Product group identifier */
  productGroupId: Scalars['UUID']['input'];
  /** The type of the product */
  productType: Scalars['String']['input'];
  properties?: InputMaybe<IMsaPropertiesInput>;
}

export enum IMsaExternalStatus {
  AWAITING_FIAT = 'AWAITING_FIAT',
  DEACTIVATED = 'DEACTIVATED',
  INITIAL = 'INITIAL',
  IN_USE = 'IN_USE',
  ORDERING = 'ORDERING'
}

export interface IMsaProduct {
  __typename?: 'MSAProduct';
  /** The available admin actions for the current status the product is in. */
  adminActions?: Maybe<Array<Maybe<IMsaAdminAction>>>;
  /** Product group identifier */
  productGroupId: Scalars['UUID']['output'];
  /** Product group identifier */
  productId: Scalars['UUID']['output'];
  /** Properties associated with the carrier. */
  properties?: Maybe<Array<Maybe<IMsaAbstractProperty>>>;
  provider: IMsaProviderType;
  status?: Maybe<IMsaExternalStatus>;
  statusCategory: IMsaStatusCategory;
  /** The available traveler actions for the current status the product is in. */
  travelerActions?: Maybe<Array<Maybe<IMsaTravelerAction>>>;
  /** The name of the type */
  type?: Maybe<Scalars['String']['output']>;
}

export interface IMsaProductGroup {
  __typename?: 'MSAProductGroup';
  /** Carriers inside this group */
  carriers: Array<Maybe<IMsaCarrier>>;
  /** Product group identifier */
  organizationalUnitId: Scalars['UUID']['output'];
  organizationalUnitUuid: Scalars['JSON']['output'];
  /** Product group identifier */
  productGroupId: Scalars['UUID']['output'];
  /** Products inside this group */
  products: Array<Maybe<IMsaProduct>>;
}

/** The properties for the entity */
export interface IMsaPropertiesInput {
  key?: InputMaybe<Scalars['String']['input']>;
}

/** A binding between a generic PropertyType and a Carrier or Product. */
export interface IMsaPropertyTypeBinding {
  __typename?: 'MSAPropertyTypeBinding';
  constraints: Array<Maybe<IMsaPropertyTypeConstraint>>;
  /** The property type name */
  name: Scalars['String']['output'];
  /** Indicates if a property type is required when requesting a product or carrier. */
  required: Scalars['Boolean']['output'];
}

export interface IMsaPropertyTypeConstraint {
  __typename?: 'MSAPropertyTypeConstraint';
  /** The constraint type, used to identify the type of constaint. One of Symfony's Validation Constraints. */
  constraint: Scalars['String']['output'];
  rules: IMsaQueryCarrierTypePropertiesListItemsConstraintsItemsRules;
}

export enum IMsaProviderType {
  AMBER = 'AMBER',
  ENECO = 'ENECO',
  GREENWHEELS = 'GREENWHEELS',
  NS = 'NS',
  OVFIETS = 'OVFIETS',
  TLS = 'TLS',
  TRANSVISION = 'TRANSVISION',
  TRANZER = 'TRANZER',
  TRAVELCARD = 'TRAVELCARD',
  VECORE = 'VECORE',
  YELLOWBRICK = 'YELLOWBRICK'
}

/** A list of rules to define the constraint. */
export interface IMsaQueryCarrierTypePropertiesListItemsConstraintsItemsRules {
  __typename?: 'MSAQueryCarrierTypePropertiesListItemsConstraintsItemsRules';
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
}

/** Replace properties on a carrier. */
export interface IMsaReplaceCarrierPropertiesRequestBodyRequestInput {
  properties: IMsaPropertiesInput;
}

/** Replace carrier request body */
export interface IMsaReplaceCarrierRequestBodyRequestInput {
  /** Product group identifier */
  carrierId: Scalars['UUID']['input'];
}

/** Replace properties on a product. */
export interface IMsaReplaceProductPropertiesRequestBodyRequestInput {
  properties: IMsaPropertiesInput;
}

export enum IMsaStatusCategory {
  ACTIVATING = 'ACTIVATING',
  ACTIVE = 'ACTIVE',
  DEACTIVATING = 'DEACTIVATING',
  INACTIVE = 'INACTIVE',
  NEW = 'NEW'
}

/** Returns if product type is supported by product group */
export interface IMsaSupportedCarriersForProductTypeList {
  __typename?: 'MSASupportedCarriersForProductTypeList';
  /** List of compatible carrier types */
  compatibleCarrierTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** List of compatible carrier ids */
  existingCompatibleCarriers?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  /** Status */
  ok?: Maybe<Scalars['Boolean']['output']>;
}

export enum IMsaTravelerAction {
  SET_DEFECTIVE = 'SET_DEFECTIVE',
  SET_STOLEN = 'SET_STOLEN'
}

export interface IMainCategory {
  __typename?: 'MainCategory';
  humanized: Scalars['String']['output'];
  subCategories: Array<ISubCategory>;
  value: Scalars['String']['output'];
}

export enum IMainCategoryEnum {
  CONTRACT = 'contract',
  CORRECTION = 'correction',
  DAY = 'day',
  DEPOSIT = 'deposit',
  E_TICKET = 'e_ticket',
  FINE = 'fine',
  FUEL = 'fuel',
  ISSUANCE = 'issuance',
  MAINTENANCE = 'maintenance',
  OTHER = 'other',
  PARKING = 'parking',
  PUBLIC_TRANSPORT_SUBSCRIPTION = 'public_transport_subscription',
  REFUND = 'refund',
  RELOAD = 'reload',
  RENTAL = 'rental',
  REPLACEMENT = 'replacement',
  RIDE = 'ride',
  SETTLEMENT = 'settlement',
  SURCHARGE = 'surcharge',
  USER_FEE = 'user_fee'
}

export interface IMandateActions {
  __typename?: 'MandateActions';
  acceptable: Scalars['Boolean']['output'];
  revokable: Scalars['Boolean']['output'];
}

export interface IMandateStatus {
  __typename?: 'MandateStatus';
  humanized: Scalars['String']['output'];
  value: IMandateStatusEnum;
}

export enum IMandateStatusEnum {
  ACCEPTED = 'ACCEPTED',
  CUSTOMER_REGISTERED = 'CUSTOMER_REGISTERED',
  REVOKED = 'REVOKED',
  SUBSCRIPTION_ADDED = 'SUBSCRIPTION_ADDED'
}

export enum IMandateStatusFilterEnum {
  ACCEPTED = 'ACCEPTED',
  ACTIVE = 'ACTIVE',
  CUSTOMER_REGISTERED = 'CUSTOMER_REGISTERED',
  INACTIVE = 'INACTIVE',
  REVOKED = 'REVOKED',
  SUBSCRIPTION_ADDED = 'SUBSCRIPTION_ADDED'
}

/** Autogenerated input type of MarkMessageAsRead */
export interface IMarkMessageAsReadInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  messageId: Scalars['ID']['input'];
}

/** Autogenerated return type of MarkMessageAsRead. */
export interface IMarkMessageAsReadPayload {
  __typename?: 'MarkMessageAsReadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
}

export interface IMeanOfTransportFiscality {
  __typename?: 'MeanOfTransportFiscality';
  humanized: Scalars['String']['output'];
  value: IFiscalityEnum;
}

/** MeansOfTransport is used as a type that could return either a vehicle or a modality. Also, it is used internally as a subclass of the VehiclePresenter. */
export interface IMeansOfTransport {
  __typename?: 'MeansOfTransport';
  /** Actions that can be executed on this vehicle */
  actions: IVehicleActions;
  budgetCostPerKilometer?: Maybe<Scalars['Float']['output']>;
  budgetCostPerMonth?: Maybe<Scalars['Float']['output']>;
  budgetGroupFuelConsumptionNorm?: Maybe<Scalars['Float']['output']>;
  co2EmissionPerKilometer: Scalars['Int']['output'];
  /** Date until when this vehicle is / was available to the user */
  endsOn?: Maybe<Scalars['Date']['output']>;
  /**
   * Whether this vehicle is fiscally reimbursed or supplied
   * @deprecated use meansOfTransportFiscality please
   */
  fiscality: IFiscalityEnum;
  /** @deprecated Please interface with the vehicleInformation field for fuelType */
  fuelType?: Maybe<Scalars['String']['output']>;
  /** Humanized description of this vehicle based on the available fields */
  humanized: Scalars['String']['output'];
  /** Icon of this vehicle */
  icon?: Maybe<IIcon>;
  /** Identifier of this vehicle */
  id: Scalars['ID']['output'];
  /**
   * Static label that is a Dutch translation for "Vehicle"
   * @deprecated Frontends should be in control of displaying labels with their translations
   */
  label: Scalars['String']['output'];
  /** License plate of this vehicle */
  licensePlate?: Maybe<Scalars['String']['output']>;
  /** Whether this vehicle is fiscally reimbursed or supplied */
  meansOfTransportFiscality: IMeanOfTransportFiscality;
  /** Modality this vehicle belongs to */
  modality: IModality;
  /** Name for this vehicle as given by the user  */
  name?: Maybe<Scalars['String']['output']>;
  /** Date from when this vehicle is available to the user */
  startsOn: Scalars['Date']['output'];
  title?: Maybe<Scalars['String']['output']>;
  /** Specific information about this vehicle, fetched from RDW */
  vehicleInformation?: Maybe<IVehicleInformation>;
}

export interface IMedia {
  __typename?: 'Media';
  humanized: Scalars['String']['output'];
  value: IMediaTypeEnum;
}

export interface IMediaSide {
  __typename?: 'MediaSide';
  /** The file extension of the side of the graphic. */
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
}

export enum IMediaTypeEnum {
  GRAPHIC = 'GRAPHIC',
  PDF = 'PDF'
}

/** Autogenerated input type of MergeMobilityEvents */
export interface IMergeMobilityEventsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityEventIds: Array<Scalars['ID']['input']>;
}

/** Autogenerated return type of MergeMobilityEvents. */
export interface IMergeMobilityEventsPayload {
  __typename?: 'MergeMobilityEventsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  day?: Maybe<IDayWithMobilityEvents>;
  mergedMobilityEvent?: Maybe<IMobilityEvent>;
  result: IResult;
}

export interface IMergedMobilityEventsPreview {
  __typename?: 'MergedMobilityEventsPreview';
  /** Possible error message */
  error?: Maybe<Scalars['String']['output']>;
  /** The merged mobility event */
  mobilityEventPreview?: Maybe<IMobilityEventPreview>;
}

export interface IMessage {
  __typename?: 'Message';
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
}

export enum IMessageKeyEnum {
  ALL = 'ALL',
  MOBILITY_CARD_ORDER = 'MOBILITY_CARD_ORDER'
}

export interface IMileage {
  __typename?: 'Mileage';
  amountInKilometers: Scalars['Int']['output'];
  budgetCharge?: Maybe<Scalars['Float']['output']>;
  budgetCostPerKilometer?: Maybe<Scalars['Float']['output']>;
  hasUnaccountedDistance: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  licensePlate: Scalars['String']['output'];
  mobilityEventId?: Maybe<Scalars['ID']['output']>;
  recordedAt: Scalars['DateTime']['output'];
  remainingKilometers?: Maybe<Scalars['Float']['output']>;
  supportsUnaccountedDistances: Scalars['Boolean']['output'];
}

export interface IMileages {
  __typename?: 'Mileages';
  create?: Maybe<ICreateMileagePayload>;
  update?: Maybe<IUpdateMileagePayload>;
}


export interface IMileagesCreateArgs {
  input: ICreateMileageInput;
}


export interface IMileagesUpdateArgs {
  input: IUpdateMileageInput;
}

export interface IMobilityCardOrder {
  __typename?: 'MobilityCardOrder';
  /** Actions that can be executed on this mobility card order */
  actions: IMobilityCardOrderActions;
  budgetGroupRequiresPincode?: Maybe<Scalars['Boolean']['output']>;
  /** DateTime at which this mobility card order was completed */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The employment that this mobility card order belongs to */
  employment?: Maybe<IEmployment>;
  firstClassReason?: Maybe<IFirstClassReason>;
  fromStation?: Maybe<Scalars['String']['output']>;
  hasPincodeBeenEntered?: Maybe<Scalars['Boolean']['output']>;
  /** Humanized value for subscription */
  humanizedSubscription?: Maybe<ISubscription>;
  id: Scalars['ID']['output'];
  /** Whether the mobilityCardOrder has been delivered after the order. */
  isDelivered?: Maybe<Scalars['Boolean']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  needsApproval?: Maybe<Scalars['Boolean']['output']>;
  /** The url pointing to the photo that has been uploaded if there is one */
  passportPhoto?: Maybe<Scalars['String']['output']>;
  /** Permissions for if the steps in the wizard can be displayed */
  permission?: Maybe<IMobilityCardOrderPermission>;
  recommendedCard?: Maybe<IRecommendedCard>;
  /** List of all requestable services */
  requestableServices?: Maybe<Array<IRequestableService>>;
  /** List of all requestable subscriptions */
  requestableSubscriptions?: Maybe<Array<IAvailableSubscription>>;
  requiredTermsAndConditionsDocuments?: Maybe<Array<ITermsAndConditionsDocument>>;
  services?: Maybe<Array<IMobilityCardOrderService>>;
  starValue?: Maybe<IStarValue>;
  status?: Maybe<IMobilityCardOrderStatus>;
  /** @deprecated Deprecated due to introduction of humanized_subscription */
  subscription?: Maybe<ISubscriptionEnum>;
  toStation?: Maybe<Scalars['String']['output']>;
  trainClass?: Maybe<ITrainClass>;
  trainClassStatus?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  zone?: Maybe<IZone>;
}

export interface IMobilityCardOrderActions {
  __typename?: 'MobilityCardOrderActions';
  isEditable: Scalars['Boolean']['output'];
}

export interface IMobilityCardOrderPermission {
  __typename?: 'MobilityCardOrderPermission';
  canRequestOptions?: Maybe<Scalars['Boolean']['output']>;
  canRequestPhoto?: Maybe<Scalars['Boolean']['output']>;
}

export interface IMobilityCardOrderService {
  __typename?: 'MobilityCardOrderService';
  /** Humanized and translated service */
  humanized: Scalars['String']['output'];
  /** Raw value of the service */
  value: IPersonalMeansOfRegistrationServiceKindSubEnum;
}

export interface IMobilityCardOrderStatus {
  __typename?: 'MobilityCardOrderStatus';
  /** Humanized and translated status */
  humanized: Scalars['String']['output'];
  /** Raw value of the status */
  value: IMobilityCardOrderStatusEnum;
}

export enum IMobilityCardOrderStatusEnum {
  APPROVED = 'APPROVED',
  DRAFT = 'DRAFT',
  NOT_REQUESTED = 'NOT_REQUESTED',
  ORDERED = 'ORDERED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  REJECTED = 'REJECTED',
  REQUEST_FAILED = 'REQUEST_FAILED'
}

export interface IMobilityCardOrders {
  __typename?: 'MobilityCardOrders';
  confirm?: Maybe<IConfirmPayload>;
  create?: Maybe<ICreateMobilityCardOrderPayload>;
  setOptions?: Maybe<ISetOptionsPayload>;
  setPhoto?: Maybe<ISetPhotoPayload>;
  setProfile?: Maybe<ISetProfilePayload>;
  setTerms?: Maybe<ISetTermsPayload>;
}


export interface IMobilityCardOrdersConfirmArgs {
  input: IConfirmInput;
}


export interface IMobilityCardOrdersCreateArgs {
  input: ICreateMobilityCardOrderInput;
}


export interface IMobilityCardOrdersSetOptionsArgs {
  input: ISetOptionsInput;
}


export interface IMobilityCardOrdersSetPhotoArgs {
  input: ISetPhotoInput;
}


export interface IMobilityCardOrdersSetProfileArgs {
  input: ISetProfileInput;
}


export interface IMobilityCardOrdersSetTermsArgs {
  input: ISetTermsInput;
}

export interface IMobilityEvent {
  __typename?: 'MobilityEvent';
  actions: IActions;
  allowPersonalConsumption: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The employment that this mobility event belongs to */
  employment?: Maybe<IEmployment>;
  financial: IFinancial;
  fiscality?: Maybe<IFiscalityEnum>;
  happenedAt: Scalars['DateTime']['output'];
  happenedInTheFuture: Scalars['Boolean']['output'];
  how: IHow;
  howMuch?: Maybe<IHowMuch>;
  id: Scalars['ID']['output'];
  /** Whether this mobility event is a refund */
  isRefund: Scalars['Boolean']['output'];
  needsApproval: Scalars['Boolean']['output'];
  /** Required fields of mobility event */
  requiredFields: Array<Scalars['String']['output']>;
  signatures?: Maybe<Array<ISignature>>;
  status: IStatus;
  what: IWhat;
  /** @deprecated Remove distinction between expense and ride. This is going to disappear. */
  whatOption?: Maybe<IWhatOption>;
  when: IWhen;
  where?: Maybe<IWhere>;
  who: IEmployee;
  why?: Maybe<IWhy>;
}

export interface IMobilityEventAggregate {
  __typename?: 'MobilityEventAggregate';
  count: Scalars['Int']['output'];
}

export interface IMobilityEventByDate {
  __typename?: 'MobilityEventByDate';
  /** Date on which these mobilityEvents happened */
  date: Scalars['Date']['output'];
  /** MobilityEvents for this date */
  mobilityEvents: Array<IMobilityEvent>;
}

/** Mobility event form validation */
export interface IMobilityEventFormValidations {
  __typename?: 'MobilityEventFormValidations';
  /** Show the warning which says the user has entered more than 5 wfh compensations this week */
  showWarningForOver5WfhThisWeek?: Maybe<Scalars['Boolean']['output']>;
  /** Show the warning which says the user has entered a walk or bike ride of over 40 km */
  showWarningForOver40KmWalkOrBike?: Maybe<Scalars['Boolean']['output']>;
  /** Show the warning which says the user has entered a wfh compensation on a holiday */
  showWarningForWfhOnHoliday?: Maybe<Scalars['Boolean']['output']>;
  /** Show the warning which says the user has entered a wfh compensation on a weekend */
  showWarningForWfhOnWeekend?: Maybe<Scalars['Boolean']['output']>;
  /** Show the warning which says the user has entered a wfh compensation on a weekend or holiday */
  showWarningForWfhOnWeekendOrHoliday?: Maybe<Scalars['Boolean']['output']>;
}

export interface IMobilityEventPreview {
  __typename?: 'MobilityEventPreview';
  actions: IActions;
  allowPersonalConsumption: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The employment that this mobility event belongs to */
  employment?: Maybe<IEmployment>;
  financial: IFinancial;
  fiscality?: Maybe<IFiscalityEnum>;
  happenedAt: Scalars['DateTime']['output'];
  happenedInTheFuture: Scalars['Boolean']['output'];
  how: IHow;
  howMuch?: Maybe<IHowMuch>;
  /** Since a mobility event preview might not have been stored yet, it can be null */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether this mobility event is a refund */
  isRefund: Scalars['Boolean']['output'];
  needsApproval: Scalars['Boolean']['output'];
  /** Required fields of mobility event */
  requiredFields: Array<Scalars['String']['output']>;
  signatures?: Maybe<Array<ISignature>>;
  status: IStatus;
  what: IWhat;
  /** @deprecated Remove distinction between expense and ride. This is going to disappear. */
  whatOption?: Maybe<IWhatOption>;
  when: IWhen;
  where?: Maybe<IWhere>;
  who: IEmployee;
  why?: Maybe<IWhy>;
}

export interface IMobilityEventTotals {
  __typename?: 'MobilityEventTotals';
  /** All totals */
  all: ITotals;
  /** Business totals */
  business: ITotals;
  /** Commute totals */
  commute: ITotals;
  /** Work from home totals */
  workFromHome: ITotals;
}

export interface IMobilityEventsWeek extends ITask {
  __typename?: 'MobilityEventsWeek';
  date?: Maybe<Scalars['Date']['output']>;
  /** Humanized value for this task type. */
  humanized: Scalars['String']['output'];
  /** Contains actionable information about the task. */
  message: Scalars['String']['output'];
  /**
   * The path you can use for navigation
   * @deprecated Frontend uses hash params instead of url params, we dont want the backend to keep track of the frontends URLs. Use detailed fields on each task.
   */
  path?: Maybe<Scalars['String']['output']>;
  /** What kind of tasks type we are handling */
  type: ITaskEnum;
}

export interface IMobilityServicesMutation {
  __typename?: 'MobilityServicesMutation';
  carrierCreate?: Maybe<IMsaCarrier>;
  carrierReplace?: Maybe<IMsaCarrier>;
  carrierUpdate?: Maybe<IMsaCarrier>;
  productCreate?: Maybe<IMsaProduct>;
  productGroupCreate?: Maybe<IMsaProductGroup>;
  productUpdate?: Maybe<IMsaProduct>;
}


export interface IMobilityServicesMutationCarrierCreateArgs {
  input?: InputMaybe<IMsaCreateCarrierRequestBodyRequestInput>;
}


export interface IMobilityServicesMutationCarrierReplaceArgs {
  input?: InputMaybe<IMsaReplaceCarrierRequestBodyRequestInput>;
}


export interface IMobilityServicesMutationCarrierUpdateArgs {
  carrierId: Scalars['String']['input'];
  input?: InputMaybe<IMsaReplaceCarrierPropertiesRequestBodyRequestInput>;
}


export interface IMobilityServicesMutationProductCreateArgs {
  input?: InputMaybe<IMsaCreateProductRequestBodyRequestInput>;
}


export interface IMobilityServicesMutationProductGroupCreateArgs {
  input?: InputMaybe<IMsaCreateProductGroupRequestBodyRequestInput>;
}


export interface IMobilityServicesMutationProductUpdateArgs {
  input?: InputMaybe<IMsaReplaceProductPropertiesRequestBodyRequestInput>;
  productId: Scalars['String']['input'];
}

export interface IMobilityServicesQuery {
  __typename?: 'MobilityServicesQuery';
  carrierGet?: Maybe<IMsaCarrier>;
  carrierTypePropertiesList?: Maybe<Array<Maybe<IMsaPropertyTypeBinding>>>;
  productGet?: Maybe<IMsaProduct>;
  productGroupGet?: Maybe<IMsaProductGroup>;
  productTypePropertiesList?: Maybe<Array<Maybe<IMsaPropertyTypeBinding>>>;
  productTypeSupportedCarriers?: Maybe<IMsaSupportedCarriersForProductTypeList>;
}


export interface IMobilityServicesQueryCarrierGetArgs {
  carrierId: Scalars['UUID']['input'];
}


export interface IMobilityServicesQueryCarrierTypePropertiesListArgs {
  carrierType: Scalars['String']['input'];
}


export interface IMobilityServicesQueryProductGetArgs {
  productId: Scalars['UUID']['input'];
}


export interface IMobilityServicesQueryProductGroupGetArgs {
  productGroupId: Scalars['UUID']['input'];
}


export interface IMobilityServicesQueryProductTypePropertiesListArgs {
  productType: Scalars['String']['input'];
}


export interface IMobilityServicesQueryProductTypeSupportedCarriersArgs {
  productGroupId: Scalars['UUID']['input'];
  productType: Scalars['String']['input'];
}

export interface IModality {
  __typename?: 'Modality';
  /** in case of a train this relays if its first or second class, in case of ride_sharing this relays the amount of passengers required */
  clazz?: Maybe<Scalars['String']['output']>;
  /** A translated and humanized version of the modality */
  humanized: Scalars['String']['output'];
  /** A translated and humanized version of the modality starting with a capital */
  humanizedWithCapital: Scalars['String']['output'];
  /** The database id of this record */
  id: Scalars['ID']['output'];
  /** Relays the kind of modality, which might be a car, bike, train and so on */
  kind: IModalityKindEnum;
  /** Who owns the modality, values like personal, employer, ride_sharing, etc */
  modalityOwnership?: Maybe<IModalityOwnership>;
  /**
   * Who owns the modality, values like personal, employer, ride_sharing, etc
   * @deprecated use modalityOwnership please
   */
  ownership?: Maybe<Scalars['String']['output']>;
  /** The required amount of passengers for this modality */
  requiredPassengerCount: Scalars['Int']['output'];
  /** Whether or not this modality requires a vehicle */
  requiresVehicle: Scalars['Boolean']['output'];
  /** The mode of traval, might be something like driving, walking or bicycling */
  travelMode: Scalars['String']['output'];
}

export enum IModalityKindEnum {
  ALL_MODALITIES = 'all_modalities',
  BICYCLE = 'bicycle',
  BUS = 'bus',
  CAR = 'car',
  E_BICYCLE = 'e_bicycle',
  E_SCOOTER = 'e_scooter',
  ELECTRIC_CAR = 'electric_car',
  FERRY = 'ferry',
  INTERNATIONAL_TRAIN = 'international_train',
  LEGS = 'legs',
  METRO = 'metro',
  MOTORCYCLE = 'motorcycle',
  OTHER = 'other',
  PRIVATE_OTHER = 'private_other',
  SCOOTER = 'scooter',
  TAXI = 'taxi',
  TRAIN = 'train',
  TRAM = 'tram'
}

export interface IModalityOwnership {
  __typename?: 'ModalityOwnership';
  humanized: Scalars['String']['output'];
  value: IOwnershipEnum;
}

export enum IModalityPrivateVehicleEnum {
  PRIVATE_CAR = 'private_car',
  PRIVATE_ELECTRIC_CAR = 'private_electric_car',
  PRIVATE_MOTORCYCLE = 'private_motorcycle'
}

export interface IMoney {
  __typename?: 'Money';
  humanized: Scalars['String']['output'];
  value: Scalars['Float']['output'];
  valueInCents: Scalars['Int']['output'];
}

export interface IMoneyTransfer {
  __typename?: 'MoneyTransfer';
  direction: IDirectionEnum;
  humanized: Scalars['String']['output'];
}

export interface IMoneyWithCurrency {
  __typename?: 'MoneyWithCurrency';
  currency: Scalars['String']['output'];
  humanized: Scalars['String']['output'];
  value: Scalars['Float']['output'];
  valueInCents: Scalars['Int']['output'];
}

export interface IMultiModalAvailability {
  deeplink?: Maybe<Scalars['String']['output']>;
  geoLocation: ICoordinates;
  icon: IIcon;
  modality: IJourneyMode;
  /** Human readable name for this asset */
  name?: Maybe<Scalars['String']['output']>;
  serviceOperator?: Maybe<IServiceOperator>;
  type: Scalars['String']['output'];
}

export interface IMutation {
  __typename?: 'Mutation';
  MobilityServices: IMobilityServicesMutation;
  reisbalans: IReisbalansMutation;
}

export interface INsRefundUrl {
  __typename?: 'NSRefundUrl';
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
}

export interface INewProduct extends ITask {
  __typename?: 'NewProduct';
  /** Humanized value for this task type. */
  humanized: Scalars['String']['output'];
  /** Contains actionable information about the task. */
  message: Scalars['String']['output'];
  /**
   * The path you can use for navigation
   * @deprecated Frontend uses hash params instead of url params, we dont want the backend to keep track of the frontends URLs. Use detailed fields on each task.
   */
  path?: Maybe<Scalars['String']['output']>;
  /** What kind of tasks type we are handling */
  type: ITaskEnum;
}

export interface INonCombined {
  __typename?: 'NonCombined';
  humanized: Scalars['String']['output'];
  label: Scalars['String']['output'];
  value: Scalars['Boolean']['output'];
}

export interface IOpeningHours {
  __typename?: 'OpeningHours';
  dayOfWeek?: Maybe<Scalars['Int']['output']>;
  endTime?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
}

export interface IOptionsMutation {
  firstClassReason?: InputMaybe<IFirstClassReasonEnum>;
  fromStation?: InputMaybe<Scalars['String']['input']>;
  motivation?: InputMaybe<Scalars['String']['input']>;
  pincode?: InputMaybe<Scalars['String']['input']>;
  services?: InputMaybe<Array<IPersonalMeansOfRegistrationServiceKindSubEnum>>;
  starValue?: InputMaybe<Scalars['String']['input']>;
  subscription?: InputMaybe<ISubscriptionEnum>;
  toStation?: InputMaybe<Scalars['String']['input']>;
  trainClass?: InputMaybe<ITrainClassEnum>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

export enum IOrderEnum {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface IOrganization {
  __typename?: 'Organization';
  budgetGroup?: Maybe<IBudgetGroup>;
  /** Customer service details for this organization */
  customerService: ICustomerService;
  distancePrecision?: Maybe<Scalars['Int']['output']>;
  /** Optional external client number to be used for Driven */
  externalClientNumber?: Maybe<Scalars['String']['output']>;
  /** Whether a final settlement is running for this organization */
  finalSettlementRunning?: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether there are active projects on the given date */
  hasActiveProjects?: Maybe<Scalars['Boolean']['output']>;
  /** The url (or host-relative path) of the icon for this organization */
  icon?: Maybe<Scalars['String']['output']>;
  /** Unique ID of the Organization */
  id: Scalars['ID']['output'];
  /** The url (or host-relative path) of the logo for this organization */
  logo?: Maybe<Scalars['String']['output']>;
  /** Whether postal codes are mandatory within this organization. */
  mandatoryLocationPostalCode?: Maybe<Scalars['Boolean']['output']>;
  minPasswordLength?: Maybe<Scalars['Int']['output']>;
  /** Name of the Organization */
  name: Scalars['String']['output'];
  /** The url that points to the policy document for this organization */
  policyDocumentUrl?: Maybe<Scalars['String']['output']>;
  /** The primary color of the organization */
  primaryColor?: Maybe<IColor>;
  /** Returns the active projects on the given date (the filter argument is deprecated, use on instead) */
  projects?: Maybe<Array<Maybe<IProject>>>;
  /** The secondary color of the organization */
  secondaryColor?: Maybe<IColor>;
  /**
   * The email address of the service desk
   * @deprecated Use fields from customerService instead
   */
  serviceEmailAddress: Scalars['String']['output'];
  /**
   * The url of the service desk
   * @deprecated Use fields from customerService instead
   */
  serviceHelpUrl?: Maybe<Scalars['String']['output']>;
  /**
   * The alias for the url service desk
   * @deprecated Use fields from customerService instead
   */
  serviceHelpUrlName: Scalars['String']['output'];
  /**
   * The phone number of the service desk
   * @deprecated Use fields from customerService instead
   */
  serviceTelephone: Scalars['String']['output'];
  /** The uuid of this organization */
  uuid?: Maybe<Scalars['String']['output']>;
}


export interface IOrganizationBudgetGroupArgs {
  id: Scalars['ID']['input'];
}


export interface IOrganizationHasActiveProjectsArgs {
  on?: InputMaybe<Scalars['Date']['input']>;
}


export interface IOrganizationProjectsArgs {
  filter?: InputMaybe<IProjectScope>;
  on?: InputMaybe<Scalars['Date']['input']>;
  textFilter?: InputMaybe<Scalars['String']['input']>;
}

export interface IOvFietsLocation {
  __typename?: 'OvFietsLocation';
  address?: Maybe<IStreetAddress>;
  availableBikes?: Maybe<Scalars['Int']['output']>;
  coordinates: ICoordinates;
  distance: Scalars['Float']['output'];
  name?: Maybe<Scalars['String']['output']>;
  open?: Maybe<Scalars['Boolean']['output']>;
  openingHours?: Maybe<Array<Maybe<IOpeningHours>>>;
  stationCode?: Maybe<Scalars['String']['output']>;
}

export interface IOvPas {
  __typename?: 'OvPas';
  allowedOvPasTypes: Array<IOvPasEnum>;
  /** Check if approval is required for the specified OV-pas type */
  approvalRequired: Scalars['Boolean']['output'];
}


export interface IOvPasApprovalRequiredArgs {
  type: IOvPasEnum;
}

export enum IOvPasEnum {
  OV_PAS_ANONYMOUS = 'OV_PAS_ANONYMOUS',
  OV_PAS_PERSONAL = 'OV_PAS_PERSONAL',
  OV_PAY = 'OV_PAY'
}

export enum IOwnershipEnum {
  COMMUNAL = 'COMMUNAL',
  COMPANY = 'COMPANY',
  EMPLOYER = 'EMPLOYER',
  LEASE = 'LEASE',
  PERSONAL = 'PERSONAL',
  RENTAL = 'RENTAL',
  REPLACEMENT = 'REPLACEMENT',
  RIDE_SHARING = 'RIDE_SHARING',
  SHARED = 'SHARED'
}

export interface IParking {
  __typename?: 'Parking';
  create?: Maybe<ICreateParkingOrderPayload>;
}


export interface IParkingCreateArgs {
  input: ICreateParkingOrderInput;
}

export interface IParkingOrder {
  __typename?: 'ParkingOrder';
  /** Actions that can be executed on this order */
  actions: IParkingOrderActions;
  /** DateTime at which this order was approved */
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  /** DateTime at which this order was completed */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Motivation given when this order was created */
  motivation?: Maybe<Scalars['String']['output']>;
  /** Status of this order */
  status: IParkingOrderStatus;
}

export interface IParkingOrderActions {
  __typename?: 'ParkingOrderActions';
  /** Whether this order is editable */
  editable: Scalars['Boolean']['output'];
}

export interface IParkingOrderStatus {
  __typename?: 'ParkingOrderStatus';
  /** Humanized and translated status */
  humanized: Scalars['String']['output'];
  /** Raw value of the status */
  value: IParkingOrderStatusEnum;
}

export enum IParkingOrderStatusEnum {
  APPROVED = 'APPROVED',
  DRAFT = 'DRAFT',
  NOT_REQUESTED = 'NOT_REQUESTED',
  ORDERED = 'ORDERED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  REJECTED = 'REJECTED',
  REQUEST_FAILED = 'REQUEST_FAILED'
}

export interface IPassword {
  __typename?: 'Password';
  /** Conditions for a valid password. */
  passwordConditions?: Maybe<Array<Scalars['String']['output']>>;
  /** Strength/validity of given password. */
  passwordStrength?: Maybe<IPasswordStrength>;
}


export interface IPasswordPasswordStrengthArgs {
  password: Scalars['String']['input'];
}

export interface IPasswordStrength {
  __typename?: 'PasswordStrength';
  valid?: Maybe<Scalars['Boolean']['output']>;
}

export interface IPendingApproval extends ITask {
  __typename?: 'PendingApproval';
  /** Humanized value for this task type. */
  humanized: Scalars['String']['output'];
  /** Contains actionable information about the task. */
  message: Scalars['String']['output'];
  /**
   * The path you can use for navigation
   * @deprecated Frontend uses hash params instead of url params, we dont want the backend to keep track of the frontends URLs. Use detailed fields on each task.
   */
  path?: Maybe<Scalars['String']['output']>;
  /** What kind of tasks type we are handling */
  type: ITaskEnum;
}

export interface IPermissions {
  __typename?: 'Permissions';
  /** List of addable modalties */
  addableVehicles: Array<IAddableVehiclePermission>;
  /** Whether the employee can login by qr code */
  allowLoginByQrCode: Scalars['Boolean']['output'];
  /**
   * Whether the employee has access to the amber feature
   * @deprecated Amber is no longer available
   */
  amberFeature: Scalars['Boolean']['output'];
  /** Whether the employee can do approvals */
  approvals: Scalars['Boolean']['output'];
  /**
   * Whether the employee can add a private car
   * @deprecated Use addable_vehicles instead
   */
  canAddPrivateCar: Scalars['Boolean']['output'];
  /**
   * Whether the employee can add a private electric car
   * @deprecated Use addable_vehicles instead
   */
  canAddPrivateElectricCar: Scalars['Boolean']['output'];
  /**
   * Whether the employee can add a private motorcycle
   * @deprecated Use addable_vehicles instead
   */
  canAddPrivateMotorcycle: Scalars['Boolean']['output'];
  /** Whether this employee can assign a replacement manager */
  canAssignReplacementManager: Scalars['Boolean']['output'];
  /** Whether the employee can create a new mobility card order */
  canCreateNewMobilityCardOrder: Scalars['Boolean']['output'];
  /** Whether this employee can remove a replacement manager */
  canRemoveReplacementManager: Scalars['Boolean']['output'];
  /**
   * Whether the employee can request the lease bike feature
   * @deprecated You can check this through the availableFeatures endpoint.
   */
  canRequestLeaseBikeFeature: Scalars['Boolean']['output'];
  /** Whether the employee can use GPS tracking */
  canUseGps: Scalars['Boolean']['output'];
  /** Whether the employee can view financial consequences */
  canViewFinancialConsequences: Scalars['Boolean']['output'];
  /** Whether the employee can view the lease bike feature */
  canViewLeaseBikeFeature: Scalars['Boolean']['output'];
  /** Whether the employee can view the mileages page */
  canViewMileages: Scalars['Boolean']['output'];
  /** whether the employee can change a ride from combined to non_combined or vice versa */
  changeCombinedRide: Scalars['Boolean']['output'];
  /** Whether the employee can manage commute compensation events made to him / her */
  manageCommuteCompensation: Scalars['Boolean']['output'];
  /** Whether the employee can add commute rides */
  manageCommuteRides: Scalars['Boolean']['output'];
  /** Whether the employee can manage (view and activate) mobility cards */
  manageMobilityCards: Scalars['Boolean']['output'];
  /** Whether the employee can add mobility events */
  manageMobilityEvents: Scalars['Boolean']['output'];
  /** Whether the employee can manage (view and request new password) parking services */
  manageParking: Scalars['Boolean']['output'];
  /** Whether the employee can manage work from home events made to him / her */
  manageWorkFromHome: Scalars['Boolean']['output'];
  /** Whether the employee can order mobility cards */
  orderMobilityCards: Scalars['Boolean']['output'];
  /** Whether the employee can order parking */
  orderParking: Scalars['Boolean']['output'];
  /** Whether OTP is configured for this employee */
  otpConfigured: Scalars['Boolean']['output'];
  /** Whether OTP configuration is required to login */
  otpRequiredForLogin: Scalars['Boolean']['output'];
  /** Whether the employee can receive reimbursements for business rides */
  reimburseBusinessRide: Scalars['Boolean']['output'];
  /** Whether the employee can receive reimbursements for commute rides */
  reimburseCommuteRide: Scalars['Boolean']['output'];
  /** Whether the employee has access to the rental car feature */
  rentalCarFeature: Scalars['Boolean']['output'];
  /** Whether the employee can request a direct debit mandate */
  requestDirectDebitMandate: Scalars['Boolean']['output'];
  /** Whether the employee can request the shared vehicle reservations feature */
  requestSharedVehicleFeature: Scalars['Boolean']['output'];
  /** Whether the employee can request split billing */
  requestSplitBillingContract: Scalars['Boolean']['output'];
  /** Whether the employee can request the taxi feature */
  requestTaxiFeature: Scalars['Boolean']['output'];
  /** Whether the employee has access to the route planner feature */
  routePlannerFeature: Scalars['Boolean']['output'];
  /** Whether the employee can select an other employment */
  selectEmployment: Scalars['Boolean']['output'];
  /** Whether the employee has access to the shared vehicle reservations feature */
  sharedVehicleFeature: Scalars['Boolean']['output'];
  /** Whether the employee has access to the taxi feature */
  taxiFeature: Scalars['Boolean']['output'];
  /** Whether the employee can unaccept mobility events */
  unacceptMobilityEvents: Scalars['Boolean']['output'];
  /** Whether the employee can unconfirm mobility events */
  unconfirmMobilityEvents: Scalars['Boolean']['output'];
  /** Whether the employee can view direct debit invoices */
  viewDirectDebitInvoices: Scalars['Boolean']['output'];
  /**
   * Whether the employee can view payments made to him / her
   * @deprecated Use view_direct_debit_invoices instead
   */
  viewPayments: Scalars['Boolean']['output'];
  /** Whether the employee can view split billing invoices */
  viewSplitBillingInvoices: Scalars['Boolean']['output'];
}

export interface IPersonalMeansOfRegistration {
  __typename?: 'PersonalMeansOfRegistration';
  /** Abilities that an employee has for this personal mean of registration */
  abilities: IPersonalMeansOfRegistrationAbilities;
  /** All services that are available for this personal mean of registration. Also contains the currently active services */
  availableServices?: Maybe<Array<IAvailableServiceInterface>>;
  /** Date when this personal mean of registration ends */
  endsOn: IPersonalMeansOfRegistrationEndsOn;
  /** Internal identifier for this personal mean of registration */
  id: Scalars['ID']['output'];
  /** Public identifier for this personal mean of registration (for example a OV card number or Yellowbrick username) */
  identity?: Maybe<IPersonalMeansOfRegistrationIdentity>;
  /** Kind of this personal mean of registration */
  kind: IPersonalMeansOfRegistrationKind;
  /** Reasons why photo is rejected, only contains reasons if status is photo_disapproved */
  photoRejectedReasons?: Maybe<Array<IPersonalMeansOfRegistrationPhotoRejectedReason>>;
  /** Is replacement of this card requested */
  replacementRequested?: Maybe<Scalars['Boolean']['output']>;
  /**
   * All services that are currently active for this personal mean of registration
   * @deprecated Use available_services instead, those can be filtered on active or inactive
   */
  services?: Maybe<Array<IPersonalMeansOfRegistrationService>>;
  /** Status of this personal mean of registration */
  status?: Maybe<IPersonalMeansOfRegistrationStatus>;
}


export interface IPersonalMeansOfRegistrationAvailableServicesArgs {
  filterBy?: InputMaybe<IPersonalMeansOfRegistrationServiceStatusFilterEnum>;
}

export interface IPersonalMeansOfRegistrationAbilities {
  __typename?: 'PersonalMeansOfRegistrationAbilities';
  /** Whether the personal mean of registration can be activated */
  activatable: Scalars['Boolean']['output'];
  /** Whether the personal mean of registration can be reported lost or stolen */
  lostOrStolenReportable: Scalars['Boolean']['output'];
  /** Whether the personal mean of registration can be terminated */
  terminatable: Scalars['Boolean']['output'];
}

export interface IPersonalMeansOfRegistrationEndsOn {
  __typename?: 'PersonalMeansOfRegistrationEndsOn';
  humanized: Scalars['String']['output'];
}

export interface IPersonalMeansOfRegistrationIdentity {
  __typename?: 'PersonalMeansOfRegistrationIdentity';
  humanized?: Maybe<Scalars['String']['output']>;
}

export interface IPersonalMeansOfRegistrationKind {
  __typename?: 'PersonalMeansOfRegistrationKind';
  humanized: Scalars['String']['output'];
  main: IPersonalMeansOfRegistrationKindMainEnum;
  sub?: Maybe<IPersonalMeansOfRegistrationKindSubEnum>;
}

export enum IPersonalMeansOfRegistrationKindMainEnum {
  ELECTRIC_CHARGING_DONGLE = 'electric_charging_dongle',
  FUEL_CARD = 'fuel_card',
  GPS = 'gps',
  MANUAL = 'manual',
  MOBILITY_CARD = 'mobility_card',
  REISBALANS = 'reisbalans',
  SEMI_MANUAL = 'semi_manual',
  TAXI_ID = 'taxi_id',
  TELEMATICA = 'telematica'
}

export enum IPersonalMeansOfRegistrationKindSubEnum {
  DRIVEN_ZOVC = 'driven_zovc',
  DRIVEN_ZOVC_DKV = 'driven_zovc_dkv',
  I_TRAVEL_DEELKAART = 'i_travel_deelkaart',
  I_TRAVEL_DEELKAART_LEGACY = 'i_travel_deelkaart_legacy',
  I_TRAVEL_PREPAID_ZOVC = 'i_travel_prepaid_zovc',
  I_TRAVEL_PREPAID_ZOVC_LEGACY = 'i_travel_prepaid_zovc_legacy',
  I_TRAVEL_ZOVC = 'i_travel_zovc',
  I_TRAVEL_ZOVC_DKV = 'i_travel_zovc_dkv',
  I_TRAVEL_ZOVC_LEGACY = 'i_travel_zovc_legacy',
  I_TRAVEL_ZOVC_TANKEN = 'i_travel_zovc_tanken',
  MOBILITEITS_MANAGER_DEELKAART = 'mobiliteits_manager_deelkaart',
  MOBILITEITS_MANAGER_DEELKAART_LEGACY = 'mobiliteits_manager_deelkaart_legacy',
  MOBILITEITS_MANAGER_PREPAID_ZOVC = 'mobiliteits_manager_prepaid_zovc',
  MOBILITEITS_MANAGER_ZOVC = 'mobiliteits_manager_zovc',
  MOBILITEITS_MANAGER_ZOVC_DKV = 'mobiliteits_manager_zovc_dkv',
  MOBILITEITS_MANAGER_ZOVC_DKV_LEGACY = 'mobiliteits_manager_zovc_dkv_legacy',
  MOBILITEITS_MANAGER_ZOVC_LEGACY = 'mobiliteits_manager_zovc_legacy',
  MOBILITEITS_MANAGER_ZOVC_TANKEN = 'mobiliteits_manager_zovc_tanken',
  MOBILITEITS_MANAGER_ZOVC_TANKEN_LEGACY = 'mobiliteits_manager_zovc_tanken_legacy',
  NOC_NSF_ZOVC = 'noc_nsf_zovc',
  NS_BUSINESS_CARD = 'ns_business_card',
  QBUZZ_DEELKAART = 'qbuzz_deelkaart',
  QBUZZ_ZOVC = 'qbuzz_zovc',
  RB_ENECO = 'rb_eneco',
  REISBALANS_ANONYMOUS_ZOVC = 'reisbalans_anonymous_zovc',
  REISBALANS_DEELKAART = 'reisbalans_deelkaart',
  REISBALANS_UNLIMITED = 'reisbalans_unlimited',
  REISBALANS_ZOVC = 'reisbalans_zovc',
  REISBALANS_ZOVC_DKV = 'reisbalans_zovc_dkv',
  REISBALANS_ZOVC_TANKEN = 'reisbalans_zovc_tanken',
  RIJKSOVERHEID_ANONYMOUS_ZOVC = 'rijksoverheid_anonymous_zovc',
  RIJKSOVERHEID_ANONYMOUS_ZOVC_V2 = 'rijksoverheid_anonymous_zovc_v2',
  RIJKSOVERHEID_NSBC = 'rijksoverheid_nsbc',
  RIJKSOVERHEID_V2_ZOVC_HANDMATIGE_VERZENDING = 'rijksoverheid_v2_zovc_handmatige_verzending',
  RIJKSOVERHEID_ZOVC = 'rijksoverheid_zovc',
  RIJKSOVERHEID_ZOVC_HANDMATIGE_VERZENDING = 'rijksoverheid_zovc_handmatige_verzending',
  RIJKSOVERHEID_ZOVC_V2 = 'rijksoverheid_zovc_v2',
  RYDER_ANONYMOUS_ZOVC = 'ryder_anonymous_zovc',
  RYDER_DEELKAART = 'ryder_deelkaart',
  RYDER_ZOVC = 'ryder_zovc',
  SHUTTEL_ZOVC = 'shuttel_zovc',
  SHUTTEL_ZOVC_DKV = 'shuttel_zovc_dkv',
  SHUTTEL_ZOVC_TANKEN = 'shuttel_zovc_tanken',
  UOV_DEELKAART = 'uov_deelkaart',
  UOV_ZOVC = 'uov_zovc',
  UTRECHT_REGION_PASS = 'utrecht_region_pass',
  VOLKSWAGEN_LEASING_COMBIKAART = 'volkswagen_leasing_combikaart',
  VOLKSWAGEN_LEASING_ZAKELIJKE_OV_CHIPKAART = 'volkswagen_leasing_zakelijke_ov_chipkaart',
  WITTEBRUG_ANONYMOUS_ZOVC = 'wittebrug_anonymous_zovc',
  WITTEBRUG_DEELKAART = 'wittebrug_deelkaart',
  WITTEBRUG_ZOVC = 'wittebrug_zovc',
  YELLOWBRICK_PRODUCT = 'yellowbrick_product',
  ZUIDAS_DEELKAART = 'zuidas_deelkaart',
  ZUIDAS_DEELKAART_LEGACY = 'zuidas_deelkaart_legacy',
  ZUIDAS_ZOVC = 'zuidas_zovc'
}

export interface IPersonalMeansOfRegistrationMetaStatus {
  __typename?: 'PersonalMeansOfRegistrationMetaStatus';
  humanized: Scalars['String']['output'];
  value: IPersonalMeansOfRegistrationMetaStatusEnum;
}

export enum IPersonalMeansOfRegistrationMetaStatusEnum {
  IN_USE = 'in_use',
  INACTIVE = 'inactive',
  PENDING = 'pending',
  REQUIRES_ACTION = 'requires_action'
}

export interface IPersonalMeansOfRegistrationPhotoRejectedReason {
  __typename?: 'PersonalMeansOfRegistrationPhotoRejectedReason';
  humanized: Scalars['String']['output'];
  value: IPersonalMeansOfRegistrationPhotoRejectedReasonEnum;
}

export enum IPersonalMeansOfRegistrationPhotoRejectedReasonEnum {
  EYES_NOT_VISIBLE = 'eyes_not_visible',
  FACE_NOT_ENTIRELY_VISIBLE = 'face_not_entirely_visible',
  NOT_ENOUGH_CONTRAST = 'not_enough_contrast',
  NOT_JUST_FACE = 'not_just_face',
  NOT_SHARP_ENOUGH = 'not_sharp_enough',
  REFLECTION = 'reflection',
  TOO_DARK = 'too_dark',
  TOO_LIGHT = 'too_light',
  TOO_SMALL = 'too_small'
}

export enum IPersonalMeansOfRegistrationReport {
  LOST_OR_STOLEN = 'LOST_OR_STOLEN'
}

export interface IPersonalMeansOfRegistrationService {
  __typename?: 'PersonalMeansOfRegistrationService';
  endsOn?: Maybe<IPersonalMeansOfRegistrationServiceEndsOn>;
  id: Scalars['ID']['output'];
  identity?: Maybe<IPersonalMeansOfRegistrationServiceIdentity>;
  kind: IPersonalMeansOfRegistrationServiceKind;
  status?: Maybe<IPersonalMeansOfRegistrationServiceStatus>;
  trainClass?: Maybe<ITrainClass>;
}

export interface IPersonalMeansOfRegistrationServiceEndsOn {
  __typename?: 'PersonalMeansOfRegistrationServiceEndsOn';
  humanized: Scalars['String']['output'];
}

export interface IPersonalMeansOfRegistrationServiceIdentity {
  __typename?: 'PersonalMeansOfRegistrationServiceIdentity';
  humanized: Scalars['String']['output'];
}

export interface IPersonalMeansOfRegistrationServiceKind {
  __typename?: 'PersonalMeansOfRegistrationServiceKind';
  humanized: Scalars['String']['output'];
  main: Scalars['String']['output'];
  sub: IPersonalMeansOfRegistrationServiceKindSubEnum;
}

export enum IPersonalMeansOfRegistrationServiceKindSubEnum {
  ALTIJD_VOORDEEL = 'altijd_voordeel',
  DAL = 'dal',
  DAL_VOORDEEL = 'dal_voordeel',
  DAL_VRIJ = 'dal_vrij',
  DKV = 'dkv',
  GREENWHEELS = 'greenwheels',
  GVB_ONLY = 'gvb_only',
  GVB_RANDSTAD_NOORD_ZONE = 'gvb_randstad_noord_zone',
  GVB_ZONE = 'gvb_zone',
  NS_PR = 'ns_pr',
  OV_FIETS = 'ov_fiets',
  OV_VRIJ = 'ov_vrij',
  PR = 'pr',
  QBUZZ_REGIO = 'qbuzz_regio',
  QBUZZ_REGIO_TREIN = 'qbuzz_regio_trein',
  RAC = 'rac',
  RAZ = 'raz',
  REIZEN_OP_REKENING = 'reizen_op_rekening',
  REIZEN_OP_SALDO = 'reizen_op_saldo',
  RET_NET = 'ret_net',
  RET_ONLY = 'ret_only',
  RET_REGIO = 'ret_regio',
  ROS_MOB = 'ros_mob',
  TAXI = 'taxi',
  TRAJECT_OP_MAAT = 'traject_op_maat',
  TRAJECT_VRIJ = 'traject_vrij',
  TRAVEL_CARD = 'travel_card',
  TREIN_VRIJ = 'trein_vrij',
  UOV_GEMAK_REGIO = 'uov_gemak_regio',
  UOV_GEMAK_STAD = 'uov_gemak_stad',
  UOV_STER = 'uov_ster',
  UOV_VRIJ_REGIO = 'uov_vrij_regio',
  UOV_VRIJ_STAD = 'uov_vrij_stad',
  YELLOWBRICK_PR = 'yellowbrick_pr',
  YELLOWBRICK_SP = 'yellowbrick_sp'
}

export interface IPersonalMeansOfRegistrationServiceStatus {
  __typename?: 'PersonalMeansOfRegistrationServiceStatus';
  /** New value field with enum values */
  enumValue: IPersonalMeansOfRegistrationServiceStatusFilterEnum;
  humanized: Scalars['String']['output'];
  /** Metastatus of this status */
  metaStatus?: Maybe<IPersonalMeansOfRegistrationMetaStatus>;
  /** @deprecated Deprecated in favor of EnumValue field */
  value: Scalars['String']['output'];
}

export enum IPersonalMeansOfRegistrationServiceStatusFilterEnum {
  ACTIVATION_EXPIRED = 'activation_expired',
  ACTIVATION_REQUESTED = 'activation_requested',
  ACTIVE = 'active',
  BEING_TERMINATED = 'being_terminated',
  ERROR = 'error',
  IN_USE = 'in_use',
  INACTIVE = 'inactive',
  INITIALIZED = 'initialized',
  MANUFACTURED = 'manufactured',
  NOT_REQUESTED = 'not_requested',
  PENDING_ACTIVATION = 'pending_activation',
  PENDING_GREENWHEELS_ACTIVATION = 'pending_greenwheels_activation',
  PENDING_GREENWHEELS_REGISTRATION = 'pending_greenwheels_registration',
  PHOTO_DISAPPROVED = 'photo_disapproved',
  REJECTED = 'rejected',
  REPORTED_STOLEN = 'reported_stolen',
  REQUESTED = 'requested',
  SHIPPED = 'shipped',
  TERMINATED = 'terminated',
  UNCOLLECTED_PRODUCTS = 'uncollected_products'
}

export interface IPersonalMeansOfRegistrationStatus {
  __typename?: 'PersonalMeansOfRegistrationStatus';
  humanized: Scalars['String']['output'];
  value: IPersonalMeansOfRegistrationStatusEnum;
}

export enum IPersonalMeansOfRegistrationStatusEnum {
  ACTIVATION_EXPIRED = 'activation_expired',
  ACTIVATION_REQUESTED = 'activation_requested',
  BEING_TERMINATED = 'being_terminated',
  ERROR = 'error',
  IN_USE = 'in_use',
  INITIALIZED = 'initialized',
  MANUFACTURED = 'manufactured',
  NOT_REQUESTED = 'not_requested',
  PENDING_ACTIVATION = 'pending_activation',
  PENDING_GREENWHEELS_ACTIVATION = 'pending_greenwheels_activation',
  PENDING_GREENWHEELS_REGISTRATION = 'pending_greenwheels_registration',
  PHOTO_DISAPPROVED = 'photo_disapproved',
  REJECTED = 'rejected',
  REPORTED_STOLEN = 'reported_stolen',
  REQUESTED = 'requested',
  SHIPPED = 'shipped',
  TERMINATED = 'terminated',
  UNCOLLECTED_PRODUCTS = 'uncollected_products'
}

export enum IPersonalMeansOfRegistrationStatusFilterEnum {
  ACTIVATABLE = 'activatable',
  ACTIVATION_EXPIRED = 'activation_expired',
  ACTIVATION_REQUESTED = 'activation_requested',
  ACTIVE = 'active',
  BEING_TERMINATED = 'being_terminated',
  ERROR = 'error',
  IN_USE = 'in_use',
  INACTIVE = 'inactive',
  INITIALIZED = 'initialized',
  MANUFACTURED = 'manufactured',
  NOT_REQUESTED = 'not_requested',
  PENDING_ACTIVATION = 'pending_activation',
  PENDING_GREENWHEELS_ACTIVATION = 'pending_greenwheels_activation',
  PENDING_GREENWHEELS_REGISTRATION = 'pending_greenwheels_registration',
  PHOTO_DISAPPROVED = 'photo_disapproved',
  REJECTED = 'rejected',
  REPORTED_STOLEN = 'reported_stolen',
  REQUESTED = 'requested',
  SHIPPED = 'shipped',
  TERMINATED = 'terminated',
  UNCOLLECTED_PRODUCTS = 'uncollected_products',
  UNKNOWN = 'unknown'
}

export interface IPhoto {
  avatar: IAttachment;
  cropH?: InputMaybe<Scalars['String']['input']>;
  cropW?: InputMaybe<Scalars['String']['input']>;
  cropX?: InputMaybe<Scalars['String']['input']>;
  cropY?: InputMaybe<Scalars['String']['input']>;
}

/** Suggest locations */
export interface IPlaces {
  __typename?: 'Places';
  /** returns the details given a specific place id */
  details?: Maybe<IPlacesDetailsResult>;
  /** returns a list of places that starts with .. */
  suggest?: Maybe<Array<IPlacesResult>>;
}


/** Suggest locations */
export interface IPlacesDetailsArgs {
  organizationId?: InputMaybe<Scalars['String']['input']>;
  placeId: Scalars['String']['input'];
}


/** Suggest locations */
export interface IPlacesSuggestArgs {
  organizationId?: InputMaybe<Scalars['String']['input']>;
  startsWith: Scalars['String']['input'];
}

export interface IPlacesDetailsResult {
  __typename?: 'PlacesDetailsResult';
  /** A formatted address of the location */
  address: Scalars['String']['output'];
  /** City address component */
  city?: Maybe<Scalars['String']['output']>;
  /** Contains the longitude and latitude of the location */
  coordinates: ICoordinates;
  /** Country ISO Code address component */
  countryIsoCode?: Maybe<Scalars['String']['output']>;
  /** Reference to stored location with a cached google or tomtom details */
  locationId?: Maybe<Scalars['ID']['output']>;
  /** The name of the location */
  name: Scalars['String']['output'];
  /** The TomTom Place ID that you'd like to get the details from. */
  placeId: Scalars['String']['output'];
  /** Postal code address component */
  postalCode?: Maybe<Scalars['String']['output']>;
  /** Street address component */
  street?: Maybe<Scalars['String']['output']>;
  /** Street number address component */
  streetNumber?: Maybe<Scalars['String']['output']>;
  /** When related location cannot be resolved or created because of missing data from google_places, an error message is provided. */
  validationErrors: Array<IValidationError>;
}

export interface IPlacesResult {
  __typename?: 'PlacesResult';
  /** A HTML enriched string containing the name of the location */
  html: Scalars['String']['output'];
  /** The TomTom Place ID which is needed if you want to retrieve the details if this place */
  placeId: Scalars['String']['output'];
  /** Structured formatting of the suggested text */
  structuredText: IPlacesStructuredText;
  /** A plain text string containing the name of the location */
  text: Scalars['String']['output'];
  /** The location type and icon */
  type?: Maybe<IPlacesTypeValue>;
}

export interface IPlacesStructuredText {
  __typename?: 'PlacesStructuredText';
  /** Main text of the suggestion, usually the name of the place */
  main?: Maybe<Scalars['String']['output']>;
  /** Secondary text of the suggestion, usually the location of the place */
  secondary?: Maybe<Scalars['String']['output']>;
}

/** TomTom places type */
export interface IPlacesTypeValue {
  __typename?: 'PlacesTypeValue';
  /** Icon name for type */
  icon?: Maybe<IIconEnum>;
  /** The location type */
  value: IPlacesTypesEnum;
}

/** TomTom places types enum */
export enum IPlacesTypesEnum {
  AIRPORT = 'airport',
  OPEN_AREA_PARKING = 'open_area_parking',
  PARKING_GARAGE = 'parking_garage',
  PETROL_STATION = 'petrol_station',
  PUBLIC_TRANSPORT_STOP = 'public_transport_stop',
  RAILWAY_STATION = 'railway_station',
  UNKNOWN = 'unknown'
}

export interface IPossibleReplacementManager {
  __typename?: 'PossibleReplacementManager';
  email: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
}

export enum IPreferredJourneyModeEnum {
  BIKE = 'bike',
  BUS = 'bus',
  CAR = 'car',
  FERRY = 'ferry',
  METRO = 'metro',
  SCOOTER = 'scooter',
  TAXI = 'taxi',
  TRAIN = 'train',
  TRAM = 'tram'
}

export enum IPreferredModalityEnum {
  BUS = 'bus',
  METRO = 'metro',
  TRAIN = 'train',
  TRAM = 'tram'
}

export interface IPrice {
  __typename?: 'Price';
  amount: IMoney;
  currency: Scalars['String']['output'];
}

export interface IPrivacy {
  __typename?: 'Privacy';
  /** More information about the privacy type */
  description?: Maybe<Scalars['String']['output']>;
  /** Humanized name for the privacy type */
  humanized?: Maybe<Scalars['String']['output']>;
  /** Optional clarifications for the request */
  reasons?: Maybe<Array<IPrivacyRequestReason>>;
  /** Privacy type value */
  value?: Maybe<IPrivacyTypeEnum>;
}

export interface IPrivacyRequestReason {
  __typename?: 'PrivacyRequestReason';
  description: Scalars['String']['output'];
  reason?: Maybe<IPrivacyRequestReasonEnum>;
  title: Scalars['String']['output'];
  /** @deprecated Deprecated in favor of PrivacyRequestReasonEnum reason field */
  value?: Maybe<IRightToBeForgottenReasonEnum>;
}

export enum IPrivacyRequestReasonEnum {
  CONSENT_WITHDRAWN = 'consent_withdrawn',
  LAWFUL_RETENTION_PERIOD_EXPIRED = 'lawful_retention_period_expired',
  NO_LONGER_NECESSARY = 'no_longer_necessary',
  OBJECTS_TO_PROCESSING = 'objects_to_processing',
  POSSIBLY_INCORRECT = 'possibly_incorrect',
  UNLAWFULLY_PROCESSED = 'unlawfully_processed'
}

export enum IPrivacyTypeEnum {
  RIGHT_TO_ACCESS = 'right_to_access',
  RIGHT_TO_BE_FORGOTTEN = 'right_to_be_forgotten',
  RIGHT_TO_DATA_PORTABILITY = 'right_to_data_portability',
  RIGHT_TO_OBJECT = 'right_to_object',
  RIGHT_TO_OBTAIN_HUMAN_INTERVENTION = 'right_to_obtain_human_intervention',
  RIGHT_TO_RECTIFICATION = 'right_to_rectification',
  RIGHT_TO_RESTRICTION_OF_PROCESSING = 'right_to_restriction_of_processing'
}

export interface IProfile {
  __typename?: 'Profile';
  /** The date since the employee has been active */
  activeSince: Scalars['Date']['output'];
  /** The url pointing to the avatar of this employee if they have one */
  avatar?: Maybe<Scalars['String']['output']>;
  /** Date of birth of this employee */
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  /** Preferred purpose of supplier transactions */
  defaultMobilityCardTransactionTravelPurpose?: Maybe<ITravelPurposeEnum>;
  /** Preferred purpose of supplier transactions on weekends */
  defaultMobilityCardWeekendTransactionTravelPurpose?: Maybe<ITravelPurposeEnum>;
  /** The email address of this employee */
  email: Scalars['String']['output'];
  /** Employee number used within the organization for this employee */
  employeeNumber?: Maybe<Scalars['String']['output']>;
  /** The first name of this employee */
  firstName: Scalars['String']['output'];
  /** home address of the current employee, with full address details */
  homeAddress?: Maybe<IEmployeeAddress>;
  /** The infix of this employee, like : 'de, van, van der, etc' */
  infix?: Maybe<Scalars['String']['output']>;
  /** The initials of this employee */
  initials?: Maybe<Scalars['String']['output']>;
  /** Flag to determine whether this employee is a manager */
  isManager: Scalars['Boolean']['output'];
  /** The last name of this employee */
  lastName: Scalars['String']['output'];
  /** The phone number of this employee */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** Preferred purpose of GPS registered rides */
  preferredTravelPurpose?: Maybe<ITravelPurposeEnum>;
  /** A list of AVG (GDPR) privacy request types */
  privacyTypes?: Maybe<Array<IPrivacy>>;
  /** A list of AVG (GDPR) right to be forgotten request reasons */
  rightToBeForgottenReasons?: Maybe<Array<IRightToBeForgottenReason>>;
  /** Whether or not the employee opted in to use the new interface */
  usesNewInterface?: Maybe<Scalars['Boolean']['output']>;
  /** work address of the current employee, with full address details */
  workAddress?: Maybe<IEmployeeAddress>;
}

export interface IProfileMutation {
  dateOfBirth: Scalars['Date']['input'];
  firstName: Scalars['String']['input'];
  homeAddress: IEmployeeAddressMutation;
  /** The infix of an employee, like : 'de, van, van der, etc' */
  infix?: InputMaybe<Scalars['String']['input']>;
  initials: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
}

export interface IProject {
  __typename?: 'Project';
  active: Scalars['Boolean']['output'];
  code: Scalars['String']['output'];
  endsOn?: Maybe<Scalars['Date']['output']>;
  humanized: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  startsOn: Scalars['Date']['output'];
}

export enum IProjectScope {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export interface IPublicTransportStop extends IMultiModalAvailability {
  __typename?: 'PublicTransportStop';
  deeplink?: Maybe<Scalars['String']['output']>;
  geoLocation: ICoordinates;
  icon: IIcon;
  modality: IJourneyMode;
  /** Human readable name for this asset */
  name?: Maybe<Scalars['String']['output']>;
  serviceOperator?: Maybe<IServiceOperator>;
  /** The services available at this stop (e.g. which kind of trains for a train station or the departing lines for tram or bus stops) */
  services?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
}

export interface IPurpose {
  __typename?: 'Purpose';
  /** A breakdown of the total value */
  details?: Maybe<Array<IStats>>;
  /** The total CO2 emission emitted during the travelled distance */
  totalCo2EmissionInGrams?: Maybe<Scalars['Int']['output']>;
  /** The total distance in meters */
  totalDistanceInMeters?: Maybe<Scalars['Int']['output']>;
}

export interface IQuantity {
  __typename?: 'Quantity';
  amount: Scalars['Int']['output'];
  humanized: Scalars['String']['output'];
  label: Scalars['String']['output'];
  unit: IUnitEnum;
}

export interface IQuery {
  __typename?: 'Query';
  MobilityServices: IMobilityServicesQuery;
  reisbalans: IReisbalansQuery;
  reisbalansV2: IReisbalansV2Query;
}

export interface IQuote {
  __typename?: 'Quote';
  endTime: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  legs: Array<ITranzerLeg>;
  /** Required fields in the booking request in order to book this quote. */
  requiredBookingFields?: Maybe<Array<Scalars['String']['output']>>;
  startTime: Scalars['DateTime']['output'];
  status: IQuoteStatus;
  totalCosts: IMoney;
  /** Total duration in seconds. */
  totalDuration: Scalars['Int']['output'];
  validUntil: Scalars['DateTime']['output'];
}

export interface IQuoteStatus {
  __typename?: 'QuoteStatus';
  humanized: Scalars['String']['output'];
  value: IQuoteStatusEnum;
}

export enum IQuoteStatusEnum {
  PROPOSED = 'PROPOSED',
  UNBOOKABLE = 'UNBOOKABLE'
}

export interface IReceipt {
  __typename?: 'Receipt';
  contentType: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  url: Scalars['String']['output'];
}

export interface IRecommendedCard {
  __typename?: 'RecommendedCard';
  /** Humanized and translated recommended card */
  humanized: Scalars['String']['output'];
  /** Raw value of the recommended card */
  value: IPersonalMeansOfRegistrationKindSubEnum;
}

/** Autogenerated input type of RefreshTranzerReservation */
export interface IRefreshTranzerReservationInput {
  /** Mobidesk booking ID */
  bookingId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of RefreshTranzerReservation. */
export interface IRefreshTranzerReservationPayload {
  __typename?: 'RefreshTranzerReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  tranzerReservation?: Maybe<ITranzerReservation>;
}

export interface IRefundToBeLinked extends ITask {
  __typename?: 'RefundToBeLinked';
  /** Humanized value for this task type. */
  humanized: Scalars['String']['output'];
  /** Contains actionable information about the task. */
  message: Scalars['String']['output'];
  mobilityEventId?: Maybe<Scalars['ID']['output']>;
  /**
   * The path you can use for navigation
   * @deprecated Frontend uses hash params instead of url params, we dont want the backend to keep track of the frontends URLs. Use detailed fields on each task.
   */
  path?: Maybe<Scalars['String']['output']>;
  /** What kind of tasks type we are handling */
  type: ITaskEnum;
}

export interface IReimbursableCategory {
  __typename?: 'ReimbursableCategory';
  category: ICategory;
  fiscality: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  modality?: Maybe<IModality>;
}

export interface IReimbursableCategoryMethod {
  __typename?: 'ReimbursableCategoryMethod';
  humanized: Scalars['String']['output'];
  value: IReimbursableCategoryMethodEnum;
}

export enum IReimbursableCategoryMethodEnum {
  BOTH = 'BOTH',
  COST = 'COST',
  ROUTE = 'ROUTE'
}

export interface IReimbursementRule {
  budgetGroupId: Scalars['ID']['output'];
  category: ICategory;
  configuration: IConfiguration;
  fiscality: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  main: IMainCategory;
  modality?: Maybe<IModality>;
  reimbursableCategory: IReimbursableCategory;
  sub?: Maybe<ISubCategory>;
  travelPurpose: Scalars['String']['output'];
}

/** Autogenerated input type of RejectMobilityEvents */
export interface IRejectMobilityEventsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityEventIds: Array<Scalars['ID']['input']>;
  rejectReason: Scalars['String']['input'];
}

/** Autogenerated return type of RejectMobilityEvents. */
export interface IRejectMobilityEventsPayload {
  __typename?: 'RejectMobilityEventsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rejectedMobilityEvents: Array<IMobilityEvent>;
  result: IResult;
  unrejectedMobilityEvents: Array<IMobilityEvent>;
}

/** Autogenerated input type of RemoveFromWhitelist */
export interface IRemoveFromWhitelistInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityCardId: Scalars['ID']['input'];
}

/** Autogenerated return type of RemoveFromWhitelist. */
export interface IRemoveFromWhitelistPayload {
  __typename?: 'RemoveFromWhitelistPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  studentTravelRequestDates: IRequestDates;
  validationErrors: Array<IValidationError>;
}

export interface IRentalCar extends IAvailableFeatureInterface {
  __typename?: 'RentalCar';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  /**
   * This is only available when the request for this feature has been approved and the employee is allowed to order a rental car
   * @deprecated Use the urls endpoint instead
   */
  url?: Maybe<Scalars['String']['output']>;
  urls?: Maybe<IFeatureUrls>;
}

export interface IRentalLocations {
  __typename?: 'RentalLocations';
  pickup: ILocation;
  pickupLabel: Scalars['String']['output'];
  return: ILocation;
  returnLabel: Scalars['String']['output'];
}

export interface IReplacementManager {
  __typename?: 'ReplacementManager';
  /** End of the period when this manager can approve */
  approvalPeriodEnd?: Maybe<Scalars['Date']['output']>;
  /** Start of the period when this manager can approve */
  approvalPeriodStart: Scalars['Date']['output'];
  /** Email of the temporary replacement manager */
  email: Scalars['String']['output'];
  /** Email of the manager that is being replaced */
  emailApprovalManager: Scalars['String']['output'];
  /** Name of the temporary replacement manager */
  fullName: Scalars['String']['output'];
  /** Name of the manager that is being replaced */
  fullNameApprovalManager: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Label for showing if manager is active or not */
  label?: Maybe<ILabel>;
  /** ID of the employee who this manager is */
  managerId: Scalars['ID']['output'];
}

/** Autogenerated input type of ReportPersonalMeansOfRegistration */
export interface IReportPersonalMeansOfRegistrationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the personal means of registration (mobility card) */
  id: Scalars['ID']['input'];
  /** Indicates whether a replacement card should be ordered, defaults to true */
  needReplacement?: InputMaybe<Scalars['Boolean']['input']>;
  /** DEPRECATED, this mutation will only be used to report cards as lost/stolen */
  reportType?: InputMaybe<IPersonalMeansOfRegistrationReport>;
}

/** Autogenerated return type of ReportPersonalMeansOfRegistration. */
export interface IReportPersonalMeansOfRegistrationPayload {
  __typename?: 'ReportPersonalMeansOfRegistrationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  personalMeansOfRegistration: IPersonalMeansOfRegistration;
  result: IResult;
}

/** Studentenreisproduct is an external managed product provided to NOC*NSF students using a (labeled) NS businesscard */
export interface IRequestDates {
  __typename?: 'RequestDates';
  /** Date of successful removal from studentenreisproduct whitelist */
  studentTravelAddedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date of successful adding to studentenreisproduct whitelist */
  studentTravelRemovedAt?: Maybe<Scalars['DateTime']['output']>;
}

/** Autogenerated input type of RequestDonkeyRepublicFeature */
export interface IRequestDonkeyRepublicFeatureInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  motivation?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
  termsAndConditionsDocumentIds: Array<Scalars['ID']['input']>;
}

/** Autogenerated return type of RequestDonkeyRepublicFeature. */
export interface IRequestDonkeyRepublicFeaturePayload {
  __typename?: 'RequestDonkeyRepublicFeaturePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result?: Maybe<IResult>;
  /** Returns which provided attribute(s) didnt apply to the validation rules */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of RequestFeature */
export interface IRequestFeatureInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feature: IFeatureOptionEnum;
  motivation?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  termsAndConditionsDocumentIds: Array<Scalars['ID']['input']>;
}

/** Autogenerated return type of RequestFeature. */
export interface IRequestFeaturePayload {
  __typename?: 'RequestFeaturePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result?: Maybe<IResult>;
  /** Returns which provided attribute(s) didnt apply to the validation rules */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of RequestService */
export interface IRequestServiceInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of mobility card on which this service is requested */
  mobilityCardId?: InputMaybe<Scalars['ID']['input']>;
  /** Motivation for requesting these services */
  motivation?: InputMaybe<Scalars['String']['input']>;
  /** Services to request */
  services: Array<IPersonalMeansOfRegistrationServiceKindSubEnum>;
  /** List of IDs of agreed upon terms and condition documents */
  terms?: InputMaybe<Array<Scalars['ID']['input']>>;
}

/** Autogenerated return type of RequestService. */
export interface IRequestServicePayload {
  __typename?: 'RequestServicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  /** Change request used for this services request */
  servicesChangeRequest?: Maybe<IServicesChangeRequest>;
  /** List of validation errors */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of RequestTrainClassChange */
export interface IRequestTrainClassChangeInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  firstClassReason?: InputMaybe<IFirstClassReasonEnum>;
  motivation?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['ID']['input'];
}

/** Autogenerated return type of RequestTrainClassChange. */
export interface IRequestTrainClassChangePayload {
  __typename?: 'RequestTrainClassChangePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  trainClassChangeRequest?: Maybe<ITrainClassChangeRequest>;
  validationErrors: Array<IValidationError>;
}

export interface IRequestableDonkeyRepublic extends IAvailableFeatureInterface {
  __typename?: 'RequestableDonkeyRepublic';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Whether the feature requires motivation. */
  requiresMotivation?: Maybe<Scalars['Boolean']['output']>;
  requiresPhoneNumber?: Maybe<Scalars['Boolean']['output']>;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  termsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  urls?: Maybe<IFeatureUrls>;
}

export interface IRequestableFeature extends IAvailableFeatureInterface {
  __typename?: 'RequestableFeature';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Whether the feature requires motivation. */
  requiresMotivation?: Maybe<Scalars['Boolean']['output']>;
  /** Whether a phone number is required for this feature. */
  requiresPhoneNumber?: Maybe<Scalars['Boolean']['output']>;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  termsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  urls?: Maybe<IFeatureUrls>;
}

export interface IRequestableFeatureOption {
  __typename?: 'RequestableFeatureOption';
  name?: Maybe<IFeatureOption>;
  requiresMotivation?: Maybe<Scalars['Boolean']['output']>;
  termsAndConditions?: Maybe<Array<ITermsAndConditions>>;
}

export interface IRequestableService extends IAvailableServiceInterface {
  __typename?: 'RequestableService';
  /** When a service is requestable because of rules of the budget group, it can be a required or an optional service. When the service is not requestable by the budget group but on the card when requesting the card, the availability is `null`. */
  budgetGroupAvailability?: Maybe<IAvailableServiceAvailabilityEnum>;
  /** Description of this service */
  description?: Maybe<IDescription>;
  /** Date on which this service is due to be ended (if available) */
  endsOn?: Maybe<IPersonalMeansOfRegistrationServiceEndsOn>;
  /** Icon for the available service, based on the kind */
  icon?: Maybe<IIcon>;
  /** Identity for the service on this card. Can be a username or a unique number (if available) */
  identity?: Maybe<IPersonalMeansOfRegistrationServiceIdentity>;
  /** Kind of service that is available */
  kind: IPersonalMeansOfRegistrationServiceKind;
  /** Mobidesk ownership token for this service */
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  /** Personal Mean of Registration this service belongs to (or will belong to if service has not been requested yet) */
  personalMeansOfRegistration?: Maybe<IPersonalMeansOfRegistration>;
  /** Internal ID used for this service (if available) */
  recordId?: Maybe<Scalars['ID']['output']>;
  /** Whether this service needs a motiviation when requesting it */
  requiresMotivation: Scalars['Boolean']['output'];
  /** Whether the requestable service may require the user to enter a PIN. */
  requiresPincode?: Maybe<Scalars['Boolean']['output']>;
  /** Current status of this service */
  status?: Maybe<IPersonalMeansOfRegistrationServiceStatus>;
  /** The terms and conditions document for this service */
  termsAndConditions?: Maybe<ITermsAndConditions>;
}

export interface IRequestableYellowbrickProduct extends IAvailableServiceInterface {
  __typename?: 'RequestableYellowbrickProduct';
  /** When a service is requestable because of rules of the budget group, it can be a required or an optional service. When the service is not requestable by the budget group but on the card when requesting the card, the availability is `null`. */
  budgetGroupAvailability?: Maybe<IAvailableServiceAvailabilityEnum>;
  /** Description of this service */
  description?: Maybe<IDescription>;
  /** Date on which this service is due to be ended (if available) */
  endsOn?: Maybe<IPersonalMeansOfRegistrationServiceEndsOn>;
  /** Icon for the available service, based on the kind */
  icon?: Maybe<IIcon>;
  /** Identity for the service on this card. Can be a username or a unique number (if available) */
  identity?: Maybe<IPersonalMeansOfRegistrationServiceIdentity>;
  /** Kind of service that is available */
  kind: IPersonalMeansOfRegistrationServiceKind;
  /** Mobidesk ownership token for this service */
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  /** Personal Mean of Registration this service belongs to (or will belong to if service has not been requested yet) */
  personalMeansOfRegistration?: Maybe<IPersonalMeansOfRegistration>;
  /** Internal ID used for this service (if available) */
  recordId?: Maybe<Scalars['ID']['output']>;
  /** Whether this service needs a motiviation when requesting it */
  requiresMotivation: Scalars['Boolean']['output'];
  /** Whether the requestable service may require the user to enter a PIN. */
  requiresPincode?: Maybe<Scalars['Boolean']['output']>;
  /** Current status of this service */
  status?: Maybe<IPersonalMeansOfRegistrationServiceStatus>;
  /** The terms and conditions document for this service */
  termsAndConditions?: Maybe<ITermsAndConditions>;
}

export interface IRequiredFields {
  __typename?: 'RequiredFields';
  /** Whether a description is allowed for any MobilityEvent, even if it is not required. */
  descriptionAllowed: Scalars['Boolean']['output'];
  /** Whether a description is required to be given when the travelpurpose of the mobilitycard_transaction is BUSINESS */
  descriptionForMobilitycardTransactionBusiness: Scalars['Boolean']['output'];
  /** Whether a description is required to be given when the travelpurpose of the mobilitycard_transaction is COMMUTE */
  descriptionForMobilitycardTransactionCommute: Scalars['Boolean']['output'];
  /** Whether a description is required to be given when the travelpurpose of the mobilitycard_transaction is PERSONAL */
  descriptionForMobilitycardTransactionPersonal: Scalars['Boolean']['output'];
  /** Whether a description is required to be given when the travelpurpose of the mobilitycard_transaction is in the given array of values */
  descriptionForMobilitycardTransactions: Array<ITravelPurposeEnum>;
  /** Whether a description is required to be given when the travelpurpose of the ride_transaction is BUSINESS when creating ride by amount */
  descriptionForRideByAmountBusiness: Scalars['Boolean']['output'];
  /** Whether a description is required to be given when the travelpurpose of the ride_transaction is COMMUTE when creating ride by amount */
  descriptionForRideByAmountCommute: Scalars['Boolean']['output'];
  /** Whether a description is required to be given when the travelpurpose of the ride_transaction is PERSONAL when creating ride by amount */
  descriptionForRideByAmountPersonal: Scalars['Boolean']['output'];
  /** Whether a description is required to be given when the travelpurpose of the ride_transaction is BUSINESS when creating ride by distance */
  descriptionForRideByDistanceBusiness: Scalars['Boolean']['output'];
  /** Whether a description is required to be given when the travelpurpose of the ride_transaction is COMMUTE when creating ride by distance */
  descriptionForRideByDistanceCommute: Scalars['Boolean']['output'];
  /** Whether a description is required to be given when the travelpurpose of the ride_transaction is PERSONAL when creating ride by distance */
  descriptionForRideByDistancePersonal: Scalars['Boolean']['output'];
  /**
   * Whether a description is required to be given when the travelpurpose of the ride_transaction is BUSINESS
   * @deprecated This settings got split in two. Use descriptionForRideByAmountBusiness or descriptionForRideByDistanceBusiness
   */
  descriptionForRideTransactionBusiness: Scalars['Boolean']['output'];
  /**
   * Whether a description is required to be given when the travelpurpose of the ride_transaction is COMMUTE
   * @deprecated This settings got split in two. Use descriptionForRideByAmountCommute or descriptionForRideByDistanceCommute
   */
  descriptionForRideTransactionCommute: Scalars['Boolean']['output'];
  /**
   * Whether a description is required to be given when the travelpurpose of the ride_transaction is PERSONAL
   * @deprecated This settings got split in two. Use descriptionForRideByAmountPersonal or descriptionForRideByDistancePersonal
   */
  descriptionForRideTransactionPersonal: Scalars['Boolean']['output'];
  /** Whether a description is required to be given when the travelpurpose of the ride_transaction is in the given array of values */
  descriptionForRideTransactions: Array<ITravelPurposeEnum>;
  /** Whether a project is allowed for any MobilityEvent, even if it is not required. */
  projectAllowed: Scalars['Boolean']['output'];
  /** Whether a project is required to be given when the travelpurpose is BUSINESS */
  projectForTravelpurposeBusiness: Scalars['Boolean']['output'];
  /** Whether a project is required to be given when the travelpurpose is COMMUTE */
  projectForTravelpurposeCommute: Scalars['Boolean']['output'];
  /** Whether a project is required to be given when the travelpurpose is PERSONAL */
  projectForTravelpurposePersonal: Scalars['Boolean']['output'];
  /** Whether a project is required to be given when the travelpurpose is in the given array of values */
  projectForTravelpurposes: Array<ITravelPurposeEnum>;
}

export interface IReservation {
  __typename?: 'Reservation';
  /** When the reservation has been cancelled */
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  /** The end location/destination of this reservation */
  endLocation?: Maybe<IGeoLocation>;
  /** When does the reservation end. Not applicable for Taxi reservations */
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  /** The name of the icon that can be used for this reservation */
  icon?: Maybe<IIcon>;
  /** This id is not the database id but a composed id from the class and record id. If you want the database id refer to the recordId. */
  id: Scalars['String']['output'];
  /** The id of this record as know by the database. */
  recordId: Scalars['ID']['output'];
  /** The original class that this reservation belongs to */
  reservationClass: IReservationClassEnum;
  /** The current status of this reservation, with a value and a humanized. Query the specific object to get precise status info */
  reservationStatus?: Maybe<IReservationStatus>;
  /** The start location/departure of this reservation */
  startLocation?: Maybe<IGeoLocation>;
  /** When does the reservation start */
  startsAt: Scalars['DateTime']['output'];
  /**
   * The current status of this reservation, prone to change, use for displaying data only. Query the specific object to get precise status info
   * @deprecated Use reservationStatus instead
   */
  status?: Maybe<Scalars['String']['output']>;
  /** The title of this reservation. */
  title: Scalars['String']['output'];
  /** If the reservation has a license plate, this will be filled with that value */
  vehicleLicensePlate?: Maybe<Scalars['String']['output']>;
}

export enum IReservationClassEnum {
  DONKEYREPUBLICBOOKING = 'DONKEYREPUBLICBOOKING',
  SHAREDVEHICLERESERVATION = 'SHAREDVEHICLERESERVATION',
  TAXIRESERVATION = 'TAXIRESERVATION',
  TRANZERBOOKING = 'TRANZERBOOKING'
}

export enum IReservationKindEnum {
  BICYCLE = 'bicycle',
  BIKE = 'bike',
  BUS = 'bus',
  CAR = 'car',
  EBIKE = 'ebike',
  METRO = 'metro',
  SCOOTER = 'scooter',
  TAXI = 'taxi',
  TRAIN = 'train',
  TRAM = 'tram'
}

export enum IReservationStateEnum {
  ARCHIVED = 'ARCHIVED',
  CURRENT = 'CURRENT'
}

export interface IReservationStatus {
  __typename?: 'ReservationStatus';
  humanized: Scalars['String']['output'];
  value: IReservationStatusValueEnum;
}

export enum IReservationStatusEnum {
  ARCHIVED = 'ARCHIVED',
  CURRENT = 'CURRENT'
}

export enum IReservationStatusValueEnum {
  ACTIVE = 'ACTIVE',
  ACTIVE_LOCKED = 'ACTIVE_LOCKED',
  ACTIVE_PENDING_LOCK = 'ACTIVE_PENDING_LOCK',
  ACTIVE_PENDING_UNLOCK = 'ACTIVE_PENDING_UNLOCK',
  BOOKED = 'BOOKED',
  CANCELLED = 'CANCELLED',
  CANCELLING = 'CANCELLING',
  COMPLETED = 'COMPLETED',
  CONFIRMED = 'CONFIRMED',
  CONFIRMED_PENDING_UNLOCK = 'CONFIRMED_PENDING_UNLOCK',
  ERROR = 'ERROR',
  FINISHED = 'FINISHED',
  FINISHING = 'FINISHING',
  NEW = 'NEW',
  REJECTED = 'REJECTED',
  STARTED = 'STARTED',
  STARTING = 'STARTING',
  UPDATED = 'UPDATED'
}

/** Autogenerated input type of ResetPassword */
export interface IResetPasswordInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** New password of the user */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Confirmation of new password of the user. */
  passwordConfirmation?: InputMaybe<Scalars['String']['input']>;
  /** The password reset token which has been provided in the password reset mail */
  token: Scalars['String']['input'];
}

/** Autogenerated return type of ResetPassword. */
export interface IResetPasswordPayload {
  __typename?: 'ResetPasswordPayload';
  /** Api-Token to authenticate the employee for successive requests */
  apiToken?: Maybe<Scalars['String']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Updated employee */
  me?: Maybe<IEmployee>;
  /** Returns whether the mutation was successful */
  result: IResult;
  /** Returns which provided attribute(s) didnt apply to the validation rules */
  validationErrors: Array<IValidationError>;
}

export interface IResult {
  __typename?: 'Result';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['String']['output']>;
}

export interface IResultInterface {
  /** Error message */
  error?: Maybe<Scalars['String']['output']>;
  /** Success message */
  success?: Maybe<Scalars['String']['output']>;
}

export interface IResultWithAccessTokens {
  __typename?: 'ResultWithAccessTokens';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['String']['output']>;
  tokens?: Maybe<IAuth>;
}

/** Autogenerated input type of ReuploadPhoto */
export interface IReuploadPhotoInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityCardId: Scalars['ID']['input'];
  photo: IPhoto;
}

/** Autogenerated return type of ReuploadPhoto. */
export interface IReuploadPhotoPayload {
  __typename?: 'ReuploadPhotoPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Profile with the new photo */
  profile?: Maybe<IProfile>;
  /** Result after reuploading a photo */
  result: IResult;
}

/** Autogenerated input type of RevokeDirectDebitMandate */
export interface IRevokeDirectDebitMandateInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Mandate ID that needs to be revoked */
  id: Scalars['ID']['input'];
}

/** Autogenerated return type of RevokeDirectDebitMandate. */
export interface IRevokeDirectDebitMandatePayload {
  __typename?: 'RevokeDirectDebitMandatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** If everything was ok, this contains your new direct debit mandate */
  directDebitMandate?: Maybe<IDirectDebitMandate>;
  /** Whether the request was a success or not */
  result: IResult;
}

/** Autogenerated input type of RevokeSplitBillingContract */
export interface IRevokeSplitBillingContractInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  /** SplitBillingContracts IDs that needs to be revoked */
  splitBillingContractIds: Array<Scalars['ID']['input']>;
}

/** Autogenerated return type of RevokeSplitBillingContract. */
export interface IRevokeSplitBillingContractPayload {
  __typename?: 'RevokeSplitBillingContractPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  revokedContracts?: Maybe<Array<ISplitBillingContract>>;
}

export interface IRgba {
  __typename?: 'Rgba';
  alpha: Scalars['Int']['output'];
  blue: Scalars['Int']['output'];
  green: Scalars['Int']['output'];
  red: Scalars['Int']['output'];
}

export interface IRightToBeForgottenReason {
  __typename?: 'RightToBeForgottenReason';
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  value?: Maybe<IRightToBeForgottenReasonEnum>;
}

export enum IRightToBeForgottenReasonEnum {
  CONSENT_WITHDRAWN = 'consent_withdrawn',
  LAWFUL_RETENTION_PERIOD_EXPIRED = 'lawful_retention_period_expired',
  NO_LONGER_NECESSARY = 'no_longer_necessary',
  OBJECTS_TO_PROCESSING = 'objects_to_processing',
  UNLAWFULLY_PROCESSED = 'unlawfully_processed'
}

export interface IRoute {
  __typename?: 'Route';
  diverted?: Maybe<IDiverted>;
  from: ILocation;
  label: Scalars['String']['output'];
  nonCombined?: Maybe<INonCombined>;
  to: ILocation;
  track?: Maybe<Array<ICoordinates>>;
}

export interface IRouteMetadata {
  __typename?: 'RouteMetadata';
  /** Returns the freshly calculated distance for a given from location, to location and modality. Does not round off. */
  calculateDistance: ICalculatedDistance;
  /** Returns the co2 emission for a given from location, to location, modality and/or vehicle */
  co2Emission: ICo2Emission;
  /** Returns the co2 emission for a given distance in km, modality and/or vehicle */
  co2EmissionFromDistance: ICo2Emission;
  /** Returns the saved distance for a given from location, to location and modality */
  distance: ICalculatedDistance;
  /** Returns whether the distance is overridable when creating or editing a mobility event */
  distanceOverridable: Scalars['Boolean']['output'];
  /** Returns whether commute distance is fixed */
  fixedCommuteDistanceEnabled: Scalars['Boolean']['output'];
  /** Returns whether non-combined rides are allowed */
  nonCombinedAllowed: Scalars['Boolean']['output'];
}


export interface IRouteMetadataCalculateDistanceArgs {
  from: ICoordinatesMutation;
  modalityId: Scalars['ID']['input'];
  on: Scalars['Date']['input'];
  to: ICoordinatesMutation;
  travelPurpose?: InputMaybe<ITravelPurposeEnum>;
}


export interface IRouteMetadataCo2EmissionArgs {
  from: ICoordinatesMutation;
  modalityId: Scalars['ID']['input'];
  to: ICoordinatesMutation;
  travelPurpose?: InputMaybe<ITravelPurposeEnum>;
  vehicleId?: InputMaybe<Scalars['ID']['input']>;
}


export interface IRouteMetadataCo2EmissionFromDistanceArgs {
  distance: Scalars['Float']['input'];
  modalityId: Scalars['ID']['input'];
  vehicleId?: InputMaybe<Scalars['ID']['input']>;
}


export interface IRouteMetadataDistanceArgs {
  from: ICoordinatesMutation;
  modalityId: Scalars['ID']['input'];
  to: ICoordinatesMutation;
  travelPurpose?: InputMaybe<ITravelPurposeEnum>;
}

export interface IRouteMutation {
  /** Overridden distance in kilometers */
  distance?: InputMaybe<Scalars['Float']['input']>;
  diverted?: InputMaybe<Scalars['Boolean']['input']>;
  from: ILocationMutation;
  nonCombined?: InputMaybe<Scalars['Boolean']['input']>;
  to: ILocationMutation;
}

export interface ISegmentPrice {
  __typename?: 'SegmentPrice';
  amount: IMoney;
  currency: Scalars['String']['output'];
  /** The duration of a segment. */
  duration?: Maybe<Scalars['Int']['output']>;
  /** The start of a segment's pricing plan. Calculated from the moment the bike was unlocked. */
  fromMinutes: Scalars['Int']['output'];
  /** The end of a segment's pricing plan. Calculated from the moment the bike was unlocked. */
  tillMinutes?: Maybe<Scalars['Int']['output']>;
  /** The amount of VAT to be added to the amount field. */
  vatPercentage: Scalars['Float']['output'];
}

/** Autogenerated input type of SendNewInvitationLink */
export interface ISendNewInvitationLinkInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The email of the employee requesting a new invitation link */
  email: Scalars['String']['input'];
}

/** Autogenerated return type of SendNewInvitationLink. */
export interface ISendNewInvitationLinkPayload {
  __typename?: 'SendNewInvitationLinkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Returns whether the mutation was successful */
  result: IResult;
}

/** Autogenerated input type of SendPasswordResetMail */
export interface ISendPasswordResetMailInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Email of the account that requests a password reset */
  email: Scalars['String']['input'];
}

/** Autogenerated return type of SendPasswordResetMail. */
export interface ISendPasswordResetMailPayload {
  __typename?: 'SendPasswordResetMailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
}

/** Autogenerated input type of SendPrivacyRequestMail */
export interface ISendPrivacyRequestMailInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Additional reason to be requested / emailed */
  reason?: InputMaybe<IPrivacyRequestReasonEnum>;
  /** Privacy type to be requested / emailed */
  requestType: IPrivacyTypeEnum;
}

/** Autogenerated return type of SendPrivacyRequestMail. */
export interface ISendPrivacyRequestMailPayload {
  __typename?: 'SendPrivacyRequestMailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
}

export interface IServiceOperator {
  __typename?: 'ServiceOperator';
  android?: Maybe<Scalars['String']['output']>;
  humanized?: Maybe<Scalars['String']['output']>;
  ios?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  orderPortal?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
}

export interface IServiceProvider {
  __typename?: 'ServiceProvider';
  humanized: Scalars['String']['output'];
  value: IContractPaymentServiceProviderEnum;
}

export interface IServices {
  __typename?: 'Services';
  confirmGreenwheels?: Maybe<IConfirmGreenwheelsPayload>;
  request?: Maybe<IRequestServicePayload>;
}


export interface IServicesConfirmGreenwheelsArgs {
  input: IConfirmGreenwheelsInput;
}


export interface IServicesRequestArgs {
  input: IRequestServiceInput;
}

export interface IServicesChangeRequest {
  __typename?: 'ServicesChangeRequest';
  /** Datetime at which the change request was approved */
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Employee that owns this change request */
  employee: IEmployee;
  id: Scalars['ID']['output'];
  /** Motivation given when filing the change request */
  motivation?: Maybe<Scalars['String']['output']>;
  /** Personal Mean of Registration this change request belongs to */
  personalMeansOfRegistration: IPersonalMeansOfRegistration;
  /** Datetime at which the change request was rejected */
  rejectedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Names of services included in this change request */
  services: Array<IPersonalMeansOfRegistrationServiceKindSubEnum>;
}

/** Autogenerated input type of SetAgreeTerms */
export interface ISetAgreeTermsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** DEPRECATED. Mutation supports now multiple ids, use ids instead */
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
}

/** Autogenerated return type of SetAgreeTerms. */
export interface ISetAgreeTermsPayload {
  __typename?: 'SetAgreeTermsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
}

/** Autogenerated input type of SetManualFavoriteCoordinates */
export interface ISetManualFavoriteCoordinatesInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the favorite you'd like to update */
  id: Scalars['ID']['input'];
  /** The latitude of the location. */
  latitude?: InputMaybe<Scalars['Coordinate']['input']>;
  /** The longitude of the location. */
  longitude?: InputMaybe<Scalars['Coordinate']['input']>;
}

/** Autogenerated return type of SetManualFavoriteCoordinates. */
export interface ISetManualFavoriteCoordinatesPayload {
  __typename?: 'SetManualFavoriteCoordinatesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** If everything was ok, this contains your new favorite record */
  favorite?: Maybe<IFavorite>;
  /** Whether the request was a success or not */
  result: IResult;
  /** If something was not ok, you can find the reason here */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of SetOptions */
export interface ISetOptionsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityCardOrderId: Scalars['ID']['input'];
  options: IOptionsMutation;
}

/** Autogenerated return type of SetOptions. */
export interface ISetOptionsPayload {
  __typename?: 'SetOptionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Updated mobility card order */
  mobilityCardOrder?: Maybe<IMobilityCardOrder>;
  /** Result of updating a mobility card order */
  result: IResult;
  /** List of validation errors while setting product options */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of SetPhoto */
export interface ISetPhotoInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityCardOrderId: Scalars['ID']['input'];
  photo: IPhoto;
}

/** Autogenerated return type of SetPhoto. */
export interface ISetPhotoPayload {
  __typename?: 'SetPhotoPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Updated mobility card order */
  mobilityCardOrder?: Maybe<IMobilityCardOrder>;
  /** Profile with the new photo */
  profile?: Maybe<IProfile>;
  /** Result of updating a mobility card order */
  result: IResult;
}

/** Autogenerated input type of SetProfile */
export interface ISetProfileInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityCardOrderId: Scalars['ID']['input'];
  profile: IProfileMutation;
}

/** Autogenerated return type of SetProfile. */
export interface ISetProfilePayload {
  __typename?: 'SetProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mobilityCardOrder?: Maybe<IMobilityCardOrder>;
  /** Changed profile */
  profile?: Maybe<IProfile>;
  /** Result of setting a profile */
  result?: Maybe<IResult>;
  /** Returns which provided attribute(s) didnt apply to the validation rules */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of SetTerms */
export interface ISetTermsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityCardOrderId: Scalars['ID']['input'];
  termsAndConditionsDocumentIds: Array<Scalars['ID']['input']>;
}

/** Autogenerated return type of SetTerms. */
export interface ISetTermsPayload {
  __typename?: 'SetTermsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Updated mobility card order */
  mobilityCardOrder?: Maybe<IMobilityCardOrder>;
  /** Result of updating a mobility card order */
  result: IResult;
}

export interface ISettlement {
  __typename?: 'Settlement';
  /** Returns the total gross, net and fiscal amount of all mobility events within this settlement */
  balance?: Maybe<IBalance>;
  committedAt?: Maybe<Scalars['DateTime']['output']>;
  financialSummary?: Maybe<Array<IFinancialSummary>>;
  /** The gross amount of money you received or had to pay */
  grossAmount: IBudget;
  happenedAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Use financial_summary instead. */
  mobilityEvents?: Maybe<Array<IMobilityEvent>>;
  /** The net amount of money you received or had to pay */
  netAmount: IBudget;
  organization?: Maybe<IOrganization>;
  /** Returns the total gross, net and fiscal amount of all mobility events of the previous settlement */
  previousBalance?: Maybe<IBalance>;
  previousSettlement?: Maybe<ISettlement>;
  /** The total gross and net charge of all mobility events within this settlement */
  totalGrossAndNetCharge: IBudget;
  /** The total gross and net reimbursement of all mobility events within this settlement */
  totalGrossAndNetReimbursement: IBudget;
  /** List of financial implications for each travel purpose */
  travelPurposes: Array<ITravelPurposeFinancialConsequences>;
  type?: Maybe<ISettlementTypeFinancialConsequences>;
  withGrossBalance?: Maybe<Scalars['Boolean']['output']>;
}

export interface ISettlementTypeFinancialConsequences {
  __typename?: 'SettlementTypeFinancialConsequences';
  enumValue?: Maybe<ISettlementTypeFinancialConsequencesEnum>;
  humanized?: Maybe<Scalars['String']['output']>;
  /** @deprecated Deprecated in favor of EnumValue field */
  value?: Maybe<Scalars['String']['output']>;
}

export enum ISettlementTypeFinancialConsequencesEnum {
  EMPLOYEEFINALSETTLEMENT = 'EmployeeFinalSettlement',
  EMPLOYEEINTERMEDIATESETTLEMENT = 'EmployeeIntermediateSettlement',
  TENTATIVEEMPLOYEEINTERMEDIATESETTLEMENT = 'TentativeEmployeeIntermediateSettlement'
}

export interface ISharedCarReservation {
  __typename?: 'SharedCarReservation';
  bookingId: Scalars['String']['output'];
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  endsAt: Scalars['DateTime']['output'];
  fromLocation: IGeoLocation;
  id: Scalars['ID']['output'];
  startable: Scalars['Boolean']['output'];
  startsAt: Scalars['DateTime']['output'];
  status: ISharedCarReservationStatus;
  vehicleBrand?: Maybe<Scalars['String']['output']>;
  vehicleKey?: Maybe<Scalars['JSON']['output']>;
  vehicleLicensePlate?: Maybe<Scalars['String']['output']>;
  vehicleModel?: Maybe<Scalars['String']['output']>;
  vehicleName?: Maybe<Scalars['String']['output']>;
}

export interface ISharedCarReservationStatus {
  __typename?: 'SharedCarReservationStatus';
  humanized: Scalars['String']['output'];
  value: ISharedVehicleReservationStatusEnum;
}

export interface ISharedVehicle extends IAvailableFeatureInterface {
  __typename?: 'SharedVehicle';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export interface ISharedVehicleReservation {
  __typename?: 'SharedVehicleReservation';
  bookingId: Scalars['String']['output'];
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  endsAt: Scalars['DateTime']['output'];
  fromLocation: IGeoLocation;
  icon: IIcon;
  id: Scalars['ID']['output'];
  modality: IModality;
  reservationId?: Maybe<Scalars['String']['output']>;
  startable: Scalars['Boolean']['output'];
  startsAt: Scalars['DateTime']['output'];
  status: ISharedCarReservationStatus;
  vehicleBrand?: Maybe<Scalars['String']['output']>;
  vehicleKey?: Maybe<Scalars['JSON']['output']>;
  vehicleLicensePlate?: Maybe<Scalars['String']['output']>;
  vehicleModel?: Maybe<Scalars['String']['output']>;
  vehicleName?: Maybe<Scalars['String']['output']>;
}

export enum ISharedVehicleReservationStatusEnum {
  ACTIVE = 'ACTIVE',
  BOOKED = 'BOOKED',
  CANCELLED = 'CANCELLED',
  CANCELLING = 'CANCELLING',
  COMPLETED = 'COMPLETED',
  CONFIRMED = 'CONFIRMED',
  ERROR = 'ERROR',
  FINISHING = 'FINISHING',
  STARTED = 'STARTED',
  STARTING = 'STARTING',
  UPDATED = 'UPDATED'
}

export interface IShuttelCheck extends IAvailableFeatureInterface {
  __typename?: 'ShuttelCheck';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** @deprecated Deprecated in favor of promocode url. This field will be empty. */
  promocode?: Maybe<Scalars['String']['output']>;
  promocodeUrl?: Maybe<Scalars['String']['output']>;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export interface IShuttelMyWheels extends IAvailableFeatureInterface {
  __typename?: 'ShuttelMyWheels';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export interface IShuttelSixt extends IAvailableFeatureInterface {
  __typename?: 'ShuttelSixt';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export interface IShuttelTaxi extends IAvailableFeatureInterface {
  __typename?: 'ShuttelTaxi';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export interface IShuttelTranzerEticket extends IAvailableFeatureInterface {
  __typename?: 'ShuttelTranzerEticket';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export interface IShuttelVecore extends IAvailableFeatureInterface {
  __typename?: 'ShuttelVecore';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export interface ISignature {
  __typename?: 'Signature';
  approvalLevel?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  mobilityEvent?: Maybe<IMobilityEvent>;
  reason?: Maybe<Scalars['String']['output']>;
  signer?: Maybe<IEmployee>;
}

export interface ISplitBillingContract {
  __typename?: 'SplitBillingContract';
  /** The url where the client can register at the payment service provider */
  accessUrl?: Maybe<Scalars['String']['output']>;
  actions: IContractActions;
  /** DateTime at which this Contract was active */
  activeAt?: Maybe<Scalars['DateTime']['output']>;
  /** DateTime at which this Contract was assigned */
  assignedAt?: Maybe<Scalars['DateTime']['output']>;
  /** To which brand this contract belongs to */
  brand?: Maybe<Scalars['String']['output']>;
  /** DateTime at which this Contract was cancelled */
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  /** The debtor number */
  debtorNumber?: Maybe<Scalars['String']['output']>;
  /** Which email address type has been used */
  emailAddressType: ISplitBillingContractEmailAddress;
  /** Contract ID */
  id: Scalars['ID']['output'];
  /** DateTime at which this Contract was invited */
  invitedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The mobidesk_entity_token */
  mobideskEntityToken?: Maybe<Scalars['String']['output']>;
  /** The parent debtor number */
  parentDebtorNumber?: Maybe<Scalars['String']['output']>;
  /** Which payment service provider has been used */
  paymentServiceProvider: IServiceProvider;
  /** DateTime at which this Contract has been requested */
  requestedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Contract UUID */
  splitBillingContractId?: Maybe<Scalars['String']['output']>;
  /** Status of Contract */
  status: IContractStatus;
  /** Contains the reason of the current status */
  statusReason?: Maybe<Scalars['String']['output']>;
  /** DateTime at which this Contract was suspended */
  suspendedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface ISplitBillingContractEmailAddress {
  __typename?: 'SplitBillingContractEmailAddress';
  humanized: Scalars['String']['output'];
  value: ISplitBillingContractEmailAddressEnum;
}

export enum ISplitBillingContractEmailAddressEnum {
  BUSINESS = 'BUSINESS',
  PERSONAL = 'PERSONAL'
}

export interface ISplitBillingContracts {
  __typename?: 'SplitBillingContracts';
  getAccessUrl?: Maybe<IGetAccessUrlPayload>;
  revoke?: Maybe<IRevokeSplitBillingContractPayload>;
}


export interface ISplitBillingContractsGetAccessUrlArgs {
  input: IGetAccessUrlInput;
}


export interface ISplitBillingContractsRevokeArgs {
  input: IRevokeSplitBillingContractInput;
}

export interface ISplitBillingInvoice {
  __typename?: 'SplitBillingInvoice';
  /** Amount invoiced */
  amount?: Maybe<IMoney>;
  /** External reference of this invoice */
  externalReference: Scalars['String']['output'];
  /** The database id of the invoice */
  id: Scalars['ID']['output'];
  /** Date of the invoice */
  invoiceDate: Scalars['Date']['output'];
  /** URL of the PDF of this invoice */
  pdfUrl?: Maybe<Scalars['String']['output']>;
  /** Transaction report of this invoice */
  transactionsReport?: Maybe<ITransactionsReport>;
  /** URL of the UBL of this invoice */
  ublUrl?: Maybe<Scalars['String']['output']>;
  /** The UUID of this invoice */
  uuid: Scalars['String']['output'];
}

export interface ISsoEnabled {
  __typename?: 'SsoEnabled';
  ssoEnabled: Scalars['Boolean']['output'];
  ssoHost: Scalars['String']['output'];
}

export interface IStarValue {
  __typename?: 'StarValue';
  humanized: Scalars['String']['output'];
  value: IStarValueEnum;
}

export enum IStarValueEnum {
  FIVE = 'FIVE',
  FOUR = 'FOUR',
  ONE = 'ONE',
  SIX = 'SIX',
  THREE = 'THREE',
  TWO = 'TWO'
}

/** Autogenerated input type of StartDonkeyRepublicReservation */
export interface IStartDonkeyRepublicReservationInput {
  bookingId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of StartDonkeyRepublicReservation. */
export interface IStartDonkeyRepublicReservationPayload {
  __typename?: 'StartDonkeyRepublicReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  donkeyRepublicReservation?: Maybe<IDonkeyRepublicReservation>;
  donkeyRepublicResult?: Maybe<IDonkeyRepublicResult>;
  result: IDonkeyRepublicResult;
}

/** Autogenerated input type of StartSharedCarReservation */
export interface IStartSharedCarReservationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
}

/** Autogenerated return type of StartSharedCarReservation. */
export interface IStartSharedCarReservationPayload {
  __typename?: 'StartSharedCarReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  sharedCarReservation: ISharedCarReservation;
}

/** Autogenerated input type of StartSharedVehicleReservation */
export interface IStartSharedVehicleReservationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
}

/** Autogenerated return type of StartSharedVehicleReservation. */
export interface IStartSharedVehicleReservationPayload {
  __typename?: 'StartSharedVehicleReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  sharedVehicleReservation: ISharedVehicleReservation;
}

export interface IStateTransitions {
  __typename?: 'StateTransitions';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  confirmedAt?: Maybe<Scalars['DateTime']['output']>;
  confirmer?: Maybe<Scalars['String']['output']>;
  rejectReason?: Maybe<Scalars['String']['output']>;
  rejectedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface IStation {
  __typename?: 'Station';
  humanized: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

export interface IStatistics {
  __typename?: 'Statistics';
  /**
   * This shows the remainder of your current budget.
   * @deprecated Use me.financialConsequences.budgetTransactionRemainder instead.
   */
  budgetBalance: IBudget;
  /** This is a representation of the kilometers you drove */
  leaseCar: ILeaseCar;
  /**
   * This is a representation of your personal balance
   * @deprecated Use me.financialConsequences.tentativeSettlement instead.
   */
  personalBalance: ISettlement;
  /**
   * This is a representation of your balance from the last settlement, or when missing the current tentative one
   * @deprecated Use me.financialConsequences.lastIntermediateSettlement instead.
   */
  settlement?: Maybe<ISettlement>;
  /** This is a representation of your travel behaviour that is split up into personal, commute and business */
  travelBehaviour: ITravelBehaviour;
}

export interface IStats {
  __typename?: 'Stats';
  /** The total CO2 emission emitted during the travelled distance */
  totalCo2EmissionInGrams?: Maybe<Scalars['Int']['output']>;
  /** The total distance travelled in meters */
  totalDistanceInMeters?: Maybe<Scalars['Int']['output']>;
  /** The kind of transport that was used */
  transportType?: Maybe<Scalars['String']['output']>;
}

export interface IStatsPerModality {
  __typename?: 'StatsPerModality';
  co2PerKm?: Maybe<Scalars['Int']['output']>;
  totalCo2?: Maybe<Scalars['Int']['output']>;
  totalKms?: Maybe<Scalars['Int']['output']>;
}

export interface IStatus {
  __typename?: 'Status';
  humanized: Scalars['String']['output'];
  label: Scalars['String']['output'];
  value: IStatusWithConfirmedEnum;
}

export enum IStatusEnum {
  ACCEPTED = 'ACCEPTED',
  DRAFT = 'DRAFT',
  REJECTED = 'REJECTED',
  SUBMITTED = 'SUBMITTED'
}

export enum IStatusWithConfirmedEnum {
  ACCEPTED = 'ACCEPTED',
  CONFIRMED = 'CONFIRMED',
  DRAFT = 'DRAFT',
  REJECTED = 'REJECTED'
}

export interface IStreetAddress {
  __typename?: 'StreetAddress';
  city?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
}

/** StudentTravelProduct(Studentenreisproduct) namespace, used to remove or add existing cards to the SRS whitelist */
export interface IStudentTravelProduct {
  __typename?: 'StudentTravelProduct';
  /** Add to the studentenreisproduct whitelist. */
  addToWhitelist?: Maybe<IAddToWhitelistPayload>;
  /** Remove from the studentenreisproduct whitelist. */
  removeFromWhitelist?: Maybe<IRemoveFromWhitelistPayload>;
}


/** StudentTravelProduct(Studentenreisproduct) namespace, used to remove or add existing cards to the SRS whitelist */
export interface IStudentTravelProductAddToWhitelistArgs {
  input: IAddToWhitelistInput;
}


/** StudentTravelProduct(Studentenreisproduct) namespace, used to remove or add existing cards to the SRS whitelist */
export interface IStudentTravelProductRemoveFromWhitelistArgs {
  input: IRemoveFromWhitelistInput;
}

export interface ISubCategory {
  __typename?: 'SubCategory';
  humanized: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

export enum ISubCategoryEnum {
  ALTIJD_VOORDEEL = 'altijd_voordeel',
  BREAKFAST = 'breakfast',
  BUDGET = 'budget',
  CAR_WASH = 'car_wash',
  COMMUTE_COMPENSATION = 'commute_compensation',
  DAL = 'dal',
  DAL_VOORDEEL = 'dal_voordeel',
  DAL_VRIJ = 'dal_vrij',
  DAYTIME = 'daytime',
  DIESEL = 'diesel',
  DINNER = 'dinner',
  DURATION = 'duration',
  ELECTRIC = 'electric',
  ELECTRICITY_FUEL_CARD = 'electricity_fuel_card',
  EVENING = 'evening',
  EXTENDED_SUBSCRIPTION = 'extended_subscription',
  FINAL = 'final',
  FLUID = 'fluid',
  FUEL_USAGE = 'fuel_usage',
  GARAGE = 'garage',
  GASOLINE = 'gasoline',
  INTERCITY_DIRECT = 'intercity_direct',
  INTERMEDIATE = 'intermediate',
  LEASE = 'lease',
  LOCATION = 'location',
  LODGING = 'lodging',
  LPG = 'lpg',
  LUNCH = 'lunch',
  MOBILITY_CARD = 'mobility_card',
  OTHER = 'other',
  OV_VRIJ = 'ov_vrij',
  OV_ZAKELIJK = 'ov_zakelijk',
  REISBALANS = 'reisbalans',
  SHARED = 'shared',
  SHED = 'shed',
  STREET = 'street',
  TRAJECT_OP_MAAT = 'traject_op_maat',
  TRAJECT_VRIJ = 'traject_vrij',
  TRANSACTION_COSTS = 'transaction_costs',
  TREIN_VRIJ = 'trein_vrij',
  UNACCOUNTED_DISTANCE = 'unaccounted_distance',
  UNKNOWN = 'unknown',
  WORK_FROM_HOME = 'work_from_home'
}

export interface ISubordinate {
  __typename?: 'Subordinate';
  /** Mobility events of this employee that are waiting a fiat */
  acceptableMobilityEvents: Array<IMobilityEvent>;
  /** Mobility events of this employee that are accepted */
  acceptedMobilityEvents: Array<IMobilityEvent>;
  /** Full name of the subordinate employee */
  fullName: Scalars['String']['output'];
  /** ID of the subordinate employee */
  id: Scalars['ID']['output'];
  /** Total number of mobility events of this employee that are waiting for a fiat */
  numAcceptableMobilityEvents: Scalars['Int']['output'];
}


export interface ISubordinateAcceptableMobilityEventsArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  offsetForId?: InputMaybe<Scalars['Int']['input']>;
}


export interface ISubordinateAcceptedMobilityEventsArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  offsetForId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ISubscription {
  __typename?: 'Subscription';
  humanized: Scalars['String']['output'];
  value: ISubscriptionEnum;
}

export enum ISubscriptionEnum {
  ALTIJD_VOORDEEL = 'ALTIJD_VOORDEEL',
  DAL = 'DAL',
  DAL_VOORDEEL = 'DAL_VOORDEEL',
  DAL_VRIJ = 'DAL_VRIJ',
  GVB_ONLY = 'GVB_ONLY',
  GVB_RANDSTAD_NOORD_ZONE = 'GVB_RANDSTAD_NOORD_ZONE',
  GVB_ZONE = 'GVB_ZONE',
  OV_VRIJ = 'OV_VRIJ',
  QBUZZ_REGIO = 'QBUZZ_REGIO',
  QBUZZ_REGIO_TREIN = 'QBUZZ_REGIO_TREIN',
  RAC = 'RAC',
  RAZ = 'RAZ',
  REIZEN_OP_REKENING = 'REIZEN_OP_REKENING',
  REIZEN_OP_SALDO = 'REIZEN_OP_SALDO',
  RET_NET = 'RET_NET',
  RET_ONLY = 'RET_ONLY',
  RET_REGIO = 'RET_REGIO',
  ROS_MOB = 'ROS_MOB',
  TRAJECT_OP_MAAT = 'TRAJECT_OP_MAAT',
  TRAJECT_VRIJ = 'TRAJECT_VRIJ',
  TRAVEL_CARD = 'TRAVEL_CARD',
  TREIN_VRIJ = 'TREIN_VRIJ',
  UOV_GEMAK_REGIO = 'UOV_GEMAK_REGIO',
  UOV_GEMAK_STAD = 'UOV_GEMAK_STAD',
  UOV_STER = 'UOV_STER',
  UOV_VRIJ_REGIO = 'UOV_VRIJ_REGIO',
  UOV_VRIJ_STAD = 'UOV_VRIJ_STAD'
}

/** Returns whether version is supported */
export interface ISupportedVersion {
  __typename?: 'SupportedVersion';
  isSupported: Scalars['Boolean']['output'];
}


/** Returns whether version is supported */
export interface ISupportedVersionIsSupportedArgs {
  version: Scalars['String']['input'];
}

export interface ITask {
  /** Humanized value for this task type. */
  humanized: Scalars['String']['output'];
  /** Contains actionable information about the task. */
  message: Scalars['String']['output'];
  /**
   * The path you can use for navigation
   * @deprecated Frontend uses hash params instead of url params, we dont want the backend to keep track of the frontends URLs. Use detailed fields on each task.
   */
  path?: Maybe<Scalars['String']['output']>;
  /** What kind of tasks type we are handling */
  type: ITaskEnum;
}

export enum ITaskEnum {
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  ACTIVATABLE_DONGLE = 'ACTIVATABLE_DONGLE',
  COMPENSATION_RIDE_TO_BE_CREATED = 'COMPENSATION_RIDE_TO_BE_CREATED',
  MOBILITY_EVENTS_WEEK = 'MOBILITY_EVENTS_WEEK',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  PRODUCT = 'PRODUCT',
  REFUND = 'REFUND'
}

export interface ITaxi {
  __typename?: 'Taxi';
  cancel?: Maybe<ICancelTaxiReservationPayload>;
  confirm?: Maybe<IConfirmTaxiReservationPayload>;
  create?: Maybe<ICreateTaxiReservationPayload>;
  /** @deprecated It is no longer possible to update a reservation... */
  update?: Maybe<IUpdateTaxiReservationPayload>;
}


export interface ITaxiCancelArgs {
  input: ICancelTaxiReservationInput;
}


export interface ITaxiConfirmArgs {
  input: IConfirmTaxiReservationInput;
}


export interface ITaxiCreateArgs {
  input: ICreateTaxiReservationInput;
}


export interface ITaxiUpdateArgs {
  input: IUpdateTaxiReservationInput;
}

export interface ITaxiFeature extends IAvailableFeatureInterface {
  __typename?: 'TaxiFeature';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export interface ITaxiReservation {
  __typename?: 'TaxiReservation';
  cancellable: Scalars['Boolean']['output'];
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  contactPhoneNumber?: Maybe<Scalars['String']['output']>;
  departAt: Scalars['DateTime']['output'];
  departure: ILocation;
  destination: ILocation;
  /** @deprecated We have lost access to this information at the moment */
  driver?: Maybe<IDriver>;
  id: Scalars['ID']['output'];
  passengerCount: Scalars['Int']['output'];
  price?: Maybe<IMoney>;
  remarks?: Maybe<Scalars['String']['output']>;
  requestedAt?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<ITaxiStatus>;
  vehicle?: Maybe<ITaxiReservationVehicle>;
  vehicleType?: Maybe<Scalars['String']['output']>;
}

export interface ITaxiReservationVehicle {
  __typename?: 'TaxiReservationVehicle';
  brand?: Maybe<Scalars['String']['output']>;
  licensePlate?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface ITaxiStatus {
  __typename?: 'TaxiStatus';
  humanized: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

/** Autogenerated input type of TerminateOwnership */
export interface ITerminateOwnershipInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The mobidesk_ownership_token of the ownership (service or feature) */
  mobideskOwnershipToken: Scalars['Int']['input'];
}

/** Autogenerated return type of TerminateOwnership. */
export interface ITerminateOwnershipPayload {
  __typename?: 'TerminateOwnershipPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
}

/** Autogenerated input type of TerminatePersonalMeansOfRegistration */
export interface ITerminatePersonalMeansOfRegistrationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the personal means of registration (mobility card) */
  id: Scalars['ID']['input'];
}

/** Autogenerated return type of TerminatePersonalMeansOfRegistration. */
export interface ITerminatePersonalMeansOfRegistrationPayload {
  __typename?: 'TerminatePersonalMeansOfRegistrationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  personalMeansOfRegistration: IPersonalMeansOfRegistration;
  result: IResult;
}

/** Autogenerated input type of TerminateReplacementManager */
export interface ITerminateReplacementManagerInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the replacement manager that should be removed */
  replacementManagerId: Scalars['ID']['input'];
}

/** Autogenerated return type of TerminateReplacementManager. */
export interface ITerminateReplacementManagerPayload {
  __typename?: 'TerminateReplacementManagerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
}

/** Autogenerated input type of TerminateVehicle */
export interface ITerminateVehicleInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Date when to terminate the vehicle */
  endsOn?: InputMaybe<Scalars['Date']['input']>;
  /** ID of the vehicle to terminate */
  id: Scalars['ID']['input'];
}

/** Autogenerated return type of TerminateVehicle. */
export interface ITerminateVehiclePayload {
  __typename?: 'TerminateVehiclePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  validationErrors: Array<IValidationError>;
  vehicle: IMeansOfTransport;
}

export interface ITermsAndConditions {
  __typename?: 'TermsAndConditions';
  /** Whether the employee agreed upon these terms and conditions */
  agreed: Scalars['Boolean']['output'];
  /** When the employee agreed upon these terms and conditions */
  agreedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The terms and conditions document */
  document: ITermsAndConditionsDocument;
  /** Whether a service requires an agreement of the terms and conditions */
  requiresAgreement: Scalars['Boolean']['output'];
}

export interface ITermsAndConditionsDocument {
  __typename?: 'TermsAndConditionsDocument';
  /** Humanized value for the kind of terms and conditions document */
  humanizedKind: ITermsAndConditionsDocumentHumanizedKind;
  /** The identifier of the terms and conditions document */
  id: Scalars['ID']['output'];
  /** @deprecated Deprecated due to introduction of humanized_kind */
  kind: ITermsAndConditionsDocumentKindEnum;
  /** The URL to the terms and conditions document */
  pdfUrl: Scalars['String']['output'];
}

export interface ITermsAndConditionsDocumentHumanizedKind {
  __typename?: 'TermsAndConditionsDocumentHumanizedKind';
  humanized?: Maybe<Scalars['String']['output']>;
  value: ITermsAndConditionsDocumentKindEnum;
}

export enum ITermsAndConditionsDocumentKindEnum {
  ADMIN_GENERAL = 'ADMIN_GENERAL',
  ADMIN_PRIVACY_STATEMENT = 'ADMIN_PRIVACY_STATEMENT',
  DKV = 'DKV',
  DONKEY_REPUBLIC = 'DONKEY_REPUBLIC',
  DRIVEN_ZOVC = 'DRIVEN_ZOVC',
  DRIVEN_ZOVC_DKV = 'DRIVEN_ZOVC_DKV',
  ENECO_DONGLE = 'ENECO_DONGLE',
  FREDDI_USER_FEE = 'FREDDI_USER_FEE',
  GENERAL = 'GENERAL',
  GREENWHEELS = 'GREENWHEELS',
  I_TRAVEL_PREPAID_ZOVC = 'I_TRAVEL_PREPAID_ZOVC',
  I_TRAVEL_ZOVC = 'I_TRAVEL_ZOVC',
  I_TRAVEL_ZOVC_DKV = 'I_TRAVEL_ZOVC_DKV',
  I_TRAVEL_ZOVC_LEGACY = 'I_TRAVEL_ZOVC_LEGACY',
  I_TRAVEL_ZOVC_TANKEN = 'I_TRAVEL_ZOVC_TANKEN',
  LEASE_BIKE = 'LEASE_BIKE',
  MOBILITEITS_MANAGER_PREPAID_ZOVC = 'MOBILITEITS_MANAGER_PREPAID_ZOVC',
  MOBILITEITS_MANAGER_ZOVC = 'MOBILITEITS_MANAGER_ZOVC',
  MOBILITEITS_MANAGER_ZOVC_DKV = 'MOBILITEITS_MANAGER_ZOVC_DKV',
  MOBILITEITS_MANAGER_ZOVC_DKV_LEGACY = 'MOBILITEITS_MANAGER_ZOVC_DKV_LEGACY',
  MOBILITEITS_MANAGER_ZOVC_LEGACY = 'MOBILITEITS_MANAGER_ZOVC_LEGACY',
  MOBILITEITS_MANAGER_ZOVC_TANKEN = 'MOBILITEITS_MANAGER_ZOVC_TANKEN',
  MOBILITEITS_MANAGER_ZOVC_TANKEN_LEGACY = 'MOBILITEITS_MANAGER_ZOVC_TANKEN_LEGACY',
  MOBILITY_CARD = 'MOBILITY_CARD',
  NOC_NSF_ZOVC = 'NOC_NSF_ZOVC',
  NS_BUSINESS_CARD = 'NS_BUSINESS_CARD',
  NS_PR = 'NS_PR',
  ORGANIZATION = 'ORGANIZATION',
  OV_FIETS = 'OV_FIETS',
  PR = 'PR',
  PRIVACY_STATEMENT = 'PRIVACY_STATEMENT',
  QBUZZ_ZOVC = 'QBUZZ_ZOVC',
  REISBALANS_ANONYMOUS_ZOVC = 'REISBALANS_ANONYMOUS_ZOVC',
  REISBALANS_UNLIMITED = 'REISBALANS_UNLIMITED',
  REISBALANS_ZOVC = 'REISBALANS_ZOVC',
  REISBALANS_ZOVC_DKV = 'REISBALANS_ZOVC_DKV',
  REISBALANS_ZOVC_TANKEN = 'REISBALANS_ZOVC_TANKEN',
  RENTAL_CAR = 'RENTAL_CAR',
  RYDER_ANONYMOUS_ZOVC = 'RYDER_ANONYMOUS_ZOVC',
  RYDER_ZOVC = 'RYDER_ZOVC',
  SHARED_VEHICLE = 'SHARED_VEHICLE',
  SHUTTEL_CHECK = 'SHUTTEL_CHECK',
  SHUTTEL_MY_WHEELS = 'SHUTTEL_MY_WHEELS',
  SHUTTEL_SIXT = 'SHUTTEL_SIXT',
  SHUTTEL_TAXI = 'SHUTTEL_TAXI',
  SHUTTEL_TRANZER_ETICKETS = 'SHUTTEL_TRANZER_ETICKETS',
  SHUTTEL_VECORE = 'SHUTTEL_VECORE',
  TAXI = 'TAXI',
  TRANZER_ETICKETS = 'TRANZER_ETICKETS',
  TRAVEL_CARD = 'TRAVEL_CARD',
  UOV_ZOVC = 'UOV_ZOVC',
  USER_FEE = 'USER_FEE',
  WITTEBRUG_ANONYMOUS_ZOVC = 'WITTEBRUG_ANONYMOUS_ZOVC',
  WITTEBRUG_ZOVC = 'WITTEBRUG_ZOVC',
  YELLOWBRICK_PR = 'YELLOWBRICK_PR',
  YELLOWBRICK_SP = 'YELLOWBRICK_SP',
  ZUIDAS_ZOVC = 'ZUIDAS_ZOVC'
}

export interface ITheme {
  __typename?: 'Theme';
  /** The url (or host-relative path) of the icon for this organization */
  icon?: Maybe<Scalars['String']['output']>;
  /** The login image that should be used for this organization */
  loginImage?: Maybe<Scalars['String']['output']>;
  /** The url (or host-relative path) of the logo for this organization */
  logo?: Maybe<Scalars['String']['output']>;
  /** The primary color of the organization */
  primaryColor?: Maybe<IColor>;
  /** The secondary color of the organization */
  secondaryColor?: Maybe<IColor>;
}


export interface IThemeIconArgs {
  expires?: InputMaybe<Scalars['DateTime']['input']>;
}


export interface IThemeLoginImageArgs {
  expires?: InputMaybe<Scalars['DateTime']['input']>;
}


export interface IThemeLogoArgs {
  expires?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ITicket {
  __typename?: 'Ticket';
  /** A base64 encoded string of a QR image, barcode or image. */
  barcode?: Maybe<Scalars['String']['output']>;
  /** Brief description of the terms of conditions that apply when purchasing this ticket. */
  conditionsOneliner?: Maybe<Scalars['String']['output']>;
  /** A link to all terms of conditions that apply when purchasing this ticket. */
  conditionsUrl: Scalars['String']['output'];
  /** The corresponding media objects for tickets. */
  media: Array<ITicketMedia>;
  /** The price of the ticket. */
  price: IPrice;
  /** The name of the purchased product */
  productName: Scalars['String']['output'];
  /** The class that applies for this ticket. */
  ticketClass?: Maybe<Scalars['String']['output']>;
  /** The name/title of the ticket. */
  ticketName: Scalars['String']['output'];
  validFrom: Scalars['DateTime']['output'];
  validTill: Scalars['DateTime']['output'];
}

export interface ITicketMedia {
  __typename?: 'TicketMedia';
  back?: Maybe<IMediaSide>;
  displayType?: Maybe<IDisplay>;
  front?: Maybe<IMediaSide>;
  type: IMedia;
  url?: Maybe<Scalars['String']['output']>;
}

export interface ITimestamp extends IWhen {
  __typename?: 'Timestamp';
  at: Scalars['DateTime']['output'];
  editable: Scalars['Boolean']['output'];
}

export enum ITokenTypeEnum {
  ACCESS = 'ACCESS',
  JWT = 'JWT',
  LEGACY = 'LEGACY'
}

export interface ITotals {
  __typename?: 'Totals';
  /** Total amount */
  amount: IMoney;
  /** Total count */
  count: Scalars['Int']['output'];
  /** Total distance */
  distance: Scalars['Float']['output'];
}

export interface ITrainClass {
  __typename?: 'TrainClass';
  humanized: Scalars['String']['output'];
  value: ITrainClassEnum;
}

export interface ITrainClassChange {
  __typename?: 'TrainClassChange';
  /** Whether the employee can request a train class change */
  canRequestTrainClassChange?: Maybe<Scalars['Boolean']['output']>;
  /** If an employee can switch train classes, but requires a reason to do so, this is the set of reasons that is available for his budget_group. */
  firstClassReasons?: Maybe<Array<IFirstClassReason>>;
  /** The train class that can be requested (if any). */
  requestableTrainClass?: Maybe<ITrainClass>;
  /** Whether a motivation is required when requesting a train class change */
  requiresTrainClassChangeMotivation?: Maybe<Scalars['Boolean']['output']>;
  /** The status of a train class request */
  trainClassStatus?: Maybe<Scalars['String']['output']>;
}

export interface ITrainClassChangeRequest {
  __typename?: 'TrainClassChangeRequest';
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  employee: IEmployee;
  firstClassReason?: Maybe<IFirstClassReason>;
  id: Scalars['ID']['output'];
  motivation?: Maybe<Scalars['String']['output']>;
  rejectedAt?: Maybe<Scalars['DateTime']['output']>;
  subscription: IPersonalMeansOfRegistrationService;
  trainClass: ITrainClass;
}

export enum ITrainClassEnum {
  FIRST = 'FIRST',
  SECOND = 'SECOND'
}

export interface ITrainClassOptions {
  __typename?: 'TrainClassOptions';
  /** If an employee can switch train classes, but requires a reason to do so, this is the set of reasons that is available for his budget_group. */
  firstClassReasons?: Maybe<Array<IFirstClassReason>>;
  /** The train classes that can be requested (if any). */
  requestableTrainClasses?: Maybe<Array<ITrainClass>>;
  /** Whether a motivation is required when requesting a train class change */
  requiresMotivation?: Maybe<Scalars['Boolean']['output']>;
}

export interface ITraject {
  __typename?: 'Traject';
  /** Start-point of the traject */
  from?: Maybe<Scalars['String']['output']>;
  /** End-point of the traject */
  to?: Maybe<Scalars['String']['output']>;
}

export interface ITransactionsReport {
  __typename?: 'TransactionsReport';
  /** PDF URL of transactions report */
  pdfUrl?: Maybe<Scalars['String']['output']>;
  /** XLS URL of transactions report */
  xlsUrl?: Maybe<Scalars['String']['output']>;
}

export interface ITranzer {
  __typename?: 'Tranzer';
  create?: Maybe<ICreateTranzerReservationPayload>;
  refresh?: Maybe<IRefreshTranzerReservationPayload>;
}


export interface ITranzerCreateArgs {
  input: ICreateTranzerReservationInput;
}


export interface ITranzerRefreshArgs {
  input: IRefreshTranzerReservationInput;
}

export interface ITranzerEticket extends IAvailableFeatureInterface {
  __typename?: 'TranzerEticket';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export interface ITranzerLeg {
  __typename?: 'TranzerLeg';
  /** The agency for this specific leg */
  agency?: Maybe<Scalars['String']['output']>;
  /** The logo URL for the agency */
  agencyLogo?: Maybe<Scalars['String']['output']>;
  /** The departure platform for this leg. Nil if not applicable. */
  arrivalPlatform?: Maybe<Scalars['String']['output']>;
  bookingId?: Maybe<Scalars['ID']['output']>;
  /** The co2 emission for the leg, measured in kilograms */
  co2Emission: Scalars['Float']['output'];
  /** The departure platform for this leg. Nil if not applicable. */
  departurePlatform?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  endTime: Scalars['DateTime']['output'];
  fromLocation: IGeoLocation;
  icon: IIcon;
  /** The identifier of the leg */
  id: Scalars['ID']['output'];
  modality: ITravelMode;
  /** The polyline for this specific leg, which can be used to draw the route on a map */
  polyline?: Maybe<Scalars['String']['output']>;
  quoteId?: Maybe<Scalars['ID']['output']>;
  /** The name of the external service that was used to obtain information. */
  service: Scalars['String']['output'];
  startTime: Scalars['DateTime']['output'];
  /** The pre-purchased tickets for the leg. */
  tickets: Array<ITicket>;
  toLocation: IGeoLocation;
}

export interface ITranzerReservation {
  __typename?: 'TranzerReservation';
  createdAt: Scalars['DateTime']['output'];
  endTime: Scalars['DateTime']['output'];
  externalId: Scalars['ID']['output'];
  /** The booking id */
  id: Scalars['ID']['output'];
  legs?: Maybe<Array<ITranzerLeg>>;
  startTime: Scalars['DateTime']['output'];
  status?: Maybe<ITranzerStatus>;
  totalCosts: IMoney;
  /** Total duration in seconds. */
  totalDuration: Scalars['Int']['output'];
}

export interface ITranzerStatus {
  __typename?: 'TranzerStatus';
  humanized: Scalars['String']['output'];
  value: ITranzerStatusEnum;
}

export enum ITranzerStatusEnum {
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  CONFIRMED = 'confirmed',
  ERROR = 'error'
}

export interface ITravelBehaviour {
  __typename?: 'TravelBehaviour';
  /** A breakdown of all modalities that you used for business travel purposes */
  business?: Maybe<IPurpose>;
  /** A breakdown of all modalities that you used for commute travel purposes */
  commute?: Maybe<IPurpose>;
  /** A breakdown of all modalities that you used for personal travel purposes */
  personal?: Maybe<IPurpose>;
  /** The total distance and co2 you are allowed to use in a month */
  usageForNorm?: Maybe<IUsage>;
  /** The total distance and co2 you have remaining to use this month */
  usageRemaining?: Maybe<IUsage>;
}

export interface ITravelMode {
  __typename?: 'TravelMode';
  humanized: Scalars['String']['output'];
  value: ITravelModeEnum;
}

export enum ITravelModeEnum {
  BUS = 'bus',
  METRO = 'metro',
  TRAIN = 'train',
  TRAM = 'tram',
  TRANSFER = 'transfer',
  WALKING = 'walking'
}

export interface ITravelPurpose {
  __typename?: 'TravelPurpose';
  humanized: Scalars['String']['output'];
  value?: Maybe<ITravelPurposeEnum>;
}

export enum ITravelPurposeEnum {
  BUSINESS = 'BUSINESS',
  COMMUTE = 'COMMUTE',
  PERSONAL = 'PERSONAL'
}

export interface ITravelPurposeFinancialConsequences {
  __typename?: 'TravelPurposeFinancialConsequences';
  /** The total gross and net charge of all mobility events within this travel purpose */
  totalGrossAndNetCharge: IBudget;
  /** The total gross and net reimbursement of all mobility events within this travel purpose */
  totalGrossAndNetReimbursement: IBudget;
  /** Travel purpose */
  travelPurpose: ITravelPurposeWithUnknown;
}

export interface ITravelPurposeWithUnknown {
  __typename?: 'TravelPurposeWithUnknown';
  humanized: Scalars['String']['output'];
  value?: Maybe<ITravelPurposeWithUnknownEnum>;
}

export enum ITravelPurposeWithUnknownEnum {
  BUSINESS = 'BUSINESS',
  COMMUTE = 'COMMUTE',
  PERSONAL = 'PERSONAL',
  UNKNOWN = 'UNKNOWN'
}

export interface ITravelerMutation {
  firstName?: InputMaybe<Scalars['String']['input']>;
  infix?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
}

export enum ITypeEnum {
  HOME = 'HOME',
  SHARED_VEHICLES_LOCATION = 'SHARED_VEHICLES_LOCATION',
  WORK = 'WORK'
}

/** Autogenerated input type of UnacceptMobilityEvent */
export interface IUnacceptMobilityEventInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityEventId: Scalars['ID']['input'];
}

/** Autogenerated return type of UnacceptMobilityEvent. */
export interface IUnacceptMobilityEventPayload {
  __typename?: 'UnacceptMobilityEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mobilityEvent?: Maybe<IMobilityEvent>;
  result: IResult;
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of UnacceptMobilityEvents */
export interface IUnacceptMobilityEventsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityEventIds: Array<Scalars['ID']['input']>;
}

/** Autogenerated return type of UnacceptMobilityEvents. */
export interface IUnacceptMobilityEventsPayload {
  __typename?: 'UnacceptMobilityEventsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  notUnacceptedMobilityEvents: Array<IMobilityEvent>;
  result: IResult;
  unacceptedMobilityEvents: Array<IMobilityEvent>;
}

/** Autogenerated input type of UnconfirmMobilityEvent */
export interface IUnconfirmMobilityEventInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityEventId: Scalars['ID']['input'];
}

/** Autogenerated return type of UnconfirmMobilityEvent. */
export interface IUnconfirmMobilityEventPayload {
  __typename?: 'UnconfirmMobilityEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mobilityEvent?: Maybe<IMobilityEvent>;
  result: IResult;
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of UnconfirmMobilityEvents */
export interface IUnconfirmMobilityEventsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityEventIds: Array<Scalars['ID']['input']>;
}

/** Autogenerated return type of UnconfirmMobilityEvents. */
export interface IUnconfirmMobilityEventsPayload {
  __typename?: 'UnconfirmMobilityEventsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  notUnconfirmedMobilityEvents: Array<IMobilityEvent>;
  result: IResult;
  unconfirmedMobilityEvents: Array<IMobilityEvent>;
}

export enum IUnitEnum {
  GRAMS = 'GRAMS',
  METERS = 'METERS',
  MILLILITERS = 'MILLILITERS',
  SECONDS = 'SECONDS',
  WATTS_PER_HOUR = 'WATTS_PER_HOUR'
}

/** Autogenerated input type of UnlockDonkeyRepublicAsset */
export interface IUnlockDonkeyRepublicAssetInput {
  bookingId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Coordinate']['input'];
  locationName: Scalars['String']['input'];
  longitude: Scalars['Coordinate']['input'];
}

/** Autogenerated return type of UnlockDonkeyRepublicAsset. */
export interface IUnlockDonkeyRepublicAssetPayload {
  __typename?: 'UnlockDonkeyRepublicAssetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  donkeyRepublicReservation?: Maybe<IDonkeyRepublicReservation>;
  donkeyRepublicResult?: Maybe<IDonkeyRepublicResult>;
  result: IDonkeyRepublicResult;
}

/** Autogenerated input type of UpdateFavorite */
export interface IUpdateFavoriteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the favorite you'd like to update */
  id: Scalars['ID']['input'];
  /** A human friendly name to recognize this favorite, for example 'Reisbalans'. */
  label?: InputMaybe<Scalars['String']['input']>;
  /** The latitude of the location. */
  latitude?: InputMaybe<Scalars['Coordinate']['input']>;
  /** The fill address information as string, for example 'Utrechtseweg 9, 3811NA Amersfoort'. */
  location?: InputMaybe<Scalars['String']['input']>;
  /** An enriched location object with complete address address informat from goolge places */
  locationId?: InputMaybe<Scalars['ID']['input']>;
  /** The longitude of the location. */
  longitude?: InputMaybe<Scalars['Coordinate']['input']>;
  /** The type of favorite this is. */
  type?: InputMaybe<IFavoriteTypeEnum>;
}

/** Autogenerated return type of UpdateFavorite. */
export interface IUpdateFavoritePayload {
  __typename?: 'UpdateFavoritePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** If everything was ok, this contains your new favorite record */
  favorite?: Maybe<IFavorite>;
  /** Whether the request was a success or not */
  result: IResult;
  /** If something was not ok, you can find the reason here */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of UpdateMileage */
export interface IUpdateMileageInput {
  amountInKilometers?: InputMaybe<Scalars['Int']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the mileage you'd like to update */
  id: Scalars['ID']['input'];
  licensePlate?: InputMaybe<Scalars['String']['input']>;
  recordedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

/** Autogenerated return type of UpdateMileage. */
export interface IUpdateMileagePayload {
  __typename?: 'UpdateMileagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Added mileage */
  mileage?: Maybe<IMileage>;
  /** Result of adding a mileage */
  result: IResult;
  /** List of validation errors while adding a mileage */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of UpdateMobilityEvent */
export interface IUpdateMobilityEventInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether the mobility event can be confirmed right away */
  confirm?: InputMaybe<Scalars['Boolean']['input']>;
  /** The id of the employment that you want to link this mobility event to */
  employmentId?: InputMaybe<Scalars['ID']['input']>;
  financial?: InputMaybe<IFinancialMutation>;
  happenedAt?: InputMaybe<Scalars['DateTime']['input']>;
  mobilityEventId: Scalars['ID']['input'];
  /** Whether or not to recalculate the consumption */
  recalculate?: InputMaybe<Scalars['Boolean']['input']>;
  route?: InputMaybe<IUpdateRouteMutation>;
  /** DEPRECATED. Submit used to be a synonym for confirm, use confirm instead */
  submit?: InputMaybe<Scalars['Boolean']['input']>;
  what?: InputMaybe<IUpdateWhatMutation>;
  why?: InputMaybe<IUpdateWhyMutation>;
}

/** Autogenerated return type of UpdateMobilityEvent. */
export interface IUpdateMobilityEventPayload {
  __typename?: 'UpdateMobilityEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mobilityEvent?: Maybe<IMobilityEvent>;
  result: IResult;
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of UpdateMobilityEvents */
export interface IUpdateMobilityEventsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not to confirm the mobility events right away */
  confirm?: InputMaybe<Scalars['Boolean']['input']>;
  /** The id of the employment that you want to link this mobility event to */
  employmentId?: InputMaybe<Scalars['ID']['input']>;
  mobilityEventIds: Array<Scalars['ID']['input']>;
  /** DEPRECATED. Submit used to be a synonym for confirm, use confirm instead */
  submit?: InputMaybe<Scalars['Boolean']['input']>;
  what?: InputMaybe<IUpdateWhatMutation>;
  why?: InputMaybe<IUpdateWhyMutation>;
}

/** Autogenerated return type of UpdateMobilityEvents. */
export interface IUpdateMobilityEventsPayload {
  __typename?: 'UpdateMobilityEventsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  days: Array<IDayWithMobilityEvents>;
  notUpdatedMobilityEvents: Array<IMobilityEvent>;
  result: IResult;
  updatedMobilityEvents: Array<IMobilityEvent>;
}

export interface IUpdateRouteMutation {
  /** Overridden distance in kilometers */
  distance?: InputMaybe<Scalars['Float']['input']>;
  diverted?: InputMaybe<Scalars['Boolean']['input']>;
  from?: InputMaybe<ILocationMutation>;
  nonCombined?: InputMaybe<Scalars['Boolean']['input']>;
  to?: InputMaybe<ILocationMutation>;
}

/** Autogenerated input type of UpdateTaxiReservation */
export interface IUpdateTaxiReservationInput {
  at?: InputMaybe<Scalars['DateTime']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  departure?: InputMaybe<ILocationMutation>;
  destination?: InputMaybe<ILocationMutation>;
  passengers?: InputMaybe<Scalars['Int']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  taxiReservationId?: InputMaybe<Scalars['ID']['input']>;
  vehicleType?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of UpdateTaxiReservation. */
export interface IUpdateTaxiReservationPayload {
  __typename?: 'UpdateTaxiReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  taxiReservation?: Maybe<ITaxiReservation>;
}

/** Autogenerated input type of UpdateUserProfile */
export interface IUpdateUserProfileInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Current password of the user. Required for a password change once the password is set, if left empty password is not changed. */
  currentPassword?: InputMaybe<Scalars['String']['input']>;
  /** Date of birth of the user */
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  /** Preferred purpose of supplier transactions */
  defaultMobilityCardTransactionTravelPurpose?: InputMaybe<ITravelPurposeEnum>;
  /** Preferred purpose of supplier transactions on weekends */
  defaultMobilityCardWeekendTransactionTravelPurpose?: InputMaybe<ITravelPurposeEnum>;
  /** Email address of the user */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Employee number of the user, that is mostly used internally. */
  employeeNumber?: InputMaybe<Scalars['String']['input']>;
  /** First name of the user */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Fixed commute distance used by default for commute rides if enabled in the budget group. */
  fixedCommuteDistance?: InputMaybe<Scalars['Float']['input']>;
  /** Home address of the user */
  homeAddress?: InputMaybe<IUserProfileAddress>;
  /** Infix of the user */
  infix?: InputMaybe<Scalars['String']['input']>;
  /** Initials of the user */
  initials?: InputMaybe<Scalars['String']['input']>;
  /** Last name of the user */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** New password of the user. Required for a password change, if left empty password is not changed. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Confirmation of new password of the user. Required for a password change, if left empty password is not changed. */
  passwordConfirmation?: InputMaybe<Scalars['String']['input']>;
  /** Phone number of the user. Accepted formats with fake phone numbers: +31612345678 (NL), +3214812345 (BE), +4912345678 (DE), 0612345678, 06-12345678, 0401234567, 040-1234567, 0455123456, 0455-123456, all other European numbers. See employee.rb for specific regex. */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** User-preferred purpose of GPS registered rides */
  preferredTravelPurpose?: InputMaybe<ITravelPurposeEnum>;
  /** Agreed Terms and conditions */
  termsAndConditionsDocumentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Whether the users opted in to use the new interface or not. */
  usesNewInterface?: InputMaybe<Scalars['Boolean']['input']>;
  /** Work address of the user */
  workAddress?: InputMaybe<IUserProfileAddress>;
}

/** Autogenerated return type of UpdateUserProfile. */
export interface IUpdateUserProfilePayload {
  __typename?: 'UpdateUserProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Updated employee */
  me?: Maybe<IEmployee>;
  /** Returns whether the mutation was successful */
  result: IResult;
  /** Returns which provided attribute(s) didnt apply to the validation rules */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of UpdateVehicle */
export interface IUpdateVehicleInput {
  brand?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  endsOn?: InputMaybe<Scalars['Date']['input']>;
  fuelType?: InputMaybe<IVehicleFuelTypeEnum>;
  licensePlate?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  startsOn: Scalars['Date']['input'];
  vehicleId: Scalars['ID']['input'];
}

/** Autogenerated return type of UpdateVehicle. */
export interface IUpdateVehiclePayload {
  __typename?: 'UpdateVehiclePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  validationErrors: Array<IValidationError>;
  vehicle?: Maybe<IMeansOfTransport>;
}

export interface IUpdateWhatMutation {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  modalityId?: InputMaybe<Scalars['ID']['input']>;
  /** Reimbursable category method. If given, properties for the other reimbursable category are nullified in the backend since theyre mutually exclusive. */
  reimbursableCategoryMethodValue?: InputMaybe<IReimbursableCategoryMethodEnum>;
  sharedRidePassengers?: InputMaybe<Array<Scalars['String']['input']>>;
  vehicleId?: InputMaybe<Scalars['ID']['input']>;
}

export interface IUpdateWhyMutation {
  explanation?: InputMaybe<Scalars['String']['input']>;
  /** Consumption amount in km */
  personalDistance?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  refundedMobilityEventId?: InputMaybe<Scalars['ID']['input']>;
  travelPurpose?: InputMaybe<ITravelPurposeEnum>;
}

export interface IUsage {
  __typename?: 'Usage';
  /** The total CO2 emission emitted during the travelled distance */
  co2EmissionInGrams?: Maybe<Scalars['Int']['output']>;
  /** The total distance in meters */
  distanceInMeters?: Maybe<Scalars['Int']['output']>;
}

export interface IUserProfileAddress {
  city?: InputMaybe<Scalars['String']['input']>;
  countryIsoCode?: InputMaybe<ICountryIsoCodeEnum>;
  street: Scalars['String']['input'];
  streetNumber: Scalars['String']['input'];
  streetNumberSuffix?: InputMaybe<Scalars['String']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
}

/** Information on budget groups */
export interface IV2BudgetGroup {
  __typename?: 'V2BudgetGroup';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
}

/** Information on organization */
export interface IV2Organization {
  __typename?: 'V2Organization';
  /** Published budget groups */
  budgetGroups?: Maybe<Array<IV2BudgetGroup>>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
}

export interface IValidationError {
  __typename?: 'ValidationError';
  /** Attribute with errors */
  attribute: Scalars['String']['output'];
  /** Full error as a sentence */
  fullMessages: Array<Scalars['String']['output']>;
  /** Individual error messages for the attribute */
  messages: Array<Scalars['String']['output']>;
}

export interface IVatRate {
  __typename?: 'VatRate';
  humanized: Scalars['String']['output'];
}

export interface IVehicle {
  __typename?: 'Vehicle';
  create?: Maybe<ICreateVehiclePayload>;
  terminate?: Maybe<ITerminateVehiclePayload>;
  update?: Maybe<IUpdateVehiclePayload>;
}


export interface IVehicleCreateArgs {
  input: ICreateVehicleInput;
}


export interface IVehicleTerminateArgs {
  input: ITerminateVehicleInput;
}


export interface IVehicleUpdateArgs {
  input: IUpdateVehicleInput;
}

export interface IVehicleActions {
  __typename?: 'VehicleActions';
  editable: Scalars['Boolean']['output'];
  terminatable: Scalars['Boolean']['output'];
}

export interface IVehicleAsset extends IMultiModalAvailability {
  __typename?: 'VehicleAsset';
  /** Estimated battery level of this vehicle as a percentage, ranging from 0-100 */
  batteryLevel?: Maybe<Scalars['Int']['output']>;
  deeplink?: Maybe<Scalars['String']['output']>;
  /** Optional details about the vehicle */
  description?: Maybe<Scalars['String']['output']>;
  geoLocation: ICoordinates;
  icon: IIcon;
  /** Unique identifier for this particular vehicle */
  id: Scalars['String']['output'];
  modality: IJourneyMode;
  /** Human readable name for this asset */
  name?: Maybe<Scalars['String']['output']>;
  /** The name of the operator for this vehicle */
  operator: Scalars['String']['output'];
  /** Estimated range for this vehicle without needing to refuel, in meters */
  range?: Maybe<Scalars['Int']['output']>;
  serviceOperator?: Maybe<IServiceOperator>;
  type: Scalars['String']['output'];
  /** Name of the type of this vehicle */
  vehicleTypeName?: Maybe<Scalars['String']['output']>;
}

export enum IVehicleFuelTypeEnum {
  BIO_ETHANOL = 'BIO_ETHANOL',
  CNG = 'CNG',
  DIESEL = 'DIESEL',
  ELECTRIC = 'ELECTRIC',
  HYBRID = 'HYBRID',
  HYBRID_DIESEL = 'HYBRID_DIESEL',
  HYBRID_PETROL = 'HYBRID_PETROL',
  HYBRID_PLUG_IN_PETROL = 'HYBRID_PLUG_IN_PETROL',
  HYDROGEN = 'HYDROGEN',
  LPG = 'LPG',
  OTHER = 'OTHER',
  PETROL = 'PETROL'
}

export interface IVehicleInformation {
  __typename?: 'VehicleInformation';
  brand?: Maybe<Scalars['String']['output']>;
  co2EmissionPerKilometer?: Maybe<Scalars['Int']['output']>;
  energyLabel?: Maybe<Scalars['String']['output']>;
  fuelType?: Maybe<IFuel>;
  model?: Maybe<Scalars['String']['output']>;
}

export interface IVehicleModalityKind {
  __typename?: 'VehicleModalityKind';
  humanized: Scalars['String']['output'];
  value: IModalityKindEnum;
}

export interface IVehicleOptions {
  __typename?: 'VehicleOptions';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

export enum IVehicleStatusEnum {
  ARCHIVED = 'ARCHIVED',
  CURRENT = 'CURRENT',
  FUTURE = 'FUTURE'
}

export interface IWhat {
  __typename?: 'What';
  /** Category of this mobility event. Examples: Ride, Parking costs, other, etc. */
  category: ICategory;
  /** Indicates whether the what of this mobility event is editable */
  editable: Scalars['Boolean']['output'];
  /** Humanized string, made up of category and modality */
  humanized: Scalars['String']['output'];
  /** Icon that corresponds to the category of the mobility event */
  icon: IIcon;
  /** @deprecated This field can be either a vehicle (if present) or a modality, you should use the vehicle and / or modality fields instead. Be aware, this field can only be used with fields that exist on both a vehicle and a modality. */
  meansOfTransport?: Maybe<IMeansOfTransport>;
  /** In case of the mobility event category being a ride, the modality field returns the way this ride was made. Examples: Public transport, car, bike, etc. */
  modality?: Maybe<IModality>;
  /** The reimbursable category method. Ride, Route or Both */
  reimbursableCategoryMethod: IReimbursableCategoryMethod;
  /** If this was a shared ride, this field returns the list of names of the passengers that shared the ride with you */
  sharedRidePassengers?: Maybe<Array<Scalars['String']['output']>>;
  /** In case of the mobility event category being a ride, the vehicle field returns the specific vehicle that was used for this ride */
  vehicle?: Maybe<IMeansOfTransport>;
}

export interface IWhatOption {
  __typename?: 'WhatOption';
  category: ICategory;
  icon: IIcon;
  modality?: Maybe<IModality>;
  /** Which reimbursable category method can be chosen */
  reimbursableCategoryMethod?: Maybe<IReimbursableCategoryMethod>;
  vehicle?: Maybe<IMeansOfTransport>;
}

export interface IWhen {
  editable: Scalars['Boolean']['output'];
}

export type IWhere = ILocation | IRentalLocations | IRoute;

export interface IWhy {
  __typename?: 'Why';
  /** @deprecated Use diverted on Route instead of Why */
  diverted?: Maybe<IDiverted>;
  /** Indicates whether the why of this mobility event is editable */
  editable: Scalars['Boolean']['output'];
  explanation?: Maybe<IExplanation>;
  /** Humanized value for ride that was refunded by this refund */
  humanizedRefundedMobilityEvent?: Maybe<Scalars['String']['output']>;
  legs?: Maybe<Array<ILeg>>;
  /** Consumption amount in kilometers when transaction is distance based */
  personalDistance?: Maybe<Scalars['Int']['output']>;
  project?: Maybe<IProject>;
  /** Ride that was refunded by this refund */
  refundedMobilityEventId?: Maybe<Scalars['Int']['output']>;
  stateTransitions: IStateTransitions;
  /** @deprecated Deprecated because of move from submitted -> confirmed. Status can now be retrieved directly on the mobilityEvent via mobilityEvent.status. */
  status: IWhyStatus;
  travelPurpose: ITravelPurpose;
}

export interface IWhyMutation {
  explanation?: InputMaybe<Scalars['String']['input']>;
  /** Personal consumption amount in kilometers */
  personalDistance?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  travelPurpose: ITravelPurposeEnum;
}

export interface IWhyStatus {
  __typename?: 'WhyStatus';
  humanized: Scalars['String']['output'];
  label: Scalars['String']['output'];
  /** Mobility event status with submitted instead of confirmed */
  value: IStatusEnum;
}

export interface IWrpm {
  __typename?: 'Wrpm';
  modality?: Maybe<IWrpmModality>;
  /** Total stats for this modality for business and commute travel purposes */
  statsPerModality?: Maybe<IStatsPerModality>;
  /** Total stats for this modality for travel purpose business */
  statsPerModalityBusiness?: Maybe<IStatsPerModality>;
  /** Total stats for this modality for travel purpose commute */
  statsPerModalityCommute?: Maybe<IStatsPerModality>;
}

export interface IWrpmModality {
  __typename?: 'WrpmModality';
  humanized?: Maybe<Scalars['String']['output']>;
  value?: Maybe<IWrpmModalityEnum>;
}

export enum IWrpmModalityEnum {
  CAR_DIESEL = 'CAR_DIESEL',
  CAR_ELECTRIC = 'CAR_ELECTRIC',
  CAR_HYBRID = 'CAR_HYBRID',
  CAR_OTHER = 'CAR_OTHER',
  CAR_PETROL = 'CAR_PETROL',
  LEGS_OR_BICYCLE = 'LEGS_OR_BICYCLE',
  MOTORCYCLE_ELECTRIC = 'MOTORCYCLE_ELECTRIC',
  MOTORCYCLE_PETROL = 'MOTORCYCLE_PETROL',
  OV = 'OV',
  SCOOTER_ELECTRIC = 'SCOOTER_ELECTRIC',
  SCOOTER_PETROL = 'SCOOTER_PETROL'
}

export interface IYellowbrickService {
  __typename?: 'YellowbrickService';
  identity?: Maybe<IPersonalMeansOfRegistrationIdentity>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface IYellowbrickSp extends IAvailableFeatureInterface {
  __typename?: 'YellowbrickSp';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export interface IZone {
  __typename?: 'Zone';
  humanized: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

export interface IReisbalansMutation {
  __typename?: 'reisbalansMutation';
  acceptMobilityEvents?: Maybe<IAcceptMobilityEventsPayload>;
  activateEnecoDongle?: Maybe<IActivateEnecoDonglePayload>;
  activatePersonalMeansOfRegistration?: Maybe<IActivatePersonalMeansOfRegistrationPayload>;
  addPushNotificationToken?: Maybe<IAddPushNotificationTokenPayload>;
  /** Login admin with email and password. */
  adminLogin?: Maybe<IAdminLoginPayload>;
  assignReplacementManager?: Maybe<IAssignReplacementManagerPayload>;
  /** @deprecated Use cancelSharedVehicleInformation */
  cancelSharedCarReservation?: Maybe<ICancelSharedCarReservationPayload>;
  cancelSharedVehicleReservation?: Maybe<ICancelSharedVehicleReservationPayload>;
  /** @deprecated Use the taxi/cancel endpoint please */
  cancelTaxiReservation?: Maybe<ICancelTaxiReservationPayload>;
  /** Configure 2FA for this user */
  configureMfa?: Maybe<IConfigureMfaPayload>;
  confirmMobilityEvent?: Maybe<IConfirmMobilityEventPayload>;
  confirmMobilityEvents?: Maybe<IConfirmMobilityEventsPayload>;
  /** @deprecated Use confirmSharedVehicleReservatoin */
  confirmSharedCarReservation?: Maybe<IConfirmSharedCarReservationPayload>;
  confirmSharedVehicleReservation?: Maybe<IConfirmSharedVehicleReservationPayload>;
  /** @deprecated Use the taxi/confirm endpoint please */
  confirmTaxiReservation?: Maybe<IConfirmTaxiReservationPayload>;
  copyMobilityEvents?: Maybe<ICopyMobilityEventsPayload>;
  copyMobilityEventsForDay?: Maybe<ICopyMobilityEventsForDayPayload>;
  createCommuteCompensation?: Maybe<ICreateCommuteCompensationPayload>;
  createCommuteRides?: Maybe<ICreateCommuteRidesPayload>;
  /** @deprecated Done automatically by backend after linking fine with the missed trip */
  createCompensationMobilityEvent?: Maybe<ICreateCompensationMobilityEventPayload>;
  createMobilityEvent?: Maybe<ICreateMobilityEventPayload>;
  /** @deprecated Use the taxi/create endpoint please */
  createTaxiReservation?: Maybe<ICreateTaxiReservationPayload>;
  /** DEPRECATED. Only used by Freddi to update employees. Still used by RBW to reset password. */
  createUserProfile?: Maybe<ICreateUserProfilePayload>;
  /** @deprecated Use the vehicle/create endpoint instead */
  createVehicle?: Maybe<ICreateVehiclePayload>;
  createWorkFromHome?: Maybe<ICreateWorkFromHomePayload>;
  destroyMobilityEvent?: Maybe<IDestroyMobilityEventPayload>;
  destroyMobilityEvents?: Maybe<IDestroyMobilityEventsPayload>;
  destroyProfileAddress?: Maybe<IDestroyProfileAddressPayload>;
  directDebitMandates?: Maybe<IDirectDebitMandates>;
  donkeyRepublic?: Maybe<IDonkeyRepublicBooking>;
  favorites?: Maybe<IFavorites>;
  /** @deprecated Use finishSharedVehicleReservation */
  finishSharedCarReservation?: Maybe<IFinishSharedCarReservationPayload>;
  finishSharedVehicleReservation?: Maybe<IFinishSharedVehicleReservationPayload>;
  leaseBikeOrder?: Maybe<ILeaseBikeOrder>;
  /** Login user with email and password. It creates a new session for the user and it optionally returns a token. */
  login?: Maybe<ILoginPayload>;
  /** Logout user. Destroys the session, optionally receives a token that will be revoked */
  logout?: Maybe<ILogoutPayload>;
  markMessageAsRead?: Maybe<IMarkMessageAsReadPayload>;
  mergeMobilityEvents?: Maybe<IMergeMobilityEventsPayload>;
  mileages?: Maybe<IMileages>;
  mobilityCardOrders?: Maybe<IMobilityCardOrders>;
  parking?: Maybe<IParking>;
  rejectMobilityEvents?: Maybe<IRejectMobilityEventsPayload>;
  reportPersonalMeansOfRegistration?: Maybe<IReportPersonalMeansOfRegistrationPayload>;
  /** @deprecated Use requestFeature instead */
  requestDonkeyRepublicFeature?: Maybe<IRequestDonkeyRepublicFeaturePayload>;
  requestFeature?: Maybe<IRequestFeaturePayload>;
  requestTrainClassChange?: Maybe<IRequestTrainClassChangePayload>;
  resetPassword?: Maybe<IResetPasswordPayload>;
  reuploadPhoto?: Maybe<IReuploadPhotoPayload>;
  sendNewInvitationLink?: Maybe<ISendNewInvitationLinkPayload>;
  sendPasswordResetMail?: Maybe<ISendPasswordResetMailPayload>;
  sendPrivacyRequestMail?: Maybe<ISendPrivacyRequestMailPayload>;
  services?: Maybe<IServices>;
  setAgreeTerms?: Maybe<ISetAgreeTermsPayload>;
  splitBillingContracts?: Maybe<ISplitBillingContracts>;
  /** @deprecated Use startSharedVehicleReservation */
  startSharedCarReservation?: Maybe<IStartSharedCarReservationPayload>;
  startSharedVehicleReservation?: Maybe<IStartSharedVehicleReservationPayload>;
  studentTravelProduct?: Maybe<IStudentTravelProduct>;
  taxi?: Maybe<ITaxi>;
  terminateOwnership?: Maybe<ITerminateOwnershipPayload>;
  terminatePersonalMeansOfRegistration?: Maybe<ITerminatePersonalMeansOfRegistrationPayload>;
  terminateReplacementManager?: Maybe<ITerminateReplacementManagerPayload>;
  /** @deprecated Use the vehicle/terminate endpoint instead */
  terminateVehicle?: Maybe<ITerminateVehiclePayload>;
  tranzer?: Maybe<ITranzer>;
  unacceptMobilityEvent?: Maybe<IUnacceptMobilityEventPayload>;
  unacceptMobilityEvents?: Maybe<IUnacceptMobilityEventsPayload>;
  unconfirmMobilityEvent?: Maybe<IUnconfirmMobilityEventPayload>;
  unconfirmMobilityEvents?: Maybe<IUnconfirmMobilityEventsPayload>;
  updateMobilityEvent?: Maybe<IUpdateMobilityEventPayload>;
  updateMobilityEvents?: Maybe<IUpdateMobilityEventsPayload>;
  updateTaxiReservation?: Maybe<IUpdateTaxiReservationPayload>;
  updateUserProfile?: Maybe<IUpdateUserProfilePayload>;
  vehicle?: Maybe<IVehicle>;
}


export interface IReisbalansMutationAcceptMobilityEventsArgs {
  input: IAcceptMobilityEventsInput;
}


export interface IReisbalansMutationActivateEnecoDongleArgs {
  input: IActivateEnecoDongleInput;
}


export interface IReisbalansMutationActivatePersonalMeansOfRegistrationArgs {
  input: IActivatePersonalMeansOfRegistrationInput;
}


export interface IReisbalansMutationAddPushNotificationTokenArgs {
  input: IAddPushNotificationTokenInput;
}


export interface IReisbalansMutationAdminLoginArgs {
  input: IAdminLoginInput;
}


export interface IReisbalansMutationAssignReplacementManagerArgs {
  input: IAssignReplacementManagerInput;
}


export interface IReisbalansMutationCancelSharedCarReservationArgs {
  input: ICancelSharedCarReservationInput;
}


export interface IReisbalansMutationCancelSharedVehicleReservationArgs {
  input: ICancelSharedVehicleReservationInput;
}


export interface IReisbalansMutationCancelTaxiReservationArgs {
  input: ICancelTaxiReservationInput;
}


export interface IReisbalansMutationConfigureMfaArgs {
  input: IConfigureMfaInput;
}


export interface IReisbalansMutationConfirmMobilityEventArgs {
  input: IConfirmMobilityEventInput;
}


export interface IReisbalansMutationConfirmMobilityEventsArgs {
  input: IConfirmMobilityEventsInput;
}


export interface IReisbalansMutationConfirmSharedCarReservationArgs {
  input: IConfirmSharedCarReservationInput;
}


export interface IReisbalansMutationConfirmSharedVehicleReservationArgs {
  input: IConfirmSharedVehicleReservationInput;
}


export interface IReisbalansMutationConfirmTaxiReservationArgs {
  input: IConfirmTaxiReservationInput;
}


export interface IReisbalansMutationCopyMobilityEventsArgs {
  input: ICopyMobilityEventsInput;
}


export interface IReisbalansMutationCopyMobilityEventsForDayArgs {
  input: ICopyMobilityEventsForDayInput;
}


export interface IReisbalansMutationCreateCommuteCompensationArgs {
  input: ICreateCommuteCompensationInput;
}


export interface IReisbalansMutationCreateCommuteRidesArgs {
  input: ICreateCommuteRidesInput;
}


export interface IReisbalansMutationCreateCompensationMobilityEventArgs {
  input: ICreateCompensationMobilityEventInput;
}


export interface IReisbalansMutationCreateMobilityEventArgs {
  input: ICreateMobilityEventInput;
}


export interface IReisbalansMutationCreateTaxiReservationArgs {
  input: ICreateTaxiReservationInput;
}


export interface IReisbalansMutationCreateUserProfileArgs {
  input: ICreateUserProfileInput;
}


export interface IReisbalansMutationCreateVehicleArgs {
  input: ICreateVehicleInput;
}


export interface IReisbalansMutationCreateWorkFromHomeArgs {
  input: ICreateWorkFromHomeInput;
}


export interface IReisbalansMutationDestroyMobilityEventArgs {
  input: IDestroyMobilityEventInput;
}


export interface IReisbalansMutationDestroyMobilityEventsArgs {
  input: IDestroyMobilityEventsInput;
}


export interface IReisbalansMutationDestroyProfileAddressArgs {
  input: IDestroyProfileAddressInput;
}


export interface IReisbalansMutationFinishSharedCarReservationArgs {
  input: IFinishSharedCarReservationInput;
}


export interface IReisbalansMutationFinishSharedVehicleReservationArgs {
  input: IFinishSharedVehicleReservationInput;
}


export interface IReisbalansMutationLoginArgs {
  input: ILoginInput;
}


export interface IReisbalansMutationLogoutArgs {
  input: ILogoutInput;
}


export interface IReisbalansMutationMarkMessageAsReadArgs {
  input: IMarkMessageAsReadInput;
}


export interface IReisbalansMutationMergeMobilityEventsArgs {
  input: IMergeMobilityEventsInput;
}


export interface IReisbalansMutationRejectMobilityEventsArgs {
  input: IRejectMobilityEventsInput;
}


export interface IReisbalansMutationReportPersonalMeansOfRegistrationArgs {
  input: IReportPersonalMeansOfRegistrationInput;
}


export interface IReisbalansMutationRequestDonkeyRepublicFeatureArgs {
  input: IRequestDonkeyRepublicFeatureInput;
}


export interface IReisbalansMutationRequestFeatureArgs {
  input: IRequestFeatureInput;
}


export interface IReisbalansMutationRequestTrainClassChangeArgs {
  input: IRequestTrainClassChangeInput;
}


export interface IReisbalansMutationResetPasswordArgs {
  input: IResetPasswordInput;
}


export interface IReisbalansMutationReuploadPhotoArgs {
  input: IReuploadPhotoInput;
}


export interface IReisbalansMutationSendNewInvitationLinkArgs {
  input: ISendNewInvitationLinkInput;
}


export interface IReisbalansMutationSendPasswordResetMailArgs {
  input: ISendPasswordResetMailInput;
}


export interface IReisbalansMutationSendPrivacyRequestMailArgs {
  input: ISendPrivacyRequestMailInput;
}


export interface IReisbalansMutationSetAgreeTermsArgs {
  input: ISetAgreeTermsInput;
}


export interface IReisbalansMutationStartSharedCarReservationArgs {
  input: IStartSharedCarReservationInput;
}


export interface IReisbalansMutationStartSharedVehicleReservationArgs {
  input: IStartSharedVehicleReservationInput;
}


export interface IReisbalansMutationTerminateOwnershipArgs {
  input: ITerminateOwnershipInput;
}


export interface IReisbalansMutationTerminatePersonalMeansOfRegistrationArgs {
  input: ITerminatePersonalMeansOfRegistrationInput;
}


export interface IReisbalansMutationTerminateReplacementManagerArgs {
  input: ITerminateReplacementManagerInput;
}


export interface IReisbalansMutationTerminateVehicleArgs {
  input: ITerminateVehicleInput;
}


export interface IReisbalansMutationUnacceptMobilityEventArgs {
  input: IUnacceptMobilityEventInput;
}


export interface IReisbalansMutationUnacceptMobilityEventsArgs {
  input: IUnacceptMobilityEventsInput;
}


export interface IReisbalansMutationUnconfirmMobilityEventArgs {
  input: IUnconfirmMobilityEventInput;
}


export interface IReisbalansMutationUnconfirmMobilityEventsArgs {
  input: IUnconfirmMobilityEventsInput;
}


export interface IReisbalansMutationUpdateMobilityEventArgs {
  input: IUpdateMobilityEventInput;
}


export interface IReisbalansMutationUpdateMobilityEventsArgs {
  input: IUpdateMobilityEventsInput;
}


export interface IReisbalansMutationUpdateTaxiReservationArgs {
  input: IUpdateTaxiReservationInput;
}


export interface IReisbalansMutationUpdateUserProfileArgs {
  input: IUpdateUserProfileInput;
}

export interface IReisbalansQuery {
  __typename?: 'reisbalansQuery';
  /** Convert the Base64 encoded data string, generated by reading the QR code of a user profile from the portal, to a valid apiToken */
  apiTokenForQrCode?: Maybe<IResultWithAccessTokens>;
  configurationProfileForHost?: Maybe<IConfigurationProfileForHost>;
  currentTime: Scalars['DateTime']['output'];
  customerService?: Maybe<ICustomerService>;
  employeeDetails?: Maybe<IEmployeeDetailsResponse>;
  /** All external URLs used throughout the application */
  externalUrls: IExternalUrls;
  /** Fuel types that can be used for vehicle */
  fuelTypes?: Maybe<Array<IFuel>>;
  getUserByInviteCode?: Maybe<IEmployee>;
  googlePlaces?: Maybe<IGooglePlaces>;
  inviteTokenValid?: Maybe<Scalars['Boolean']['output']>;
  /**
   * If you are logged in and supply a httponly cookie, you can request a JWT here.
   * @deprecated Use reauthorize field instead
   */
  jwt?: Maybe<Scalars['String']['output']>;
  me?: Maybe<IEmployee>;
  /**
   * Returns the url where refund can be claimed from NS for a delayed train journey
   * @deprecated Use externalUrls.nsRefundUrl instead
   */
  nsRefundUrl: INsRefundUrl;
  ovFietsLocationsByCoordinates?: Maybe<Array<IOvFietsLocation>>;
  ovFietsLocationsByFavoriteId?: Maybe<Array<IOvFietsLocation>>;
  password?: Maybe<IPassword>;
  places?: Maybe<IPlaces>;
  /** If you are logged in and supply a httponly cookie, you can request a pair of access and refresh tokens here. */
  reauthorize?: Maybe<IAuth>;
  /** Returns wether SSO is enabled or not and an authentication url */
  ssoEnabled?: Maybe<ISsoEnabled>;
  /** All NS Stations */
  stations?: Maybe<Array<IStation>>;
  supportedVersion?: Maybe<ISupportedVersion>;
  /** Terms documents for the current platform and brand. */
  termsAndConditionsDocuments?: Maybe<Array<ITermsAndConditionsDocument>>;
  theme?: Maybe<ITheme>;
  /** @deprecated Only use in the app to migrate. */
  tokens?: Maybe<IAuth>;
  vehicleInformation?: Maybe<IVehicleInformation>;
}


export interface IReisbalansQueryApiTokenForQrCodeArgs {
  data: Scalars['String']['input'];
  tokenType?: InputMaybe<ITokenTypeEnum>;
}


export interface IReisbalansQueryConfigurationProfileForHostArgs {
  host: Scalars['String']['input'];
}


export interface IReisbalansQueryCustomerServiceArgs {
  host?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}


export interface IReisbalansQueryEmployeeDetailsArgs {
  key: Scalars['String']['input'];
}


export interface IReisbalansQueryGetUserByInviteCodeArgs {
  email: Scalars['String']['input'];
  inviteCode: Scalars['String']['input'];
}


export interface IReisbalansQueryInviteTokenValidArgs {
  inviteToken: Scalars['String']['input'];
}


export interface IReisbalansQueryJwtArgs {
  refreshToken?: InputMaybe<Scalars['String']['input']>;
}


export interface IReisbalansQueryOvFietsLocationsByCoordinatesArgs {
  at: ICoordinatesMutation;
  radius: Scalars['Int']['input'];
}


export interface IReisbalansQueryOvFietsLocationsByFavoriteIdArgs {
  id: Scalars['ID']['input'];
  radius: Scalars['Int']['input'];
}


export interface IReisbalansQueryReauthorizeArgs {
  refreshToken: Scalars['String']['input'];
}


export interface IReisbalansQuerySsoEnabledArgs {
  username: Scalars['String']['input'];
}


export interface IReisbalansQueryThemeArgs {
  uuid: Scalars['String']['input'];
}


export interface IReisbalansQueryVehicleInformationArgs {
  licensePlate: Scalars['String']['input'];
}

export interface IReisbalansV2Query {
  __typename?: 'reisbalansV2Query';
  /** Get all OV-pas details */
  ovPas: IOvPas;
  /** Get information on a given organization */
  v2Organization: IV2Organization;
  /** Get all WRPM information */
  wrpm?: Maybe<Array<IWrpm>>;
}


export interface IReisbalansV2QueryV2OrganizationArgs {
  organizationId: Scalars['ID']['input'];
}


export interface IReisbalansV2QueryWrpmArgs {
  forBudgetGroup: Scalars['Boolean']['input'];
  polymorphicId: Scalars['ID']['input'];
}

export type IGetBudgetGroupsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type IGetBudgetGroupsQuery = (
  { __typename?: 'Query' }
  & { reisbalansV2: (
    { __typename?: 'reisbalansV2Query' }
    & { v2Organization: (
      { __typename?: 'V2Organization' }
      & Pick<IV2Organization, 'id'>
      & { budgetGroups?: Maybe<Array<(
        { __typename?: 'V2BudgetGroup' }
        & Pick<IV2BudgetGroup, 'id' | 'name'>
      )>> }
    ) }
  ) }
);

export type IGetWrpmQueryVariables = Exact<{
  polymorphicId: Scalars['ID']['input'];
  forBudgetGroup: Scalars['Boolean']['input'];
}>;


export type IGetWrpmQuery = (
  { __typename?: 'Query' }
  & { reisbalansV2: (
    { __typename?: 'reisbalansV2Query' }
    & { wrpm?: Maybe<Array<(
      { __typename?: 'Wrpm' }
      & { modality?: Maybe<(
        { __typename?: 'WrpmModality' }
        & Pick<IWrpmModality, 'humanized' | 'value'>
      )>, statsPerModalityBusiness?: Maybe<(
        { __typename?: 'StatsPerModality' }
        & Pick<IStatsPerModality, 'totalCo2' | 'totalKms'>
      )>, statsPerModalityCommute?: Maybe<(
        { __typename?: 'StatsPerModality' }
        & Pick<IStatsPerModality, 'totalCo2' | 'totalKms'>
      )> }
    )>> }
  ) }
);

export type IAdminLoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  otp?: InputMaybe<Scalars['String']['input']>;
}>;


export type IAdminLoginMutation = (
  { __typename?: 'Mutation' }
  & { reisbalans: (
    { __typename?: 'reisbalansMutation' }
    & { adminLogin?: Maybe<(
      { __typename?: 'AdminLoginPayload' }
      & Pick<IAdminLoginPayload, 'jwt'>
      & { result: (
        { __typename?: 'Result' }
        & Pick<IResult, 'success' | 'error'>
      ) }
    )> }
  ) }
);

export type IGetJwtQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type IGetJwtQuery = (
  { __typename?: 'Query' }
  & { reisbalans: (
    { __typename?: 'reisbalansQuery' }
    & Pick<IReisbalansQuery, 'jwt'>
  ) }
);

export type ILogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type ILogoutMutation = (
  { __typename?: 'Mutation' }
  & { reisbalans: (
    { __typename?: 'reisbalansMutation' }
    & { logout?: Maybe<(
      { __typename?: 'LogoutPayload' }
      & { result: (
        { __typename?: 'Result' }
        & Pick<IResult, 'error' | 'success'>
      ) }
    )> }
  ) }
);

export type IGetThemeQueryVariables = Exact<{
  hostname: Scalars['String']['input'];
}>;


export type IGetThemeQuery = (
  { __typename: 'Query' }
  & { reisbalans: (
    { __typename?: 'reisbalansQuery' }
    & { configurationProfileForHost?: Maybe<(
      { __typename?: 'ConfigurationProfileForHost' }
      & { customerLabelTheme?: Maybe<(
        { __typename?: 'CustomerLabelTheme' }
        & { primaryColor?: Maybe<(
          { __typename?: 'Color' }
          & { hsla?: Maybe<(
            { __typename?: 'Hsla' }
            & Pick<IHsla, 'alpha' | 'hue' | 'lightness' | 'saturation'>
          )>, rgba?: Maybe<(
            { __typename?: 'Rgba' }
            & Pick<IRgba, 'blue' | 'alpha' | 'green' | 'red'>
          )> }
        )>, secondaryColor?: Maybe<(
          { __typename?: 'Color' }
          & { hsla?: Maybe<(
            { __typename?: 'Hsla' }
            & Pick<IHsla, 'hue' | 'alpha' | 'lightness' | 'saturation'>
          )>, rgba?: Maybe<(
            { __typename?: 'Rgba' }
            & Pick<IRgba, 'alpha' | 'blue' | 'green' | 'red'>
          )> }
        )>, poster?: Maybe<(
          { __typename?: 'Image' }
          & Pick<IImage, 'uri' | 'base64' | 'width' | 'height'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<IImage, 'uri' | 'base64' | 'height' | 'width'>
        )>, icon?: Maybe<(
          { __typename?: 'Image' }
          & Pick<IImage, 'uri' | 'base64' | 'height' | 'width'>
        )>, favicon?: Maybe<(
          { __typename?: 'Image' }
          & Pick<IImage, 'uri' | 'base64'>
        )> }
      )> }
    )> }
  ) }
);

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping of union types */
export type IResolversUnionTypes<RefType extends Record<string, unknown>> = ResolversObject<{
  Day: ( IDayWithMobilityEvents ) | ( IDayWithoutEmployment );
  Where: ( ILocation ) | ( IRentalLocations ) | ( IRoute );
}>;

/** Mapping of interface types */
export type IResolversInterfaceTypes<RefType extends Record<string, unknown>> = ResolversObject<{
  AvailableFeatureInterface: ( IAmber ) | ( IDonkeyRepublic ) | ( IEnecoDongle ) | ( IGreenwheels ) | ( ILeaseBike ) | ( IRentalCar ) | ( IRequestableDonkeyRepublic ) | ( IRequestableFeature ) | ( ISharedVehicle ) | ( IShuttelCheck ) | ( IShuttelMyWheels ) | ( IShuttelSixt ) | ( IShuttelTaxi ) | ( IShuttelTranzerEticket ) | ( IShuttelVecore ) | ( ITaxiFeature ) | ( ITranzerEticket ) | ( IYellowbrickSp );
  AvailableServiceInterface: ( IActiveAdditional ) | ( IActiveGreenwheels ) | ( IActiveSubscription ) | ( IActiveYellowbrickSp ) | ( IRequestableService ) | ( IRequestableYellowbrickProduct );
  MultiModalAvailability: ( IHub ) | ( IPublicTransportStop ) | ( IVehicleAsset );
  ReimbursementRule: ( ICost ) | ( IDistance );
  ResultInterface: ( IDonkeyRepublicResult );
  Task: ( IActionRequired ) | ( IActivatableDongle ) | ( ICompensationRideToBeCreated ) | ( IMobilityEventsWeek ) | ( INewProduct ) | ( IPendingApproval ) | ( IRefundToBeLinked );
  When: ( IDuration ) | ( ITimestamp );
}>;

/** Mapping between all available schema types and the resolvers types */
export type IResolversTypes = ResolversObject<{
  AcceptMobilityEventsInput: IAcceptMobilityEventsInput;
  AcceptMobilityEventsPayload: ResolverTypeWrapper<IAcceptMobilityEventsPayload>;
  ActionRequired: ResolverTypeWrapper<IActionRequired>;
  Actions: ResolverTypeWrapper<IActions>;
  ActivatableDongle: ResolverTypeWrapper<IActivatableDongle>;
  ActivateEnecoDongleInput: IActivateEnecoDongleInput;
  ActivateEnecoDonglePayload: ResolverTypeWrapper<IActivateEnecoDonglePayload>;
  ActivatePersonalMeansOfRegistrationInput: IActivatePersonalMeansOfRegistrationInput;
  ActivatePersonalMeansOfRegistrationPayload: ResolverTypeWrapper<IActivatePersonalMeansOfRegistrationPayload>;
  ActiveAdditional: ResolverTypeWrapper<IActiveAdditional>;
  ActiveGreenwheels: ResolverTypeWrapper<IActiveGreenwheels>;
  ActiveSubscription: ResolverTypeWrapper<IActiveSubscription>;
  ActiveYellowbrickSp: ResolverTypeWrapper<IActiveYellowbrickSp>;
  AddPushNotificationTokenInput: IAddPushNotificationTokenInput;
  AddPushNotificationTokenPayload: ResolverTypeWrapper<IAddPushNotificationTokenPayload>;
  AddToWhitelistInput: IAddToWhitelistInput;
  AddToWhitelistPayload: ResolverTypeWrapper<IAddToWhitelistPayload>;
  AddableVehiclePermission: ResolverTypeWrapper<IAddableVehiclePermission>;
  Address: ResolverTypeWrapper<IAddress>;
  AdminLoginInput: IAdminLoginInput;
  AdminLoginPayload: ResolverTypeWrapper<IAdminLoginPayload>;
  Amber: ResolverTypeWrapper<IAmber>;
  AppUrls: ResolverTypeWrapper<IAppUrls>;
  AssetAvailability: ResolverTypeWrapper<IAssetAvailability>;
  AssetLocation: ResolverTypeWrapper<IAssetLocation>;
  AssetStation: ResolverTypeWrapper<IAssetStation>;
  AssignReplacementManagerInput: IAssignReplacementManagerInput;
  AssignReplacementManagerPayload: ResolverTypeWrapper<IAssignReplacementManagerPayload>;
  Attachment: IAttachment;
  Auth: ResolverTypeWrapper<IAuth>;
  AvailableFeatureInterface: ResolverTypeWrapper<IResolversInterfaceTypes<IResolversTypes>['AvailableFeatureInterface']>;
  AvailableServiceAvailabilityEnum: IAvailableServiceAvailabilityEnum;
  AvailableServiceInterface: ResolverTypeWrapper<IResolversInterfaceTypes<IResolversTypes>['AvailableServiceInterface']>;
  AvailableSharedCar: ResolverTypeWrapper<IAvailableSharedCar>;
  AvailableSharedVehicle: ResolverTypeWrapper<IAvailableSharedVehicle>;
  AvailableSubscription: ResolverTypeWrapper<IAvailableSubscription>;
  Balance: ResolverTypeWrapper<IBalance>;
  Base64: ResolverTypeWrapper<Scalars['Base64']['output']>;
  BigDecimal: ResolverTypeWrapper<Scalars['BigDecimal']['output']>;
  BikeAccess: ResolverTypeWrapper<IBikeAccess>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  Branding: ResolverTypeWrapper<IBranding>;
  Budget: ResolverTypeWrapper<IBudget>;
  BudgetGroup: ResolverTypeWrapper<IBudgetGroup>;
  CalculatedDistance: ResolverTypeWrapper<ICalculatedDistance>;
  CancelDonkeyRepublicReservationInput: ICancelDonkeyRepublicReservationInput;
  CancelDonkeyRepublicReservationPayload: ResolverTypeWrapper<ICancelDonkeyRepublicReservationPayload>;
  CancelSharedCarReservationInput: ICancelSharedCarReservationInput;
  CancelSharedCarReservationPayload: ResolverTypeWrapper<ICancelSharedCarReservationPayload>;
  CancelSharedVehicleReservationInput: ICancelSharedVehicleReservationInput;
  CancelSharedVehicleReservationPayload: ResolverTypeWrapper<ICancelSharedVehicleReservationPayload>;
  CancelTaxiReservationInput: ICancelTaxiReservationInput;
  CancelTaxiReservationPayload: ResolverTypeWrapper<ICancelTaxiReservationPayload>;
  Category: ResolverTypeWrapper<ICategory>;
  Co2Emission: ResolverTypeWrapper<ICo2Emission>;
  Co2EmissionPerModality: ResolverTypeWrapper<ICo2EmissionPerModality>;
  Co2EmissionSummary: ResolverTypeWrapper<ICo2EmissionSummary>;
  Color: ResolverTypeWrapper<IColor>;
  CompensationRideToBeCreated: ResolverTypeWrapper<ICompensationRideToBeCreated>;
  Configuration: ResolverTypeWrapper<IConfiguration>;
  ConfigurationProfileForHost: ResolverTypeWrapper<IConfigurationProfileForHost>;
  ConfigureMfaInput: IConfigureMfaInput;
  ConfigureMfaPayload: ResolverTypeWrapper<IConfigureMfaPayload>;
  ConfirmGreenwheelsInput: IConfirmGreenwheelsInput;
  ConfirmGreenwheelsPayload: ResolverTypeWrapper<IConfirmGreenwheelsPayload>;
  ConfirmInput: IConfirmInput;
  ConfirmMobilityEventInput: IConfirmMobilityEventInput;
  ConfirmMobilityEventPayload: ResolverTypeWrapper<IConfirmMobilityEventPayload>;
  ConfirmMobilityEventsInput: IConfirmMobilityEventsInput;
  ConfirmMobilityEventsPayload: ResolverTypeWrapper<IConfirmMobilityEventsPayload>;
  ConfirmPayload: ResolverTypeWrapper<IConfirmPayload>;
  ConfirmSharedCarReservationInput: IConfirmSharedCarReservationInput;
  ConfirmSharedCarReservationPayload: ResolverTypeWrapper<IConfirmSharedCarReservationPayload>;
  ConfirmSharedVehicleReservationInput: IConfirmSharedVehicleReservationInput;
  ConfirmSharedVehicleReservationPayload: ResolverTypeWrapper<IConfirmSharedVehicleReservationPayload>;
  ConfirmTaxiReservationInput: IConfirmTaxiReservationInput;
  ConfirmTaxiReservationPayload: ResolverTypeWrapper<IConfirmTaxiReservationPayload>;
  ContractActions: ResolverTypeWrapper<IContractActions>;
  ContractPaymentServiceProviderEnum: IContractPaymentServiceProviderEnum;
  ContractStatus: ResolverTypeWrapper<IContractStatus>;
  ContractStatusEnum: IContractStatusEnum;
  Coordinate: ResolverTypeWrapper<Scalars['Coordinate']['output']>;
  Coordinates: ResolverTypeWrapper<ICoordinates>;
  CoordinatesMutation: ICoordinatesMutation;
  CopyMobilityEventsForDayInput: ICopyMobilityEventsForDayInput;
  CopyMobilityEventsForDayPayload: ResolverTypeWrapper<Omit<ICopyMobilityEventsForDayPayload, 'days'> & { days: Array<IResolversTypes['Day']> }>;
  CopyMobilityEventsInput: ICopyMobilityEventsInput;
  CopyMobilityEventsPayload: ResolverTypeWrapper<ICopyMobilityEventsPayload>;
  Cost: ResolverTypeWrapper<ICost>;
  CountryIsoCode: ResolverTypeWrapper<ICountryIsoCode>;
  CountryIsoCodeEnum: ICountryIsoCodeEnum;
  CreateCommuteCompensationInput: ICreateCommuteCompensationInput;
  CreateCommuteCompensationPayload: ResolverTypeWrapper<ICreateCommuteCompensationPayload>;
  CreateCommuteRidesInput: ICreateCommuteRidesInput;
  CreateCommuteRidesPayload: ResolverTypeWrapper<ICreateCommuteRidesPayload>;
  CreateCompensationMobilityEventInput: ICreateCompensationMobilityEventInput;
  CreateCompensationMobilityEventPayload: ResolverTypeWrapper<ICreateCompensationMobilityEventPayload>;
  CreateDirectDebitMandateInput: ICreateDirectDebitMandateInput;
  CreateDirectDebitMandatePayload: ResolverTypeWrapper<ICreateDirectDebitMandatePayload>;
  CreateDonkeyRepublicReservationInput: ICreateDonkeyRepublicReservationInput;
  CreateDonkeyRepublicReservationPayload: ResolverTypeWrapper<ICreateDonkeyRepublicReservationPayload>;
  CreateFavoriteInput: ICreateFavoriteInput;
  CreateFavoritePayload: ResolverTypeWrapper<ICreateFavoritePayload>;
  CreateLeaseBikeOrderInput: ICreateLeaseBikeOrderInput;
  CreateLeaseBikeOrderPayload: ResolverTypeWrapper<ICreateLeaseBikeOrderPayload>;
  CreateMileageInput: ICreateMileageInput;
  CreateMileagePayload: ResolverTypeWrapper<ICreateMileagePayload>;
  CreateMobilityCardOrderInput: ICreateMobilityCardOrderInput;
  CreateMobilityCardOrderPayload: ResolverTypeWrapper<ICreateMobilityCardOrderPayload>;
  CreateMobilityEventInput: ICreateMobilityEventInput;
  CreateMobilityEventPayload: ResolverTypeWrapper<ICreateMobilityEventPayload>;
  CreateParkingOrderInput: ICreateParkingOrderInput;
  CreateParkingOrderPayload: ResolverTypeWrapper<ICreateParkingOrderPayload>;
  CreateTaxiReservationInput: ICreateTaxiReservationInput;
  CreateTaxiReservationPayload: ResolverTypeWrapper<ICreateTaxiReservationPayload>;
  CreateTranzerReservationInput: ICreateTranzerReservationInput;
  CreateTranzerReservationPayload: ResolverTypeWrapper<ICreateTranzerReservationPayload>;
  CreateUserProfileInput: ICreateUserProfileInput;
  CreateUserProfilePayload: ResolverTypeWrapper<ICreateUserProfilePayload>;
  CreateVehicleInput: ICreateVehicleInput;
  CreateVehiclePayload: ResolverTypeWrapper<ICreateVehiclePayload>;
  CreateWhatMutation: ICreateWhatMutation;
  CreateWorkFromHomeInput: ICreateWorkFromHomeInput;
  CreateWorkFromHomePayload: ResolverTypeWrapper<ICreateWorkFromHomePayload>;
  CrowdForecastEnum: ICrowdForecastEnum;
  CustomerLabelTheme: ResolverTypeWrapper<ICustomerLabelTheme>;
  CustomerService: ResolverTypeWrapper<ICustomerService>;
  Date: ResolverTypeWrapper<Scalars['Date']['output']>;
  DateRangeInput: IDateRangeInput;
  DateRangeStringEnum: IDateRangeStringEnum;
  DateRangeStringInput: IDateRangeStringInput;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  Day: ResolverTypeWrapper<IResolversUnionTypes<IResolversTypes>['Day']>;
  DayWithMobilityEvents: ResolverTypeWrapper<IDayWithMobilityEvents>;
  DayWithoutEmployment: ResolverTypeWrapper<IDayWithoutEmployment>;
  Description: ResolverTypeWrapper<IDescription>;
  DestroyFavoriteInput: IDestroyFavoriteInput;
  DestroyFavoritePayload: ResolverTypeWrapper<IDestroyFavoritePayload>;
  DestroyMobilityEventInput: IDestroyMobilityEventInput;
  DestroyMobilityEventPayload: ResolverTypeWrapper<Omit<IDestroyMobilityEventPayload, 'day'> & { day: IResolversTypes['Day'] }>;
  DestroyMobilityEventsInput: IDestroyMobilityEventsInput;
  DestroyMobilityEventsPayload: ResolverTypeWrapper<IDestroyMobilityEventsPayload>;
  DestroyProfileAddressEnum: IDestroyProfileAddressEnum;
  DestroyProfileAddressInput: IDestroyProfileAddressInput;
  DestroyProfileAddressPayload: ResolverTypeWrapper<IDestroyProfileAddressPayload>;
  DirectDebitInvoice: ResolverTypeWrapper<IDirectDebitInvoice>;
  DirectDebitMandate: ResolverTypeWrapper<IDirectDebitMandate>;
  DirectDebitMandates: ResolverTypeWrapper<IDirectDebitMandates>;
  DirectionEnum: IDirectionEnum;
  Display: ResolverTypeWrapper<IDisplay>;
  DisplayTypeEnum: IDisplayTypeEnum;
  Distance: ResolverTypeWrapper<IDistance>;
  DistancePerModality: ResolverTypeWrapper<IDistancePerModality>;
  DistanceSummary: ResolverTypeWrapper<IDistanceSummary>;
  Diverted: ResolverTypeWrapper<IDiverted>;
  DonkeyLeg: ResolverTypeWrapper<IDonkeyLeg>;
  DonkeyQuote: ResolverTypeWrapper<IDonkeyQuote>;
  DonkeyRepublic: ResolverTypeWrapper<IDonkeyRepublic>;
  DonkeyRepublicBooking: ResolverTypeWrapper<IDonkeyRepublicBooking>;
  DonkeyRepublicModality: ResolverTypeWrapper<IDonkeyRepublicModality>;
  DonkeyRepublicModalityEnum: IDonkeyRepublicModalityEnum;
  DonkeyRepublicReservation: ResolverTypeWrapper<IDonkeyRepublicReservation>;
  DonkeyRepublicResult: ResolverTypeWrapper<IDonkeyRepublicResult>;
  DonkeyRepublicStatus: ResolverTypeWrapper<IDonkeyRepublicStatus>;
  DonkeyRepublicStatusEnum: IDonkeyRepublicStatusEnum;
  Driver: ResolverTypeWrapper<IDriver>;
  DriversLicenseCountry: ResolverTypeWrapper<IDriversLicenseCountry>;
  DriversLicenseCountryEnum: IDriversLicenseCountryEnum;
  Duration: ResolverTypeWrapper<IDuration>;
  Ekey: ResolverTypeWrapper<IEkey>;
  EkeyLock: ResolverTypeWrapper<IEkeyLock>;
  Employee: ResolverTypeWrapper<Omit<IEmployee, 'day' | 'days'> & { day?: Maybe<IResolversTypes['Day']>, days: Array<IResolversTypes['Day']> }>;
  EmployeeAddress: ResolverTypeWrapper<IEmployeeAddress>;
  EmployeeAddressMutation: IEmployeeAddressMutation;
  EmployeeDetails: ResolverTypeWrapper<IEmployeeDetails>;
  EmployeeDetailsResponse: ResolverTypeWrapper<IEmployeeDetailsResponse>;
  EmployeeFinancialConsequences: ResolverTypeWrapper<IEmployeeFinancialConsequences>;
  Employment: ResolverTypeWrapper<IEmployment>;
  EnecoDongle: ResolverTypeWrapper<IEnecoDongle>;
  Error: ResolverTypeWrapper<IError>;
  Explanation: ResolverTypeWrapper<IExplanation>;
  ExplanationRequired: ResolverTypeWrapper<IExplanationRequired>;
  ExportFiles: ResolverTypeWrapper<IExportFiles>;
  ExportUrls: ResolverTypeWrapper<IExportUrls>;
  ExternalUrl: ResolverTypeWrapper<IExternalUrl>;
  ExternalUrls: ResolverTypeWrapper<IExternalUrls>;
  Favorite: ResolverTypeWrapper<IFavorite>;
  FavoriteActions: ResolverTypeWrapper<IFavoriteActions>;
  FavoriteFilterEnum: IFavoriteFilterEnum;
  FavoriteLocation: ResolverTypeWrapper<IFavoriteLocation>;
  FavoriteTypeEnum: IFavoriteTypeEnum;
  Favorites: ResolverTypeWrapper<IFavorites>;
  FeatureOption: ResolverTypeWrapper<IFeatureOption>;
  FeatureOptionEnum: IFeatureOptionEnum;
  FeatureOrderStatus: ResolverTypeWrapper<IFeatureOrderStatus>;
  FeatureOrderStatusEnum: IFeatureOrderStatusEnum;
  FeatureUrls: ResolverTypeWrapper<IFeatureUrls>;
  FilterOptionItem: ResolverTypeWrapper<IFilterOptionItem>;
  FilterOptions: ResolverTypeWrapper<IFilterOptions>;
  FilterStatusEnum: IFilterStatusEnum;
  Financial: ResolverTypeWrapper<IFinancial>;
  FinancialComponent: ResolverTypeWrapper<IFinancialComponent>;
  FinancialConsequences: ResolverTypeWrapper<IFinancialConsequences>;
  FinancialFact: ResolverTypeWrapper<IFinancialFact>;
  FinancialFactMutation: IFinancialFactMutation;
  FinancialMutation: IFinancialMutation;
  FinancialSummary: ResolverTypeWrapper<IFinancialSummary>;
  FinishDonkeyRepublicReservationInput: IFinishDonkeyRepublicReservationInput;
  FinishDonkeyRepublicReservationPayload: ResolverTypeWrapper<IFinishDonkeyRepublicReservationPayload>;
  FinishSharedCarReservationInput: IFinishSharedCarReservationInput;
  FinishSharedCarReservationPayload: ResolverTypeWrapper<IFinishSharedCarReservationPayload>;
  FinishSharedVehicleReservationInput: IFinishSharedVehicleReservationInput;
  FinishSharedVehicleReservationPayload: ResolverTypeWrapper<IFinishSharedVehicleReservationPayload>;
  FirstClassReason: ResolverTypeWrapper<IFirstClassReason>;
  FirstClassReasonEnum: IFirstClassReasonEnum;
  FiscalityEnum: IFiscalityEnum;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  Fuel: ResolverTypeWrapper<IFuel>;
  GeoLocation: ResolverTypeWrapper<IGeoLocation>;
  GetAccessUrlInput: IGetAccessUrlInput;
  GetAccessUrlPayload: ResolverTypeWrapper<IGetAccessUrlPayload>;
  GooglePlaces: ResolverTypeWrapper<IGooglePlaces>;
  GooglePlacesDetailsResult: ResolverTypeWrapper<IGooglePlacesDetailsResult>;
  GooglePlacesResult: ResolverTypeWrapper<IGooglePlacesResult>;
  GooglePlacesStructuredText: ResolverTypeWrapper<IGooglePlacesStructuredText>;
  GooglePlacesTypeValue: ResolverTypeWrapper<IGooglePlacesTypeValue>;
  GooglePlacesTypesEnum: IGooglePlacesTypesEnum;
  Greenwheels: ResolverTypeWrapper<IGreenwheels>;
  GreenwheelsFleet: ResolverTypeWrapper<IGreenwheelsFleet>;
  How: ResolverTypeWrapper<IHow>;
  HowMuch: ResolverTypeWrapper<IHowMuch>;
  Hsla: ResolverTypeWrapper<IHsla>;
  HttpMethod: IHttpMethod;
  Hub: ResolverTypeWrapper<IHub>;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  Icon: ResolverTypeWrapper<IIcon>;
  IconEnum: IIconEnum;
  Identity: ResolverTypeWrapper<IIdentity>;
  Image: ResolverTypeWrapper<IImage>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  InvoiceStatus: ResolverTypeWrapper<IInvoiceStatus>;
  InvoiceStatusEnum: IInvoiceStatusEnum;
  JSON: ResolverTypeWrapper<Scalars['JSON']['output']>;
  JournalEntry: ResolverTypeWrapper<IJournalEntry>;
  Journey: ResolverTypeWrapper<IJourney>;
  JourneyLeg: ResolverTypeWrapper<IJourneyLeg>;
  JourneyMode: ResolverTypeWrapper<IJourneyMode>;
  JourneyModeEnum: IJourneyModeEnum;
  JourneySortTypeEnum: IJourneySortTypeEnum;
  JourneySorter: IJourneySorter;
  Label: ResolverTypeWrapper<ILabel>;
  LabelEnum: ILabelEnum;
  LeaseBike: ResolverTypeWrapper<ILeaseBike>;
  LeaseBikeOrder: ResolverTypeWrapper<ILeaseBikeOrder>;
  LeaseCar: ResolverTypeWrapper<ILeaseCar>;
  Leg: ResolverTypeWrapper<ILeg>;
  LicensePlate: ResolverTypeWrapper<ILicensePlate>;
  Location: ResolverTypeWrapper<ILocation>;
  LocationInput: ILocationInput;
  LocationMutation: ILocationMutation;
  Lock: ResolverTypeWrapper<ILock>;
  LockDonkeyRepublicAssetInput: ILockDonkeyRepublicAssetInput;
  LockDonkeyRepublicAssetPayload: ResolverTypeWrapper<ILockDonkeyRepublicAssetPayload>;
  LoginInput: ILoginInput;
  LoginPayload: ResolverTypeWrapper<ILoginPayload>;
  LogoutInput: ILogoutInput;
  LogoutPayload: ResolverTypeWrapper<ILogoutPayload>;
  MSAAbstractProperty: ResolverTypeWrapper<IMsaAbstractProperty>;
  MSAAdminAction: IMsaAdminAction;
  MSACarrier: ResolverTypeWrapper<IMsaCarrier>;
  MSACreateCarrierRequestBodyRequestInput: IMsaCreateCarrierRequestBodyRequestInput;
  MSACreateProductGroupRequestBodyRequestInput: IMsaCreateProductGroupRequestBodyRequestInput;
  MSACreateProductRequestBodyRequestInput: IMsaCreateProductRequestBodyRequestInput;
  MSAExternalStatus: IMsaExternalStatus;
  MSAProduct: ResolverTypeWrapper<IMsaProduct>;
  MSAProductGroup: ResolverTypeWrapper<IMsaProductGroup>;
  MSAPropertiesInput: IMsaPropertiesInput;
  MSAPropertyTypeBinding: ResolverTypeWrapper<IMsaPropertyTypeBinding>;
  MSAPropertyTypeConstraint: ResolverTypeWrapper<IMsaPropertyTypeConstraint>;
  MSAProviderType: IMsaProviderType;
  MSAQueryCarrierTypePropertiesListItemsConstraintsItemsRules: ResolverTypeWrapper<IMsaQueryCarrierTypePropertiesListItemsConstraintsItemsRules>;
  MSAReplaceCarrierPropertiesRequestBodyRequestInput: IMsaReplaceCarrierPropertiesRequestBodyRequestInput;
  MSAReplaceCarrierRequestBodyRequestInput: IMsaReplaceCarrierRequestBodyRequestInput;
  MSAReplaceProductPropertiesRequestBodyRequestInput: IMsaReplaceProductPropertiesRequestBodyRequestInput;
  MSAStatusCategory: IMsaStatusCategory;
  MSASupportedCarriersForProductTypeList: ResolverTypeWrapper<IMsaSupportedCarriersForProductTypeList>;
  MSATravelerAction: IMsaTravelerAction;
  MainCategory: ResolverTypeWrapper<IMainCategory>;
  MainCategoryEnum: IMainCategoryEnum;
  MandateActions: ResolverTypeWrapper<IMandateActions>;
  MandateStatus: ResolverTypeWrapper<IMandateStatus>;
  MandateStatusEnum: IMandateStatusEnum;
  MandateStatusFilterEnum: IMandateStatusFilterEnum;
  MarkMessageAsReadInput: IMarkMessageAsReadInput;
  MarkMessageAsReadPayload: ResolverTypeWrapper<IMarkMessageAsReadPayload>;
  MeanOfTransportFiscality: ResolverTypeWrapper<IMeanOfTransportFiscality>;
  MeansOfTransport: ResolverTypeWrapper<IMeansOfTransport>;
  Media: ResolverTypeWrapper<IMedia>;
  MediaSide: ResolverTypeWrapper<IMediaSide>;
  MediaTypeEnum: IMediaTypeEnum;
  MergeMobilityEventsInput: IMergeMobilityEventsInput;
  MergeMobilityEventsPayload: ResolverTypeWrapper<IMergeMobilityEventsPayload>;
  MergedMobilityEventsPreview: ResolverTypeWrapper<IMergedMobilityEventsPreview>;
  Message: ResolverTypeWrapper<IMessage>;
  MessageKeyEnum: IMessageKeyEnum;
  Mileage: ResolverTypeWrapper<IMileage>;
  Mileages: ResolverTypeWrapper<IMileages>;
  MobilityCardOrder: ResolverTypeWrapper<IMobilityCardOrder>;
  MobilityCardOrderActions: ResolverTypeWrapper<IMobilityCardOrderActions>;
  MobilityCardOrderPermission: ResolverTypeWrapper<IMobilityCardOrderPermission>;
  MobilityCardOrderService: ResolverTypeWrapper<IMobilityCardOrderService>;
  MobilityCardOrderStatus: ResolverTypeWrapper<IMobilityCardOrderStatus>;
  MobilityCardOrderStatusEnum: IMobilityCardOrderStatusEnum;
  MobilityCardOrders: ResolverTypeWrapper<IMobilityCardOrders>;
  MobilityEvent: ResolverTypeWrapper<Omit<IMobilityEvent, 'where'> & { where?: Maybe<IResolversTypes['Where']> }>;
  MobilityEventAggregate: ResolverTypeWrapper<IMobilityEventAggregate>;
  MobilityEventByDate: ResolverTypeWrapper<IMobilityEventByDate>;
  MobilityEventFormValidations: ResolverTypeWrapper<IMobilityEventFormValidations>;
  MobilityEventPreview: ResolverTypeWrapper<Omit<IMobilityEventPreview, 'where'> & { where?: Maybe<IResolversTypes['Where']> }>;
  MobilityEventTotals: ResolverTypeWrapper<IMobilityEventTotals>;
  MobilityEventsWeek: ResolverTypeWrapper<IMobilityEventsWeek>;
  MobilityServicesMutation: ResolverTypeWrapper<IMobilityServicesMutation>;
  MobilityServicesQuery: ResolverTypeWrapper<IMobilityServicesQuery>;
  Modality: ResolverTypeWrapper<IModality>;
  ModalityKindEnum: IModalityKindEnum;
  ModalityOwnership: ResolverTypeWrapper<IModalityOwnership>;
  ModalityPrivateVehicleEnum: IModalityPrivateVehicleEnum;
  Money: ResolverTypeWrapper<IMoney>;
  MoneyTransfer: ResolverTypeWrapper<IMoneyTransfer>;
  MoneyWithCurrency: ResolverTypeWrapper<IMoneyWithCurrency>;
  MultiModalAvailability: ResolverTypeWrapper<IResolversInterfaceTypes<IResolversTypes>['MultiModalAvailability']>;
  Mutation: ResolverTypeWrapper<{}>;
  NSRefundUrl: ResolverTypeWrapper<INsRefundUrl>;
  NewProduct: ResolverTypeWrapper<INewProduct>;
  NonCombined: ResolverTypeWrapper<INonCombined>;
  ObjMap: ResolverTypeWrapper<Scalars['ObjMap']['output']>;
  OpeningHours: ResolverTypeWrapper<IOpeningHours>;
  OptionsMutation: IOptionsMutation;
  OrderEnum: IOrderEnum;
  Organization: ResolverTypeWrapper<IOrganization>;
  OvFietsLocation: ResolverTypeWrapper<IOvFietsLocation>;
  OvPas: ResolverTypeWrapper<IOvPas>;
  OvPasEnum: IOvPasEnum;
  OwnershipEnum: IOwnershipEnum;
  Parking: ResolverTypeWrapper<IParking>;
  ParkingOrder: ResolverTypeWrapper<IParkingOrder>;
  ParkingOrderActions: ResolverTypeWrapper<IParkingOrderActions>;
  ParkingOrderStatus: ResolverTypeWrapper<IParkingOrderStatus>;
  ParkingOrderStatusEnum: IParkingOrderStatusEnum;
  Password: ResolverTypeWrapper<IPassword>;
  PasswordStrength: ResolverTypeWrapper<IPasswordStrength>;
  PendingApproval: ResolverTypeWrapper<IPendingApproval>;
  Permissions: ResolverTypeWrapper<IPermissions>;
  PersonalMeansOfRegistration: ResolverTypeWrapper<IPersonalMeansOfRegistration>;
  PersonalMeansOfRegistrationAbilities: ResolverTypeWrapper<IPersonalMeansOfRegistrationAbilities>;
  PersonalMeansOfRegistrationEndsOn: ResolverTypeWrapper<IPersonalMeansOfRegistrationEndsOn>;
  PersonalMeansOfRegistrationIdentity: ResolverTypeWrapper<IPersonalMeansOfRegistrationIdentity>;
  PersonalMeansOfRegistrationKind: ResolverTypeWrapper<IPersonalMeansOfRegistrationKind>;
  PersonalMeansOfRegistrationKindMainEnum: IPersonalMeansOfRegistrationKindMainEnum;
  PersonalMeansOfRegistrationKindSubEnum: IPersonalMeansOfRegistrationKindSubEnum;
  PersonalMeansOfRegistrationMetaStatus: ResolverTypeWrapper<IPersonalMeansOfRegistrationMetaStatus>;
  PersonalMeansOfRegistrationMetaStatusEnum: IPersonalMeansOfRegistrationMetaStatusEnum;
  PersonalMeansOfRegistrationPhotoRejectedReason: ResolverTypeWrapper<IPersonalMeansOfRegistrationPhotoRejectedReason>;
  PersonalMeansOfRegistrationPhotoRejectedReasonEnum: IPersonalMeansOfRegistrationPhotoRejectedReasonEnum;
  PersonalMeansOfRegistrationReport: IPersonalMeansOfRegistrationReport;
  PersonalMeansOfRegistrationService: ResolverTypeWrapper<IPersonalMeansOfRegistrationService>;
  PersonalMeansOfRegistrationServiceEndsOn: ResolverTypeWrapper<IPersonalMeansOfRegistrationServiceEndsOn>;
  PersonalMeansOfRegistrationServiceIdentity: ResolverTypeWrapper<IPersonalMeansOfRegistrationServiceIdentity>;
  PersonalMeansOfRegistrationServiceKind: ResolverTypeWrapper<IPersonalMeansOfRegistrationServiceKind>;
  PersonalMeansOfRegistrationServiceKindSubEnum: IPersonalMeansOfRegistrationServiceKindSubEnum;
  PersonalMeansOfRegistrationServiceStatus: ResolverTypeWrapper<IPersonalMeansOfRegistrationServiceStatus>;
  PersonalMeansOfRegistrationServiceStatusFilterEnum: IPersonalMeansOfRegistrationServiceStatusFilterEnum;
  PersonalMeansOfRegistrationStatus: ResolverTypeWrapper<IPersonalMeansOfRegistrationStatus>;
  PersonalMeansOfRegistrationStatusEnum: IPersonalMeansOfRegistrationStatusEnum;
  PersonalMeansOfRegistrationStatusFilterEnum: IPersonalMeansOfRegistrationStatusFilterEnum;
  Photo: IPhoto;
  Places: ResolverTypeWrapper<IPlaces>;
  PlacesDetailsResult: ResolverTypeWrapper<IPlacesDetailsResult>;
  PlacesResult: ResolverTypeWrapper<IPlacesResult>;
  PlacesStructuredText: ResolverTypeWrapper<IPlacesStructuredText>;
  PlacesTypeValue: ResolverTypeWrapper<IPlacesTypeValue>;
  PlacesTypesEnum: IPlacesTypesEnum;
  PossibleReplacementManager: ResolverTypeWrapper<IPossibleReplacementManager>;
  PreferredJourneyModeEnum: IPreferredJourneyModeEnum;
  PreferredModalityEnum: IPreferredModalityEnum;
  Price: ResolverTypeWrapper<IPrice>;
  Privacy: ResolverTypeWrapper<IPrivacy>;
  PrivacyRequestReason: ResolverTypeWrapper<IPrivacyRequestReason>;
  PrivacyRequestReasonEnum: IPrivacyRequestReasonEnum;
  PrivacyTypeEnum: IPrivacyTypeEnum;
  Profile: ResolverTypeWrapper<IProfile>;
  ProfileMutation: IProfileMutation;
  Project: ResolverTypeWrapper<IProject>;
  ProjectScope: IProjectScope;
  PublicTransportStop: ResolverTypeWrapper<IPublicTransportStop>;
  Purpose: ResolverTypeWrapper<IPurpose>;
  Quantity: ResolverTypeWrapper<IQuantity>;
  Query: ResolverTypeWrapper<{}>;
  Quote: ResolverTypeWrapper<IQuote>;
  QuoteStatus: ResolverTypeWrapper<IQuoteStatus>;
  QuoteStatusEnum: IQuoteStatusEnum;
  Receipt: ResolverTypeWrapper<IReceipt>;
  RecommendedCard: ResolverTypeWrapper<IRecommendedCard>;
  RefreshTranzerReservationInput: IRefreshTranzerReservationInput;
  RefreshTranzerReservationPayload: ResolverTypeWrapper<IRefreshTranzerReservationPayload>;
  RefundToBeLinked: ResolverTypeWrapper<IRefundToBeLinked>;
  ReimbursableCategory: ResolverTypeWrapper<IReimbursableCategory>;
  ReimbursableCategoryMethod: ResolverTypeWrapper<IReimbursableCategoryMethod>;
  ReimbursableCategoryMethodEnum: IReimbursableCategoryMethodEnum;
  ReimbursementRule: ResolverTypeWrapper<IResolversInterfaceTypes<IResolversTypes>['ReimbursementRule']>;
  RejectMobilityEventsInput: IRejectMobilityEventsInput;
  RejectMobilityEventsPayload: ResolverTypeWrapper<IRejectMobilityEventsPayload>;
  RemoveFromWhitelistInput: IRemoveFromWhitelistInput;
  RemoveFromWhitelistPayload: ResolverTypeWrapper<IRemoveFromWhitelistPayload>;
  RentalCar: ResolverTypeWrapper<IRentalCar>;
  RentalLocations: ResolverTypeWrapper<IRentalLocations>;
  ReplacementManager: ResolverTypeWrapper<IReplacementManager>;
  ReportPersonalMeansOfRegistrationInput: IReportPersonalMeansOfRegistrationInput;
  ReportPersonalMeansOfRegistrationPayload: ResolverTypeWrapper<IReportPersonalMeansOfRegistrationPayload>;
  RequestDates: ResolverTypeWrapper<IRequestDates>;
  RequestDonkeyRepublicFeatureInput: IRequestDonkeyRepublicFeatureInput;
  RequestDonkeyRepublicFeaturePayload: ResolverTypeWrapper<IRequestDonkeyRepublicFeaturePayload>;
  RequestFeatureInput: IRequestFeatureInput;
  RequestFeaturePayload: ResolverTypeWrapper<IRequestFeaturePayload>;
  RequestServiceInput: IRequestServiceInput;
  RequestServicePayload: ResolverTypeWrapper<IRequestServicePayload>;
  RequestTrainClassChangeInput: IRequestTrainClassChangeInput;
  RequestTrainClassChangePayload: ResolverTypeWrapper<IRequestTrainClassChangePayload>;
  RequestableDonkeyRepublic: ResolverTypeWrapper<IRequestableDonkeyRepublic>;
  RequestableFeature: ResolverTypeWrapper<IRequestableFeature>;
  RequestableFeatureOption: ResolverTypeWrapper<IRequestableFeatureOption>;
  RequestableService: ResolverTypeWrapper<IRequestableService>;
  RequestableYellowbrickProduct: ResolverTypeWrapper<IRequestableYellowbrickProduct>;
  RequiredFields: ResolverTypeWrapper<IRequiredFields>;
  Reservation: ResolverTypeWrapper<IReservation>;
  ReservationClassEnum: IReservationClassEnum;
  ReservationKindEnum: IReservationKindEnum;
  ReservationStateEnum: IReservationStateEnum;
  ReservationStatus: ResolverTypeWrapper<IReservationStatus>;
  ReservationStatusEnum: IReservationStatusEnum;
  ReservationStatusValueEnum: IReservationStatusValueEnum;
  ResetPasswordInput: IResetPasswordInput;
  ResetPasswordPayload: ResolverTypeWrapper<IResetPasswordPayload>;
  Result: ResolverTypeWrapper<IResult>;
  ResultInterface: ResolverTypeWrapper<IResolversInterfaceTypes<IResolversTypes>['ResultInterface']>;
  ResultWithAccessTokens: ResolverTypeWrapper<IResultWithAccessTokens>;
  ReuploadPhotoInput: IReuploadPhotoInput;
  ReuploadPhotoPayload: ResolverTypeWrapper<IReuploadPhotoPayload>;
  RevokeDirectDebitMandateInput: IRevokeDirectDebitMandateInput;
  RevokeDirectDebitMandatePayload: ResolverTypeWrapper<IRevokeDirectDebitMandatePayload>;
  RevokeSplitBillingContractInput: IRevokeSplitBillingContractInput;
  RevokeSplitBillingContractPayload: ResolverTypeWrapper<IRevokeSplitBillingContractPayload>;
  Rgba: ResolverTypeWrapper<IRgba>;
  RightToBeForgottenReason: ResolverTypeWrapper<IRightToBeForgottenReason>;
  RightToBeForgottenReasonEnum: IRightToBeForgottenReasonEnum;
  Route: ResolverTypeWrapper<IRoute>;
  RouteMetadata: ResolverTypeWrapper<IRouteMetadata>;
  RouteMutation: IRouteMutation;
  SegmentPrice: ResolverTypeWrapper<ISegmentPrice>;
  SendNewInvitationLinkInput: ISendNewInvitationLinkInput;
  SendNewInvitationLinkPayload: ResolverTypeWrapper<ISendNewInvitationLinkPayload>;
  SendPasswordResetMailInput: ISendPasswordResetMailInput;
  SendPasswordResetMailPayload: ResolverTypeWrapper<ISendPasswordResetMailPayload>;
  SendPrivacyRequestMailInput: ISendPrivacyRequestMailInput;
  SendPrivacyRequestMailPayload: ResolverTypeWrapper<ISendPrivacyRequestMailPayload>;
  ServiceOperator: ResolverTypeWrapper<IServiceOperator>;
  ServiceProvider: ResolverTypeWrapper<IServiceProvider>;
  Services: ResolverTypeWrapper<IServices>;
  ServicesChangeRequest: ResolverTypeWrapper<IServicesChangeRequest>;
  SetAgreeTermsInput: ISetAgreeTermsInput;
  SetAgreeTermsPayload: ResolverTypeWrapper<ISetAgreeTermsPayload>;
  SetManualFavoriteCoordinatesInput: ISetManualFavoriteCoordinatesInput;
  SetManualFavoriteCoordinatesPayload: ResolverTypeWrapper<ISetManualFavoriteCoordinatesPayload>;
  SetOptionsInput: ISetOptionsInput;
  SetOptionsPayload: ResolverTypeWrapper<ISetOptionsPayload>;
  SetPhotoInput: ISetPhotoInput;
  SetPhotoPayload: ResolverTypeWrapper<ISetPhotoPayload>;
  SetProfileInput: ISetProfileInput;
  SetProfilePayload: ResolverTypeWrapper<ISetProfilePayload>;
  SetTermsInput: ISetTermsInput;
  SetTermsPayload: ResolverTypeWrapper<ISetTermsPayload>;
  Settlement: ResolverTypeWrapper<ISettlement>;
  SettlementTypeFinancialConsequences: ResolverTypeWrapper<ISettlementTypeFinancialConsequences>;
  SettlementTypeFinancialConsequencesEnum: ISettlementTypeFinancialConsequencesEnum;
  SharedCarReservation: ResolverTypeWrapper<ISharedCarReservation>;
  SharedCarReservationStatus: ResolverTypeWrapper<ISharedCarReservationStatus>;
  SharedVehicle: ResolverTypeWrapper<ISharedVehicle>;
  SharedVehicleReservation: ResolverTypeWrapper<ISharedVehicleReservation>;
  SharedVehicleReservationStatusEnum: ISharedVehicleReservationStatusEnum;
  ShuttelCheck: ResolverTypeWrapper<IShuttelCheck>;
  ShuttelMyWheels: ResolverTypeWrapper<IShuttelMyWheels>;
  ShuttelSixt: ResolverTypeWrapper<IShuttelSixt>;
  ShuttelTaxi: ResolverTypeWrapper<IShuttelTaxi>;
  ShuttelTranzerEticket: ResolverTypeWrapper<IShuttelTranzerEticket>;
  ShuttelVecore: ResolverTypeWrapper<IShuttelVecore>;
  Signature: ResolverTypeWrapper<ISignature>;
  SplitBillingContract: ResolverTypeWrapper<ISplitBillingContract>;
  SplitBillingContractEmailAddress: ResolverTypeWrapper<ISplitBillingContractEmailAddress>;
  SplitBillingContractEmailAddressEnum: ISplitBillingContractEmailAddressEnum;
  SplitBillingContracts: ResolverTypeWrapper<ISplitBillingContracts>;
  SplitBillingInvoice: ResolverTypeWrapper<ISplitBillingInvoice>;
  SsoEnabled: ResolverTypeWrapper<ISsoEnabled>;
  StarValue: ResolverTypeWrapper<IStarValue>;
  StarValueEnum: IStarValueEnum;
  StartDonkeyRepublicReservationInput: IStartDonkeyRepublicReservationInput;
  StartDonkeyRepublicReservationPayload: ResolverTypeWrapper<IStartDonkeyRepublicReservationPayload>;
  StartSharedCarReservationInput: IStartSharedCarReservationInput;
  StartSharedCarReservationPayload: ResolverTypeWrapper<IStartSharedCarReservationPayload>;
  StartSharedVehicleReservationInput: IStartSharedVehicleReservationInput;
  StartSharedVehicleReservationPayload: ResolverTypeWrapper<IStartSharedVehicleReservationPayload>;
  StateTransitions: ResolverTypeWrapper<IStateTransitions>;
  Station: ResolverTypeWrapper<IStation>;
  Statistics: ResolverTypeWrapper<IStatistics>;
  Stats: ResolverTypeWrapper<IStats>;
  StatsPerModality: ResolverTypeWrapper<IStatsPerModality>;
  Status: ResolverTypeWrapper<IStatus>;
  StatusEnum: IStatusEnum;
  StatusWithConfirmedEnum: IStatusWithConfirmedEnum;
  StreetAddress: ResolverTypeWrapper<IStreetAddress>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  StudentTravelProduct: ResolverTypeWrapper<IStudentTravelProduct>;
  SubCategory: ResolverTypeWrapper<ISubCategory>;
  SubCategoryEnum: ISubCategoryEnum;
  Subordinate: ResolverTypeWrapper<ISubordinate>;
  Subscription: ResolverTypeWrapper<{}>;
  SubscriptionEnum: ISubscriptionEnum;
  SupportedVersion: ResolverTypeWrapper<ISupportedVersion>;
  Task: ResolverTypeWrapper<IResolversInterfaceTypes<IResolversTypes>['Task']>;
  TaskEnum: ITaskEnum;
  Taxi: ResolverTypeWrapper<ITaxi>;
  TaxiFeature: ResolverTypeWrapper<ITaxiFeature>;
  TaxiReservation: ResolverTypeWrapper<ITaxiReservation>;
  TaxiReservationVehicle: ResolverTypeWrapper<ITaxiReservationVehicle>;
  TaxiStatus: ResolverTypeWrapper<ITaxiStatus>;
  TerminateOwnershipInput: ITerminateOwnershipInput;
  TerminateOwnershipPayload: ResolverTypeWrapper<ITerminateOwnershipPayload>;
  TerminatePersonalMeansOfRegistrationInput: ITerminatePersonalMeansOfRegistrationInput;
  TerminatePersonalMeansOfRegistrationPayload: ResolverTypeWrapper<ITerminatePersonalMeansOfRegistrationPayload>;
  TerminateReplacementManagerInput: ITerminateReplacementManagerInput;
  TerminateReplacementManagerPayload: ResolverTypeWrapper<ITerminateReplacementManagerPayload>;
  TerminateVehicleInput: ITerminateVehicleInput;
  TerminateVehiclePayload: ResolverTypeWrapper<ITerminateVehiclePayload>;
  TermsAndConditions: ResolverTypeWrapper<ITermsAndConditions>;
  TermsAndConditionsDocument: ResolverTypeWrapper<ITermsAndConditionsDocument>;
  TermsAndConditionsDocumentHumanizedKind: ResolverTypeWrapper<ITermsAndConditionsDocumentHumanizedKind>;
  TermsAndConditionsDocumentKindEnum: ITermsAndConditionsDocumentKindEnum;
  Theme: ResolverTypeWrapper<ITheme>;
  Ticket: ResolverTypeWrapper<ITicket>;
  TicketMedia: ResolverTypeWrapper<ITicketMedia>;
  Timestamp: ResolverTypeWrapper<ITimestamp>;
  TokenTypeEnum: ITokenTypeEnum;
  Totals: ResolverTypeWrapper<ITotals>;
  TrainClass: ResolverTypeWrapper<ITrainClass>;
  TrainClassChange: ResolverTypeWrapper<ITrainClassChange>;
  TrainClassChangeRequest: ResolverTypeWrapper<ITrainClassChangeRequest>;
  TrainClassEnum: ITrainClassEnum;
  TrainClassOptions: ResolverTypeWrapper<ITrainClassOptions>;
  Traject: ResolverTypeWrapper<ITraject>;
  TransactionsReport: ResolverTypeWrapper<ITransactionsReport>;
  Tranzer: ResolverTypeWrapper<ITranzer>;
  TranzerEticket: ResolverTypeWrapper<ITranzerEticket>;
  TranzerLeg: ResolverTypeWrapper<ITranzerLeg>;
  TranzerReservation: ResolverTypeWrapper<ITranzerReservation>;
  TranzerStatus: ResolverTypeWrapper<ITranzerStatus>;
  TranzerStatusEnum: ITranzerStatusEnum;
  TravelBehaviour: ResolverTypeWrapper<ITravelBehaviour>;
  TravelMode: ResolverTypeWrapper<ITravelMode>;
  TravelModeEnum: ITravelModeEnum;
  TravelPurpose: ResolverTypeWrapper<ITravelPurpose>;
  TravelPurposeEnum: ITravelPurposeEnum;
  TravelPurposeFinancialConsequences: ResolverTypeWrapper<ITravelPurposeFinancialConsequences>;
  TravelPurposeWithUnknown: ResolverTypeWrapper<ITravelPurposeWithUnknown>;
  TravelPurposeWithUnknownEnum: ITravelPurposeWithUnknownEnum;
  TravelerMutation: ITravelerMutation;
  TypeEnum: ITypeEnum;
  UUID: ResolverTypeWrapper<Scalars['UUID']['output']>;
  UnacceptMobilityEventInput: IUnacceptMobilityEventInput;
  UnacceptMobilityEventPayload: ResolverTypeWrapper<IUnacceptMobilityEventPayload>;
  UnacceptMobilityEventsInput: IUnacceptMobilityEventsInput;
  UnacceptMobilityEventsPayload: ResolverTypeWrapper<IUnacceptMobilityEventsPayload>;
  UnconfirmMobilityEventInput: IUnconfirmMobilityEventInput;
  UnconfirmMobilityEventPayload: ResolverTypeWrapper<IUnconfirmMobilityEventPayload>;
  UnconfirmMobilityEventsInput: IUnconfirmMobilityEventsInput;
  UnconfirmMobilityEventsPayload: ResolverTypeWrapper<IUnconfirmMobilityEventsPayload>;
  UnitEnum: IUnitEnum;
  UnlockDonkeyRepublicAssetInput: IUnlockDonkeyRepublicAssetInput;
  UnlockDonkeyRepublicAssetPayload: ResolverTypeWrapper<IUnlockDonkeyRepublicAssetPayload>;
  UpdateFavoriteInput: IUpdateFavoriteInput;
  UpdateFavoritePayload: ResolverTypeWrapper<IUpdateFavoritePayload>;
  UpdateMileageInput: IUpdateMileageInput;
  UpdateMileagePayload: ResolverTypeWrapper<IUpdateMileagePayload>;
  UpdateMobilityEventInput: IUpdateMobilityEventInput;
  UpdateMobilityEventPayload: ResolverTypeWrapper<IUpdateMobilityEventPayload>;
  UpdateMobilityEventsInput: IUpdateMobilityEventsInput;
  UpdateMobilityEventsPayload: ResolverTypeWrapper<IUpdateMobilityEventsPayload>;
  UpdateRouteMutation: IUpdateRouteMutation;
  UpdateTaxiReservationInput: IUpdateTaxiReservationInput;
  UpdateTaxiReservationPayload: ResolverTypeWrapper<IUpdateTaxiReservationPayload>;
  UpdateUserProfileInput: IUpdateUserProfileInput;
  UpdateUserProfilePayload: ResolverTypeWrapper<IUpdateUserProfilePayload>;
  UpdateVehicleInput: IUpdateVehicleInput;
  UpdateVehiclePayload: ResolverTypeWrapper<IUpdateVehiclePayload>;
  UpdateWhatMutation: IUpdateWhatMutation;
  UpdateWhyMutation: IUpdateWhyMutation;
  Usage: ResolverTypeWrapper<IUsage>;
  UserProfileAddress: IUserProfileAddress;
  V2BudgetGroup: ResolverTypeWrapper<IV2BudgetGroup>;
  V2Organization: ResolverTypeWrapper<IV2Organization>;
  ValidationError: ResolverTypeWrapper<IValidationError>;
  VatRate: ResolverTypeWrapper<IVatRate>;
  Vehicle: ResolverTypeWrapper<IVehicle>;
  VehicleActions: ResolverTypeWrapper<IVehicleActions>;
  VehicleAsset: ResolverTypeWrapper<IVehicleAsset>;
  VehicleFuelTypeEnum: IVehicleFuelTypeEnum;
  VehicleInformation: ResolverTypeWrapper<IVehicleInformation>;
  VehicleModalityKind: ResolverTypeWrapper<IVehicleModalityKind>;
  VehicleOptions: ResolverTypeWrapper<IVehicleOptions>;
  VehicleStatusEnum: IVehicleStatusEnum;
  What: ResolverTypeWrapper<IWhat>;
  WhatOption: ResolverTypeWrapper<IWhatOption>;
  When: ResolverTypeWrapper<IResolversInterfaceTypes<IResolversTypes>['When']>;
  Where: ResolverTypeWrapper<IResolversUnionTypes<IResolversTypes>['Where']>;
  Why: ResolverTypeWrapper<IWhy>;
  WhyMutation: IWhyMutation;
  WhyStatus: ResolverTypeWrapper<IWhyStatus>;
  Wrpm: ResolverTypeWrapper<IWrpm>;
  WrpmModality: ResolverTypeWrapper<IWrpmModality>;
  WrpmModalityEnum: IWrpmModalityEnum;
  YellowbrickService: ResolverTypeWrapper<IYellowbrickService>;
  YellowbrickSp: ResolverTypeWrapper<IYellowbrickSp>;
  Zone: ResolverTypeWrapper<IZone>;
  reisbalansMutation: ResolverTypeWrapper<IReisbalansMutation>;
  reisbalansQuery: ResolverTypeWrapper<IReisbalansQuery>;
  reisbalansV2Query: ResolverTypeWrapper<IReisbalansV2Query>;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type IResolversParentTypes = ResolversObject<{
  AcceptMobilityEventsInput: IAcceptMobilityEventsInput;
  AcceptMobilityEventsPayload: IAcceptMobilityEventsPayload;
  ActionRequired: IActionRequired;
  Actions: IActions;
  ActivatableDongle: IActivatableDongle;
  ActivateEnecoDongleInput: IActivateEnecoDongleInput;
  ActivateEnecoDonglePayload: IActivateEnecoDonglePayload;
  ActivatePersonalMeansOfRegistrationInput: IActivatePersonalMeansOfRegistrationInput;
  ActivatePersonalMeansOfRegistrationPayload: IActivatePersonalMeansOfRegistrationPayload;
  ActiveAdditional: IActiveAdditional;
  ActiveGreenwheels: IActiveGreenwheels;
  ActiveSubscription: IActiveSubscription;
  ActiveYellowbrickSp: IActiveYellowbrickSp;
  AddPushNotificationTokenInput: IAddPushNotificationTokenInput;
  AddPushNotificationTokenPayload: IAddPushNotificationTokenPayload;
  AddToWhitelistInput: IAddToWhitelistInput;
  AddToWhitelistPayload: IAddToWhitelistPayload;
  AddableVehiclePermission: IAddableVehiclePermission;
  Address: IAddress;
  AdminLoginInput: IAdminLoginInput;
  AdminLoginPayload: IAdminLoginPayload;
  Amber: IAmber;
  AppUrls: IAppUrls;
  AssetAvailability: IAssetAvailability;
  AssetLocation: IAssetLocation;
  AssetStation: IAssetStation;
  AssignReplacementManagerInput: IAssignReplacementManagerInput;
  AssignReplacementManagerPayload: IAssignReplacementManagerPayload;
  Attachment: IAttachment;
  Auth: IAuth;
  AvailableFeatureInterface: IResolversInterfaceTypes<IResolversParentTypes>['AvailableFeatureInterface'];
  AvailableServiceInterface: IResolversInterfaceTypes<IResolversParentTypes>['AvailableServiceInterface'];
  AvailableSharedCar: IAvailableSharedCar;
  AvailableSharedVehicle: IAvailableSharedVehicle;
  AvailableSubscription: IAvailableSubscription;
  Balance: IBalance;
  Base64: Scalars['Base64']['output'];
  BigDecimal: Scalars['BigDecimal']['output'];
  BikeAccess: IBikeAccess;
  Boolean: Scalars['Boolean']['output'];
  Branding: IBranding;
  Budget: IBudget;
  BudgetGroup: IBudgetGroup;
  CalculatedDistance: ICalculatedDistance;
  CancelDonkeyRepublicReservationInput: ICancelDonkeyRepublicReservationInput;
  CancelDonkeyRepublicReservationPayload: ICancelDonkeyRepublicReservationPayload;
  CancelSharedCarReservationInput: ICancelSharedCarReservationInput;
  CancelSharedCarReservationPayload: ICancelSharedCarReservationPayload;
  CancelSharedVehicleReservationInput: ICancelSharedVehicleReservationInput;
  CancelSharedVehicleReservationPayload: ICancelSharedVehicleReservationPayload;
  CancelTaxiReservationInput: ICancelTaxiReservationInput;
  CancelTaxiReservationPayload: ICancelTaxiReservationPayload;
  Category: ICategory;
  Co2Emission: ICo2Emission;
  Co2EmissionPerModality: ICo2EmissionPerModality;
  Co2EmissionSummary: ICo2EmissionSummary;
  Color: IColor;
  CompensationRideToBeCreated: ICompensationRideToBeCreated;
  Configuration: IConfiguration;
  ConfigurationProfileForHost: IConfigurationProfileForHost;
  ConfigureMfaInput: IConfigureMfaInput;
  ConfigureMfaPayload: IConfigureMfaPayload;
  ConfirmGreenwheelsInput: IConfirmGreenwheelsInput;
  ConfirmGreenwheelsPayload: IConfirmGreenwheelsPayload;
  ConfirmInput: IConfirmInput;
  ConfirmMobilityEventInput: IConfirmMobilityEventInput;
  ConfirmMobilityEventPayload: IConfirmMobilityEventPayload;
  ConfirmMobilityEventsInput: IConfirmMobilityEventsInput;
  ConfirmMobilityEventsPayload: IConfirmMobilityEventsPayload;
  ConfirmPayload: IConfirmPayload;
  ConfirmSharedCarReservationInput: IConfirmSharedCarReservationInput;
  ConfirmSharedCarReservationPayload: IConfirmSharedCarReservationPayload;
  ConfirmSharedVehicleReservationInput: IConfirmSharedVehicleReservationInput;
  ConfirmSharedVehicleReservationPayload: IConfirmSharedVehicleReservationPayload;
  ConfirmTaxiReservationInput: IConfirmTaxiReservationInput;
  ConfirmTaxiReservationPayload: IConfirmTaxiReservationPayload;
  ContractActions: IContractActions;
  ContractStatus: IContractStatus;
  Coordinate: Scalars['Coordinate']['output'];
  Coordinates: ICoordinates;
  CoordinatesMutation: ICoordinatesMutation;
  CopyMobilityEventsForDayInput: ICopyMobilityEventsForDayInput;
  CopyMobilityEventsForDayPayload: Omit<ICopyMobilityEventsForDayPayload, 'days'> & { days: Array<IResolversParentTypes['Day']> };
  CopyMobilityEventsInput: ICopyMobilityEventsInput;
  CopyMobilityEventsPayload: ICopyMobilityEventsPayload;
  Cost: ICost;
  CountryIsoCode: ICountryIsoCode;
  CreateCommuteCompensationInput: ICreateCommuteCompensationInput;
  CreateCommuteCompensationPayload: ICreateCommuteCompensationPayload;
  CreateCommuteRidesInput: ICreateCommuteRidesInput;
  CreateCommuteRidesPayload: ICreateCommuteRidesPayload;
  CreateCompensationMobilityEventInput: ICreateCompensationMobilityEventInput;
  CreateCompensationMobilityEventPayload: ICreateCompensationMobilityEventPayload;
  CreateDirectDebitMandateInput: ICreateDirectDebitMandateInput;
  CreateDirectDebitMandatePayload: ICreateDirectDebitMandatePayload;
  CreateDonkeyRepublicReservationInput: ICreateDonkeyRepublicReservationInput;
  CreateDonkeyRepublicReservationPayload: ICreateDonkeyRepublicReservationPayload;
  CreateFavoriteInput: ICreateFavoriteInput;
  CreateFavoritePayload: ICreateFavoritePayload;
  CreateLeaseBikeOrderInput: ICreateLeaseBikeOrderInput;
  CreateLeaseBikeOrderPayload: ICreateLeaseBikeOrderPayload;
  CreateMileageInput: ICreateMileageInput;
  CreateMileagePayload: ICreateMileagePayload;
  CreateMobilityCardOrderInput: ICreateMobilityCardOrderInput;
  CreateMobilityCardOrderPayload: ICreateMobilityCardOrderPayload;
  CreateMobilityEventInput: ICreateMobilityEventInput;
  CreateMobilityEventPayload: ICreateMobilityEventPayload;
  CreateParkingOrderInput: ICreateParkingOrderInput;
  CreateParkingOrderPayload: ICreateParkingOrderPayload;
  CreateTaxiReservationInput: ICreateTaxiReservationInput;
  CreateTaxiReservationPayload: ICreateTaxiReservationPayload;
  CreateTranzerReservationInput: ICreateTranzerReservationInput;
  CreateTranzerReservationPayload: ICreateTranzerReservationPayload;
  CreateUserProfileInput: ICreateUserProfileInput;
  CreateUserProfilePayload: ICreateUserProfilePayload;
  CreateVehicleInput: ICreateVehicleInput;
  CreateVehiclePayload: ICreateVehiclePayload;
  CreateWhatMutation: ICreateWhatMutation;
  CreateWorkFromHomeInput: ICreateWorkFromHomeInput;
  CreateWorkFromHomePayload: ICreateWorkFromHomePayload;
  CustomerLabelTheme: ICustomerLabelTheme;
  CustomerService: ICustomerService;
  Date: Scalars['Date']['output'];
  DateRangeInput: IDateRangeInput;
  DateRangeStringInput: IDateRangeStringInput;
  DateTime: Scalars['DateTime']['output'];
  Day: IResolversUnionTypes<IResolversParentTypes>['Day'];
  DayWithMobilityEvents: IDayWithMobilityEvents;
  DayWithoutEmployment: IDayWithoutEmployment;
  Description: IDescription;
  DestroyFavoriteInput: IDestroyFavoriteInput;
  DestroyFavoritePayload: IDestroyFavoritePayload;
  DestroyMobilityEventInput: IDestroyMobilityEventInput;
  DestroyMobilityEventPayload: Omit<IDestroyMobilityEventPayload, 'day'> & { day: IResolversParentTypes['Day'] };
  DestroyMobilityEventsInput: IDestroyMobilityEventsInput;
  DestroyMobilityEventsPayload: IDestroyMobilityEventsPayload;
  DestroyProfileAddressInput: IDestroyProfileAddressInput;
  DestroyProfileAddressPayload: IDestroyProfileAddressPayload;
  DirectDebitInvoice: IDirectDebitInvoice;
  DirectDebitMandate: IDirectDebitMandate;
  DirectDebitMandates: IDirectDebitMandates;
  Display: IDisplay;
  Distance: IDistance;
  DistancePerModality: IDistancePerModality;
  DistanceSummary: IDistanceSummary;
  Diverted: IDiverted;
  DonkeyLeg: IDonkeyLeg;
  DonkeyQuote: IDonkeyQuote;
  DonkeyRepublic: IDonkeyRepublic;
  DonkeyRepublicBooking: IDonkeyRepublicBooking;
  DonkeyRepublicModality: IDonkeyRepublicModality;
  DonkeyRepublicReservation: IDonkeyRepublicReservation;
  DonkeyRepublicResult: IDonkeyRepublicResult;
  DonkeyRepublicStatus: IDonkeyRepublicStatus;
  Driver: IDriver;
  DriversLicenseCountry: IDriversLicenseCountry;
  Duration: IDuration;
  Ekey: IEkey;
  EkeyLock: IEkeyLock;
  Employee: Omit<IEmployee, 'day' | 'days'> & { day?: Maybe<IResolversParentTypes['Day']>, days: Array<IResolversParentTypes['Day']> };
  EmployeeAddress: IEmployeeAddress;
  EmployeeAddressMutation: IEmployeeAddressMutation;
  EmployeeDetails: IEmployeeDetails;
  EmployeeDetailsResponse: IEmployeeDetailsResponse;
  EmployeeFinancialConsequences: IEmployeeFinancialConsequences;
  Employment: IEmployment;
  EnecoDongle: IEnecoDongle;
  Error: IError;
  Explanation: IExplanation;
  ExplanationRequired: IExplanationRequired;
  ExportFiles: IExportFiles;
  ExportUrls: IExportUrls;
  ExternalUrl: IExternalUrl;
  ExternalUrls: IExternalUrls;
  Favorite: IFavorite;
  FavoriteActions: IFavoriteActions;
  FavoriteLocation: IFavoriteLocation;
  Favorites: IFavorites;
  FeatureOption: IFeatureOption;
  FeatureOrderStatus: IFeatureOrderStatus;
  FeatureUrls: IFeatureUrls;
  FilterOptionItem: IFilterOptionItem;
  FilterOptions: IFilterOptions;
  Financial: IFinancial;
  FinancialComponent: IFinancialComponent;
  FinancialConsequences: IFinancialConsequences;
  FinancialFact: IFinancialFact;
  FinancialFactMutation: IFinancialFactMutation;
  FinancialMutation: IFinancialMutation;
  FinancialSummary: IFinancialSummary;
  FinishDonkeyRepublicReservationInput: IFinishDonkeyRepublicReservationInput;
  FinishDonkeyRepublicReservationPayload: IFinishDonkeyRepublicReservationPayload;
  FinishSharedCarReservationInput: IFinishSharedCarReservationInput;
  FinishSharedCarReservationPayload: IFinishSharedCarReservationPayload;
  FinishSharedVehicleReservationInput: IFinishSharedVehicleReservationInput;
  FinishSharedVehicleReservationPayload: IFinishSharedVehicleReservationPayload;
  FirstClassReason: IFirstClassReason;
  Float: Scalars['Float']['output'];
  Fuel: IFuel;
  GeoLocation: IGeoLocation;
  GetAccessUrlInput: IGetAccessUrlInput;
  GetAccessUrlPayload: IGetAccessUrlPayload;
  GooglePlaces: IGooglePlaces;
  GooglePlacesDetailsResult: IGooglePlacesDetailsResult;
  GooglePlacesResult: IGooglePlacesResult;
  GooglePlacesStructuredText: IGooglePlacesStructuredText;
  GooglePlacesTypeValue: IGooglePlacesTypeValue;
  Greenwheels: IGreenwheels;
  GreenwheelsFleet: IGreenwheelsFleet;
  How: IHow;
  HowMuch: IHowMuch;
  Hsla: IHsla;
  Hub: IHub;
  ID: Scalars['ID']['output'];
  Icon: IIcon;
  Identity: IIdentity;
  Image: IImage;
  Int: Scalars['Int']['output'];
  InvoiceStatus: IInvoiceStatus;
  JSON: Scalars['JSON']['output'];
  JournalEntry: IJournalEntry;
  Journey: IJourney;
  JourneyLeg: IJourneyLeg;
  JourneyMode: IJourneyMode;
  JourneySorter: IJourneySorter;
  Label: ILabel;
  LeaseBike: ILeaseBike;
  LeaseBikeOrder: ILeaseBikeOrder;
  LeaseCar: ILeaseCar;
  Leg: ILeg;
  LicensePlate: ILicensePlate;
  Location: ILocation;
  LocationInput: ILocationInput;
  LocationMutation: ILocationMutation;
  Lock: ILock;
  LockDonkeyRepublicAssetInput: ILockDonkeyRepublicAssetInput;
  LockDonkeyRepublicAssetPayload: ILockDonkeyRepublicAssetPayload;
  LoginInput: ILoginInput;
  LoginPayload: ILoginPayload;
  LogoutInput: ILogoutInput;
  LogoutPayload: ILogoutPayload;
  MSAAbstractProperty: IMsaAbstractProperty;
  MSACarrier: IMsaCarrier;
  MSACreateCarrierRequestBodyRequestInput: IMsaCreateCarrierRequestBodyRequestInput;
  MSACreateProductGroupRequestBodyRequestInput: IMsaCreateProductGroupRequestBodyRequestInput;
  MSACreateProductRequestBodyRequestInput: IMsaCreateProductRequestBodyRequestInput;
  MSAProduct: IMsaProduct;
  MSAProductGroup: IMsaProductGroup;
  MSAPropertiesInput: IMsaPropertiesInput;
  MSAPropertyTypeBinding: IMsaPropertyTypeBinding;
  MSAPropertyTypeConstraint: IMsaPropertyTypeConstraint;
  MSAQueryCarrierTypePropertiesListItemsConstraintsItemsRules: IMsaQueryCarrierTypePropertiesListItemsConstraintsItemsRules;
  MSAReplaceCarrierPropertiesRequestBodyRequestInput: IMsaReplaceCarrierPropertiesRequestBodyRequestInput;
  MSAReplaceCarrierRequestBodyRequestInput: IMsaReplaceCarrierRequestBodyRequestInput;
  MSAReplaceProductPropertiesRequestBodyRequestInput: IMsaReplaceProductPropertiesRequestBodyRequestInput;
  MSASupportedCarriersForProductTypeList: IMsaSupportedCarriersForProductTypeList;
  MainCategory: IMainCategory;
  MandateActions: IMandateActions;
  MandateStatus: IMandateStatus;
  MarkMessageAsReadInput: IMarkMessageAsReadInput;
  MarkMessageAsReadPayload: IMarkMessageAsReadPayload;
  MeanOfTransportFiscality: IMeanOfTransportFiscality;
  MeansOfTransport: IMeansOfTransport;
  Media: IMedia;
  MediaSide: IMediaSide;
  MergeMobilityEventsInput: IMergeMobilityEventsInput;
  MergeMobilityEventsPayload: IMergeMobilityEventsPayload;
  MergedMobilityEventsPreview: IMergedMobilityEventsPreview;
  Message: IMessage;
  Mileage: IMileage;
  Mileages: IMileages;
  MobilityCardOrder: IMobilityCardOrder;
  MobilityCardOrderActions: IMobilityCardOrderActions;
  MobilityCardOrderPermission: IMobilityCardOrderPermission;
  MobilityCardOrderService: IMobilityCardOrderService;
  MobilityCardOrderStatus: IMobilityCardOrderStatus;
  MobilityCardOrders: IMobilityCardOrders;
  MobilityEvent: Omit<IMobilityEvent, 'where'> & { where?: Maybe<IResolversParentTypes['Where']> };
  MobilityEventAggregate: IMobilityEventAggregate;
  MobilityEventByDate: IMobilityEventByDate;
  MobilityEventFormValidations: IMobilityEventFormValidations;
  MobilityEventPreview: Omit<IMobilityEventPreview, 'where'> & { where?: Maybe<IResolversParentTypes['Where']> };
  MobilityEventTotals: IMobilityEventTotals;
  MobilityEventsWeek: IMobilityEventsWeek;
  MobilityServicesMutation: IMobilityServicesMutation;
  MobilityServicesQuery: IMobilityServicesQuery;
  Modality: IModality;
  ModalityOwnership: IModalityOwnership;
  Money: IMoney;
  MoneyTransfer: IMoneyTransfer;
  MoneyWithCurrency: IMoneyWithCurrency;
  MultiModalAvailability: IResolversInterfaceTypes<IResolversParentTypes>['MultiModalAvailability'];
  Mutation: {};
  NSRefundUrl: INsRefundUrl;
  NewProduct: INewProduct;
  NonCombined: INonCombined;
  ObjMap: Scalars['ObjMap']['output'];
  OpeningHours: IOpeningHours;
  OptionsMutation: IOptionsMutation;
  Organization: IOrganization;
  OvFietsLocation: IOvFietsLocation;
  OvPas: IOvPas;
  Parking: IParking;
  ParkingOrder: IParkingOrder;
  ParkingOrderActions: IParkingOrderActions;
  ParkingOrderStatus: IParkingOrderStatus;
  Password: IPassword;
  PasswordStrength: IPasswordStrength;
  PendingApproval: IPendingApproval;
  Permissions: IPermissions;
  PersonalMeansOfRegistration: IPersonalMeansOfRegistration;
  PersonalMeansOfRegistrationAbilities: IPersonalMeansOfRegistrationAbilities;
  PersonalMeansOfRegistrationEndsOn: IPersonalMeansOfRegistrationEndsOn;
  PersonalMeansOfRegistrationIdentity: IPersonalMeansOfRegistrationIdentity;
  PersonalMeansOfRegistrationKind: IPersonalMeansOfRegistrationKind;
  PersonalMeansOfRegistrationMetaStatus: IPersonalMeansOfRegistrationMetaStatus;
  PersonalMeansOfRegistrationPhotoRejectedReason: IPersonalMeansOfRegistrationPhotoRejectedReason;
  PersonalMeansOfRegistrationService: IPersonalMeansOfRegistrationService;
  PersonalMeansOfRegistrationServiceEndsOn: IPersonalMeansOfRegistrationServiceEndsOn;
  PersonalMeansOfRegistrationServiceIdentity: IPersonalMeansOfRegistrationServiceIdentity;
  PersonalMeansOfRegistrationServiceKind: IPersonalMeansOfRegistrationServiceKind;
  PersonalMeansOfRegistrationServiceStatus: IPersonalMeansOfRegistrationServiceStatus;
  PersonalMeansOfRegistrationStatus: IPersonalMeansOfRegistrationStatus;
  Photo: IPhoto;
  Places: IPlaces;
  PlacesDetailsResult: IPlacesDetailsResult;
  PlacesResult: IPlacesResult;
  PlacesStructuredText: IPlacesStructuredText;
  PlacesTypeValue: IPlacesTypeValue;
  PossibleReplacementManager: IPossibleReplacementManager;
  Price: IPrice;
  Privacy: IPrivacy;
  PrivacyRequestReason: IPrivacyRequestReason;
  Profile: IProfile;
  ProfileMutation: IProfileMutation;
  Project: IProject;
  PublicTransportStop: IPublicTransportStop;
  Purpose: IPurpose;
  Quantity: IQuantity;
  Query: {};
  Quote: IQuote;
  QuoteStatus: IQuoteStatus;
  Receipt: IReceipt;
  RecommendedCard: IRecommendedCard;
  RefreshTranzerReservationInput: IRefreshTranzerReservationInput;
  RefreshTranzerReservationPayload: IRefreshTranzerReservationPayload;
  RefundToBeLinked: IRefundToBeLinked;
  ReimbursableCategory: IReimbursableCategory;
  ReimbursableCategoryMethod: IReimbursableCategoryMethod;
  ReimbursementRule: IResolversInterfaceTypes<IResolversParentTypes>['ReimbursementRule'];
  RejectMobilityEventsInput: IRejectMobilityEventsInput;
  RejectMobilityEventsPayload: IRejectMobilityEventsPayload;
  RemoveFromWhitelistInput: IRemoveFromWhitelistInput;
  RemoveFromWhitelistPayload: IRemoveFromWhitelistPayload;
  RentalCar: IRentalCar;
  RentalLocations: IRentalLocations;
  ReplacementManager: IReplacementManager;
  ReportPersonalMeansOfRegistrationInput: IReportPersonalMeansOfRegistrationInput;
  ReportPersonalMeansOfRegistrationPayload: IReportPersonalMeansOfRegistrationPayload;
  RequestDates: IRequestDates;
  RequestDonkeyRepublicFeatureInput: IRequestDonkeyRepublicFeatureInput;
  RequestDonkeyRepublicFeaturePayload: IRequestDonkeyRepublicFeaturePayload;
  RequestFeatureInput: IRequestFeatureInput;
  RequestFeaturePayload: IRequestFeaturePayload;
  RequestServiceInput: IRequestServiceInput;
  RequestServicePayload: IRequestServicePayload;
  RequestTrainClassChangeInput: IRequestTrainClassChangeInput;
  RequestTrainClassChangePayload: IRequestTrainClassChangePayload;
  RequestableDonkeyRepublic: IRequestableDonkeyRepublic;
  RequestableFeature: IRequestableFeature;
  RequestableFeatureOption: IRequestableFeatureOption;
  RequestableService: IRequestableService;
  RequestableYellowbrickProduct: IRequestableYellowbrickProduct;
  RequiredFields: IRequiredFields;
  Reservation: IReservation;
  ReservationStatus: IReservationStatus;
  ResetPasswordInput: IResetPasswordInput;
  ResetPasswordPayload: IResetPasswordPayload;
  Result: IResult;
  ResultInterface: IResolversInterfaceTypes<IResolversParentTypes>['ResultInterface'];
  ResultWithAccessTokens: IResultWithAccessTokens;
  ReuploadPhotoInput: IReuploadPhotoInput;
  ReuploadPhotoPayload: IReuploadPhotoPayload;
  RevokeDirectDebitMandateInput: IRevokeDirectDebitMandateInput;
  RevokeDirectDebitMandatePayload: IRevokeDirectDebitMandatePayload;
  RevokeSplitBillingContractInput: IRevokeSplitBillingContractInput;
  RevokeSplitBillingContractPayload: IRevokeSplitBillingContractPayload;
  Rgba: IRgba;
  RightToBeForgottenReason: IRightToBeForgottenReason;
  Route: IRoute;
  RouteMetadata: IRouteMetadata;
  RouteMutation: IRouteMutation;
  SegmentPrice: ISegmentPrice;
  SendNewInvitationLinkInput: ISendNewInvitationLinkInput;
  SendNewInvitationLinkPayload: ISendNewInvitationLinkPayload;
  SendPasswordResetMailInput: ISendPasswordResetMailInput;
  SendPasswordResetMailPayload: ISendPasswordResetMailPayload;
  SendPrivacyRequestMailInput: ISendPrivacyRequestMailInput;
  SendPrivacyRequestMailPayload: ISendPrivacyRequestMailPayload;
  ServiceOperator: IServiceOperator;
  ServiceProvider: IServiceProvider;
  Services: IServices;
  ServicesChangeRequest: IServicesChangeRequest;
  SetAgreeTermsInput: ISetAgreeTermsInput;
  SetAgreeTermsPayload: ISetAgreeTermsPayload;
  SetManualFavoriteCoordinatesInput: ISetManualFavoriteCoordinatesInput;
  SetManualFavoriteCoordinatesPayload: ISetManualFavoriteCoordinatesPayload;
  SetOptionsInput: ISetOptionsInput;
  SetOptionsPayload: ISetOptionsPayload;
  SetPhotoInput: ISetPhotoInput;
  SetPhotoPayload: ISetPhotoPayload;
  SetProfileInput: ISetProfileInput;
  SetProfilePayload: ISetProfilePayload;
  SetTermsInput: ISetTermsInput;
  SetTermsPayload: ISetTermsPayload;
  Settlement: ISettlement;
  SettlementTypeFinancialConsequences: ISettlementTypeFinancialConsequences;
  SharedCarReservation: ISharedCarReservation;
  SharedCarReservationStatus: ISharedCarReservationStatus;
  SharedVehicle: ISharedVehicle;
  SharedVehicleReservation: ISharedVehicleReservation;
  ShuttelCheck: IShuttelCheck;
  ShuttelMyWheels: IShuttelMyWheels;
  ShuttelSixt: IShuttelSixt;
  ShuttelTaxi: IShuttelTaxi;
  ShuttelTranzerEticket: IShuttelTranzerEticket;
  ShuttelVecore: IShuttelVecore;
  Signature: ISignature;
  SplitBillingContract: ISplitBillingContract;
  SplitBillingContractEmailAddress: ISplitBillingContractEmailAddress;
  SplitBillingContracts: ISplitBillingContracts;
  SplitBillingInvoice: ISplitBillingInvoice;
  SsoEnabled: ISsoEnabled;
  StarValue: IStarValue;
  StartDonkeyRepublicReservationInput: IStartDonkeyRepublicReservationInput;
  StartDonkeyRepublicReservationPayload: IStartDonkeyRepublicReservationPayload;
  StartSharedCarReservationInput: IStartSharedCarReservationInput;
  StartSharedCarReservationPayload: IStartSharedCarReservationPayload;
  StartSharedVehicleReservationInput: IStartSharedVehicleReservationInput;
  StartSharedVehicleReservationPayload: IStartSharedVehicleReservationPayload;
  StateTransitions: IStateTransitions;
  Station: IStation;
  Statistics: IStatistics;
  Stats: IStats;
  StatsPerModality: IStatsPerModality;
  Status: IStatus;
  StreetAddress: IStreetAddress;
  String: Scalars['String']['output'];
  StudentTravelProduct: IStudentTravelProduct;
  SubCategory: ISubCategory;
  Subordinate: ISubordinate;
  Subscription: {};
  SupportedVersion: ISupportedVersion;
  Task: IResolversInterfaceTypes<IResolversParentTypes>['Task'];
  Taxi: ITaxi;
  TaxiFeature: ITaxiFeature;
  TaxiReservation: ITaxiReservation;
  TaxiReservationVehicle: ITaxiReservationVehicle;
  TaxiStatus: ITaxiStatus;
  TerminateOwnershipInput: ITerminateOwnershipInput;
  TerminateOwnershipPayload: ITerminateOwnershipPayload;
  TerminatePersonalMeansOfRegistrationInput: ITerminatePersonalMeansOfRegistrationInput;
  TerminatePersonalMeansOfRegistrationPayload: ITerminatePersonalMeansOfRegistrationPayload;
  TerminateReplacementManagerInput: ITerminateReplacementManagerInput;
  TerminateReplacementManagerPayload: ITerminateReplacementManagerPayload;
  TerminateVehicleInput: ITerminateVehicleInput;
  TerminateVehiclePayload: ITerminateVehiclePayload;
  TermsAndConditions: ITermsAndConditions;
  TermsAndConditionsDocument: ITermsAndConditionsDocument;
  TermsAndConditionsDocumentHumanizedKind: ITermsAndConditionsDocumentHumanizedKind;
  Theme: ITheme;
  Ticket: ITicket;
  TicketMedia: ITicketMedia;
  Timestamp: ITimestamp;
  Totals: ITotals;
  TrainClass: ITrainClass;
  TrainClassChange: ITrainClassChange;
  TrainClassChangeRequest: ITrainClassChangeRequest;
  TrainClassOptions: ITrainClassOptions;
  Traject: ITraject;
  TransactionsReport: ITransactionsReport;
  Tranzer: ITranzer;
  TranzerEticket: ITranzerEticket;
  TranzerLeg: ITranzerLeg;
  TranzerReservation: ITranzerReservation;
  TranzerStatus: ITranzerStatus;
  TravelBehaviour: ITravelBehaviour;
  TravelMode: ITravelMode;
  TravelPurpose: ITravelPurpose;
  TravelPurposeFinancialConsequences: ITravelPurposeFinancialConsequences;
  TravelPurposeWithUnknown: ITravelPurposeWithUnknown;
  TravelerMutation: ITravelerMutation;
  UUID: Scalars['UUID']['output'];
  UnacceptMobilityEventInput: IUnacceptMobilityEventInput;
  UnacceptMobilityEventPayload: IUnacceptMobilityEventPayload;
  UnacceptMobilityEventsInput: IUnacceptMobilityEventsInput;
  UnacceptMobilityEventsPayload: IUnacceptMobilityEventsPayload;
  UnconfirmMobilityEventInput: IUnconfirmMobilityEventInput;
  UnconfirmMobilityEventPayload: IUnconfirmMobilityEventPayload;
  UnconfirmMobilityEventsInput: IUnconfirmMobilityEventsInput;
  UnconfirmMobilityEventsPayload: IUnconfirmMobilityEventsPayload;
  UnlockDonkeyRepublicAssetInput: IUnlockDonkeyRepublicAssetInput;
  UnlockDonkeyRepublicAssetPayload: IUnlockDonkeyRepublicAssetPayload;
  UpdateFavoriteInput: IUpdateFavoriteInput;
  UpdateFavoritePayload: IUpdateFavoritePayload;
  UpdateMileageInput: IUpdateMileageInput;
  UpdateMileagePayload: IUpdateMileagePayload;
  UpdateMobilityEventInput: IUpdateMobilityEventInput;
  UpdateMobilityEventPayload: IUpdateMobilityEventPayload;
  UpdateMobilityEventsInput: IUpdateMobilityEventsInput;
  UpdateMobilityEventsPayload: IUpdateMobilityEventsPayload;
  UpdateRouteMutation: IUpdateRouteMutation;
  UpdateTaxiReservationInput: IUpdateTaxiReservationInput;
  UpdateTaxiReservationPayload: IUpdateTaxiReservationPayload;
  UpdateUserProfileInput: IUpdateUserProfileInput;
  UpdateUserProfilePayload: IUpdateUserProfilePayload;
  UpdateVehicleInput: IUpdateVehicleInput;
  UpdateVehiclePayload: IUpdateVehiclePayload;
  UpdateWhatMutation: IUpdateWhatMutation;
  UpdateWhyMutation: IUpdateWhyMutation;
  Usage: IUsage;
  UserProfileAddress: IUserProfileAddress;
  V2BudgetGroup: IV2BudgetGroup;
  V2Organization: IV2Organization;
  ValidationError: IValidationError;
  VatRate: IVatRate;
  Vehicle: IVehicle;
  VehicleActions: IVehicleActions;
  VehicleAsset: IVehicleAsset;
  VehicleInformation: IVehicleInformation;
  VehicleModalityKind: IVehicleModalityKind;
  VehicleOptions: IVehicleOptions;
  What: IWhat;
  WhatOption: IWhatOption;
  When: IResolversInterfaceTypes<IResolversParentTypes>['When'];
  Where: IResolversUnionTypes<IResolversParentTypes>['Where'];
  Why: IWhy;
  WhyMutation: IWhyMutation;
  WhyStatus: IWhyStatus;
  Wrpm: IWrpm;
  WrpmModality: IWrpmModality;
  YellowbrickService: IYellowbrickService;
  YellowbrickSp: IYellowbrickSp;
  Zone: IZone;
  reisbalansMutation: IReisbalansMutation;
  reisbalansQuery: IReisbalansQuery;
  reisbalansV2Query: IReisbalansV2Query;
}>;

export type IEnumDirectiveArgs = {
  subgraph?: Maybe<Scalars['String']['input']>;
  value?: Maybe<Scalars['String']['input']>;
};

export type IEnumDirectiveResolver<Result, Parent, ContextType = IGraphQLContext, Args = IEnumDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type IExampleDirectiveArgs = {
  subgraph?: Maybe<Scalars['String']['input']>;
  value?: Maybe<Scalars['ObjMap']['input']>;
};

export type IExampleDirectiveResolver<Result, Parent, ContextType = IGraphQLContext, Args = IExampleDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type IHttpOperationDirectiveArgs = {
  httpMethod?: Maybe<IHttpMethod>;
  isBinary?: Maybe<Scalars['Boolean']['input']>;
  jsonApiFields?: Maybe<Scalars['Boolean']['input']>;
  operationSpecificHeaders?: Maybe<Scalars['ObjMap']['input']>;
  path?: Maybe<Scalars['String']['input']>;
  queryParamArgMap?: Maybe<Scalars['ObjMap']['input']>;
  queryStringOptionsByParam?: Maybe<Scalars['ObjMap']['input']>;
  requestBaseBody?: Maybe<Scalars['ObjMap']['input']>;
  subgraph?: Maybe<Scalars['String']['input']>;
};

export type IHttpOperationDirectiveResolver<Result, Parent, ContextType = IGraphQLContext, Args = IHttpOperationDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type IOneOfDirectiveArgs = { };

export type IOneOfDirectiveResolver<Result, Parent, ContextType = IGraphQLContext, Args = IOneOfDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ITransportDirectiveArgs = {
  headers?: Maybe<Scalars['ObjMap']['input']>;
  kind?: Maybe<Scalars['String']['input']>;
  location?: Maybe<Scalars['String']['input']>;
  queryParams?: Maybe<Scalars['ObjMap']['input']>;
  queryStringOptions?: Maybe<Scalars['ObjMap']['input']>;
  subgraph?: Maybe<Scalars['String']['input']>;
};

export type ITransportDirectiveResolver<Result, Parent, ContextType = IGraphQLContext, Args = ITransportDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ITypescriptDirectiveArgs = {
  subgraph?: Maybe<Scalars['String']['input']>;
  type?: Maybe<Scalars['String']['input']>;
};

export type ITypescriptDirectiveResolver<Result, Parent, ContextType = IGraphQLContext, Args = ITypescriptDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type IAcceptMobilityEventsPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['AcceptMobilityEventsPayload'] = IResolversParentTypes['AcceptMobilityEventsPayload']> = ResolversObject<{
  acceptedMobilityEvents?: Resolver<Array<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  unacceptedMobilityEvents?: Resolver<Array<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IActionRequiredResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ActionRequired'] = IResolversParentTypes['ActionRequired']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  path?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<IResolversTypes['TaskEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IActionsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Actions'] = IResolversParentTypes['Actions']> = ResolversObject<{
  canRestoreToInitial?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  categoryAndVehicleEditable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  changeCombinedRide?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  changeTravelPurpose?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  confirmable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  copyable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  deletable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  distanceOverridable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  editable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  mergeable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  missingCheckout?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  submittable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  unacceptable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  unconfirmable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  viewDetails?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IActivatableDongleResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ActivatableDongle'] = IResolversParentTypes['ActivatableDongle']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  path?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<IResolversTypes['TaskEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IActivateEnecoDonglePayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ActivateEnecoDonglePayload'] = IResolversParentTypes['ActivateEnecoDonglePayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  enecoDongle?: Resolver<Maybe<IResolversTypes['EnecoDongle']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IActivatePersonalMeansOfRegistrationPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ActivatePersonalMeansOfRegistrationPayload'] = IResolversParentTypes['ActivatePersonalMeansOfRegistrationPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  personalMeansOfRegistration?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistration']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IActiveAdditionalResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ActiveAdditional'] = IResolversParentTypes['ActiveAdditional']> = ResolversObject<{
  budgetGroupAvailability?: Resolver<Maybe<IResolversTypes['AvailableServiceAvailabilityEnum']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['Description']>, ParentType, ContextType>;
  endsOn?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationServiceEndsOn']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<IResolversTypes['Icon']>, ParentType, ContextType>;
  identity?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationServiceIdentity']>, ParentType, ContextType>;
  kind?: Resolver<IResolversTypes['PersonalMeansOfRegistrationServiceKind'], ParentType, ContextType>;
  mobideskOwnershipToken?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  personalMeansOfRegistration?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistration']>, ParentType, ContextType>;
  recordId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  requiresMotivation?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationServiceStatus']>, ParentType, ContextType>;
  termsAndConditions?: Resolver<Maybe<IResolversTypes['TermsAndConditions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IActiveGreenwheelsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ActiveGreenwheels'] = IResolversParentTypes['ActiveGreenwheels']> = ResolversObject<{
  activatable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  activationLink?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  budgetGroupAvailability?: Resolver<Maybe<IResolversTypes['AvailableServiceAvailabilityEnum']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['Description']>, ParentType, ContextType>;
  driversLicenseCountries?: Resolver<Array<IResolversTypes['DriversLicenseCountry']>, ParentType, ContextType>;
  endsOn?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationServiceEndsOn']>, ParentType, ContextType>;
  greenwheelsTermsAndConditionsUrl?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  icon?: Resolver<Maybe<IResolversTypes['Icon']>, ParentType, ContextType>;
  identity?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationServiceIdentity']>, ParentType, ContextType>;
  kind?: Resolver<IResolversTypes['PersonalMeansOfRegistrationServiceKind'], ParentType, ContextType>;
  mobideskOwnershipToken?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  personalMeansOfRegistration?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistration']>, ParentType, ContextType>;
  recordId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  registerable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  requiresMotivation?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationServiceStatus']>, ParentType, ContextType>;
  termsAndConditions?: Resolver<Maybe<IResolversTypes['TermsAndConditions']>, ParentType, ContextType>;
  urls?: Resolver<Maybe<IResolversTypes['AppUrls']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IActiveSubscriptionResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ActiveSubscription'] = IResolversParentTypes['ActiveSubscription']> = ResolversObject<{
  budgetGroupAvailability?: Resolver<Maybe<IResolversTypes['AvailableServiceAvailabilityEnum']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['Description']>, ParentType, ContextType>;
  endsOn?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationServiceEndsOn']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<IResolversTypes['Icon']>, ParentType, ContextType>;
  identity?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationServiceIdentity']>, ParentType, ContextType>;
  kind?: Resolver<IResolversTypes['PersonalMeansOfRegistrationServiceKind'], ParentType, ContextType>;
  mobideskOwnershipToken?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  personalMeansOfRegistration?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistration']>, ParentType, ContextType>;
  recordId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  requiresMotivation?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  starValue?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationServiceStatus']>, ParentType, ContextType>;
  termsAndConditions?: Resolver<Maybe<IResolversTypes['TermsAndConditions']>, ParentType, ContextType>;
  trainClass?: Resolver<Maybe<IResolversTypes['TrainClass']>, ParentType, ContextType>;
  trainClassChange?: Resolver<IResolversTypes['TrainClassChange'], ParentType, ContextType>;
  traject?: Resolver<Maybe<IResolversTypes['Traject']>, ParentType, ContextType>;
  zone?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IActiveYellowbrickSpResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ActiveYellowbrickSp'] = IResolversParentTypes['ActiveYellowbrickSp']> = ResolversObject<{
  budgetGroupAvailability?: Resolver<Maybe<IResolversTypes['AvailableServiceAvailabilityEnum']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['Description']>, ParentType, ContextType>;
  endsOn?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationServiceEndsOn']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<IResolversTypes['Icon']>, ParentType, ContextType>;
  identity?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationServiceIdentity']>, ParentType, ContextType>;
  kind?: Resolver<IResolversTypes['PersonalMeansOfRegistrationServiceKind'], ParentType, ContextType>;
  mobideskOwnershipToken?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  personalMeansOfRegistration?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistration']>, ParentType, ContextType>;
  recordId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  requiresMotivation?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationServiceStatus']>, ParentType, ContextType>;
  termsAndConditions?: Resolver<Maybe<IResolversTypes['TermsAndConditions']>, ParentType, ContextType>;
  username?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IAddPushNotificationTokenPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['AddPushNotificationTokenPayload'] = IResolversParentTypes['AddPushNotificationTokenPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IAddToWhitelistPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['AddToWhitelistPayload'] = IResolversParentTypes['AddToWhitelistPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  studentTravelRequestDates?: Resolver<IResolversTypes['RequestDates'], ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IAddableVehiclePermissionResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['AddableVehiclePermission'] = IResolversParentTypes['AddableVehiclePermission']> = ResolversObject<{
  allowedFuelTypes?: Resolver<Array<IResolversTypes['Fuel']>, ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  name?: Resolver<IResolversTypes['VehicleModalityKind'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IAddressResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Address'] = IResolversParentTypes['Address']> = ResolversObject<{
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  latitude?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
  location?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  longitude?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
  type?: Resolver<Maybe<IResolversTypes['TypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IAdminLoginPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['AdminLoginPayload'] = IResolversParentTypes['AdminLoginPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  jwt?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  tokens?: Resolver<Maybe<IResolversTypes['Auth']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IAmberResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Amber'] = IResolversParentTypes['Amber']> = ResolversObject<{
  activatable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  agreedTermsAndConditions?: Resolver<Maybe<Array<IResolversTypes['TermsAndConditions']>>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  id?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  mobideskOwnershipToken?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  motivation?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<IResolversTypes['FeatureOption'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['FeatureOrderStatus'], ParentType, ContextType>;
  url?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  urls?: Resolver<Maybe<IResolversTypes['FeatureUrls']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IAppUrlsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['AppUrls'] = IResolversParentTypes['AppUrls']> = ResolversObject<{
  android?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  ios?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IAssetAvailabilityResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['AssetAvailability'] = IResolversParentTypes['AssetAvailability']> = ResolversObject<{
  bikes?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  dropoff?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  ebikes?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IAssetLocationResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['AssetLocation'] = IResolversParentTypes['AssetLocation']> = ResolversObject<{
  countryCode?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  geoLocation?: Resolver<IResolversTypes['Coordinates'], ParentType, ContextType>;
  identifier?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  stations?: Resolver<Array<IResolversTypes['AssetStation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IAssetStationResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['AssetStation'] = IResolversParentTypes['AssetStation']> = ResolversObject<{
  availability?: Resolver<Maybe<IResolversTypes['AssetAvailability']>, ParentType, ContextType>;
  geoLocation?: Resolver<Maybe<IResolversTypes['Coordinates']>, ParentType, ContextType>;
  id?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IAssignReplacementManagerPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['AssignReplacementManagerPayload'] = IResolversParentTypes['AssignReplacementManagerPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  replacementManagerId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IAuthResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Auth'] = IResolversParentTypes['Auth']> = ResolversObject<{
  accessToken?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  refreshToken?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IAvailableFeatureInterfaceResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['AvailableFeatureInterface'] = IResolversParentTypes['AvailableFeatureInterface']> = ResolversObject<{
  __resolveType: TypeResolveFn<'Amber' | 'DonkeyRepublic' | 'EnecoDongle' | 'Greenwheels' | 'LeaseBike' | 'RentalCar' | 'RequestableDonkeyRepublic' | 'RequestableFeature' | 'SharedVehicle' | 'ShuttelCheck' | 'ShuttelMyWheels' | 'ShuttelSixt' | 'ShuttelTaxi' | 'ShuttelTranzerEticket' | 'ShuttelVecore' | 'TaxiFeature' | 'TranzerEticket' | 'YellowbrickSp', ParentType, ContextType>;
  activatable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  agreedTermsAndConditions?: Resolver<Maybe<Array<IResolversTypes['TermsAndConditions']>>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  id?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  mobideskOwnershipToken?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  motivation?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<IResolversTypes['FeatureOption'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['FeatureOrderStatus'], ParentType, ContextType>;
  urls?: Resolver<Maybe<IResolversTypes['FeatureUrls']>, ParentType, ContextType>;
}>;

export type IAvailableServiceInterfaceResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['AvailableServiceInterface'] = IResolversParentTypes['AvailableServiceInterface']> = ResolversObject<{
  __resolveType: TypeResolveFn<'ActiveAdditional' | 'ActiveGreenwheels' | 'ActiveSubscription' | 'ActiveYellowbrickSp' | 'RequestableService' | 'RequestableYellowbrickProduct', ParentType, ContextType>;
  budgetGroupAvailability?: Resolver<Maybe<IResolversTypes['AvailableServiceAvailabilityEnum']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['Description']>, ParentType, ContextType>;
  endsOn?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationServiceEndsOn']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<IResolversTypes['Icon']>, ParentType, ContextType>;
  identity?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationServiceIdentity']>, ParentType, ContextType>;
  kind?: Resolver<IResolversTypes['PersonalMeansOfRegistrationServiceKind'], ParentType, ContextType>;
  mobideskOwnershipToken?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  personalMeansOfRegistration?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistration']>, ParentType, ContextType>;
  recordId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  requiresMotivation?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationServiceStatus']>, ParentType, ContextType>;
  termsAndConditions?: Resolver<Maybe<IResolversTypes['TermsAndConditions']>, ParentType, ContextType>;
}>;

export type IAvailableSharedCarResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['AvailableSharedCar'] = IResolversParentTypes['AvailableSharedCar']> = ResolversObject<{
  endsAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  fromLocation?: Resolver<IResolversTypes['GeoLocation'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  startsAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  vehicleName?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IAvailableSharedVehicleResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['AvailableSharedVehicle'] = IResolversParentTypes['AvailableSharedVehicle']> = ResolversObject<{
  endsAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  fromLocation?: Resolver<IResolversTypes['GeoLocation'], ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  modality?: Resolver<IResolversTypes['Modality'], ParentType, ContextType>;
  startsAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  vehicleName?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IAvailableSubscriptionResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['AvailableSubscription'] = IResolversParentTypes['AvailableSubscription']> = ResolversObject<{
  hasTraject?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  humanizedZones?: Resolver<Maybe<Array<IResolversTypes['Zone']>>, ParentType, ContextType>;
  requiresMotivation?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  starValues?: Resolver<Array<IResolversTypes['StarValue']>, ParentType, ContextType>;
  trainClassOptions?: Resolver<Maybe<IResolversTypes['TrainClassOptions']>, ParentType, ContextType>;
  value?: Resolver<IResolversTypes['SubscriptionEnum'], ParentType, ContextType>;
  zones?: Resolver<Array<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IBalanceResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Balance'] = IResolversParentTypes['Balance']> = ResolversObject<{
  totalFiscalAmount?: Resolver<Maybe<IResolversTypes['Float']>, ParentType, ContextType>;
  totalGrossAmount?: Resolver<Maybe<IResolversTypes['Float']>, ParentType, ContextType>;
  totalNetAmount?: Resolver<Maybe<IResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface IBase64ScalarConfig extends GraphQLScalarTypeConfig<IResolversTypes['Base64'], any> {
  name: 'Base64';
}

export interface IBigDecimalScalarConfig extends GraphQLScalarTypeConfig<IResolversTypes['BigDecimal'], any> {
  name: 'BigDecimal';
}

export type IBikeAccessResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['BikeAccess'] = IResolversParentTypes['BikeAccess']> = ResolversObject<{
  tokenData?: Resolver<Maybe<IResolversTypes['EkeyLock']>, ParentType, ContextType>;
  tokenType?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  validFrom?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  validUntil?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IBrandingResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Branding'] = IResolversParentTypes['Branding']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IBudgetResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Budget'] = IResolversParentTypes['Budget']> = ResolversObject<{
  amount?: Resolver<IResolversTypes['MoneyTransfer'], ParentType, ContextType>;
  label?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
  valueInCents?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IBudgetGroupResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['BudgetGroup'] = IResolversParentTypes['BudgetGroup']> = ResolversObject<{
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  reimbursementRules?: Resolver<Array<IResolversTypes['ReimbursementRule']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICalculatedDistanceResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CalculatedDistance'] = IResolversParentTypes['CalculatedDistance']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICancelDonkeyRepublicReservationPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CancelDonkeyRepublicReservationPayload'] = IResolversParentTypes['CancelDonkeyRepublicReservationPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  donkeyRepublicReservation?: Resolver<Maybe<IResolversTypes['DonkeyRepublicReservation']>, ParentType, ContextType>;
  donkeyRepublicResult?: Resolver<Maybe<IResolversTypes['DonkeyRepublicResult']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['DonkeyRepublicResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICancelSharedCarReservationPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CancelSharedCarReservationPayload'] = IResolversParentTypes['CancelSharedCarReservationPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  sharedCarReservation?: Resolver<IResolversTypes['SharedCarReservation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICancelSharedVehicleReservationPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CancelSharedVehicleReservationPayload'] = IResolversParentTypes['CancelSharedVehicleReservationPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  sharedVehicleReservation?: Resolver<IResolversTypes['SharedVehicleReservation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICancelTaxiReservationPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CancelTaxiReservationPayload'] = IResolversParentTypes['CancelTaxiReservationPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  taxiReservation?: Resolver<IResolversTypes['TaxiReservation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICategoryResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Category'] = IResolversParentTypes['Category']> = ResolversObject<{
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  main?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  mainCategory?: Resolver<IResolversTypes['MainCategory'], ParentType, ContextType>;
  reimbursableCategoryMethod?: Resolver<Maybe<IResolversTypes['ReimbursableCategoryMethod']>, ParentType, ContextType>;
  requiresModality?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  sub?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  subCategory?: Resolver<Maybe<IResolversTypes['SubCategory']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICo2EmissionResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Co2Emission'] = IResolversParentTypes['Co2Emission']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICo2EmissionPerModalityResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Co2EmissionPerModality'] = IResolversParentTypes['Co2EmissionPerModality']> = ResolversObject<{
  emission?: Resolver<IResolversTypes['Co2Emission'], ParentType, ContextType>;
  modality?: Resolver<Maybe<IResolversTypes['Modality']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICo2EmissionSummaryResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Co2EmissionSummary'] = IResolversParentTypes['Co2EmissionSummary']> = ResolversObject<{
  mobilityEventsPerModality?: Resolver<Maybe<Array<IResolversTypes['Co2EmissionPerModality']>>, ParentType, ContextType>;
  totalEmission?: Resolver<IResolversTypes['Co2Emission'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IColorResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Color'] = IResolversParentTypes['Color']> = ResolversObject<{
  hex?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  hsla?: Resolver<Maybe<IResolversTypes['Hsla']>, ParentType, ContextType>;
  rgba?: Resolver<Maybe<IResolversTypes['Rgba']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICompensationRideToBeCreatedResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CompensationRideToBeCreated'] = IResolversParentTypes['CompensationRideToBeCreated']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  incompleteRideId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  message?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  path?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  refundId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  type?: Resolver<IResolversTypes['TaskEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IConfigurationResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Configuration'] = IResolversParentTypes['Configuration']> = ResolversObject<{
  grossLimit?: Resolver<Maybe<IResolversTypes['Float']>, ParentType, ContextType>;
  grossPercentage?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  limitFrom?: Resolver<Maybe<IResolversTypes['Float']>, ParentType, ContextType>;
  limitTo?: Resolver<Maybe<IResolversTypes['Float']>, ParentType, ContextType>;
  limitToDefaultCommute?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  netLimit?: Resolver<Maybe<IResolversTypes['Float']>, ParentType, ContextType>;
  netPercentage?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  offsets?: Resolver<Maybe<Array<Array<IResolversTypes['Float']>>>, ParentType, ContextType>;
  threshold?: Resolver<Maybe<IResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IConfigurationProfileForHostResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ConfigurationProfileForHost'] = IResolversParentTypes['ConfigurationProfileForHost']> = ResolversObject<{
  customerLabelTheme?: Resolver<Maybe<IResolversTypes['CustomerLabelTheme']>, ParentType, ContextType>;
  customerService?: Resolver<Maybe<IResolversTypes['CustomerService']>, ParentType, ContextType>;
  ssoUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  theme?: Resolver<Maybe<IResolversTypes['Theme']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IConfigureMfaPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ConfigureMfaPayload'] = IResolversParentTypes['ConfigureMfaPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  configurationSuccess?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  otpUri?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IConfirmGreenwheelsPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ConfirmGreenwheelsPayload'] = IResolversParentTypes['ConfirmGreenwheelsPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IConfirmMobilityEventPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ConfirmMobilityEventPayload'] = IResolversParentTypes['ConfirmMobilityEventPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  day?: Resolver<IResolversTypes['DayWithMobilityEvents'], ParentType, ContextType>;
  mobilityEvent?: Resolver<Maybe<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IConfirmMobilityEventsPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ConfirmMobilityEventsPayload'] = IResolversParentTypes['ConfirmMobilityEventsPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  confirmedMobilityEvents?: Resolver<Array<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  days?: Resolver<Array<IResolversTypes['DayWithMobilityEvents']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  unconfirmedMobilityEvents?: Resolver<Array<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IConfirmPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ConfirmPayload'] = IResolversParentTypes['ConfirmPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  mobilityCardOrder?: Resolver<Maybe<IResolversTypes['MobilityCardOrder']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IConfirmSharedCarReservationPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ConfirmSharedCarReservationPayload'] = IResolversParentTypes['ConfirmSharedCarReservationPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  sharedCarReservation?: Resolver<Maybe<IResolversTypes['SharedCarReservation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IConfirmSharedVehicleReservationPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ConfirmSharedVehicleReservationPayload'] = IResolversParentTypes['ConfirmSharedVehicleReservationPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  sharedVehicleReservation?: Resolver<Maybe<IResolversTypes['SharedVehicleReservation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IConfirmTaxiReservationPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ConfirmTaxiReservationPayload'] = IResolversParentTypes['ConfirmTaxiReservationPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  taxiReservation?: Resolver<IResolversTypes['TaxiReservation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IContractActionsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ContractActions'] = IResolversParentTypes['ContractActions']> = ResolversObject<{
  revocable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IContractStatusResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ContractStatus'] = IResolversParentTypes['ContractStatus']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['ContractStatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface ICoordinateScalarConfig extends GraphQLScalarTypeConfig<IResolversTypes['Coordinate'], any> {
  name: 'Coordinate';
}

export type ICoordinatesResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Coordinates'] = IResolversParentTypes['Coordinates']> = ResolversObject<{
  latitude?: Resolver<IResolversTypes['Coordinate'], ParentType, ContextType>;
  longitude?: Resolver<IResolversTypes['Coordinate'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICopyMobilityEventsForDayPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CopyMobilityEventsForDayPayload'] = IResolversParentTypes['CopyMobilityEventsForDayPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  days?: Resolver<Array<IResolversTypes['Day']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICopyMobilityEventsPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CopyMobilityEventsPayload'] = IResolversParentTypes['CopyMobilityEventsPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  days?: Resolver<Array<IResolversTypes['DayWithMobilityEvents']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICostResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Cost'] = IResolversParentTypes['Cost']> = ResolversObject<{
  budgetGroupId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  category?: Resolver<IResolversTypes['Category'], ParentType, ContextType>;
  configuration?: Resolver<IResolversTypes['Configuration'], ParentType, ContextType>;
  fiscality?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  main?: Resolver<IResolversTypes['MainCategory'], ParentType, ContextType>;
  modality?: Resolver<Maybe<IResolversTypes['Modality']>, ParentType, ContextType>;
  reimbursableCategory?: Resolver<IResolversTypes['ReimbursableCategory'], ParentType, ContextType>;
  sub?: Resolver<Maybe<IResolversTypes['SubCategory']>, ParentType, ContextType>;
  travelPurpose?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICountryIsoCodeResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CountryIsoCode'] = IResolversParentTypes['CountryIsoCode']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['CountryIsoCodeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICreateCommuteCompensationPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CreateCommuteCompensationPayload'] = IResolversParentTypes['CreateCommuteCompensationPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  confirmedMobilityEvents?: Resolver<Maybe<Array<IResolversTypes['MobilityEvent']>>, ParentType, ContextType>;
  dayWithMobilityEvents?: Resolver<IResolversTypes['DayWithMobilityEvents'], ParentType, ContextType>;
  daysWithMobilityEvents?: Resolver<Array<IResolversTypes['DayWithMobilityEvents']>, ParentType, ContextType>;
  mobilityEvents?: Resolver<Array<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  unconfirmedMobilityEvents?: Resolver<Maybe<Array<IResolversTypes['MobilityEvent']>>, ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICreateCommuteRidesPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CreateCommuteRidesPayload'] = IResolversParentTypes['CreateCommuteRidesPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  confirmedMobilityEvents?: Resolver<Maybe<Array<IResolversTypes['MobilityEvent']>>, ParentType, ContextType>;
  dayWithMobilityEvents?: Resolver<IResolversTypes['DayWithMobilityEvents'], ParentType, ContextType>;
  daysWithMobilityEvents?: Resolver<Array<IResolversTypes['DayWithMobilityEvents']>, ParentType, ContextType>;
  favoriteWithFallback?: Resolver<Maybe<IResolversTypes['Favorite']>, ParentType, ContextType>;
  mobilityEvents?: Resolver<Array<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  unconfirmedMobilityEvents?: Resolver<Maybe<Array<IResolversTypes['MobilityEvent']>>, ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICreateCompensationMobilityEventPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CreateCompensationMobilityEventPayload'] = IResolversParentTypes['CreateCompensationMobilityEventPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  mobilityEvent?: Resolver<Maybe<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICreateDirectDebitMandatePayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CreateDirectDebitMandatePayload'] = IResolversParentTypes['CreateDirectDebitMandatePayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  directDebitMandate?: Resolver<Maybe<IResolversTypes['DirectDebitMandate']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICreateDonkeyRepublicReservationPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CreateDonkeyRepublicReservationPayload'] = IResolversParentTypes['CreateDonkeyRepublicReservationPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  donkeyRepublicReservation?: Resolver<Maybe<IResolversTypes['DonkeyRepublicReservation']>, ParentType, ContextType>;
  donkeyRepublicResult?: Resolver<Maybe<IResolversTypes['DonkeyRepublicResult']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['DonkeyRepublicResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICreateFavoritePayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CreateFavoritePayload'] = IResolversParentTypes['CreateFavoritePayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  favorite?: Resolver<Maybe<IResolversTypes['Favorite']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICreateLeaseBikeOrderPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CreateLeaseBikeOrderPayload'] = IResolversParentTypes['CreateLeaseBikeOrderPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICreateMileagePayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CreateMileagePayload'] = IResolversParentTypes['CreateMileagePayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  mileage?: Resolver<Maybe<IResolversTypes['Mileage']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICreateMobilityCardOrderPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CreateMobilityCardOrderPayload'] = IResolversParentTypes['CreateMobilityCardOrderPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  mobilityCardOrder?: Resolver<Maybe<IResolversTypes['MobilityCardOrder']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICreateMobilityEventPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CreateMobilityEventPayload'] = IResolversParentTypes['CreateMobilityEventPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  confirmedMobilityEvents?: Resolver<Maybe<Array<IResolversTypes['MobilityEvent']>>, ParentType, ContextType>;
  mobilityEvent?: Resolver<Maybe<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  mobilityEvents?: Resolver<Maybe<Array<IResolversTypes['MobilityEvent']>>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  unconfirmedMobilityEvents?: Resolver<Maybe<Array<IResolversTypes['MobilityEvent']>>, ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICreateParkingOrderPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CreateParkingOrderPayload'] = IResolversParentTypes['CreateParkingOrderPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  parkingOrder?: Resolver<Maybe<IResolversTypes['ParkingOrder']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICreateTaxiReservationPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CreateTaxiReservationPayload'] = IResolversParentTypes['CreateTaxiReservationPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  taxiReservation?: Resolver<Maybe<IResolversTypes['TaxiReservation']>, ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICreateTranzerReservationPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CreateTranzerReservationPayload'] = IResolversParentTypes['CreateTranzerReservationPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  tranzerReservation?: Resolver<Maybe<IResolversTypes['TranzerReservation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICreateUserProfilePayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CreateUserProfilePayload'] = IResolversParentTypes['CreateUserProfilePayload']> = ResolversObject<{
  apiToken?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  jwt?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  me?: Resolver<Maybe<IResolversTypes['Employee']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICreateVehiclePayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CreateVehiclePayload'] = IResolversParentTypes['CreateVehiclePayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  vehicle?: Resolver<Maybe<IResolversTypes['MeansOfTransport']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICreateWorkFromHomePayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CreateWorkFromHomePayload'] = IResolversParentTypes['CreateWorkFromHomePayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  confirmedMobilityEvents?: Resolver<Maybe<Array<IResolversTypes['MobilityEvent']>>, ParentType, ContextType>;
  dayWithMobilityEvents?: Resolver<IResolversTypes['DayWithMobilityEvents'], ParentType, ContextType>;
  daysWithMobilityEvents?: Resolver<Array<IResolversTypes['DayWithMobilityEvents']>, ParentType, ContextType>;
  mobilityEvents?: Resolver<Array<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  unconfirmedMobilityEvents?: Resolver<Maybe<Array<IResolversTypes['MobilityEvent']>>, ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICustomerLabelThemeResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CustomerLabelTheme'] = IResolversParentTypes['CustomerLabelTheme']> = ResolversObject<{
  favicon?: Resolver<Maybe<IResolversTypes['Image']>, ParentType, ContextType, Partial<ICustomerLabelThemeFaviconArgs>>;
  icon?: Resolver<Maybe<IResolversTypes['Image']>, ParentType, ContextType, Partial<ICustomerLabelThemeIconArgs>>;
  logo?: Resolver<Maybe<IResolversTypes['Image']>, ParentType, ContextType, Partial<ICustomerLabelThemeLogoArgs>>;
  logoDark?: Resolver<Maybe<IResolversTypes['Image']>, ParentType, ContextType, Partial<ICustomerLabelThemeLogoDarkArgs>>;
  poster?: Resolver<Maybe<IResolversTypes['Image']>, ParentType, ContextType, Partial<ICustomerLabelThemePosterArgs>>;
  primaryColor?: Resolver<Maybe<IResolversTypes['Color']>, ParentType, ContextType>;
  secondaryColor?: Resolver<Maybe<IResolversTypes['Color']>, ParentType, ContextType>;
  sidebarColor?: Resolver<Maybe<IResolversTypes['Color']>, ParentType, ContextType>;
  sidebarTextColor?: Resolver<Maybe<IResolversTypes['Color']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ICustomerServiceResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['CustomerService'] = IResolversParentTypes['CustomerService']> = ResolversObject<{
  emailAddress?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  helpUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  helpUrlName?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  privacyPolicyUrl?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  telephone?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface IDateScalarConfig extends GraphQLScalarTypeConfig<IResolversTypes['Date'], any> {
  name: 'Date';
}

export interface IDateTimeScalarConfig extends GraphQLScalarTypeConfig<IResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type IDayResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Day'] = IResolversParentTypes['Day']> = ResolversObject<{
  __resolveType: TypeResolveFn<'DayWithMobilityEvents' | 'DayWithoutEmployment', ParentType, ContextType>;
}>;

export type IDayWithMobilityEventsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['DayWithMobilityEvents'] = IResolversParentTypes['DayWithMobilityEvents']> = ResolversObject<{
  commuteCompensationAddable?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  commuteRideAddable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  commuteRidesAddable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  date?: Resolver<IResolversTypes['Date'], ParentType, ContextType>;
  eventsAddable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  eventsConfirmable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  eventsSubmittable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  mobilityEvents?: Resolver<Array<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  permissions?: Resolver<IResolversTypes['Permissions'], ParentType, ContextType>;
  status?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  workFromHomeCompensationAddable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IDayWithoutEmploymentResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['DayWithoutEmployment'] = IResolversParentTypes['DayWithoutEmployment']> = ResolversObject<{
  date?: Resolver<IResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IDescriptionResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Description'] = IResolversParentTypes['Description']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IDestroyFavoritePayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['DestroyFavoritePayload'] = IResolversParentTypes['DestroyFavoritePayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IDestroyMobilityEventPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['DestroyMobilityEventPayload'] = IResolversParentTypes['DestroyMobilityEventPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  day?: Resolver<IResolversTypes['Day'], ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IDestroyMobilityEventsPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['DestroyMobilityEventsPayload'] = IResolversParentTypes['DestroyMobilityEventsPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  days?: Resolver<Array<IResolversTypes['DayWithMobilityEvents']>, ParentType, ContextType>;
  destroyedMobilityEventIds?: Resolver<Array<IResolversTypes['ID']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  undestroyedMobilityEvents?: Resolver<Array<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IDestroyProfileAddressPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['DestroyProfileAddressPayload'] = IResolversParentTypes['DestroyProfileAddressPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IDirectDebitInvoiceResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['DirectDebitInvoice'] = IResolversParentTypes['DirectDebitInvoice']> = ResolversObject<{
  amount?: Resolver<Maybe<IResolversTypes['Money']>, ParentType, ContextType>;
  externalReference?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  invoiceDate?: Resolver<IResolversTypes['Date'], ParentType, ContextType>;
  pdfUrl?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  settlement?: Resolver<Maybe<IResolversTypes['Settlement']>, ParentType, ContextType>;
  status?: Resolver<Maybe<IResolversTypes['InvoiceStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IDirectDebitMandateResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['DirectDebitMandate'] = IResolversParentTypes['DirectDebitMandate']> = ResolversObject<{
  acceptanceUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  acceptedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  actions?: Resolver<IResolversTypes['MandateActions'], ParentType, ContextType>;
  ecurringCustomerToken?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  ecurringSubscriptionToken?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  isAccepted?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  isCustomerRegistered?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  isRevoked?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  isValid?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  revokedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<Maybe<IResolversTypes['MandateStatus']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IDirectDebitMandatesResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['DirectDebitMandates'] = IResolversParentTypes['DirectDebitMandates']> = ResolversObject<{
  create?: Resolver<Maybe<IResolversTypes['CreateDirectDebitMandatePayload']>, ParentType, ContextType, RequireFields<IDirectDebitMandatesCreateArgs, 'input'>>;
  revoke?: Resolver<Maybe<IResolversTypes['RevokeDirectDebitMandatePayload']>, ParentType, ContextType, RequireFields<IDirectDebitMandatesRevokeArgs, 'input'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IDisplayResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Display'] = IResolversParentTypes['Display']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['DisplayTypeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IDistanceResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Distance'] = IResolversParentTypes['Distance']> = ResolversObject<{
  budgetGroupId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  category?: Resolver<IResolversTypes['Category'], ParentType, ContextType>;
  configuration?: Resolver<IResolversTypes['Configuration'], ParentType, ContextType>;
  fiscality?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  main?: Resolver<IResolversTypes['MainCategory'], ParentType, ContextType>;
  modality?: Resolver<Maybe<IResolversTypes['Modality']>, ParentType, ContextType>;
  reimbursableCategory?: Resolver<IResolversTypes['ReimbursableCategory'], ParentType, ContextType>;
  sub?: Resolver<Maybe<IResolversTypes['SubCategory']>, ParentType, ContextType>;
  travelPurpose?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IDistancePerModalityResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['DistancePerModality'] = IResolversParentTypes['DistancePerModality']> = ResolversObject<{
  distance?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  modality?: Resolver<Maybe<IResolversTypes['Modality']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IDistanceSummaryResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['DistanceSummary'] = IResolversParentTypes['DistanceSummary']> = ResolversObject<{
  mobilityEventsPerModality?: Resolver<Maybe<Array<IResolversTypes['DistancePerModality']>>, ParentType, ContextType>;
  totalDistance?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IDivertedResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Diverted'] = IResolversParentTypes['Diverted']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IDonkeyLegResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['DonkeyLeg'] = IResolversParentTypes['DonkeyLeg']> = ResolversObject<{
  bookingId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  from?: Resolver<IResolversTypes['AssetStation'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  mode?: Resolver<Maybe<IResolversTypes['DonkeyRepublicModality']>, ParentType, ContextType>;
  price?: Resolver<Array<IResolversTypes['SegmentPrice']>, ParentType, ContextType>;
  quoteId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  service?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IDonkeyQuoteResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['DonkeyQuote'] = IResolversParentTypes['DonkeyQuote']> = ResolversObject<{
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  legs?: Resolver<Array<IResolversTypes['DonkeyLeg']>, ParentType, ContextType>;
  validUntil?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IDonkeyRepublicResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['DonkeyRepublic'] = IResolversParentTypes['DonkeyRepublic']> = ResolversObject<{
  activatable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  agreedTermsAndConditions?: Resolver<Maybe<Array<IResolversTypes['TermsAndConditions']>>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  id?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  mobideskOwnershipToken?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  motivation?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<IResolversTypes['FeatureOption'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['FeatureOrderStatus'], ParentType, ContextType>;
  urls?: Resolver<Maybe<IResolversTypes['FeatureUrls']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IDonkeyRepublicBookingResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['DonkeyRepublicBooking'] = IResolversParentTypes['DonkeyRepublicBooking']> = ResolversObject<{
  cancel?: Resolver<Maybe<IResolversTypes['CancelDonkeyRepublicReservationPayload']>, ParentType, ContextType, RequireFields<IDonkeyRepublicBookingCancelArgs, 'input'>>;
  create?: Resolver<Maybe<IResolversTypes['CreateDonkeyRepublicReservationPayload']>, ParentType, ContextType, RequireFields<IDonkeyRepublicBookingCreateArgs, 'input'>>;
  finish?: Resolver<Maybe<IResolversTypes['FinishDonkeyRepublicReservationPayload']>, ParentType, ContextType, RequireFields<IDonkeyRepublicBookingFinishArgs, 'input'>>;
  lock?: Resolver<Maybe<IResolversTypes['LockDonkeyRepublicAssetPayload']>, ParentType, ContextType, RequireFields<IDonkeyRepublicBookingLockArgs, 'input'>>;
  start?: Resolver<Maybe<IResolversTypes['StartDonkeyRepublicReservationPayload']>, ParentType, ContextType, RequireFields<IDonkeyRepublicBookingStartArgs, 'input'>>;
  unlock?: Resolver<Maybe<IResolversTypes['UnlockDonkeyRepublicAssetPayload']>, ParentType, ContextType, RequireFields<IDonkeyRepublicBookingUnlockArgs, 'input'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IDonkeyRepublicModalityResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['DonkeyRepublicModality'] = IResolversParentTypes['DonkeyRepublicModality']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['DonkeyRepublicModalityEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IDonkeyRepublicReservationResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['DonkeyRepublicReservation'] = IResolversParentTypes['DonkeyRepublicReservation']> = ResolversObject<{
  accessData?: Resolver<Maybe<IResolversTypes['BikeAccess']>, ParentType, ContextType>;
  assetId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  assetName?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  externalId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  fullPriceExVatInCents?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  legs?: Resolver<Array<IResolversTypes['DonkeyLeg']>, ParentType, ContextType>;
  status?: Resolver<IResolversTypes['DonkeyRepublicStatus'], ParentType, ContextType>;
  statusReason?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  supplierReference?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IDonkeyRepublicResultResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['DonkeyRepublicResult'] = IResolversParentTypes['DonkeyRepublicResult']> = ResolversObject<{
  donkeyRepublicError?: Resolver<Maybe<IResolversTypes['Error']>, ParentType, ContextType>;
  error?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IDonkeyRepublicStatusResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['DonkeyRepublicStatus'] = IResolversParentTypes['DonkeyRepublicStatus']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['DonkeyRepublicStatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IDriverResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Driver'] = IResolversParentTypes['Driver']> = ResolversObject<{
  name?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IDriversLicenseCountryResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['DriversLicenseCountry'] = IResolversParentTypes['DriversLicenseCountry']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['DriversLicenseCountryEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IDurationResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Duration'] = IResolversParentTypes['Duration']> = ResolversObject<{
  editable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  endsAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  startsAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IEkeyResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Ekey'] = IResolversParentTypes['Ekey']> = ResolversObject<{
  key?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  passkey?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IEkeyLockResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['EkeyLock'] = IResolversParentTypes['EkeyLock']> = ResolversObject<{
  ekey?: Resolver<Maybe<IResolversTypes['Ekey']>, ParentType, ContextType>;
  lock?: Resolver<Maybe<IResolversTypes['Lock']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IEmployeeResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Employee'] = IResolversParentTypes['Employee']> = ResolversObject<{
  addresses?: Resolver<Maybe<Array<IResolversTypes['Address']>>, ParentType, ContextType>;
  approvableMobilityEventsAggregate?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  availableFeature?: Resolver<Maybe<IResolversTypes['AvailableFeatureInterface']>, ParentType, ContextType, RequireFields<IEmployeeAvailableFeatureArgs, 'id'>>;
  availableFeatures?: Resolver<Maybe<Array<IResolversTypes['AvailableFeatureInterface']>>, ParentType, ContextType, Partial<IEmployeeAvailableFeaturesArgs>>;
  availableService?: Resolver<Maybe<IResolversTypes['AvailableServiceInterface']>, ParentType, ContextType, RequireFields<IEmployeeAvailableServiceArgs, 'id'>>;
  availableServices?: Resolver<Maybe<Array<IResolversTypes['AvailableServiceInterface']>>, ParentType, ContextType, Partial<IEmployeeAvailableServicesArgs>>;
  availableSharedCars?: Resolver<Maybe<Array<IResolversTypes['AvailableSharedCar']>>, ParentType, ContextType, RequireFields<IEmployeeAvailableSharedCarsArgs, 'endsAt' | 'startsAt'>>;
  availableSharedVehicles?: Resolver<Maybe<Array<IResolversTypes['AvailableSharedVehicle']>>, ParentType, ContextType, RequireFields<IEmployeeAvailableSharedVehiclesArgs, 'endsAt' | 'startsAt'>>;
  availableSubscriptions?: Resolver<Maybe<Array<IResolversTypes['AvailableSubscription']>>, ParentType, ContextType>;
  becameEmployee?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  branding?: Resolver<Maybe<IResolversTypes['Branding']>, ParentType, ContextType>;
  co2EmissionSummary?: Resolver<Maybe<IResolversTypes['Co2EmissionSummary']>, ParentType, ContextType, Partial<IEmployeeCo2EmissionSummaryArgs>>;
  copyableMobilityEvents?: Resolver<Array<IResolversTypes['MobilityEvent']>, ParentType, ContextType, RequireFields<IEmployeeCopyableMobilityEventsArgs, 'forDate'>>;
  day?: Resolver<Maybe<IResolversTypes['Day']>, ParentType, ContextType, RequireFields<IEmployeeDayArgs, 'date'>>;
  days?: Resolver<Array<IResolversTypes['Day']>, ParentType, ContextType, RequireFields<IEmployeeDaysArgs, 'from' | 'to'>>;
  defaultTravelPurpose?: Resolver<Maybe<IResolversTypes['TravelPurposeEnum']>, ParentType, ContextType, Partial<IEmployeeDefaultTravelPurposeArgs>>;
  directDebitInvoices?: Resolver<Array<IResolversTypes['DirectDebitInvoice']>, ParentType, ContextType>;
  directDebitMandates?: Resolver<Array<IResolversTypes['DirectDebitMandate']>, ParentType, ContextType, Partial<IEmployeeDirectDebitMandatesArgs>>;
  distanceSummary?: Resolver<Maybe<IResolversTypes['DistanceSummary']>, ParentType, ContextType, Partial<IEmployeeDistanceSummaryArgs>>;
  donkeyAssetsAvailability?: Resolver<Maybe<Array<IResolversTypes['AssetLocation']>>, ParentType, ContextType>;
  donkeyQuotes?: Resolver<Maybe<Array<IResolversTypes['DonkeyQuote']>>, ParentType, ContextType, RequireFields<IEmployeeDonkeyQuotesArgs, 'cityIdentifier' | 'stationId'>>;
  donkeyRepublicReservation?: Resolver<Maybe<IResolversTypes['DonkeyRepublicReservation']>, ParentType, ContextType, RequireFields<IEmployeeDonkeyRepublicReservationArgs, 'id'>>;
  donkeyRepublicReservations?: Resolver<Maybe<Array<IResolversTypes['DonkeyRepublicReservation']>>, ParentType, ContextType>;
  eMandateRequired?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  employeeFinancialConsequences?: Resolver<IResolversTypes['EmployeeFinancialConsequences'], ParentType, ContextType>;
  employments?: Resolver<Maybe<Array<IResolversTypes['Employment']>>, ParentType, ContextType, Partial<IEmployeeEmploymentsArgs>>;
  exportFiles?: Resolver<Maybe<IResolversTypes['ExportFiles']>, ParentType, ContextType, Partial<IEmployeeExportFilesArgs>>;
  exportUrls?: Resolver<IResolversTypes['ExportUrls'], ParentType, ContextType>;
  favorites?: Resolver<Maybe<Array<IResolversTypes['Favorite']>>, ParentType, ContextType, Partial<IEmployeeFavoritesArgs>>;
  featureOptions?: Resolver<Array<IResolversTypes['FeatureOption']>, ParentType, ContextType, Partial<IEmployeeFeatureOptionsArgs>>;
  filterOptions?: Resolver<Maybe<IResolversTypes['FilterOptions']>, ParentType, ContextType>;
  financialConsequences?: Resolver<IResolversTypes['EmployeeFinancialConsequences'], ParentType, ContextType>;
  fixedCommuteDistance?: Resolver<Maybe<IResolversTypes['Float']>, ParentType, ContextType>;
  greenwheelsFleets?: Resolver<Maybe<Array<IResolversTypes['GreenwheelsFleet']>>, ParentType, ContextType>;
  hasAgreedToGeneralTerms?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  hasAgreedToPrivacyStatement?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  hasNotAgreedToUpdatedGeneralTerms?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  hasNotAgreedToUpdatedPrivacyStatement?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  hasSso?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  holidayOnDate?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType, RequireFields<IEmployeeHolidayOnDateArgs, 'date'>>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  invoices?: Resolver<Array<IResolversTypes['DirectDebitInvoice']>, ParentType, ContextType>;
  journey?: Resolver<Maybe<IResolversTypes['Journey']>, ParentType, ContextType, RequireFields<IEmployeeJourneyArgs, 'journeyId'>>;
  journeys?: Resolver<Maybe<Array<IResolversTypes['Journey']>>, ParentType, ContextType, RequireFields<IEmployeeJourneysArgs, 'destination' | 'origin'>>;
  leaseBikeUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  licensePlate?: Resolver<Maybe<IResolversTypes['LicensePlate']>, ParentType, ContextType, RequireFields<IEmployeeLicensePlateArgs, 'licensePlate'>>;
  licensePlates?: Resolver<Maybe<Array<IResolversTypes['LicensePlate']>>, ParentType, ContextType, Partial<IEmployeeLicensePlatesArgs>>;
  mergedMobilityEventsPreview?: Resolver<IResolversTypes['MergedMobilityEventsPreview'], ParentType, ContextType, RequireFields<IEmployeeMergedMobilityEventsPreviewArgs, 'mobilityEventIds'>>;
  messages?: Resolver<Array<IResolversTypes['Message']>, ParentType, ContextType, Partial<IEmployeeMessagesArgs>>;
  mileage?: Resolver<Maybe<IResolversTypes['Mileage']>, ParentType, ContextType, RequireFields<IEmployeeMileageArgs, 'id'>>;
  mileagesYears?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  mobidotUserToken?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  mobilityCardOrder?: Resolver<Maybe<IResolversTypes['MobilityCardOrder']>, ParentType, ContextType, RequireFields<IEmployeeMobilityCardOrderArgs, 'id'>>;
  mobilityCardOrders?: Resolver<Array<IResolversTypes['MobilityCardOrder']>, ParentType, ContextType>;
  mobilityEvent?: Resolver<Maybe<IResolversTypes['MobilityEvent']>, ParentType, ContextType, Partial<IEmployeeMobilityEventArgs>>;
  mobilityEventFormValidations?: Resolver<Maybe<IResolversTypes['MobilityEventFormValidations']>, ParentType, ContextType, Partial<IEmployeeMobilityEventFormValidationsArgs>>;
  mobilityEventTotals?: Resolver<IResolversTypes['MobilityEventTotals'], ParentType, ContextType, Partial<IEmployeeMobilityEventTotalsArgs>>;
  mobilityEvents?: Resolver<Array<IResolversTypes['MobilityEvent']>, ParentType, ContextType, Partial<IEmployeeMobilityEventsArgs>>;
  mobilityEventsAggregate?: Resolver<Maybe<IResolversTypes['MobilityEventAggregate']>, ParentType, ContextType, Partial<IEmployeeMobilityEventsAggregateArgs>>;
  mobilityEventsByDate?: Resolver<Array<IResolversTypes['MobilityEventByDate']>, ParentType, ContextType, Partial<IEmployeeMobilityEventsByDateArgs>>;
  multiModalAvailability?: Resolver<Maybe<Array<IResolversTypes['MultiModalAvailability']>>, ParentType, ContextType, RequireFields<IEmployeeMultiModalAvailabilityArgs, 'lat' | 'long' | 'radius'>>;
  organization?: Resolver<Maybe<IResolversTypes['Organization']>, ParentType, ContextType, Partial<IEmployeeOrganizationArgs>>;
  organizations?: Resolver<Maybe<Array<IResolversTypes['Organization']>>, ParentType, ContextType, RequireFields<IEmployeeOrganizationsArgs, 'from' | 'to'>>;
  parkingOrders?: Resolver<Array<IResolversTypes['ParkingOrder']>, ParentType, ContextType>;
  permissions?: Resolver<IResolversTypes['Permissions'], ParentType, ContextType, Partial<IEmployeePermissionsArgs>>;
  personalMeansOfRegistration?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistration']>, ParentType, ContextType, RequireFields<IEmployeePersonalMeansOfRegistrationArgs, 'id'>>;
  personalMeansOfRegistrations?: Resolver<Maybe<Array<IResolversTypes['PersonalMeansOfRegistration']>>, ParentType, ContextType, Partial<IEmployeePersonalMeansOfRegistrationsArgs>>;
  personalVehicle?: Resolver<Maybe<IResolversTypes['MeansOfTransport']>, ParentType, ContextType, RequireFields<IEmployeePersonalVehicleArgs, 'id'>>;
  personalVehicles?: Resolver<Array<IResolversTypes['MeansOfTransport']>, ParentType, ContextType, Partial<IEmployeePersonalVehiclesArgs>>;
  phoneNumber?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  possibleReplacementManagers?: Resolver<Maybe<Array<IResolversTypes['PossibleReplacementManager']>>, ParentType, ContextType>;
  profile?: Resolver<IResolversTypes['Profile'], ParentType, ContextType>;
  profileCountries?: Resolver<Array<IResolversTypes['CountryIsoCode']>, ParentType, ContextType>;
  qrCodeForApiToken?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  refundableIncompleteRides?: Resolver<Maybe<Array<IResolversTypes['MobilityEvent']>>, ParentType, ContextType>;
  replacementAssignments?: Resolver<Maybe<Array<IResolversTypes['ReplacementManager']>>, ParentType, ContextType>;
  replacementManager?: Resolver<Maybe<IResolversTypes['ReplacementManager']>, ParentType, ContextType, RequireFields<IEmployeeReplacementManagerArgs, 'id'>>;
  replacementManagers?: Resolver<Maybe<Array<IResolversTypes['ReplacementManager']>>, ParentType, ContextType>;
  requestableFeatureOptions?: Resolver<Maybe<Array<IResolversTypes['RequestableFeatureOption']>>, ParentType, ContextType>;
  requiredFields?: Resolver<IResolversTypes['RequiredFields'], ParentType, ContextType, Partial<IEmployeeRequiredFieldsArgs>>;
  reservations?: Resolver<Maybe<Array<IResolversTypes['Reservation']>>, ParentType, ContextType, Partial<IEmployeeReservationsArgs>>;
  routeMetadata?: Resolver<IResolversTypes['RouteMetadata'], ParentType, ContextType, Partial<IEmployeeRouteMetadataArgs>>;
  sharedCarReservation?: Resolver<Maybe<IResolversTypes['SharedCarReservation']>, ParentType, ContextType, RequireFields<IEmployeeSharedCarReservationArgs, 'id'>>;
  sharedCarReservations?: Resolver<Maybe<Array<IResolversTypes['SharedCarReservation']>>, ParentType, ContextType, Partial<IEmployeeSharedCarReservationsArgs>>;
  sharedVehicleReservation?: Resolver<Maybe<IResolversTypes['SharedVehicleReservation']>, ParentType, ContextType, RequireFields<IEmployeeSharedVehicleReservationArgs, 'id'>>;
  sharedVehicleReservations?: Resolver<Maybe<Array<IResolversTypes['SharedVehicleReservation']>>, ParentType, ContextType, Partial<IEmployeeSharedVehicleReservationsArgs>>;
  splitBillingContract?: Resolver<Maybe<IResolversTypes['SplitBillingContract']>, ParentType, ContextType, RequireFields<IEmployeeSplitBillingContractArgs, 'contractId'>>;
  splitBillingContracts?: Resolver<Maybe<Array<IResolversTypes['SplitBillingContract']>>, ParentType, ContextType, Partial<IEmployeeSplitBillingContractsArgs>>;
  splitBillingInvoices?: Resolver<Array<IResolversTypes['SplitBillingInvoice']>, ParentType, ContextType>;
  statistics?: Resolver<IResolversTypes['Statistics'], ParentType, ContextType>;
  subordinate?: Resolver<Maybe<IResolversTypes['Subordinate']>, ParentType, ContextType, RequireFields<IEmployeeSubordinateArgs, 'id'>>;
  subordinates?: Resolver<Maybe<Array<IResolversTypes['Subordinate']>>, ParentType, ContextType>;
  tasks?: Resolver<Array<IResolversTypes['Task']>, ParentType, ContextType>;
  taxiReservation?: Resolver<Maybe<IResolversTypes['TaxiReservation']>, ParentType, ContextType, RequireFields<IEmployeeTaxiReservationArgs, 'id'>>;
  taxiReservations?: Resolver<Array<IResolversTypes['TaxiReservation']>, ParentType, ContextType>;
  termsAndConditions?: Resolver<Maybe<Array<IResolversTypes['TermsAndConditions']>>, ParentType, ContextType, Partial<IEmployeeTermsAndConditionsArgs>>;
  token?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  tranzerQuotes?: Resolver<Maybe<Array<IResolversTypes['Quote']>>, ParentType, ContextType, RequireFields<IEmployeeTranzerQuotesArgs, 'fromLocationName' | 'toLocationName'>>;
  tranzerReservation?: Resolver<Maybe<IResolversTypes['TranzerReservation']>, ParentType, ContextType, RequireFields<IEmployeeTranzerReservationArgs, 'id'>>;
  tranzerReservations?: Resolver<Maybe<Array<IResolversTypes['TranzerReservation']>>, ParentType, ContextType>;
  vehicleOptions?: Resolver<Maybe<Array<IResolversTypes['VehicleOptions']>>, ParentType, ContextType>;
  whatOptions?: Resolver<Array<IResolversTypes['WhatOption']>, ParentType, ContextType, Partial<IEmployeeWhatOptionsArgs>>;
  yellowbrickServices?: Resolver<Maybe<Array<IResolversTypes['YellowbrickService']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IEmployeeAddressResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['EmployeeAddress'] = IResolversParentTypes['EmployeeAddress']> = ResolversObject<{
  city?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  countryIsoCode?: Resolver<Maybe<IResolversTypes['CountryIsoCodeEnum']>, ParentType, ContextType>;
  employeeCountryIsoCode?: Resolver<Maybe<IResolversTypes['CountryIsoCode']>, ParentType, ContextType>;
  street?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  streetNumber?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  streetNumberSuffix?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  zipcode?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IEmployeeDetailsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['EmployeeDetails'] = IResolversParentTypes['EmployeeDetails']> = ResolversObject<{
  email?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  employeeUuid?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  infix?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  leaseBicycleCategory?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  organizationUuid?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IEmployeeDetailsResponseResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['EmployeeDetailsResponse'] = IResolversParentTypes['EmployeeDetailsResponse']> = ResolversObject<{
  details?: Resolver<Maybe<IResolversTypes['EmployeeDetails']>, ParentType, ContextType>;
  error?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IEmployeeFinancialConsequencesResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['EmployeeFinancialConsequences'] = IResolversParentTypes['EmployeeFinancialConsequences']> = ResolversObject<{
  budgetTransactionRemainder?: Resolver<IResolversTypes['Budget'], ParentType, ContextType>;
  cumulativeGrossAmount?: Resolver<Maybe<IResolversTypes['Float']>, ParentType, ContextType>;
  cumulativeNetAmount?: Resolver<Maybe<IResolversTypes['Float']>, ParentType, ContextType>;
  grossAmount?: Resolver<Maybe<IResolversTypes['Budget']>, ParentType, ContextType>;
  lastIntermediateSettlement?: Resolver<Maybe<IResolversTypes['Settlement']>, ParentType, ContextType>;
  netAmount?: Resolver<Maybe<IResolversTypes['Budget']>, ParentType, ContextType>;
  settlement?: Resolver<Maybe<IResolversTypes['Settlement']>, ParentType, ContextType, RequireFields<IEmployeeFinancialConsequencesSettlementArgs, 'id'>>;
  settlements?: Resolver<Maybe<Array<IResolversTypes['Settlement']>>, ParentType, ContextType>;
  tentativeGrossAmount?: Resolver<IResolversTypes['Budget'], ParentType, ContextType>;
  tentativeNetAmount?: Resolver<IResolversTypes['Budget'], ParentType, ContextType>;
  tentativeSettlement?: Resolver<IResolversTypes['Settlement'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IEmploymentResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Employment'] = IResolversParentTypes['Employment']> = ResolversObject<{
  budgetGroupName?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  commuteMonthlyKmBudget?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  commuteMonthlyMoneyBudget?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  costCenter?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  department?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  endsOn?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  main?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  startsOn?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IEnecoDongleResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['EnecoDongle'] = IResolversParentTypes['EnecoDongle']> = ResolversObject<{
  activatable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  agreedTermsAndConditions?: Resolver<Maybe<Array<IResolversTypes['TermsAndConditions']>>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  id?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  mobideskOwnershipToken?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  motivation?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<IResolversTypes['FeatureOption'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['FeatureOrderStatus'], ParentType, ContextType>;
  urls?: Resolver<Maybe<IResolversTypes['FeatureUrls']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IErrorResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Error'] = IResolversParentTypes['Error']> = ResolversObject<{
  details?: Resolver<Maybe<Array<IResolversTypes['Error']>>, ParentType, ContextType>;
  message?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  messageCode?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IExplanationResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Explanation'] = IResolversParentTypes['Explanation']> = ResolversObject<{
  allowed?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  humanized?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  required?: Resolver<IResolversTypes['ExplanationRequired'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IExplanationRequiredResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ExplanationRequired'] = IResolversParentTypes['ExplanationRequired']> = ResolversObject<{
  business?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  businessByAmount?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  businessByDistance?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  commute?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  commuteByAmount?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  commuteByDistance?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  personal?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  personalByAmount?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  personalByDistance?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IExportFilesResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ExportFiles'] = IResolversParentTypes['ExportFiles']> = ResolversObject<{
  mobilityEventsExport?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  ridesExport?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  transactionsExport?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IExportUrlsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ExportUrls'] = IResolversParentTypes['ExportUrls']> = ResolversObject<{
  mobilityEventsExportUrl?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IExternalUrlResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ExternalUrl'] = IResolversParentTypes['ExternalUrl']> = ResolversObject<{
  title?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IExternalUrlsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ExternalUrls'] = IResolversParentTypes['ExternalUrls']> = ResolversObject<{
  appStoreUrls?: Resolver<IResolversTypes['AppUrls'], ParentType, ContextType>;
  englishPrivacyStatementUrl?: Resolver<IResolversTypes['ExternalUrl'], ParentType, ContextType>;
  englishTermsAndConditionsUrl?: Resolver<IResolversTypes['ExternalUrl'], ParentType, ContextType>;
  nsRefundUrl?: Resolver<IResolversTypes['ExternalUrl'], ParentType, ContextType>;
  yellowbrickForgotPasswordUrl?: Resolver<IResolversTypes['ExternalUrl'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IFavoriteResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Favorite'] = IResolversParentTypes['Favorite']> = ResolversObject<{
  actions?: Resolver<IResolversTypes['FavoriteActions'], ParentType, ContextType>;
  coordinates?: Resolver<IResolversTypes['Coordinates'], ParentType, ContextType>;
  geoLocation?: Resolver<Maybe<IResolversTypes['FavoriteLocation']>, ParentType, ContextType>;
  hasFallbackLocation?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  locationId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  organization?: Resolver<Maybe<IResolversTypes['Organization']>, ParentType, ContextType>;
  type?: Resolver<Maybe<IResolversTypes['FavoriteTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IFavoriteActionsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['FavoriteActions'] = IResolversParentTypes['FavoriteActions']> = ResolversObject<{
  deletable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  editable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IFavoriteLocationResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['FavoriteLocation'] = IResolversParentTypes['FavoriteLocation']> = ResolversObject<{
  city?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  coordinates?: Resolver<IResolversTypes['Coordinates'], ParentType, ContextType>;
  countryIsoCode?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  formattedAddress?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  googlePlaceId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  initialSource?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  postalCode?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  streetNumber?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  streetNumberSuffix?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  tomtomPlaceId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IFavoritesResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Favorites'] = IResolversParentTypes['Favorites']> = ResolversObject<{
  create?: Resolver<Maybe<IResolversTypes['CreateFavoritePayload']>, ParentType, ContextType, RequireFields<IFavoritesCreateArgs, 'input'>>;
  destroy?: Resolver<Maybe<IResolversTypes['DestroyFavoritePayload']>, ParentType, ContextType, RequireFields<IFavoritesDestroyArgs, 'input'>>;
  setManualCoordinates?: Resolver<Maybe<IResolversTypes['SetManualFavoriteCoordinatesPayload']>, ParentType, ContextType, RequireFields<IFavoritesSetManualCoordinatesArgs, 'input'>>;
  update?: Resolver<Maybe<IResolversTypes['UpdateFavoritePayload']>, ParentType, ContextType, RequireFields<IFavoritesUpdateArgs, 'input'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IFeatureOptionResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['FeatureOption'] = IResolversParentTypes['FeatureOption']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['FeatureOptionEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IFeatureOrderStatusResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['FeatureOrderStatus'] = IResolversParentTypes['FeatureOrderStatus']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['FeatureOrderStatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IFeatureUrlsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['FeatureUrls'] = IResolversParentTypes['FeatureUrls']> = ResolversObject<{
  android?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  ios?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  web?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IFilterOptionItemResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['FilterOptionItem'] = IResolversParentTypes['FilterOptionItem']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IFilterOptionsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['FilterOptions'] = IResolversParentTypes['FilterOptions']> = ResolversObject<{
  donkeyAssetsAvailabilityType?: Resolver<Maybe<Array<IResolversTypes['FilterOptionItem']>>, ParentType, ContextType>;
  journeyServiceOperators?: Resolver<Maybe<Array<IResolversTypes['FilterOptionItem']>>, ParentType, ContextType>;
  journeysJourneyModes?: Resolver<Maybe<Array<IResolversTypes['FilterOptionItem']>>, ParentType, ContextType>;
  mainCategories?: Resolver<Maybe<Array<IResolversTypes['FilterOptionItem']>>, ParentType, ContextType>;
  mobilityEventsFilterByStatus?: Resolver<Maybe<Array<IResolversTypes['FilterOptionItem']>>, ParentType, ContextType>;
  mobilityEventsModality?: Resolver<Maybe<Array<IResolversTypes['FilterOptionItem']>>, ParentType, ContextType>;
  mobilityEventsTravelPurpose?: Resolver<Maybe<Array<IResolversTypes['FilterOptionItem']>>, ParentType, ContextType>;
  multiModalAvailabilityModalities?: Resolver<Maybe<Array<IResolversTypes['FilterOptionItem']>>, ParentType, ContextType>;
  reservationsKinds?: Resolver<Maybe<Array<IResolversTypes['FilterOptionItem']>>, ParentType, ContextType>;
  tranzerQuotesPreferredModalities?: Resolver<Maybe<Array<IResolversTypes['FilterOptionItem']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IFinancialResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Financial'] = IResolversParentTypes['Financial']> = ResolversObject<{
  consequences?: Resolver<IResolversTypes['FinancialConsequences'], ParentType, ContextType>;
  fact?: Resolver<Maybe<IResolversTypes['FinancialFact']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IFinancialComponentResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['FinancialComponent'] = IResolversParentTypes['FinancialComponent']> = ResolversObject<{
  amount?: Resolver<IResolversTypes['Money'], ParentType, ContextType>;
  entries?: Resolver<Array<IResolversTypes['JournalEntry']>, ParentType, ContextType>;
  label?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  moneyTransfer?: Resolver<IResolversTypes['MoneyTransfer'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IFinancialConsequencesResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['FinancialConsequences'] = IResolversParentTypes['FinancialConsequences']> = ResolversObject<{
  fiscal?: Resolver<Maybe<IResolversTypes['FinancialComponent']>, ParentType, ContextType>;
  gross?: Resolver<Maybe<IResolversTypes['FinancialComponent']>, ParentType, ContextType>;
  net?: Resolver<Maybe<IResolversTypes['FinancialComponent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IFinancialFactResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['FinancialFact'] = IResolversParentTypes['FinancialFact']> = ResolversObject<{
  direction?: Resolver<Maybe<IResolversTypes['DirectionEnum']>, ParentType, ContextType>;
  exclVat?: Resolver<Maybe<IResolversTypes['Money']>, ParentType, ContextType>;
  inclVat?: Resolver<IResolversTypes['Money'], ParentType, ContextType>;
  label?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  receipt?: Resolver<Maybe<IResolversTypes['Receipt']>, ParentType, ContextType>;
  vatRate?: Resolver<Maybe<IResolversTypes['VatRate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IFinancialSummaryResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['FinancialSummary'] = IResolversParentTypes['FinancialSummary']> = ResolversObject<{
  fiscal?: Resolver<Maybe<IResolversTypes['Float']>, ParentType, ContextType>;
  gross?: Resolver<Maybe<IResolversTypes['Float']>, ParentType, ContextType>;
  mobilityEvent?: Resolver<Maybe<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  net?: Resolver<Maybe<IResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IFinishDonkeyRepublicReservationPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['FinishDonkeyRepublicReservationPayload'] = IResolversParentTypes['FinishDonkeyRepublicReservationPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  donkeyRepublicReservation?: Resolver<Maybe<IResolversTypes['DonkeyRepublicReservation']>, ParentType, ContextType>;
  donkeyRepublicResult?: Resolver<Maybe<IResolversTypes['DonkeyRepublicResult']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['DonkeyRepublicResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IFinishSharedCarReservationPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['FinishSharedCarReservationPayload'] = IResolversParentTypes['FinishSharedCarReservationPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  sharedCarReservation?: Resolver<IResolversTypes['SharedCarReservation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IFinishSharedVehicleReservationPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['FinishSharedVehicleReservationPayload'] = IResolversParentTypes['FinishSharedVehicleReservationPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  sharedVehicleReservation?: Resolver<IResolversTypes['SharedVehicleReservation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IFirstClassReasonResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['FirstClassReason'] = IResolversParentTypes['FirstClassReason']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['FirstClassReasonEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IFuelResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Fuel'] = IResolversParentTypes['Fuel']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['VehicleFuelTypeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IGeoLocationResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['GeoLocation'] = IResolversParentTypes['GeoLocation']> = ResolversObject<{
  coordinates?: Resolver<IResolversTypes['Coordinates'], ParentType, ContextType>;
  humanized?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IGetAccessUrlPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['GetAccessUrlPayload'] = IResolversParentTypes['GetAccessUrlPayload']> = ResolversObject<{
  accessUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  contract?: Resolver<Maybe<IResolversTypes['SplitBillingContract']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IGooglePlacesResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['GooglePlaces'] = IResolversParentTypes['GooglePlaces']> = ResolversObject<{
  details?: Resolver<Maybe<IResolversTypes['GooglePlacesDetailsResult']>, ParentType, ContextType, RequireFields<IGooglePlacesDetailsArgs, 'placeId'>>;
  suggest?: Resolver<Maybe<Array<IResolversTypes['GooglePlacesResult']>>, ParentType, ContextType, RequireFields<IGooglePlacesSuggestArgs, 'startsWith'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IGooglePlacesDetailsResultResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['GooglePlacesDetailsResult'] = IResolversParentTypes['GooglePlacesDetailsResult']> = ResolversObject<{
  address?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  coordinates?: Resolver<IResolversTypes['Coordinates'], ParentType, ContextType>;
  countryIsoCode?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  locationId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  placeId?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  postalCode?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  streetNumber?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IGooglePlacesResultResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['GooglePlacesResult'] = IResolversParentTypes['GooglePlacesResult']> = ResolversObject<{
  html?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  placeId?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  structuredText?: Resolver<IResolversTypes['GooglePlacesStructuredText'], ParentType, ContextType>;
  text?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<Maybe<IResolversTypes['GooglePlacesTypeValue']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IGooglePlacesStructuredTextResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['GooglePlacesStructuredText'] = IResolversParentTypes['GooglePlacesStructuredText']> = ResolversObject<{
  main?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  secondary?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IGooglePlacesTypeValueResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['GooglePlacesTypeValue'] = IResolversParentTypes['GooglePlacesTypeValue']> = ResolversObject<{
  icon?: Resolver<Maybe<IResolversTypes['IconEnum']>, ParentType, ContextType>;
  value?: Resolver<IResolversTypes['GooglePlacesTypesEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IGreenwheelsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Greenwheels'] = IResolversParentTypes['Greenwheels']> = ResolversObject<{
  activatable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  activationLink?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  agreedTermsAndConditions?: Resolver<Maybe<Array<IResolversTypes['TermsAndConditions']>>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  driversLicenseCountries?: Resolver<Array<IResolversTypes['DriversLicenseCountry']>, ParentType, ContextType>;
  greenwheelsTermsAndConditionsUrl?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  id?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  mobideskOwnershipToken?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  motivation?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<IResolversTypes['FeatureOption'], ParentType, ContextType>;
  registerable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['FeatureOrderStatus'], ParentType, ContextType>;
  urls?: Resolver<Maybe<IResolversTypes['FeatureUrls']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IGreenwheelsFleetResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['GreenwheelsFleet'] = IResolversParentTypes['GreenwheelsFleet']> = ResolversObject<{
  id?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IHowResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['How'] = IResolversParentTypes['How']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  icon?: Resolver<Maybe<IResolversTypes['Icon']>, ParentType, ContextType>;
  identity?: Resolver<Maybe<IResolversTypes['Identity']>, ParentType, ContextType>;
  label?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IHowMuchResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['HowMuch'] = IResolversParentTypes['HowMuch']> = ResolversObject<{
  co2?: Resolver<Maybe<IResolversTypes['Quantity']>, ParentType, ContextType>;
  co2Emission?: Resolver<Maybe<IResolversTypes['Quantity']>, ParentType, ContextType>;
  consumption?: Resolver<IResolversTypes['Quantity'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IHslaResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Hsla'] = IResolversParentTypes['Hsla']> = ResolversObject<{
  alpha?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  hue?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  lightness?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  saturation?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IHubResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Hub'] = IResolversParentTypes['Hub']> = ResolversObject<{
  cityIdentifier?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  deeplink?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  geoLocation?: Resolver<IResolversTypes['Coordinates'], ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  modality?: Resolver<IResolversTypes['JourneyMode'], ParentType, ContextType>;
  name?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  operator?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  parkingAvailable?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  serviceOperator?: Resolver<Maybe<IResolversTypes['ServiceOperator']>, ParentType, ContextType>;
  stationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  totalAvailable?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IIconResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Icon'] = IResolversParentTypes['Icon']> = ResolversObject<{
  name?: Resolver<IResolversTypes['IconEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IIdentityResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Identity'] = IResolversParentTypes['Identity']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IImageResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Image'] = IResolversParentTypes['Image']> = ResolversObject<{
  base64?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  contentType?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  height?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  size?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  uri?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  width?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IInvoiceStatusResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['InvoiceStatus'] = IResolversParentTypes['InvoiceStatus']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['InvoiceStatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface IJsonScalarConfig extends GraphQLScalarTypeConfig<IResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type IJournalEntryResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['JournalEntry'] = IResolversParentTypes['JournalEntry']> = ResolversObject<{
  amount?: Resolver<IResolversTypes['Money'], ParentType, ContextType>;
  calculation?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  moneyTransfer?: Resolver<IResolversTypes['MoneyTransfer'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IJourneyResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Journey'] = IResolversParentTypes['Journey']> = ResolversObject<{
  arrivalTime?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  caloriesCost?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  co2Emission?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  departureTime?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  destination?: Resolver<IResolversTypes['GeoLocation'], ParentType, ContextType>;
  duration?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  estimatedPrice?: Resolver<Maybe<IResolversTypes['MoneyWithCurrency']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  inconvenienceScore?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  journeyServiceOperatorIds?: Resolver<Array<IResolversTypes['String']>, ParentType, ContextType>;
  journeyServiceOperators?: Resolver<Array<IResolversTypes['ServiceOperator']>, ParentType, ContextType>;
  legs?: Resolver<Array<IResolversTypes['JourneyLeg']>, ParentType, ContextType>;
  origin?: Resolver<IResolversTypes['GeoLocation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IJourneyLegResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['JourneyLeg'] = IResolversParentTypes['JourneyLeg']> = ResolversObject<{
  agency?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  agencyLogo?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  bookingId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  crowdForecast?: Resolver<Maybe<IResolversTypes['CrowdForecastEnum']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  endPlatform?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  endTime?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  fromLocation?: Resolver<IResolversTypes['GeoLocation'], ParentType, ContextType>;
  humanized?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  lineDirection?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  lineNumber?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  mode?: Resolver<IResolversTypes['JourneyMode'], ParentType, ContextType>;
  polyline?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  quoteId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  startPlatform?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  toLocation?: Resolver<IResolversTypes['GeoLocation'], ParentType, ContextType>;
  waypoints?: Resolver<Maybe<Array<IResolversTypes['Coordinates']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IJourneyModeResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['JourneyMode'] = IResolversParentTypes['JourneyMode']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['JourneyModeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ILabelResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Label'] = IResolversParentTypes['Label']> = ResolversObject<{
  humanized?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<IResolversTypes['LabelEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ILeaseBikeResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['LeaseBike'] = IResolversParentTypes['LeaseBike']> = ResolversObject<{
  activatable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  agreedTermsAndConditions?: Resolver<Maybe<Array<IResolversTypes['TermsAndConditions']>>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  id?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  mobideskOwnershipToken?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  motivation?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<IResolversTypes['FeatureOption'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['FeatureOrderStatus'], ParentType, ContextType>;
  url?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  urls?: Resolver<Maybe<IResolversTypes['FeatureUrls']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ILeaseBikeOrderResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['LeaseBikeOrder'] = IResolversParentTypes['LeaseBikeOrder']> = ResolversObject<{
  create?: Resolver<Maybe<IResolversTypes['CreateLeaseBikeOrderPayload']>, ParentType, ContextType, RequireFields<ILeaseBikeOrderCreateArgs, 'input'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ILeaseCarResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['LeaseCar'] = IResolversParentTypes['LeaseCar']> = ResolversObject<{
  personalKilometers?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
  totalUnaccountedKilometers?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ILegResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Leg'] = IResolversParentTypes['Leg']> = ResolversObject<{
  quantity?: Resolver<IResolversTypes['Quantity'], ParentType, ContextType>;
  travelPurpose?: Resolver<IResolversTypes['TravelPurpose'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ILicensePlateResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['LicensePlate'] = IResolversParentTypes['LicensePlate']> = ResolversObject<{
  budgetChargeTotal?: Resolver<Maybe<IResolversTypes['Float']>, ParentType, ContextType>;
  licensePlate?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  mileages?: Resolver<Maybe<Array<IResolversTypes['Mileage']>>, ParentType, ContextType>;
  remainingKilometersTotal?: Resolver<Maybe<IResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ILocationResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Location'] = IResolversParentTypes['Location']> = ResolversObject<{
  coordinates?: Resolver<Maybe<IResolversTypes['Coordinates']>, ParentType, ContextType>;
  favoriteId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  locationId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ILockResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Lock'] = IResolversParentTypes['Lock']> = ResolversObject<{
  bdAddress?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  deviceName?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ILockDonkeyRepublicAssetPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['LockDonkeyRepublicAssetPayload'] = IResolversParentTypes['LockDonkeyRepublicAssetPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  donkeyRepublicReservation?: Resolver<Maybe<IResolversTypes['DonkeyRepublicReservation']>, ParentType, ContextType>;
  donkeyRepublicResult?: Resolver<Maybe<IResolversTypes['DonkeyRepublicResult']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ILoginPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['LoginPayload'] = IResolversParentTypes['LoginPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  hasOtpConfigured?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  jwt?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  otpRequired?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  token?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  tokens?: Resolver<Maybe<IResolversTypes['Auth']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ILogoutPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['LogoutPayload'] = IResolversParentTypes['LogoutPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMsaAbstractPropertyResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MSAAbstractProperty'] = IResolversParentTypes['MSAAbstractProperty']> = ResolversObject<{
  key?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMsaCarrierResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MSACarrier'] = IResolversParentTypes['MSACarrier']> = ResolversObject<{
  adminActions?: Resolver<Maybe<Array<Maybe<IResolversTypes['MSAAdminAction']>>>, ParentType, ContextType>;
  carrierId?: Resolver<IResolversTypes['UUID'], ParentType, ContextType>;
  productGroupId?: Resolver<IResolversTypes['UUID'], ParentType, ContextType>;
  properties?: Resolver<Maybe<Array<Maybe<IResolversTypes['MSAAbstractProperty']>>>, ParentType, ContextType>;
  provider?: Resolver<IResolversTypes['MSAProviderType'], ParentType, ContextType>;
  status?: Resolver<Maybe<IResolversTypes['MSAExternalStatus']>, ParentType, ContextType>;
  statusCategory?: Resolver<IResolversTypes['MSAStatusCategory'], ParentType, ContextType>;
  travelerActions?: Resolver<Maybe<Array<Maybe<IResolversTypes['MSATravelerAction']>>>, ParentType, ContextType>;
  type?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMsaProductResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MSAProduct'] = IResolversParentTypes['MSAProduct']> = ResolversObject<{
  adminActions?: Resolver<Maybe<Array<Maybe<IResolversTypes['MSAAdminAction']>>>, ParentType, ContextType>;
  productGroupId?: Resolver<IResolversTypes['UUID'], ParentType, ContextType>;
  productId?: Resolver<IResolversTypes['UUID'], ParentType, ContextType>;
  properties?: Resolver<Maybe<Array<Maybe<IResolversTypes['MSAAbstractProperty']>>>, ParentType, ContextType>;
  provider?: Resolver<IResolversTypes['MSAProviderType'], ParentType, ContextType>;
  status?: Resolver<Maybe<IResolversTypes['MSAExternalStatus']>, ParentType, ContextType>;
  statusCategory?: Resolver<IResolversTypes['MSAStatusCategory'], ParentType, ContextType>;
  travelerActions?: Resolver<Maybe<Array<Maybe<IResolversTypes['MSATravelerAction']>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMsaProductGroupResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MSAProductGroup'] = IResolversParentTypes['MSAProductGroup']> = ResolversObject<{
  carriers?: Resolver<Array<Maybe<IResolversTypes['MSACarrier']>>, ParentType, ContextType>;
  organizationalUnitId?: Resolver<IResolversTypes['UUID'], ParentType, ContextType>;
  organizationalUnitUuid?: Resolver<IResolversTypes['JSON'], ParentType, ContextType>;
  productGroupId?: Resolver<IResolversTypes['UUID'], ParentType, ContextType>;
  products?: Resolver<Array<Maybe<IResolversTypes['MSAProduct']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMsaPropertyTypeBindingResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MSAPropertyTypeBinding'] = IResolversParentTypes['MSAPropertyTypeBinding']> = ResolversObject<{
  constraints?: Resolver<Array<Maybe<IResolversTypes['MSAPropertyTypeConstraint']>>, ParentType, ContextType>;
  name?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  required?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMsaPropertyTypeConstraintResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MSAPropertyTypeConstraint'] = IResolversParentTypes['MSAPropertyTypeConstraint']> = ResolversObject<{
  constraint?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  rules?: Resolver<IResolversTypes['MSAQueryCarrierTypePropertiesListItemsConstraintsItemsRules'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMsaQueryCarrierTypePropertiesListItemsConstraintsItemsRulesResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MSAQueryCarrierTypePropertiesListItemsConstraintsItemsRules'] = IResolversParentTypes['MSAQueryCarrierTypePropertiesListItemsConstraintsItemsRules']> = ResolversObject<{
  max?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  min?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMsaSupportedCarriersForProductTypeListResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MSASupportedCarriersForProductTypeList'] = IResolversParentTypes['MSASupportedCarriersForProductTypeList']> = ResolversObject<{
  compatibleCarrierTypes?: Resolver<Maybe<Array<Maybe<IResolversTypes['String']>>>, ParentType, ContextType>;
  existingCompatibleCarriers?: Resolver<Maybe<Array<Maybe<IResolversTypes['UUID']>>>, ParentType, ContextType>;
  ok?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMainCategoryResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MainCategory'] = IResolversParentTypes['MainCategory']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  subCategories?: Resolver<Array<IResolversTypes['SubCategory']>, ParentType, ContextType>;
  value?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMandateActionsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MandateActions'] = IResolversParentTypes['MandateActions']> = ResolversObject<{
  acceptable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  revokable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMandateStatusResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MandateStatus'] = IResolversParentTypes['MandateStatus']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['MandateStatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMarkMessageAsReadPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MarkMessageAsReadPayload'] = IResolversParentTypes['MarkMessageAsReadPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMeanOfTransportFiscalityResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MeanOfTransportFiscality'] = IResolversParentTypes['MeanOfTransportFiscality']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['FiscalityEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMeansOfTransportResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MeansOfTransport'] = IResolversParentTypes['MeansOfTransport']> = ResolversObject<{
  actions?: Resolver<IResolversTypes['VehicleActions'], ParentType, ContextType>;
  budgetCostPerKilometer?: Resolver<Maybe<IResolversTypes['Float']>, ParentType, ContextType>;
  budgetCostPerMonth?: Resolver<Maybe<IResolversTypes['Float']>, ParentType, ContextType>;
  budgetGroupFuelConsumptionNorm?: Resolver<Maybe<IResolversTypes['Float']>, ParentType, ContextType>;
  co2EmissionPerKilometer?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  endsOn?: Resolver<Maybe<IResolversTypes['Date']>, ParentType, ContextType>;
  fiscality?: Resolver<IResolversTypes['FiscalityEnum'], ParentType, ContextType>;
  fuelType?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  icon?: Resolver<Maybe<IResolversTypes['Icon']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  licensePlate?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  meansOfTransportFiscality?: Resolver<IResolversTypes['MeanOfTransportFiscality'], ParentType, ContextType>;
  modality?: Resolver<IResolversTypes['Modality'], ParentType, ContextType>;
  name?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  startsOn?: Resolver<IResolversTypes['Date'], ParentType, ContextType>;
  title?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  vehicleInformation?: Resolver<Maybe<IResolversTypes['VehicleInformation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMediaResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Media'] = IResolversParentTypes['Media']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['MediaTypeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMediaSideResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MediaSide'] = IResolversParentTypes['MediaSide']> = ResolversObject<{
  type?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMergeMobilityEventsPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MergeMobilityEventsPayload'] = IResolversParentTypes['MergeMobilityEventsPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  day?: Resolver<Maybe<IResolversTypes['DayWithMobilityEvents']>, ParentType, ContextType>;
  mergedMobilityEvent?: Resolver<Maybe<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMergedMobilityEventsPreviewResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MergedMobilityEventsPreview'] = IResolversParentTypes['MergedMobilityEventsPreview']> = ResolversObject<{
  error?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  mobilityEventPreview?: Resolver<Maybe<IResolversTypes['MobilityEventPreview']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMessageResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Message'] = IResolversParentTypes['Message']> = ResolversObject<{
  content?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMileageResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Mileage'] = IResolversParentTypes['Mileage']> = ResolversObject<{
  amountInKilometers?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  budgetCharge?: Resolver<Maybe<IResolversTypes['Float']>, ParentType, ContextType>;
  budgetCostPerKilometer?: Resolver<Maybe<IResolversTypes['Float']>, ParentType, ContextType>;
  hasUnaccountedDistance?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  licensePlate?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  mobilityEventId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  recordedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  remainingKilometers?: Resolver<Maybe<IResolversTypes['Float']>, ParentType, ContextType>;
  supportsUnaccountedDistances?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMileagesResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Mileages'] = IResolversParentTypes['Mileages']> = ResolversObject<{
  create?: Resolver<Maybe<IResolversTypes['CreateMileagePayload']>, ParentType, ContextType, RequireFields<IMileagesCreateArgs, 'input'>>;
  update?: Resolver<Maybe<IResolversTypes['UpdateMileagePayload']>, ParentType, ContextType, RequireFields<IMileagesUpdateArgs, 'input'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMobilityCardOrderResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MobilityCardOrder'] = IResolversParentTypes['MobilityCardOrder']> = ResolversObject<{
  actions?: Resolver<IResolversTypes['MobilityCardOrderActions'], ParentType, ContextType>;
  budgetGroupRequiresPincode?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  employment?: Resolver<Maybe<IResolversTypes['Employment']>, ParentType, ContextType>;
  firstClassReason?: Resolver<Maybe<IResolversTypes['FirstClassReason']>, ParentType, ContextType>;
  fromStation?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  hasPincodeBeenEntered?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  humanizedSubscription?: Resolver<Maybe<IResolversTypes['Subscription']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  isDelivered?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  motivation?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  needsApproval?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  passportPhoto?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  permission?: Resolver<Maybe<IResolversTypes['MobilityCardOrderPermission']>, ParentType, ContextType>;
  recommendedCard?: Resolver<Maybe<IResolversTypes['RecommendedCard']>, ParentType, ContextType>;
  requestableServices?: Resolver<Maybe<Array<IResolversTypes['RequestableService']>>, ParentType, ContextType>;
  requestableSubscriptions?: Resolver<Maybe<Array<IResolversTypes['AvailableSubscription']>>, ParentType, ContextType>;
  requiredTermsAndConditionsDocuments?: Resolver<Maybe<Array<IResolversTypes['TermsAndConditionsDocument']>>, ParentType, ContextType>;
  services?: Resolver<Maybe<Array<IResolversTypes['MobilityCardOrderService']>>, ParentType, ContextType>;
  starValue?: Resolver<Maybe<IResolversTypes['StarValue']>, ParentType, ContextType>;
  status?: Resolver<Maybe<IResolversTypes['MobilityCardOrderStatus']>, ParentType, ContextType>;
  subscription?: Resolver<Maybe<IResolversTypes['SubscriptionEnum']>, ParentType, ContextType>;
  toStation?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  trainClass?: Resolver<Maybe<IResolversTypes['TrainClass']>, ParentType, ContextType>;
  trainClassStatus?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  zone?: Resolver<Maybe<IResolversTypes['Zone']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMobilityCardOrderActionsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MobilityCardOrderActions'] = IResolversParentTypes['MobilityCardOrderActions']> = ResolversObject<{
  isEditable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMobilityCardOrderPermissionResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MobilityCardOrderPermission'] = IResolversParentTypes['MobilityCardOrderPermission']> = ResolversObject<{
  canRequestOptions?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  canRequestPhoto?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMobilityCardOrderServiceResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MobilityCardOrderService'] = IResolversParentTypes['MobilityCardOrderService']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['PersonalMeansOfRegistrationServiceKindSubEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMobilityCardOrderStatusResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MobilityCardOrderStatus'] = IResolversParentTypes['MobilityCardOrderStatus']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['MobilityCardOrderStatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMobilityCardOrdersResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MobilityCardOrders'] = IResolversParentTypes['MobilityCardOrders']> = ResolversObject<{
  confirm?: Resolver<Maybe<IResolversTypes['ConfirmPayload']>, ParentType, ContextType, RequireFields<IMobilityCardOrdersConfirmArgs, 'input'>>;
  create?: Resolver<Maybe<IResolversTypes['CreateMobilityCardOrderPayload']>, ParentType, ContextType, RequireFields<IMobilityCardOrdersCreateArgs, 'input'>>;
  setOptions?: Resolver<Maybe<IResolversTypes['SetOptionsPayload']>, ParentType, ContextType, RequireFields<IMobilityCardOrdersSetOptionsArgs, 'input'>>;
  setPhoto?: Resolver<Maybe<IResolversTypes['SetPhotoPayload']>, ParentType, ContextType, RequireFields<IMobilityCardOrdersSetPhotoArgs, 'input'>>;
  setProfile?: Resolver<Maybe<IResolversTypes['SetProfilePayload']>, ParentType, ContextType, RequireFields<IMobilityCardOrdersSetProfileArgs, 'input'>>;
  setTerms?: Resolver<Maybe<IResolversTypes['SetTermsPayload']>, ParentType, ContextType, RequireFields<IMobilityCardOrdersSetTermsArgs, 'input'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMobilityEventResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MobilityEvent'] = IResolversParentTypes['MobilityEvent']> = ResolversObject<{
  actions?: Resolver<IResolversTypes['Actions'], ParentType, ContextType>;
  allowPersonalConsumption?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  employment?: Resolver<Maybe<IResolversTypes['Employment']>, ParentType, ContextType>;
  financial?: Resolver<IResolversTypes['Financial'], ParentType, ContextType>;
  fiscality?: Resolver<Maybe<IResolversTypes['FiscalityEnum']>, ParentType, ContextType>;
  happenedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  happenedInTheFuture?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  how?: Resolver<IResolversTypes['How'], ParentType, ContextType>;
  howMuch?: Resolver<Maybe<IResolversTypes['HowMuch']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  isRefund?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  needsApproval?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  requiredFields?: Resolver<Array<IResolversTypes['String']>, ParentType, ContextType>;
  signatures?: Resolver<Maybe<Array<IResolversTypes['Signature']>>, ParentType, ContextType>;
  status?: Resolver<IResolversTypes['Status'], ParentType, ContextType>;
  what?: Resolver<IResolversTypes['What'], ParentType, ContextType>;
  whatOption?: Resolver<Maybe<IResolversTypes['WhatOption']>, ParentType, ContextType>;
  when?: Resolver<IResolversTypes['When'], ParentType, ContextType>;
  where?: Resolver<Maybe<IResolversTypes['Where']>, ParentType, ContextType>;
  who?: Resolver<IResolversTypes['Employee'], ParentType, ContextType>;
  why?: Resolver<Maybe<IResolversTypes['Why']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMobilityEventAggregateResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MobilityEventAggregate'] = IResolversParentTypes['MobilityEventAggregate']> = ResolversObject<{
  count?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMobilityEventByDateResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MobilityEventByDate'] = IResolversParentTypes['MobilityEventByDate']> = ResolversObject<{
  date?: Resolver<IResolversTypes['Date'], ParentType, ContextType>;
  mobilityEvents?: Resolver<Array<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMobilityEventFormValidationsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MobilityEventFormValidations'] = IResolversParentTypes['MobilityEventFormValidations']> = ResolversObject<{
  showWarningForOver5WfhThisWeek?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  showWarningForOver40KmWalkOrBike?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  showWarningForWfhOnHoliday?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  showWarningForWfhOnWeekend?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  showWarningForWfhOnWeekendOrHoliday?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMobilityEventPreviewResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MobilityEventPreview'] = IResolversParentTypes['MobilityEventPreview']> = ResolversObject<{
  actions?: Resolver<IResolversTypes['Actions'], ParentType, ContextType>;
  allowPersonalConsumption?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  employment?: Resolver<Maybe<IResolversTypes['Employment']>, ParentType, ContextType>;
  financial?: Resolver<IResolversTypes['Financial'], ParentType, ContextType>;
  fiscality?: Resolver<Maybe<IResolversTypes['FiscalityEnum']>, ParentType, ContextType>;
  happenedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  happenedInTheFuture?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  how?: Resolver<IResolversTypes['How'], ParentType, ContextType>;
  howMuch?: Resolver<Maybe<IResolversTypes['HowMuch']>, ParentType, ContextType>;
  id?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  isRefund?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  needsApproval?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  requiredFields?: Resolver<Array<IResolversTypes['String']>, ParentType, ContextType>;
  signatures?: Resolver<Maybe<Array<IResolversTypes['Signature']>>, ParentType, ContextType>;
  status?: Resolver<IResolversTypes['Status'], ParentType, ContextType>;
  what?: Resolver<IResolversTypes['What'], ParentType, ContextType>;
  whatOption?: Resolver<Maybe<IResolversTypes['WhatOption']>, ParentType, ContextType>;
  when?: Resolver<IResolversTypes['When'], ParentType, ContextType>;
  where?: Resolver<Maybe<IResolversTypes['Where']>, ParentType, ContextType>;
  who?: Resolver<IResolversTypes['Employee'], ParentType, ContextType>;
  why?: Resolver<Maybe<IResolversTypes['Why']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMobilityEventTotalsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MobilityEventTotals'] = IResolversParentTypes['MobilityEventTotals']> = ResolversObject<{
  all?: Resolver<IResolversTypes['Totals'], ParentType, ContextType>;
  business?: Resolver<IResolversTypes['Totals'], ParentType, ContextType>;
  commute?: Resolver<IResolversTypes['Totals'], ParentType, ContextType>;
  workFromHome?: Resolver<IResolversTypes['Totals'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMobilityEventsWeekResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MobilityEventsWeek'] = IResolversParentTypes['MobilityEventsWeek']> = ResolversObject<{
  date?: Resolver<Maybe<IResolversTypes['Date']>, ParentType, ContextType>;
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  path?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<IResolversTypes['TaskEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMobilityServicesMutationResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MobilityServicesMutation'] = IResolversParentTypes['MobilityServicesMutation']> = ResolversObject<{
  carrierCreate?: Resolver<Maybe<IResolversTypes['MSACarrier']>, ParentType, ContextType, Partial<IMobilityServicesMutationCarrierCreateArgs>>;
  carrierReplace?: Resolver<Maybe<IResolversTypes['MSACarrier']>, ParentType, ContextType, Partial<IMobilityServicesMutationCarrierReplaceArgs>>;
  carrierUpdate?: Resolver<Maybe<IResolversTypes['MSACarrier']>, ParentType, ContextType, RequireFields<IMobilityServicesMutationCarrierUpdateArgs, 'carrierId'>>;
  productCreate?: Resolver<Maybe<IResolversTypes['MSAProduct']>, ParentType, ContextType, Partial<IMobilityServicesMutationProductCreateArgs>>;
  productGroupCreate?: Resolver<Maybe<IResolversTypes['MSAProductGroup']>, ParentType, ContextType, Partial<IMobilityServicesMutationProductGroupCreateArgs>>;
  productUpdate?: Resolver<Maybe<IResolversTypes['MSAProduct']>, ParentType, ContextType, RequireFields<IMobilityServicesMutationProductUpdateArgs, 'productId'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMobilityServicesQueryResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MobilityServicesQuery'] = IResolversParentTypes['MobilityServicesQuery']> = ResolversObject<{
  carrierGet?: Resolver<Maybe<IResolversTypes['MSACarrier']>, ParentType, ContextType, RequireFields<IMobilityServicesQueryCarrierGetArgs, 'carrierId'>>;
  carrierTypePropertiesList?: Resolver<Maybe<Array<Maybe<IResolversTypes['MSAPropertyTypeBinding']>>>, ParentType, ContextType, RequireFields<IMobilityServicesQueryCarrierTypePropertiesListArgs, 'carrierType'>>;
  productGet?: Resolver<Maybe<IResolversTypes['MSAProduct']>, ParentType, ContextType, RequireFields<IMobilityServicesQueryProductGetArgs, 'productId'>>;
  productGroupGet?: Resolver<Maybe<IResolversTypes['MSAProductGroup']>, ParentType, ContextType, RequireFields<IMobilityServicesQueryProductGroupGetArgs, 'productGroupId'>>;
  productTypePropertiesList?: Resolver<Maybe<Array<Maybe<IResolversTypes['MSAPropertyTypeBinding']>>>, ParentType, ContextType, RequireFields<IMobilityServicesQueryProductTypePropertiesListArgs, 'productType'>>;
  productTypeSupportedCarriers?: Resolver<Maybe<IResolversTypes['MSASupportedCarriersForProductTypeList']>, ParentType, ContextType, RequireFields<IMobilityServicesQueryProductTypeSupportedCarriersArgs, 'productGroupId' | 'productType'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IModalityResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Modality'] = IResolversParentTypes['Modality']> = ResolversObject<{
  clazz?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  humanizedWithCapital?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<IResolversTypes['ModalityKindEnum'], ParentType, ContextType>;
  modalityOwnership?: Resolver<Maybe<IResolversTypes['ModalityOwnership']>, ParentType, ContextType>;
  ownership?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  requiredPassengerCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  requiresVehicle?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  travelMode?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IModalityOwnershipResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ModalityOwnership'] = IResolversParentTypes['ModalityOwnership']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['OwnershipEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMoneyResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Money'] = IResolversParentTypes['Money']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
  valueInCents?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMoneyTransferResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MoneyTransfer'] = IResolversParentTypes['MoneyTransfer']> = ResolversObject<{
  direction?: Resolver<IResolversTypes['DirectionEnum'], ParentType, ContextType>;
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMoneyWithCurrencyResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MoneyWithCurrency'] = IResolversParentTypes['MoneyWithCurrency']> = ResolversObject<{
  currency?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
  valueInCents?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IMultiModalAvailabilityResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['MultiModalAvailability'] = IResolversParentTypes['MultiModalAvailability']> = ResolversObject<{
  __resolveType: TypeResolveFn<'Hub' | 'PublicTransportStop' | 'VehicleAsset', ParentType, ContextType>;
  deeplink?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  geoLocation?: Resolver<IResolversTypes['Coordinates'], ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  modality?: Resolver<IResolversTypes['JourneyMode'], ParentType, ContextType>;
  name?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  serviceOperator?: Resolver<Maybe<IResolversTypes['ServiceOperator']>, ParentType, ContextType>;
  type?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
}>;

export type IMutationResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Mutation'] = IResolversParentTypes['Mutation']> = ResolversObject<{
  MobilityServices?: Resolver<IResolversTypes['MobilityServicesMutation'], ParentType, ContextType>;
  reisbalans?: Resolver<IResolversTypes['reisbalansMutation'], ParentType, ContextType>;
}>;

export type INsRefundUrlResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['NSRefundUrl'] = IResolversParentTypes['NSRefundUrl']> = ResolversObject<{
  title?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type INewProductResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['NewProduct'] = IResolversParentTypes['NewProduct']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  path?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<IResolversTypes['TaskEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type INonCombinedResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['NonCombined'] = IResolversParentTypes['NonCombined']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface IObjMapScalarConfig extends GraphQLScalarTypeConfig<IResolversTypes['ObjMap'], any> {
  name: 'ObjMap';
}

export type IOpeningHoursResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['OpeningHours'] = IResolversParentTypes['OpeningHours']> = ResolversObject<{
  dayOfWeek?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  endTime?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IOrganizationResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Organization'] = IResolversParentTypes['Organization']> = ResolversObject<{
  budgetGroup?: Resolver<Maybe<IResolversTypes['BudgetGroup']>, ParentType, ContextType, RequireFields<IOrganizationBudgetGroupArgs, 'id'>>;
  customerService?: Resolver<IResolversTypes['CustomerService'], ParentType, ContextType>;
  distancePrecision?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  externalClientNumber?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  finalSettlementRunning?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  hasActiveProjects?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType, Partial<IOrganizationHasActiveProjectsArgs>>;
  icon?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  logo?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  mandatoryLocationPostalCode?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  minPasswordLength?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  policyDocumentUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  primaryColor?: Resolver<Maybe<IResolversTypes['Color']>, ParentType, ContextType>;
  projects?: Resolver<Maybe<Array<Maybe<IResolversTypes['Project']>>>, ParentType, ContextType, Partial<IOrganizationProjectsArgs>>;
  secondaryColor?: Resolver<Maybe<IResolversTypes['Color']>, ParentType, ContextType>;
  serviceEmailAddress?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  serviceHelpUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  serviceHelpUrlName?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  serviceTelephone?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  uuid?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IOvFietsLocationResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['OvFietsLocation'] = IResolversParentTypes['OvFietsLocation']> = ResolversObject<{
  address?: Resolver<Maybe<IResolversTypes['StreetAddress']>, ParentType, ContextType>;
  availableBikes?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  coordinates?: Resolver<IResolversTypes['Coordinates'], ParentType, ContextType>;
  distance?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  open?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  openingHours?: Resolver<Maybe<Array<Maybe<IResolversTypes['OpeningHours']>>>, ParentType, ContextType>;
  stationCode?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IOvPasResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['OvPas'] = IResolversParentTypes['OvPas']> = ResolversObject<{
  allowedOvPasTypes?: Resolver<Array<IResolversTypes['OvPasEnum']>, ParentType, ContextType>;
  approvalRequired?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType, RequireFields<IOvPasApprovalRequiredArgs, 'type'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IParkingResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Parking'] = IResolversParentTypes['Parking']> = ResolversObject<{
  create?: Resolver<Maybe<IResolversTypes['CreateParkingOrderPayload']>, ParentType, ContextType, RequireFields<IParkingCreateArgs, 'input'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IParkingOrderResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ParkingOrder'] = IResolversParentTypes['ParkingOrder']> = ResolversObject<{
  actions?: Resolver<IResolversTypes['ParkingOrderActions'], ParentType, ContextType>;
  approvedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  motivation?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<IResolversTypes['ParkingOrderStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IParkingOrderActionsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ParkingOrderActions'] = IResolversParentTypes['ParkingOrderActions']> = ResolversObject<{
  editable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IParkingOrderStatusResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ParkingOrderStatus'] = IResolversParentTypes['ParkingOrderStatus']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['ParkingOrderStatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPasswordResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Password'] = IResolversParentTypes['Password']> = ResolversObject<{
  passwordConditions?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  passwordStrength?: Resolver<Maybe<IResolversTypes['PasswordStrength']>, ParentType, ContextType, RequireFields<IPasswordPasswordStrengthArgs, 'password'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPasswordStrengthResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['PasswordStrength'] = IResolversParentTypes['PasswordStrength']> = ResolversObject<{
  valid?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPendingApprovalResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['PendingApproval'] = IResolversParentTypes['PendingApproval']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  path?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<IResolversTypes['TaskEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPermissionsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Permissions'] = IResolversParentTypes['Permissions']> = ResolversObject<{
  addableVehicles?: Resolver<Array<IResolversTypes['AddableVehiclePermission']>, ParentType, ContextType>;
  allowLoginByQrCode?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  amberFeature?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  approvals?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  canAddPrivateCar?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  canAddPrivateElectricCar?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  canAddPrivateMotorcycle?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  canAssignReplacementManager?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  canCreateNewMobilityCardOrder?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  canRemoveReplacementManager?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  canRequestLeaseBikeFeature?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  canUseGps?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  canViewFinancialConsequences?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  canViewLeaseBikeFeature?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  canViewMileages?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  changeCombinedRide?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  manageCommuteCompensation?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  manageCommuteRides?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  manageMobilityCards?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  manageMobilityEvents?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  manageParking?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  manageWorkFromHome?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  orderMobilityCards?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  orderParking?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  otpConfigured?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  otpRequiredForLogin?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  reimburseBusinessRide?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  reimburseCommuteRide?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  rentalCarFeature?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  requestDirectDebitMandate?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  requestSharedVehicleFeature?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  requestSplitBillingContract?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  requestTaxiFeature?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  routePlannerFeature?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  selectEmployment?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  sharedVehicleFeature?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  taxiFeature?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  unacceptMobilityEvents?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  unconfirmMobilityEvents?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  viewDirectDebitInvoices?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  viewPayments?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  viewSplitBillingInvoices?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPersonalMeansOfRegistrationResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['PersonalMeansOfRegistration'] = IResolversParentTypes['PersonalMeansOfRegistration']> = ResolversObject<{
  abilities?: Resolver<IResolversTypes['PersonalMeansOfRegistrationAbilities'], ParentType, ContextType>;
  availableServices?: Resolver<Maybe<Array<IResolversTypes['AvailableServiceInterface']>>, ParentType, ContextType, Partial<IPersonalMeansOfRegistrationAvailableServicesArgs>>;
  endsOn?: Resolver<IResolversTypes['PersonalMeansOfRegistrationEndsOn'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  identity?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationIdentity']>, ParentType, ContextType>;
  kind?: Resolver<IResolversTypes['PersonalMeansOfRegistrationKind'], ParentType, ContextType>;
  photoRejectedReasons?: Resolver<Maybe<Array<IResolversTypes['PersonalMeansOfRegistrationPhotoRejectedReason']>>, ParentType, ContextType>;
  replacementRequested?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  services?: Resolver<Maybe<Array<IResolversTypes['PersonalMeansOfRegistrationService']>>, ParentType, ContextType>;
  status?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPersonalMeansOfRegistrationAbilitiesResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['PersonalMeansOfRegistrationAbilities'] = IResolversParentTypes['PersonalMeansOfRegistrationAbilities']> = ResolversObject<{
  activatable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  lostOrStolenReportable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  terminatable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPersonalMeansOfRegistrationEndsOnResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['PersonalMeansOfRegistrationEndsOn'] = IResolversParentTypes['PersonalMeansOfRegistrationEndsOn']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPersonalMeansOfRegistrationIdentityResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['PersonalMeansOfRegistrationIdentity'] = IResolversParentTypes['PersonalMeansOfRegistrationIdentity']> = ResolversObject<{
  humanized?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPersonalMeansOfRegistrationKindResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['PersonalMeansOfRegistrationKind'] = IResolversParentTypes['PersonalMeansOfRegistrationKind']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  main?: Resolver<IResolversTypes['PersonalMeansOfRegistrationKindMainEnum'], ParentType, ContextType>;
  sub?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationKindSubEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPersonalMeansOfRegistrationMetaStatusResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['PersonalMeansOfRegistrationMetaStatus'] = IResolversParentTypes['PersonalMeansOfRegistrationMetaStatus']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['PersonalMeansOfRegistrationMetaStatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPersonalMeansOfRegistrationPhotoRejectedReasonResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['PersonalMeansOfRegistrationPhotoRejectedReason'] = IResolversParentTypes['PersonalMeansOfRegistrationPhotoRejectedReason']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['PersonalMeansOfRegistrationPhotoRejectedReasonEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPersonalMeansOfRegistrationServiceResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['PersonalMeansOfRegistrationService'] = IResolversParentTypes['PersonalMeansOfRegistrationService']> = ResolversObject<{
  endsOn?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationServiceEndsOn']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  identity?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationServiceIdentity']>, ParentType, ContextType>;
  kind?: Resolver<IResolversTypes['PersonalMeansOfRegistrationServiceKind'], ParentType, ContextType>;
  status?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationServiceStatus']>, ParentType, ContextType>;
  trainClass?: Resolver<Maybe<IResolversTypes['TrainClass']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPersonalMeansOfRegistrationServiceEndsOnResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['PersonalMeansOfRegistrationServiceEndsOn'] = IResolversParentTypes['PersonalMeansOfRegistrationServiceEndsOn']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPersonalMeansOfRegistrationServiceIdentityResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['PersonalMeansOfRegistrationServiceIdentity'] = IResolversParentTypes['PersonalMeansOfRegistrationServiceIdentity']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPersonalMeansOfRegistrationServiceKindResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['PersonalMeansOfRegistrationServiceKind'] = IResolversParentTypes['PersonalMeansOfRegistrationServiceKind']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  main?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  sub?: Resolver<IResolversTypes['PersonalMeansOfRegistrationServiceKindSubEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPersonalMeansOfRegistrationServiceStatusResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['PersonalMeansOfRegistrationServiceStatus'] = IResolversParentTypes['PersonalMeansOfRegistrationServiceStatus']> = ResolversObject<{
  enumValue?: Resolver<IResolversTypes['PersonalMeansOfRegistrationServiceStatusFilterEnum'], ParentType, ContextType>;
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  metaStatus?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationMetaStatus']>, ParentType, ContextType>;
  value?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPersonalMeansOfRegistrationStatusResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['PersonalMeansOfRegistrationStatus'] = IResolversParentTypes['PersonalMeansOfRegistrationStatus']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['PersonalMeansOfRegistrationStatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPlacesResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Places'] = IResolversParentTypes['Places']> = ResolversObject<{
  details?: Resolver<Maybe<IResolversTypes['PlacesDetailsResult']>, ParentType, ContextType, RequireFields<IPlacesDetailsArgs, 'placeId'>>;
  suggest?: Resolver<Maybe<Array<IResolversTypes['PlacesResult']>>, ParentType, ContextType, RequireFields<IPlacesSuggestArgs, 'startsWith'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPlacesDetailsResultResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['PlacesDetailsResult'] = IResolversParentTypes['PlacesDetailsResult']> = ResolversObject<{
  address?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  coordinates?: Resolver<IResolversTypes['Coordinates'], ParentType, ContextType>;
  countryIsoCode?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  locationId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  placeId?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  postalCode?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  streetNumber?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPlacesResultResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['PlacesResult'] = IResolversParentTypes['PlacesResult']> = ResolversObject<{
  html?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  placeId?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  structuredText?: Resolver<IResolversTypes['PlacesStructuredText'], ParentType, ContextType>;
  text?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<Maybe<IResolversTypes['PlacesTypeValue']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPlacesStructuredTextResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['PlacesStructuredText'] = IResolversParentTypes['PlacesStructuredText']> = ResolversObject<{
  main?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  secondary?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPlacesTypeValueResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['PlacesTypeValue'] = IResolversParentTypes['PlacesTypeValue']> = ResolversObject<{
  icon?: Resolver<Maybe<IResolversTypes['IconEnum']>, ParentType, ContextType>;
  value?: Resolver<IResolversTypes['PlacesTypesEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPossibleReplacementManagerResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['PossibleReplacementManager'] = IResolversParentTypes['PossibleReplacementManager']> = ResolversObject<{
  email?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  fullName?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPriceResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Price'] = IResolversParentTypes['Price']> = ResolversObject<{
  amount?: Resolver<IResolversTypes['Money'], ParentType, ContextType>;
  currency?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPrivacyResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Privacy'] = IResolversParentTypes['Privacy']> = ResolversObject<{
  description?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  humanized?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  reasons?: Resolver<Maybe<Array<IResolversTypes['PrivacyRequestReason']>>, ParentType, ContextType>;
  value?: Resolver<Maybe<IResolversTypes['PrivacyTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPrivacyRequestReasonResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['PrivacyRequestReason'] = IResolversParentTypes['PrivacyRequestReason']> = ResolversObject<{
  description?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  reason?: Resolver<Maybe<IResolversTypes['PrivacyRequestReasonEnum']>, ParentType, ContextType>;
  title?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Maybe<IResolversTypes['RightToBeForgottenReasonEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IProfileResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Profile'] = IResolversParentTypes['Profile']> = ResolversObject<{
  activeSince?: Resolver<IResolversTypes['Date'], ParentType, ContextType>;
  avatar?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  dateOfBirth?: Resolver<Maybe<IResolversTypes['Date']>, ParentType, ContextType>;
  defaultMobilityCardTransactionTravelPurpose?: Resolver<Maybe<IResolversTypes['TravelPurposeEnum']>, ParentType, ContextType>;
  defaultMobilityCardWeekendTransactionTravelPurpose?: Resolver<Maybe<IResolversTypes['TravelPurposeEnum']>, ParentType, ContextType>;
  email?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  employeeNumber?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  homeAddress?: Resolver<Maybe<IResolversTypes['EmployeeAddress']>, ParentType, ContextType>;
  infix?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  initials?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  isManager?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  lastName?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  preferredTravelPurpose?: Resolver<Maybe<IResolversTypes['TravelPurposeEnum']>, ParentType, ContextType>;
  privacyTypes?: Resolver<Maybe<Array<IResolversTypes['Privacy']>>, ParentType, ContextType>;
  rightToBeForgottenReasons?: Resolver<Maybe<Array<IResolversTypes['RightToBeForgottenReason']>>, ParentType, ContextType>;
  usesNewInterface?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  workAddress?: Resolver<Maybe<IResolversTypes['EmployeeAddress']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IProjectResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Project'] = IResolversParentTypes['Project']> = ResolversObject<{
  active?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  code?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  endsOn?: Resolver<Maybe<IResolversTypes['Date']>, ParentType, ContextType>;
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  startsOn?: Resolver<IResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPublicTransportStopResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['PublicTransportStop'] = IResolversParentTypes['PublicTransportStop']> = ResolversObject<{
  deeplink?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  geoLocation?: Resolver<IResolversTypes['Coordinates'], ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  modality?: Resolver<IResolversTypes['JourneyMode'], ParentType, ContextType>;
  name?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  serviceOperator?: Resolver<Maybe<IResolversTypes['ServiceOperator']>, ParentType, ContextType>;
  services?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IPurposeResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Purpose'] = IResolversParentTypes['Purpose']> = ResolversObject<{
  details?: Resolver<Maybe<Array<IResolversTypes['Stats']>>, ParentType, ContextType>;
  totalCo2EmissionInGrams?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  totalDistanceInMeters?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IQuantityResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Quantity'] = IResolversParentTypes['Quantity']> = ResolversObject<{
  amount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  unit?: Resolver<IResolversTypes['UnitEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IQueryResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Query'] = IResolversParentTypes['Query']> = ResolversObject<{
  MobilityServices?: Resolver<IResolversTypes['MobilityServicesQuery'], ParentType, ContextType>;
  reisbalans?: Resolver<IResolversTypes['reisbalansQuery'], ParentType, ContextType>;
  reisbalansV2?: Resolver<IResolversTypes['reisbalansV2Query'], ParentType, ContextType>;
}>;

export type IQuoteResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Quote'] = IResolversParentTypes['Quote']> = ResolversObject<{
  endTime?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  legs?: Resolver<Array<IResolversTypes['TranzerLeg']>, ParentType, ContextType>;
  requiredBookingFields?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  startTime?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['QuoteStatus'], ParentType, ContextType>;
  totalCosts?: Resolver<IResolversTypes['Money'], ParentType, ContextType>;
  totalDuration?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  validUntil?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IQuoteStatusResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['QuoteStatus'] = IResolversParentTypes['QuoteStatus']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['QuoteStatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IReceiptResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Receipt'] = IResolversParentTypes['Receipt']> = ResolversObject<{
  contentType?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  filename?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  size?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  url?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IRecommendedCardResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['RecommendedCard'] = IResolversParentTypes['RecommendedCard']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['PersonalMeansOfRegistrationKindSubEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IRefreshTranzerReservationPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['RefreshTranzerReservationPayload'] = IResolversParentTypes['RefreshTranzerReservationPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  tranzerReservation?: Resolver<Maybe<IResolversTypes['TranzerReservation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IRefundToBeLinkedResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['RefundToBeLinked'] = IResolversParentTypes['RefundToBeLinked']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  mobilityEventId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  path?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<IResolversTypes['TaskEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IReimbursableCategoryResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ReimbursableCategory'] = IResolversParentTypes['ReimbursableCategory']> = ResolversObject<{
  category?: Resolver<IResolversTypes['Category'], ParentType, ContextType>;
  fiscality?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  modality?: Resolver<Maybe<IResolversTypes['Modality']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IReimbursableCategoryMethodResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ReimbursableCategoryMethod'] = IResolversParentTypes['ReimbursableCategoryMethod']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['ReimbursableCategoryMethodEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IReimbursementRuleResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ReimbursementRule'] = IResolversParentTypes['ReimbursementRule']> = ResolversObject<{
  __resolveType: TypeResolveFn<'Cost' | 'Distance', ParentType, ContextType>;
  budgetGroupId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  category?: Resolver<IResolversTypes['Category'], ParentType, ContextType>;
  configuration?: Resolver<IResolversTypes['Configuration'], ParentType, ContextType>;
  fiscality?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  main?: Resolver<IResolversTypes['MainCategory'], ParentType, ContextType>;
  modality?: Resolver<Maybe<IResolversTypes['Modality']>, ParentType, ContextType>;
  reimbursableCategory?: Resolver<IResolversTypes['ReimbursableCategory'], ParentType, ContextType>;
  sub?: Resolver<Maybe<IResolversTypes['SubCategory']>, ParentType, ContextType>;
  travelPurpose?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
}>;

export type IRejectMobilityEventsPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['RejectMobilityEventsPayload'] = IResolversParentTypes['RejectMobilityEventsPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  rejectedMobilityEvents?: Resolver<Array<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  unrejectedMobilityEvents?: Resolver<Array<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IRemoveFromWhitelistPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['RemoveFromWhitelistPayload'] = IResolversParentTypes['RemoveFromWhitelistPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  studentTravelRequestDates?: Resolver<IResolversTypes['RequestDates'], ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IRentalCarResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['RentalCar'] = IResolversParentTypes['RentalCar']> = ResolversObject<{
  activatable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  agreedTermsAndConditions?: Resolver<Maybe<Array<IResolversTypes['TermsAndConditions']>>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  id?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  mobideskOwnershipToken?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  motivation?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<IResolversTypes['FeatureOption'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['FeatureOrderStatus'], ParentType, ContextType>;
  url?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  urls?: Resolver<Maybe<IResolversTypes['FeatureUrls']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IRentalLocationsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['RentalLocations'] = IResolversParentTypes['RentalLocations']> = ResolversObject<{
  pickup?: Resolver<IResolversTypes['Location'], ParentType, ContextType>;
  pickupLabel?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  return?: Resolver<IResolversTypes['Location'], ParentType, ContextType>;
  returnLabel?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IReplacementManagerResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ReplacementManager'] = IResolversParentTypes['ReplacementManager']> = ResolversObject<{
  approvalPeriodEnd?: Resolver<Maybe<IResolversTypes['Date']>, ParentType, ContextType>;
  approvalPeriodStart?: Resolver<IResolversTypes['Date'], ParentType, ContextType>;
  email?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  emailApprovalManager?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  fullName?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  fullNameApprovalManager?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<Maybe<IResolversTypes['Label']>, ParentType, ContextType>;
  managerId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IReportPersonalMeansOfRegistrationPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ReportPersonalMeansOfRegistrationPayload'] = IResolversParentTypes['ReportPersonalMeansOfRegistrationPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  personalMeansOfRegistration?: Resolver<IResolversTypes['PersonalMeansOfRegistration'], ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IRequestDatesResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['RequestDates'] = IResolversParentTypes['RequestDates']> = ResolversObject<{
  studentTravelAddedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  studentTravelRemovedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IRequestDonkeyRepublicFeaturePayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['RequestDonkeyRepublicFeaturePayload'] = IResolversParentTypes['RequestDonkeyRepublicFeaturePayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<Maybe<IResolversTypes['Result']>, ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IRequestFeaturePayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['RequestFeaturePayload'] = IResolversParentTypes['RequestFeaturePayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<Maybe<IResolversTypes['Result']>, ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IRequestServicePayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['RequestServicePayload'] = IResolversParentTypes['RequestServicePayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  servicesChangeRequest?: Resolver<Maybe<IResolversTypes['ServicesChangeRequest']>, ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IRequestTrainClassChangePayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['RequestTrainClassChangePayload'] = IResolversParentTypes['RequestTrainClassChangePayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  trainClassChangeRequest?: Resolver<Maybe<IResolversTypes['TrainClassChangeRequest']>, ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IRequestableDonkeyRepublicResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['RequestableDonkeyRepublic'] = IResolversParentTypes['RequestableDonkeyRepublic']> = ResolversObject<{
  activatable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  agreedTermsAndConditions?: Resolver<Maybe<Array<IResolversTypes['TermsAndConditions']>>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  id?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  mobideskOwnershipToken?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  motivation?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<IResolversTypes['FeatureOption'], ParentType, ContextType>;
  requiresMotivation?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  requiresPhoneNumber?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<IResolversTypes['FeatureOrderStatus'], ParentType, ContextType>;
  termsAndConditions?: Resolver<Maybe<Array<IResolversTypes['TermsAndConditions']>>, ParentType, ContextType>;
  urls?: Resolver<Maybe<IResolversTypes['FeatureUrls']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IRequestableFeatureResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['RequestableFeature'] = IResolversParentTypes['RequestableFeature']> = ResolversObject<{
  activatable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  agreedTermsAndConditions?: Resolver<Maybe<Array<IResolversTypes['TermsAndConditions']>>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  id?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  mobideskOwnershipToken?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  motivation?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<IResolversTypes['FeatureOption'], ParentType, ContextType>;
  requiresMotivation?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  requiresPhoneNumber?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<IResolversTypes['FeatureOrderStatus'], ParentType, ContextType>;
  termsAndConditions?: Resolver<Maybe<Array<IResolversTypes['TermsAndConditions']>>, ParentType, ContextType>;
  urls?: Resolver<Maybe<IResolversTypes['FeatureUrls']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IRequestableFeatureOptionResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['RequestableFeatureOption'] = IResolversParentTypes['RequestableFeatureOption']> = ResolversObject<{
  name?: Resolver<Maybe<IResolversTypes['FeatureOption']>, ParentType, ContextType>;
  requiresMotivation?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  termsAndConditions?: Resolver<Maybe<Array<IResolversTypes['TermsAndConditions']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IRequestableServiceResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['RequestableService'] = IResolversParentTypes['RequestableService']> = ResolversObject<{
  budgetGroupAvailability?: Resolver<Maybe<IResolversTypes['AvailableServiceAvailabilityEnum']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['Description']>, ParentType, ContextType>;
  endsOn?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationServiceEndsOn']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<IResolversTypes['Icon']>, ParentType, ContextType>;
  identity?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationServiceIdentity']>, ParentType, ContextType>;
  kind?: Resolver<IResolversTypes['PersonalMeansOfRegistrationServiceKind'], ParentType, ContextType>;
  mobideskOwnershipToken?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  personalMeansOfRegistration?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistration']>, ParentType, ContextType>;
  recordId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  requiresMotivation?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  requiresPincode?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationServiceStatus']>, ParentType, ContextType>;
  termsAndConditions?: Resolver<Maybe<IResolversTypes['TermsAndConditions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IRequestableYellowbrickProductResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['RequestableYellowbrickProduct'] = IResolversParentTypes['RequestableYellowbrickProduct']> = ResolversObject<{
  budgetGroupAvailability?: Resolver<Maybe<IResolversTypes['AvailableServiceAvailabilityEnum']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['Description']>, ParentType, ContextType>;
  endsOn?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationServiceEndsOn']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<IResolversTypes['Icon']>, ParentType, ContextType>;
  identity?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationServiceIdentity']>, ParentType, ContextType>;
  kind?: Resolver<IResolversTypes['PersonalMeansOfRegistrationServiceKind'], ParentType, ContextType>;
  mobideskOwnershipToken?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  personalMeansOfRegistration?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistration']>, ParentType, ContextType>;
  recordId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  requiresMotivation?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  requiresPincode?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationServiceStatus']>, ParentType, ContextType>;
  termsAndConditions?: Resolver<Maybe<IResolversTypes['TermsAndConditions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IRequiredFieldsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['RequiredFields'] = IResolversParentTypes['RequiredFields']> = ResolversObject<{
  descriptionAllowed?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  descriptionForMobilitycardTransactionBusiness?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  descriptionForMobilitycardTransactionCommute?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  descriptionForMobilitycardTransactionPersonal?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  descriptionForMobilitycardTransactions?: Resolver<Array<IResolversTypes['TravelPurposeEnum']>, ParentType, ContextType>;
  descriptionForRideByAmountBusiness?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  descriptionForRideByAmountCommute?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  descriptionForRideByAmountPersonal?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  descriptionForRideByDistanceBusiness?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  descriptionForRideByDistanceCommute?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  descriptionForRideByDistancePersonal?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  descriptionForRideTransactionBusiness?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  descriptionForRideTransactionCommute?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  descriptionForRideTransactionPersonal?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  descriptionForRideTransactions?: Resolver<Array<IResolversTypes['TravelPurposeEnum']>, ParentType, ContextType>;
  projectAllowed?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  projectForTravelpurposeBusiness?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  projectForTravelpurposeCommute?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  projectForTravelpurposePersonal?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  projectForTravelpurposes?: Resolver<Array<IResolversTypes['TravelPurposeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IReservationResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Reservation'] = IResolversParentTypes['Reservation']> = ResolversObject<{
  cancelledAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  endLocation?: Resolver<Maybe<IResolversTypes['GeoLocation']>, ParentType, ContextType>;
  endsAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<IResolversTypes['Icon']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  recordId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  reservationClass?: Resolver<IResolversTypes['ReservationClassEnum'], ParentType, ContextType>;
  reservationStatus?: Resolver<Maybe<IResolversTypes['ReservationStatus']>, ParentType, ContextType>;
  startLocation?: Resolver<Maybe<IResolversTypes['GeoLocation']>, ParentType, ContextType>;
  startsAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  status?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  vehicleLicensePlate?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IReservationStatusResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ReservationStatus'] = IResolversParentTypes['ReservationStatus']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['ReservationStatusValueEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IResetPasswordPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ResetPasswordPayload'] = IResolversParentTypes['ResetPasswordPayload']> = ResolversObject<{
  apiToken?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  me?: Resolver<Maybe<IResolversTypes['Employee']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IResultResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Result'] = IResolversParentTypes['Result']> = ResolversObject<{
  error?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IResultInterfaceResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ResultInterface'] = IResolversParentTypes['ResultInterface']> = ResolversObject<{
  __resolveType: TypeResolveFn<'DonkeyRepublicResult', ParentType, ContextType>;
  error?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
}>;

export type IResultWithAccessTokensResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ResultWithAccessTokens'] = IResolversParentTypes['ResultWithAccessTokens']> = ResolversObject<{
  error?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  tokens?: Resolver<Maybe<IResolversTypes['Auth']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IReuploadPhotoPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ReuploadPhotoPayload'] = IResolversParentTypes['ReuploadPhotoPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  profile?: Resolver<Maybe<IResolversTypes['Profile']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IRevokeDirectDebitMandatePayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['RevokeDirectDebitMandatePayload'] = IResolversParentTypes['RevokeDirectDebitMandatePayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  directDebitMandate?: Resolver<Maybe<IResolversTypes['DirectDebitMandate']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IRevokeSplitBillingContractPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['RevokeSplitBillingContractPayload'] = IResolversParentTypes['RevokeSplitBillingContractPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  revokedContracts?: Resolver<Maybe<Array<IResolversTypes['SplitBillingContract']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IRgbaResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Rgba'] = IResolversParentTypes['Rgba']> = ResolversObject<{
  alpha?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  blue?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  green?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  red?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IRightToBeForgottenReasonResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['RightToBeForgottenReason'] = IResolversParentTypes['RightToBeForgottenReason']> = ResolversObject<{
  description?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<IResolversTypes['RightToBeForgottenReasonEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IRouteResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Route'] = IResolversParentTypes['Route']> = ResolversObject<{
  diverted?: Resolver<Maybe<IResolversTypes['Diverted']>, ParentType, ContextType>;
  from?: Resolver<IResolversTypes['Location'], ParentType, ContextType>;
  label?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  nonCombined?: Resolver<Maybe<IResolversTypes['NonCombined']>, ParentType, ContextType>;
  to?: Resolver<IResolversTypes['Location'], ParentType, ContextType>;
  track?: Resolver<Maybe<Array<IResolversTypes['Coordinates']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IRouteMetadataResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['RouteMetadata'] = IResolversParentTypes['RouteMetadata']> = ResolversObject<{
  calculateDistance?: Resolver<IResolversTypes['CalculatedDistance'], ParentType, ContextType, RequireFields<IRouteMetadataCalculateDistanceArgs, 'from' | 'modalityId' | 'on' | 'to'>>;
  co2Emission?: Resolver<IResolversTypes['Co2Emission'], ParentType, ContextType, RequireFields<IRouteMetadataCo2EmissionArgs, 'from' | 'modalityId' | 'to'>>;
  co2EmissionFromDistance?: Resolver<IResolversTypes['Co2Emission'], ParentType, ContextType, RequireFields<IRouteMetadataCo2EmissionFromDistanceArgs, 'distance' | 'modalityId'>>;
  distance?: Resolver<IResolversTypes['CalculatedDistance'], ParentType, ContextType, RequireFields<IRouteMetadataDistanceArgs, 'from' | 'modalityId' | 'to'>>;
  distanceOverridable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  fixedCommuteDistanceEnabled?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  nonCombinedAllowed?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ISegmentPriceResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['SegmentPrice'] = IResolversParentTypes['SegmentPrice']> = ResolversObject<{
  amount?: Resolver<IResolversTypes['Money'], ParentType, ContextType>;
  currency?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  duration?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  fromMinutes?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  tillMinutes?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  vatPercentage?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ISendNewInvitationLinkPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['SendNewInvitationLinkPayload'] = IResolversParentTypes['SendNewInvitationLinkPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ISendPasswordResetMailPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['SendPasswordResetMailPayload'] = IResolversParentTypes['SendPasswordResetMailPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ISendPrivacyRequestMailPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['SendPrivacyRequestMailPayload'] = IResolversParentTypes['SendPrivacyRequestMailPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IServiceOperatorResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ServiceOperator'] = IResolversParentTypes['ServiceOperator']> = ResolversObject<{
  android?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  humanized?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  ios?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  orderPortal?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IServiceProviderResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ServiceProvider'] = IResolversParentTypes['ServiceProvider']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['ContractPaymentServiceProviderEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IServicesResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Services'] = IResolversParentTypes['Services']> = ResolversObject<{
  confirmGreenwheels?: Resolver<Maybe<IResolversTypes['ConfirmGreenwheelsPayload']>, ParentType, ContextType, RequireFields<IServicesConfirmGreenwheelsArgs, 'input'>>;
  request?: Resolver<Maybe<IResolversTypes['RequestServicePayload']>, ParentType, ContextType, RequireFields<IServicesRequestArgs, 'input'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IServicesChangeRequestResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ServicesChangeRequest'] = IResolversParentTypes['ServicesChangeRequest']> = ResolversObject<{
  approvedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  employee?: Resolver<IResolversTypes['Employee'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  motivation?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  personalMeansOfRegistration?: Resolver<IResolversTypes['PersonalMeansOfRegistration'], ParentType, ContextType>;
  rejectedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  services?: Resolver<Array<IResolversTypes['PersonalMeansOfRegistrationServiceKindSubEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ISetAgreeTermsPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['SetAgreeTermsPayload'] = IResolversParentTypes['SetAgreeTermsPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ISetManualFavoriteCoordinatesPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['SetManualFavoriteCoordinatesPayload'] = IResolversParentTypes['SetManualFavoriteCoordinatesPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  favorite?: Resolver<Maybe<IResolversTypes['Favorite']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ISetOptionsPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['SetOptionsPayload'] = IResolversParentTypes['SetOptionsPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  mobilityCardOrder?: Resolver<Maybe<IResolversTypes['MobilityCardOrder']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ISetPhotoPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['SetPhotoPayload'] = IResolversParentTypes['SetPhotoPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  mobilityCardOrder?: Resolver<Maybe<IResolversTypes['MobilityCardOrder']>, ParentType, ContextType>;
  profile?: Resolver<Maybe<IResolversTypes['Profile']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ISetProfilePayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['SetProfilePayload'] = IResolversParentTypes['SetProfilePayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  mobilityCardOrder?: Resolver<Maybe<IResolversTypes['MobilityCardOrder']>, ParentType, ContextType>;
  profile?: Resolver<Maybe<IResolversTypes['Profile']>, ParentType, ContextType>;
  result?: Resolver<Maybe<IResolversTypes['Result']>, ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ISetTermsPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['SetTermsPayload'] = IResolversParentTypes['SetTermsPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  mobilityCardOrder?: Resolver<Maybe<IResolversTypes['MobilityCardOrder']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ISettlementResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Settlement'] = IResolversParentTypes['Settlement']> = ResolversObject<{
  balance?: Resolver<Maybe<IResolversTypes['Balance']>, ParentType, ContextType>;
  committedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  financialSummary?: Resolver<Maybe<Array<IResolversTypes['FinancialSummary']>>, ParentType, ContextType>;
  grossAmount?: Resolver<IResolversTypes['Budget'], ParentType, ContextType>;
  happenedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  mobilityEvents?: Resolver<Maybe<Array<IResolversTypes['MobilityEvent']>>, ParentType, ContextType>;
  netAmount?: Resolver<IResolversTypes['Budget'], ParentType, ContextType>;
  organization?: Resolver<Maybe<IResolversTypes['Organization']>, ParentType, ContextType>;
  previousBalance?: Resolver<Maybe<IResolversTypes['Balance']>, ParentType, ContextType>;
  previousSettlement?: Resolver<Maybe<IResolversTypes['Settlement']>, ParentType, ContextType>;
  totalGrossAndNetCharge?: Resolver<IResolversTypes['Budget'], ParentType, ContextType>;
  totalGrossAndNetReimbursement?: Resolver<IResolversTypes['Budget'], ParentType, ContextType>;
  travelPurposes?: Resolver<Array<IResolversTypes['TravelPurposeFinancialConsequences']>, ParentType, ContextType>;
  type?: Resolver<Maybe<IResolversTypes['SettlementTypeFinancialConsequences']>, ParentType, ContextType>;
  withGrossBalance?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ISettlementTypeFinancialConsequencesResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['SettlementTypeFinancialConsequences'] = IResolversParentTypes['SettlementTypeFinancialConsequences']> = ResolversObject<{
  enumValue?: Resolver<Maybe<IResolversTypes['SettlementTypeFinancialConsequencesEnum']>, ParentType, ContextType>;
  humanized?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ISharedCarReservationResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['SharedCarReservation'] = IResolversParentTypes['SharedCarReservation']> = ResolversObject<{
  bookingId?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  cancelledAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  endsAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  fromLocation?: Resolver<IResolversTypes['GeoLocation'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  startable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  startsAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['SharedCarReservationStatus'], ParentType, ContextType>;
  vehicleBrand?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  vehicleKey?: Resolver<Maybe<IResolversTypes['JSON']>, ParentType, ContextType>;
  vehicleLicensePlate?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  vehicleModel?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  vehicleName?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ISharedCarReservationStatusResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['SharedCarReservationStatus'] = IResolversParentTypes['SharedCarReservationStatus']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['SharedVehicleReservationStatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ISharedVehicleResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['SharedVehicle'] = IResolversParentTypes['SharedVehicle']> = ResolversObject<{
  activatable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  agreedTermsAndConditions?: Resolver<Maybe<Array<IResolversTypes['TermsAndConditions']>>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  id?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  mobideskOwnershipToken?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  motivation?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<IResolversTypes['FeatureOption'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['FeatureOrderStatus'], ParentType, ContextType>;
  urls?: Resolver<Maybe<IResolversTypes['FeatureUrls']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ISharedVehicleReservationResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['SharedVehicleReservation'] = IResolversParentTypes['SharedVehicleReservation']> = ResolversObject<{
  bookingId?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  cancelledAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  endsAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  fromLocation?: Resolver<IResolversTypes['GeoLocation'], ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  modality?: Resolver<IResolversTypes['Modality'], ParentType, ContextType>;
  reservationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  startable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  startsAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['SharedCarReservationStatus'], ParentType, ContextType>;
  vehicleBrand?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  vehicleKey?: Resolver<Maybe<IResolversTypes['JSON']>, ParentType, ContextType>;
  vehicleLicensePlate?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  vehicleModel?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  vehicleName?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IShuttelCheckResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ShuttelCheck'] = IResolversParentTypes['ShuttelCheck']> = ResolversObject<{
  activatable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  agreedTermsAndConditions?: Resolver<Maybe<Array<IResolversTypes['TermsAndConditions']>>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  id?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  mobideskOwnershipToken?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  motivation?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<IResolversTypes['FeatureOption'], ParentType, ContextType>;
  promocode?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  promocodeUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<IResolversTypes['FeatureOrderStatus'], ParentType, ContextType>;
  urls?: Resolver<Maybe<IResolversTypes['FeatureUrls']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IShuttelMyWheelsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ShuttelMyWheels'] = IResolversParentTypes['ShuttelMyWheels']> = ResolversObject<{
  activatable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  agreedTermsAndConditions?: Resolver<Maybe<Array<IResolversTypes['TermsAndConditions']>>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  id?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  mobideskOwnershipToken?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  motivation?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<IResolversTypes['FeatureOption'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['FeatureOrderStatus'], ParentType, ContextType>;
  urls?: Resolver<Maybe<IResolversTypes['FeatureUrls']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IShuttelSixtResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ShuttelSixt'] = IResolversParentTypes['ShuttelSixt']> = ResolversObject<{
  activatable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  agreedTermsAndConditions?: Resolver<Maybe<Array<IResolversTypes['TermsAndConditions']>>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  id?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  mobideskOwnershipToken?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  motivation?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<IResolversTypes['FeatureOption'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['FeatureOrderStatus'], ParentType, ContextType>;
  urls?: Resolver<Maybe<IResolversTypes['FeatureUrls']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IShuttelTaxiResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ShuttelTaxi'] = IResolversParentTypes['ShuttelTaxi']> = ResolversObject<{
  activatable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  agreedTermsAndConditions?: Resolver<Maybe<Array<IResolversTypes['TermsAndConditions']>>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  id?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  mobideskOwnershipToken?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  motivation?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<IResolversTypes['FeatureOption'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['FeatureOrderStatus'], ParentType, ContextType>;
  urls?: Resolver<Maybe<IResolversTypes['FeatureUrls']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IShuttelTranzerEticketResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ShuttelTranzerEticket'] = IResolversParentTypes['ShuttelTranzerEticket']> = ResolversObject<{
  activatable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  agreedTermsAndConditions?: Resolver<Maybe<Array<IResolversTypes['TermsAndConditions']>>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  id?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  mobideskOwnershipToken?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  motivation?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<IResolversTypes['FeatureOption'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['FeatureOrderStatus'], ParentType, ContextType>;
  urls?: Resolver<Maybe<IResolversTypes['FeatureUrls']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IShuttelVecoreResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ShuttelVecore'] = IResolversParentTypes['ShuttelVecore']> = ResolversObject<{
  activatable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  agreedTermsAndConditions?: Resolver<Maybe<Array<IResolversTypes['TermsAndConditions']>>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  id?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  mobideskOwnershipToken?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  motivation?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<IResolversTypes['FeatureOption'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['FeatureOrderStatus'], ParentType, ContextType>;
  urls?: Resolver<Maybe<IResolversTypes['FeatureUrls']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ISignatureResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Signature'] = IResolversParentTypes['Signature']> = ResolversObject<{
  approvalLevel?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  mobilityEvent?: Resolver<Maybe<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  signer?: Resolver<Maybe<IResolversTypes['Employee']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ISplitBillingContractResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['SplitBillingContract'] = IResolversParentTypes['SplitBillingContract']> = ResolversObject<{
  accessUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  actions?: Resolver<IResolversTypes['ContractActions'], ParentType, ContextType>;
  activeAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  assignedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  brand?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  cancelledAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  debtorNumber?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  emailAddressType?: Resolver<IResolversTypes['SplitBillingContractEmailAddress'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  invitedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  mobideskEntityToken?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  parentDebtorNumber?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  paymentServiceProvider?: Resolver<IResolversTypes['ServiceProvider'], ParentType, ContextType>;
  requestedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  splitBillingContractId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<IResolversTypes['ContractStatus'], ParentType, ContextType>;
  statusReason?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  suspendedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ISplitBillingContractEmailAddressResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['SplitBillingContractEmailAddress'] = IResolversParentTypes['SplitBillingContractEmailAddress']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['SplitBillingContractEmailAddressEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ISplitBillingContractsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['SplitBillingContracts'] = IResolversParentTypes['SplitBillingContracts']> = ResolversObject<{
  getAccessUrl?: Resolver<Maybe<IResolversTypes['GetAccessUrlPayload']>, ParentType, ContextType, RequireFields<ISplitBillingContractsGetAccessUrlArgs, 'input'>>;
  revoke?: Resolver<Maybe<IResolversTypes['RevokeSplitBillingContractPayload']>, ParentType, ContextType, RequireFields<ISplitBillingContractsRevokeArgs, 'input'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ISplitBillingInvoiceResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['SplitBillingInvoice'] = IResolversParentTypes['SplitBillingInvoice']> = ResolversObject<{
  amount?: Resolver<Maybe<IResolversTypes['Money']>, ParentType, ContextType>;
  externalReference?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  invoiceDate?: Resolver<IResolversTypes['Date'], ParentType, ContextType>;
  pdfUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  transactionsReport?: Resolver<Maybe<IResolversTypes['TransactionsReport']>, ParentType, ContextType>;
  ublUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ISsoEnabledResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['SsoEnabled'] = IResolversParentTypes['SsoEnabled']> = ResolversObject<{
  ssoEnabled?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  ssoHost?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IStarValueResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['StarValue'] = IResolversParentTypes['StarValue']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['StarValueEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IStartDonkeyRepublicReservationPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['StartDonkeyRepublicReservationPayload'] = IResolversParentTypes['StartDonkeyRepublicReservationPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  donkeyRepublicReservation?: Resolver<Maybe<IResolversTypes['DonkeyRepublicReservation']>, ParentType, ContextType>;
  donkeyRepublicResult?: Resolver<Maybe<IResolversTypes['DonkeyRepublicResult']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['DonkeyRepublicResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IStartSharedCarReservationPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['StartSharedCarReservationPayload'] = IResolversParentTypes['StartSharedCarReservationPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  sharedCarReservation?: Resolver<IResolversTypes['SharedCarReservation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IStartSharedVehicleReservationPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['StartSharedVehicleReservationPayload'] = IResolversParentTypes['StartSharedVehicleReservationPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  sharedVehicleReservation?: Resolver<IResolversTypes['SharedVehicleReservation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IStateTransitionsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['StateTransitions'] = IResolversParentTypes['StateTransitions']> = ResolversObject<{
  acceptedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  confirmedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  confirmer?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  rejectReason?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  rejectedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IStationResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Station'] = IResolversParentTypes['Station']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IStatisticsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Statistics'] = IResolversParentTypes['Statistics']> = ResolversObject<{
  budgetBalance?: Resolver<IResolversTypes['Budget'], ParentType, ContextType>;
  leaseCar?: Resolver<IResolversTypes['LeaseCar'], ParentType, ContextType>;
  personalBalance?: Resolver<IResolversTypes['Settlement'], ParentType, ContextType>;
  settlement?: Resolver<Maybe<IResolversTypes['Settlement']>, ParentType, ContextType>;
  travelBehaviour?: Resolver<IResolversTypes['TravelBehaviour'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IStatsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Stats'] = IResolversParentTypes['Stats']> = ResolversObject<{
  totalCo2EmissionInGrams?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  totalDistanceInMeters?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  transportType?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IStatsPerModalityResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['StatsPerModality'] = IResolversParentTypes['StatsPerModality']> = ResolversObject<{
  co2PerKm?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  totalCo2?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  totalKms?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IStatusResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Status'] = IResolversParentTypes['Status']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['StatusWithConfirmedEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IStreetAddressResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['StreetAddress'] = IResolversParentTypes['StreetAddress']> = ResolversObject<{
  city?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  streetNumber?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  zipCode?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IStudentTravelProductResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['StudentTravelProduct'] = IResolversParentTypes['StudentTravelProduct']> = ResolversObject<{
  addToWhitelist?: Resolver<Maybe<IResolversTypes['AddToWhitelistPayload']>, ParentType, ContextType, RequireFields<IStudentTravelProductAddToWhitelistArgs, 'input'>>;
  removeFromWhitelist?: Resolver<Maybe<IResolversTypes['RemoveFromWhitelistPayload']>, ParentType, ContextType, RequireFields<IStudentTravelProductRemoveFromWhitelistArgs, 'input'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ISubCategoryResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['SubCategory'] = IResolversParentTypes['SubCategory']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ISubordinateResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Subordinate'] = IResolversParentTypes['Subordinate']> = ResolversObject<{
  acceptableMobilityEvents?: Resolver<Array<IResolversTypes['MobilityEvent']>, ParentType, ContextType, Partial<ISubordinateAcceptableMobilityEventsArgs>>;
  acceptedMobilityEvents?: Resolver<Array<IResolversTypes['MobilityEvent']>, ParentType, ContextType, Partial<ISubordinateAcceptedMobilityEventsArgs>>;
  fullName?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  numAcceptableMobilityEvents?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ISubscriptionResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Subscription'] = IResolversParentTypes['Subscription']> = ResolversObject<{
  humanized?: SubscriptionResolver<IResolversTypes['String'], "humanized", ParentType, ContextType>;
  value?: SubscriptionResolver<IResolversTypes['SubscriptionEnum'], "value", ParentType, ContextType>;
}>;

export type ISupportedVersionResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['SupportedVersion'] = IResolversParentTypes['SupportedVersion']> = ResolversObject<{
  isSupported?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType, RequireFields<ISupportedVersionIsSupportedArgs, 'version'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITaskResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Task'] = IResolversParentTypes['Task']> = ResolversObject<{
  __resolveType: TypeResolveFn<'ActionRequired' | 'ActivatableDongle' | 'CompensationRideToBeCreated' | 'MobilityEventsWeek' | 'NewProduct' | 'PendingApproval' | 'RefundToBeLinked', ParentType, ContextType>;
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  path?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<IResolversTypes['TaskEnum'], ParentType, ContextType>;
}>;

export type ITaxiResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Taxi'] = IResolversParentTypes['Taxi']> = ResolversObject<{
  cancel?: Resolver<Maybe<IResolversTypes['CancelTaxiReservationPayload']>, ParentType, ContextType, RequireFields<ITaxiCancelArgs, 'input'>>;
  confirm?: Resolver<Maybe<IResolversTypes['ConfirmTaxiReservationPayload']>, ParentType, ContextType, RequireFields<ITaxiConfirmArgs, 'input'>>;
  create?: Resolver<Maybe<IResolversTypes['CreateTaxiReservationPayload']>, ParentType, ContextType, RequireFields<ITaxiCreateArgs, 'input'>>;
  update?: Resolver<Maybe<IResolversTypes['UpdateTaxiReservationPayload']>, ParentType, ContextType, RequireFields<ITaxiUpdateArgs, 'input'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITaxiFeatureResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['TaxiFeature'] = IResolversParentTypes['TaxiFeature']> = ResolversObject<{
  activatable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  agreedTermsAndConditions?: Resolver<Maybe<Array<IResolversTypes['TermsAndConditions']>>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  id?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  mobideskOwnershipToken?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  motivation?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<IResolversTypes['FeatureOption'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['FeatureOrderStatus'], ParentType, ContextType>;
  urls?: Resolver<Maybe<IResolversTypes['FeatureUrls']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITaxiReservationResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['TaxiReservation'] = IResolversParentTypes['TaxiReservation']> = ResolversObject<{
  cancellable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  cancelledAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  contactPhoneNumber?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  departAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  departure?: Resolver<IResolversTypes['Location'], ParentType, ContextType>;
  destination?: Resolver<IResolversTypes['Location'], ParentType, ContextType>;
  driver?: Resolver<Maybe<IResolversTypes['Driver']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  passengerCount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  price?: Resolver<Maybe<IResolversTypes['Money']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  requestedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<Maybe<IResolversTypes['TaxiStatus']>, ParentType, ContextType>;
  vehicle?: Resolver<Maybe<IResolversTypes['TaxiReservationVehicle']>, ParentType, ContextType>;
  vehicleType?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITaxiReservationVehicleResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['TaxiReservationVehicle'] = IResolversParentTypes['TaxiReservationVehicle']> = ResolversObject<{
  brand?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  licensePlate?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITaxiStatusResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['TaxiStatus'] = IResolversParentTypes['TaxiStatus']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITerminateOwnershipPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['TerminateOwnershipPayload'] = IResolversParentTypes['TerminateOwnershipPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITerminatePersonalMeansOfRegistrationPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['TerminatePersonalMeansOfRegistrationPayload'] = IResolversParentTypes['TerminatePersonalMeansOfRegistrationPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  personalMeansOfRegistration?: Resolver<IResolversTypes['PersonalMeansOfRegistration'], ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITerminateReplacementManagerPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['TerminateReplacementManagerPayload'] = IResolversParentTypes['TerminateReplacementManagerPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITerminateVehiclePayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['TerminateVehiclePayload'] = IResolversParentTypes['TerminateVehiclePayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  vehicle?: Resolver<IResolversTypes['MeansOfTransport'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITermsAndConditionsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['TermsAndConditions'] = IResolversParentTypes['TermsAndConditions']> = ResolversObject<{
  agreed?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  agreedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  document?: Resolver<IResolversTypes['TermsAndConditionsDocument'], ParentType, ContextType>;
  requiresAgreement?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITermsAndConditionsDocumentResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['TermsAndConditionsDocument'] = IResolversParentTypes['TermsAndConditionsDocument']> = ResolversObject<{
  humanizedKind?: Resolver<IResolversTypes['TermsAndConditionsDocumentHumanizedKind'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<IResolversTypes['TermsAndConditionsDocumentKindEnum'], ParentType, ContextType>;
  pdfUrl?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITermsAndConditionsDocumentHumanizedKindResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['TermsAndConditionsDocumentHumanizedKind'] = IResolversParentTypes['TermsAndConditionsDocumentHumanizedKind']> = ResolversObject<{
  humanized?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<IResolversTypes['TermsAndConditionsDocumentKindEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IThemeResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Theme'] = IResolversParentTypes['Theme']> = ResolversObject<{
  icon?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType, Partial<IThemeIconArgs>>;
  loginImage?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType, Partial<IThemeLoginImageArgs>>;
  logo?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType, Partial<IThemeLogoArgs>>;
  primaryColor?: Resolver<Maybe<IResolversTypes['Color']>, ParentType, ContextType>;
  secondaryColor?: Resolver<Maybe<IResolversTypes['Color']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITicketResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Ticket'] = IResolversParentTypes['Ticket']> = ResolversObject<{
  barcode?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  conditionsOneliner?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  conditionsUrl?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  media?: Resolver<Array<IResolversTypes['TicketMedia']>, ParentType, ContextType>;
  price?: Resolver<IResolversTypes['Price'], ParentType, ContextType>;
  productName?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  ticketClass?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  ticketName?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  validFrom?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  validTill?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITicketMediaResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['TicketMedia'] = IResolversParentTypes['TicketMedia']> = ResolversObject<{
  back?: Resolver<Maybe<IResolversTypes['MediaSide']>, ParentType, ContextType>;
  displayType?: Resolver<Maybe<IResolversTypes['Display']>, ParentType, ContextType>;
  front?: Resolver<Maybe<IResolversTypes['MediaSide']>, ParentType, ContextType>;
  type?: Resolver<IResolversTypes['Media'], ParentType, ContextType>;
  url?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITimestampResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Timestamp'] = IResolversParentTypes['Timestamp']> = ResolversObject<{
  at?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  editable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITotalsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Totals'] = IResolversParentTypes['Totals']> = ResolversObject<{
  amount?: Resolver<IResolversTypes['Money'], ParentType, ContextType>;
  count?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  distance?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITrainClassResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['TrainClass'] = IResolversParentTypes['TrainClass']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['TrainClassEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITrainClassChangeResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['TrainClassChange'] = IResolversParentTypes['TrainClassChange']> = ResolversObject<{
  canRequestTrainClassChange?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  firstClassReasons?: Resolver<Maybe<Array<IResolversTypes['FirstClassReason']>>, ParentType, ContextType>;
  requestableTrainClass?: Resolver<Maybe<IResolversTypes['TrainClass']>, ParentType, ContextType>;
  requiresTrainClassChangeMotivation?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  trainClassStatus?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITrainClassChangeRequestResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['TrainClassChangeRequest'] = IResolversParentTypes['TrainClassChangeRequest']> = ResolversObject<{
  approvedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  employee?: Resolver<IResolversTypes['Employee'], ParentType, ContextType>;
  firstClassReason?: Resolver<Maybe<IResolversTypes['FirstClassReason']>, ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  motivation?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  rejectedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  subscription?: Resolver<IResolversTypes['PersonalMeansOfRegistrationService'], ParentType, ContextType>;
  trainClass?: Resolver<IResolversTypes['TrainClass'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITrainClassOptionsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['TrainClassOptions'] = IResolversParentTypes['TrainClassOptions']> = ResolversObject<{
  firstClassReasons?: Resolver<Maybe<Array<IResolversTypes['FirstClassReason']>>, ParentType, ContextType>;
  requestableTrainClasses?: Resolver<Maybe<Array<IResolversTypes['TrainClass']>>, ParentType, ContextType>;
  requiresMotivation?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITrajectResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Traject'] = IResolversParentTypes['Traject']> = ResolversObject<{
  from?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  to?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITransactionsReportResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['TransactionsReport'] = IResolversParentTypes['TransactionsReport']> = ResolversObject<{
  pdfUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  xlsUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITranzerResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Tranzer'] = IResolversParentTypes['Tranzer']> = ResolversObject<{
  create?: Resolver<Maybe<IResolversTypes['CreateTranzerReservationPayload']>, ParentType, ContextType, RequireFields<ITranzerCreateArgs, 'input'>>;
  refresh?: Resolver<Maybe<IResolversTypes['RefreshTranzerReservationPayload']>, ParentType, ContextType, RequireFields<ITranzerRefreshArgs, 'input'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITranzerEticketResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['TranzerEticket'] = IResolversParentTypes['TranzerEticket']> = ResolversObject<{
  activatable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  agreedTermsAndConditions?: Resolver<Maybe<Array<IResolversTypes['TermsAndConditions']>>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  id?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  mobideskOwnershipToken?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  motivation?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<IResolversTypes['FeatureOption'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['FeatureOrderStatus'], ParentType, ContextType>;
  urls?: Resolver<Maybe<IResolversTypes['FeatureUrls']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITranzerLegResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['TranzerLeg'] = IResolversParentTypes['TranzerLeg']> = ResolversObject<{
  agency?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  agencyLogo?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  arrivalPlatform?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  bookingId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  co2Emission?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
  departurePlatform?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  endTime?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  fromLocation?: Resolver<IResolversTypes['GeoLocation'], ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  modality?: Resolver<IResolversTypes['TravelMode'], ParentType, ContextType>;
  polyline?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  quoteId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  service?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  startTime?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  tickets?: Resolver<Array<IResolversTypes['Ticket']>, ParentType, ContextType>;
  toLocation?: Resolver<IResolversTypes['GeoLocation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITranzerReservationResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['TranzerReservation'] = IResolversParentTypes['TranzerReservation']> = ResolversObject<{
  createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  endTime?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  externalId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
  legs?: Resolver<Maybe<Array<IResolversTypes['TranzerLeg']>>, ParentType, ContextType>;
  startTime?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  status?: Resolver<Maybe<IResolversTypes['TranzerStatus']>, ParentType, ContextType>;
  totalCosts?: Resolver<IResolversTypes['Money'], ParentType, ContextType>;
  totalDuration?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITranzerStatusResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['TranzerStatus'] = IResolversParentTypes['TranzerStatus']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['TranzerStatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITravelBehaviourResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['TravelBehaviour'] = IResolversParentTypes['TravelBehaviour']> = ResolversObject<{
  business?: Resolver<Maybe<IResolversTypes['Purpose']>, ParentType, ContextType>;
  commute?: Resolver<Maybe<IResolversTypes['Purpose']>, ParentType, ContextType>;
  personal?: Resolver<Maybe<IResolversTypes['Purpose']>, ParentType, ContextType>;
  usageForNorm?: Resolver<Maybe<IResolversTypes['Usage']>, ParentType, ContextType>;
  usageRemaining?: Resolver<Maybe<IResolversTypes['Usage']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITravelModeResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['TravelMode'] = IResolversParentTypes['TravelMode']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['TravelModeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITravelPurposeResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['TravelPurpose'] = IResolversParentTypes['TravelPurpose']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Maybe<IResolversTypes['TravelPurposeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITravelPurposeFinancialConsequencesResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['TravelPurposeFinancialConsequences'] = IResolversParentTypes['TravelPurposeFinancialConsequences']> = ResolversObject<{
  totalGrossAndNetCharge?: Resolver<IResolversTypes['Budget'], ParentType, ContextType>;
  totalGrossAndNetReimbursement?: Resolver<IResolversTypes['Budget'], ParentType, ContextType>;
  travelPurpose?: Resolver<IResolversTypes['TravelPurposeWithUnknown'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ITravelPurposeWithUnknownResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['TravelPurposeWithUnknown'] = IResolversParentTypes['TravelPurposeWithUnknown']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Maybe<IResolversTypes['TravelPurposeWithUnknownEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface IUuidScalarConfig extends GraphQLScalarTypeConfig<IResolversTypes['UUID'], any> {
  name: 'UUID';
}

export type IUnacceptMobilityEventPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['UnacceptMobilityEventPayload'] = IResolversParentTypes['UnacceptMobilityEventPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  mobilityEvent?: Resolver<Maybe<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IUnacceptMobilityEventsPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['UnacceptMobilityEventsPayload'] = IResolversParentTypes['UnacceptMobilityEventsPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  notUnacceptedMobilityEvents?: Resolver<Array<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  unacceptedMobilityEvents?: Resolver<Array<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IUnconfirmMobilityEventPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['UnconfirmMobilityEventPayload'] = IResolversParentTypes['UnconfirmMobilityEventPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  mobilityEvent?: Resolver<Maybe<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IUnconfirmMobilityEventsPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['UnconfirmMobilityEventsPayload'] = IResolversParentTypes['UnconfirmMobilityEventsPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  notUnconfirmedMobilityEvents?: Resolver<Array<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  unconfirmedMobilityEvents?: Resolver<Array<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IUnlockDonkeyRepublicAssetPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['UnlockDonkeyRepublicAssetPayload'] = IResolversParentTypes['UnlockDonkeyRepublicAssetPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  donkeyRepublicReservation?: Resolver<Maybe<IResolversTypes['DonkeyRepublicReservation']>, ParentType, ContextType>;
  donkeyRepublicResult?: Resolver<Maybe<IResolversTypes['DonkeyRepublicResult']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['DonkeyRepublicResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IUpdateFavoritePayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['UpdateFavoritePayload'] = IResolversParentTypes['UpdateFavoritePayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  favorite?: Resolver<Maybe<IResolversTypes['Favorite']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IUpdateMileagePayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['UpdateMileagePayload'] = IResolversParentTypes['UpdateMileagePayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  mileage?: Resolver<Maybe<IResolversTypes['Mileage']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IUpdateMobilityEventPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['UpdateMobilityEventPayload'] = IResolversParentTypes['UpdateMobilityEventPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  mobilityEvent?: Resolver<Maybe<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IUpdateMobilityEventsPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['UpdateMobilityEventsPayload'] = IResolversParentTypes['UpdateMobilityEventsPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  days?: Resolver<Array<IResolversTypes['DayWithMobilityEvents']>, ParentType, ContextType>;
  notUpdatedMobilityEvents?: Resolver<Array<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  updatedMobilityEvents?: Resolver<Array<IResolversTypes['MobilityEvent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IUpdateTaxiReservationPayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['UpdateTaxiReservationPayload'] = IResolversParentTypes['UpdateTaxiReservationPayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  taxiReservation?: Resolver<Maybe<IResolversTypes['TaxiReservation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IUpdateUserProfilePayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['UpdateUserProfilePayload'] = IResolversParentTypes['UpdateUserProfilePayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  me?: Resolver<Maybe<IResolversTypes['Employee']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IUpdateVehiclePayloadResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['UpdateVehiclePayload'] = IResolversParentTypes['UpdateVehiclePayload']> = ResolversObject<{
  clientMutationId?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<IResolversTypes['Result'], ParentType, ContextType>;
  validationErrors?: Resolver<Array<IResolversTypes['ValidationError']>, ParentType, ContextType>;
  vehicle?: Resolver<Maybe<IResolversTypes['MeansOfTransport']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IUsageResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Usage'] = IResolversParentTypes['Usage']> = ResolversObject<{
  co2EmissionInGrams?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  distanceInMeters?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IV2BudgetGroupResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['V2BudgetGroup'] = IResolversParentTypes['V2BudgetGroup']> = ResolversObject<{
  id?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IV2OrganizationResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['V2Organization'] = IResolversParentTypes['V2Organization']> = ResolversObject<{
  budgetGroups?: Resolver<Maybe<Array<IResolversTypes['V2BudgetGroup']>>, ParentType, ContextType>;
  id?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IValidationErrorResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['ValidationError'] = IResolversParentTypes['ValidationError']> = ResolversObject<{
  attribute?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  fullMessages?: Resolver<Array<IResolversTypes['String']>, ParentType, ContextType>;
  messages?: Resolver<Array<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IVatRateResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['VatRate'] = IResolversParentTypes['VatRate']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IVehicleResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Vehicle'] = IResolversParentTypes['Vehicle']> = ResolversObject<{
  create?: Resolver<Maybe<IResolversTypes['CreateVehiclePayload']>, ParentType, ContextType, RequireFields<IVehicleCreateArgs, 'input'>>;
  terminate?: Resolver<Maybe<IResolversTypes['TerminateVehiclePayload']>, ParentType, ContextType, RequireFields<IVehicleTerminateArgs, 'input'>>;
  update?: Resolver<Maybe<IResolversTypes['UpdateVehiclePayload']>, ParentType, ContextType, RequireFields<IVehicleUpdateArgs, 'input'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IVehicleActionsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['VehicleActions'] = IResolversParentTypes['VehicleActions']> = ResolversObject<{
  editable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  terminatable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IVehicleAssetResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['VehicleAsset'] = IResolversParentTypes['VehicleAsset']> = ResolversObject<{
  batteryLevel?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  deeplink?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  geoLocation?: Resolver<IResolversTypes['Coordinates'], ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  id?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  modality?: Resolver<IResolversTypes['JourneyMode'], ParentType, ContextType>;
  name?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  operator?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  range?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  serviceOperator?: Resolver<Maybe<IResolversTypes['ServiceOperator']>, ParentType, ContextType>;
  type?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  vehicleTypeName?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IVehicleInformationResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['VehicleInformation'] = IResolversParentTypes['VehicleInformation']> = ResolversObject<{
  brand?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  co2EmissionPerKilometer?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  energyLabel?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  fuelType?: Resolver<Maybe<IResolversTypes['Fuel']>, ParentType, ContextType>;
  model?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IVehicleModalityKindResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['VehicleModalityKind'] = IResolversParentTypes['VehicleModalityKind']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['ModalityKindEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IVehicleOptionsResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['VehicleOptions'] = IResolversParentTypes['VehicleOptions']> = ResolversObject<{
  label?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IWhatResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['What'] = IResolversParentTypes['What']> = ResolversObject<{
  category?: Resolver<IResolversTypes['Category'], ParentType, ContextType>;
  editable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  meansOfTransport?: Resolver<Maybe<IResolversTypes['MeansOfTransport']>, ParentType, ContextType>;
  modality?: Resolver<Maybe<IResolversTypes['Modality']>, ParentType, ContextType>;
  reimbursableCategoryMethod?: Resolver<IResolversTypes['ReimbursableCategoryMethod'], ParentType, ContextType>;
  sharedRidePassengers?: Resolver<Maybe<Array<IResolversTypes['String']>>, ParentType, ContextType>;
  vehicle?: Resolver<Maybe<IResolversTypes['MeansOfTransport']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IWhatOptionResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['WhatOption'] = IResolversParentTypes['WhatOption']> = ResolversObject<{
  category?: Resolver<IResolversTypes['Category'], ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  modality?: Resolver<Maybe<IResolversTypes['Modality']>, ParentType, ContextType>;
  reimbursableCategoryMethod?: Resolver<Maybe<IResolversTypes['ReimbursableCategoryMethod']>, ParentType, ContextType>;
  vehicle?: Resolver<Maybe<IResolversTypes['MeansOfTransport']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IWhenResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['When'] = IResolversParentTypes['When']> = ResolversObject<{
  __resolveType: TypeResolveFn<'Duration' | 'Timestamp', ParentType, ContextType>;
  editable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
}>;

export type IWhereResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Where'] = IResolversParentTypes['Where']> = ResolversObject<{
  __resolveType: TypeResolveFn<'Location' | 'RentalLocations' | 'Route', ParentType, ContextType>;
}>;

export type IWhyResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Why'] = IResolversParentTypes['Why']> = ResolversObject<{
  diverted?: Resolver<Maybe<IResolversTypes['Diverted']>, ParentType, ContextType>;
  editable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  explanation?: Resolver<Maybe<IResolversTypes['Explanation']>, ParentType, ContextType>;
  humanizedRefundedMobilityEvent?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  legs?: Resolver<Maybe<Array<IResolversTypes['Leg']>>, ParentType, ContextType>;
  personalDistance?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  project?: Resolver<Maybe<IResolversTypes['Project']>, ParentType, ContextType>;
  refundedMobilityEventId?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  stateTransitions?: Resolver<IResolversTypes['StateTransitions'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['WhyStatus'], ParentType, ContextType>;
  travelPurpose?: Resolver<IResolversTypes['TravelPurpose'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IWhyStatusResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['WhyStatus'] = IResolversParentTypes['WhyStatus']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['StatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IWrpmResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Wrpm'] = IResolversParentTypes['Wrpm']> = ResolversObject<{
  modality?: Resolver<Maybe<IResolversTypes['WrpmModality']>, ParentType, ContextType>;
  statsPerModality?: Resolver<Maybe<IResolversTypes['StatsPerModality']>, ParentType, ContextType>;
  statsPerModalityBusiness?: Resolver<Maybe<IResolversTypes['StatsPerModality']>, ParentType, ContextType>;
  statsPerModalityCommute?: Resolver<Maybe<IResolversTypes['StatsPerModality']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IWrpmModalityResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['WrpmModality'] = IResolversParentTypes['WrpmModality']> = ResolversObject<{
  humanized?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<IResolversTypes['WrpmModalityEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IYellowbrickServiceResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['YellowbrickService'] = IResolversParentTypes['YellowbrickService']> = ResolversObject<{
  identity?: Resolver<Maybe<IResolversTypes['PersonalMeansOfRegistrationIdentity']>, ParentType, ContextType>;
  type?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IYellowbrickSpResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['YellowbrickSp'] = IResolversParentTypes['YellowbrickSp']> = ResolversObject<{
  activatable?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
  agreedTermsAndConditions?: Resolver<Maybe<Array<IResolversTypes['TermsAndConditions']>>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  icon?: Resolver<IResolversTypes['Icon'], ParentType, ContextType>;
  id?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
  mobideskOwnershipToken?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
  motivation?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<IResolversTypes['FeatureOption'], ParentType, ContextType>;
  status?: Resolver<IResolversTypes['FeatureOrderStatus'], ParentType, ContextType>;
  urls?: Resolver<Maybe<IResolversTypes['FeatureUrls']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IZoneResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['Zone'] = IResolversParentTypes['Zone']> = ResolversObject<{
  humanized?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IReisbalansMutationResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['reisbalansMutation'] = IResolversParentTypes['reisbalansMutation']> = ResolversObject<{
  acceptMobilityEvents?: Resolver<Maybe<IResolversTypes['AcceptMobilityEventsPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationAcceptMobilityEventsArgs, 'input'>>;
  activateEnecoDongle?: Resolver<Maybe<IResolversTypes['ActivateEnecoDonglePayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationActivateEnecoDongleArgs, 'input'>>;
  activatePersonalMeansOfRegistration?: Resolver<Maybe<IResolversTypes['ActivatePersonalMeansOfRegistrationPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationActivatePersonalMeansOfRegistrationArgs, 'input'>>;
  addPushNotificationToken?: Resolver<Maybe<IResolversTypes['AddPushNotificationTokenPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationAddPushNotificationTokenArgs, 'input'>>;
  adminLogin?: Resolver<Maybe<IResolversTypes['AdminLoginPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationAdminLoginArgs, 'input'>>;
  assignReplacementManager?: Resolver<Maybe<IResolversTypes['AssignReplacementManagerPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationAssignReplacementManagerArgs, 'input'>>;
  cancelSharedCarReservation?: Resolver<Maybe<IResolversTypes['CancelSharedCarReservationPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationCancelSharedCarReservationArgs, 'input'>>;
  cancelSharedVehicleReservation?: Resolver<Maybe<IResolversTypes['CancelSharedVehicleReservationPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationCancelSharedVehicleReservationArgs, 'input'>>;
  cancelTaxiReservation?: Resolver<Maybe<IResolversTypes['CancelTaxiReservationPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationCancelTaxiReservationArgs, 'input'>>;
  configureMfa?: Resolver<Maybe<IResolversTypes['ConfigureMfaPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationConfigureMfaArgs, 'input'>>;
  confirmMobilityEvent?: Resolver<Maybe<IResolversTypes['ConfirmMobilityEventPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationConfirmMobilityEventArgs, 'input'>>;
  confirmMobilityEvents?: Resolver<Maybe<IResolversTypes['ConfirmMobilityEventsPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationConfirmMobilityEventsArgs, 'input'>>;
  confirmSharedCarReservation?: Resolver<Maybe<IResolversTypes['ConfirmSharedCarReservationPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationConfirmSharedCarReservationArgs, 'input'>>;
  confirmSharedVehicleReservation?: Resolver<Maybe<IResolversTypes['ConfirmSharedVehicleReservationPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationConfirmSharedVehicleReservationArgs, 'input'>>;
  confirmTaxiReservation?: Resolver<Maybe<IResolversTypes['ConfirmTaxiReservationPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationConfirmTaxiReservationArgs, 'input'>>;
  copyMobilityEvents?: Resolver<Maybe<IResolversTypes['CopyMobilityEventsPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationCopyMobilityEventsArgs, 'input'>>;
  copyMobilityEventsForDay?: Resolver<Maybe<IResolversTypes['CopyMobilityEventsForDayPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationCopyMobilityEventsForDayArgs, 'input'>>;
  createCommuteCompensation?: Resolver<Maybe<IResolversTypes['CreateCommuteCompensationPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationCreateCommuteCompensationArgs, 'input'>>;
  createCommuteRides?: Resolver<Maybe<IResolversTypes['CreateCommuteRidesPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationCreateCommuteRidesArgs, 'input'>>;
  createCompensationMobilityEvent?: Resolver<Maybe<IResolversTypes['CreateCompensationMobilityEventPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationCreateCompensationMobilityEventArgs, 'input'>>;
  createMobilityEvent?: Resolver<Maybe<IResolversTypes['CreateMobilityEventPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationCreateMobilityEventArgs, 'input'>>;
  createTaxiReservation?: Resolver<Maybe<IResolversTypes['CreateTaxiReservationPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationCreateTaxiReservationArgs, 'input'>>;
  createUserProfile?: Resolver<Maybe<IResolversTypes['CreateUserProfilePayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationCreateUserProfileArgs, 'input'>>;
  createVehicle?: Resolver<Maybe<IResolversTypes['CreateVehiclePayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationCreateVehicleArgs, 'input'>>;
  createWorkFromHome?: Resolver<Maybe<IResolversTypes['CreateWorkFromHomePayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationCreateWorkFromHomeArgs, 'input'>>;
  destroyMobilityEvent?: Resolver<Maybe<IResolversTypes['DestroyMobilityEventPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationDestroyMobilityEventArgs, 'input'>>;
  destroyMobilityEvents?: Resolver<Maybe<IResolversTypes['DestroyMobilityEventsPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationDestroyMobilityEventsArgs, 'input'>>;
  destroyProfileAddress?: Resolver<Maybe<IResolversTypes['DestroyProfileAddressPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationDestroyProfileAddressArgs, 'input'>>;
  directDebitMandates?: Resolver<Maybe<IResolversTypes['DirectDebitMandates']>, ParentType, ContextType>;
  donkeyRepublic?: Resolver<Maybe<IResolversTypes['DonkeyRepublicBooking']>, ParentType, ContextType>;
  favorites?: Resolver<Maybe<IResolversTypes['Favorites']>, ParentType, ContextType>;
  finishSharedCarReservation?: Resolver<Maybe<IResolversTypes['FinishSharedCarReservationPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationFinishSharedCarReservationArgs, 'input'>>;
  finishSharedVehicleReservation?: Resolver<Maybe<IResolversTypes['FinishSharedVehicleReservationPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationFinishSharedVehicleReservationArgs, 'input'>>;
  leaseBikeOrder?: Resolver<Maybe<IResolversTypes['LeaseBikeOrder']>, ParentType, ContextType>;
  login?: Resolver<Maybe<IResolversTypes['LoginPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationLoginArgs, 'input'>>;
  logout?: Resolver<Maybe<IResolversTypes['LogoutPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationLogoutArgs, 'input'>>;
  markMessageAsRead?: Resolver<Maybe<IResolversTypes['MarkMessageAsReadPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationMarkMessageAsReadArgs, 'input'>>;
  mergeMobilityEvents?: Resolver<Maybe<IResolversTypes['MergeMobilityEventsPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationMergeMobilityEventsArgs, 'input'>>;
  mileages?: Resolver<Maybe<IResolversTypes['Mileages']>, ParentType, ContextType>;
  mobilityCardOrders?: Resolver<Maybe<IResolversTypes['MobilityCardOrders']>, ParentType, ContextType>;
  parking?: Resolver<Maybe<IResolversTypes['Parking']>, ParentType, ContextType>;
  rejectMobilityEvents?: Resolver<Maybe<IResolversTypes['RejectMobilityEventsPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationRejectMobilityEventsArgs, 'input'>>;
  reportPersonalMeansOfRegistration?: Resolver<Maybe<IResolversTypes['ReportPersonalMeansOfRegistrationPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationReportPersonalMeansOfRegistrationArgs, 'input'>>;
  requestDonkeyRepublicFeature?: Resolver<Maybe<IResolversTypes['RequestDonkeyRepublicFeaturePayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationRequestDonkeyRepublicFeatureArgs, 'input'>>;
  requestFeature?: Resolver<Maybe<IResolversTypes['RequestFeaturePayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationRequestFeatureArgs, 'input'>>;
  requestTrainClassChange?: Resolver<Maybe<IResolversTypes['RequestTrainClassChangePayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationRequestTrainClassChangeArgs, 'input'>>;
  resetPassword?: Resolver<Maybe<IResolversTypes['ResetPasswordPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationResetPasswordArgs, 'input'>>;
  reuploadPhoto?: Resolver<Maybe<IResolversTypes['ReuploadPhotoPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationReuploadPhotoArgs, 'input'>>;
  sendNewInvitationLink?: Resolver<Maybe<IResolversTypes['SendNewInvitationLinkPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationSendNewInvitationLinkArgs, 'input'>>;
  sendPasswordResetMail?: Resolver<Maybe<IResolversTypes['SendPasswordResetMailPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationSendPasswordResetMailArgs, 'input'>>;
  sendPrivacyRequestMail?: Resolver<Maybe<IResolversTypes['SendPrivacyRequestMailPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationSendPrivacyRequestMailArgs, 'input'>>;
  services?: Resolver<Maybe<IResolversTypes['Services']>, ParentType, ContextType>;
  setAgreeTerms?: Resolver<Maybe<IResolversTypes['SetAgreeTermsPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationSetAgreeTermsArgs, 'input'>>;
  splitBillingContracts?: Resolver<Maybe<IResolversTypes['SplitBillingContracts']>, ParentType, ContextType>;
  startSharedCarReservation?: Resolver<Maybe<IResolversTypes['StartSharedCarReservationPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationStartSharedCarReservationArgs, 'input'>>;
  startSharedVehicleReservation?: Resolver<Maybe<IResolversTypes['StartSharedVehicleReservationPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationStartSharedVehicleReservationArgs, 'input'>>;
  studentTravelProduct?: Resolver<Maybe<IResolversTypes['StudentTravelProduct']>, ParentType, ContextType>;
  taxi?: Resolver<Maybe<IResolversTypes['Taxi']>, ParentType, ContextType>;
  terminateOwnership?: Resolver<Maybe<IResolversTypes['TerminateOwnershipPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationTerminateOwnershipArgs, 'input'>>;
  terminatePersonalMeansOfRegistration?: Resolver<Maybe<IResolversTypes['TerminatePersonalMeansOfRegistrationPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationTerminatePersonalMeansOfRegistrationArgs, 'input'>>;
  terminateReplacementManager?: Resolver<Maybe<IResolversTypes['TerminateReplacementManagerPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationTerminateReplacementManagerArgs, 'input'>>;
  terminateVehicle?: Resolver<Maybe<IResolversTypes['TerminateVehiclePayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationTerminateVehicleArgs, 'input'>>;
  tranzer?: Resolver<Maybe<IResolversTypes['Tranzer']>, ParentType, ContextType>;
  unacceptMobilityEvent?: Resolver<Maybe<IResolversTypes['UnacceptMobilityEventPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationUnacceptMobilityEventArgs, 'input'>>;
  unacceptMobilityEvents?: Resolver<Maybe<IResolversTypes['UnacceptMobilityEventsPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationUnacceptMobilityEventsArgs, 'input'>>;
  unconfirmMobilityEvent?: Resolver<Maybe<IResolversTypes['UnconfirmMobilityEventPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationUnconfirmMobilityEventArgs, 'input'>>;
  unconfirmMobilityEvents?: Resolver<Maybe<IResolversTypes['UnconfirmMobilityEventsPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationUnconfirmMobilityEventsArgs, 'input'>>;
  updateMobilityEvent?: Resolver<Maybe<IResolversTypes['UpdateMobilityEventPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationUpdateMobilityEventArgs, 'input'>>;
  updateMobilityEvents?: Resolver<Maybe<IResolversTypes['UpdateMobilityEventsPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationUpdateMobilityEventsArgs, 'input'>>;
  updateTaxiReservation?: Resolver<Maybe<IResolversTypes['UpdateTaxiReservationPayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationUpdateTaxiReservationArgs, 'input'>>;
  updateUserProfile?: Resolver<Maybe<IResolversTypes['UpdateUserProfilePayload']>, ParentType, ContextType, RequireFields<IReisbalansMutationUpdateUserProfileArgs, 'input'>>;
  vehicle?: Resolver<Maybe<IResolversTypes['Vehicle']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IReisbalansQueryResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['reisbalansQuery'] = IResolversParentTypes['reisbalansQuery']> = ResolversObject<{
  apiTokenForQrCode?: Resolver<Maybe<IResolversTypes['ResultWithAccessTokens']>, ParentType, ContextType, RequireFields<IReisbalansQueryApiTokenForQrCodeArgs, 'data'>>;
  configurationProfileForHost?: Resolver<Maybe<IResolversTypes['ConfigurationProfileForHost']>, ParentType, ContextType, RequireFields<IReisbalansQueryConfigurationProfileForHostArgs, 'host'>>;
  currentTime?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
  customerService?: Resolver<Maybe<IResolversTypes['CustomerService']>, ParentType, ContextType, Partial<IReisbalansQueryCustomerServiceArgs>>;
  employeeDetails?: Resolver<Maybe<IResolversTypes['EmployeeDetailsResponse']>, ParentType, ContextType, RequireFields<IReisbalansQueryEmployeeDetailsArgs, 'key'>>;
  externalUrls?: Resolver<IResolversTypes['ExternalUrls'], ParentType, ContextType>;
  fuelTypes?: Resolver<Maybe<Array<IResolversTypes['Fuel']>>, ParentType, ContextType>;
  getUserByInviteCode?: Resolver<Maybe<IResolversTypes['Employee']>, ParentType, ContextType, RequireFields<IReisbalansQueryGetUserByInviteCodeArgs, 'email' | 'inviteCode'>>;
  googlePlaces?: Resolver<Maybe<IResolversTypes['GooglePlaces']>, ParentType, ContextType>;
  inviteTokenValid?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<IReisbalansQueryInviteTokenValidArgs, 'inviteToken'>>;
  jwt?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType, Partial<IReisbalansQueryJwtArgs>>;
  me?: Resolver<Maybe<IResolversTypes['Employee']>, ParentType, ContextType>;
  nsRefundUrl?: Resolver<IResolversTypes['NSRefundUrl'], ParentType, ContextType>;
  ovFietsLocationsByCoordinates?: Resolver<Maybe<Array<IResolversTypes['OvFietsLocation']>>, ParentType, ContextType, RequireFields<IReisbalansQueryOvFietsLocationsByCoordinatesArgs, 'at' | 'radius'>>;
  ovFietsLocationsByFavoriteId?: Resolver<Maybe<Array<IResolversTypes['OvFietsLocation']>>, ParentType, ContextType, RequireFields<IReisbalansQueryOvFietsLocationsByFavoriteIdArgs, 'id' | 'radius'>>;
  password?: Resolver<Maybe<IResolversTypes['Password']>, ParentType, ContextType>;
  places?: Resolver<Maybe<IResolversTypes['Places']>, ParentType, ContextType>;
  reauthorize?: Resolver<Maybe<IResolversTypes['Auth']>, ParentType, ContextType, RequireFields<IReisbalansQueryReauthorizeArgs, 'refreshToken'>>;
  ssoEnabled?: Resolver<Maybe<IResolversTypes['SsoEnabled']>, ParentType, ContextType, RequireFields<IReisbalansQuerySsoEnabledArgs, 'username'>>;
  stations?: Resolver<Maybe<Array<IResolversTypes['Station']>>, ParentType, ContextType>;
  supportedVersion?: Resolver<Maybe<IResolversTypes['SupportedVersion']>, ParentType, ContextType>;
  termsAndConditionsDocuments?: Resolver<Maybe<Array<IResolversTypes['TermsAndConditionsDocument']>>, ParentType, ContextType>;
  theme?: Resolver<Maybe<IResolversTypes['Theme']>, ParentType, ContextType, RequireFields<IReisbalansQueryThemeArgs, 'uuid'>>;
  tokens?: Resolver<Maybe<IResolversTypes['Auth']>, ParentType, ContextType>;
  vehicleInformation?: Resolver<Maybe<IResolversTypes['VehicleInformation']>, ParentType, ContextType, RequireFields<IReisbalansQueryVehicleInformationArgs, 'licensePlate'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IReisbalansV2QueryResolvers<ContextType = IGraphQLContext, ParentType extends IResolversParentTypes['reisbalansV2Query'] = IResolversParentTypes['reisbalansV2Query']> = ResolversObject<{
  ovPas?: Resolver<IResolversTypes['OvPas'], ParentType, ContextType>;
  v2Organization?: Resolver<IResolversTypes['V2Organization'], ParentType, ContextType, RequireFields<IReisbalansV2QueryV2OrganizationArgs, 'organizationId'>>;
  wrpm?: Resolver<Maybe<Array<IResolversTypes['Wrpm']>>, ParentType, ContextType, RequireFields<IReisbalansV2QueryWrpmArgs, 'forBudgetGroup' | 'polymorphicId'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IResolvers<ContextType = IGraphQLContext> = ResolversObject<{
  AcceptMobilityEventsPayload?: IAcceptMobilityEventsPayloadResolvers<ContextType>;
  ActionRequired?: IActionRequiredResolvers<ContextType>;
  Actions?: IActionsResolvers<ContextType>;
  ActivatableDongle?: IActivatableDongleResolvers<ContextType>;
  ActivateEnecoDonglePayload?: IActivateEnecoDonglePayloadResolvers<ContextType>;
  ActivatePersonalMeansOfRegistrationPayload?: IActivatePersonalMeansOfRegistrationPayloadResolvers<ContextType>;
  ActiveAdditional?: IActiveAdditionalResolvers<ContextType>;
  ActiveGreenwheels?: IActiveGreenwheelsResolvers<ContextType>;
  ActiveSubscription?: IActiveSubscriptionResolvers<ContextType>;
  ActiveYellowbrickSp?: IActiveYellowbrickSpResolvers<ContextType>;
  AddPushNotificationTokenPayload?: IAddPushNotificationTokenPayloadResolvers<ContextType>;
  AddToWhitelistPayload?: IAddToWhitelistPayloadResolvers<ContextType>;
  AddableVehiclePermission?: IAddableVehiclePermissionResolvers<ContextType>;
  Address?: IAddressResolvers<ContextType>;
  AdminLoginPayload?: IAdminLoginPayloadResolvers<ContextType>;
  Amber?: IAmberResolvers<ContextType>;
  AppUrls?: IAppUrlsResolvers<ContextType>;
  AssetAvailability?: IAssetAvailabilityResolvers<ContextType>;
  AssetLocation?: IAssetLocationResolvers<ContextType>;
  AssetStation?: IAssetStationResolvers<ContextType>;
  AssignReplacementManagerPayload?: IAssignReplacementManagerPayloadResolvers<ContextType>;
  Auth?: IAuthResolvers<ContextType>;
  AvailableFeatureInterface?: IAvailableFeatureInterfaceResolvers<ContextType>;
  AvailableServiceInterface?: IAvailableServiceInterfaceResolvers<ContextType>;
  AvailableSharedCar?: IAvailableSharedCarResolvers<ContextType>;
  AvailableSharedVehicle?: IAvailableSharedVehicleResolvers<ContextType>;
  AvailableSubscription?: IAvailableSubscriptionResolvers<ContextType>;
  Balance?: IBalanceResolvers<ContextType>;
  Base64?: GraphQLScalarType;
  BigDecimal?: GraphQLScalarType;
  BikeAccess?: IBikeAccessResolvers<ContextType>;
  Branding?: IBrandingResolvers<ContextType>;
  Budget?: IBudgetResolvers<ContextType>;
  BudgetGroup?: IBudgetGroupResolvers<ContextType>;
  CalculatedDistance?: ICalculatedDistanceResolvers<ContextType>;
  CancelDonkeyRepublicReservationPayload?: ICancelDonkeyRepublicReservationPayloadResolvers<ContextType>;
  CancelSharedCarReservationPayload?: ICancelSharedCarReservationPayloadResolvers<ContextType>;
  CancelSharedVehicleReservationPayload?: ICancelSharedVehicleReservationPayloadResolvers<ContextType>;
  CancelTaxiReservationPayload?: ICancelTaxiReservationPayloadResolvers<ContextType>;
  Category?: ICategoryResolvers<ContextType>;
  Co2Emission?: ICo2EmissionResolvers<ContextType>;
  Co2EmissionPerModality?: ICo2EmissionPerModalityResolvers<ContextType>;
  Co2EmissionSummary?: ICo2EmissionSummaryResolvers<ContextType>;
  Color?: IColorResolvers<ContextType>;
  CompensationRideToBeCreated?: ICompensationRideToBeCreatedResolvers<ContextType>;
  Configuration?: IConfigurationResolvers<ContextType>;
  ConfigurationProfileForHost?: IConfigurationProfileForHostResolvers<ContextType>;
  ConfigureMfaPayload?: IConfigureMfaPayloadResolvers<ContextType>;
  ConfirmGreenwheelsPayload?: IConfirmGreenwheelsPayloadResolvers<ContextType>;
  ConfirmMobilityEventPayload?: IConfirmMobilityEventPayloadResolvers<ContextType>;
  ConfirmMobilityEventsPayload?: IConfirmMobilityEventsPayloadResolvers<ContextType>;
  ConfirmPayload?: IConfirmPayloadResolvers<ContextType>;
  ConfirmSharedCarReservationPayload?: IConfirmSharedCarReservationPayloadResolvers<ContextType>;
  ConfirmSharedVehicleReservationPayload?: IConfirmSharedVehicleReservationPayloadResolvers<ContextType>;
  ConfirmTaxiReservationPayload?: IConfirmTaxiReservationPayloadResolvers<ContextType>;
  ContractActions?: IContractActionsResolvers<ContextType>;
  ContractStatus?: IContractStatusResolvers<ContextType>;
  Coordinate?: GraphQLScalarType;
  Coordinates?: ICoordinatesResolvers<ContextType>;
  CopyMobilityEventsForDayPayload?: ICopyMobilityEventsForDayPayloadResolvers<ContextType>;
  CopyMobilityEventsPayload?: ICopyMobilityEventsPayloadResolvers<ContextType>;
  Cost?: ICostResolvers<ContextType>;
  CountryIsoCode?: ICountryIsoCodeResolvers<ContextType>;
  CreateCommuteCompensationPayload?: ICreateCommuteCompensationPayloadResolvers<ContextType>;
  CreateCommuteRidesPayload?: ICreateCommuteRidesPayloadResolvers<ContextType>;
  CreateCompensationMobilityEventPayload?: ICreateCompensationMobilityEventPayloadResolvers<ContextType>;
  CreateDirectDebitMandatePayload?: ICreateDirectDebitMandatePayloadResolvers<ContextType>;
  CreateDonkeyRepublicReservationPayload?: ICreateDonkeyRepublicReservationPayloadResolvers<ContextType>;
  CreateFavoritePayload?: ICreateFavoritePayloadResolvers<ContextType>;
  CreateLeaseBikeOrderPayload?: ICreateLeaseBikeOrderPayloadResolvers<ContextType>;
  CreateMileagePayload?: ICreateMileagePayloadResolvers<ContextType>;
  CreateMobilityCardOrderPayload?: ICreateMobilityCardOrderPayloadResolvers<ContextType>;
  CreateMobilityEventPayload?: ICreateMobilityEventPayloadResolvers<ContextType>;
  CreateParkingOrderPayload?: ICreateParkingOrderPayloadResolvers<ContextType>;
  CreateTaxiReservationPayload?: ICreateTaxiReservationPayloadResolvers<ContextType>;
  CreateTranzerReservationPayload?: ICreateTranzerReservationPayloadResolvers<ContextType>;
  CreateUserProfilePayload?: ICreateUserProfilePayloadResolvers<ContextType>;
  CreateVehiclePayload?: ICreateVehiclePayloadResolvers<ContextType>;
  CreateWorkFromHomePayload?: ICreateWorkFromHomePayloadResolvers<ContextType>;
  CustomerLabelTheme?: ICustomerLabelThemeResolvers<ContextType>;
  CustomerService?: ICustomerServiceResolvers<ContextType>;
  Date?: GraphQLScalarType;
  DateTime?: GraphQLScalarType;
  Day?: IDayResolvers<ContextType>;
  DayWithMobilityEvents?: IDayWithMobilityEventsResolvers<ContextType>;
  DayWithoutEmployment?: IDayWithoutEmploymentResolvers<ContextType>;
  Description?: IDescriptionResolvers<ContextType>;
  DestroyFavoritePayload?: IDestroyFavoritePayloadResolvers<ContextType>;
  DestroyMobilityEventPayload?: IDestroyMobilityEventPayloadResolvers<ContextType>;
  DestroyMobilityEventsPayload?: IDestroyMobilityEventsPayloadResolvers<ContextType>;
  DestroyProfileAddressPayload?: IDestroyProfileAddressPayloadResolvers<ContextType>;
  DirectDebitInvoice?: IDirectDebitInvoiceResolvers<ContextType>;
  DirectDebitMandate?: IDirectDebitMandateResolvers<ContextType>;
  DirectDebitMandates?: IDirectDebitMandatesResolvers<ContextType>;
  Display?: IDisplayResolvers<ContextType>;
  Distance?: IDistanceResolvers<ContextType>;
  DistancePerModality?: IDistancePerModalityResolvers<ContextType>;
  DistanceSummary?: IDistanceSummaryResolvers<ContextType>;
  Diverted?: IDivertedResolvers<ContextType>;
  DonkeyLeg?: IDonkeyLegResolvers<ContextType>;
  DonkeyQuote?: IDonkeyQuoteResolvers<ContextType>;
  DonkeyRepublic?: IDonkeyRepublicResolvers<ContextType>;
  DonkeyRepublicBooking?: IDonkeyRepublicBookingResolvers<ContextType>;
  DonkeyRepublicModality?: IDonkeyRepublicModalityResolvers<ContextType>;
  DonkeyRepublicReservation?: IDonkeyRepublicReservationResolvers<ContextType>;
  DonkeyRepublicResult?: IDonkeyRepublicResultResolvers<ContextType>;
  DonkeyRepublicStatus?: IDonkeyRepublicStatusResolvers<ContextType>;
  Driver?: IDriverResolvers<ContextType>;
  DriversLicenseCountry?: IDriversLicenseCountryResolvers<ContextType>;
  Duration?: IDurationResolvers<ContextType>;
  Ekey?: IEkeyResolvers<ContextType>;
  EkeyLock?: IEkeyLockResolvers<ContextType>;
  Employee?: IEmployeeResolvers<ContextType>;
  EmployeeAddress?: IEmployeeAddressResolvers<ContextType>;
  EmployeeDetails?: IEmployeeDetailsResolvers<ContextType>;
  EmployeeDetailsResponse?: IEmployeeDetailsResponseResolvers<ContextType>;
  EmployeeFinancialConsequences?: IEmployeeFinancialConsequencesResolvers<ContextType>;
  Employment?: IEmploymentResolvers<ContextType>;
  EnecoDongle?: IEnecoDongleResolvers<ContextType>;
  Error?: IErrorResolvers<ContextType>;
  Explanation?: IExplanationResolvers<ContextType>;
  ExplanationRequired?: IExplanationRequiredResolvers<ContextType>;
  ExportFiles?: IExportFilesResolvers<ContextType>;
  ExportUrls?: IExportUrlsResolvers<ContextType>;
  ExternalUrl?: IExternalUrlResolvers<ContextType>;
  ExternalUrls?: IExternalUrlsResolvers<ContextType>;
  Favorite?: IFavoriteResolvers<ContextType>;
  FavoriteActions?: IFavoriteActionsResolvers<ContextType>;
  FavoriteLocation?: IFavoriteLocationResolvers<ContextType>;
  Favorites?: IFavoritesResolvers<ContextType>;
  FeatureOption?: IFeatureOptionResolvers<ContextType>;
  FeatureOrderStatus?: IFeatureOrderStatusResolvers<ContextType>;
  FeatureUrls?: IFeatureUrlsResolvers<ContextType>;
  FilterOptionItem?: IFilterOptionItemResolvers<ContextType>;
  FilterOptions?: IFilterOptionsResolvers<ContextType>;
  Financial?: IFinancialResolvers<ContextType>;
  FinancialComponent?: IFinancialComponentResolvers<ContextType>;
  FinancialConsequences?: IFinancialConsequencesResolvers<ContextType>;
  FinancialFact?: IFinancialFactResolvers<ContextType>;
  FinancialSummary?: IFinancialSummaryResolvers<ContextType>;
  FinishDonkeyRepublicReservationPayload?: IFinishDonkeyRepublicReservationPayloadResolvers<ContextType>;
  FinishSharedCarReservationPayload?: IFinishSharedCarReservationPayloadResolvers<ContextType>;
  FinishSharedVehicleReservationPayload?: IFinishSharedVehicleReservationPayloadResolvers<ContextType>;
  FirstClassReason?: IFirstClassReasonResolvers<ContextType>;
  Fuel?: IFuelResolvers<ContextType>;
  GeoLocation?: IGeoLocationResolvers<ContextType>;
  GetAccessUrlPayload?: IGetAccessUrlPayloadResolvers<ContextType>;
  GooglePlaces?: IGooglePlacesResolvers<ContextType>;
  GooglePlacesDetailsResult?: IGooglePlacesDetailsResultResolvers<ContextType>;
  GooglePlacesResult?: IGooglePlacesResultResolvers<ContextType>;
  GooglePlacesStructuredText?: IGooglePlacesStructuredTextResolvers<ContextType>;
  GooglePlacesTypeValue?: IGooglePlacesTypeValueResolvers<ContextType>;
  Greenwheels?: IGreenwheelsResolvers<ContextType>;
  GreenwheelsFleet?: IGreenwheelsFleetResolvers<ContextType>;
  How?: IHowResolvers<ContextType>;
  HowMuch?: IHowMuchResolvers<ContextType>;
  Hsla?: IHslaResolvers<ContextType>;
  Hub?: IHubResolvers<ContextType>;
  Icon?: IIconResolvers<ContextType>;
  Identity?: IIdentityResolvers<ContextType>;
  Image?: IImageResolvers<ContextType>;
  InvoiceStatus?: IInvoiceStatusResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  JournalEntry?: IJournalEntryResolvers<ContextType>;
  Journey?: IJourneyResolvers<ContextType>;
  JourneyLeg?: IJourneyLegResolvers<ContextType>;
  JourneyMode?: IJourneyModeResolvers<ContextType>;
  Label?: ILabelResolvers<ContextType>;
  LeaseBike?: ILeaseBikeResolvers<ContextType>;
  LeaseBikeOrder?: ILeaseBikeOrderResolvers<ContextType>;
  LeaseCar?: ILeaseCarResolvers<ContextType>;
  Leg?: ILegResolvers<ContextType>;
  LicensePlate?: ILicensePlateResolvers<ContextType>;
  Location?: ILocationResolvers<ContextType>;
  Lock?: ILockResolvers<ContextType>;
  LockDonkeyRepublicAssetPayload?: ILockDonkeyRepublicAssetPayloadResolvers<ContextType>;
  LoginPayload?: ILoginPayloadResolvers<ContextType>;
  LogoutPayload?: ILogoutPayloadResolvers<ContextType>;
  MSAAbstractProperty?: IMsaAbstractPropertyResolvers<ContextType>;
  MSACarrier?: IMsaCarrierResolvers<ContextType>;
  MSAProduct?: IMsaProductResolvers<ContextType>;
  MSAProductGroup?: IMsaProductGroupResolvers<ContextType>;
  MSAPropertyTypeBinding?: IMsaPropertyTypeBindingResolvers<ContextType>;
  MSAPropertyTypeConstraint?: IMsaPropertyTypeConstraintResolvers<ContextType>;
  MSAQueryCarrierTypePropertiesListItemsConstraintsItemsRules?: IMsaQueryCarrierTypePropertiesListItemsConstraintsItemsRulesResolvers<ContextType>;
  MSASupportedCarriersForProductTypeList?: IMsaSupportedCarriersForProductTypeListResolvers<ContextType>;
  MainCategory?: IMainCategoryResolvers<ContextType>;
  MandateActions?: IMandateActionsResolvers<ContextType>;
  MandateStatus?: IMandateStatusResolvers<ContextType>;
  MarkMessageAsReadPayload?: IMarkMessageAsReadPayloadResolvers<ContextType>;
  MeanOfTransportFiscality?: IMeanOfTransportFiscalityResolvers<ContextType>;
  MeansOfTransport?: IMeansOfTransportResolvers<ContextType>;
  Media?: IMediaResolvers<ContextType>;
  MediaSide?: IMediaSideResolvers<ContextType>;
  MergeMobilityEventsPayload?: IMergeMobilityEventsPayloadResolvers<ContextType>;
  MergedMobilityEventsPreview?: IMergedMobilityEventsPreviewResolvers<ContextType>;
  Message?: IMessageResolvers<ContextType>;
  Mileage?: IMileageResolvers<ContextType>;
  Mileages?: IMileagesResolvers<ContextType>;
  MobilityCardOrder?: IMobilityCardOrderResolvers<ContextType>;
  MobilityCardOrderActions?: IMobilityCardOrderActionsResolvers<ContextType>;
  MobilityCardOrderPermission?: IMobilityCardOrderPermissionResolvers<ContextType>;
  MobilityCardOrderService?: IMobilityCardOrderServiceResolvers<ContextType>;
  MobilityCardOrderStatus?: IMobilityCardOrderStatusResolvers<ContextType>;
  MobilityCardOrders?: IMobilityCardOrdersResolvers<ContextType>;
  MobilityEvent?: IMobilityEventResolvers<ContextType>;
  MobilityEventAggregate?: IMobilityEventAggregateResolvers<ContextType>;
  MobilityEventByDate?: IMobilityEventByDateResolvers<ContextType>;
  MobilityEventFormValidations?: IMobilityEventFormValidationsResolvers<ContextType>;
  MobilityEventPreview?: IMobilityEventPreviewResolvers<ContextType>;
  MobilityEventTotals?: IMobilityEventTotalsResolvers<ContextType>;
  MobilityEventsWeek?: IMobilityEventsWeekResolvers<ContextType>;
  MobilityServicesMutation?: IMobilityServicesMutationResolvers<ContextType>;
  MobilityServicesQuery?: IMobilityServicesQueryResolvers<ContextType>;
  Modality?: IModalityResolvers<ContextType>;
  ModalityOwnership?: IModalityOwnershipResolvers<ContextType>;
  Money?: IMoneyResolvers<ContextType>;
  MoneyTransfer?: IMoneyTransferResolvers<ContextType>;
  MoneyWithCurrency?: IMoneyWithCurrencyResolvers<ContextType>;
  MultiModalAvailability?: IMultiModalAvailabilityResolvers<ContextType>;
  Mutation?: IMutationResolvers<ContextType>;
  NSRefundUrl?: INsRefundUrlResolvers<ContextType>;
  NewProduct?: INewProductResolvers<ContextType>;
  NonCombined?: INonCombinedResolvers<ContextType>;
  ObjMap?: GraphQLScalarType;
  OpeningHours?: IOpeningHoursResolvers<ContextType>;
  Organization?: IOrganizationResolvers<ContextType>;
  OvFietsLocation?: IOvFietsLocationResolvers<ContextType>;
  OvPas?: IOvPasResolvers<ContextType>;
  Parking?: IParkingResolvers<ContextType>;
  ParkingOrder?: IParkingOrderResolvers<ContextType>;
  ParkingOrderActions?: IParkingOrderActionsResolvers<ContextType>;
  ParkingOrderStatus?: IParkingOrderStatusResolvers<ContextType>;
  Password?: IPasswordResolvers<ContextType>;
  PasswordStrength?: IPasswordStrengthResolvers<ContextType>;
  PendingApproval?: IPendingApprovalResolvers<ContextType>;
  Permissions?: IPermissionsResolvers<ContextType>;
  PersonalMeansOfRegistration?: IPersonalMeansOfRegistrationResolvers<ContextType>;
  PersonalMeansOfRegistrationAbilities?: IPersonalMeansOfRegistrationAbilitiesResolvers<ContextType>;
  PersonalMeansOfRegistrationEndsOn?: IPersonalMeansOfRegistrationEndsOnResolvers<ContextType>;
  PersonalMeansOfRegistrationIdentity?: IPersonalMeansOfRegistrationIdentityResolvers<ContextType>;
  PersonalMeansOfRegistrationKind?: IPersonalMeansOfRegistrationKindResolvers<ContextType>;
  PersonalMeansOfRegistrationMetaStatus?: IPersonalMeansOfRegistrationMetaStatusResolvers<ContextType>;
  PersonalMeansOfRegistrationPhotoRejectedReason?: IPersonalMeansOfRegistrationPhotoRejectedReasonResolvers<ContextType>;
  PersonalMeansOfRegistrationService?: IPersonalMeansOfRegistrationServiceResolvers<ContextType>;
  PersonalMeansOfRegistrationServiceEndsOn?: IPersonalMeansOfRegistrationServiceEndsOnResolvers<ContextType>;
  PersonalMeansOfRegistrationServiceIdentity?: IPersonalMeansOfRegistrationServiceIdentityResolvers<ContextType>;
  PersonalMeansOfRegistrationServiceKind?: IPersonalMeansOfRegistrationServiceKindResolvers<ContextType>;
  PersonalMeansOfRegistrationServiceStatus?: IPersonalMeansOfRegistrationServiceStatusResolvers<ContextType>;
  PersonalMeansOfRegistrationStatus?: IPersonalMeansOfRegistrationStatusResolvers<ContextType>;
  Places?: IPlacesResolvers<ContextType>;
  PlacesDetailsResult?: IPlacesDetailsResultResolvers<ContextType>;
  PlacesResult?: IPlacesResultResolvers<ContextType>;
  PlacesStructuredText?: IPlacesStructuredTextResolvers<ContextType>;
  PlacesTypeValue?: IPlacesTypeValueResolvers<ContextType>;
  PossibleReplacementManager?: IPossibleReplacementManagerResolvers<ContextType>;
  Price?: IPriceResolvers<ContextType>;
  Privacy?: IPrivacyResolvers<ContextType>;
  PrivacyRequestReason?: IPrivacyRequestReasonResolvers<ContextType>;
  Profile?: IProfileResolvers<ContextType>;
  Project?: IProjectResolvers<ContextType>;
  PublicTransportStop?: IPublicTransportStopResolvers<ContextType>;
  Purpose?: IPurposeResolvers<ContextType>;
  Quantity?: IQuantityResolvers<ContextType>;
  Query?: IQueryResolvers<ContextType>;
  Quote?: IQuoteResolvers<ContextType>;
  QuoteStatus?: IQuoteStatusResolvers<ContextType>;
  Receipt?: IReceiptResolvers<ContextType>;
  RecommendedCard?: IRecommendedCardResolvers<ContextType>;
  RefreshTranzerReservationPayload?: IRefreshTranzerReservationPayloadResolvers<ContextType>;
  RefundToBeLinked?: IRefundToBeLinkedResolvers<ContextType>;
  ReimbursableCategory?: IReimbursableCategoryResolvers<ContextType>;
  ReimbursableCategoryMethod?: IReimbursableCategoryMethodResolvers<ContextType>;
  ReimbursementRule?: IReimbursementRuleResolvers<ContextType>;
  RejectMobilityEventsPayload?: IRejectMobilityEventsPayloadResolvers<ContextType>;
  RemoveFromWhitelistPayload?: IRemoveFromWhitelistPayloadResolvers<ContextType>;
  RentalCar?: IRentalCarResolvers<ContextType>;
  RentalLocations?: IRentalLocationsResolvers<ContextType>;
  ReplacementManager?: IReplacementManagerResolvers<ContextType>;
  ReportPersonalMeansOfRegistrationPayload?: IReportPersonalMeansOfRegistrationPayloadResolvers<ContextType>;
  RequestDates?: IRequestDatesResolvers<ContextType>;
  RequestDonkeyRepublicFeaturePayload?: IRequestDonkeyRepublicFeaturePayloadResolvers<ContextType>;
  RequestFeaturePayload?: IRequestFeaturePayloadResolvers<ContextType>;
  RequestServicePayload?: IRequestServicePayloadResolvers<ContextType>;
  RequestTrainClassChangePayload?: IRequestTrainClassChangePayloadResolvers<ContextType>;
  RequestableDonkeyRepublic?: IRequestableDonkeyRepublicResolvers<ContextType>;
  RequestableFeature?: IRequestableFeatureResolvers<ContextType>;
  RequestableFeatureOption?: IRequestableFeatureOptionResolvers<ContextType>;
  RequestableService?: IRequestableServiceResolvers<ContextType>;
  RequestableYellowbrickProduct?: IRequestableYellowbrickProductResolvers<ContextType>;
  RequiredFields?: IRequiredFieldsResolvers<ContextType>;
  Reservation?: IReservationResolvers<ContextType>;
  ReservationStatus?: IReservationStatusResolvers<ContextType>;
  ResetPasswordPayload?: IResetPasswordPayloadResolvers<ContextType>;
  Result?: IResultResolvers<ContextType>;
  ResultInterface?: IResultInterfaceResolvers<ContextType>;
  ResultWithAccessTokens?: IResultWithAccessTokensResolvers<ContextType>;
  ReuploadPhotoPayload?: IReuploadPhotoPayloadResolvers<ContextType>;
  RevokeDirectDebitMandatePayload?: IRevokeDirectDebitMandatePayloadResolvers<ContextType>;
  RevokeSplitBillingContractPayload?: IRevokeSplitBillingContractPayloadResolvers<ContextType>;
  Rgba?: IRgbaResolvers<ContextType>;
  RightToBeForgottenReason?: IRightToBeForgottenReasonResolvers<ContextType>;
  Route?: IRouteResolvers<ContextType>;
  RouteMetadata?: IRouteMetadataResolvers<ContextType>;
  SegmentPrice?: ISegmentPriceResolvers<ContextType>;
  SendNewInvitationLinkPayload?: ISendNewInvitationLinkPayloadResolvers<ContextType>;
  SendPasswordResetMailPayload?: ISendPasswordResetMailPayloadResolvers<ContextType>;
  SendPrivacyRequestMailPayload?: ISendPrivacyRequestMailPayloadResolvers<ContextType>;
  ServiceOperator?: IServiceOperatorResolvers<ContextType>;
  ServiceProvider?: IServiceProviderResolvers<ContextType>;
  Services?: IServicesResolvers<ContextType>;
  ServicesChangeRequest?: IServicesChangeRequestResolvers<ContextType>;
  SetAgreeTermsPayload?: ISetAgreeTermsPayloadResolvers<ContextType>;
  SetManualFavoriteCoordinatesPayload?: ISetManualFavoriteCoordinatesPayloadResolvers<ContextType>;
  SetOptionsPayload?: ISetOptionsPayloadResolvers<ContextType>;
  SetPhotoPayload?: ISetPhotoPayloadResolvers<ContextType>;
  SetProfilePayload?: ISetProfilePayloadResolvers<ContextType>;
  SetTermsPayload?: ISetTermsPayloadResolvers<ContextType>;
  Settlement?: ISettlementResolvers<ContextType>;
  SettlementTypeFinancialConsequences?: ISettlementTypeFinancialConsequencesResolvers<ContextType>;
  SharedCarReservation?: ISharedCarReservationResolvers<ContextType>;
  SharedCarReservationStatus?: ISharedCarReservationStatusResolvers<ContextType>;
  SharedVehicle?: ISharedVehicleResolvers<ContextType>;
  SharedVehicleReservation?: ISharedVehicleReservationResolvers<ContextType>;
  ShuttelCheck?: IShuttelCheckResolvers<ContextType>;
  ShuttelMyWheels?: IShuttelMyWheelsResolvers<ContextType>;
  ShuttelSixt?: IShuttelSixtResolvers<ContextType>;
  ShuttelTaxi?: IShuttelTaxiResolvers<ContextType>;
  ShuttelTranzerEticket?: IShuttelTranzerEticketResolvers<ContextType>;
  ShuttelVecore?: IShuttelVecoreResolvers<ContextType>;
  Signature?: ISignatureResolvers<ContextType>;
  SplitBillingContract?: ISplitBillingContractResolvers<ContextType>;
  SplitBillingContractEmailAddress?: ISplitBillingContractEmailAddressResolvers<ContextType>;
  SplitBillingContracts?: ISplitBillingContractsResolvers<ContextType>;
  SplitBillingInvoice?: ISplitBillingInvoiceResolvers<ContextType>;
  SsoEnabled?: ISsoEnabledResolvers<ContextType>;
  StarValue?: IStarValueResolvers<ContextType>;
  StartDonkeyRepublicReservationPayload?: IStartDonkeyRepublicReservationPayloadResolvers<ContextType>;
  StartSharedCarReservationPayload?: IStartSharedCarReservationPayloadResolvers<ContextType>;
  StartSharedVehicleReservationPayload?: IStartSharedVehicleReservationPayloadResolvers<ContextType>;
  StateTransitions?: IStateTransitionsResolvers<ContextType>;
  Station?: IStationResolvers<ContextType>;
  Statistics?: IStatisticsResolvers<ContextType>;
  Stats?: IStatsResolvers<ContextType>;
  StatsPerModality?: IStatsPerModalityResolvers<ContextType>;
  Status?: IStatusResolvers<ContextType>;
  StreetAddress?: IStreetAddressResolvers<ContextType>;
  StudentTravelProduct?: IStudentTravelProductResolvers<ContextType>;
  SubCategory?: ISubCategoryResolvers<ContextType>;
  Subordinate?: ISubordinateResolvers<ContextType>;
  Subscription?: ISubscriptionResolvers<ContextType>;
  SupportedVersion?: ISupportedVersionResolvers<ContextType>;
  Task?: ITaskResolvers<ContextType>;
  Taxi?: ITaxiResolvers<ContextType>;
  TaxiFeature?: ITaxiFeatureResolvers<ContextType>;
  TaxiReservation?: ITaxiReservationResolvers<ContextType>;
  TaxiReservationVehicle?: ITaxiReservationVehicleResolvers<ContextType>;
  TaxiStatus?: ITaxiStatusResolvers<ContextType>;
  TerminateOwnershipPayload?: ITerminateOwnershipPayloadResolvers<ContextType>;
  TerminatePersonalMeansOfRegistrationPayload?: ITerminatePersonalMeansOfRegistrationPayloadResolvers<ContextType>;
  TerminateReplacementManagerPayload?: ITerminateReplacementManagerPayloadResolvers<ContextType>;
  TerminateVehiclePayload?: ITerminateVehiclePayloadResolvers<ContextType>;
  TermsAndConditions?: ITermsAndConditionsResolvers<ContextType>;
  TermsAndConditionsDocument?: ITermsAndConditionsDocumentResolvers<ContextType>;
  TermsAndConditionsDocumentHumanizedKind?: ITermsAndConditionsDocumentHumanizedKindResolvers<ContextType>;
  Theme?: IThemeResolvers<ContextType>;
  Ticket?: ITicketResolvers<ContextType>;
  TicketMedia?: ITicketMediaResolvers<ContextType>;
  Timestamp?: ITimestampResolvers<ContextType>;
  Totals?: ITotalsResolvers<ContextType>;
  TrainClass?: ITrainClassResolvers<ContextType>;
  TrainClassChange?: ITrainClassChangeResolvers<ContextType>;
  TrainClassChangeRequest?: ITrainClassChangeRequestResolvers<ContextType>;
  TrainClassOptions?: ITrainClassOptionsResolvers<ContextType>;
  Traject?: ITrajectResolvers<ContextType>;
  TransactionsReport?: ITransactionsReportResolvers<ContextType>;
  Tranzer?: ITranzerResolvers<ContextType>;
  TranzerEticket?: ITranzerEticketResolvers<ContextType>;
  TranzerLeg?: ITranzerLegResolvers<ContextType>;
  TranzerReservation?: ITranzerReservationResolvers<ContextType>;
  TranzerStatus?: ITranzerStatusResolvers<ContextType>;
  TravelBehaviour?: ITravelBehaviourResolvers<ContextType>;
  TravelMode?: ITravelModeResolvers<ContextType>;
  TravelPurpose?: ITravelPurposeResolvers<ContextType>;
  TravelPurposeFinancialConsequences?: ITravelPurposeFinancialConsequencesResolvers<ContextType>;
  TravelPurposeWithUnknown?: ITravelPurposeWithUnknownResolvers<ContextType>;
  UUID?: GraphQLScalarType;
  UnacceptMobilityEventPayload?: IUnacceptMobilityEventPayloadResolvers<ContextType>;
  UnacceptMobilityEventsPayload?: IUnacceptMobilityEventsPayloadResolvers<ContextType>;
  UnconfirmMobilityEventPayload?: IUnconfirmMobilityEventPayloadResolvers<ContextType>;
  UnconfirmMobilityEventsPayload?: IUnconfirmMobilityEventsPayloadResolvers<ContextType>;
  UnlockDonkeyRepublicAssetPayload?: IUnlockDonkeyRepublicAssetPayloadResolvers<ContextType>;
  UpdateFavoritePayload?: IUpdateFavoritePayloadResolvers<ContextType>;
  UpdateMileagePayload?: IUpdateMileagePayloadResolvers<ContextType>;
  UpdateMobilityEventPayload?: IUpdateMobilityEventPayloadResolvers<ContextType>;
  UpdateMobilityEventsPayload?: IUpdateMobilityEventsPayloadResolvers<ContextType>;
  UpdateTaxiReservationPayload?: IUpdateTaxiReservationPayloadResolvers<ContextType>;
  UpdateUserProfilePayload?: IUpdateUserProfilePayloadResolvers<ContextType>;
  UpdateVehiclePayload?: IUpdateVehiclePayloadResolvers<ContextType>;
  Usage?: IUsageResolvers<ContextType>;
  V2BudgetGroup?: IV2BudgetGroupResolvers<ContextType>;
  V2Organization?: IV2OrganizationResolvers<ContextType>;
  ValidationError?: IValidationErrorResolvers<ContextType>;
  VatRate?: IVatRateResolvers<ContextType>;
  Vehicle?: IVehicleResolvers<ContextType>;
  VehicleActions?: IVehicleActionsResolvers<ContextType>;
  VehicleAsset?: IVehicleAssetResolvers<ContextType>;
  VehicleInformation?: IVehicleInformationResolvers<ContextType>;
  VehicleModalityKind?: IVehicleModalityKindResolvers<ContextType>;
  VehicleOptions?: IVehicleOptionsResolvers<ContextType>;
  What?: IWhatResolvers<ContextType>;
  WhatOption?: IWhatOptionResolvers<ContextType>;
  When?: IWhenResolvers<ContextType>;
  Where?: IWhereResolvers<ContextType>;
  Why?: IWhyResolvers<ContextType>;
  WhyStatus?: IWhyStatusResolvers<ContextType>;
  Wrpm?: IWrpmResolvers<ContextType>;
  WrpmModality?: IWrpmModalityResolvers<ContextType>;
  YellowbrickService?: IYellowbrickServiceResolvers<ContextType>;
  YellowbrickSp?: IYellowbrickSpResolvers<ContextType>;
  Zone?: IZoneResolvers<ContextType>;
  reisbalansMutation?: IReisbalansMutationResolvers<ContextType>;
  reisbalansQuery?: IReisbalansQueryResolvers<ContextType>;
  reisbalansV2Query?: IReisbalansV2QueryResolvers<ContextType>;
}>;

export type IDirectiveResolvers<ContextType = IGraphQLContext> = ResolversObject<{
  enum?: IEnumDirectiveResolver<any, any, ContextType>;
  example?: IExampleDirectiveResolver<any, any, ContextType>;
  httpOperation?: IHttpOperationDirectiveResolver<any, any, ContextType>;
  oneOf?: IOneOfDirectiveResolver<any, any, ContextType>;
  transport?: ITransportDirectiveResolver<any, any, ContextType>;
  typescript?: ITypescriptDirectiveResolver<any, any, ContextType>;
}>;


export const GetBudgetGroupsDocument = gql`
    query getBudgetGroups($organizationId: ID!) {
  reisbalansV2 {
    v2Organization(organizationId: $organizationId) {
      id
      budgetGroups {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetBudgetGroupsQuery__
 *
 * To run a query within a React component, call `useGetBudgetGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBudgetGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBudgetGroupsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetBudgetGroupsQuery(baseOptions: Apollo.QueryHookOptions<IGetBudgetGroupsQuery, IGetBudgetGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetBudgetGroupsQuery, IGetBudgetGroupsQueryVariables>(GetBudgetGroupsDocument, options);
      }
export function useGetBudgetGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetBudgetGroupsQuery, IGetBudgetGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetBudgetGroupsQuery, IGetBudgetGroupsQueryVariables>(GetBudgetGroupsDocument, options);
        }
export function useGetBudgetGroupsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetBudgetGroupsQuery, IGetBudgetGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetBudgetGroupsQuery, IGetBudgetGroupsQueryVariables>(GetBudgetGroupsDocument, options);
        }
export type GetBudgetGroupsQueryHookResult = ReturnType<typeof useGetBudgetGroupsQuery>;
export type GetBudgetGroupsLazyQueryHookResult = ReturnType<typeof useGetBudgetGroupsLazyQuery>;
export type GetBudgetGroupsSuspenseQueryHookResult = ReturnType<typeof useGetBudgetGroupsSuspenseQuery>;
export type GetBudgetGroupsQueryResult = Apollo.QueryResult<IGetBudgetGroupsQuery, IGetBudgetGroupsQueryVariables>;
export const GetWrpmDocument = gql`
    query getWRPM($polymorphicId: ID!, $forBudgetGroup: Boolean!) {
  reisbalansV2 {
    wrpm(polymorphicId: $polymorphicId, forBudgetGroup: $forBudgetGroup) {
      modality {
        humanized
        value
      }
      statsPerModalityBusiness {
        totalCo2
        totalKms
      }
      statsPerModalityCommute {
        totalCo2
        totalKms
      }
    }
  }
}
    `;

/**
 * __useGetWrpmQuery__
 *
 * To run a query within a React component, call `useGetWrpmQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWrpmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWrpmQuery({
 *   variables: {
 *      polymorphicId: // value for 'polymorphicId'
 *      forBudgetGroup: // value for 'forBudgetGroup'
 *   },
 * });
 */
export function useGetWrpmQuery(baseOptions: Apollo.QueryHookOptions<IGetWrpmQuery, IGetWrpmQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetWrpmQuery, IGetWrpmQueryVariables>(GetWrpmDocument, options);
      }
export function useGetWrpmLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetWrpmQuery, IGetWrpmQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetWrpmQuery, IGetWrpmQueryVariables>(GetWrpmDocument, options);
        }
export function useGetWrpmSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetWrpmQuery, IGetWrpmQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetWrpmQuery, IGetWrpmQueryVariables>(GetWrpmDocument, options);
        }
export type GetWrpmQueryHookResult = ReturnType<typeof useGetWrpmQuery>;
export type GetWrpmLazyQueryHookResult = ReturnType<typeof useGetWrpmLazyQuery>;
export type GetWrpmSuspenseQueryHookResult = ReturnType<typeof useGetWrpmSuspenseQuery>;
export type GetWrpmQueryResult = Apollo.QueryResult<IGetWrpmQuery, IGetWrpmQueryVariables>;
export const AdminLoginDocument = gql`
    mutation adminLogin($email: String!, $password: String!, $otp: String) {
  reisbalans {
    adminLogin(input: {email: $email, password: $password, otp: $otp}) {
      result {
        success
        error
      }
      jwt
    }
  }
}
    `;

/**
 * __useAdminLoginMutation__
 *
 * To run a mutation, you first call `useAdminLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminLoginMutation, { data, loading, error }] = useAdminLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      otp: // value for 'otp'
 *   },
 * });
 */
export function useAdminLoginMutation(baseOptions?: Apollo.MutationHookOptions<IAdminLoginMutation, IAdminLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IAdminLoginMutation, IAdminLoginMutationVariables>(AdminLoginDocument, options);
      }
export type AdminLoginMutationHookResult = ReturnType<typeof useAdminLoginMutation>;
export type AdminLoginMutationResult = Apollo.MutationResult<IAdminLoginMutation>;
export type AdminLoginMutationOptions = Apollo.BaseMutationOptions<IAdminLoginMutation, IAdminLoginMutationVariables>;
export const GetJwtDocument = gql`
    query getJWT($token: String!) {
  reisbalans {
    jwt(refreshToken: $token)
  }
}
    `;

/**
 * __useGetJwtQuery__
 *
 * To run a query within a React component, call `useGetJwtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJwtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJwtQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetJwtQuery(baseOptions: Apollo.QueryHookOptions<IGetJwtQuery, IGetJwtQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetJwtQuery, IGetJwtQueryVariables>(GetJwtDocument, options);
      }
export function useGetJwtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetJwtQuery, IGetJwtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetJwtQuery, IGetJwtQueryVariables>(GetJwtDocument, options);
        }
export function useGetJwtSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetJwtQuery, IGetJwtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetJwtQuery, IGetJwtQueryVariables>(GetJwtDocument, options);
        }
export type GetJwtQueryHookResult = ReturnType<typeof useGetJwtQuery>;
export type GetJwtLazyQueryHookResult = ReturnType<typeof useGetJwtLazyQuery>;
export type GetJwtSuspenseQueryHookResult = ReturnType<typeof useGetJwtSuspenseQuery>;
export type GetJwtQueryResult = Apollo.QueryResult<IGetJwtQuery, IGetJwtQueryVariables>;
export const LogoutDocument = gql`
    mutation logout {
  reisbalans {
    logout(input: {}) {
      result {
        error
        success
      }
    }
  }
}
    `;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<ILogoutMutation, ILogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ILogoutMutation, ILogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<ILogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<ILogoutMutation, ILogoutMutationVariables>;
export const GetThemeDocument = gql`
    query GetTheme($hostname: String!) {
  __typename
  reisbalans {
    configurationProfileForHost(host: $hostname) {
      customerLabelTheme {
        primaryColor {
          hsla {
            alpha
            hue
            lightness
            saturation
          }
          rgba {
            blue
            alpha
            green
            red
          }
        }
        secondaryColor {
          hsla {
            hue
            alpha
            lightness
            saturation
          }
          rgba {
            alpha
            blue
            green
            red
          }
        }
        poster {
          uri
          base64
          width
          height
        }
        logo {
          uri
          base64
          height
          width
        }
        icon {
          uri
          base64
          height
          width
        }
        favicon {
          uri
          base64
        }
      }
    }
  }
}
    `;

/**
 * __useGetThemeQuery__
 *
 * To run a query within a React component, call `useGetThemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThemeQuery({
 *   variables: {
 *      hostname: // value for 'hostname'
 *   },
 * });
 */
export function useGetThemeQuery(baseOptions: Apollo.QueryHookOptions<IGetThemeQuery, IGetThemeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetThemeQuery, IGetThemeQueryVariables>(GetThemeDocument, options);
      }
export function useGetThemeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetThemeQuery, IGetThemeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetThemeQuery, IGetThemeQueryVariables>(GetThemeDocument, options);
        }
export function useGetThemeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetThemeQuery, IGetThemeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetThemeQuery, IGetThemeQueryVariables>(GetThemeDocument, options);
        }
export type GetThemeQueryHookResult = ReturnType<typeof useGetThemeQuery>;
export type GetThemeLazyQueryHookResult = ReturnType<typeof useGetThemeLazyQuery>;
export type GetThemeSuspenseQueryHookResult = ReturnType<typeof useGetThemeSuspenseQuery>;
export type GetThemeQueryResult = Apollo.QueryResult<IGetThemeQuery, IGetThemeQueryVariables>;