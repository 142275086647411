import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ISelect } from '../../../../../Components/LeasefleetTable/interfaces'
import { Modal } from '../../../../../Components/Modal'
import { List } from '../../../../../Components/Panel/Components/Content/List'
// @ts-ignore
// import { useGetExceededContractsQuery } from '../../../../../Lib/graphql'
import { CarContractsTable } from '../../../../DrivenLeaseContracts/Tabs/CarContractsTable'
import { Widget } from '../../Widget'
import { TOption } from '../../Widget/interfaces'
import Styles from '../../Widget/styles.module.scss'
import { WidgetListItem } from '../../WidgetListItem'
import { IBaseWidgetProps } from '../interfaces'
import { LoadingSkeleton } from './LoadingSkeleton'

const selectOptions = [
  { label: '10%', value: 10 },
  { label: '25%', value: 25 },
  { label: '50%', value: 50 },
]

export const ExceededContractsWidget: React.FC<IBaseWidgetProps> = ({ draggable }): JSX.Element => {
  const { t } = useTranslation()
  // @ts-ignore
  const { data, loading, refetch } = useGetExceededContractsQuery({
    fetchPolicy: 'no-cache',
  })

  const [stats, setStats] = useState<{ more: number; less: number }>({ more: 0, less: 0 })
  const [percentage, setPercentage] = useState<TOption>(selectOptions[0])
  const [modalType, setModalType] = useState<'overspending' | 'underspending'>()
  const modalTitle =
    modalType === 'overspending' ? t('Lease contract(s) with overspending') : t('Lease contract(s) with underspending')

  const handleOnChange = (selected: TOption): void => {
    setPercentage(selected)
    refetch({ positive_percentage: Number(selected.value), negative_percentage: Number(selected.value) * -1 })
  }

  useEffect(() => {
    // @ts-ignore
    const more = data?.lease?.contracts.filter((contract) => contract && contract.value && contract.value > 0).length
    // @ts-ignore
    const less = data?.lease?.contracts.filter((contract) => contract && contract.value && contract.value < 0).length
    setStats({ more: more || 0, less: less || 0 })
  }, [data])

  const selectWidgetOptions: ISelect<TOption> = {
    options: selectOptions,
    name: 'exceededContractsWidget',
    value: percentage,
    onChange: handleOnChange,
    menuAlignment: 'right',
    small: true,
  }

  return (
    <Widget
      id="ExceededContractsWidget"
      title={t('Lease contract(s) with over- and underspendings')}
      icon="graph-warning"
      draggable={draggable}
      select={selectWidgetOptions}
    >
      <Modal
        id="ExceededContractsWidgetModal"
        title={modalTitle}
        onClose={(): void => setModalType(undefined)}
        show={!!modalType}
        className={Styles.modal}
      >
        {modalType === 'overspending' && (
          <CarContractsTable
            yearlyMileageForecastDeviationParam={Number(percentage.value)}
            select={selectWidgetOptions}
          />
        )}
        {modalType === 'underspending' && (
          <CarContractsTable
            yearlyMileageForecastDeviationParam={Number(percentage.value) * -1}
            select={selectWidgetOptions}
          />
        )}
      </Modal>

      {((): JSX.Element => {
        if (loading) return <LoadingSkeleton />

        return (
          <List rows>
            <WidgetListItem
              onClick={(): void => setModalType('overspending')}
              count={stats.more}
              icon="arrow-up"
              disabled={draggable}
            >
              {t('More than {{percentage}}% exceeded', { percentage: percentage.value })}
            </WidgetListItem>
            <WidgetListItem
              onClick={(): void => setModalType('underspending')}
              count={stats.less}
              icon="arrow-down"
              disabled={draggable}
            >
              {t('Less than {{percentage}}% exceeded', { percentage: percentage.value })}
            </WidgetListItem>
          </List>
        )
      })()}
    </Widget>
  )
}

ExceededContractsWidget.displayName = 'ExceededContractsWidget'
