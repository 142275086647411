import React from 'react'

// import { useTranslation } from 'react-i18next'
import { IDrivenLeaseCarFleetProps } from './interfaces'

// @ts-ignore
export const DrivenLeaseCarFleet: React.FC<IDrivenLeaseCarFleetProps> = (/* { activeTabId } */): JSX.Element => {
  // const { t } = useTranslation()
  // return (
  //   <PageContainer
  //     header={{
  //       title: t('Lease Contracts'),
  //       subTitle: t('Select a view and/or fields'),
  //       info: t('To preserve the overview, we recommend selecting a maximum of 6 fields'),
  //       showLine: false,
  //     }}
  //   >
  //     <TabBar activeTabId={activeTabId}>
  //       <Tab id="carContracts" title={t('Car contracts')}>
  //         <CarContractsTable />
  //       </Tab>
  //       <Tab id="bicycleContracts" title={t('Bicycle contracts')}>
  //         <BicycleContractsTable />
  //       </Tab>
  //       <Tab id="carDamages" title={t('Car damage')}>
  //         <DamagesTable contractType="car-lease" />
  //       </Tab>
  //       <Tab id="carOrders" title={t('Car orders')}>
  //         <OrdersTable contractType="car-lease" />
  //       </Tab>
  //       <Tab id="bicycleOrders" title={t('Bicycle orders')}>
  //         <OrdersTable contractType="bicycle-lease" />
  //       </Tab>
  //       <Tab id="transactions" title={t('Tank & charge transactions')}>
  //         <TransactionsTable />
  //       </Tab>
  //       <Tab id="fines" title={t('Fines')}>
  //         <FinesTable />
  //       </Tab>
  //     </TabBar>
  //   </PageContainer>
  // )
}
