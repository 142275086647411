import React from 'react'

import { Typography } from '../../../../Typography'
import { TProps } from './interfaces'
import Styles from './styles.module.scss'

export const Option: React.FC<TProps> = ({
  label,
  description,
  value,
  onChange,
  selected,
  inline = false,
  children,
}): JSX.Element => {
  const onClick = (): void => {
    if (onChange) onChange(value)
  }

  return (
    <div className={`${Styles.option} ${selected ? Styles.selected : ''}`}>
      <div className={`${Styles.circle} ${inline ? Styles.inline : ''}`} onClick={onClick} />
      <div>
        {!inline && (
          <React.Fragment>
            <Typography variant="body-2" element="div" className={Styles.label} onClick={onClick}>
              {label}
            </Typography>
            {description && (
              <Typography variant="body-2" className={Styles.description}>
                {description}
              </Typography>
            )}
          </React.Fragment>
        )}

        {children}
      </div>
    </div>
  )
}
