import React from 'react'

import { LicensePlate } from '../../../../Components/LicensePlate'
import { Typography } from '../../../../Components/Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Contract: React.FC<IProps> = ({ contractNumber, licensePlate }): JSX.Element => {
  return (
    <React.Fragment>
      <Typography variant="h2" element="p" className={Styles.labelPrimary}>
        {contractNumber}
      </Typography>
      {licensePlate && <LicensePlate licensePlate={licensePlate} />}
    </React.Fragment>
  )
}
