import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../Components'
import { CreateTermsModal } from './CreateTermsModal'

export const CreateTermsButton = (): JSX.Element => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState<boolean>(false)

  return (
    <React.Fragment>
      <Button size="big" key="add-button" id="add-button" icon="add" onClick={(): void => setShowModal(true)}>
        {t('Add conditions')}
      </Button>

      <CreateTermsModal show={showModal} onClose={(): void => setShowModal(false)} />
    </React.Fragment>
  )
}
