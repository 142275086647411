import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Form } from '../../../../Components/FormComponents/Form'
import { Input } from '../../../../Components/FormComponents/Input'
import { TextArea } from '../../../../Components/FormComponents/TextArea'
import { Modal } from '../../../../Components/Modal'
import { ConfirmFooter, ContentSection, Footer } from '../../../../Components/Modal/Components'
import { IProps, TMessage } from '../interfaces'

export const EditMessage: React.FC<IProps> = ({ onClose }): JSX.Element => {
  const { t } = useTranslation()
  const [message, setMessage] = useState<TMessage>({ title: '', message: '' })

  const handleChange = (value: string, type: keyof TMessage): void => {
    setMessage((prevState) => ({ ...prevState, [type]: value }))
  }

  return (
    <Modal title={t('Edit concept')} onClose={onClose} show>
      <ContentSection title={t('Message')}>
        <Form>
          <Input
            id="title"
            label={t('Title')}
            value={message.title}
            onChange={(newValue): void => handleChange(newValue, 'title')}
          />
          <TextArea
            id="message"
            label={t('Message')}
            value={message.message}
            onChange={(newValue): void => handleChange(newValue, 'message')}
          />
        </Form>
      </ContentSection>

      <Footer>
        <ConfirmFooter
          cancelProps={{ onClick: onClose }}
          confirmProps={{ label: t('Save'), onClick: (): void => {} }}
        />
      </Footer>
    </Modal>
  )
}
