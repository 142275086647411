import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { ListItem } from '../../../../Components/Panel/Components/Content/List/Components/ListItem'
import { LayoutContext } from '../../../../Providers/LayoutProvider'
import { IProps } from './interface'

export const WidgetListItem: React.FC<IProps> = ({ url, integratedUrl, disabled, onClick, ...props }): JSX.Element => {
  const navigate = useNavigate()
  const { integrated } = useContext(LayoutContext)

  const handleClick = (): void => {
    if (!disabled) {
      if (onClick) {
        onClick()
        return
      }
      if (integratedUrl && integrated) window.parent.location.href = integratedUrl
      if (url) navigate(url)
    }
  }

  return <ListItem {...props} disabled={disabled} onClick={handleClick} />
}
