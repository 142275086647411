import i18n from 'i18next'

export const publishedTo = [
  'Appelo-3jan',
  'Appelo-4',
  'Appelo B.V',
  'Appelo-90',
  'jantje',
  'Appelo-3jan1',
  'Appelo-42',
  'Appelo B.V3',
  'Appelo-904',
  'Appelo-9124',
  'Appelo-9134',
  'Appelo-9154',
  'Appelo-9174',
  'Appel3o-904',
  'Appe1lo-904',
  'Appel5o-904',
  'Ap1pelo-904',
  'Apelo-904',
]

const { t } = i18n
export const tableConfig = {
  name: {
    name: t('Name'),
    width: '300px',
  },
  organisations: {
    name: t('Organisations'),
    width: '100%',
  },
}
