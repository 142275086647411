import React from 'react'

import { Icon } from '../Icon'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Pagination: React.FC<IProps> = ({
  totalItems,
  onChange,
  selected,
  totalItemsPerPage = 10,
  navArrows = false,
  visiblePages = 10,
}) => {
  const totalPages = Math.ceil(totalItems / totalItemsPerPage)

  const onClick = (page: number): void => {
    if (page !== selected) onChange(page)
  }

  const onNavArrowClick = (direction: 'left' | 'right'): void => {
    if (direction === 'left' && selected !== 1) onClick(selected - 1)
    else if (direction === 'right' && selected < totalPages) onClick(selected + 1)
  }

  const addPagesToArray = (startNumber: number, total: number, pagesArray: JSX.Element[]): JSX.Element[] => {
    for (let page = startNumber; page < startNumber + total; page += 1) {
      pagesArray.push(
        <li key={page}>
          <a
            className={selected === page ? Styles.selected : ''}
            onClick={(): void => onClick(page)}
            data-testid={`pagination-page-${page}`}
          >
            {page}
          </a>
        </li>
      )
    }

    return pagesArray
  }

  const addDividerToArray = (pagesArray: JSX.Element[]): JSX.Element[] => {
    pagesArray.push(
      <li className={Styles.divider} key={`divider-${pagesArray.length}`}>
        <span>...</span>
      </li>
    )

    return pagesArray
  }

  const getPages = (): JSX.Element[] => {
    const pageElements: Array<JSX.Element> = []

    // no grouping needed
    if (totalPages <= visiblePages) addPagesToArray(1, totalPages, pageElements)
    // selected page is in the first group
    else if (selected < visiblePages) {
      addPagesToArray(1, visiblePages - 1, pageElements)
      addDividerToArray(pageElements)
      addPagesToArray(totalPages, 1, pageElements)
    }
    // selected page is in the last group
    else if (selected > totalPages - visiblePages + 1) {
      addPagesToArray(1, 1, pageElements)
      addDividerToArray(pageElements)
      addPagesToArray(totalPages - visiblePages + 2, visiblePages - 1, pageElements)
    }
    // selected page is in the somewhere in the middle of the range
    else {
      const marginPos = Math.floor((visiblePages - 3) / 2)

      addPagesToArray(1, 1, pageElements)
      addDividerToArray(pageElements)
      addPagesToArray(selected - marginPos, visiblePages - 2, pageElements)
      addDividerToArray(pageElements)
      addPagesToArray(totalPages, 1, pageElements)
    }

    return pageElements
  }

  return (
    <nav aria-label="pagination" data-testid="pagination">
      <ul className={Styles.pagination}>
        {navArrows && (
          <li>
            <a
              className={`${Styles.nav} ${selected === 1 ? Styles.disabled : ''}`}
              onClick={(): void => onNavArrowClick('left')}
              data-testid="pagination-arrow-left"
            >
              <Icon name="chevron-left" height="13px" />
            </a>
          </li>
        )}
        {getPages()}
        {navArrows && (
          <li>
            <a
              className={`${Styles.nav} ${selected === totalPages ? Styles.disabled : ''}`}
              onClick={(): void => onNavArrowClick('right')}
              data-testid="pagination-arrow-right"
            >
              <Icon name="chevron-right" height="13px" />
            </a>
          </li>
        )}
      </ul>
    </nav>
  )
}
