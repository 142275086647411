import { format } from 'date-fns'
import React from 'react'
import { DayContent, DayContentProps, DayPicker as ReactDayPicker, DayPickerProps } from 'react-day-picker'
import DayPickerStyles from 'react-day-picker/dist/style.module.css'

import { useTranslationDecorator } from '../../../Hooks/useTranslationDecorator'
import { localeForDateFn } from '../../../Lib/Formatters/DateFormatter'
import { dayPickerFormat } from './helpers'
import Styles from './styles.module.scss'

export const DayPicker: React.FC<DayPickerProps> = (props: DayPickerProps): JSX.Element => {
  const { locale } = useTranslationDecorator()
  const renderDayContent = (dayContentProps: DayContentProps): JSX.Element => {
    return (
      <span data-testid={`day-${format(dayContentProps.date, dayPickerFormat, { locale: localeForDateFn(locale) })}`}>
        <DayContent {...dayContentProps} />
      </span>
    )
  }

  return (
    <ReactDayPicker
      components={{
        DayContent: renderDayContent,
      }}
      classNames={{ ...DayPickerStyles, ...Styles }}
      {...props}
    />
  )
}
