import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { LoadingSkeleton as ListItemSkeleton } from '../../../../../../Components/Panel/Components/Content/List/Components/ListItem/LoadingSkeleton'
import Styles from './styles.module.scss'

const Bar: React.FC<{ height: number }> = ({ height }): JSX.Element => (
  <Skeleton className={Styles.bar} width={40} height={`${height}%`} />
)

const Statistic = (): JSX.Element => (
  <div className={Styles.statistic}>
    <Skeleton height={20} width={50} />
    <Skeleton height={10} width={70} />
  </div>
)

export const LoadingSkeleton = (): JSX.Element => {
  return (
    <div>
      <div className={Styles.top}>
        <div className={Styles.inner}>
          <Bar height={20} />
          <Bar height={40} />
          <Bar height={60} />
          <Bar height={80} />
          <Bar height={60} />
          <Bar height={40} />
          <Bar height={20} />
        </div>
      </div>

      <div className={Styles.statistics}>
        <Statistic />
        <Statistic />
        <Statistic />
      </div>

      <ListItemSkeleton />
    </div>
  )
}
