import React, { ReactElement } from 'react'

import { IProps as IKeyValueListItem } from './Components/KeyValueListItem/interfaces'
import { IProps as IListItem } from './Components/ListItem/interface'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const List: React.FC<IProps> = ({ children, labelWidth, rows = false }): JSX.Element => {
  const values = ((): Array<ReactElement<IKeyValueListItem | IListItem>> => {
    if (Array.isArray(children)) return children

    return [children]
  })()

  return (
    <ul className={`${Styles.list} ${rows ? Styles.rows : ''}`}>
      {values.map((value, index) => {
        const props: { key: string; labelWidth?: string } = {
          key: `value-${index}`,
        }

        if ('labelWidth' in value.props) props.labelWidth = value.props.labelWidth || labelWidth

        return React.cloneElement(value, { key: props.key, ...value.props })
      })}
    </ul>
  )
}
