import { TableNode } from '@table-library/react-table-library'
import { add, differenceInCalendarMonths, format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LeasefleetTable } from '../../../../Components/LeasefleetTable'
import { ITableConfig } from '../../../../Components/LeasefleetTable/interfaces'
import { MaintenanceNotice } from '../../../../Components/MaintenanceNotice'
import { formatCurrency } from '../../../../Lib/Formatters/currencyFormatter'
import { formatDistance } from '../../../../Lib/Formatters/distanceFormatter'
// @ts-ignore
// import { useGetLeaseCarContractDataQuery } from '../../../../Lib/graphql'
import { TLocale } from '../../../../Lib/sharedInterfaces'
import { Cell } from '../../Components/Cell'
import { Contract } from '../../Components/Contract'
import { formatDate, getFuelTypeTranslation, getStatusTranslation, getValidValue } from '../../helpers'
import { ICarContractProps, TDav } from '../../interfaces'
import { tableConfig } from './helpers'

export const CarContractsTable: React.FC<ICarContractProps> = ({
  dateRange,
  yearlyMileageForecastDeviationParam = 0,
  select,
}): JSX.Element => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const locale = language as TLocale

  const [rowData, setRowData] = useState<TableNode[]>([])

  let yearlyMileageForecastDeviation: TDav

  if (yearlyMileageForecastDeviationParam === 0) yearlyMileageForecastDeviation = {}
  else {
    yearlyMileageForecastDeviation =
      yearlyMileageForecastDeviationParam > 0
        ? { gte: yearlyMileageForecastDeviationParam }
        : { lte: yearlyMileageForecastDeviationParam }
  }

  const perPage = 7
  const isRangeProvided = !!dateRange
  const endDateRange = isRangeProvided
    ? { gte: format(dateRange.from, 'yyyy-MM-dd'), lte: format(dateRange.till, 'yyyy-MM-dd') }
    : undefined
  // @ts-ignore
  const { data, error, loading, refetch } = useGetLeaseCarContractDataQuery({
    variables: {
      limit: perPage,
      whereStatus: { eq: 'running' },
      expectedEndDate: endDateRange,
      yearlyMileageForecastDeviation,
    },
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    const contracts = data?.lease?.contracts
    if (!contracts) return
    setRowData(contracts)
  }, [data])

  const cellDataGenerator = (config: ITableConfig, item: TableNode): JSX.Element[] => {
    return Object.keys(config).map((column) => {
      switch (column) {
        case 'contractNumber':
          return <Contract contractNumber={item.contractNumber} licensePlate={item.vehicle?.licensePlate || null} />
        case 'driverName':
          return (
            <Cell
              value={getValidValue(item.driverName)}
              subValue={t('driver since {{ date }}', { date: formatDate(item.driverSince, locale) })}
            />
          )
        case 'kind':
          return (
            <Cell
              value={[item.vehicle?.brand, item.vehicle?.model, item.vehicle?.year]
                .filter((value) => !!value)
                .join(' - ')}
              subValue={item.vehicle?.modelSubtype}
            />
          )
        case 'fuel':
          return <Cell value={getFuelTypeTranslation(item.vehicle?.fuelType, locale)} />

        case 'contractPeriod':
          return (
            <Cell
              value={t('Start: {{ date }}', { date: formatDate(item.startDate, locale) })}
              subValue={t('End: {{ date }}', { date: formatDate(item.expectedEndDate, locale) })}
            />
          )

        case 'contractDuration': {
          const diff = differenceInCalendarMonths(
            add(Date.parse(item.startDate), { months: item.durationInMonths }),
            Date.now()
          )

          return (
            <Cell
              value={t('Duration: {{ months }} months', { months: item.durationInMonths })}
              subValue={diff <= 0 ? t('Expired') : t('Remaining: {{ months }} months', { months: diff || '-' })}
            />
          )
        }

        case 'budgetMileage':
          return <Cell value={formatDistance(item.yearlyContractMileage * 1000, locale)} />

        case 'insurance':
          return (
            <Cell
              value={t('Insurance: {{ date }}', { date: formatDate(item.liabilityInsuranceSince, locale) })}
              subValue={t('APK: {{ date }}', {
                date: formatDate(item.vehicle?.vehicleData?.data?.periodicCheckupValidUntil.slice(0, 10), locale),
              })}
            />
          )

        case 'financial':
          return (
            <Cell
              value={t('Operating costs: {{ cost }}', {
                cost: formatCurrency(item.operatingCostsPerMonth || 0, locale),
              })}
              subValue={t('Catalog value: {{ value }}', { value: formatCurrency(item.cataloguePrice || 0, locale) })}
            />
          )

        case 'cost':
          return (
            <Cell
              value={t('Lease rate: {{ rate }}', { rate: formatCurrency(item.ratePerMonth || 0, locale) })}
              subValue={t('Fuel advance: {{ advance }}', {
                advance: formatCurrency(item.fuelAdvancePerMonth || 0, locale),
              })}
            />
          )

        case 'status':
          return <Cell value={getStatusTranslation(item.status, locale)} />

        case 'environment':
          return (
            <Cell
              value={t('Energylabel: {{ label }}', { label: item.vehicle?.energyClassification || '-' })}
              subValue={t('Co2: {{ emission }}', { emission: item.vehicle?.vehicleData?.data?.coEmission || '-' })}
            />
          )

        case 'isShortLease':
          return <Cell value={item.isShortLease ? t('true') : t('false')} />

        default:
          return <Cell value={item[column]} />
      }
    })
  }

  if (!loading && error) return <MaintenanceNotice error={error} />
  const totalRecords = data?.lease?.contractsAggregate?.aggregate?.count || 0

  return (
    <LeasefleetTable
      loading={loading}
      cellGenerator={cellDataGenerator}
      tableConfig={tableConfig(t)}
      tableData={rowData}
      tableName="LeaseCarContracts"
      showCustomization
      perPage={perPage}
      totalResults={totalRecords}
      refetch={refetch}
      select={select}
    />
  )
}
