import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../../Button'
import Styles from '../../styles.module.scss'
import { UploadInput } from '../UploadInput'
import { IProps } from './interfaces'

export const FileContent: React.FC<IProps> = ({
  innerRef,
  value,
  accept = '.xls, .xlsx, .pdf, .doc, .docx',
  onChange,
}): JSX.Element => {
  const { t } = useTranslation()

  const handleFileChange = (file: File): void => {
    const { name, type } = file

    const input = {
      data: '',
      contentType: type,
      filename: name,
    }

    onChange(input)
  }

  const openFileSelector = (): void => innerRef.current?.click()

  const overrideFile = (): void => {
    onChange(null)
    if (innerRef.current) innerRef.current.value = ''
    openFileSelector()
  }

  const downloadFile = (): void => {
    const file = innerRef.current?.files?.item(0)
    const downloadLink = document.createElement('a')

    if (file) {
      // File selected by the user
      downloadLink.href = URL.createObjectURL(file)
      downloadLink.download = file.name
    } else if (value) {
      // Value provided by the parent without user action
      downloadLink.href = value.data
      downloadLink.download = value.filename
    } else {
      // Neither a file nor a value is available, return
      return
    }

    downloadLink.target = '_blank'
    downloadLink.click()
  }

  return (
    <div data-testid="buttons" className={Styles.buttons}>
      <UploadInput accept={accept} innerRef={innerRef} onChange={handleFileChange} />

      {!value ? (
        <Button
          size="small"
          className={Styles.button}
          icon="upload"
          variant="secondary"
          onClick={openFileSelector}
          fullWidth
        >
          {value ? t('Again') : t('Upload')}
        </Button>
      ) : (
        <React.Fragment>
          <Button
            icon="download"
            size="small"
            className={Styles.button}
            variant="secondary"
            onClick={downloadFile}
            fullWidth
          >
            {t('Download')}
          </Button>

          <Button
            icon="upload"
            size="small"
            className={Styles.button}
            variant="secondary"
            onClick={overrideFile}
            fullWidth
          >
            {t('Override')}
          </Button>
        </React.Fragment>
      )}
    </div>
  )
}
