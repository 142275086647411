import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  defaultDataIdFromObject,
  InMemoryCache,
  InMemoryCacheConfig,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import ApolloLinkNetworkStatus from 'react-apollo-network-status/dist/src/ApolloLinkNetworkStatus'

import { ICustomResponseObject } from './apolloClientInterfaces'
import { generateApolloClientHeaders } from './headersHelper'
import { TLocale } from './sharedInterfaces'

// We add the operation name as query parameter so that the VCR functionality can differentiate between consecutive graphql requests.
// As an added bonus, this also allows us to track statistics on how ofter a certain query is called.
const customFetch = (uri: string, options: RequestInit | undefined): Promise<Response> => {
  const { operationName } = JSON.parse((options?.body as string) || '')
  return fetch(`${uri}?operationName=${operationName}`, options)
}

const httpLink = createHttpLink({
  uri: import.meta.env.REACT_APP_GRAPHQL_URL,
  fetch: customFetch,
  ...(import.meta.env.MODE === 'production' && { credentials: 'include' }),
})

// Generate shared InMemoryCache options to share between this apollo client and mockedprovider
export const sharedInMemoryCacheOptions: InMemoryCacheConfig = {
  dataIdFromObject(responseObject: ICustomResponseObject) {
    // eslint-disable-next-line sonarjs/no-small-switch
    let id: string | undefined = 'GooglePlaces'
    switch (responseObject.__typename) {
      case 'Coordinates':
        return `Cooridantes:${responseObject.latitude}-${responseObject.longitude}`

      case 'GooglePlaces':
        if (responseObject.suggest?.placeId) id = `suggest_${responseObject.suggest.placeId}`
        if (responseObject.details?.placeId) id = `details_${responseObject.details.placeId}`

        return `GooglePlaces:${id}`

      default:
        id = defaultDataIdFromObject(responseObject)
        if (!id) {
          if (responseObject.create?.taxiReservation)
            return `TaxiReservation:${responseObject.create.taxiReservation.id || 'UNKNOWN'}`

          if (responseObject.permissions && responseObject.id) return `Permissions:${responseObject.id}`
        }
        // console.warn('Could not handle cache key for', responseObject)
        return defaultDataIdFromObject(responseObject)
    }
  },
}

const unauthorizedLink = (locale: TLocale): ReturnType<typeof setContext> =>
  setContext((_request, context) => {
    return {
      headers: {
        ...context.headers,
        ...generateApolloClientHeaders({ withAuthorization: false, locale }),
      },
    }
  })

const authorizedLink = (jwtToken: string | null, locale: TLocale): ReturnType<typeof setContext> =>
  setContext((request, context) => {
    return {
      headers: {
        ...context.headers,
        ...generateApolloClientHeaders({ jwtToken, withAuthorization: true, role: context.role, locale }),
      },
    }
  })

const errorLink = (logoutFn: () => void): ApolloLink =>
  onError(({ networkError }) => {
    if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) logoutFn()
  })

const generateClient = (
  receivedLink: ReturnType<typeof setContext>,
  link?: ApolloLinkNetworkStatus
): InstanceType<typeof ApolloClient> => {
  return new ApolloClient({
    cache: new InMemoryCache(sharedInMemoryCacheOptions),
    link: link ? link.concat(receivedLink.concat(httpLink)) : receivedLink.concat(httpLink),
    connectToDevTools: true,
  })
}

export const authorizedClient = (
  jwtToken: string | null,
  link: ApolloLinkNetworkStatus,
  logoutFn: () => void,
  locale: TLocale
): ApolloClient<unknown> => generateClient(authorizedLink(jwtToken, locale).concat(errorLink(logoutFn)), link)

export const unauthorizedClient = (locale: TLocale): ApolloClient<unknown> => generateClient(unauthorizedLink(locale))
