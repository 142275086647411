import React from 'react'

import { layoutId } from '../helpers'
import Styles from './styles.module.scss'

export const IntegratedLayout = ({ children }: { children: JSX.Element }): JSX.Element => {
  return (
    <div className={Styles.layout}>
      <div id={layoutId} className={Styles.content}>
        {children}
      </div>
    </div>
  )
}
