import { ESortDirection, ITableConfig, ITableFieldsDisplaySettings, TConvertedObject } from './interfaces'

export const generateDefaultTableDisplaySettings = (tableConfig: ITableConfig): ITableFieldsDisplaySettings => {
  const showFields: Array<string> = Object.keys(tableConfig).reduce((acc: Array<string>, key) => {
    if (tableConfig[key].required || !tableConfig[key].hide) acc.push(key)

    return acc
  }, [])

  return {
    __default: true,
    showFields,
  }
}

export const storybookTableConfig = {
  name: {
    name: 'Name',
    required: true,
    pinLeft: true,
  },
  age: {
    name: 'Age',
  },
  favoriteColor: {
    name: 'Favorite Color',
  },
}

export const storybookTableConfigLong = {
  ...storybookTableConfig,
  favoriteMovie: {
    name: 'Favorite Movie',
  },
  favoriteFood: {
    name: 'Favorite Food',
  },
  favoriteAnimal: {
    name: 'Favorite Animal',
  },
}

export const storybookTableData = [
  {
    id: '1',
    name: 'John',
    age: 20,
    favoriteColor: 'blue',
    favoriteMovie: 'The Matrix',
    favoriteFood: 'Pizza',
    favoriteAnimal: 'Dog',
  },
  {
    id: '2',
    name: 'Jane',
    age: 24,
    favoriteColor: 'green',
    favoriteMovie: 'Boon Dog saints',
    favoriteFood: 'Shawarma',
    favoriteAnimal: 'Cat',
  },
  {
    id: '3',
    name: 'Mary',
    age: 31,
    favoriteColor: 'red',
    favoriteMovie: 'IT',
    favoriteFood: 'Falafal',
    favoriteAnimal: 'Fish',
  },
  {
    id: '4',
    name: 'Peter',
    age: 16,
    favoriteColor: 'black',
    favoriteMovie: 'Everything Everywhere All At Once',
    favoriteFood: 'Mac and Cheese',
    favoriteAnimal: 'Bird',
  },
  {
    id: '5',
    name: 'Barbara',
    age: 43,
    favoriteColor: 'yellow',
    favoriteMovie: 'Highlander',
    favoriteFood: 'Fried Chicken',
    favoriteAnimal: 'Snake',
  },
  {
    id: '6',
    name: 'Richard',
    age: 18,
    favoriteColor: 'purple',
    favoriteMovie: 'Deadpool',
    favoriteFood: 'Noodles',
    favoriteAnimal: 'Duck',
  },
]

export const convertArrayToObject = (keys: string[], directionIndex: number): TConvertedObject => {
  if (keys.length === 0) return ESortDirection[directionIndex]

  const obj: any = {}
  const [firstKey, ...remainingKeys] = keys
  obj[firstKey] = convertArrayToObject(remainingKeys, directionIndex)

  return obj
}

export const removeKeysWithNoneValues = (obj: any): object => {
  return Object.fromEntries(
    Object.entries(obj).filter(([__, value]) => {
      if (value === 'NONE') return false

      if (typeof value === 'object' && !Array.isArray(value)) {
        const filteredValue = removeKeysWithNoneValues(value)
        return Object.keys(filteredValue).length > 0
      }
      return true
    })
  )
}

export const mergeDeep = (target: Record<string, any>, source: Record<string, any>): void => {
  Object.keys(source).forEach((key) => {
    if (source[key] instanceof Object) {
      if (!target[key]) Object.assign(target, { [key]: {} })
      mergeDeep(target[key], source[key])
    } else Object.assign(target, { [key]: source[key] })
  })
}
