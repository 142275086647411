import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Modal } from '../../../../Components/Modal'
import { ContentSection } from '../../../../Components/Modal/Components'
import { Contract } from './Components/Contract'
import { IProps } from './Components/interfaces'
import { Label } from './Components/Label'
import { MobilityCard } from './Components/MobilityCard'
import { Platform } from './Components/Platform'
import { SelectButtons } from './Components/SelectButtons'
import { IProps as ISelectButtonProps } from './Components/SelectButtons/Components/SelectButton/interfaces'
import { Services } from './Components/Services'
import { TVariant } from './interfaces'

export const CreateTermsModal: React.FC<IProps> = (props): JSX.Element => {
  const { show, onClose } = props
  const icon = 'file'
  const { t } = useTranslation()
  const [selectedValue, setSelectedValue] = useState<TVariant>()

  const data = [
    {
      label: t('Platform'),
      value: 'PLATFORM',
      icon,
      component: <Platform {...props} />,
    },
    {
      label: t('Label'),
      value: 'LABEL',
      icon,
      component: <Label {...props} />,
    },
    {
      label: t('Contract'),
      value: 'CONTRACT',
      icon,
      component: <Contract {...props} />,
    },
    {
      label: t('Mobility card'),
      value: 'MOBILITY_CARD',
      icon,
      component: <MobilityCard {...props} />,
    },
    {
      label: t('Services'),
      value: 'SERVICES',
      icon,
      component: <Services {...props} />,
    },
  ]

  const dataSet: ISelectButtonProps<TVariant>[] = data.map(
    ({ component: __, ...rest }) => rest as ISelectButtonProps<TVariant>
  )

  const Component = data.find((item) => item.value === selectedValue)?.component

  return (
    <Modal show={show} title={t('Add conditions')} onClose={onClose}>
      <ContentSection title={t('What kind of conditions do you want to add?')}>
        <SelectButtons<TVariant> value={selectedValue} data={dataSet} onClick={setSelectedValue} />
      </ContentSection>

      {!!Component && Component}
    </Modal>
  )
}
