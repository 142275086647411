import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../../../../../Components'
import { ColorInput } from '../../../../../../../Components/FormComponents/ColorInput'
import { FileUpload } from '../../../../../../../Components/FormComponents/FileUpload'
import { IFile } from '../../../../../../../Components/FormComponents/FileUpload/interfaces'
import { Form } from '../../../../../../../Components/FormComponents/Form'
import { InlineFields } from '../../../../../../../Components/FormComponents/InlineFields'
import { Radio } from '../../../../../../../Components/FormComponents/Radio'
import { Option } from '../../../../../../../Components/FormComponents/Radio/Components/Option'
import { Modal } from '../../../../../../../Components/Modal'
import { ConfirmFooter, ContentSection } from '../../../../../../../Components/Modal/Components'

export const Edit = (): JSX.Element => {
  const { t } = useTranslation()
  const [show, setShow] = useState<boolean>(false)

  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [primaryColor, setPrimaryColor] = useState<string>('#32B457')
  const [sidebarColor, setSidebarColor] = useState<string>('#e2e2e2')
  const [iconBlackBackground, setIconBlackBackground] = useState<IFile | null>(null)
  const [iconWhiteBackground, setIconWhiteBackground] = useState<IFile | null>(null)
  const [logoWhiteBackground, setLogoWhiteBackground] = useState<IFile | null>(null)
  const [logoBlackBackground, setLogoBlackBackground] = useState<IFile | null>(null)
  const [favicon, setFavicon] = useState<IFile | null>(null)
  const [loginCover, setLoginCover] = useState<IFile | null>(null)

  const clickHandler = (): void => setShow(true)
  const closeHandler = (): void => setShow(false)
  const renderFooter = (): JSX.Element => {
    return (
      <ConfirmFooter
        cancelProps={{ onClick: closeHandler }}
        confirmProps={{ label: t('Save'), onClick: closeHandler }}
      />
    )
  }

  const formType = t('Change style')
  const size = { width: 200, height: 200 }

  return (
    <React.Fragment>
      <Button variant="secondaryBold" size="small" onClick={clickHandler}>
        {t('Edit', { context: 'short' })}
      </Button>

      {show && (
        <Modal
          title={t('Customer label - {{ formType }}', { formType })}
          onClose={closeHandler}
          footer={renderFooter()}
          show
        >
          <ContentSection>
            <Form>
              <Radio label={t('Admin')} id="admin" onChange={(value): void => setIsAdmin(value === 'true')}>
                <Option label={t('Yes')} value="true" selected={isAdmin} />
                <Option label={t('No')} value="false" selected={!isAdmin} />
              </Radio>

              <InlineFields>
                <ColorInput
                  id="primaryColor"
                  label={t('Primary color')}
                  value={primaryColor}
                  onChange={setPrimaryColor}
                />
                <ColorInput id="sidebarColor" label={t('Sidebar')} value={sidebarColor} onChange={setSidebarColor} />
              </InlineFields>

              <InlineFields>
                <FileUpload
                  type="image"
                  label={t('Icon black background')}
                  value={iconBlackBackground}
                  onChange={setIconBlackBackground}
                  size={size}
                />
                <FileUpload
                  type="image"
                  label={t('Icon white background')}
                  value={iconWhiteBackground}
                  onChange={setIconWhiteBackground}
                  size={size}
                />
              </InlineFields>

              <InlineFields>
                <FileUpload
                  type="image"
                  label={t('Logo black background')}
                  value={logoBlackBackground}
                  onChange={setLogoBlackBackground}
                  size={size}
                />
                <FileUpload
                  type="image"
                  label={t('Logo white background')}
                  value={logoWhiteBackground}
                  onChange={setLogoWhiteBackground}
                  size={size}
                />
              </InlineFields>

              <InlineFields>
                <FileUpload type="image" label={t('Favicon')} value={favicon} onChange={setFavicon} size={size} />
                <FileUpload
                  type="image"
                  label={t('Inlog cover')}
                  value={loginCover}
                  onChange={setLoginCover}
                  size={size}
                />
              </InlineFields>
            </Form>
          </ContentSection>
        </Modal>
      )}
    </React.Fragment>
  )
}
