import React from 'react'

import { LicensePlate } from '../../../../../Components/LicensePlate'
import { Typography } from '../../../../../Components/Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Kind: React.FC<IProps> = ({ licensePlate, kind }): JSX.Element => {
  return (
    <div>
      <Typography variant="h2" element="span">
        {kind}
      </Typography>
      {licensePlate && (
        <div className={Styles.licensePlate}>
          <LicensePlate licensePlate={licensePlate} />
        </div>
      )}
    </div>
  )
}
